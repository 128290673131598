import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustDifferent } from 'src/app/_helpers/must-different.validator';
import {gql, Apollo} from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { config } from './../../../config';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';

const getcountry = gql`
query{
  registrationPage{
      subscriptionId
      countryItem{
          id
          countryName
          currency
      }
  }
}
`;

const gettac = gql`
query{
  registrationTermAndCondition{
      id
      version
      description
  }
}
`;

const postregistration = gql`
mutation register($companyName: String!, $countryId: ID!, $userEmail: EmailAddress!, $agreedTermConditionId: ID!, $registrationIp: IPv4,$reference: String, $utmCampaign: String){
  register(input: { companyName: $companyName, countryId: $countryId, userEmail: $userEmail, agreedTermConditionId: $agreedTermConditionId, registrationIp: $registrationIp, reference: $reference, utmCampaign: $utmCampaign
  }){
      id
      companyName
      registrationCountry
      userEmail
      agreedTermConditionId
      registrationIp
      subscriptionChoiceIdDuringRegistration
      reference
  }
}
`;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  countries : any;
 
  //registerForm: FormGroup | undefined;
  submitted = false;

  tos:boolean=false;
  tac: any = false;
  getipdetail : any ='';

  //model
  ValCompanyname:string=''
  ValCountry:string=''
  ValEmail:string=''
  ValBackupemail:string=''
  ValName:string=''

  successregistration:boolean=false;
  pageloading: boolean = false;

  captcha?:string
  cpatchaErr:boolean=false
  config:any

  registerForm = this.formBuilder.group({
    companyname: ['', Validators.required],
    country: ['', Validators.required],
    email: ['', [Validators.required, Validators.email, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
    // inputcaptcha: [null, Validators.required],
    inputcaptcha: [null], // NOT REQUIRED FOR DEMO, NEED DOMAIN
    

    // backupemail: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]],
    // name: ['', [Validators.required]],
    acceptTerms: [false, Validators.requiredTrue]
  }
  // , {
  //   validator: MustDifferent('email', 'backupemail')
  // }
  );


  display ='none'
  cpatchaText:string='';

  TnCsrc:any
  ValReference:any=''
  showRefCode:boolean = false
  isFromUrl:boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private authService:AuthService,
    private route: ActivatedRoute,
    protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.authService.forceLogout();
    this.getCountry();
    this.getTerm();
    this.getIPAddrs();
    this.config = config
    
    if(this.route.snapshot.queryParamMap){
      if(this.route.snapshot.queryParamMap.get('ref')){
        this.ValReference = this.route.snapshot.queryParamMap.get('ref')
        this.isFromUrl = true
        localStorage.setItem('ref', this.ValReference);
      }
      //reset ref
      if(this.route.snapshot.queryParamMap.get('ref')==''){
        this.ValReference = ''
        this.isFromUrl = false
        localStorage.removeItem('ref');
      }
    }
    
    if(localStorage.getItem('ref')){
      this.ValReference = localStorage.getItem('ref')
      this.isFromUrl = true
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm?.controls; }

  onSubmit() {
        this.submitted = true;
        console.log(this.registerForm?.value);
        console.log(this.registerForm);
        if(this.cpatchaText==''){
          console.log('registerForm cpatchaErr true');
          this.cpatchaErr = true
        } else {
          console.log('registerForm cpatchaErr false');
          this.cpatchaErr = false
        }
        // stop here if form is invalid
        if (this.registerForm.invalid) {
          console.log('registerForm invalid');
            return;
        }

        // display form values on success
        //alert(JSON.stringify(this.registerForm?.value, null, 4));

        // if(this.cpatchaText!==''){
        //   console.log('cpatchaRes ok');
        //   this.postregister();
        // } else {
        //   console.log('cpatchaRes not checked');
        // }
        this.postregister();
  }

  getCountry(){
    this.apollo.watchQuery<any>({
      query: getcountry,
      errorPolicy: 'all'
    })
    .valueChanges
    .subscribe(({data, loading}) => {
      console.log(loading);
      console.log('getcountry',data);
      this.countries = data.registrationPage.countryItem;
      console.log('xxx',this.countries);
    })
  }

  getTerm(){
    this.apollo.watchQuery<any>({
      query: gettac
    })
    .valueChanges
    .subscribe(({data, loading}) => {
      console.log(loading);
      this.tac = data.registrationTermAndCondition;
      console.log('tac',this.tac);
      this.TnCsrc = {
        url: this.tac?.description,
        withCredentials: false
       }
    })
  }

  getSafeUrl(fileName: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(fileName);
}
  getIPAddrs() {
    var urlGetIp = 'http://api.ipify.org/?format=json'
    if (location.protocol == 'https:') {
      urlGetIp = 'https://api.ipify.org/?format=json';
    }
    return this.http.get(urlGetIp).subscribe((res:any)=>{  
      this.getipdetail=res;  
      console.log('getipdetail',this.getipdetail);
    });  
  }
  
  postregister() {
    this.pageloading=true;
    var companyName = this.ValCompanyname
    var countryId = this.ValCountry
    var userEmail = this.ValEmail
    var userSecondaryEmail = this.ValBackupemail
    var fullName = this.ValName
    var agreedTermConditionId = this.tac?.id
    var registrationIp = (this.getipdetail!=='') ? (this.getipdetail.ip)?this.getipdetail.ip: '8.8.8.8' : '0.0.0.0';
    var reference = this.ValReference;
    console.log('companyName',companyName);
    console.log('countryId',countryId);
    console.log('userEmail',userEmail);
    console.log('userSecondaryEmail',userSecondaryEmail);
    console.log('fullName',fullName);
    console.log('agreedTermConditionId',agreedTermConditionId);
    console.log('registrationIp',registrationIp);

    var postData:any = {
      companyName: companyName,
      countryId: countryId,
      userEmail: userEmail,
      agreedTermConditionId: agreedTermConditionId,
      registrationIp: registrationIp
    }
    if(reference!==''){
      postData['reference'] = reference
    }

    var utmCampaign =  localStorage.getItem('utm_campaign') || '';
    if(utmCampaign!==''){
      postData['utmCampaign'] = utmCampaign
    }
    console.log('postData : ',postData)


    this.apollo
      .mutate({
        mutation: postregistration,
        variables: postData
        // variables: {
        //   companyName: companyName,
        //   countryId: countryId,
        //   userEmail: userEmail,
        //   //userSecondaryEmail : userSecondaryEmail,
        //   //fullName : fullName,
        //   agreedTermConditionId: agreedTermConditionId,
        //   registrationIp: registrationIp
        // }
      })
      .subscribe(
        (res:any) => {
          console.log("succes, get data:", res);
          if(res.data){
            this.successregistration=true;
            localStorage.removeItem('ref');
          }
          else {
            alert('Registration failed, please try again later.');
          }
          this.pageloading=false;
          
        },
        error => {
          console.log("there was an error sending the query", error);
          alert('Oops.. Something went wrong.');
          this.pageloading=false;
        }
      );
      

  }
  


  onReset() {
        this.submitted = false;
        this.registerForm?.reset();
  }
  openModal() {
    
    console.log(this.tos);
    if(!this.tos){
      this.display = "block";
    }
  }
  closeModal(x:any) {
    this.display = "none";
    if(x=='Y'){
      this.tos=true;
    } else {
      this.tos=false;
    }
  }

  public resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    console.log('captchaResponse',captchaResponse);
    
    if(captchaResponse){
      this.cpatchaText = captchaResponse
    }
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  public clickReffcode(){
    if(this.showRefCode){
      this.showRefCode = false
    } else {
      this.showRefCode = true
    }
  }
}

<div class="bg5 pt-3 pb-3">

  <div class="container" *ngIf="!notFound">

    <!-- Breadcrumb -->
    <div class="divshbreadcum mt-1 mb-3 mt-sm-1 mb-sm-3 mt-md-3 mb-md-4 mt-lg-3 mb-lg-4 pt-2 pb-2 pe-3 ps-3"  *ngIf="!PageLoading">
      <div class="row">
        <div class="col-sm-7">
          <div class="shbreadcum d-none d-sm-block">
            <table>
              <tr>
                <td class="separator">
                  <h6 class="pt-2 mt-1 me-3">
                    <strong>
                      <a href="/" class="c1 pointer">
                        <img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back
                      </a>
                    </strong>
                  </h6>
                </td>
                <td>
                  <h6 class="mb-1 mt-2 ms-3">
                    <strong class="c1">
                      <a class="c1 pointer" href="/vendor/rfq-board">RFQ Board</a> > <font class="text-muted">Detail RFQ
                      </font>
                    </strong>
                  </h6>
                </td>
              </tr>
            </table>
          </div>
          
          <div class="shbreadcum d-block d-sm-none">
            <table>
              <tr>
                <td>
                  <p class="mb-0">
                    <strong class="c1">
                      <a class="c1 pointer" href="/vendor/rfq-board">RFQ Board</a> > <font class="text-muted">Detail RFQ
                      </font>
                    </strong>
                  </p>
                </td>
              </tr>
            </table>
          </div>


        </div>
        <div class="col-sm-5">
        </div>
      </div>
    </div>





    <div class="mt-3">

      <!-- Saat page loading -->
      <div *ngIf="PageLoading">
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
              style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>
      </div>

      <!-- Saat page tidak loading -->
      <div class="pb-1" *ngIf="!PageLoading">

        <!-- Jika RFQ tidak ditemukan  -->
        <div *ngIf="DataRfq?.length == 0" class="empty-product">
          <div class="bg-secondary bg-gradient rounded-3 pt-5 pb-5 mb-5 text-center">
            <h1 class="text-light bi bi-file-earmark-ruled" style="font-size: 80px;"></h1>
            <h6 class="text-light">RFQ ID Not Found.</h6>
          </div>
        </div>

        <!-- Jika ada RFQ -->
        <div class="mb-4" *ngIf="DataRfq != []">
          <!-- RFQ Summary -->


          <!-- <ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
            <app-board [rfq]="rfqboard"></app-board>
          </ng-container> -->


          <!-- RFQ Detail -->
          <div class="card divrfqlist mt-4">
            <!-- <div class="card-header bg1">
              <div class="row c3">
                <div class="col-12 text-start ps-2 ps-sm-4 ps-md-4 ps-lg-4 py-md-2"><h4 class="m-0 fw-bold">Detail RFQ</h4></div>
              </div>
            </div> -->
            <div class="card-body">
              <!-- Detail -->
              <div class="row d-none">
                <div class="col-sm-12 px-3 px-sm-3 px-md-5 px-lg-5">
                  <div class="row mt-2 mt-sm-2 mt-md-4 mt-lg-4">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">Vendor Category</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-9 col-lg-9 text-start">
                      <span *ngFor="let item of DataRfq.rfqVendorCategory"
                        class="badge rounded-pill bg-category text-dark me-2 mb-2">{{item.category}}</span>
                      {{DataRfq.rfqVendorCategory.length==0?'-':''}}
                    </div>
                  </div>
                  <div class="row mt-1 mt-sm-1 mt-md-3 mt-lg-3">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">Ship to</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.companyLocation?.city}}, {{DataRfq.companyLocation?.country}}</div>
                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 pt-2 pt-sm-2 pt-md-0 pt-lg-0"></div>
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">Currency</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.currency}}</div>
                  </div>
                  <div class="row mt-2 mt-sm-2 mt-md-4 mt-lg-4">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">Payment Term</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.paymentTermRequestedInDays}}
                      {{DataRfq.paymentTermRequestedInDays > 1 ? "days" : "day"}}</div>
                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 pt-2 pt-sm-2 pt-md-0 pt-lg-0"></div>
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">Internal Ref. No</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.procurementRefNumber ? DataRfq.procurementRefNumber:'-'}}</div>
                  </div>
                  <div class="row mt-2 mt-sm-2 mt-md-4 mt-lg-4">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">ETA Requested</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.etaRequested | date:'d MMM y'}}</div>
                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 {{DataRfq.isForFullQuote?'pt-2 pt-sm-2 pt-md-0 pt-lg-0':''}}  "></div>
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 text-start text-success  fw-bold"  *ngIf="!isMobile">
                      {{DataRfq.isForFullQuote ? "Only Full Quotes Accepted" : ""}}</div>
                  </div>
                  <div class="row mt-2 mt-sm-2 mt-md-4 mt-lg-4">
                    <div class="col-4 col-sm-4 col-md-2 col-lg-2 text-start fw-bold">RFQ Valid Until</div>
                    <div class="col-1 text-start">:</div>
                    <div class="col-7 col-sm-7 col-md-2 col-lg-2 text-start">{{DataRfq.submitQuotationLastDate | date:'d MMM y'}}</div>
                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 pt-2 pt-sm-2 pt-md-0 pt-lg-0"></div>
                    <div class="col-12 col-sm-12 col-md-5 col-lg-5 text-start text-success  fw-bold" *ngIf="!isMobile">
                      {{DataRfq.isIndentAccepted ? "Indent (Backorder) Accepted" : ""}}</div>
                  </div>
                  <div class="row mt-2 mt-sm-2 mt-md-4 mt-lg-4" *ngIf="isMobile">
                    <div class="col-12 text-start text-success  fw-bold">
                      {{DataRfq.isForFullQuote ? "Only Full Quotes Accepted" : ""}}</div>
                    <div class="col-12 text-start text-success  fw-bold">
                      {{DataRfq.isIndentAccepted ? "Indent (Backorder) Accepted" : ""}}</div>
                  </div>
                  

                </div>
              </div>
              <!-- Horizontal Line -->
              <div class="col-sm-12 px-0 px-sm-0 px-md-4 px-lg-4 py-3  my-0 my-sm-0 my-md-4 my-lg-4 d-none">
                <hr class="mt-0 mb-0">
              </div>
              <div class="col-sm-12">
                <div class="my-1 my-sm-3"></div>
              </div>

              <!-- List of Product -->
              <div class="row divShare">
                <!-- <div class="col-sm-12 px-3 px-sm-3 px-md-5 px-lg-5 mb-3">
                  <label class="" for="listproduct"><strong>List of Product / Service</strong></label>
                </div> -->
                <div class="col-sm-12 px-3 px-sm-3 px-md-5 px-lg-5 pt-0 pb-0">
                  <div class="row">
                    <div class="col-lg-4 text-center">
                      <div class="d-flex align-items-center justify-content-center {{limitItems? 'h-100':''}}">
                        <img class="img-fluid p-md-4 imgShare mb-lg-5" src="/assets/images/ic-procurement-5-1@2x.png">
                      </div>
                      
                    </div>
                    <div class="col-lg-8 masked mb-5">
                      <div class="mb-3 mt-4 mt-lg-0 text-center">
                        <h2 class="fh3 fw-bold">Apakah Anda menjual produk yang tertera di RFQ ini?</h2>
                        <h5 class="fh3 fw-bold">Ada permintaan RFQ menunggu penawaran Anda. Login atau Register untuk melakukan penawaran.</h5>
                      </div>
                      <div class="position-relative">

                        <div class="table-responsive divItems p-2 p-sm-4">
                          <h4 class="fh3 fw-bold">RFQ # {{  formatDisplayId(DataRfq?.rfqDisplayId) }}</h4>
                          <table class="table table-borderless share-table">
                            <!-- <thead>
                              <tr>
                                <th>No.</th>
                                <th>List of Product/Service</th>
                                <th class="text-center" width="30%">QTY Requested</th>
                              </tr>
                            </thead> -->
                            <tbody>
                              <ng-container  *ngFor="let product of productList; let i = index">
                              <tr *ngIf="i < (limitItems? (maxShownItem+1) : 9999)">
                                <td><b>{{i+1}}</b></td>
                                <td>
                                  <!-- <strong>{{product.brand}}</strong> {{product.brand?':':''}}
                                  <ng-container *ngIf="isMobile">
                                    {{divDetailspecLessMobile(product.description)}}
                                  </ng-container>
                                  <ng-container *ngIf="!isMobile">
                                    {{divDetailspecLess(product.description)}}
                                  </ng-container>      -->
                                  
                                  <div class="ellipsis-container">
                                    <strong>{{product.brand}}</strong> {{product.brand?':':''}} 
                                    {{ divDetailspec(product.description) }}
                                  </div>
  
                                </td>
                                <td class="divUnit">
                                  <table class="w-100">
                                    <tr>
                                      <td class="text-end ps-2" width="70%" style="border-left: 2px solid #212529;">{{product.qtyNeeded}}</td>
                                      <td class="px-2">{{product.itemUnit}}</td>
                                    </tr>
                                  </table>
                                  
                                </td>
                              </tr>
                              </ng-container>
  
                              <ng-container *ngIf="productList.length> maxShownItem">
                                <tr>
                                  <td colspan="3" class="text-center p-0">
                                    <a class="pointer" (click)="expandItems()">
                                      <h4 class="fw-bold bi bi-chevron-double-{{limitItems?'down':'up'}} fhcyan p-0 m-0"></h4>
                                    </a>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
  
                        <div class="divShareBtn w-100">
                          <div class="text-center">
                            <a class="shareBtn px-3 py-2" href="/vendor/rfq-board">Lihat Lebih Banyak RFQ <i class="bi bi-chevron-right"></i></a>
                          </div>
                        </div>
                        
                      </div>


                    </div>
                    
                    <!-- <div class="col position-absolute  bottom-0 start-50 translate-middle-x divnotif">

                      <div class="">
                        <div class="notif p-5 text-center">
                            <p><strong>Full access to RFQ data is only available for logged-in users. Please Login or Register to continue.</strong></p>
                            <a href="/vendor/rfq-not-participating-in/detail/{{rfqId}}" class="btn btn-primary bg1 fhbtn1t me-2 pointer">Login</a> or <a href="/regis" class="btn btn-primary bg1 fhbtn1 ms-2 pointer">Register</a>
                        </div>
                      </div>

                    </div> -->
                  </div>

                </div>
                <div class="col-sm-12">

                  <div class="ctaShare m-2">
                    <div class="row">
                      <div class="col-12">
                        <h4 class="fh3 fw-bold">Ingin optimalkan peluang bisnis?</h4>
                      </div>
                      <div class="col-sm-9">
                        <p class="mb-0 fw-bold text-black">
                          Terhubung dengan Procurement menjadi lebih mudah dengan <a href="/" class="fhcyan">Cepetdapet</a>. Daftar sekarang untuk melihat lebih banyak RFQ, kirimkan penawaran, dan perluas jaringan bisnis Anda!
                        </p>
                      </div>
                      <div class="col-sm-3 py-2">
                        <a class="shareBtn px-3 py-2 w-100 my-2 btn w-100" href="/regis">Daftar Sekarang</a>
                      </div>
                    </div>
                    

                  </div>
                  
                </div>
                <div class="col-sm-12 px-3 px-sm-3 px-md-5 px-lg-5 pt-0 pb-0 d-none">
                  <div class="mb-3 row">
                    <div class="container">
                      <ul class="list-group rounded-0">
                        <li class="list-group-item bg1 p-0 mb-3 rounded shadow border-0 b1 hvshadow"
                          *ngFor="let product of productList; let i = index">
                          <div class="row m-0 p-0 pointer" (click)="AccordionHideShow(i)">
                            <div class="col-1 p-2 ps-3 fw-bold text-white">{{i+1}}</div>
                            <div class="col-10 p-2 text-center text-white"><strong>{{product.brand}}</strong> {{product.brand?':':''}}
                              <ng-container *ngIf="isMobile">
                                {{divDetailspecLessMobile(product.description)}}
                              </ng-container>
                              <ng-container *ngIf="!isMobile">
                                {{divDetailspecLess(product.description)}}
                              </ng-container>
                              
                            </div>
                            <div class="col-1 p-2 text-end fw-bold text-white">
                              <img
                                src="/assets/images/icons/{{ (product.collapse) ? 'ic_Dup@2x.png':'ic_Ddown@2x.png' }}"
                                class="ps-2 pe-2 icondropdown">
                            </div>
                          </div>
                          <!-- Expanded Content -->
                          <div
                            class="bg-white m-0 px-3 px-sm-3 px-md-5 px-lg-5 py-2 py-sm-2 py-md-4 pt-lg-4 rounded-bottom row {{ (product.collapse) ? '':'d-none' }} ">
                            <div class="col-sm-12 p-2 p-sm-2 p-md-4 p-lg-4 ms-0 mb-4 product-detail pt-lg-1 pt-md-1 pt-sm-1 pt-1">



                              <div class="row">
                                <div class="col-12 text-end">
                                  <app-share *ngIf="product.description" shareType="rfq" shareData="{{DataRfq | json}}" shareDetail="{{product | json}}" shareTitle="{{product.description}}" shareUrl="{{currentUrl}}"></app-share>
                                </div>

                                <div class="col-12 col-sm-2 text-center">


                                  <lightgallery *ngIf="product?.fileName.length>0" [settings]="settingsGallery" id="previewGallery" [onInit]="onInitGallery" class="row g-0">


                                    <a *ngFor="let item of product?.fileName; let i = index" class="text-center {{i==0?'col-12':'col-4'}}" [attr.href]="item">
                                      <div style="  display: flex;
                                      align-items: center;
                                      justify-content: space-between;">
                                        <i *ngIf="i==0 && product?.fileName.length>1" class="bi bi-chevron-left text-black-50" style="
                                            position: absolute;
                                        "></i>
                                        <div class="text-center w-100">
                                          <img *ngIf="i==0" class="img-fluid p-1" [src]="item" onError="this.src='/assets/images/notfound.png'" />
                                        </div>
                                        
                                        <i *ngIf="i==0 && product?.fileName.length>1" class="bi bi-chevron-right text-black-50" style="
                                            position: relative;
                                            right: 15px;
                                        "></i>
                                      </div>

                                    </a>

                                  </lightgallery>
                                  <img *ngIf="product?.fileName.length==0" class="img-fluid p-1" src="/assets/images/notfound.png"  />


                                </div>
                                <div class="col-12 col-sm-10">
                                  <div class="row">

                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold f14 mb-1">Brand</div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center  f14">:</div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start f14">{{product.brand?product.brand:'-'}}</div>

                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold"></div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center"></div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start"></div>

                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold f14 mb-1">Manf. Prod. No.</div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center  f14">:</div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start f14">{{product.manufacturerSku?product.manufacturerSku:'-'}}</div>

                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold f14 mb-1">QTY Requested</div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center f14">:</div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start f14">{{product.qtyNeeded}} {{product.itemUnit}}</div>


                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold f14 mb-1">Product Desc.</div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center  f14">:</div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start f14">
                                      
                                      <font *ngIf="!(divDetailspecLess(product.description).length>50)">{{ divDetailspecLess(product.description) }}</font>

                                      <div *ngIf="arrMoreLessDesc[i]=='less' && divDetailspecLess(product.description).length>50">
                                        {{ divDetailspecLess(product.description) }}
                                        <strong><span class="c1 pointer" (click)="MoreLessDesc(i,'more')">Read More</span></strong>
                                      </div>
                                      <div *ngIf="arrMoreLessDesc[i]=='more' && divDetailspecLess(product.description).length>50">
                                        {{ divDetailspec(product.description) }}
                                        <strong><span class="c1 pointer" (click)="MoreLessDesc(i,'less')">Read Less</span></strong>                   
                                      </div>                                  
                                      
                                    </div>

                                    <div class="col-5 col-sm-5 col-md-2 col-lg-2 text-start fw-bold f14 mb-1">Specification</div>
                                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 text-center f14">:</div>
                                    <div class="col-5 col-sm-5 col-md-3 col-lg-3 text-start f14">
                                      <div [innerHTML]="divspec(product.description)"></div>
                                    </div>

                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fixed Bottom Button -->
              <div class="fixed-bottom d-none">
                <div class="bg-white pt-3 pb-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-6">
                        <a (click)="sendMessage()" class="btn btn-primary fhbtn3 rounded-1 roundedmore ms-3 ps-4 pe-4">Ask Customer</a>
                      </div>
                      <div class="col-6 text-end">
                        <button type="button" class="btn text-white fhbcyan rounded-1 roundedmore ms-3 ps-4 pe-4"
                          data-bs-toggle="modal"
                          data-bs-target="#confirmModal" [disabled]="this.isAlreadyParticipating">{{this.isAlreadyParticipating ? "Participating" : "Participate Now"}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Modal -->
    <div class="modal fade" id="confirmModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0 border-0">
          <div class="modal-header bg1 rounded-0 text-center">
            <h5 class="text-white f1 mb-0"><strong>Participate Confirmation</strong></h5>
          </div>
          <div class="modal-body">
            <!-- <h6 class="text-center mt-4 mb-4">
              Are you sure want to participate?
            </h6> -->
            <h6 class="text-center mt-2 mb-4" [innerHtml]="'rfq.board_detail_confirm' | translate"></h6>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3">
              <div class="row">
                <div class="col-6">
                  <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall"
                  data-bs-dismiss="modal">Cancel</button>
                </div>
                <div class="col-6">
                  <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="participateNow()" data-bs-dismiss="modal">Yes</button>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <!-- <div class="modal" id="successModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displaydone}">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
          <div class="modal-body pt-4 pb-4 ps-4 pe-4">
            <div class="text-center">
              <img class="saveimgicon" src="/assets/images/icons/done-save.png">
            </div>
            <h3 class="text-center mt-3 mb-2 f1" [innerHtml]="'rfq.modal_participate_title' | translate"></h3>
            <p class="text-center mt-0 mb-4 fw-bold" [innerHtml]="'rfq.modal_participate_detail' | translate"></p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
              
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/rfq-participating-in-list"
                data-bs-dismiss="modal" *ngIf="rfqParticipatedId==''">DONE</a>
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/rfq-participating-in/detail/{{rfqParticipatedId}}/null"
                data-bs-dismiss="modal" *ngIf="rfqParticipatedId!==''">DONE</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Error Modal -->
    <div class="modal" id="errorModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayerror}">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
          <div class="modal-body pt-4 pb-4 ps-4 pe-4">
            <div class="text-center">
              <img class="saveimgicon" src="/assets/images/error.png">
            </div>
            <h3 class="text-center mt-3 mb-2 f1">Error!</h3>
            <p class="text-center mt-0 mb-4 fw-bold">{{errortext}}</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="closeError()">CLOSE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="notFound">

    <div class="vh-100">
        <div class="row align-items-center h-100  bg-white">
            <div class="col-md-12">
                <div class="text-center">
                    <img src="/assets/images/404-empty-rfq.png" class="img-fluid">
                </div>
                <div class="text-center">
                    <h3 class="mt-3">RFQ Tidak Tersedia</h3>
                    <p>Sepertinya tautan RFQ telah kedaluwarsa atau dihapus. Silakan <a href="/contact-us">Hubungi Kami</a> atau cek RFQ yang masih aktif.</p>
                    <a class="btn btn-outline-secondary fhbtn1 text-white" href="/"><strong>{{'not_found_btn' | translate}}</strong></a>
                </div>
            </div>
        </div>
    </div>

  </div>
</div>

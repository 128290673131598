import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PopupmessagingComponent } from 'src/app/components/popupmessaging/popupmessaging.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { NavigationService } from 'src/app/_services/navigation';
import { ProductService } from 'src/app/_services/product.services';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.scss']
})
export class VendorDetailComponent implements OnInit {
  VendorDomain:string=''
  prevVendorDomain:string=''
  PageLoading:boolean=false
  PageProductLoading:boolean=false
  isFavourite:boolean=false
  isBlackist:boolean=false
  checkisFavourite:boolean=false //loading
  checkisBlacklist:boolean=false //loading
  loadProducts:boolean=false


  keyword:string='test'
  prevkeyword:string='test'
  offset:number=0
  currentpage:number=1
  totalpage:number =0
  totaldata:number=0
  pagingloading:boolean=false

  BulkLabel:string="Bulk Action"
  first : number = 3
  ValPaging:any = 10
  // PageLoading:boolean=false
  loadingNext:boolean=false
  dataProducts: any
  AlldataProducts : any =[]
  endCursor: any
  hasNextPage: any

  hasPrevPage:any
  arrPrevCursor:any=[]

  VendorId:string=''
  getVendorDomain:string=''
  getVendorName:string=''
  getvendorCity:string=''
  getvendorCountry:string=''

  valSearch:string=''
  valPrevSearch:string=''
  onSearch:boolean=false
  AllSerachProducts : any =[]
  offsetSearch:number=0
  currentpageSearch:number=1
  totalpageSearch:number =0
  totaldataSearch:number=0
  pagingloadingSearch:boolean=false
  endCursorSearch: any
  hasNextPageSearch: any
  hasPrevPageSearch:any
  isLogin:boolean=false

  vendorLogo:string=''

  isPKP:boolean=false

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private popupmodal : Popupmodal,
    private navigation: NavigationService,
    private productService: ProductService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    var getVendorDomain  = this.route.snapshot.paramMap.get('VendorDomain');
    console.log('getVendorDomain',getVendorDomain);
    if(getVendorDomain){
      this.VendorDomain= getVendorDomain
      this.prevVendorDomain = decodeURIComponent(getVendorDomain)

      this.GetLogoCompany(getVendorDomain,'')
    }
    this.getVendorDetail()
    
    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.isLogin = true
    } 

  }

  GetLogoCompany(companydomain:string,vendorId:string){
    this.productService.getVendorLogo(companydomain,vendorId)
    .subscribe((res:any) => {
      console.log(res)
      if(res.data.getVendorLogo){
        this.vendorLogo=res.data.getVendorLogo
      }
    });
  }
    
  getVendorDetail(){
    this.productService.getVendorInfo(this.VendorDomain).subscribe((res:any) => {
      console.log(res)
      if(res.data?.getVendorInfo){
        this.VendorId = res.data?.getVendorInfo.vendorId
        this.getVendorDomain = res.data?.getVendorInfo.vendorDomain
        this.getVendorName = res.data?.getVendorInfo.vendorName
        this.getvendorCity = res.data?.getVendorInfo.vendorCity
        this.getvendorCountry = res.data?.getVendorInfo.vendorCountry
        this.isPKP = res.data?.getVendorInfo.isPKP

        this.getproduct()
        if(this.isLogin){
          this.getisVendorAlreadyFav(this.VendorId)
          this.getisVendorBlackList(this.VendorId)
        }

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,'/').subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }


  getisVendorAlreadyFav(id:string){
    this.productService.getisVendorAlreadyFavourite(id).subscribe((res:any) => {
      console.log(res)
      this.checkisFavourite=false
      if(res.data){
        this.isFavourite = res.data?.isVendorAlreadyFavourite
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }


        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  
  setBlacklist() {
    (this.isBlackist) ? this.remoteBlacklist() : this.addBlacklist()
  }
  getisVendorBlackList(id:string){
    this.productService.getisVendorBlackList(id).subscribe((res:any) => {
      console.log(res)
      this.checkisBlacklist=false
      if(res.data){
        this.isBlackist = res.data?.isVendorBlackList
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }
  addBlacklist(){
    this.checkisBlacklist=true
    this.productService.getaddCompanyToBlacklist('VENDOR',this.VendorId).subscribe((res:any) => {
      console.log(res)
      this.checkisBlacklist=false
      if(res.data?.addCompanyToBlacklist){
        this.isBlackist = true
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }
  remoteBlacklist(){
    this.checkisBlacklist=true
    this.productService.getremoveCompanyFromBlacklist('VENDOR',this.VendorId).subscribe((res:any) => {
      console.log(res)
      this.checkisBlacklist=false
      if(res.data?.removeCompanyFromBlacklist){
        this.isBlackist = false
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
     
  }

  setFavourite() {
    // var setfav = (this.isFavourite) ? false : true
    //this.isFavourite = setfav
    (this.isFavourite) ? this.remoteToFavourite() : this.addToFavourite()
  }

  addToFavourite(){
    this.checkisFavourite=true
    this.productService.getaddCompanyToFavourite('VENDOR',this.VendorId).subscribe((res:any) => {
      console.log(res)
      this.checkisFavourite=false
      if(res.data?.addCompanyToFavourite){
        this.isFavourite = true
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  remoteToFavourite(){
    this.checkisFavourite=true
    this.productService.getremoveCompanyToFavourite('VENDOR',this.VendorId).subscribe((res:any) => {
      console.log(res)
      this.checkisFavourite=false
      if(res.data?.removeCompanyFromFavourite){
        this.isFavourite = false
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
     
  }

  LoadMoreProducts() {
    var load = (this.loadProducts) ? false : true
    this.loadProducts = load
  }
  

  getproduct(){
    this.PageProductLoading=true
    this.pagingloading = true
    this.productService.getVendorProductList(this.VendorId,this.ValPaging,this.offset)
    .subscribe((res:any) => {
      console.log(res)
      this.PageProductLoading=false
      this.pagingloading = false
      if(res.data?.getVendorProductList){
          //console.log("ada data");


          this.AlldataProducts=[]
          var arrProd = res.data.getVendorProductList.productVendorData
          arrProd.forEach((value: any, key: any) => {
            this.AlldataProducts.push(value)
          })

          this.totaldata = res.data.getVendorProductList.totalData
          
          // this.offset = (this.currentpage-1)*this.ValPaging
          
          this.loadPaging()
     
      }
      else if(res.errors){
   
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

      }
      else {
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = 'Please try again later'
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/']);
          } 
        })
      }
      
    });

  }
  
  loadPaging(){
    var gettotal = Math.ceil(this.totaldata/this.ValPaging)
    this.totalpage = gettotal
    console.log('gettotal ',gettotal)
    if(this.currentpage==gettotal){
      this.hasNextPage=false
    } else {
      this.hasNextPage=true
    }

    if(this.currentpage==1){
      this.hasPrevPage=false
    } else {
      this.hasPrevPage=true
    }

    if(gettotal==0){
      this.hasNextPage=false
    }
    console.log('if',(this.totalpage==this.currentpage))
    if(this.totalpage==this.currentpage){
      this.hasNextPage=false
    }
  }

  loadNext(){
    this.currentpage = this.currentpage+1

    this.offset = (this.currentpage-1)*this.ValPaging
    console.log('loadNext offset ',this.offset)
    console.log('loadNext currentpage ',this.currentpage)

    this.getproduct()
  }

  loadPrev(){
    this.currentpage = this.currentpage-1

    console.log('currentpage ',this.currentpage)
    this.offset = (this.currentpage-1)*this.ValPaging
    console.log('loadPrev offset ',this.offset)
    console.log('loadPrev currentpage ',this.currentpage)

    this.getproduct()

  }

  productPerPage(val: any){
    this.currentpage = 1
    this.offset = 0
    this.ValPaging=val
    
    
    if(this.onSearch){
      this.currentpageSearch = 1
      this.offsetSearch = 0
      this.searchProduct()
    } else {
      this.getproduct()
    }
  }

  searchProduct(){
    if(this.valSearch==''){
      this.onSearch=false
      return
    }
    this.valPrevSearch = this.valSearch
    this.onSearch=true


    // AllSerachProducts : any =[]
    // offsetSearch:number=0
    // currentpageSearch:number=1
    // totalpageSearch:number =0
    // totaldataSearch:number=0
    // pagingloadingSearch:boolean=false

    this.pagingloadingSearch = true

    //vendorId:any,keyword:string, limit:any, offset:any

    this.PageProductLoading=true
    this.pagingloading = true

    this.productService.getsearchVendorProductList(this.VendorId,this.valPrevSearch,this.ValPaging,this.offsetSearch).subscribe((res:any) => {
      console.log(res)

      this.PageProductLoading=false
      this.pagingloading = false


      this.pagingloadingSearch=false
      if(res.data?.searchVendorProductList){
        this.AllSerachProducts=[]
        var arrProd = res.data.searchVendorProductList.productVendorData
        arrProd.forEach((value: any, key: any) => {
          this.AllSerachProducts.push(value)
        })
        this.totaldataSearch = res.data.searchVendorProductList.totalData
        this.loadPagingSearch()
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });

  }

  resetProduct(){
    this.onSearch=false
    this.offset=0
    this.getproduct()

    //reset search
    this.AllSerachProducts=[]
    this.offsetSearch=0
    this.currentpageSearch=1
    this.totalpageSearch =0
    this.totaldataSearch=0
    this.pagingloadingSearch=false
    this.endCursorSearch=null
    this.hasNextPageSearch=null
    this.hasPrevPageSearch=null

  }


  loadPagingSearch(){
    var gettotal = Math.ceil(this.totaldataSearch/this.ValPaging)
    this.totalpage = gettotal
    console.log('gettotal ',gettotal)
    if(this.currentpageSearch==gettotal){
      this.hasNextPageSearch=false
    } else {
      this.hasNextPageSearch=true
    }

    if(this.currentpageSearch==1){
      this.hasPrevPageSearch=false
    } else {
      this.hasPrevPageSearch=true
    }

    if(gettotal==0){
      this.hasNextPageSearch=false
    }
    console.log('if',(this.totalpageSearch==this.currentpageSearch))
    if(this.totalpageSearch==this.currentpageSearch){
      this.hasNextPageSearch=false
    }
  }

  loadNextSearch(){
    this.currentpageSearch = this.currentpageSearch+1

    this.offsetSearch = (this.currentpageSearch-1)*this.ValPaging
    console.log('loadNext offsetSearch ',this.offsetSearch)
    console.log('loadNext currentpageSearch ',this.currentpageSearch)

    this.searchProduct()
  }

  loadPrevSearch(){
    this.currentpageSearch = this.currentpageSearch-1

    console.log('currentpageSearch ',this.currentpageSearch)
    this.offsetSearch = (this.currentpageSearch-1)*this.ValPaging
    console.log('loadPrev offsetSearch ',this.offsetSearch)
    console.log('loadPrev currentpageSearch ',this.currentpageSearch)

    this.searchProduct()

  }

  sendMessage(vendorId:string){
    if(!this.isLogin){
      var errortext = "Please login first"
      this.popupmodal.showError(errortext,'/login').subscribe((res:any) => {})
      return
    }


    // alert('send message from product..')
    const modalRef = this.modalService.open(PopupmessagingComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'md'
    })


    modalRef.componentInstance.title = 'Ask Vendor'
    modalRef.componentInstance.sendMessageAs = 'LEADS'
    modalRef.componentInstance.rfqid = vendorId
    modalRef.componentInstance.quotationId = null
    
  }


  resetFilter(key:string){
    if(key =='search'){
      this.valSearch=''
      this.valPrevSearch=''
    }
    this.resetProduct()
    this.getproduct()
    this.onSearch=false

  }

  back(): void {
    this.navigation.back()
  }
}

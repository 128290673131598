import { Component, HostListener, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import Util from 'src/app/_helpers/util';
import { NavigationService } from 'src/app/_services/navigation';
import { ProductService } from 'src/app/_services/product.services';
import { LightGallery } from 'lightgallery/lightgallery';
import { Meta, Title } from '@angular/platform-browser';
import { PopupmessagingComponent } from 'src/app/components/popupmessaging/popupmessaging.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { OthersService } from 'src/app/_services/others.service';
import { PopupcartComponent } from 'src/app/components/popupcart/popupcart.component';
// import { MetadataService } from 'src/app/metadata.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  dataShopSide:any
  companyName:string=''
  PageLoading:boolean=false
  resdata:any
  keyword:string=''
  prevkeyword:string=''
  companydomain:string=''
  prevcompanydomain:string=''
  companyId:string=''
  prevcompanyId:string=''
  isLogin:boolean=false
  vendorName:string=''
  vendorId:string=''
  vendorLocation:string=''

  lightboxImg:any = [];
  private lightGallery!: LightGallery;
  private needRefresh = false;

  vendorLogo:string=''
  currentUrl:string=''

  public getScreenWidth: any;
  isMobile:boolean=false
  isPKP:any
  isCompanySetPKP:boolean=false

  shareData:any
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthService,
    private navigation: NavigationService,
    private productService: ProductService,
    private othersService: OthersService,
    private meta: Meta,
    private titleService: Title,
    private popupmodal : Popupmodal,
    private translate: TranslateService
    // @Optional() private metadataService: MetadataService
  ) { }

  ngOnInit(): void {
    var getketword  = this.route.snapshot.paramMap.get('keyword');
    console.log('getketword',getketword);
    if(getketword){
      if(getketword.split('?')[0]){
        console.log('getketword ', getketword.split('?')[0])
        getketword = getketword.split('?')[0];
      }
      this.keyword= getketword
      this.prevkeyword = decodeURIComponent(getketword)
    }

    var getdomain  = this.route.snapshot.paramMap.get('companydomain');
    var getcompanyId  = this.route.snapshot.paramMap.get('getcompanyId');
    console.log('getdomain',getdomain);
    console.log('getcompanyId',getcompanyId);
    if(getdomain){
      this.companydomain= getdomain
      this.prevcompanydomain = decodeURIComponent(getdomain)


      this.GetLogoCompany(getdomain,'')
      this.getVendorDetail(getdomain)
    } 
    else if(getcompanyId) {
      this.companyId= getcompanyId
      this.prevcompanyId = decodeURIComponent(getcompanyId)
      this.vendorId=getcompanyId
    }
    



    this.getsearch()

    //if it is login
    // console.log('isLoggedIn',this.authService.getJwtToken())
    // if(this.authService.getJwtToken()){
    //   this.isLogin = true
    // }

    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.isLogin = true
    } 


    this.currentUrl = window.location.href;

    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  settingsGallery = {
    counter: true,
    download:false,
  };
  settingsGalleryMobile = {
    counter: true,
    download:false,
    selector: '.itemz'
  };
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


  divspec(val:any){
    return Util.fhspectocomma(val)
  }
  divshotdesc(val:any){
    return Util.formatShortDesc(val)
  }

  GetLogoCompany(companydomain:string,vendorId:string){
    this.productService.getVendorLogo(companydomain,vendorId)
    .subscribe((res:any) => {
      console.log(res)
      if(res.data.getVendorLogo){
        this.vendorLogo=res.data.getVendorLogo
      }
    });
  }

  getsearch(){
    this.PageLoading=true
    var param:any=null
    if(this.companydomain){
      param={'companyDomain': this.companydomain}
    } 
    else {
      param={'companyId': this.companyId}
    }
    this.productService.getglobalSearchDetail(param,this.keyword)
    .subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data.companyProductGlobalView){
          //console.log("ada data");
          this.resdata = res.data.companyProductGlobalView.companyProduct
          this.vendorName = res.data.companyProductGlobalView.vendorName
          //this.vendorId = res.data.companyProductGlobalView.vendorId

      
          var ArrImg = res.data.companyProductGlobalView.companyProduct?.imageUrl

          var idGallery = 0
          ArrImg.forEach((v:string,k:number) => {
            var src = v;
            var thumb = v;

            this.itemsGallery = [
              ...this.itemsGallery,
              {
                id: idGallery,
                src: src,
                thumb: thumb,
              }
            ];
            this.needRefresh = true;
            idGallery++

          });
      
          this.shareData = {'id':res.data.companyProductGlobalView.companyProduct?.id,'rfqDisplayId':''}

          //Set Open Graph


          this.titleService.setTitle(''+this.resdata?.name+' | CepetDapet')

          this.meta.updateTag({property: 'og:title', content: ''+this.resdata?.name+' | CepetDapet.com'});
          // this.meta.updateTag({property: 'og:image', content: 'image'});
          this.meta.updateTag({property: 'og:url', content: window.location.href});
          // this.meta.updateTag({property: 'og:description', content: 'desc'});

          //Set Twitter Share
          this.meta.updateTag({name: 'twitter:title', content: ''+this.resdata?.name+' | CepetDapet.com'});
          // this.meta.updateTag({name: 'twitter:image', content: 'image twitter'});
          this.meta.updateTag({name: 'twitter:url', content: window.location.href});
          //this.meta.updateTag({name: 'twitter:description', content: 'desc twitter'});
    

          // if (this.metadataService) {
          //   this.metadataService.updateMetadata({
          //     title: this.resdata?.name,
          //     description: 'Cepetdapet mencarikan vendor yang menyediakan produk yang Anda cari!',
          //     imageRelativeUrl: (ArrImg[0]) ? ArrImg[0] : 'https://cepetdapet.com/assets/images/Screenshot.png',
          //   });
          // }
     
      }
      else if(res.errors){
   
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

      }
      else {
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = 'Please try again later'
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/']);
          } 
        })
      }
      
    });

  }

  redirect(){
    // this.router.navigate(['/vendor-detail/'+domain]);
    if(this.companydomain){
      this.router.navigate(['/vendor-detail/'+this.companydomain]);
    } 
    // else if(this.companyId) {
    //   this.router.navigate(['/vendor-detail/'+this.companyId]);
    // }

  }

  back(): void {
    this.navigation.back()
  }

  getVendorDetail(domainName:string){
    this.productService.getVendorInfo(domainName).subscribe((res:any) => {
      console.log(res)
      if(res.data?.getVendorInfo){
        this.vendorId = res.data?.getVendorInfo.vendorId
        console.log('res.data?.getVendorInfo.vendorId : ',res.data?.getVendorInfo.vendorId)

        this.vendorLocation= ( res.data?.getVendorInfo.vendorCity ? res.data?.getVendorInfo.vendorCity+', ' : '' ) + res.data?.getVendorInfo.vendorCountry

        this.isPKP = res.data?.getVendorInfo.isPKP
        
        if(this.isPKP===true || this.isPKP===false){
          this.isCompanySetPKP = true
        } 
        
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,'/').subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  sendMessage(vendorId:string){
    console.log('vendorId : ',vendorId)
    console.log('this.vendorId : ',this.vendorId)
    console.log('this.vendorName : ',this.vendorName)
    if(!this.isLogin){
      var errortext = "Please login first"
      this.popupmodal.showError(errortext,'/login').subscribe((res:any) => {})
      return
    }


    // alert('send message from product..')
    const modalRef = this.modalService.open(PopupmessagingComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'md'
    })


    modalRef.componentInstance.title = 'Ask Vendor'
    modalRef.componentInstance.sendMessageAs = 'LEADS'
    modalRef.componentInstance.rfqid = vendorId
    modalRef.componentInstance.quotationId = null
    
  }

  addToCart(){
    var productId = this.resdata?.id

    this.othersService.postaddProductToCart(productId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.addProductToCart){

        const modalRef = this.modalService.open(PopupcartComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          size: 'md'
        })
        // modalRef.componentInstance.modalconfirm = 'modal'
        // modalRef.componentInstance.title = 'Confirm Bulk Delete'
        // modalRef.componentInstance.subtitle = 'Are you sure want to bulk delete?'
        // modalRef.componentInstance.yesStr = 'Confirm'
        // modalRef.componentInstance.noStr = 'Cancel'
    
        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res) {  
            this.router.navigate(['/cart']);  
          }
        })



      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });


          if(res.errors[0].extensions.type=='ProductAlreadyInCart'){

            const modalRef = this.modalService.open(PopupcartComponent, {
              modalDialogClass: 'modal-dialog-centered',
              centered: true,
              size: 'md'
            })

            modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
              if (res) {   
                this.router.navigate(['/cart']);  
              }
            })            
            
            return
          }

          
          if(res.errors[0].extensions.type=='CanNotAddOwnProductToCart'){
            this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        
            
            return
          }


          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,'/').subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });

  }

}

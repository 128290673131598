import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';


const mutationSubmitResetPassword = gql`
mutation resetPassword($resetPasswordToken:String!,$newPassword:String!){
  resetPassword(input: {
      resetPasswordToken:$resetPasswordToken
      newPassword: $newPassword
  })
}
`;


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  verificationToken:any;
  btnloading:boolean=false;
  submitted = false;
  regID:string=''
  state:string='loading' // loading, expired, submitdata, success

  //model
  ValPassword:string=''
  ValRetypePassword:string=''
  Valemail:any


  detailinfoForm = this.formBuilder.group({
    password: ['', Validators.required],
    retypepassword: ['', Validators.required],
  }
  , {
    validator: MustMatch('password', 'retypepassword')
  }
  );

  errortext:string=''

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.forceLogout();
    this.verificationToken = this.route.snapshot.paramMap.get('token');
    //this.Valemail = this.route.snapshot.paramMap.get('email');
    //this.pageloading=true;
    this.state = 'loading';

    if(this.verificationToken.length>32){
      this.state = 'submitdata'
    } else {
      this.router.navigate(['']);
    }
  }

  get f() { return this.detailinfoForm?.controls; }

  onSubmit() {
    this.submitted = true;
    console.log(this.detailinfoForm?.value);
    console.log(this.detailinfoForm);
    // stop here if form is invalid
    if (this.detailinfoForm.invalid) {
        return;
    }

    this.btnloading=true;

    let ArrData:any
    ArrData ={
      resetPasswordToken: this.verificationToken,
      newPassword: this.ValPassword,
      // email: this.Valemail
    }

    //this.state ='expired'
    //this.state ='success'
    //return

    this.apollo
    .mutate({
      mutation: mutationSubmitResetPassword,
      variables: ArrData
    })
    .subscribe(
      (res:any) => {
        console.log("succes, get data:", res);
        this.state ='success'
      },
      error => {
        console.log('error',error);
        var errMsg = error + '';
        var res = errMsg.split(":");
        var resMsg = res[res.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        this.state ='expired'
        this.errortext = msg
      }
    );



  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {gql, Apollo} from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';	
import { onError } from "apollo-link-error";
// import 'tinymce/icons/default';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const getTags = gql`
query{
  getCompanyTagList{
      id
      tag
      isActive
  }
}
`;

const postApproval = gql`
mutation changeCompanyProductsApproval($action:approvalAction!,$productsId:[String!]){
  changeCompanyProductsApproval(input: {
      action: $action
      productsId: $productsId
  })
}
`;

const postPublish = gql`
mutation publishProducts($productsId:[String!]){
  publishProducts(input: {
      productsId: $productsId
  })
}
`;



const deleteProducts = gql`
mutation deleteCompanyProducts($productsId: [ID!]){
  deleteCompanyProducts(input: {
      productsId: $productsId
  })
}
`;

const getProductDetail = gql`
query companyProduct($productId: ID!){
  companyProduct(input: $productId){
      id
      name
      brand
      description
      sku
      manufacturerSku
      price
      imageUrl
      tags{
          id
          tag
      }
      isDisplayPriceOnShopfront
      isDisplayProductOnShopfront
      quotationInitialPriceOffer
      autobidMinimumPrice
      autobidPriceDecrement
      isAutobidEnabled

      state    
      publishApprovalStatus
      publishedOngoingApprovalStatus
      isActivatedByUser
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;

@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss']
})
export class ProcurementProductsDetailComponent implements OnInit {
  pageloading: boolean = false;
  submitted = false;
  tags:any=[]
  SelectedTags:any=[]
  imageSrc: string=''
  imageSrcMore:any=[]

  successResponse:boolean=false;
  successResponseName:string=''

  MoreImgCount = new Array(3);
  MoreImgArr:any = []
  CombineImg:any = []

  checkPriceDecCalculate:boolean=false;
  checkDiscIncCalculate:boolean=false;

  displaydelete='none';
  
  displaydraft='none'
  displaydone='none'
  displaypublish='none'

  divFhSpec :any=[] // <fhspecdiv></fhspecdiv>
  countarrFhSpec:any =[]

  valspecLabel=''
  valspecValue=''

  emptyLabelSpec : any = []
  emptyValueSpec : any = []

  ValSpecEmpty:boolean=false


  actionbtn:any='' //draft , preview, done

  StatusApproval:number=1 // 1->OPEN , 2->REQUESTED, 4->APPROVED  
  LoadingreqeustApproval:boolean=false

  CanApproveReject:boolean=false

  LoadingreqeustPublish:boolean=false

  displayApproveReject='none'

  productForm = this.formBuilder.group({
    name: ['', Validators.required],
    brand: [''],
    // description: ['', Validators.required],
    description: [''],
    sku: [''],
    manufacturerSku: [''],
    price: ['', Validators.required],
    // imageFileName: [''],
    isDisplayPriceOnShopfront:  [''],
    isDisplayProductOnShopfront: [''],
    tags: [''],
    quotationInitialPriceOffer: [''],
    autobidMinimumPrice: [''],
    autobidPriceDecrement: [''],
    isAutobidEnabled: [''],
    isActivatedByUser: [''],
    primaryImg: [''],
    tinymce: ['', Validators.required],
  });


  //model
  Valname:string=''
  Valbrand:string=''
  Valdescription:string=''
  Valsku:string=''
  ValmanufacturerSku:string=''
  Valprice:string=''
  ValimageFileName:string=''
  ValisDisplayPriceOnShopfront:boolean=true
  ValisDisplayProductOnShopfront:boolean=false
  Valtags:any=''
  ValquotationInitialPriceOffer:string=''
  ValautobidMinimumPrice:string=''
  ValautobidPriceDecrement:string=''
  ValisAutobidEnabled:boolean=false
  ValisActivatedByUser:boolean=false

  Valstate:string=''

  ValprimaryImg:string=''
  FilenamePirmaryImg:string=''
  ValimageMore:any=[]

  EditProductId:any
  PassData:any
  valspecValueErr: boolean=false;
  valspecLabelErr:boolean=false;
  //EditTags:any
  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.EditProductId = this.route.snapshot.paramMap.get('ProductId');
    console.log('EditProductId',this.EditProductId);
    if(this.EditProductId !==''){
      this.getDataEdit(this.EditProductId)
    }
    
    this.getTag()
    
    for (let i = 0; i < this.MoreImgCount.length; i++) {
      this.imageSrcMore.push({'id':i,'val':'','name':''})
    }
  }

  get f() { return this.productForm?.controls; }

  getUserStatus(){
    var usercompany = localStorage.getItem("USERCOMPANY")
    console.log('usercompany --- ',usercompany);
    //check if owner, show approve or reject

    if(usercompany){
      var arrUserCompany = JSON.parse(usercompany)
      console.log(arrUserCompany.roleMembership)
     //roleMembership
      // if (1,2,3) //1,2,4 can approve / reject
      if(arrUserCompany.roleMembership==1 || arrUserCompany.roleMembership==2 || arrUserCompany.roleMembership==4){
        this.CanApproveReject=true
      }

    }
    


  }

  fieldsToModel(fields: Array<any>, output: string): string {

    for (const field in fields) {

      if (typeof fields[field] !== 'object') {
        output += fields[field].toString() + ' ';
      }
      

      // if (typeof fields[field] !== 'object') {
      //   output += fields[field].toString() + ',';
      // } else {
      //   if (Object.keys(fields[field])[0] !== undefined) {
      //     output += Object.keys(fields[field])[0] + '{' + this.fieldsToModel(fields[field][Object.keys(fields[field])[0]], '') + '},';
      //   }
      // }
    }
  
    return output;
  }


  getDataEdit(ProductId: any){

    this.apollo.watchQuery<any>({
      query: getProductDetail,
      variables: {
        productId: ProductId,
      },
    })
    .valueChanges
    .subscribe(({data}) => {
      // this.tags = data.getCompanyTagList;
      console.log('get prodct detail ',data.companyProduct);

      this.Valname = data.companyProduct.name
      this.Valbrand = data.companyProduct.brand

      // seperate desc
      this.Valdescription = data.companyProduct.description

      this.divFhSpec = []
      this.Valdescription = ''
      var desctext = data.companyProduct.description;
      
      var a = data.companyProduct.description;
      var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
      console.log('xxxx>>',getdetaildesc);
      if(getdetaildesc){
        console.log('>>>>>>>',getdetaildesc[0]);
        console.log('>>>>>>>',getdetaildesc[1]);

        this.divFhSpec = getdetaildesc[1]
        this.divFhSpec = JSON.parse(this.divFhSpec)
        //this.Valdescription = desctext.split(getdetaildesc[0]).pop()
        this.Valdescription = desctext.replace(getdetaildesc[0],'')

        console.log('>>>>>>>',desctext);
      } else {
        this.Valdescription = data.companyProduct.description;
      }

      console.log('divFhSpec',this.divFhSpec);
      console.log('Valdescription',this.Valdescription);
      // // this.fhspecdiv =
      // // this.Valdescription =

      //this.Valdescription = data.companyProduct.description
      this.Valsku = data.companyProduct.sku
      this.ValmanufacturerSku = data.companyProduct.manufacturerSku
      this.Valprice = data.companyProduct.price
      this.ValimageFileName = '' //obj.node.imageUrl[0]
      this.ValisDisplayPriceOnShopfront = data.companyProduct.isDisplayPriceOnShopfront
      this.ValisDisplayProductOnShopfront = data.companyProduct.isDisplayProductOnShopfront
      this.Valtags = data.companyProduct.tags //obj.node.tags
      this.ValquotationInitialPriceOffer = data.companyProduct.quotationInitialPriceOffer
      this.ValautobidMinimumPrice = data.companyProduct.autobidMinimumPrice
      this.ValautobidPriceDecrement = data.companyProduct.autobidPriceDecrement
      this.ValisAutobidEnabled = data.companyProduct.isAutobidEnabled
      this.ValisActivatedByUser = data.companyProduct.isActivatedByUser
      this.ValprimaryImg = '' //obj.node.imageUrl[0]
      this.FilenamePirmaryImg = '' //obj.node.imageUrl[0]
      this.ValimageMore = []

      this.Valstate = data.companyProduct.state
        
            this.StatusApproval=data.companyProduct.publishApprovalStatus
          


    })

    this.getUserStatus();



    // console.log('edit',ProductId);
    
    // var allprod = localStorage.getItem("PRODUCTLIST")
    // var parse = allprod;
    // if(parse){
    //   var allprodArr = JSON.parse(parse);
    //   var x=0
    //   allprodArr.forEach((obj: any) => {

    //     if(obj.node.id==ProductId){
    //       //set data
    //       console.log(obj.node)

    //       //model
    //       this.Valname = obj.node.name
    //       this.Valbrand = obj.node.brand
    //       this.Valdescription = obj.node.description
    //       this.Valsku = obj.node.sku
    //       this.ValmanufacturerSku = obj.node.manufacturerSku
    //       this.Valprice = obj.node.price
    //       this.ValimageFileName = '' //obj.node.imageUrl[0]
    //       this.ValisDisplayPriceOnShopfront = obj.node.isDisplayPriceOnShopfront
    //       this.ValisDisplayProductOnShopfront = obj.node.isDisplayProductOnShopfront
    //       this.Valtags = '' //obj.node.tags
    //       this.ValquotationInitialPriceOffer = obj.node.quotationInitialPriceOffer
    //       this.ValautobidMinimumPrice = obj.node.autobidMinimumPrice
    //       this.ValautobidPriceDecrement = obj.node.autobidPriceDecrement
    //       this.ValisAutobidEnabled = obj.node.isAutobidEnabled
    //       this.ValisActivatedByUser = obj.node.isActivatedByUser
    //       this.ValprimaryImg = '' //obj.node.imageUrl[0]
    //       this.FilenamePirmaryImg = '' //obj.node.imageUrl[0]
    //       this.ValimageMore = []
          
    //       if(obj.node.publishApprovalStatus==4){
    //         this.StatusApproval=true
    //       }

    //       console.log('>>> ',obj.node.publishedOngoingApprovalStatus);
    //       //obj.node.publishedOngoingApprovalStatus = null
    //       if(obj.node.publishedOngoingApprovalStatus){
    //         console.log('ok, not null');
    //         this.StatusComparation = true
    //       } else {
    //         console.log(' null');
    //         this.StatusComparation = false
    //       }

    //       // this.EditTags.forEach((el: any) => {
    //       //   console.log('el',el);
    //       //   console.log('inlineCheckbox'+el.id+'');
    //       //   // var check={'target':{'checked':true}} 
    //       //   // this.onChangeTag(el.id, check)
    //       //   this.MoreImgFileClick('inlineCheckbox'+el.id+'')
    
    //       // });
    

    //     }
    //     x++
    //   });
    // }



    // console.log('parse',parse);
    // console.log('allprod',allprod);

  }




  openmodaldraft(){
    if(this.displaydraft=="none"){
      this.displaydraft = "block";
    } else {
      this.displaydraft = "none";
    }
  }

  openmodaldone(){
    if(this.displaydone=="none"){
      this.displaydone = "block";
    } else {
      this.displaydone = "none";
    }
  }
  openmodaldelete(){
    if(this.displaydelete=="none"){
      this.displaydelete = "block";
    } else {
      this.displaydelete = "none";
    }
  }

  

  openModalApproveReject(){
    if(this.displayApproveReject=="none"){
      this.displayApproveReject = "block";
    } else {
      this.displayApproveReject = "none";
    }
  }
  
  openModalPublished(){
    if(this.displaypublish=="none"){
      this.displaypublish = "block";
    } else {
      this.displaypublish = "none";
    }
  }
  

  submitaction(val:any){
    this.actionbtn = ''
    this.actionbtn = val
    //this.onSubmit()
    console.log('val',val);

  }




  getTag(){

    // // const fields = ['id', '', 'firstname', 'repId', 'lastname', 'sex',
    // //   { 'adress': ['street', 'zip', 'city'] },'birthday'];
    // const fields = ['id','isActive'];

    // const head: String = `query{getCompanyTagList{`;
    // let body = '';
    // body = this.fieldsToModel(fields, body);
    // const foot: String = '}}';
    // const query = head + body + foot;
    // console.log('test query ',query);

    

    this.apollo.watchQuery<any>({
      query: getTags
      //query: gql`${query}`,
    })
    .valueChanges
    .subscribe((data:any) => {
      if(data.errors){
        console.log('data>',data.errors[0].extensions.classification);
      }
      
      this.tags = data.getCompanyTagList;
    })
  }

  confirmdelete()
  {
    console.log('delete',this.EditProductId);


    //post delete
    
    let productsId =this.EditProductId
    this.apollo
    .mutate({
      mutation: deleteProducts,
      variables: {productsId: productsId}
    })
    .subscribe(
      (res:any) => {
        console.log("succes delete, get data:", res);
        if(res.data){
          //alert('Delete Successfully')
          console.log("ada data");
          this.router.navigate(['/vendor/product-list']);
        }
      },
      error => {
        alert(error)
        console.log("there was an error sending the delete query", error);   
        alert('Error while delete product. Please refresh and try again.')       
      }
    );


  }



  reqeustApproval(id:any,action:any){
    console.log('id',id);
    console.log('action',action);

    this.LoadingreqeustApproval=true

    this.apollo
    .mutate({
      mutation: postApproval,
      variables: {action:action,productsId: id}
    })
    .subscribe(
      (res:any) => {
        this.LoadingreqeustApproval=false
        console.log("succes request approval, get data:", res);

        //this.getDataEdit(this.EditProductId)

        if(action=='APPROVE' || action=='REJECT'){
          this.openModalApproveReject()
          console.log('appr / reject')

        } else {
          console.log('request')
        }
        
        // if(res.data){
        //   //alert('Delete Successfully')
        //   console.log("ada data");
        //   this.router.navigate(['/vendor/products']);
        // }
      },
      error => {
        this.LoadingreqeustApproval=false
        alert(error)
        console.log("there was an error sending the approval query", error);   
        alert('Error while approval product. Please refresh and try again.')       
      }
    );


    if(action=='APPROVE'){
      this.StatusApproval=4
      console.log('appr reject',this.StatusApproval)
    } 
    else if(action=='REJECT'){
      this.StatusApproval=1
    }
    else {
      console.log('request')
      this.StatusApproval=2
      console.log('request',this.StatusApproval)
    }

  }

  submitPublish()
  {
    
    this.LoadingreqeustPublish=true

    this.apollo
    .mutate({
      mutation: postPublish,
      variables: {productsId: this.EditProductId}
    })
    .subscribe(
      (res:any) => {
        this.LoadingreqeustPublish=false
        console.log("succes requestPublish, get data:", res);
        this.Valstate = 'PUBLISHED'
        this.StatusApproval=4
        this.openModalPublished()
      },
      error => {
        this.LoadingreqeustPublish=false
        alert(error)
        console.log("there was an error sending the Publish query", error);   
        alert('Error while publishing product. Please refresh and try again.')       
      }
    );


  }

  comparepage(){
    console.log('comparepage');

  }
}

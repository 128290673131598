import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modalconfirm?: string;
  @Input() title?: string;
  @Input() content?: string;
  @Input() subtitle?: string;
  @Input() yesStr?: string;
  @Input() noStr?: string;

  @Input() modalinfo?: string;
  @Input() infoTitle?: string;
  @Input() infoSuccess?: string;
  @Input() infoFailed?: string;
  @Input() closeTxt?: string;

  @Input() modalnotification?: string;
  @Input() notificationSubject?: string;
  @Input() notificationContent?: string;
  @Input() notificationTime?: string;

  @Input() modalrating?: string;
  
  @Input() modalRatingProcurement?: string;
  @Input() pheader?: string;
  @Input() ptitle?: string;
  @Input() ptext?: string;
  @Input() ptextyes?: string;
  @Input() ptextno?: string;
  @Input() pstate?: string;
  @Input() phaverating?: string;
  @Input() ptype?: string;
  
  @Input() modalMenu?: string;
  @Input() modalisLogin?: string;
  @Input() modalCompanyName?: string;
  @Input() modalFullName?: string;
  
  @Input() modalhelp?: string;

  @Input() IdBtnClose?: string;

  @Output() confirmedClick = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  submit() {
    this.confirmedClick.emit(true);
  }
  rating(val:string) {
    this.confirmedClick.emit(val);
  }
  hitmenu(val:string) {
    this.confirmedClick.emit(val);
  }
}

import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const mutationLogin = gql`
mutation login($email: EmailAddress!, $password: String!, $loginIp: IPv4, $userAgent: String) {
  login(input: {email: $email,  password: $password, loginIp: $loginIp, userAgent:$userAgent }) {
      userData {
          id,
          fullname,
          salutation,
          profilePictureUrl,
          email,
          secondaryEmail,
          createdDate,
          createdBy,
          modifiedDate,
          modifiedBy
      },
      accessToken,
      refreshToken,
      companyChoices {
          companyId,
          companyName
      }
  }
}
`;


const mutationRefresh = gql`
mutation refreshAccessToken($userId: ID!, $staffId: ID, $refreshToken: JwtToken!) {
  refreshAccessToken(input: {
      userId: $userId,
      staffId: $staffId
      refreshToken: $refreshToken
  })
}
`;

const mutationForgot = gql`
mutation forgotPassword($email:EmailAddress!){
  forgotPassword(input: {
      userEmail: $email
  })
}
`;

const mutationVerify = gql`
mutation verifyEmail($verificationToken: String!) {
  verifyEmail(input: {
    verificationToken: $verificationToken}) {
      registrationId
      isAlreadyVerified
  }
}
`;

const mutationSubmitData = gql`
mutation inputUserDetailInfo($registrationId: ID!,$password: String!,$fullname: String!,$secondaryEmail: String) {
  inputUserDetailInfo(input: {
      registrationId: $registrationId
      password: $password
      fullname: $fullname
      secondaryEmail: $secondaryEmail
  })
}
`;

export interface LoginRes {
    userData: {
        id: string;
        fullname: string;
        salutation: string;
        profilePictureUrl: string;
        email: string;
        secondaryEmail: string;
        createdDate: string;
        createdBy: string;
        modifiedDate: string;
        modifiedBy: string;
    },
    accessToken: string;
    refreshToken: string;
    companyChoices: {
        companyId: string;
        companyName: string;
    }
  }
  export interface Response {
    LoginRes: LoginRes[];
  }
  

@Injectable({ providedIn: 'root' })

export class LoginService{

    detailObs = new Subject();
    refreshObs = new Subject();
    forgotObs = new Subject();
    mutationVerifyObs = new Subject();
    mutationSubmitDataObs = new Subject();
    
    constructor(private apollo: Apollo) {}

    PostLoginData(email:any, password:any, loginIp:any, userAgent:any) {
        this.apollo
        .mutate({
          mutation: mutationLogin,
          variables: {email: email, password: password,loginIp: loginIp, userAgent: userAgent},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.detailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.detailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getPostLoginData(email:any, password:any, loginIp:any, userAgent:any) {
        this.detailObs = new Subject();
        this.PostLoginData(email, password, loginIp, userAgent);
        return this.detailObs.asObservable();
    }

    refreshAccessToken(userId:any, staffId:any, refreshToken:any) {
        this.apollo
        .mutate({
          mutation: mutationRefresh,
          variables: {userId: userId, staffId: staffId, refreshToken: refreshToken},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.refreshObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.refreshObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getrefreshAccessToken(userId:any, staffId:any, refreshToken:any) {
        this.refreshObs = new Subject();
        this.refreshAccessToken(userId, staffId, refreshToken);
        return this.refreshObs.asObservable();
    }

    Forgot(email:string) {
        this.apollo
        .mutate({
          mutation: mutationForgot,
          variables: {email: email},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.forgotObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.forgotObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getForgot(email:string) {
        this.refreshObs = new Subject();
        this.Forgot(email);
        return this.forgotObs.asObservable();
    }

    verifyEmail(verificationToken:string) {
        this.apollo
        .mutate({
          mutation: mutationVerify,
          variables: {verificationToken: verificationToken},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.mutationVerifyObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.mutationVerifyObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postverifyEmail(verificationToken:string) {
        this.mutationVerifyObs = new Subject();
        this.verifyEmail(verificationToken);
        return this.mutationVerifyObs.asObservable();
    }


    inputUserDetailInfo(registrationId:string, password:string, fullname:string, secondaryEmail:string) {
      var ArrData:any;
      if(secondaryEmail==''){
        ArrData ={
          registrationId: registrationId,
          password: password,
          fullname: fullname,
        }
      } else {
        ArrData ={
          registrationId: registrationId,
          password: password,
          fullname: fullname,
          secondaryEmail: secondaryEmail
        }
      }

        this.apollo
        .mutate({
          mutation: mutationSubmitData,
          // variables: {registrationId: registrationId, password: password,fullname: fullname, secondaryEmail: secondaryEmail},
          variables: ArrData,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.mutationSubmitDataObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.mutationSubmitDataObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postinputUserDetailInfo(registrationId:string, password:string, fullname:string, secondaryEmail:string) {
        this.mutationSubmitDataObs = new Subject();
        this.inputUserDetailInfo(registrationId, password, fullname, secondaryEmail);
        return this.mutationSubmitDataObs.asObservable();
    }    
}
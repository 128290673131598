<div class="divProduct">
  <div class="">
    <div class="container-imgcard {{isHome?'container-imgcard-home':''}}" *ngIf="images?.length == 0">
        <img
        src="/assets/images/notfound.png"
        class="w-100 noimg imgcard"
        />    
    </div>


    <div *ngIf="images?.length > 0"
      id="carouselControls{{ galleryId }}"
      class="carousel slide imgcard"
      data-bs-ride="carousel"
      data-bs-interval="false"
    >
      <div class="carousel-inner container-imgcard {{isHome?'container-imgcard-home':''}}">
        <lightgallery
          [settings]="settingsGallery"
          [onInit]="onInitGallery"
          id="prodGallery{{ galleryId }}"
        >
          <ng-container *ngFor="let img of images; let x = index">
            <div class="carousel-item {{ x == 0 ? 'active' : '' }}">
              <!-- <a class="itemz" [attr.href]="img"> -->
              <a href="{{url}}">
                <img
                  [src]="img"
                  class="d-block w-100"
                  alt="{{ title }}"
                />
              <!-- </a> -->
              </a>
            </div>
          </ng-container>
        </lightgallery>
      </div>
      <button
        *ngIf="images?.length>1"
        class="carousel-control-prev"
        type="button"
        [attr.data-bs-target]="'#carouselControls' + galleryId"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        *ngIf="images?.length>1"
        class="carousel-control-next"
        type="button"
        [attr.data-bs-target]="'#carouselControls' + galleryId"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  </div>

  <div class="p-2 bg-white">
    <a href="{{url}}">
      <div class="row g-0 align-items-center">
        <div class="col-12">
          <p class="titleProduct mb-0 mt-0 limit-text">{{ title }}</p>
        </div>
        <div class="col-12"  *ngIf="price">
          <p class="priceProduct my-1">
            {{ currency }} {{ price | number }}
          </p>
        </div>
        <div class="col-12"  *ngIf="!price">
          <p class="priceProduct my-1">
            {{currency?currency:'IDR'}} ***
          </p>
        </div>
        <div class="col-sm-7">
          <p class="mb-0 pe-2 mt-1">
            <span *ngIf="company" class="badge rounded-pill bg-info cpname text-dark limit-text badge-limit">
              <img src="/assets/images/icons/vendor-icon.png" class="vendor-icon pe-1">
              <font class="limit-text">{{ company }}</font>
            </span>
            <span *ngIf="!company"
              class="badge rounded-pill bg-info ncpname text-dark limit-text badge-limit"
              >
              <img src="/assets/images/icons/vendor-confidential.png" class="vendor-icon pe-1">
              <font class="limit-text">Confidential</font>
            </span>
          </p>


          <!-- <p class="pb-2 mb-0 limit-text h-100"><img class="vendoricon" src="/assets/images/icons/vendor_icon.jpg"> 
            <strong class="ps-2  limit-text"  *ngIf="company">{{company}}</strong>
            <strong class="ps-2  limit-text"  *ngIf="!company">****</strong>
          </p> -->
        </div>
        <div class="col-sm-5 text-start text-sm-end">
          <p class="limit-text my-0">
            <small class="limit-text"
            ><i class="bi bi-geo-alt-fill" style="color: #1769b5"></i>
            {{ location }}</small
            >
          </p>

          <!-- <p class="pb-2 mb-0 limit-text h-100"><i class="bi bi-geo-alt-fill" style="color:#1769b5"></i> <strong class="ps-2">{{location}}</strong></p> -->
        </div>

      </div>
    </a>

      
      
  </div>
  <!-- <a href="{{url}}">
    <div class="p-1">
      <p class="titleProduct mb-2 mt-0 limit-text">{{ title }}</p>
      <p class="priceProduct mb-0">
        {{ currency }} {{ price | number }}
      </p>
      <table class="mb-2 table">
        <tr>
          <td>
            <span class="mt-2 badge rounded-pill bg-info cpname text-dark">
              <font class="limitText">{{ company }}</font>
            </span>
            <span
              class="bmt-2 adge rounded-pill bg-info ncpname text-dark d-none"
              >Confidential</span
            >
          </td>
          <td>
            <small class="limitText"
              ><i class="bi bi-geo-alt-fill" style="color: #1769b5"></i>
              {{ location }}</small
            >
          </td>
        </tr>
      </table>
    </div>
  </a> -->
</div>

<footer id="web-footer" *ngIf="isLoggedin" class="{{(isHomePage)?'':'d-none'}}">
	<div class="main-container">
		<div class="footer-top">
			<div class="footer-child child-1 d-none">
				<img src="/assets/homepage/images/logo-white-200.png" srcset="/assets/homepage/images/logo-white-200.png 200w, /assets/homepage/images/logo-white-400.png 400w" alt="Cepetdapet" loading="lazy" width="200" height="52" class="footer-logo">
			</div><!-- .footer-child -->
			<div class="footer-child child-2 d-none">
				<h3 class="ngc-title">Hubungi Kami</h3>
				<ul class="contact-info">
					<li>
						<span class="fas fa-map-marker-alt icon"></span>
						<span>Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia</span>
					</li>
					<li>
						<span class="fas fa-phone icon flip-x"></span>
						<a href="tel:0215554433">(021) 555 4433</a>
					</li>
					<li>
						<span class="fas fa-envelope icon"></span>
						<a href="mailto:info@cepetdapet.com">info@cepetdapet.com</a>
					</li>
				</ul><!-- .contact-info -->
			</div><!-- .footer-child -->
			<div class="footer-child child-3 d-none">
				<h3 class="ngc-title">Links</h3>
				<ul class="footer-nav">
					<li>
						<a href="index.html">Homepage</a>
					</li>
					<li>
						<a href="">Syarat & Ketentuan</a>
					</li>
					<li>
						<a href="">Report & Feedback</a>
					</li>
					<li>
						<a href="#section-faq">Pertanyaan Yang Sering Diajukan</a>
					</li>
					<li>
						<a href="/help">Help</a>
					</li>
					<li>
						<a href="">Subscription</a>
					</li>
				</ul><!-- .footer-nav -->
			</div><!-- .footer-child -->

				<div class="row p-4 lh-lg w-100">
					<div class="col-sm-5">
					  <img src="/assets/images/Logo FH with Text - White.png" style="max-width:220px;" class="logofooter">
					  <!-- <p class="addressfooter">
						Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia<br>
						Phone : (021 555 4433) <br>
						E-mail : info@website.com<br>
					  </p> -->
					</div>
					<div class="col-sm-7">
					  <div class="row g-0">
						<div class="col-sm-6">
						  <!-- <strong>Tautan</strong><br> -->
						  <a class="listfooter" href="/">{{'menu.home'|translate}}</a><br>
						  <a class="listfooter pointer" (click)="sendMessage()">{{'menu.report_feedback'|translate}}</a><br>
						  <a class="listfooter" href="/subscription">{{'menu.subscription'|translate}}</a><br>
						</div>
						<div class="col-sm-6">
						  <!-- <br> -->
						  <a class="listfooter pointer" (click)="openVerticallyCentered(content)">{{'menu.tos'|translate}}</a><br>
						  <a class="listfooter" href="/help">{{'menu.help'|translate}}</a><br>
						  <a class="listfooter" href="/contact-us">{{'menu.contact_us'|translate}}</a><br>
						</div>
					  </div>
					</div>
			
				  </div>


		</div><!-- .footer-top -->
		<div class="footer-bottom">
			<ul class="socmed-nav d-none">
				<li>
					<a href="" target="_blank" class="soc-fb" aria-label="Visit our Facebook"><span class="fab fa-facebook-f icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-linkedin" aria-label="Visit our LinkedIn"><span class="fab fa-linkedin-in icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-ig" aria-label="Visit our Instagram"><span class="fab fa-instagram icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-youtube" aria-label="Visit our Youtube channel"><span class="fab fa-youtube icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-twitter" aria-label="Visit our Twitter"><span class="fab fa-twitter icon"></span></a>
				</li>
			</ul><!-- .socmed-nav -->
			<p class="copyright">Copyright &copy; 2023 Cepetdapet. All Rights Reserved.</p>
		</div><!-- .footer-bottom -->
	</div><!-- .main-container -->
</footer>



<footer *ngIf="isLoggedin" class="text-muted {{(isHomePage)?'fhHomeFooter d-none':'fhgradfooter'}}">
    <div class="container text-white footerdiv  py-4 ">

      <div class="row pt-4 lh-lg">
        <div class="col-sm-5">
          <img src="/assets/images/Logo FH with Text - White.png" class="logofooter">
          <!-- <p class="addressfooter">
            Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia<br>
            Phone : (021 555 4433) <br>
            E-mail : info@website.com<br>
          </p> -->
        </div>
        <div class="col-sm-7">
          <div class="row g-0">
            <div class="col-sm-4">
              <b>Tautan</b><br>
              <a class="listfooter" href="/">{{'menu.home'|translate}}</a><br>
              <a class="listfooter" (click)="sendMessage()">{{'menu.report_feedback'|translate}}</a><br>
            </div>
            <div class="col-sm-4">
              <br>
              <a class="listfooter" (click)="openVerticallyCentered(content)">{{'menu.tos'|translate}}</a><br>
              <a class="listfooter" href="/help">{{'menu.help'|translate}}</a><br>
    
            </div>
            <div class="col-sm-4">
              <br>
              <a class="listfooter" href="/subscription">{{'menu.subscription'|translate}}</a><br>
			  <a class="listfooter" href="/contact-us">{{'menu.contact_us'|translate}}</a><br>
            </div>
          </div>
        </div>

      </div>

      <!-- <p class="float-end mb-1">
        <a href="{{href}}#">Back to top</a>
      </p>
      <p class="mb-1">DEV © 2021</p> -->
      
    </div>
    <div class="{{(isHomePage)?'copyrightdiv':''}}">
      <p class="mb-0 text-white text-center pt-2 pb-2">Copyright © 2023 Cepetdapet. All Rights Reserved.</p>
    </div>
</footer>


<footer *ngIf="!isLoggedin" id="web-footer">
	<div class="main-container">
		<div class="footer-top">
			<div class="footer-child child-1 d-none">
				<img src="/assets/homepage/images/logo-white-200.png" srcset="/assets/homepage/images/logo-white-200.png 200w, /assets/homepage/images/logo-white-400.png 400w" alt="Cepetdapet" loading="lazy" width="200" height="52" class="footer-logo">
			</div><!-- .footer-child -->
			<div class="footer-child child-2 d-none">
				<h3 class="ngc-title">Hubungi Kami</h3>
				<ul class="contact-info">
					<li>
						<span class="fas fa-map-marker-alt icon"></span>
						<span>Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia</span>
					</li>
					<li>
						<span class="fas fa-phone icon flip-x"></span>
						<a href="tel:0215554433">(021) 555 4433</a>
					</li>
					<li>
						<span class="fas fa-envelope icon"></span>
						<a href="mailto:info@cepetdapet.com">info@cepetdapet.com</a>
					</li>
				</ul><!-- .contact-info -->
			</div><!-- .footer-child -->
			<div class="footer-child child-3 d-none">
				<h3 class="ngc-title">Links</h3>
				<ul class="footer-nav">
					<li>
						<a href="index.html">Homepage</a>
					</li>
					<li>
						<a href="">Syarat & Ketentuan</a>
					</li>
					<li>
						<a href="">Report & Feedback</a>
					</li>
					<li>
						<a href="#section-faq">Pertanyaan Yang Sering Diajukan</a>
					</li>
					<li>
						<a href="/help">Help</a>
					</li>
					<li>
						<a href="">Subscription</a>
					</li>
				</ul><!-- .footer-nav -->
			</div><!-- .footer-child -->

				<div class="row p-4 lh-lg w-100">
					<div class="col-sm-5">
					  <img src="/assets/images/Logo FH with Text - White.png" style="max-width:220px;" class="logofooter">
					  <!-- <p class="addressfooter">
						Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia<br>
						Phone : (021 555 4433) <br>
						E-mail : info@website.com<br>
					  </p> -->
					</div>
					<div class="col-sm-7">
					  <div class="row g-0">
						<div class="col-sm-6">
						  <!-- <strong>Tautan</strong><br> -->
						  <a class="listfooter" href="/">{{'menu.home'|translate}}</a><br>
						  <a class="listfooter pointer" (click)="sendMessage()">{{'menu.report_feedback'|translate}}</a><br>
						  <a class="listfooter" href="/subscription">{{'menu.subscription'|translate}}</a><br>
						</div>
						<div class="col-sm-6">
						  <!-- <br> -->
						  <a class="listfooter pointer" (click)="openVerticallyCentered(content)">{{'menu.tos'|translate}}</a><br>
						  <a class="listfooter" href="/help">{{'menu.help'|translate}}</a><br>
						  <a class="listfooter" href="/contact-us">{{'menu.contact_us'|translate}}</a><br>
						</div>
					  </div>
					</div>
			
				  </div>


		</div><!-- .footer-top -->
		<div class="footer-bottom">
			<ul class="socmed-nav d-none">
				<li>
					<a href="" target="_blank" class="soc-fb" aria-label="Visit our Facebook"><span class="fab fa-facebook-f icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-linkedin" aria-label="Visit our LinkedIn"><span class="fab fa-linkedin-in icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-ig" aria-label="Visit our Instagram"><span class="fab fa-instagram icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-youtube" aria-label="Visit our Youtube channel"><span class="fab fa-youtube icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-twitter" aria-label="Visit our Twitter"><span class="fab fa-twitter icon"></span></a>
				</li>
			</ul><!-- .socmed-nav -->
			<p class="copyright">Copyright &copy; 2023 Cepetdapet. All Rights Reserved.</p>
		</div><!-- .footer-bottom -->
	</div><!-- .main-container -->
</footer>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title px-3">Term & Conditions</h4>
  </div>
	<div class="modal-body">
		<div class="pt-3 pb-3 pe-4 ps-4 border-1">

				<div  *ngIf="TnCsrc" >
					<pdf-viewer 
					[src]="TnCsrc" 
					[original-size]="false"
					[show-all]="true"
					[fit-to-page]="false"
					[zoom]="1"
					[zoom-scale]="'page-width'"
					[stick-to-page]="false"
					[render-text]="true"
					[external-link-target]="'blank'"
					[autoresize]="true"
					[show-borders]="false"
					style="width: 100%; height: 400px;"
					>
					</pdf-viewer>
				</div>

		</div>
	</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light mb-3 mx-3  rounded-pill px-4" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

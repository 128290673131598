<div class="">
  <div class="container" *ngIf="PageLoading">
    <div>
      <div class="text-center pt-5 pb-5">
        <div class="loader loader--style8" title="7">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;"
            xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!PageLoading">

    <!-- ??? why causes error ?? -->
    <!-- <app-rfqmenu class="d-none d-sm-block"></app-rfqmenu> -->
    <!-- manual dl -->
    <div class="d-none d-sm-block pt-3">
      <nav class="nav nav-pills navrfqmenu">
        <a class="nav-link c1 rfqmenu" routerLinkActive="activemenu" routerLink="/procurement/rfq-list">My RFQ</a>
        <a class="nav-link c1 rfqmenu" routerLinkActive="activemenu" routerLink="/procurement/rfq/my-proforma-po">My Proforma PO</a>
        <a class="nav-link c1 rfqmenu" routerLinkActive="activemenu" routerLink="/procurement/rfq/recurring">Recurring RFQ</a>
        <a class="nav-link c1 rfqmenu" routerLinkActive="activemenu" routerLink="/procurement/rfq-history">History</a>
        <a class="nav-link c1 rfqmenu" routerLinkActive="activemenu" routerLink="/procurement/rating/procurement-rating-list">Rating & Review</a>
      </nav>  
    </div>

    



    <div class="mt-3">
      <div class="pb-5">
        <div class="mb-4">
          <div class="d-none d-sm-block">
            <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
              <div class="row">
                <div class="col-sm-12">
                  <div class="shbreadcum">
                    <table>
                      <tr>
                        <td class="separator">
                          <h6 class="pt-2 mt-1 me-3"><strong><a href="/"
                                class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png"
                                  class="backicon"> Back</a></strong></h6>
                        </td>
                        <td>
                          <h6 class="mb-1 mt-2 ms-3">
                            <strong class="c1">
                              <font class="text-muted">Rating & Review
                              </font>
                            </strong>
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


                  <!-- Breadcrumb mobile -->
                  <div class="d-block d-sm-none">

                    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">


                      <div class="row">
                        <div class="col-6">
                          <div class="pt-1">
                            <strong class="c1">
                              <strong class="c1">
                                <font class="text-muted">Rating & Review
                                </font>
                              </strong>
                            </strong>
                          </div>

                        </div>
                        <div class="col-6 text-end">
 
                        </div>
                      </div>

                    </div>
                  </div>

          
          
          <div class="card border-0  mb-3 divshbreadcum">

            <div class="container">
              <div class="row">
                <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top ps-3 ps-md-5 ps-lg-5">
                  <h4 class="mb-0 fw-bold">Rating & Review</h4>
                </div>

                <div class="col-12 ps-3 pe-3 {{ loadingrating ? 'onloading' : ''}}">
                  <div class="row justify-content-center pb-0 pb-md-5 pb-lg-5">
                    <div class="col-sm-12 col-md-10 col-lg-10 pt-3 pb-3 table-responsive-sm">
                      <div class="row g-0 mb-0 mt-0 mb-md-3 mb-lg-3 mt-md-3 mt-lg-3">
                        <div class="col-sm-5">
                          <!-- <div class="form-check ms-2 mt-2">
                            <input class="form-check-input" type="checkbox" [checked]="valselectallstaffs"
                              (click)="checkAll()" id="selectallstaffs">
                            <label class="form-check-label pointer" (click)="checkAll()" for="flexCheckDefault">
                              <strong>Select All Staffs</strong>
                            </label>
                          </div> -->
                        </div>
                        <!-- <div class="col-lg-3 col-md-2 col-sm-2">

                        </div>
                        <div class="col-sm-3">
                          <select class="form-select" [(ngModel)]="valBulk" aria-label="Default select example">
                            <option value="" selected>- Select Bulk Action -</option>
                            <option value="activeall">Active All</option>
                            <option value="disabledall">Disabled All</option>
                            <option value="deleteall">Delete All</option>
                          </select>
                        </div>
                        <div class="col-lg-1 col-md-2 col-sm-2 text-end">
                          <a class="ms-2 btn btn-primary bg1" (click)="bulkAction()">Apply</a>
                        </div> -->
                        <div class="col-sm-4">
                        </div>
                        <div class="col-sm-3">
                            <!-- <div class="input-group mb-3">
                                <input type="text" class="form-control borderInputSearch" placeholder="Search PPO..." aria-label="Recipient's username" aria-describedby="button-addon2">
                                <button class="btn btn-primary fhbtn2 borderSearch" type="button" id="button-addon2"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>
                            </div> -->
                        </div>
                      </div>
                      <table class="table table-hover" id="usertable">
                        <thead>
                          <tr>
                            <th class="text-center act" scope="col text-center wact">Action</th>
                            <th class="text-left" scope="col"><font class="pointer">Date 
                              <!-- <i class="bi bi-arrow-up-short"></i> -->
                            </font></th>
                            <th  class="text-left" scope="col"><font class="pointer">RFQ ID 
                              <!-- <i class="bi bi-arrow-up-short"></i> -->
                            </font></th>
                            <th class="text-left" scope="col"><font class="pointer">PPO ID 
                              <!-- <i class="bi bi-arrow-up-short"></i> -->
                            </font></th>
                            <th class="text-left" scope="col"><font class="pointer">Vendor Name 
                              <!-- <i class="bi bi-arrow-up-short"></i> -->
                            </font></th>
                            <th class="text-left" scope="col"><font class="pointer">Status 
                              <!-- <i class="bi bi-arrow-up-short"></i> -->
                            </font></th>

                          </tr>
                        </thead>
                        <tbody>

                          <ng-container *ngIf="noData">
                            <tr>
                              <td colspan="6" class="text-center">No data</td>
                            </tr>
                          </ng-container>

                          <ng-container *ngIf="!noData">

                          <tr *ngFor="let data of AllRatingData; let i = index">
                            <td class="text-center">
                              <a class="pointer" href="/procurement/rating/procurement-rating-vendor/{{data.proformaPoId}}"><img class="rating-icon" src="/assets/images/icons/eyes-icon-fh@3x.png"></a>
                            </td>
                            <td>{{data.proformaPoDate | date:'d MMM y'}}</td>
                            <td>RFQ # {{data.rfqDisplayId}}</td>
                            <td># {{data.proformaPoNumber}}</td>
                            <td>{{data.vendorName}}</td>
                            <td>
                              <ng-container *ngIf="!data.isRating">
                                Not review yet
                              </ng-container>
                              <ng-container *ngIf="data.isRating">
                                <div class="pointer" triggers="click" [autoClose]="'outside'" placement="start" ngbTooltip="{{data.totalRating | number : '1.1-1'}}"  container="body" [innerHtml]="ratingicon(data.totalRating)"></div> 
                              </ng-container>
                              
                            </td>


                          </tr>

                            <!-- "proformaPoId": "01G3BAQDTVJNTDXYZ2C648ZZEN",
                            "proformaPoNumber": "u6JyMvwk",
                            "rfqDisplayId": "K7yYsz85",
                            "vendorName": "Company Pak Sen 3",
                            "isRating": true,
                            "proformaPoDate": "2022-05-18T10:16:59.483095Z",
                            "totalRating": 2.67,
                            "isTransactionOccur": true,
                            "__typename": "ProcurementPpoNeedRatingListData" -->


                          </ng-container>       





                        </tbody>
                      </table>


                      <div class="row g-0 mb-3 mt-3">


                        <div class="col-sm-12 pt-0 pb-0 pb-md-5 pb-lg-5  container {{ loadingrating ? 'onloading' : ''}}">

                          <div class="row g-0 pt-2 pb-0 pb-md-5 pb-lg-5  mb-0  justify-content-end">
                              <div class="col-auto ps-1 pe-1">
                                <div class=" disable-btn p-0 ps-3  pe-2">
                                  <img *ngIf="hasprev" src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle pointer" (click)="gotopage(currentpage-1)">
                                  <img *ngIf="!hasprev" src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                </div>
                              </div>
                              <div class="col-auto mt-2">
                                <span  *ngFor="let item of [].constructor(totalpage); let i = index" class="pagingqrfq {{currentpage==i+1?'active':'pointer'}}" (click)="gotopage(i+1)">{{i+1}}</span>
                                <!-- <span class="pagingqrfq active">1</span>
                                <span class="pagingqrfq pointer">2</span>
                                <span class="pagingqrfq pointer">3</span>
                                <span class="pagingqrfq pointer">4</span>
                                <span class="pagingqrfq pointer">5</span> -->

                                <span *ngIf="noData" class="pagingqrfq active">1</span>

                              </div>
                              <div class="col-auto  ps-1 pe-1">
                                <div class="disable-btn p-0  ps-2">
                                  <img *ngIf="hasnext" src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle pointer" (click)="gotopage(currentpage+1)">
                                  <img  *ngIf="!hasnext" src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                </div>
                              </div>
                          </div>

                        </div>


                        <!-- <div _ngcontent-lwj-c125="" class="col-lg-6"></div>
                        <div _ngcontent-lwj-c125="" class="col-lg-6">
                          <div _ngcontent-lwj-c125="" class="mb-3 row g-0 justify-content-end"><label _ngcontent-lwj-c125=""
                              class="col-3 col-form-label text-end ps-2">Show</label>
                            <div _ngcontent-lwj-c125="" class="col-auto ms-2"><select _ngcontent-lwj-c125=""
                                class="form-select ng-untouched ng-pristine ng-valid" ng-reflect-model="20">
                                <option _ngcontent-lwj-c125="" value="20" selected="" ng-reflect-value="20">20</option>
                                <option _ngcontent-lwj-c125="" value="50" ng-reflect-value="50">50</option>
                                <option _ngcontent-lwj-c125="" value="100" ng-reflect-value="100">100</option>
                                <option _ngcontent-lwj-c125="" value="200" ng-reflect-value="200">200</option>
                                <option _ngcontent-lwj-c125="" value="500" ng-reflect-value="500">500</option>
                                <option _ngcontent-lwj-c125="" value="1000" ng-reflect-value="1000">1000</option>
                              </select></div>
                            <div _ngcontent-lwj-c125="" class="col-auto ps-1 pe-1">
                              <div _ngcontent-lwj-c125="" class="disable-btn p-0 ps-3"><img _ngcontent-lwj-c125=""
                                  src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle"></div>
                            </div>
                            <div _ngcontent-lwj-c125="" class="col-auto ps-1 pe-1">
                              <div _ngcontent-lwj-c125="" class="btn p-0 ps-2"><img _ngcontent-lwj-c125=""
                                  src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle"></div>
                            </div>
                          </div>
                        </div> -->
                      </div>

      



                    </div>








                  </div>
                </div>




              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

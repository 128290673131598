import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { config } from '../config';

const key = config.secretKey;

@Pipe({name: 'encrypted'})
export class EncryptPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
        return CryptoJS.AES.encrypt(value, key).toString();
    } else {
        return null
    }
  }
}

@Pipe({name: 'decrypted'})
export class DecryptPipe implements PipeTransform {
  transform(encrypted: string): any {
    if (encrypted) {
      const decrypted = CryptoJS.AES.decrypt(encrypted, key);
      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
        return null
    }
  }
}
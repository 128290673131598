import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import Util from 'src/app/_helpers/util';
import { RfqProcurementService } from 'src/app/_services/rfqprocurement.services';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  @Input() shareTitle?: any;
  @Input() shareUrl?: any;
  @Input() shareType?: any;  // rfq / product
  @Input() shareData?: any;
  @Input() shareDetail?: any;
  


  textWa:string=''

  showInviteEmail='none'
  submitInvEmail:boolean=false
  invalidEmail:boolean=false
  ValinputEmail:string=''
  selectedInvEmail: { id: string; val: string; type: string; }[] =[]
  inviteloading:boolean=false

  rfqId:string=''
  utm_content:string=""


  // utm_campaign = SrWaPrd-productid
  // utm_campaign = SrLkPrd-productid
  // utm_campaign = SrFbPrd-productid
  
  // utm_campaign = SrFbRfq-Rfqid
  // Sr Lk Prd id
  // Sr Fb Rfq id
  
  // share sosmed/medium type + id

  utm_campaign:string=''
  share_type:string='sh' // share
  share_channel:string='' // Fb / Lk / Ig / Wa
  share_attribute:string='Prd' // Prd -> Product / Rfq -> Rfq

  share_url = '' 
  link_fb = ''
  link_lk = ''
  link_wa = ''

  constructor(
    private popupmodal : Popupmodal,
    private translate: TranslateService,
    private rfqProcurementService : RfqProcurementService,
  ) { }

  ngOnInit(): void {

    if(this.shareData){
      this.shareData = JSON.parse(this.shareData);
      
      this.utm_content = this.shareData.id
    }
    if(this.shareDetail){
      this.shareDetail = JSON.parse(this.shareDetail);
    }

    console.log('share shareType ',this.shareType)
    console.log('share shareData ',this.shareData)
    console.log('share shareDetail ',this.shareDetail)


    this.shareTitle = Util.formatShortDesc(this.shareTitle)

    this.textWa = 'Lagi cari *'+this.shareTitle+'* ? Temukan di '+this.shareUrl+'?utm_source=product-share%26utm_medium=whatsapp%26utm_campaign=share%26utm_content='+this.utm_content; //product


    console.log('generateLink ...')
    var domain = 'cepetdapet.com'
    var protocol = 'https'
    console.log('window.location.hostname : ',window.location.hostname)
    if(window.location.hostname=='117.102.124.83' || window.location.hostname=='localhost'){
      domain = '117.102.124.83:17173'
      protocol = 'http'
    } 
   
    var url = this.shareUrl

    var WaText = 'Anda menjual *'+this.truncateToFiveWords(this.shareTitle)+'* ?  Tawarkan di CepetDapet '+url

    //prd
    this.link_fb = 'http://www.facebook.com/sharer/sharer.php?u='+url+'?utm_campaign=SrFbPrd-'+this.shareData.id
    this.link_lk = 'https://www.linkedin.com/sharing/share-offsite/?url='+url+'?utm_campaign%3DSrLkPrd-'+this.shareData.id
    this.link_wa = 'https://wa.me/?text='+WaText+'?utm_campaign=SrWaPrd-'+this.shareData.id

    //rfq
    if(this.shareData && this.shareDetail){
      console.log('shareData shareDetail...')
      var rfqid = this.shareData.id
      var rfqDisplayId = this.formatDisplayId( this.shareData.rfqDisplayId)

      url=protocol+'://'+domain+'/s/'+rfqid

      var WaText = 'Anda menjual *'+this.truncateToFiveWords(this.shareTitle)+'* ?  Tawarkan di CepetDapet '+url

      this.link_fb = 'http://www.facebook.com/sharer/sharer.php?u='+url+'?utm_campaign=SrFbRfq-'+rfqDisplayId
      this.link_lk = 'https://www.linkedin.com/sharing/share-offsite/?url='+url+'?utm_campaign%3DSrLkRfq-'+rfqDisplayId //Linkedin cannot accept '=' convert to '%3D'
      this.link_wa = 'https://wa.me/?text='+WaText+'?utm_campaign=SrWaRfq-'+rfqDisplayId

      console.log('link_fb : ',this.link_fb)
      console.log('link_lk : ',this.link_lk)
      console.log('link_wa : ',this.link_wa)
    }
    

    // if(this.shareData){
    //   this.shareData = JSON.parse(this.shareData);
      
    //   this.utm_content = this.shareData.id
    // }
    // if(this.shareDetail){
    //   this.shareDetail = JSON.parse(this.shareDetail);
    // }

    // console.log('share shareType ',this.shareType)
    // console.log('share shareData ',this.shareData)
    // console.log('share shareDetail ',this.shareDetail)



    // this.textWa = 'Lagi cari *'+this.shareTitle+'* ? Temukan di '+this.shareUrl+'?utm_source=product-share%26utm_medium=whatsapp%26utm_campaign=share%26utm_content='+this.utm_content; //product


    // if(this.shareData && this.shareDetail){

    //   var name = Util.formatShortDesc(this.shareDetail.description)
    //   var brand = this.shareDetail.brand  
    //   var nameBrandText = (brand)?'*'+name+'* ('+brand+')':'*'+name+'*'
    //   console.log('this.document.location.hostname; ',document.location.hostname)
      

    //   console.log('shareData= ',this.shareData)
    //   var rfqid = this.shareData.id
    //   var rfqDisplayId = this.formatDisplayId( this.shareData.rfqDisplayId)
    //   console.log('rfqid= ',rfqid)
    //   console.log('rfqDisplayId= ',rfqDisplayId)
      
    //   var domain = 'cepetdapet.com'
    //   var protocol = 'https'
    //   console.log('window.location.hostname : ',window.location.hostname)
    //   if(window.location.hostname=='117.102.124.83' || window.location.hostname=='localhost'){
    //     domain = '117.102.124.83:17173'
    //     protocol = 'http'
    //   } 
    //   var url=protocol+'://'+domain+'/vendor/rfq-not-participating-in/detail/'+rfqid
    //   this.textWa = 'Ada produk menarik yang mungkin sesuai dengan produk Anda. Silahkan cek di Cepetdapet. '+nameBrandText+' '+ url+'?utm_source=rfq-invite%26utm_medium=whatsapp%26utm_campaign=share%26utm_term=vendor%26utm_content='+rfqDisplayId+'' //rfq
  
    //   this.shareUrl = url
    //   this.rfqId = rfqid
    //   this.utm_content = rfqDisplayId
    // }

  }

  truncateToFiveWords(text:string) {
    const words = text.split(/\s+/);
    if (words.length > 5) {
        return words.slice(0, 5).join(' ') + ' ...';
    } else {
        return text;
    }
}


  formatDisplayId(displayId: any) {
    var re = new RegExp('.{1,4}', 'g');
    return displayId?.match(re).join("-");
  }

  showModalInviteVendor(){
    if(this.showInviteEmail=='none'){
      this.showInviteEmail='block'
    } else {
      this.showInviteEmail='none'
    }  
    //reset
    this.ValinputEmail=''
    this.selectedInvEmail=[]
    this.inviteloading=false

  }
  addInvEmail(){
    this.submitInvEmail=true
    

    const validateEmail = (email: string) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    if(this.ValinputEmail){
      console.log('validate email : ',validateEmail(this.ValinputEmail))
      if(validateEmail(this.ValinputEmail)){
        this.addVendorToArr(this.ValinputEmail,this.ValinputEmail,'email')
        this.submitInvEmail=false
        this.invalidEmail=false
        this.ValinputEmail=''
      } else {
        this.invalidEmail=true
      }

    } else {
      this.invalidEmail=true
    }
  }
  addVendorToArr(id:string,name:string,type:string){
    //check if exist
    var exist = false
    if(this.selectedInvEmail.length==0){
      this.selectedInvEmail.push({'id':id,'val':name,'type':type})
    } else {
      this.selectedInvEmail.forEach((e:any,i:any) => {
        if(e.type==type){
          if(e.val==name){
              //if not exist , push
              exist = true
          }
        }
      });
      if(!exist){
        this.selectedInvEmail.push({'id':id,'val':name,'type':type})
      }
    }

  }
  removeInvVendor(id:any){
    
    
    this.selectedInvEmail.forEach((e:any,i:any) => {
      if(e.id==id){
        this.selectedInvEmail.splice(i, 1)
      }
    });
  }

  submitInv(){

      this.inviteloading=true

      var rfqId = this.rfqId
      var usingInviteVendorID:any = []
      var usingInviteEmail:any = []

      this.selectedInvEmail.forEach((e:any,i:any) => {
        console.log(e)
        if(e.type=='email'){
          usingInviteEmail.push(e.id)
        }
        if(e.type=='vendor'){
          usingInviteVendorID.push(e.id)
        }
      });

      this.rfqProcurementService
      .postinviteVendorToRfq(rfqId,usingInviteVendorID,usingInviteEmail)
      .subscribe((res:any) => {
        this.showModalInviteVendor()
        console.log('res : ',res)
        if(res.data?.inviteVendorToRfq){
          this.popupmodal.showError('Invitation sent.',null).subscribe((res:any) => {})
        }
        else if(res.errors){
          var error = res.errors[0].message
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          var errortext = msg
  
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
  
            //back to specific url from errortype
            var getBackUrl = CheckErrortype.getBackUrl(res.errors[0].extensions.type)
            if(getBackUrl){
              this.popupmodal.showError(errortext,getBackUrl).subscribe((res:any) => {})
              return
            }
            
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
      });


  }


  submitInvProduct(){
    this.showModalInviteVendor()
    this.popupmodal.showError('call api email share product',null).subscribe((res:any) => {})
  }
}

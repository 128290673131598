import { Component, OnInit } from '@angular/core';
import { gql, Apollo } from 'apollo-angular';
import { NavigationService } from 'src/app/_services/navigation';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import Util from 'src/app/_helpers/util';
import { formatNumber } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { RfqVendorService } from 'src/app/_services/rfqvendor.services';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { TranslateService } from '@ngx-translate/core';


const changeQuotationApprovalStatus = gql`
mutation changeQuotationApprovalStatus($action:approvalAction!,$quotationId:ID!){
  changeQuotationApprovalStatus(input: {
      action: $action
      quotationId: $quotationId
  })
}
`;
const changeQuotationDraftApprovalStatus = gql`
mutation changeQuotationDraftApprovalStatus($action:approvalAction!,$quotationId:ID!){
  changeQuotationDraftApprovalStatus(input: {
      action: $action
      quotationId: $quotationId
  })
}
`;


const changeQuotationState = gql`
mutation changeQuotationState($action:stateAction!,$quotationId:ID!){
  changeQuotationState(input: {
      action: $action
      quotationId: $quotationId
  })
}
`;




const queryRfq = gql`
query rfqDetail($rfqParticipatedId: ID!, $quotationId: ID) {
  participatedRfqDetail(input: {
    rfqParticipatedId: $rfqParticipatedId,
    quotationId: $quotationId
}){
    rfqData{
      rfqDisplayId
      procurementName
      description
      rfqVendorCategory
      submitQuotationLastDate
      rfqDeliveryCountry
      rfqDeliveryProvince
      rfqDeliveryCity
      rfqDeliveryStreet
      rfqDeliveryPostalCode
      rfqState
      paymentTermRequestedInDays
      etaRequested
      currency
      isForFullQuote
      isIndentAccepted
      rfqItem {
          id
          name
          brand
          description
          qtyNeeded
          manufacturerSku
          itemSequence
          itemUnit
      }
      totalParticipation
    }
    quotationData{
        id
        displayQuotationId
        state
        approvalStatus
        ongoingApprovalStatus
        vendorRefNumber
        quotationDeliveryCountry
        quotationDeliveryCity
        etaOffered
        paymentTermOfferedInDays
        isForFullOrder
        isShippingCostIncluded
        otherCost
        memo
        isTaxIncluded
        taxPercentage
        taxValue
        isAutoBidActive
        maxNumberOfAutoBid
        closeReason
        quotationItem {
            id
            rfqItemId
            qtyOffered
            priceOffered
            productId
            productNameOffered
            productBrandOffered
            productManufacturerCodeOffered
            productDescOffered
        }
        quotationItemAlternative {
            id
            rfqItemId
            qtyOffered
            priceOffered
            productId
            alternativeProductName
            alternativeProductBrand
            alternativeProductManufacturerCode
            alternativeProductDesc
        }
        createdDate
        createdBy
        modifiedDate
        modifiedBy
    }
}
}
`;

const postQuotation = gql`
mutation insertQuotation($rfqParticipationId: ID!, $vendorRefNumber: String!, $quotationDeliveryCountry: String!, $etaOffered: Int, $paymentTermOfferedInDays: Int, $isForFullOrder: Boolean, $isShippingCostIncluded: Boolean, $otherCost: String, $memo: String, $isTaxIncluded: Boolean, $taxPercentage: Float, $taxValue: Float, $isAutoBidActive: Boolean, $maxNumberOfAutoBid: Int, $rfqItemId: ID, $qtyOffered: Float, $priceOffered: Float, $productNameOffered: String!, $productBrandOffered: String!, $productManufacturerCodeOffered: String!, $productDescOffered: String!, $alternativeProductName: String!, $alternativeProductBrand: String!, $alternativeProductManufacturerCode: String!, $alternativeProductDesc: String!) {
  insertQuotation(input: {
      rfqParticipationId: $rfqParticipationId
      quotationInsertInputData: {
          vendorRefNumber: $vendorRefNumber
          quotationDeliveryCountry: $quotationDeliveryCountry
          quotationDeliveryCity: "String!"
          etaOffered: $etaOffered
          paymentTermOfferedInDays: $paymentTermOfferedInDays
          isForFullOrder: $isForFullOrder
          isShippingCostIncluded: $isShippingCostIncluded
          otherCost: $otherCost
          memo: $memo
          isTaxIncluded: $isTaxIncluded
          taxPercentage: $taxPercentage
          taxValue: $taxValue
          isAutoBidActive: $isAutoBidActive
          maxNumberOfAutoBid: $maxNumberOfAutoBid
          quotationItemInput: [
              {
                  rfqItemId: $rfqItemId
                  qtyOffered: $qtyOffered
                  priceOffered: $priceOffered
                  productNameOffered: $productNameOffered
                  productBrandOffered: $productBrandOffered
                  productManufacturerCodeOffered: $productManufacturerCodeOffered
                  productDescOffered: $productDescOffered
              }
          ]
          quotationItemAlternativeInput: [
              {
                  rfqItemId: $rfqItemId
                  qtyOffered: $qtyOffered
                  priceOffered: $priceOffered
                  alternativeProductName: $alternativeProductName
                  alternativeProductBrand: $alternativeProductBrand
                  alternativeProductManufacturerCode: $alternativeProductManufacturerCode
                  alternativeProductDesc: $alternativeProductDesc
              }
          ]
      }
  }){
      id
      displayQuotationId
      state
      approvalStatus
      ongoingApprovalStatus
      vendorRefNumber
      quotationDeliveryCountry
      quotationDeliveryCity
      etaOffered
      paymentTermOfferedInDays
      isForFullOrder
      isShippingCostIncluded
      otherCost
      memo
      isTaxIncluded
      taxPercentage
      taxValue
      isAutoBidActive
      maxNumberOfAutoBid
      closeReason
      quotationItem {
          id
          rfqItemId
          qtyOffered
          priceOffered
          productId
          productNameOffered
          productBrandOffered
          productManufacturerCodeOffered
          productDescOffered
      }
      quotationItemAlternative {
          id
          rfqItemId
          qtyOffered
          priceOffered
          productId
          alternativeProductName
          alternativeProductBrand
          alternativeProductManufacturerCode
          alternativeProductDesc
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;

const editQuotation = gql`
mutation updateCompanyProduct($productId: ID!,$name: String!, $brand: String, $description: String , $sku: String, $manufacturerSku: String, $price: Float!, $imageFileName: [String!], $isDisplayPriceOnShopfront: Boolean! = false, $isDisplayProductOnShopfront: Boolean! = false, $tags: [ID!], $quotationInitialPriceOffer: Float, $autobidMinimumPrice: Float, $autobidPriceDecrement: Float, $isAutobidEnabled: Boolean! = false) {
  updateCompanyProduct(input: {
      productId: $productId
      companyProductUpdateInputData:
      {
          name: $name,
          brand: $brand,
          description: $description,
          sku: $sku,
          manufacturerSku: $manufacturerSku,
          price: $price,
          imageFileName: $imageFileName,
          isDisplayPriceOnShopfront:  $isDisplayPriceOnShopfront,
          isDisplayProductOnShopfront: $isDisplayProductOnShopfront,
          tags: $tags,
          quotationInitialPriceOffer: $quotationInitialPriceOffer,
          autobidMinimumPrice: $autobidMinimumPrice,
          autobidPriceDecrement: $autobidPriceDecrement,
          isAutobidEnabled: $isAutobidEnabled
      }
  }) {
      __typename,
      ...on CompanyProduct {
          id
          name
          brand
          description
          sku
          manufacturerSku
          price
          imageUrl
          tags{
              id
              tag
          }
          isDisplayPriceOnShopfront
          isDisplayProductOnShopfront
          quotationInitialPriceOffer
          autobidMinimumPrice
          autobidPriceDecrement
          isAutobidEnabled
          state
          publishApprovalStatus
          publishedOngoingApprovalStatus
          isActivatedByUser
          createdDate
          createdBy
          modifiedDate
          modifiedBy
      }
      ... on CompanyProductDraft {
          id
          draftName
          draftBrand
          draftDescription
          draftSku
          draftManufacturerSku
          draftPrice
          draftImageUrl
          draftTags{
              id
              tag
          }
          draftIsDisplayPriceOnShopfront
          draftIsDisplayProductOnShopfront
          draftQuotationInitialPriceOffer
          draftAutobidMinimumPrice
          draftAutobidPriceDecrement
          draftIsAutobidEnabled
          draftCreatedDate
          draftCreatedBy
          draftModifiedDate
          draftModifiedBy
     }
  }
}
`;



const getPoList = gql`
query getPoList($rfqId: String!){
  getPoList(input: {
      rfqId: $rfqId
  }){
      poRfqData{
          rfqDisplayId
          description
          rfqVendorCategory
          submitQuotationLastDate
          rfqDeliveryCountry
          rfqDeliveryProvince
          rfqDeliveryCity
          rfqDeliveryStreet
          rfqDeliveryPostalCode
          paymentTermRequestedInDays
          etaRequested
          currency
          procurementRefNumber
      }
      totalActiveVendorChoose
      totalActivePrice
      poListData{
          poId
          batchNumber
          isSent
          isVoid
          approvedBy
          approvedDate
          poNumber
          poVendorData{
              vendorName
              isEtaMatch
              isPaymentTermMatch
              isDeliveryMatch
              totalPrice
          }
      }
  }
}
`;


// const getPoDetail = gql`
// query getPoDetail($poId: String!){
//   getPoDetail(input: {
//       poId: $poId
//   }){
//       poNumber
//       procurementDetailAddress{
//           companyName
//           companyMainCountry
//           companyMainProvince
//           companyMainCity
//           companyMainStreet
//           companyMainPostalCode
//           companyMainPhone
//           companyMainEmail
//       }
//       vendorAddress
//       vendorName
//       vendorPhone
//       vendorEmail
//       quotationOfferedDeliveryAddress
//       quotationEtaOffered
//       quotationPaymentTermInDays
//       rfqCurrency
//       procurementRefNumber
//       vendorRefNumber
//       vendorIsTaxIncluded
//       vendorTaxPercentage
//       vendorTaxValue
//       PoItem {
//           productManufacturerCode
//           productBrand
//           productDescription
//           selectedQty
//           productPrice
//       }
//   }
// }
// `;



@Component({
  selector: 'app-rfq-po-detail',
  templateUrl: './rfq-po-detail.component.html',
  styleUrls: ['./rfq-po-detail.component.scss']
})
export class RfqPoDetailComponent implements OnInit {
  rfqId: any
  PoId: any
  quotationId: any =''
  DataRfq: any = []
  DataQuotation: any = []
  PageLoading: boolean = false
  rfqVendorCategory: any
  displaydone: string = 'none'
  displayconfirm: string = 'none'
  displayerror: string = 'none'
  errortext: string = ''
  divFhSpec: any = []
  EditQuotationId: any = null
  successdraft: boolean = false
  rfqFormInvalid: boolean = false
  Valdescription: string = ''
  submitted = false;
  ValcompanyLocationId: string = ''
  ValrfqVendorCategory: string = ''
  selectedCat: any = []
  modalCategories: boolean = false
  tags: any = []
  SelectedTags: any = []
  ValCountry: string = ''
  getCompanyLocation: any
  ValpaymentTermRequestedInDays: string = ''
  ValetaRequested: any
  minDate: any
  ValsubmitQuotationLastDate: any
  Valcurrency: string = ''
  countries: any;
  ValprocurementRefNumber: string = ''
  ValShipTo: string = ''
  ValisForFullQuote: boolean = false
  ValisIndentAccepted: boolean = false
  ValisAutoBid: boolean = false
  emptyitem: boolean = false;
  dataCategoryList: any = null
  AlldataCategoryList: any = []
  categoryfirst: number = 12
  categoryendCursor: any = ''
  categoryhasNextPage: any = true
  categoryhasPrevPage: any = false
  allCategoryLoaded: boolean = false
  CategoryPage: number = 0
  LastCategoryPage: number = 0
  CategoryShown: any = []
  rfqForm = this.formBuilder.group({
    companyLocationId: ['', Validators.required],
    description: [''],
    submitQuotationLastDate: ['', Validators.required],
    paymentTermRequestedInDays: ['', Validators.required],
    etaRequested: [''],
    currency: ['', Validators.required],
    procurementRefNumber: [''],
    isForFullQuote: [''],
    isIndentAccepted: [''],
    rfqItem: [''],
    rfqVendorCategory: ['', Validators.required],
    country: ['', Validators.required]
  });
  btnloading: boolean = false
  ValrfqItem: string = ''
  ArrRfqItem: any = []
  cateories = [
    {
      title: 'Accordion Item 1',
      children: [{ title: 'Child 1.1' }, { title: 'Child 1.2' }]
    },
    {
      title: 'Accordion Item 2',
      children: [{ title: 'Child 2.1' }, { title: 'Child 2.2' }]
    },
    {
      title: 'Accordion Item 3',
      children: [
        { title: 'Child 3.1' },
        { title: 'Child 3.2' },
        { title: 'Child 3.3' }
      ]
    }
  ];
  productList: any = []
  Valname: any = []
  Valbrand: any = []
  ValdescriptionProduct: any = []
  ValqtyNeeded: any = []
  ValmanufacturerSku: any = []
  ValqtyUnit: any = []
  accordionStatus: any = []
  imageSrc: string = ''
  imageSrcMore: any = []
  ValimageMore: any = []
  MoreImgCount = new Array(3);
  MoreImgArr: any = []
  CombineImg: any = []
  ValimageFileName: string = ''
  valspecLabel: any = []
  valspecValue: any = []
  emptyLabelSpec: any = []
  emptyValueSpec: any = []
  ValSpecEmpty: any = []
  valspecValueErr: any = []
  valspecLabelErr: any = []
  countarrFhSpec: any = []
  dropdownListTag: any;
  selectedItemsTag: any = [];
  dropdownSettingsTag: any;
  successResponse: boolean = false;
  successResponseName: string = ''
  displaydraft = 'none'
  actionbtn: any = '' //draft , preview, done
  message: string | undefined;
  subscription: Subscription | undefined;
  arrCategoryfromEdit: any = [];

  QuotationItemList:any =[]
  alternativeItemList: any = [];

  keyword = 'name';
  public productAutoComplete = [];



  StatusApproval:number=1 // 1->OPEN , 2->REQUESTED, 4->APPROVED
  Valstate:string=''
  LoadingreqeustApproval:boolean=false
  displayApproveReject='none'
  displaytimeline='none'
  CanApproveReject:boolean=false
  successpublish='none'
  successvoid='none'
  ongoingApproval: boolean = false;
  totalQuotationPrice: number = 0;
  arrMoreLessDesc: any=[];
  getPoListData: any=[];
  getPoDetail:any;
  totalPODetail:number=0
  totalPOTax:number=0
  // CheckIsSent:boolean=false
  // CheckIsVoid:boolean=false

  poIdData:any
  getValueTax:number=0

  viewBy:string=''
  datapoDetail:any 

  private history: string[] = []
  constructor(
    private apollo: Apollo,
    private navigation: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private rfqvendorservice: RfqVendorService,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
  ) { 
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.history.push(event.urlAfterRedirects)
    //   }
    // })
  }

  ngOnInit(): void {
    console.log('rfq-participating-in-detail', 'ngOnInit()')
    this.rfqId = this.route.snapshot.paramMap.get('RfqId');
    this.PoId = this.route.snapshot.paramMap.get('PoId');

    var href = this.router.url;
    console.log(href.split('/')[1]);
    if(href.split('/')[1]){
      this.viewBy = href.split('/')[1].toLowerCase()
    }


    this.quotationId = this.route.snapshot.paramMap.get('QuotationId');
    if(this.quotationId!=='null'){
      console.log('>>quotationId true',  this.quotationId)
    } else {
      console.log('>>quotationId false',  this.quotationId)
    }
    
    console.log('rfq-participating-in-detail', 'this.rfqId', this.rfqId)
    this.PageLoading = true
    console.log('rfq-participating-in-detail', 'this.PageLoading', this.PageLoading)
    //this.rfqDetail()
    this.poDetail()


    var usercompany = localStorage.getItem("USERCOMPANY")
    console.log('usercompany --- ',usercompany);
    //check if owner, show approve or reject

    if(usercompany){
      var arrUserCompany = JSON.parse(usercompany)
      console.log(arrUserCompany.roleMembership)
     //roleMembership
      // if (1,2,3) //1,2,4 can approve / reject
      if(arrUserCompany.roleMembership==1 || arrUserCompany.roleMembership==2 || arrUserCompany.roleMembership==4){
        this.CanApproveReject=true
      }

    }
    

  }

  back(): void {
    this.navigation.back()
    window.close()

    // console.log('history : ',this.history)
    // // this.history.pop()
    // // console.log('history : ',this.history)
    // if (this.history.length > 0) {
    //   this.navigation.back()
    // } else {
    //   //this.router.navigateByUrl('/')
    //   window.close()
    // }
  }

  formatDisplayId(displayId: any) {
    var re = new RegExp('.{1,4}', 'g');
    return displayId?.match(re).join("-");
  }

  formatShortDesc(shortdesc: any) {
    var desctext = shortdesc;
    var a = shortdesc;
    var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
    console.log('rfq-participating-in-detail', 'getdetaildesc', getdetaildesc);
    if (getdetaildesc) {
      console.log('rfq-participating-in-detail', 'getdetaildesc[0]', getdetaildesc[0]);
      console.log('rfq-participating-in-detail', 'getdetaildesc[1]', getdetaildesc[1]);

      this.divFhSpec = getdetaildesc[1]
      this.divFhSpec = JSON.parse(this.divFhSpec)
      return desctext.replace(getdetaildesc[0], '')
    } else {
      return shortdesc;
    }
  }

  // rfqDetail() {
  //   return
  //   console.log('getPoList', 'this.rfqId', this.rfqId)
  //   this.apollo
  //     .mutate({
  //       mutation: getPoList,
  //       variables: {
  //         rfqId: this.rfqId
  //       }
  //     })
  //     .subscribe(
  //       (res: any) => {
  //         console.log('getPoList : ', 'res', res)

  //         this.getPoListData = res.data.getPoList
  //         console.log('>>',this.getPoListData);


       

  //         // this.DataRfq = res.data.participatedRfqDetail.rfqData
  //         // this.DataQuotation = res.data.participatedRfqDetail.quotationData
  //         // console.log('rfq-participating-in-detail', 'this.DataRfq', this.DataRfq)
  //         // console.log('DataQuotation > ', this.DataQuotation)


  //         // this.getPoListData.poListData.forEach((e:any,i:any) => {
  //         //   this.CheckIsSent = this.getPoListData.poListData[i].isSent
  //         //   this.CheckIsVoid = this.getPoListData.poListData[i].isVoid        
  //         // })
  //         // this.CheckIsSent = true
  //         // this.CheckIsVoid = true
          

  //         // this.rfqVendorCategory = this.DataRfq.rfqVendorCategory.map((val: any) => { return val }).join(", ")
  //         // if(this.DataQuotation?.displayQuotationId){
  //         //   this.EditQuotationId = this.formatDisplayId(this.DataQuotation.displayQuotationId)
  //         // }

  //         // this.StatusApproval = this.DataQuotation.approvalStatus

  //         // //if changes after published
  //         // if(this.DataQuotation.state =='PUBLISHED' && this.StatusApproval!==4){
  //         //   this.StatusApproval = this.DataQuotation.ongoingApprovalStatus
  //         //   this.ongoingApproval = true
  //         // }
          

  //         // this.Valstate = this.DataQuotation.state
          
  //         // this.productList = this.DataRfq.rfqItem
  //         // this.alternativeItemList = this.DataQuotation?.quotationItemAlternative
  //         // console.log('alternativeItemList',this.alternativeItemList);
  //         // this.QuotationItemList = this.DataQuotation?.quotationItem

  //         // if(this.QuotationItemList){
  //         //   var totals = 0
  //         //   this.QuotationItemList.forEach((obj: any, key: any) => {
  //         //     console.log(obj.priceOffered);
  //         //     totals = totals + (obj.priceOffered * obj.qtyOffered)
  //         //   });
  //         //   this.totalQuotationPrice = totals
  //         // }
          

  //         // this.ValShipTo = ""
  //         // this.ValpaymentTermRequestedInDays = this.DataQuotation?.paymentTermOfferedInDays
  //         // if(this.DataQuotation?.etaOffered){
  //         //   this.ValetaRequested = new Date(this.DataQuotation.etaOffered)
  //         // }
          
  //         // this.countarrFhSpec.push([])
  //         // this.countarrFhSpec.push([])
  //         // this.valspecLabel.push('')
  //         // this.valspecValue.push('')
  //         // this.ValSpecEmpty.push(false)
  //         // this.valspecValueErr.push(false)
  //         // this.valspecLabelErr.push(false)
  //         // this.emptyLabelSpec.push([{ error: false }])
  //         // this.emptyValueSpec.push([{ error: false }])
  //         // this.emptyLabelSpec.push([{ error: false }])
  //         // this.emptyValueSpec.push([{ error: false }])
  //         this.PageLoading = false
  //       },
  //       error => {
  //         this.PageLoading = false
  //         this.getPoListData = []
  //         console.log('rfq-participating-in-detail', 'error queryRfq', error);

  //         const modalRef = this.modalService.open(ModalComponent, {
  //           modalDialogClass: 'modal-dialog-centered',
  //           centered: true,
  //           backdrop : 'static',
  //           keyboard : false,
  //           size: 'sm'
  //         })
  //         modalRef.componentInstance.modalinfo = 'info'
  //         modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
  //         modalRef.componentInstance.infoSuccess = null
  //         modalRef.componentInstance.infoFailed = error
  //         modalRef.componentInstance.closeTxt = 'Close'
  
  //         modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
  //           if (res){
  //             // this.router.navigate(['/']);
  //           } 
  //         })

  //       })
  // }
  poDetail() {

    this.rfqvendorservice.getPoDetail(this.PoId).subscribe((res:any) => {
      this.PageLoading=false
      console.log(res)
      if(res.data?.getPoDetail){
        // this.isMessageStar(msgId)
        this.datapoDetail = res.data?.getPoDetail

          if(this.datapoDetail?.PoItem){
            var totals = 0
            this.datapoDetail?.PoItem.forEach((obj: any, key: any) => {
              console.log(obj.productPrice);
              totals = totals + (obj.productPrice * obj.selectedQty)
            });
            this.totalQuotationPrice = totals
          }

          //calculate tax
          if(this.datapoDetail?.vendorIsTaxIncluded){
            if(this.datapoDetail?.vendorTaxValue){
              this.getValueTax = this.datapoDetail?.vendorTaxValue
            }
            if(this.datapoDetail?.vendorTaxPercentage){
              this.getValueTax = this.totalQuotationPrice * this.datapoDetail?.vendorTaxPercentage /100
            }
          }
          

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });

  }

  closeError() {
    this.displayerror = 'none'
  }

  onSubmit() {

  }

  closesuccessdraft() {
    this.successdraft = false
  }

  closerfqFormInvalid() {
    this.rfqFormInvalid = false
  }

  get f() { return this.rfqForm?.controls; }

  modalCategory() {
    (this.modalCategories) ? this.modalCategories = false : this.modalCategories = true
  }

  getCategoryName(id: any) { }

  removeCat(id: any) { }

  onCheckisForFullQuote(check: any) {
    if (check.target.checked) {
      console.log('onCheckisForFullQuote checked')
      this.ValisForFullQuote = true
    } else {
      console.log('onCheckisForFullQuote unchecked')
      this.ValisForFullQuote = false
    }
  }

  onCheckisIndentAccepted(check: any) {
    if (check.target.checked) {
      console.log('onCheckisIndentAccepted checked')
      this.ValisIndentAccepted = true
    } else {
      console.log('onCheckisIndentAccepted unchecked')
      this.ValisIndentAccepted = false
    }
  }

  onCheckisAutoBid(check: any) {
    if (check.target.checked) {
      console.log('onCheckIsAutoBid checked')
      this.ValisAutoBid = true
    } else {
      console.log('onCheckIsAutoBid unchecked')
      this.ValisAutoBid = false
    }
  }

  CheckVal(val: any) {
    if (val == '' || !val) {
      return true
    } else {
      return false
    }
  }

  AccordionHideShow(i: any) {
    console.log('--------------');

    if (this.productList[i]['collapse'] == true) {
      this.productList[i]['collapse'] = false
    } else {
      this.productList[i]['collapse'] = true
    }
    this.productList[i]['specification'] = "spec"
    console.log('to', this.productList);
    console.log('--------------');
  }

  AccordionHideShowAlt(i: any) {
    console.log('--------------');

    if (this.alternativeItemList[i]['collapse'] == true) {
      this.alternativeItemList[i]['collapse'] = false
    } else {
      this.alternativeItemList[i]['collapse'] = true
    }
    this.alternativeItemList[i]['specification'] = "spec"
    console.log('to', this.alternativeItemList);
    console.log('--------------');
  }

  removePirmaryImg() {
    this.imageSrc = ''
    this.ValimageFileName = ''
  }

  onChangeTag(val: any, check: any) {
    console.log('onChangeTag', val)
    console.log('check', check)
    if (check.target.checked) {
      this.SelectedTags.push(val)
    } else {
      const index: number = this.SelectedTags.indexOf(val);
      this.SelectedTags.splice(index, 1);
    }
    console.log('this.tags', this.SelectedTags);
  }

  removeProduct(i: any) {
    this.productList.splice(i, 1)
  }

  removeProductAlt(i: any) {
    this.alternativeItemList.splice(i, 1)
  }

  addProductAlt(){
    console.log('>addproduct');
    this.alternativeItemList.forEach((value: any, key: any) => {
      this.alternativeItemList[key]['collapse']=false
      this.alternativeItemList[key]['required']=true
    })

    this.alternativeItemList.push({'collapse':false,'altProduct':null,'required':true})
    console.log('this.alternativeItemList',this.alternativeItemList);


    //desc detail
    /* this.countarrFhSpec.push([])
    this.valspecLabel.push('')
    this.valspecValue.push('')
    this.ValSpecEmpty.push(false)
    this.valspecValueErr.push(false)
    this.valspecLabelErr.push(false)
    this.emptyLabelSpec.push([{error:false}])
    this.emptyValueSpec.push([{error:false}]) */



    //window.scrollTo(0,document.body.scrollHeight);

  }

  addSpec(seq: any) {
    console.log('rfq-participating-in-detail', 'valspecLabel', this.valspecLabel[seq]);
    console.log('rfq-participating-in-detail', 'valspecValue', this.valspecValue[seq]);
    if (this.valspecLabel[seq] == '' || !this.valspecLabel[seq]) {
      this.valspecLabelErr[seq] = true
    } else {
      this.valspecLabelErr[seq] = false
    }
    if (this.valspecValue[seq] == '' || !this.valspecValue[seq]) {
      this.valspecValueErr[seq] = true
    } else {
      this.valspecValueErr[seq] = false
    }

    if (((this.valspecLabel[seq] == '' || !this.valspecLabel[seq]) && (this.valspecValue[seq] == '' || !this.valspecValue[seq])) || ((this.valspecLabel[seq] == '' || !this.valspecLabel[seq]) || (this.valspecValue[seq] == '' || !this.valspecValue[seq]))) {
      this.ValSpecEmpty[seq] = true
    }

    if ((this.valspecLabel[seq] !== '' || this.valspecLabel[seq]) && (this.valspecValue[seq] !== '' || this.valspecValue[seq])) {
      console.log('rfq-participating-in-detail', 'countarrFhSpec start', this.countarrFhSpec[seq]);
      this.countarrFhSpec[seq].push({ label: this.valspecLabel[seq], value: this.valspecValue[seq] })
      this.valspecValue[seq] = ''
      this.valspecLabel[seq] = ''
      this.valspecLabelErr[seq] = false
      this.valspecValueErr[seq] = false

      this.emptyLabelSpec[seq].push({ error: false })
      this.emptyValueSpec[seq].push({ error: false })

      this.ValSpecEmpty[seq] = false
      console.log('rfq-participating-in-detail', 'countarrFhSpec end', this.countarrFhSpec);
    }

  }

  clearSpec(seq: any) {
    this.valspecLabelErr[seq] = false
    this.valspecValueErr[seq] = false
    this.ValSpecEmpty[seq] = false
    this.valspecValue[seq] = ''
    this.valspecLabel[seq] = ''
  }

  firstLabelSpec(seq: any, val: any) {
    if (val?.target.value !== '') {
      this.valspecLabelErr[seq] = false
    } else {
      this.valspecLabelErr[seq] = true
    }
    this.ValSpecEmpty[seq] = true
  }

  firstValueSpec(seq: any, val: any) {
    if (val?.target.value !== '') {
      this.valspecValueErr[seq] = false
    } else {
      this.valspecValueErr[seq] = true
    }
    this.ValSpecEmpty[seq] = true
  }

  removeSpec(seq: any, i: any) {
    console.log('removeSpec:: ', i)
    var index: number = this.countarrFhSpec[seq].indexOf(i);
    if (index !== -1) {
      this.countarrFhSpec[seq].splice(index, 1);

      this.emptyLabelSpec[seq].splice(index, 1)
      this.emptyValueSpec[seq].splice(index, 1)
    }


    console.log('countarrFhSpec remove:: ', this.countarrFhSpec)
  }

  changeValueSpec(seq: any, i: any, val: any) {
    console.log('changeValueSpec:: ', i)

    var index: number = this.countarrFhSpec[seq].indexOf(i);
    if (val?.target.value !== '') {
      this.emptyValueSpec[seq][index].error = false
    } else {
      this.emptyValueSpec[seq][index].error = true
    }

    this.countarrFhSpec[seq].forEach((obj: any, key: any) => {
      if (key == index) {
        console.log('>>', obj);
        this.countarrFhSpec[seq][key].value = val?.target.value
      }
    });

  }

  changeLabelSpec(seq: any, i: any, val: any) {
    console.log('changeLabelSpec:: ', i)
    console.log('val:: ', val?.target.value)

    var index: number = this.countarrFhSpec[seq].indexOf(i);

    if (val?.target.value !== '') {
      this.emptyLabelSpec[seq][index].error = false
    } else {
      this.emptyLabelSpec[seq][index].error = true
    }

    this.countarrFhSpec[seq].forEach((obj: any, key: any) => {
      if (key == index) {
        console.log('>>', obj);
        this.countarrFhSpec[seq][key].label = val?.target.value
      }
    });



  }

  selectEvent(item: any) {
    // do something with selected item
  }


  openModalApproveReject(){
    if(this.displayApproveReject=="none"){
      this.displayApproveReject = "block";
    } else {
      this.displayApproveReject = "none";
    }
  }
  OpenModalTimeLine(){
    if(this.displaytimeline=="none"){
      this.displaytimeline = "block";
    } else {
      this.displaytimeline = "none";
    }
    console.log('displaytimeline',this.displaytimeline);
  }

  reqeustApproval(id:any,action:any){
    console.log('action',action);
    console.log('id',id);

    if(action=='APPROVE' || action=='REJECT'){
      this.openModalApproveReject()
      console.log('appr / reject')
    } else {
      console.log('request')
    }


    this.LoadingreqeustApproval=true

    var qlurl=changeQuotationApprovalStatus
    if(this.ongoingApproval){
      qlurl=changeQuotationDraftApprovalStatus
    }

    this.apollo
    .mutate({
      mutation: qlurl,
      variables: {action:action,quotationId: id}
    })
    .subscribe(
      (res:any) => {
        this.LoadingreqeustApproval=false
        console.log("succes request approval, get data:", res);


        if(action=='APPROVE'){
          this.StatusApproval=4
          console.log('appr reject',this.StatusApproval)
        }
        else if(action=='REJECT'){
          this.StatusApproval=1
        }
        else if(action=='REQUEST'){
          console.log('request')
          this.StatusApproval=2
        }
        else {
          this.StatusApproval=1 
        }
        console.log('StatusApproval',this.StatusApproval)
  
      },
      error => {
        this.LoadingreqeustApproval=false

        console.log('error',error);
        var errMsg = error + '';
        var res = errMsg.split(":");
        var resMsg = res[res.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        alert(msg)
      }
    );






  }


  
  changeStateQuotation(state:any){
    alert('Call api : '+state)
    // this.PageLoading=true

    // this.apollo
    //   .mutate({
    //     mutation: changeQuotationState,
    //     variables: { quotationId: this.quotationId, action:state } // publish void close
    //   })
    //   .subscribe(
    //     (res: any) => {
    //       this.Valstate=state
    //       this.PageLoading = false
    //       console.log('success',res);


    //       if(state=='PUBLISH'){
    //         this.successpublish='block'
    //       }
    //       else if(state=='VOID'){
    //         this.successvoid='block' 
    //       }
          
    //       this.rfqDetail()
    //     },
    //     error => {
    //       this.PageLoading = false
    //       console.log('error',error);
    //       var errMsg = error + '';
    //       var res = errMsg.split(":");
    //       var resMsg = res[res.length - 1];
    //       var msg = (resMsg)? resMsg: errMsg
    //       alert(msg)
    //     })
  }

  closeModal(){
    this.successpublish='none'
    this.successvoid='none' 
  }

  onFocused(e: any) {
    // do something
  }

  openQuotation() {
    location.href = "vendor/rfq/" + this.rfqId + "/" + this.quotationId
  }

  divDetailspec(val:any){
    return Util.formatShortDesc(val) 
  }
  divDetailspecLess(val:any){
    //return Util.formatShortDesc(val)
    var checkChar = Util.formatShortDesc(val)

    if(checkChar?.length>50){
      checkChar = checkChar.substring(0,50) + '...'
    }
    return checkChar

  }

  divspec(val:any){
    return Util.fhspectocomma(val)
  }

  mappingProductWithOffer(idproduct:any,param:any){
    var newObj:any=null
    this.QuotationItemList.forEach((obj: any, key: any) => {
      if (obj.rfqItemId == idproduct) {
        
        if(param=='productDescOffered'){
          newObj = this.divDetailspec(this.QuotationItemList[key][param])
        }else if(param=='productDescOfferedSpec'){
          newObj = this.divspec(this.QuotationItemList[key]['productDescOffered'])
        }else{
          newObj = this.QuotationItemList[key][param]
        }

      }
    });
    return newObj
  }

  mappingProductfromRfq(idproduct:any,param:any){
    var newObj:any=null
    this.productList.forEach((obj: any, key: any) => {
      if (obj.id == idproduct) {
        if(param=='description'){
          newObj = this.divDetailspecLess(this.productList[key][param])
        } else {
          newObj = this.productList[key][param]
        }
      }
    });
    return newObj
  }

  transformDecimal(num:any) {
    // return this._decimalPipe.transform(num, '1.2-2');
    return formatNumber(Number(num), 'en-US', '1.0-0')
  }
  MoreLessDesc(i:any,action:string){
    this.arrMoreLessDesc[i]=action; 
    console.log('arrMoreLessDesc',this.arrMoreLessDesc);
  }

  getDetailPpo(poId:string,param:string){
    var res = null

    var data = this.getPoListData?.poListData
    if(data){
      data.forEach((value: any, key: any) => {

        if(value.poId==poId){
            // data[key].forEach((v: any, k: any) => {
            //   if(k==param){
            //     res = v
            //   }
            // })
            res = data[key][param]
        }
      });
    }
    return res

  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'highlight' })

export class HighLightPipe implements PipeTransform {
    // transform(text: string, search:any): string {
    //     return search ? text.replace(new RegExp(search, 'i'), `<span class="highlight">${search}</span>`) : text;
    // }

    transform(text: string, search:any, index:any): string {
        return search ? text.replace(new RegExp(search, 'i'), `<span class="highlight filter_`+index+`">${search}</span>`) : text;
    }

}
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import CheckErrortype from 'src/app/_helpers/check-errortype';

declare var require: any;

// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import Util from 'src/app/_helpers/util';
import { RfqVendorService } from 'src/app/_services/rfqvendor.services';
const htmlToPdfmake = require("html-to-pdfmake");
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-rfq-po-export',
  templateUrl: './rfq-po-export.component.html',
  styleUrls: ['./rfq-po-export.component.scss']
})
export class RfqPoExportComponent implements OnInit {
  PoId: any
  PageLoading: boolean = false
  datapoDetail:any 
  totalQuotationPrice: number = 0;
  getValueTax:number=0
  isPrint:boolean=false
  companyLogo:any
  companyBase64:any
  constructor(
    private route: ActivatedRoute,
    private rfqvendorservice: RfqVendorService,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
    protected _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.PoId = this.route.snapshot.paramMap.get('PoId');
    this.poDetail()

   

    if(localStorage.getItem("COMPANY_LOGO")){
      this.companyLogo = localStorage.getItem("COMPANY_LOGO")
      this.toDataURL(this.companyLogo, (dataUrl:any) => {
        if(dataUrl){
          //base64 to image
          var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
          var ximgdata = new File([u8arr], 'company.logo', {type:mime});
          this.companyBase64 = ximgdata
        }
      })

    }

  }

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  
  printPage() {
    this.isPrint=true
    if(this.isPrint){
      window.print();
      window.close();
    }

  }

  async downloadPdf() {

    let data = document.getElementById("exportpdf"); 
    // let data = document.getElementById("maindiv");
    console.log(data);  
    if(data){

      data.style.overflow = "inherit";
      data.style.maxHeight = "inherit";

    await html2canvas(data, { scrollY: -window.scrollY, scale: 1 }).then(
      canvas => {
        const contentDataURL = canvas.toDataURL("image/png", 1.0);
        // enabling the scroll
        // document.getElementById("alldata").style.overflow = "scroll";
        // document.getElementById("alldata").style.maxHeight = "150px";

        let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF

        let imgWidth = 210; //300
        let pageHeight = pdf.internal.pageSize.height;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        // window.open(
        //   pdf.output("bloburl", { filename: "new-file.pdf" }),
        //   "_blank"
        // );

        // pdf.addImage(contentDataURL, 'PNG', 0, 0,21.0, 29.7);  

        const dt = new Date();
        const padL = (nr:any, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

        pdf.save(''+dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())+'.pdf');  
      }
    );
    }
  }


  poDetail() {

    this.rfqvendorservice.getPoDetail(this.PoId).subscribe((res:any) => {
      this.PageLoading=false
      console.log(res)
      if(res.data?.getPoDetail){
        // this.isMessageStar(msgId)
        this.datapoDetail = res.data?.getPoDetail

          if(this.datapoDetail?.PoItem){
            var totals = 0
            this.datapoDetail?.PoItem.forEach((obj: any, key: any) => {
              console.log(obj.productPrice);
              totals = totals + (obj.productPrice * obj.selectedQty)
            });
            this.totalQuotationPrice = totals
          }

          //calculate tax
          if(this.datapoDetail?.vendorIsTaxIncluded){
            if(this.datapoDetail?.vendorTaxValue){
              this.getValueTax = this.datapoDetail?.vendorTaxValue
            }
            if(this.datapoDetail?.vendorTaxPercentage){
              this.getValueTax = this.totalQuotationPrice * this.datapoDetail?.vendorTaxPercentage /100
            }
          }
          
          setTimeout(() => this.downloadPdf(),2000);
          // setTimeout(() => console.log('xxx'));


      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });

  }
  formatDisplayId(displayId: any) {
    var re = new RegExp('.{1,4}', 'g');
    return displayId?.match(re).join("-");
  }
  divDetailspec(val:any){
    return Util.formatShortDesc(val) 
  }
  
  //img url to base64
  toDataURL(url:string, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}


<!-- 8 -->
<div class="text-center pt-5 pb-5" *ngIf="state=='loading'">
    <div class="bggraddiv h-100">
        <div class=" mt-5 row justify-content-center g-0">
            <div class="col-sm-6 pt-5 pb-5 bg-white loader loader--style8">
                <div class="pt-5 mt-5"></div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                  <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                  <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                </svg>
                <div class="pb-5 mb-5"></div>
            </div>
        </div>

    </div>
    <div class="bggradlayer"></div>

</div>  

<div *ngIf="state=='submitdata'" class="bggraddiv">


    <div class="container">
        <div class="row justify-content-center pt-5 pb-5 mb-2 g-0">

            <div class="col-sm-12 col-md-6 col-lg-6 bg-white divshadow">
                <div class="w-100 text-center fhgrad">
                    <a href="/"><img class="img-fluid fh-logo-div pt-2 pb-2" src="/assets/images/logofh-cepetdapet.png"></a>
                </div>
                <div class="paddinglogin divlogin">
                    <h3 class="f1 pb-0 mb-0 texttitlelogin"><strong>Welcome to CepetDapet !</strong></h3>
                    <p class="f1l pt-0 mt-0 mb-4 textlogin">Please input your name and password below</p>

                    <form [formGroup]="detailinfoForm" (ngSubmit)="onSubmit()">
                        <div class="form-group mb-3">
                            <label for="name">Owner Name <font class="text-danger">*</font></label>
                            <input type="text" [(ngModel)]="ValName" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                            <div
                            class="errtxt"
                            role="alert"
                            *ngIf="submitted && f?.name?.errors"
                            >
                            <div *ngIf="f?.name?.errors?.required">Owner Name is required</div>
                            </div>
                        </div>
                    

                        <div class="form-group mb-3">
                            <label for="backupemail">Backup Email</label>
                            <input type="text" max="254" [(ngModel)]="ValBackupemail" formControlName="backupemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.backupemail?.errors }" />
                            <div
                            class="errtxt"
                            role="alert"
                            *ngIf="submitted && f?.backupemail?.errors"
                            >
                            <div *ngIf="f?.backupemail?.errors?.required">Backup Email is required</div>
                            <div *ngIf="f?.backupemail?.errors?.pattern">Backup email must be a valid email address</div>
                            <div *ngIf="f.backupemail.errors?.MustDifferent">Backup email must different with Email</div>
                            </div>
                        </div>


                        <div class="form-group mb-3">
                            <label for="password">Password <font class="text-danger">*</font></label>
                            <input type="password" minlength="8" maxlength="64" [(ngModel)]="ValPassword" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.password?.errors }" />
                            <div
                            class="errtxt"
                            role="alert"
                            *ngIf="submitted && f?.password?.errors"
                            >
                            <div *ngIf="f?.password?.errors?.required">Password is required</div>
                            <div *ngIf="f?.password?.errors?.minlength">Password must be at least 8 characters</div>
                            </div>
                        </div>


                        <div class="form-group mb-3">
                            <label for="retypepassword">Re-type password <font class="text-danger">*</font></label>
                            <input type="password" [(ngModel)]="ValRetypePassword" formControlName="retypepassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.retypepassword?.errors }" />
                            <div
                            class="errtxt"
                            role="alert"
                            *ngIf="submitted && f?.retypepassword?.errors" 
                            >
                            <div *ngIf="f?.retypepassword?.errors?.required">Re-type password is required</div>
                            </div>

                            <div
                            class="errtxt"
                            role="alert"
                            *ngIf="submitted && f.retypepassword.errors?.mustMatch" 
                            >
                            <div>Password must same with Re-type Password</div>
                            </div>
                        </div>


                        <div class="form-group mt-3">
                            <button *ngIf="!btnloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2">
                                Submit
                            </button>
                            <button *ngIf="btnloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2" disabled>
                            Loading..
                            </button>
              
                        </div>



                    </form>


                </div>
            </div>  
        </div>

    </div>
    <div class="bggradlayer"></div>

        <form [formGroup]="detailinfoForm" (ngSubmit)="onSubmit()" class="d-none">
            <div class="row mt-5">
                <div class="col-sm-2">

                </div>
                <div class="col-sm-8">

                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="mb-3 row">
                                <h4 class="text-center">Registration</h4>
                            </div>

                            <div class="mb-3 row">
                                <label for="name" class="col-sm-3 col-form-label">Owner Name</label>
                                <div class="col-sm-9">
                                    <input type="text" [(ngModel)]="ValName" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                                    <div *ngIf="submitted && f?.name?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.name?.errors?.required">Owner Name is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="backupemail" class="col-sm-3 col-form-label">Backup Email</label>
                                <div class="col-sm-9">
                                    <input type="text" max="254" [(ngModel)]="ValBackupemail" formControlName="backupemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.backupemail?.errors }" />
                                    <div *ngIf="submitted && f?.backupemail?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.backupemail?.errors?.required">Backup Email is required</div>
                                        <div *ngIf="f?.backupemail?.errors?.pattern">Backup email must be a valid email address</div>
                                        <div *ngIf="f.backupemail.errors?.MustDifferent">Backup email must different with Email</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="password" class="col-sm-3 col-form-label">Password</label>
                                <div class="col-sm-9">
                                    <input type="password" minlength="8" maxlength="64" [(ngModel)]="ValPassword" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.password?.errors }" />
                                    <div *ngIf="submitted && f?.password?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.password?.errors?.required">Password is required</div>
                                        <div *ngIf="f?.password?.errors?.minlength">Password must be at least 8 characters</div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label for="retypepassword" minlength="8" maxlength="64" class="col-sm-3 col-form-label">Re-type password</label>
                                <div class="col-sm-9">
                                    <input type="password" [(ngModel)]="ValRetypePassword" formControlName="retypepassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.retypepassword?.errors }" />
                                    <div *ngIf="submitted && f?.retypepassword?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.retypepassword?.errors?.required">Re-type password is required</div>
                                    </div>
                                    <div *ngIf="submitted && f.retypepassword.errors?.mustMatch" class="invalid-feedback">Password must same with Re-type Password</div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9">
                                    <button *ngIf="!btnloading" class="btn btn-secondary">Submit</button>
                                    <button *ngIf="btnloading" class="btn btn-secondary" disabled>Loading..</button>
            
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    
                </div>

                <div class="col-sm-12">
                    <div class="mb-3 text-center mt-3">
                        <a href="" class="btn btn-link">Back</a>
                    </div>
                </div>
            </div>
        </form>  
</div>


<div *ngIf="state=='expired'" class="bggraddiv">


    <div class="container">
        <div class="row justify-content-md-center pt-5 pb-5">

 



            <div class="col-md-4">
                <div class="card divshadow mt-5 mb-5 border-0 rounded-1">
                    <div class="card-line-danger"></div>
                    <div class="card-body">

                        <div class="text-center mt-5">
                            <img src="/assets/images/icons/Group 518-2.png" class="img-fluid">
                        </div>

                        <br>
                        <div class="row justify-content-center mb-3">
                            <div class="col-9 text-center ">
                                <h3 class="f1">Token Expired!</h3>
                                <!-- <h5 class="card-title">Sorry, an error occured. The token is expired or invalid.</h5> -->
                                <h5 class="card-title">{{errortext}}</h5>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer border-0 bg-white">
                        <div class="text-center mb-4">
                            <a href="/regis" class="text-center f1 btn btn-secondary btn-failed btn-block w-50  pt-2 pb-2">Back</a>
                        </div> 
                    </div>
                </div>    
            </div>  
        </div>  
    </div>         
    <div class="bggradlayer"></div>

    
    <!-- <div class="alert alert-danger text-center mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Token Expired</h4>
        <a href="" class="btn btn-danger mt-2">Back</a>
      </div> -->
</div>

<div *ngIf="state=='success'" class="bggraddiv">

    <div class="row justify-content-center g-0">

        <div class="col-auto mb-5">
              
            <div class="card divshadow mt-5 mb-5 border-0 rounded-1">
              <div class="card-line"></div>
              <div class="card-body">

                  <div class="text-center mt-4">
                      <img src="/assets/images/icons/Group 518.png" class="img-fluid">
                  </div>

                  <br>
                  <div class="row justify-content-center mb-3">
                      <div class="col-9 text-center ">
                        <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
                        <p class="text-center mt-0 fw-bold mb-0">Registration Completed</p>
                      </div>
                  </div>

              </div>
              <div class="card-footer border-0 bg-white">
                  <div class="text-center mb-4">
                      <a href='/login' class="text-center f1 btn btn-secondary btn-success btn-block w-50  pt-2 pb-2 mtmSuccessRegistrationBtn">Done</a>
                  </div> 
              </div>
            </div>    
          </div> 


        <!-- <div class="mt-5 mb-5 col-sm-4 divshadow bg-white">
              <div class="text-center mt-3">
                <img class="draftimgicon" src="/assets/images/icons/done-save.png">
              </div>  
              <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
              <p class="text-center mt-0 mb-4 fw-bold">Registration Completed</p>
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 pb-3">
                <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" href='/login'>Done</a>
              </div>
        </div> -->

    </div>
    <div class="bggradlayer"></div>

    <!-- <div class="alert alert-success text-center mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Registration Completed</h4>
        <a href="login" class="btn btn-success mt-2">Login</a>
      </div> -->
</div>

<div *ngIf="state=='alredyverified'" class="bggraddiv">

    <div class="row justify-content-center g-0 mb-3">
        <div class="mt-5 mb-5 col-sm-4 divshadow bg-white border-0 rounded-1">
            <div class="card-line"></div>
            <div class="p-3">
                <div class="text-center py-5">
                    <img src="/assets/images/icons/Group 518.png" class="img-fluid">
                </div>
                  <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
                  <p class="text-center mt-0 mb-4 fw-bold">Successfully verified</p>
                  
                  <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 pb-3">
                    <a class="btn btn-secondary f1 btn-success fw-bold pt-2 pb-2 pe-5 ps-5 mtmSuccessRegistrationBtn" data-bs-dismiss="modal" href='/login'>Done</a>
                  </div>
            </div>

        </div>

    </div>
    <div class="bggradlayer"></div>

    <!-- <div class="alert alert-success text-center mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Registration Completed</h4>
        <a href="login" class="btn btn-success mt-2">Login</a>
      </div> -->
</div>

<div class="">
<app-footer></app-footer>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import Util from 'src/app/_helpers/util';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    console.log('auth.guard ..')
    if (this.authService.isLoggedIn()) {
      console.log('authService.isLoggedIn');


              // //--redirect dashboard--//
              // var getusercompany = localStorage.getItem("USERCOMPANY")
              // var redirect = '/';
              // console.log('redirectDasboard 2 : ', usercompany);
              // if (getusercompany !== null) {
              //   var usercompany = JSON.parse(getusercompany);
              //   redirect = Util.redirectDasboard(usercompany.roleMembership)

              //   if(this.router.url == redirect){
              //     location.reload() 
              //   } else {
              //     this.router.navigate([redirect]);
              //   }

              // } else {
              //   this.router.navigate([redirect]);
              // }
              // //return
              // //--redirect dashboard--//

      this.router.navigate(['/dashboard']);
      // //this.router.navigate(['/']);
    }
    return !this.authService.isLoggedIn();
  }
}

<div class="card mb-4 d-none {{forceMobile?'':'d-sm-block'}}">  <!-- hide on small // d-none d-sm-block -->
  <div class="card divrfqlist hvshadow">
    <a *ngIf="rfq?.url" href="{{rfq?.url}}">
      <div class="card-header card-header-bg bg-dark">
        <div class="row c3 fw-bold">
          <div class="col-3 text-start ps-4 pe-0">
            <i class="bi bi-geo-alt-fill geo-color"></i>

            {{rfq?.city}}
            {{(rfq?.city && rfq?.country) ? ',':' '}}
            {{rfq?.country}}

          </div>
          <div class="col-6 text-center">

            {{rfqType=='procurement'?'Valid until':'Submit quote before'}} : {{rfq?.submitQuotationLastDate | date:'d MMM y'}}
            <span *ngIf="rfq?.sponsored" class="badge rounded-pill card-header-bg text-success border border-3 border-success ms-2">SPONSORED</span>
          </div>
          <div class="col-3 text-end pe-4">Vendor Participating : {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
        </div>
      </div>
    </a>
    <div  *ngIf="!rfq?.url" class="card-header card-header-bg bg-dark">
      <div class="row c3 fw-bold">
        <div class="col-3 text-start ps-4 pe-0">
          <i class="bi bi-geo-alt-fill geo-color"></i>

          {{rfq?.city}}
          {{(rfq?.city && rfq?.country) ? ',':' '}}
          {{rfq?.country}}

        </div>
        <div class="col-6 text-center">
          {{rfqType=='procurement'?'Valid until':'Submit quote before'}} : {{rfq?.submitQuotationLastDate | date:'d MMM y'}}
          <span *ngIf="rfq?.sponsored" class="badge rounded-pill card-header-bg text-success border border-3 border-success ms-2">SPONSORED</span>
        </div>
        <div class="col-3 text-end pe-4">Vendor Participating : {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
      </div>
    </div>
    <div class="card-body">

      <div class="row text-start px-3 fw-bold">
        <div class="col-9">


          
        </div>
        <div class="col-3 text-end">

        </div>
      </div>
      <div class="row text-start px-3">
        <!-- <div class="col-12">
          <p class="card-text">{{divDetailspec(rfq?.description)}}</p>
        </div> -->
        <div class="col-8">
          <div class="pt-1">
            <a *ngIf="rfq?.url" href="{{rfq?.url}}">
              <div [ngSwitch]="rfq?.rfqState | uppercase">
                <h5 *ngIf="(rfq?.isAlreadyParticipating)" class="fw-bold">{{ rfq?.procurementName }} <font class="participatingdiv ms-2">{{ (rfq?.isAlreadyParticipating) ? 'PARTICIPATING' : '' }}</font></h5>
              </div>
            </a>
          </div>

          <div *ngIf="!rfq?.url" [ngSwitch]="rfq?.rfqState | uppercase">
            <h5 *ngIf="(rfq?.isAlreadyParticipating)" class="fw-bold">{{ rfq?.procurementName }} <font class="participatingdiv ms-2">{{ (rfq?.isAlreadyParticipating) ? 'PARTICIPATING' : '' }}</font></h5>
          </div>

          <div class="fw-bold">
            <a *ngIf="rfq?.url" href="{{rfq?.url}}">
              Category : {{rfq?.rfqVendorCategory}}
            </a>
            <font *ngIf="!rfq?.url">
              Category : {{rfq?.rfqVendorCategory}}
            </font>
          </div>



          <a *ngIf="rfq?.url" href="{{rfq?.url}}">
            <ul>
              <ng-container *ngFor="let items of rfq?.rfqItem ; let i = index">
                <li *ngIf="i < config.maxLimitItemShow">
                  {{(items.brand)?items.brand+' : ':''}} 
                  {{(items.description)? divDetailspec(items.description):''}}
                </li>
              </ng-container>
              <!-- <div *ngIf="rfq?.rfqItem.length > config.maxLimitItemShow">...</div> -->
              <div *ngIf="rfq?.haveMoreRfqItem">...</div>
              
            </ul>
          </a>
          <ul *ngIf="!rfq?.url" >
            <ng-container *ngFor="let items of rfq?.rfqItem ; let i = index">
              <li *ngIf="i < config.maxLimitItemShow">
                {{(items.brand)?items.brand+' : ':''}} 
                {{(items.description)? divDetailspec(items.description):''}}
              </li>
            </ng-container>
            <!-- <div *ngIf="rfq?.rfqItem.length > config.maxLimitItemShow">...</div> -->
            <div *ngIf="rfq?.haveMoreRfqItem">...</div>
            
          </ul>
        </div>
        <div class="col-4 mt-0 text-end">
          <a *ngIf="rfq?.url" href="{{rfq?.url}}">
            <strong>RFQ # {{this.formatDisplayId(rfq?.rfqDisplayId)}}</strong> 
          </a>
          <strong *ngIf="!rfq?.url">RFQ # {{this.formatDisplayId(rfq?.rfqDisplayId)}}</strong> 

          <div [ngSwitch]="rfq?.rfqState | uppercase">

            <div class="mb-1">
            <span *ngSwitchCase="'UNPUBLISHED'"
              class="badge rounded-pill bg-unpublished ms-3">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'PUBLISHED'"
              class="badge rounded-pill bg-published ms-3">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'EXPIRED'"
              class="badge rounded-pill bg-expired ms-3">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'VOID'"
              class="badge rounded-pill bg-void ms-3">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'HALT'"
              class="badge rounded-pill bg-void ms-3 text-uppercase">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{'text.halt' | translate}}</span>
            <span *ngSwitchCase="'CLOSED'"
              class="badge rounded-pill bg-void ms-3">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>   
            </div>


            <div *ngIf="rfq?.ongoingApprovalStatus">
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate3" *ngIf="rfq?.approvalStatus==2">ON GOING APPROVAL REQUESTED</span>
            </div>
            <div *ngIf="!rfq?.ongoingApprovalStatus">
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate1" *ngIf="rfq?.approvalStatus==4">APPROVED</span>
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate2" *ngIf="rfq?.approvalStatus==1">OPEN</span>
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate3" *ngIf="rfq?.approvalStatus==2">APPROVAL REQUESTED</span>
            </div>

            <strong *ngIf="rfq?.haveActivePO" class="mt-1">
              <img src="/assets/images/icons/ic_outline-request-quote.png" class="outlineicon pb-2"> 
              <font *ngIf="!rfq?.isPoSent">Proforma PO Ready</font>
              <font *ngIf="rfq?.isPoSent">PO sent</font>
            </strong>

            <strong *ngIf="rfq?.poId" class="mt-1">
              <img src="/assets/images/icons/ic_outline-request-quote.png" class="outlineicon pb-2"> Proforma PO Ready
            </strong>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mb-4 d-block  {{forceMobile?'':'d-sm-none'}}"> <!-- show on small //d-block d-sm-none -->
  <div class="card divrfqlist hvshadow">
    <a *ngIf="rfq?.url" href="{{rfq?.url}}">
      <div class="card-header card-header-bg bg-dark p-2">
        <div class="row c3 fw-bold g-1" *ngIf="!rfq?.sponsored">
          <div class="col-5 text-start limitTxt">
            <i class="bi bi-geo-alt-fill geo-color"></i>
            {{(rfq?.city)?rfq?.city:rfq?.country}}
            <!-- ,{{rfq?.country}} -->
          </div>
          <div class="col-4 text-start limitTxt"><i class="bi bi-calendar-event"></i> {{rfq?.submitQuotationLastDate | date:'d/M/y'}}
          </div>
          <div class="col-3 text-end limitTxt"><i class="bi bi-people-fill"></i> {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
        </div>
        <div class="row c3 fw-bold g-0" *ngIf="rfq?.sponsored">
          <div class="col-3 text-start">
            <i class="bi bi-geo-alt-fill geo-color"></i>
            {{(rfq?.city)?rfq?.city:rfq?.country}}
            <!-- ,{{rfq?.country}} -->
          </div>
          <div class="col-7 text-center">
            <span class="badge rounded-pill card-header-bg text-success border border-3 border-success me-2">SPONSORED</span>
            <i class="bi bi-calendar-event"></i> {{rfq?.submitQuotationLastDate | date:'d/M/y'}}
          </div>
          <div class="col-2 text-end"><i class="bi bi-people-fill"></i> {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
        </div>
      </div>
    </a>
    <div  *ngIf="!rfq?.url" class="card-header card-header-bg bg-dark">
      <div class="row c3 fw-bold" *ngIf="!rfq?.sponsored">
        <div class="col-5 text-start">
          <i class="bi bi-geo-alt-fill geo-color"></i>
          {{(rfq?.city)?rfq?.city:rfq?.country}}
          <!-- ,{{rfq?.country}} -->
        </div>
        <div class="col-4 text-start"><i class="bi bi-calendar-event"></i> {{rfq?.submitQuotationLastDate | date:'d/M/y'}}
        </div>
        <div class="col-3 text-end"><i class="bi bi-people-fill"></i> {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
      </div>
      <div class="row c3 fw-bold g-0" *ngIf="rfq?.sponsored">
        <div class="col-3 text-start">
          <i class="bi bi-geo-alt-fill geo-color"></i>
          {{(rfq?.city)?rfq?.city:rfq?.country}}
          <!-- ,{{rfq?.country}} -->
        </div>
        <div class="col-7 text-center">
          <span class="badge rounded-pill card-header-bg text-success border border-3 border-success me-2">SPONSORED</span>
          <i class="bi bi-calendar-event"></i> {{rfq?.submitQuotationLastDate | date:'d/M/y'}}
        </div>
        <div class="col-2 text-end"><i class="bi bi-people-fill"></i> {{ (rfq?.totalVendorParticipation) ? rfq?.totalVendorParticipation : '0'  }}</div>
      </div>
    </div>
    <div class="card-body">

      <div class="row text-start fw-bold g-0">
        <div class="col-6 pb-2">
          <a  *ngIf="rfq?.url" href="{{rfq?.url}}">
            <h6 class="mb-2 fw-bold" *ngIf="(rfq?.isAlreadyParticipating)">{{ rfq?.procurementName }}</h6>
            <strong  class="mb-2">RFQ # {{this.formatDisplayId(rfq?.rfqDisplayId)}}</strong> 
          </a>
          <div *ngIf="!rfq?.url">
            <h6 class="mb-2 fw-bold" *ngIf="(rfq?.isAlreadyParticipating)">{{ rfq?.procurementName }}</h6>
            <strong  class="mb-2">RFQ # {{this.formatDisplayId(rfq?.rfqDisplayId)}}</strong> 
          </div>
        </div>
        <div class="col-6 text-end">
          <a *ngIf="rfq?.url" href="{{rfq?.url}}">
            <font *ngIf="(rfq?.isAlreadyParticipating)" class="participatingdiv mx-0">{{ (rfq?.isAlreadyParticipating) ? 'PARTICIPATING' : '' }}</font>
            <div [ngSwitch]="rfq?.rfqState | uppercase">
              <!-- <span *ngSwitchCase="'UNPUBLISHED'"
                class="badge rounded-pill bg-unpublished ms-3">{{rfq?.rfqState}}</span> -->
              <!-- <span *ngSwitchCase="'PUBLISHED'"
                class="badge rounded-pill bg-published ms-3">{{rfq?.rfqState}}</span> -->
              <!-- <span *ngSwitchCase="'EXPIRED'"
                class="badge rounded-pill bg-expired ms-3">{{rfq?.rfqState}}</span> -->
            </div>
          </a>
          <div *ngIf="!rfq?.url" >
            <font *ngIf="(rfq?.isAlreadyParticipating)" class="participatingdiv mx-0">{{ (rfq?.isAlreadyParticipating) ? 'PARTICIPATING' : '' }}</font>
            <div [ngSwitch]="rfq?.rfqState | uppercase">
              <!-- <span *ngSwitchCase="'UNPUBLISHED'"
                class="badge rounded-pill bg-unpublished ms-3">{{rfq?.rfqState}}</span> -->
              <!-- <span *ngSwitchCase="'PUBLISHED'"
                class="badge rounded-pill bg-published ms-3">{{rfq?.rfqState}}</span> -->
              <!-- <span *ngSwitchCase="'EXPIRED'"
                class="badge rounded-pill bg-expired ms-3">{{rfq?.rfqState}}</span> -->
            </div>
          </div>
        </div>
        <div class="col-12 pb-2">
          <!-- Category : {{rfq?.rfqVendorCategory.length==0?'-':rfq?.rfqVendorCategory.join(", ")}} -->
          <a *ngIf="rfq?.url"  href="{{rfq?.url}}">
            Category : {{rfq?.rfqVendorCategory}}
          </a>
          <font *ngIf="!rfq?.url" >
            Category : {{rfq?.rfqVendorCategory}}
          </font>
        </div>

      </div>
      <div class="row g-0 text-start">
        <!-- <div class="col-12">
          <p class="card-text">{{divDetailspec(rfq?.description)}}</p>
        </div> -->
        <div class="col-lg-9 col-md-9 col-sm-12 col-12">
          <a *ngIf="rfq?.url" href="{{rfq?.url}}">
            <ul class="mb-0">
              <ng-container *ngFor="let items of rfq?.rfqItem ; let i = index">
                <li *ngIf="i < config.maxLimitItemShow">
                  {{(items.brand)?items.brand+' : ':''}} 
                  {{(items.description)? divDetailspecMobile(items.description):''}}
                </li>
              </ng-container>
              <div *ngIf="rfq?.haveMoreRfqItem">...</div>
            </ul>
          </a>
          <ul *ngIf="!rfq?.url" class="mb-0">
            <ng-container *ngFor="let items of rfq?.rfqItem ; let i = index">
              <li *ngIf="i < config.maxLimitItemShow">
                {{(items.brand)?items.brand+' : ':''}} 
                {{(items.description)? divDetailspecMobile(items.description):''}}
              </li>
            </ng-container>
            <div *ngIf="rfq?.haveMoreRfqItem">...</div>
          </ul>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-12 mt-0 text-lg-end text-md-end text-center">

          <div class="row justify-content-center" [ngSwitch]="rfq?.rfqState | uppercase">

            <div class="mb-1 col-auto">
            <span *ngSwitchCase="'UNPUBLISHED'"
              class="badge rounded-pill bg-unpublished">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'PUBLISHED'"
              class="badge rounded-pill bg-published">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'EXPIRED'"
              class="badge rounded-pill bg-expired">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'VOID'"
              class="badge rounded-pill bg-void">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>
            <span *ngSwitchCase="'HALT'"
              class="badge rounded-pill bg-void text-uppercase">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{'text.halt' | translate}}</span>
            <span *ngSwitchCase="'CLOSED'"
              class="badge rounded-pill bg-void">{{rfqType=='procurement'?'RFQ':'QUOTE'}} {{rfq?.rfqState}}</span>   
            </div>


            <div class="mb-1 col-auto" *ngIf="rfq?.ongoingApprovalStatus">
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate3" *ngIf="rfq?.approvalStatus==2">ON GOING APPROVAL REQUESTED</span>
            </div>
            <div class="mb-1 col-auto" *ngIf="!rfq?.ongoingApprovalStatus">
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate1" *ngIf="rfq?.approvalStatus==4">APPROVED</span>
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate2" *ngIf="rfq?.approvalStatus==1">OPEN</span>
              <span class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgstate3" *ngIf="rfq?.approvalStatus==2">APPROVAL REQUESTED</span>
            </div>

            <strong *ngIf="rfq?.haveActivePO" class="mb-1 col-auto">
              <img src="/assets/images/icons/ic_outline-request-quote.png" class="outlineicon pb-2"> 
              <font *ngIf="!rfq?.isPoSent">Proforma PO Ready</font>
              <font *ngIf="rfq?.isPoSent">PO sent</font>
            </strong>

            <strong *ngIf="rfq?.poId" class="mb-1 col-auto">
              <img src="/assets/images/icons/ic_outline-request-quote.png" class="outlineicon pb-2"> Proforma PO Ready
            </strong>

                          
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const QuerycompanyStaffs = gql`
query companyStaffs($first: Int, $after: String){
  companyStaffs(input: {first: $first, after: $after}){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              role
              roleId
              isActive
              userData{
                  name
                  email
                  secondaryEmail
              }
          }
          isStaffAlreadyVerified
      }
  }
}
`;

const getDetailStaff = gql`
query companyStaff($targetStaffId:ID!){
  companyStaff(targetStaffId: $targetStaffId){
      id
      role
      roleId
      isActive
      userData{
          name
          email
          secondaryEmail
      }
  }
}
`;

const PostDeleteUser = gql`
mutation deleteCompanyStaffs($targetStaffsId: [ID]!){
  deleteCompanyStaffs(input: {
      targetStaffsId: $targetStaffsId
  })
}
`;

const ChangePasswordUser = gql`
mutation changePassword($oldPassword:String! ,$newPassword:String! ){
  changePassword(input: {
      oldPassword: $oldPassword
      newPassword: $newPassword
  }) 
}
`;

const getRegisteredCompany = gql`
query {
  viewUserRegisteredCompanyTable {
      staffId
      companyName
      staffRole
      roleId
      isActive
  }
}
`;

const leavecompany = gql`
mutation userLeaveCompany($targetStaffsId:[ID]!){
  userLeaveCompany(input: {
      targetStaffsId: $targetStaffsId
  }) 
}
`;

const PostInsertUserGql = gql`
mutation insertCompanyStaff($roleMembershipId:Int!,$name:String!,$email:EmailAddress!){
  insertCompanyStaff(input: {
      companyStaffInsertInputData: {
          roleMembershipId: $roleMembershipId
          userData: {
              name: $name
              email: $email
          }
      }
  }){
      id
      role
      isActive
      userData{
          name
          email
          secondaryEmail
      }
  }
}
`;

const PostUpdateUser = gql`
mutation updateCompanyStaffs($companyStaffsUpdateInputData: [CompanyStaffUpdateInputData!]!){
  updateCompanyStaffs(input: {
      companyStaffsUpdateInputData: $companyStaffsUpdateInputData

      
  }){
      id
      role
      isActive
      userData{
          name
          email
          secondaryEmail
      }
  }
}
`;


const postResendEmailGql = gql`
mutation resendVerificationEmail($email:String!,$companyStaffId:ID!){
  resendVerificationEmail(input: {
      email: $email
      companyStaffId: $companyStaffId
  })
}
`;

@Injectable({ providedIn: 'root' })

export class StaffService{

    stafflistObs = new Subject();
    staffObs = new Subject();
    staffDeleteObs = new Subject();
    staffUpdateObs = new Subject();
    updatePasswordObs = new Subject();
    staffRegisteredCompanyObs = new Subject();
    staffLeaveCompanyObs = new Subject();
    resendEmailObs = new Subject();
    insertUserObs = new Subject();
    

    constructor(private apollo: Apollo) {}

    staffList(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: QuerycompanyStaffs,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.stafflistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.stafflistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getstaffList(first:any, after:any) {
        this.stafflistObs = new Subject();
        this.staffList(first, after);
        return this.stafflistObs.asObservable();
    }

    staffDetail(StaffId:any) {
      this.apollo
      .mutate({
        mutation: getDetailStaff,
        variables: {targetStaffId: StaffId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.staffObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.staffObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getstaffDetail(StaffId:any) {
      this.staffObs = new Subject();
      this.staffDetail(StaffId);
      return this.staffObs.asObservable();
  }


  deleteStaff(StaffId:any) {
      this.apollo
      .mutate({
        mutation: PostDeleteUser,
        variables: {targetStaffsId: StaffId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.staffDeleteObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.staffDeleteObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getdeleteStaff(StaffId:any) {
      this.staffDeleteObs = new Subject();
      this.deleteStaff(StaffId);
      return this.staffDeleteObs.asObservable();
  }


  updatePassword(oldPassword:any, newPassword:any) {
    this.apollo
    .mutate({
      mutation: ChangePasswordUser,
      variables: {oldPassword: oldPassword, newPassword: newPassword},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.updatePasswordObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.updatePasswordObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getupdatePassword(oldPassword:any, newPassword:any) {
    this.updatePasswordObs = new Subject();
    this.updatePassword(oldPassword, newPassword);
    return this.updatePasswordObs.asObservable();
  }


  registedCompany() {
    this.apollo
    .mutate({
      mutation: getRegisteredCompany,
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.staffRegisteredCompanyObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.staffRegisteredCompanyObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getregistedCompany() {
    this.staffRegisteredCompanyObs = new Subject();
    this.registedCompany();
    return this.staffRegisteredCompanyObs.asObservable();
  }

  staffLeaveCompany(StaffId:any) {
      this.apollo
      .mutate({
        mutation: leavecompany,
        variables: {targetStaffsId: [StaffId]},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.staffLeaveCompanyObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.staffLeaveCompanyObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  poststaffLeaveCompany(StaffId:any) {
      this.staffLeaveCompanyObs = new Subject();
      this.staffLeaveCompany(StaffId);
      return this.staffLeaveCompanyObs.asObservable();
  }
  
  
  UpdateStaffCompany(companyStaffsUpdateInputData:any) {
      this.apollo
      .mutate({
        mutation: PostUpdateUser,
        variables: {companyStaffsUpdateInputData: companyStaffsUpdateInputData},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.staffUpdateObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.staffUpdateObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  postUpdateStaffCompany(companyStaffsUpdateInputData:any) {
      this.staffUpdateObs = new Subject();
      this.UpdateStaffCompany(companyStaffsUpdateInputData);
      return this.staffUpdateObs.asObservable();
  }

  ResendEmail(email:any,companyStaffId:any) {
      this.apollo
      .mutate({
        mutation: postResendEmailGql,
        variables: {email: email, companyStaffId: companyStaffId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.resendEmailObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.resendEmailObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  postResendEmail(email:any,companyStaffId:any) {
      this.resendEmailObs = new Subject();
      this.ResendEmail(email,companyStaffId);
      return this.resendEmailObs.asObservable();
  }
   

  InsertUser(roleMembershipId:any,name:any,email:any) {
      this.apollo
      .mutate({
        mutation: PostInsertUserGql,
        variables: {roleMembershipId:roleMembershipId,name:name,email:email},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.insertUserObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.insertUserObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  PostInsertUser(roleMembershipId:any,name:any,email:any) {
      this.insertUserObs = new Subject();
      this.InsertUser(roleMembershipId,name,email);
      return this.insertUserObs.asObservable();
  }
  

  
}
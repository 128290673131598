import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustDifferent } from 'src/app/_helpers/must-different.validator';
import {gql, Apollo} from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { config } from './../../../config';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OthersService } from 'src/app/_services/others.service';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';



@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html', 
  styleUrls: ['./contact-us.component.scss']
})
export class ContactusComponent implements OnInit {
    submitted = false;
    pageloading:boolean=false

    //model
    ValName:string=''
    ValEmail:string=''
    ValSubject:string=''
    ValMessage:string=''
    
    contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
      message: ['', Validators.required],
    }
  );

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private othersService: OthersService,
    private popupmodal : Popupmodal,
  ) { }

  ngOnInit(): void {

  }

  get f() { return this.contactForm?.controls; }

  onSubmit(){

    this.submitted = true;
    console.log(this.contactForm?.value);
    console.log(this.contactForm);

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      console.log('contactForm invalid');
        return;
    }


    this.pageloading=true
    this.othersService.postcontactUs(this.ValName,this.ValEmail,this.ValSubject,this.ValMessage).subscribe((res:any) => {
      console.log(res)
      this.pageloading=false
      if(res.data?.contactUs){
        this.popupmodal.showSuccess('Pesan terkirim. Terima kasih telah menghubungi kami.','window.location.href="/"').subscribe((res:any) => {})
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }
}

<div ngbAutofocus>

    <div class="modal-dialog modal-lg modal-dialog-centered m-0">
        <div class="modal-content  rounded-0 border-0">
            <div class="modal-header bg1 rounded-0  text-center">
                <h4 class="text-white f1 mb-0"><strong>{{title}}</strong></h4>
                <button type="button" class="btn text-white ps-0 pe-0" data-bs-dismiss="modal" (click)="activeModal.close();"><i class="bi bi-x-lg"></i></button>
            </div>
            <div class="modal-body filterdiv" *ngIf="!getResponse">

                <p>Frequently Asked Questions</p>
                <p *ngFor="let question of sampleQuestion; let i = index" (click)="selectData(question.id, question.text)" class="pointer {{selectedIdQuestion==question.id?'selected':'unselected'}} mt-2 mb-2">{{question.text}}</p>
                <p class="mt-4 mb-1">Ask something else about the product here</p>
                <textarea class="form-control" row="3" maxlength="50" placeholder="Ask someting to {{sendTo}}"  [(ngModel)]="valMessage" [ngModelOptions]="{standalone: true}" (ngModelChange)="messageChange()" [disabled]="processMsg"></textarea>
                <div class="text-center mt-3 mb-2">
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="submitMessage()" [disabled]="isEmptyChat">{{processMsg?'Loading...':'Send'}}</button>
                </div>

            </div>
            <div  *ngIf="getResponse">
                <div class="text-center mt-3 mb-4">
                    <div class="m-3">
                        <p><font [innerHtml]="ResponseText"></font></p>
                    </div>
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="close()">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from 'src/app/_services/messaging.services';

@Component({
  selector: 'app-popupfilter',
  templateUrl: './popupfilter.component.html',
  styleUrls: ['./popupfilter.component.scss']
})
export class PopupfilterComponent implements OnInit {

  @Input() title?: string;
  @Input() sendMessageAs?: string;
  @Input() rfqid?: string;
  @Input() quotationId?: string;
  
  @Output() confirmedClick = new EventEmitter();
  
  displayFilter:string='none'

  //Valinmodulesearch:string=''
  // ValetaRequestedStart:any
  // ValetaRequestedTo:any
  Vallocation:any
  minDateStart: any
  minDateTo: any
  //Valstatus: string=''

  modalCategories: boolean=false
  ValrfqVendorCategory:string=''
  dataCategoryList:any = null
  AlldataCategoryList:any =[]
  categoryfirst : number = 24
  categoryendCursor: any=''
  categoryhasNextPage: any = true
  categoryhasPrevPage:any = false
  allCategoryLoaded:boolean=false
  CategoryPage:number = 0
  LastCategoryPage:number = 0
  CategoryShown:any=[]
  selectedCat:any=[]
  
  onSearch:boolean=false

  valSearch:string=''
  Valstatus:string=''
  ValLocation:any=[]
  ValCategory:any=[]
  ValminDateStart: any = null
  ValminDateTo: any = null

  ValminDateStartPrev:any
  ValminDateToPrev:any

  PrevvalSearch:string=''
  PrevvalStatus:string=''
  PrevvalLocation:any=[]
  PrevvalLocationText:string=''
  PrevvalLocationTextDisplay:string=''

  PrevvalCategory:any=[]
  PrevvalCategoryText:string=''
  PrevvalCategoryTextDisplay:string=''
  PrevvalminDateStart: any = null
  PrevvalminDateTo: any = null

  getCityList:any=[]
  dropdownListTag: any;
  selectedItemsTag: any = [];
  dropdownSettingsTag: any;

  errorDateRange:boolean=false

  rfqFilterForm = this.formBuilder.group({
    inmodulesearch: [''],
    etaRequestedStart: [''],
    etaRequestedTo: [''],
    location: [''],
    status: [''],
  })

  constructor(
    public activeModal: NgbActiveModal,
    private messagingService: MessagingService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  filterRFQ(){
    alert('filter submit')
  }

  clearDate(val:any){
    this.errorDateRange =false
    if(val=='start'){
      this.ValminDateStart=null
    }
    if(val=='end'){
      this.ValminDateTo=null
    }
  }

  
  onItemSelect(item: any) {
    console.log('onItemSelect : ',item);

    // this.selectedItemsTag.push(item)

    var exist = false
    this.selectedItemsTag.forEach((obj: any, key: any) => {
      if (obj.item_id == item.item_id) {
        exist=true
      }
    });

    if(!exist){
      this.selectedItemsTag.push(item)
    } 


  }

  onItemDeSelect(item: any) {
    console.log('onItemDeSelect : ',item);

    this.selectedItemsTag.forEach((obj: any, key: any) => {
      if (obj.id == item.item_id) {
        this.selectedItemsTag.splice(key, 1);
      }
    });
    console.log(this.selectedItemsTag);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll : ',items);

    this.selectedItemsTag = items
    console.log(this.selectedItemsTag);
  }

  onDeselectAll(items: any) {
    console.log('onDeselectAll : ',items);

    this.selectedItemsTag = []
    console.log(this.selectedItemsTag);
  }

  removeCat(id:any){
    this.selectedCat.forEach((v: any, k: any) => {
      if(v==id){
        this.selectedCat.splice(k, 1);
      }
    });
    this.ValCategory = this.selectedCat

    if(this.selectedCat.length>0){
      this.ValrfqVendorCategory =  JSON.stringify(this.selectedCat)
    } else {
      this.ValrfqVendorCategory = ''
    }

    console.log('this.selectedCat',this.selectedCat);
  }

  getCategoryName(id:any){
    var name =''
    this.AlldataCategoryList.forEach((v: any, k: any) => {
      if(id==v.node.industry){
        name = v.node.industry
      }
    });

    // //if from edit data
    // if(this.EditRfqId){
    //   this.arrCategoryfromEdit.forEach((v: any, k: any) => {
    //     if(id==v.category){
    //       name = v.category
    //     }
    //   });
    // }

    return name
  }

  modalCategory(){
    
  }
  onSubmit(){

  }
}

<!-- <p>dashboard works!</p>
<button class="btn btn-primary" (click)="logout();">Logout</button> -->

<!-- <app-memberheader></app-memberheader> -->


<div *ngIf="!PageLoading">
      
    <!-- Sidebar -->
          <!-- Sidebar -->
    <!-- <div id="sidebar-wrapper">
    <ul id="sidebar_menu" class="sidebar-nav">
         <li class="sidebar-brand"><a id="menu-toggle" (click)='hideshowmenu()'>Menu
            <i id="main_icon" class="bi bi-list"></i>
        </a></li>
    </ul>
      <ul class="sidebar-nav" id="sidebar">     
        <li><a>Dashboard<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
        <li><a>Request<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
        <li><a>Message<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
        <li><a>Notification<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
      </ul>
    </div> -->
        
    <!-- Page content -->
    <div id="page-content-wrapper">
      <!-- Keep all page content within the page-content inset div! -->
      <div class="page-content inset container">
        <div class="row">
            <div class="col-md-12 mt-4 pt-3">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-sm-9">
                      <h4 class="pt-1 mb-1">{{ ValCompanyName }}</h4>
                    </div>
                    <div class="col-sm-3 d-none" *ngIf="!singleCompany">
                      <button class="btn btn-secondary w-100"   (click)="ChangeCompany()">Change</button>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>


            <div class="col-md-12">  
            <!-- <button class="btn btn-primary" (click)="logout();">Logout</button> -->
          </div>
        </div>



        <div class="row mb-5">
          <div class="col-xl-4 col-md-4  mt-4">
              <div class="card bg-pattern">
                  <div class="card-body">
                      <div class="float-right">
                          <i class="fa fa-archive text-primary h4 ml-3"></i>
                      </div>
                      <h5 class="font-size-20 mt-0 pt-1">24</h5>
                      <p class="text-muted mb-0">Request</p>
                  </div>
              </div>
          </div>
          <div class="col-xl-4 col-md-4  mt-4">
              <div class="card bg-pattern">
                  <div class="card-body">
                      <div class="float-right">
                          <i class="fa fa-th text-primary h4 ml-3"></i>
                      </div>
                      <h5 class="font-size-20 mt-0 pt-1">6</h5>
                      <p class="text-muted mb-0">On Progress</p>
                  </div>
              </div>
          </div>
          <div class="col-xl-4 col-md-4  mt-4">
              <div class="card bg-pattern">
                  <div class="card-body">
                      <div class="float-right">
                          <i class="fa fa-file text-primary h4 ml-3"></i>
                      </div>
                      <h5 class="font-size-20 mt-0 pt-1">11</h5>
                      <p class="text-muted mb-0">Completed</p>
                  </div>
              </div>
          </div>

      </div>
      <!-- end row -->




      </div>
    </div>
    
    



  </div>



    

        <!-- Modal -->
        <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
          <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <!-- <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Select Company</h5>
                </div> -->
                <div class="modal-body">
  
                  <div class="pb-4 pt-4 ps-3 pe-3 row">
                    <div class="col-sm-12">
                      <div>
                        <div class="row">
                          <div class="col-6">
                            <div class="text-center">
                              <img src="/assets/images/logofh-cepetdapet.png" class="img-fluid">
                            </div>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="text-center  mb-4">
                          <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M52.0833 109.375H72.9167M98.9583 109.375V26.0417C98.9583 23.279 97.8609 20.6295 95.9074 18.676C93.9539 16.7225 91.3043 15.625 88.5417 15.625H36.4583C33.6957 15.625 31.0461 16.7225 29.0926 18.676C27.1391 20.6295 26.0417 23.279 26.0417 26.0417V109.375H98.9583ZM98.9583 109.375H109.375H98.9583ZM98.9583 109.375H72.9167H98.9583ZM26.0417 109.375H15.625H26.0417ZM26.0417 109.375H52.0833H26.0417ZM46.875 36.4583H52.0833H46.875ZM46.875 57.2917H52.0833H46.875ZM72.9167 36.4583H78.125H72.9167ZM72.9167 57.2917H78.125H72.9167ZM52.0833 109.375V83.3333C52.0833 81.952 52.6321 80.6272 53.6088 79.6505C54.5856 78.6737 55.9103 78.125 57.2917 78.125H67.7083C69.0897 78.125 70.4144 78.6737 71.3912 79.6505C72.3679 80.6272 72.9167 81.952 72.9167 83.3333V109.375H52.0833Z" stroke="#174559" stroke-opacity="0.5" stroke-width="11" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        
                      </div>
                    </div>
                    <div class="col-sm-12 mb-4">
                      <select [(ngModel)]="ValCompany" class="form-select form-control" id="selectCompany" name="selectCompany">
                        <option value=''> - select company - </option>
                        <option *ngFor="let company of ArrCompany" [ngValue]="company['companyId']">{{company['companyName']}}</option>
                      </select>
                    </div>
                    <div class="col-sm-12">
                      <button  *ngIf="!confirmLoading" type="button" class="btn btn-secondary w-100 bg1 pt-2 pb-2 fw-bold" data-bs-dismiss="modal" (click)="ConfirmCompany()">OK</button>
                      <button *ngIf="confirmLoading" class="btn btn-secondary btn-block w-100 bg1 pt-2 pb-2 fw-bold" disabled>
                        Loading..
                        </button>
                      
                    </div>
  
                    <div class="col-md-12 mt-4" *ngIf="emptyCompany">
                      <div class="alert alert-warning" role="alert">
                        <i class="bi bi-exclamation-circle"></i> Please select company.
                      </div>
                    </div>
  
                    <div class="col-md-12 mt-4" *ngIf="errChooseCompany!==''">
                      <div class="alert alert-danger" role="alert">
                        <i class="bi bi-dash-circle"></i> {{errChooseCompany}}
                      </div>
                    </div>
  
                  </div>
  
                </div>
                <!-- <div class="modal-footer">
                  
                </div> -->
              </div>
            </div>
          </div>

<div *ngIf="PageLoading && !subscription">



  <div class="text-center pt-5 pb-5">
    <div class="loader loader--style8" title="7">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
        <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
        </rect>
        <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
        </rect>
        <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
        </rect>
      </svg>
    </div>
  </div>  

</div>          


<div *ngIf="subscription">
        <div class="album py-5">
          <div class="container">
            
            <h2 class="pb-3 text-center">Subcribtion Info</h2>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription A Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=1">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription B Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=2">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription C Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=3">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription D Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=4">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>







            </div>
          </div>
        </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';


const mutationunblockUser = gql`
mutation unblockUser($unblockToken:String!){
  unblockUser(input: {
      unblockToken:$unblockToken
  })
}
`;


@Component({
  selector: 'app-unblockemail',
  templateUrl: './unblockemail.component.html',
  styleUrls: ['./unblockemail.component.scss']
})
export class UnblockemailComponent implements OnInit {
  verificationToken:any;
  btnloading:boolean=false;
  submitted = false;
  regID:string=''
  state:string='loading' // loading, expired, submitdata, success


  errortext:string=''

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.forceLogout();
    this.verificationToken = this.route.snapshot.paramMap.get('token');

    //this.pageloading=true;
    this.state = 'loading';

    if(this.verificationToken.length>32){
      this.state = 'submitdata'
      this.onSubmit()
    } else {
      this.router.navigate(['']);
    }
  }


  onSubmit() {
    this.submitted = true;


    this.btnloading=true;

    let ArrData:any
    ArrData ={
      unblockToken: this.verificationToken,
    }

    //this.state ='expired'
    //this.state ='success'
    //return

    this.apollo
    .mutate({
      mutation: mutationunblockUser,
      variables: ArrData
    })
    .subscribe(
      (res:any) => {
        console.log("succes, get data:", res);
        this.state ='success'
      },
      error => {
        console.log('error',error);
        var errMsg = error + '';
        var res = errMsg.split(":");
        var resMsg = res[res.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        this.state ='expired'
        this.errortext = msg
      }
    );



  }
}

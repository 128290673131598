import { Injectable } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { ModalComponent } from "../components/modal/modal.component"
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NavigationService } from '../_services/navigation';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })

export class Popupmodal{
    popupObs = new Subject();

    defaultdata={
        class:{
            modalDialogClass:'modal-dialog-centered',
            centered: true,
            backdrop : 'static',
            keyboard : false,
            size: 'sm' 
        },
        modalinfo:'info',
        infoTitle:'', 
        infoSuccess:null,
        infoFailed:null,
        closeTxt:'',
        redirect:null,
        IdBtnClose:''
    }

    defaultSuccess={
        infoTitle:'Success!',
        infoSuccess:'',
        closeTxt:'Ok',
    }
    defaultError={
        infoTitle:'Opps Something Wrong!',
        infoFailed:'Please try again later',
        closeTxt:'Close',
    }

    constructor(
        public modalService: NgbModal,
        private navigation: NavigationService,
        private translate: TranslateService,
        public router: Router
        ) { 

            // var getLang = localStorage.getItem('language') 
            // if(getLang!=='en'){

            //     this.defaultSuccess.infoTitle="Sukses!"
            //     this.defaultSuccess.infoSuccess=""
            //     this.defaultSuccess.closeTxt="Ok"
                
            //     this.defaultError.infoTitle="Terjadi kesalahan!"
            //     this.defaultError.infoFailed="Silahkan coba kembali"
            //     this.defaultError.closeTxt="Tutup"
            // }        

            this.translate.get('success.title').subscribe((data:any)=> {
                this.defaultSuccess.infoTitle = data
            });
            this.translate.get('error.title').subscribe((data:any)=> {
                this.defaultError.infoTitle = data
            });
            this.translate.get('error.text').subscribe((data:any)=> {
                this.defaultError.infoFailed = data
            });
            this.translate.get('error.button').subscribe((data:any)=> {
                this.defaultError.closeTxt = data
            });

        }

    show(data:any){
        var modalClass = (data?.class) ? data?.class: this.defaultdata.class
        var modalinfo = (data?.modalinfo) ? data?.modalinfo: this.defaultdata.modalinfo
        var infoTitle = (data?.infoTitle) ? data?.infoTitle: this.defaultdata.infoTitle
        var infoSuccess = (data?.infoSuccess) ? data?.infoSuccess: this.defaultdata.infoSuccess
        var infoFailed = (data?.infoFailed) ? data?.infoFailed: this.defaultdata.infoFailed
        var closeTxt = (data?.closeTxt) ? data?.closeTxt: this.defaultdata.closeTxt
        var redirect = (data?.redirect) ? data?.redirect: this.defaultdata.redirect
        var IdBtnClose = (data?.IdBtnClose) ? data?.IdBtnClose: this.defaultdata.IdBtnClose

        if(data?.infoSuccess){
            infoTitle = (data?.infoTitle) ? data?.infoTitle : this.defaultSuccess.infoTitle
            infoSuccess = (data?.infoSuccess) ? data?.infoSuccess : this.defaultSuccess.infoSuccess
            infoFailed = null
            closeTxt = (data?.closeTxt) ? data?.closeTxt : this.defaultSuccess.closeTxt
        } else {
            infoTitle = (data?.infoTitle) ? data?.infoTitle : this.defaultError.infoTitle
            infoSuccess = null
            infoFailed = (data?.infoFailed) ? data?.infoFailed : this.defaultError.infoFailed
            closeTxt = (data?.closeTxt) ? data?.closeTxt : this.defaultError.closeTxt
        }

        const modalRef = this.modalService.open(ModalComponent, modalClass)
          modalRef.componentInstance.modalinfo = modalinfo
          modalRef.componentInstance.infoTitle = infoTitle
          modalRef.componentInstance.infoSuccess = infoSuccess
          modalRef.componentInstance.infoFailed = infoFailed
          modalRef.componentInstance.closeTxt = closeTxt
          modalRef.componentInstance.IdBtnClose = IdBtnClose
          
        
          if(redirect){
            if(redirect=='location.reload()'){ 
                modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                    if (res){
                        location.reload()
                    } 
                  })
            } 
            else if(redirect=='window.location.href="/"'){ 
                modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                    if (res){
                        window.location.href='/'
                    } 
                  })
            } 
            else if(redirect=='location.back()'){ 
                modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                    if (res){
                        this.navigation.back()
                    } 
                  })
            } 
            else {
                modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                    if (res){
                      if(redirect){
                        window.location.href=redirect
                      } else {
                        location.reload()
                      }
                    } 
                  })
            }
          }

    }
    //call to custumize all input, pattern like this.defaultdata
    showModal(data:any){
        this.popupObs = new Subject();
        this.show(data);
        return this.popupObs.asObservable();
    }
    //call success default popup
    showSuccess(message:string,redirect:any){
        this.popupObs = new Subject();
        var modalData={
            infoSuccess:message,
            redirect:redirect
          }
        this.show(modalData);
        return this.popupObs.asObservable();
    }
    //call error default popup
    showError(message:string,redirect:any){
        this.popupObs = new Subject();
        var modalData={
            infoFailed:message,
            redirect:redirect
          }
        this.show(modalData);
        return this.popupObs.asObservable();
    }
    //call error default popup
    showFail(){
        this.popupObs = new Subject();
        var modalData={
            infoFailed:this.defaultError.infoFailed,
            redirect:'/'
          }
        this.show(modalData);
        return this.popupObs.asObservable();
    }


    // infoSuccess , infoFailed , redirect
    // var modalData={
    //   infoSuccess:'mie sukses',
    //   //infoFailed:'Please try again later 123',
    //   //redirect:'/'
    // }
    // this.popupmodal.showModal(modalData).subscribe((res:any) => {
    // })
    //this.popupmodal.showSuccess('mie sukses',null).subscribe((res:any) => {})
    //this.popupmodal.showError('mie gagal','/').subscribe((res:any) => {})


    
    notification(subject:string,content:string,time:string){
        const modalRef = this.modalService.open(ModalComponent,{
            modalDialogClass:'modal-dialog-centered',
            centered: true,
            backdrop : 'static',
            keyboard : false,
            size: 'lg' 
        })
          modalRef.componentInstance.modalnotification = 'modalnotification'
          modalRef.componentInstance.notificationSubject = subject
          modalRef.componentInstance.notificationContent = content
          modalRef.componentInstance.notificationTime = time
    }


    showNotification(subject:string,content:string,time:string){
        this.popupObs = new Subject();
        this.notification(subject,content,time);
        return this.popupObs.asObservable();
    }
}
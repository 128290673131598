<header class="fhgrad fixed-top d-none"  *ngIf="!isHomePage">
  <ng-container *ngIf="!isMobile">
  <div class="container">
    <div class="row g-0 align-self-center divmenufh m-0">
      <div class="col-sm-4 col-md-4 col-lg-3">
        <a href="/"><img class="img-fluid fh-logo pt-0 pb-0 pe-2" src="/assets/images/logofh-cepetdapet.png"></a>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-6 text-center align-self-center">
        <div class="btn-group w-100 {{invalidSearch?'mt-4':''}}" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <!-- <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle fhbtn2 f1" data-bs-toggle="dropdown" aria-expanded="false">
              {{'menu.select_module' | translate}}
            </button> -->
            <!-- <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
            </ul> -->
          </div>        
          <input type="text" class="form-control searchinput f1" placeholder="{{'menu.search' | translate}}" aria-label="Search" aria-describedby="btnGroupAddon" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}">
          <button type="button" class="btn btn-primary  align-self-center fhsearchiconbtn" (click)="globalSearch()"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>

        </div>
        <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-3 text-center align-self-center d-md-flex d-sm-flex justify-content-md-center justify-content-sm-center">



        <div class="row w-100 g-0">
          <div class="col-sm-1 col-md-1 col-lg-3 pe-0 ps-0">
            <!-- <a href="" class="btn fhc2 f1  pe-0 ps-0"><strong>{{'menu.discover_more' | translate}}</strong></a> -->
          </div>
          <div class="col-sm-5 col-md-5 col-lg-4">
            <!-- <a href="" class="btn fhc2 f1"><strong>{{'menu.support' | translate}}</strong></a> -->
            <a href="/login" class="btn fhbtn1t text-white f1 ps-2 pe-2 pt-2 pb-2 w-100"><strong>{{'menu.login' | translate}}</strong></a>
          </div>
          <div class="col-1"></div>
          <div class="col-sm-5 col-md-5 col-lg-4">
            <a href="/regis" class="btn fhbtn1 text-white f1 ps-2 pe-2 pt-2 pb-2 w-100"><strong>{{'menu.regis' | translate}}</strong></a>
          </div>
        </div>
        
        
        

      </div>
    </div>

  </div>

  <nav class=" fhbgmenu">
    <div class="container">

      <div class="row g-0">
        <div class="col-3 ">
          <a class="nav-link active fw-bold pointer ps-0" (click)="hideshowmenu()"><img class="fhdashicon"  src="/assets/images/icons/dashicons_menu@3x.png"></a>
        </div>
        <div class="col-9 text-end py-2">
          <div class="">


            
            <!-- <div ngbDropdown class="d-inline-block">
              <button class="btn dropdown-toggle fhc1 ps-0 pe-0 flagbtn" id="dropdownBasic1" ngbDropdownToggle>
                <img class="fhusericon" src="/assets/images/country/{{currentlang}}.png">
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right">
                <div><p class="mb-0 ps-2 pe-2 selectlang">{{'menu.select_language' | translate}} :</p></div>
                <button ngbDropdownItem (click)="switchLang('en')" class="dropdownbg selectlang">English</button>
                <button ngbDropdownItem (click)="switchLang('id')" class="dropdownbg selectlang">Indonesia</button>
              </div>
            </div> -->
            

            <!-- <a href="/procurement/cart" class="text-white fw-bold pointer ps-4"><img class="" src="/assets/images/icons/ic_baseline-notifications-active.png">
              <span class="badge bg-danger fhnotif">7</span>
            </a>
            <a href="/procurement/cart" class="text-white fw-bold pointer ps-4"><img class="" src="/assets/images/icons/fluent_mail-16-filled.png">
              <span class="badge bg-danger fhnotif">7</span>
            </a> -->

            <a href="/cart" class="text-white fw-bold pointer ps-4">
              <img class="fhrighticon" src="/assets/images/icons/clarity_shopping-cart-solid@3x.png">
                <!-- <span class="badge bg-danger fhnotif">7</span> -->
            </a>
            <a href="/messaging" class="text-white fw-bold pointer ps-4">
              <img class="fhrighticon" src="/assets/images/icons/fluent_mail-16-filled@3x.png">
              <!-- <span class="badge bg-danger fhnotif">7</span> -->
            </a>
            <a href="/procurement/cart" class="text-white fw-bold pointer ps-4 d-none">
              <img class="fhrighticon" src="/assets/images/icons/clarity_shopping-cart-solid@3x.png">
              <!-- <span class="badge bg-danger fhnotif">7</span> -->
            </a>

          </div>

        </div>
      </div>

      <!-- <a class="navbar-brand dashicons_menu"><img class="fhusericon" src="/assets/images/icons/dashicons_menu.png"></a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse container ps-0 pe-0" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold " href="/">Home</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold " href="/vendor/products">Product</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">RFQ</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">Supliers</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">Inbox <span class="badge bg-danger fhnotif">18</span></a>
          </li>
       </ul>
      </div> -->

    </div>
  </nav>
  </ng-container>

  <ng-container *ngIf="isMobile">

    <div class="row g-0">
      <div class="col-1">
        <a class="nav-link active fw-bold pointer ps-0 pt-3 pe-2" (click)="hideshowmenu()"><img class="fhdashicon"
          src="/assets/images/icons/dashicons_menu@3x.png"></a>
      </div>
      <div class="col-7 ps-0 pe-0">
        <a href="/"><img class="img-fluid pt-2" style="max-width:178.4px;width:100%" src="/assets/images/logofh-cepetdapet.png"></a>
      </div>

      <div class="col-4 px-0 text-center">

        <table class="w-100">
          <tr>
            <td>
              <div class="pt-3 pe-2 text-end">
                <a href="/login">
                  <img class="rounded-circle pointer avatar" src="/assets/images/default-company.jpeg">
                </a>
              </div>
            </td>
            <td>
              <div ngbDropdown class="d-inline-block  pt-3">
                <a href="/login" class="btn dropdown-toggle fhc2 p-0">
                  <img class="fhusericon" src="/assets/images/icons/user@3x.png">
                </a>
              </div>
            </td>
          </tr>
        </table>


      </div>
      <div class="col-12 pb-2">

        <div class="btn-group w-100 {{invalidSearch?'':''}} ps-2 pe-2" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <!-- <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle fhsearchdropbtn fhbtn2"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{'menu.select_module' | translate}}
            </button> -->
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
            </ul>
          </div>
          <input type="text" class="form-control searchinput f1" placeholder="{{'menu.search' | translate}}" aria-label="Search" aria-describedby="btnGroupAddon" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}">
          <button type="button" class="btn btn-primary  align-self-center fhsearchiconbtn" (click)="globalSearch()"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>

        </div>
        <small *ngIf="invalidSearch"><font class="text-danger invSearch ps-2">Please type keyword minimum 3 character.</font></small>
      </div>

    </div>
    
  </ng-container>

</header>

<app-headernonlogin id="nonhomepage" *ngIf="!isHomePage"></app-headernonlogin>

<nav class="navbar  bg-transparent  fixed-top p-0 d-none" *ngIf="isHomePage">
  <div *ngIf="!isMobile" class="container-fluid divmenufh2 m-lg-4 m-sm-1 p-1 bg-white">

    <a href="/"><img class="img-fluid fh-logo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>

    <div class="d-flex">
      <div class="p-3 ps-1 pe-1">
        <a href="/regis" class="btn btn-default m-0 MenuBtn1">Register</a>
      </div>
      <div class="p-3 ps-1 pe-1">
        <a href="/login" class="btn btn-default m-0 MenuBtn2">Login</a>
      </div>
      
      <button class="navbar-toggler" type="button"> 
      
        <div ngbDropdown display="dynamic" placement="bottom-end">
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button"> 
            <span class="navbar-toggler-icon "><strong><h3 class="bi bi-list menuicon" style="color:#ee7824;"></h3></strong></span>  
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu menufhiconlist">
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"> <i class="pe-3 bi bi-info-circle"></i>About Us</a>
            <div class="dropdown-divider"></div>
            <ngb-accordion #acc="ngbAccordion">
              <ngb-panel>
  
                <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <i class="pe-3 bi bi-lightbulb"></i>Solution
                    </div>
                </ng-template>
  
  
                <ng-template ngbPanelContent>
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Procurement</a>
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Vendor</a>   
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-coin"></i>Subscription</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-briefcase"></i>Career</a>
  
          </div>
        </div>
  
      </button>

    </div>

  </div>
  <div *ngIf="isMobile" class="w-100 mobileNav">
    <div class="divmenufh2 container bg-white">
      <div class="row">
        <div class="col-10">
          <a href="/"><img class="img-fluid fh-logo-mobile pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>
        </div>
        <div class="col-2 text-end">
          <span>
           <h4 class="bi bi-list menuicon m-2 pointer" (click)="fullMenu()" style="color:#ee7824;"></h4>
          </span>  
        </div>
      </div>
    </div>
  </div>
</nav>


<div class="fhsidebar d-flex flex-column flex-shrink-0   {{(sidebar) ? 'fhsidebaractive' : ''}}">
  <div class="w-100">
    <div class="row g-0 slideout {{(sidebar) ? 'slidein' : ''}}">
      <div class="col-sm-12 bg-light hfsidebarcontent" style="max-width: 315px;overflow: auto;">
        <div class="d-flex flex-column flex-shrink-0" style="height: 100vh;">
          <div class="bgsidebarhead  pt-4 pb-4">
            <table class="table mt-2 mb-2 m-0">
              <tr>
                <td class="text-end"><a href="/login"><h2 class="mt-0 mb-0 pt-0 pb-0 bi bi-person-circle text-white"></h2></a></td>
                <td class="text-white">
                  <h5 class="p-0 m-0 f1"><strong><a href="/login" class="text-white ps-2">Hello, Guest </a></strong></h5>
                  <!-- <h6 class="p-0 m-0 f1l subtitle">:</h6>  -->
                </td>
              </tr>
            </table>
          </div>
          <ul class="nav flex-column mb-auto">
            <!-- <li class="nav-item menusidediv pointer" *ngFor="let menu of sidebarmenu; let i = index">
              <a *ngIf="(menu.parent)" href="{{menu.url}}" class="nav-link f1 mt-2 mb-2">
                <img class="sideicon {{ (menu.parent)? 'ps-5':'' }}" src="{{menu.icon}}"> {{menu.text}}
              </a>
            </li> -->
            <ng-container *ngFor="let menu of sidebarmenu; let i = index">
              <li class="nav-item menusidediv pointer {{ arrowmenu(menu.id,menu.parent) }}">
              <a href="{{menu.url}}" class="nav-link f1 mt-2 mb-2" *ngIf="(menu.parent); else Parent">
                <img class="sideicon ps-5" src="{{menu.icon}}"> {{menu.text}}
              </a>
              <ng-template #Parent>
                <a *ngIf="menu.url!==''" href="{{menu.url}}" class="nav-link f1 mt-2 mb-2">
                  <img class="sideicon" src="{{menu.icon}}"> {{menu.text}}
                </a>
                <a *ngIf="menu.url==''" class="nav-link f1 mt-2 mb-2" (click)="hideshowChild(menu.id)">
                  <div class="row">
                    <div class="col-10">
                      <img class="sideicon" src="{{menu.icon}}"> {{menu.text}}
                    </div>
                    <div class="col-2  text-end">
                      <b><i class="bi {{(dispaymenu[menu.id])?'bi-chevron-up':'bi-chevron-down'}}  arrowmenu"></i></b> 
                    </div>
                  </div>
                  
                </a>
              </ng-template>
              </li>
            </ng-container>

            
          </ul>
          <div class="dropdown">
            <ul class="nav flex-column mb-auto">
              <li class="nav-item menusidediv menusidefooter">
                <a href="/login" class="nav-link f1 pointer mt-2 mb-2">
                  <img class="sideicon" src="/assets/images/icons/ic_round-log-out.png"> Log In
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col pt-3 ps-3" (click)="hideshowmenu()">
        <i class="pointer bi bi-x-lg text-white {{(sidebar) ? '' : 'd-none'}}"></i>
      </div>
    </div>
  </div>
</div>



<a id="totop" class="ps-1 pe-1 membertotop bg1 {{showtotop?'show':''}}" (click)="gototop()"><h3 class="bi bi-arrow-up-short mb-0"></h3></a>



<header id="web-header">
	<div class="main-container">
		<div class="header-left">
			<a href="{{redirectDashboardUrl()}}">
				<img src="/assets/images/logofh-cepetdapet.png" srcset="/assets/images/logofh-cepetdapet.png 1x, /assets/images/logofh-cepetdapet.png 2x" sizes="(max-width: 800px) 230px" alt="Cepetdapet" loading="lazy" width="230" height="60" class="logo">
			</a>
		</div><!-- .header-left -->
		<div class="header-right">


      
			<div class="child" *ngIf="!isTablet">
				<a href="/">
					<span class="content">
						<span class="text menu-text">{{'menu.home' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isTablet">
				<a class="menu-select pointer" (click)="openSubMenu()">
					<span class="content">
						<span class="text menu-text">{{'menu.about' | translate}}</span>
					</span>
				</a>
				<ng-container *ngIf="showSubmenu">
					<div class="divSubmenu">
						<div class="submenu">
							<a id="mtmManfaatProcurement" href="#manfaat-procurement" (click)="openSubMenu()">Procurement</a>
						</div>
						<div class="submenu">
							<a id="mtmManfaatVendor" href="#manfaat-vendor" (click)="openSubMenu()">Vendor</a>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="child" *ngIf="!isTablet">
				<a href="/subscription">
					<span class="content">
						<span class="text menu-text">{{'menu.subscription' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isTablet">
				<a href="/help">
					<span class="content">
						<span class="text menu-text">{{'menu.help' | translate}}</span>
					</span>
				</a>
			</div>

      
			<div class="child d-none">
				<a href="" class="lang-select">
					<span class="content">
						<span class="text text-uppercase">{{currentlang}}</span>
					</span><!-- .content -->
				</a>
			</div><!-- .child -->
			<div class="child group-buttons">

					<!-- <a href="/login" *ngIf="!isLoggedin" class="btn btn-cta2">{{'menu.login' | translate}}</a>
					<a href="/regis" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost">{{'menu.regis' | translate}}</a> -->
					<div class="n-1-hide n-768-show ">
						<a href="/login" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" class="btn py-2 btn-cta">Register</a>
		
					</div>



					<ng-container *ngIf="isLoggedin" class="d-none">
						<!-- <font class="fhc2 pointer" [hidden]="getcompanyName==''">
						<a href="{{redirectDashboardUrl()}}"><h2 class="mt-2 mb-2"><b>{{limitText(userdata.fullname)}}</b></h2></a>
						</font> -->

						<!-- <a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" *ngIf="isLoggedin" class="btn py-2 btn-cta">Register</a> -->

					</ng-container>
			</div><!-- .child -->
			<div class="child hamburger-container"  *ngIf="isTablet">
				<button class="hamburger hamburger--spin" type="button" aria-label="Menu button">
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div><!-- .child -->
		</div><!-- .header-right -->
	</div><!-- .main-container -->
</header>

<div id="mobile-menu-container">
	<div class="main-container">
		<div class="mobile-menu-content">
			<nav class="mobile-nav">
				<ul>
					<li class="nav-home">
						<a href="/">
							<span class="text">{{'menu.home' | translate}}</span>
						</a>
					</li>
					<!-- <li class="nav-about">
						<a href="">
							<span class="text">About Us</span>
						</a>
					</li> -->
					<li class="nav-product has-sub" *ngIf="isTablet">
						<a href="">
							<span class="text">{{'menu.about' | translate}}</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="#manfaat-vendor" id="mtmManfaatVendor">
										<span class="text">Vendor</span>
									</a>
								</li>
								<li>
									<a href="#manfaat-procurement" id="mtmManfaatProcurement">
										<span class="text">Procurement</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- <li class="nav-news has-sub">
						<a href="">
							<span class="text">News &amp; Articles</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="">
										<span class="text">News</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Articles</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Promo</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">View All</span>
									</a>
								</li>
							</ul>
						</div>
					</li> -->
					<li class="nav-contact">
						<a href="/subscription">
							<span class="text">{{'menu.subscription' | translate}}</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="/help">
							<span class="text">{{'menu.help' | translate}}</span>
						</a>
					</li>
					<!-- 
					<li class="nav-contact">
						<a href="">
							<span class="text">Contact Us</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="#section-faq">
							<span class="text">FAQ</span>
						</a>
					</li> -->
					<li class="nav-contact buttons n-1-show n-768-hide ">
						<a href="/regis" class="btn py-2 btn-cta mt-3 btnpillMenu text-white">Register</a>
						<a href="/logout" class="btn btn-cta btn-ghost  py-2 btnpillMenu mt-3">Login</a>

	
	
						<!-- <ng-container *ngIf="isLoggedin">
							<font class="fhc2 pointer" [hidden]="getcompanyName==''">
							<a href="{{redirectDashboardUrl()}}"><h2 class="mt-2 mb-2 pt-2 ms-3"><b>{{limitText(userdata.fullname)}}</b></h2></a>
							</font>
						</ng-container> -->

					</li>
					<li class="nav-contact buttons">
						<ng-container *ngIf="isLoggedin">
								<!-- <a href="/logout" class="btn btn-cta btn-ghost mobile-btn">Logout</a> -->

								<!-- <a href="/logout" class="btn py-2 btn-cta text-white btnpillMenu mt-2">Logout</a> -->

						</ng-container>

			

					</li>
				</ul>
			</nav><!-- .mobile-nav -->
		</div><!-- .mobile-menu-content -->
	</div><!-- .main-container -->
	<a href="" class="close-mobile-nav">Close Menu</a>
</div><!-- #mobile-menu-container -->


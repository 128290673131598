<div ngbAutofocus>

    <div id="modalCd" class="modal-dialog modal-lg modal-dialog-centered m-0">
        <div class="modal-content  rounded-0 border-0">
            <div class="modal-header bg1 rounded-0  text-center">
                <h4 class="text-white f1 mb-0"><strong>Report and Feedback</strong></h4>
                <button type="button" class="btn text-white ps-0 pe-0" data-bs-dismiss="modal" (click)="activeModal.close();"><i class="bi bi-x-lg"></i></button>
            </div>
            <div class="modal-body filterdiv p-5" *ngIf="!getResponse">
                <div class="ps-4 pe-4">

                <p class="filter">Terima kasih atas feedback nya untuk halaman ini :</p>

                <input type="text" class="mb-3 form-control" placeholder="Url" [(ngModel)]="valUrl" readonly>

                <table class="mb-4">
                    <tr>
                        <th>Nama</th>
                        <td class="ps-3 pe-2">:</td>
                        <td>{{valName}}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td class="ps-3 pe-2">:</td>
                        <td>{{valEmail}}</td>
                    </tr>
                    <tr>
                        <th>Nama Perusahaan</th>
                        <td class="ps-3 pe-2">:</td>
                        <td>{{valCompany}}</td>
                    </tr>
                    <tr>
                        <th>Role</th>
                        <td class="ps-3 pe-2">:</td>
                        <td>{{valRole}}</td>
                    </tr>
                </table>
                <!-- <input type="text" class="mb-3 form-control" placeholder="Company" [(ngModel)]="valCompany" readonly>

                <input type="text" class="mb-3 form-control" placeholder="Email" [(ngModel)]="valEmail" readonly>

                <input type="text" class="mb-3 form-control" placeholder="Role" [(ngModel)]="valRole" readonly> -->

                <select class="mb-3 form-control" [(ngModel)]="valIssue" #t (change)="changeIssue(t.value)">
                    <option value=""> - Select issue - </option>
                    <option value="BUG">Bug</option>
                    <option value="ERROR">Error</option>
                    <option value="INPUT">Masukan</option>
                </select>


                <textarea class="form-control" row="4" maxlength="500" placeholder="Tulisakan catatan"  [(ngModel)]="valMessage" [ngModelOptions]="{standalone: true}"  [disabled]="processMsg" (ngModelChange)="messageChange()"></textarea>
                <div class="text-center mt-3 mb-2">
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="submitMessage()" [disabled]="isInvalid">{{processMsg?'Loading...':'Kirim'}}</button>
                </div>
                </div>
            </div>
            <div  *ngIf="getResponse">
                <div class="text-center mt-3 mb-4">
                    <div class="m-4">
                        <p><font [innerHtml]="ResponseText"></font></p>
                    </div>
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="close()">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PopupfeedbackComponent } from 'src/app/components/popupfeedback/popupfeedback.component';

const gettac = gql`
query{
  registrationTermAndCondition{
      id
      version
      description
  }
}
`;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public href: string = "";
  isHomePage: boolean=false;

  isLoggedin:boolean=false
  userdata: any
  currentlang:any

  tos:boolean=false;
  tac: any = false;
  TnCsrc:any
  display ='none'

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private apollo: Apollo,
    ) { }

  ngOnInit(): void {
    this.href = this.router.url;
    console.log('this.href : ',this.href);

    if(this.href=='/'){
      this.isHomePage=true
    }

    this.currentlang = localStorage.getItem('language');
    console.log('HomepageComponent : ',this.currentlang)

    if(localStorage.getItem('language')=== null){
      localStorage.setItem('language', 'id');
      this.translate.use('id');
    } else {
      var setlang = localStorage.getItem('language') 
      if(setlang){
        this.translate.use(setlang);
      }
    }  

    this.loadProfile()
  }
  loadProfile() {
    var getuserdata = localStorage.getItem("USERDATA")
    if (getuserdata !== null) {
      this.userdata = JSON.parse(getuserdata);
      console.log('getuserdata', this.userdata);
      this.isLoggedin=true
    }
    console.log('this.href ',this.href)
    if(this.href=='/regis' || this.href=='/login'){
      this.isLoggedin=true
    }


    var arrUrl = this.href.split("/")
    console.log('this.router.url : ',this.router.url)
    console.log('arrUrl : ',arrUrl)
    if(Array.isArray(arrUrl)){
      if(arrUrl[1]=='search' || arrUrl[1]=='search-rfq' || arrUrl[1]=='product' || arrUrl[1]=='s'){
        this.isLoggedin=true
      }

      if(arrUrl[1]=='email-verification' || arrUrl[1]=='email-unblock' || arrUrl[1]=='reset-password' || arrUrl[1]=='email-verification-user'){
        this.isLoggedin=true
      }

      //handle returnUrl login
      var pathParts = location.pathname.split('/')
      console.log('check = ',pathParts[pathParts.length - 1])
      console.log('pathParts : ',pathParts)
      if(pathParts[pathParts.length - 1]=='login'){
        this.isLoggedin=true
      }
      if(pathParts[pathParts.length - 1]=='regis'){
        this.isLoggedin=true
      }

        //help & subscribtion after login
      if(arrUrl[1]=='help'){
        this.isLoggedin=false
      }
    }

    //after regis
    if(this.href=='/success-verification'){
      this.isLoggedin=true
    }

    //help & subscribtion after login
    if(this.href=='/help' || this.href=='/subscription'){
      this.isLoggedin=false
    }

    console.log('this.isLoggedin ',this.isLoggedin)
  }

  sendMessage(){

    const modalRef = this.modalService.open(PopupfeedbackComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
    })
    modalRef.componentInstance.url = window.location.href

    
  }

  getTerm(){
    this.apollo.watchQuery<any>({
      query: gettac
    })
    .valueChanges
    .subscribe(({data, loading}) => {
      console.log(loading);
      this.tac = data.registrationTermAndCondition;
      console.log('tac',this.tac);
      this.TnCsrc = {
        url: this.tac?.description,
        withCredentials: false
       }
    })
  }

  openModalTos() {
    if(!this.tac){
      this.getTerm()
    }
  }

  openVerticallyCentered(content:any) {
    this.openModalTos()
    this.modalService.open(content, { centered: true , size: 'lg' });
  }
}

<div>
  <div class="container" *ngIf="PageLoading">
    <div>
      <div class="text-center pt-5 pb-5">
        <div class="loader loader--style8" title="7">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;"
            xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!PageLoading">
    <div class="mt-3">
      <div class="pb-5">
        <div class="mb-4">
          <div class="d-none d-sm-block">
            <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
              <div class="row">
                <div class="col-sm-12">
                  <div class="shbreadcum">
                    <table>
                      <tr>
                        <td class="separator">
                          <h6 class="pt-2 mt-1 me-3"><strong><a href="/procurement/rating/procurement-rating-list"
                                class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png"
                                  class="backicon"> Back</a></strong></h6>
                        </td>
                        <td>
                          <h6 class="mb-1 mt-2 ms-3">
                            <strong class="c1">
                                
                                <a class="c1 pointer" href="/procurement/rating/procurement-rating-list">Rating and Review List</a> > 
                              <font class="text-muted">Vendor
                              </font>
                            </strong>
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

                  <!-- Breadcrumb mobile -->
                  <div class="d-block d-sm-none">

                    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">


                      <div class="row">
                        <div class="col-12">
                          <div class="pt-1">
                            <strong class="c1">
                              <a class="c1 pointer" href="/procurement/rating/procurement-rating-list">Rating and Review List</a> > 
                            <font class="text-muted">Vendor
                            </font>
                          </strong>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
          
          <div class="card border-0  mb-3 divshbreadcum">

            <div class="container">
              <div class="row">

                <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top ps-3 ps-md-5 ps-lg-5">
                  <h4 class="mb-0 fw-bold">Rating and Review Detail</h4>
                </div>


                <div *ngIf="PageLoading">
                  <div class="text-center pt-5 pb-5">
                    <div class="loader loader--style8" title="7">
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
                        style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                            repeatCount="indefinite" />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                            repeatCount="indefinite" />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                            repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                            repeatCount="indefinite" />
                        </rect>
                      </svg>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!alreadyRating">
                <div class="col-12"  *ngIf="!PageLoading">
                

                  <div class="px-0 px-sm-3 px-md-5 px-lg-5" *ngIf="showRatingVendor">
                      <h2 class="heading mt-2">Rate and Review Vendor!</h2>
                      <h4 class="heading-detail mb-4">PPO ID : {{DetailRating.proformaPoNumber}}</h4>
                      

                      <div class="cardshadow border-green m-center ps-5 pe-5 pt-4 pb-4 mb-4">
                        <div class="row  d-flex justify-content-center">
                          <div class="col-sm-6">
                            <p class="mt-2 heading-text">Your transaction has been successfully done, please give us rate based on your experiences!</p>
                          </div>
                          <div class="col-sm-6">

                          </div>
                          <div class="col-12  col-sm-7  col-md-5  col-lg-3">
                              <h3 class="mb-0 mt-2">DID THE TRANSACTION OCCUR?</h3>
                          </div>
                          <div class="col-auto col-sm-5  col-md-7  col-lg-9">

                            <div class="form-check form-switch mt-1 mb-1">
                              <input class="form-check-input"  (change)="changeOccur($event)" type="checkbox" id="isDisplayPriceOnShopfront" [(ngModel)]="transactionAccur"> 
                              <label for="isDisplayPriceOnShopfront"><strong>{{(transactionAccur)?'YES':'NO'}}</strong></label>
                            </div>
                            
                            <!-- <div class="row ratingyn">
                              <div class="col-6 text-right">
                                <input type="radio" class="btn-check" name="options-outlined" id="danger-outlined" autocomplete="off">
                                <label class="btn btn-outline-danger btnrating px-3" for="danger-outlined">No</label>
                              </div>
                              <div class="col-6 text-left">
                                <input type="radio" class="btn-check" name="options-outlined" id="success-outlined" autocomplete="off">
                                <label class="btn btn-outline-success btnrating px-3" for="success-outlined">Yes</label>
                              </div>
                            </div>

                             -->


                            <!-- <h3 (click)="showModalOccur()" class="mb-0 btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall btnrating" *ngIf="transactionAccur">{{(transactionAccur)?'Yes':'No'}}</h3>
                            <h3 (click)="showModalOccur()" class="mb-0 text-uppercase fsmall rounded-1 bg3 fw-bold border1 c1  pe-5 ps-5 btn" *ngIf="!transactionAccur">{{(transactionAccur)?'Yes':'No'}}</h3> -->
                        </div>
                        </div>
                      </div>

                      <div class="text-center">
                        <h3 class="titlerating">Rate and Review Vendor!</h3>
                      </div>
                      <div class="cardshadow m-rating px-2 px-sm-3 px-md-5 px-lg-5 pt-4 pb-4  mb-4">
                          <div class="row">
                              <div class="col-sm-12">
                                  <h3>({{DetailRating.vendorName}})</h3>
                              </div>
                              <div class="col-12 col-sm-6  col-md-5  col-lg-3">
                                  <div class="mb-3" *ngFor="let data of RatingCompanyQuestion; let i = index">


                                    <div class="row g-0">
                                      <div class="col-5 col-sm-12">
                                        <h4 id="{{data.companyRatingQuestionId}}" class="mb-0">{{data.question}}</h4>
                                      </div>
                                      <div class="col-7 col-sm-12">

                                        <img class="rating-icon pointer" 
                                        (click)="clickStar('ONE',data.companyRatingQuestionId,1)" 
                                        (mouseover)="addStar('ONE',data.companyRatingQuestionId,1)" 
                                        (mouseout)="addStar('ONE',data.companyRatingQuestionId,1)" 
                                        src="/assets/images/icons/star-filled-{{checkStar('ONE',data.companyRatingQuestionId,1) ?'fill':'blank'}}.png">
                                        <img class="rating-icon pointer" 
                                        (click)="clickStar('TWO',data.companyRatingQuestionId,2)" 
                                        (mouseover)="addStar('TWO',data.companyRatingQuestionId,2)" 
                                        (mouseout)="addStar('TWO',data.companyRatingQuestionId,2)" 
                                        src="/assets/images/icons/star-filled-{{checkStar('TWO',data.companyRatingQuestionId,2) ?'fill':'blank'}}.png">
                                        <img class="rating-icon pointer" 
                                        (click)="clickStar('THREE',data.companyRatingQuestionId,3)" 
                                        (mouseover)="addStar('THREE',data.companyRatingQuestionId,3)" 
                                        (mouseout)="addStar('THREE',data.companyRatingQuestionId,3)" 
                                        src="/assets/images/icons/star-filled-{{checkStar('THREE',data.companyRatingQuestionId,3) ?'fill':'blank'}}.png">
                                        <img class="rating-icon pointer" 
                                        (click)="clickStar('FOUR',data.companyRatingQuestionId,4)" 
                                        (mouseover)="addStar('FOUR',data.companyRatingQuestionId,4)" 
                                        (mouseout)="addStar('FOUR',data.companyRatingQuestionId,4)" 
                                        src="/assets/images/icons/star-filled-{{checkStar('FOUR',data.companyRatingQuestionId,4) ?'fill':'blank'}}.png">
                                        <img class="rating-icon pointer" 
                                        (click)="clickStar('FIVE',data.companyRatingQuestionId,5)" 
                                        (mouseover)="addStar('FIVE',data.companyRatingQuestionId,5)" 
                                        (mouseout)="addStar('FIVE',data.companyRatingQuestionId,5)" 
                                        src="/assets/images/icons/star-filled-{{checkStar('FIVE',data.companyRatingQuestionId,5) ?'fill':'blank'}}.png">
    
                                      </div>
                                    </div>



                                  
                                  </div>
                                
                                  <div class="errmsg" *ngIf="invalidRating">Please rating the vendor.</div>
                              </div>
                              <div class="col-12 col-sm-6  col-md-7  col-lg-9">
                                  <h4 class="mb-2 m-desc">Review Description</h4>
                                  <textarea class="form-control"  rows="5" cols="40" maxlength="500" placeholder="Type Review Description" [(ngModel)]="valReviewVendor"></textarea>
                              </div>
                          </div>
                      </div>

                  </div>



                  <div class="px-0 px-sm-3 px-md-5 px-lg-5" *ngIf="transactionAccur">
                    <!-- <h2 class="heading mt-5 mb-3">Rate and Review Products!</h2> -->
                    <div class="text-center">
                      <h3 class="titlerating">Rate and Review Products!</h3>
                    </div>
                    <!-- // starRatingProduct

                    // proformaPoItemId
                    // productName
                    // productBrand
                    // productDescription
                    // rating
                    // review -->

                      <div class="cardshadow px-2 px-sm-3 px-md-5 px-lg-5 pt-4 pb-4 mb-4" *ngFor="let data of RatingProduct; let i = index">
                          <div class="row">
                              <div class="col-sm-3">



                                <div class="row g-0">
                                  <div class="col-12">

                                    <h4>{{(data.productBrand) ? data.productBrand+' : ':''}} {{data.productName}} </h4>
                                  </div>
                                  <div class="col-12 mb-3">

                                    <img class="rating-icon pointer" 
                                    (click)="clickStarProduct('ONE',data.proformaPoItemId,1)" 
                                    (mouseover)="addStarProduct('ONE',data.proformaPoItemId,1)" 
                                    (mouseout)="addStarProduct('ONE',data.proformaPoItemId,1)" 
                                    src="/assets/images/icons/star-filled-{{checkStarProduct('ONE',data.proformaPoItemId,1) ?'fill':'blank'}}.png">
                                    <img class="rating-icon pointer" 
                                    (click)="clickStarProduct('TWO',data.proformaPoItemId,2)" 
                                    (mouseover)="addStarProduct('TWO',data.proformaPoItemId,2)" 
                                    (mouseout)="addStarProduct('TWO',data.proformaPoItemId,2)" 
                                    src="/assets/images/icons/star-filled-{{checkStarProduct('TWO',data.proformaPoItemId,2) ?'fill':'blank'}}.png">
                                    <img class="rating-icon pointer" 
                                    (click)="clickStarProduct('THREE',data.proformaPoItemId,3)" 
                                    (mouseover)="addStarProduct('THREE',data.proformaPoItemId,3)" 
                                    (mouseout)="addStarProduct('THREE',data.proformaPoItemId,3)" 
                                    src="/assets/images/icons/star-filled-{{checkStarProduct('THREE',data.proformaPoItemId,3) ?'fill':'blank'}}.png">
                                    <img class="rating-icon pointer" 
                                    (click)="clickStarProduct('FOUR',data.proformaPoItemId,4)" 
                                    (mouseover)="addStarProduct('FOUR',data.proformaPoItemId,4)" 
                                    (mouseout)="addStarProduct('FOUR',data.proformaPoItemId,4)" 
                                    src="/assets/images/icons/star-filled-{{checkStarProduct('FOUR',data.proformaPoItemId,4) ?'fill':'blank'}}.png">
                                    <img class="rating-icon pointer" 
                                    (click)="clickStarProduct('FIVE',data.proformaPoItemId,5)" 
                                    (mouseover)="addStarProduct('FIVE',data.proformaPoItemId,5)" 
                                    (mouseout)="addStarProduct('FIVE',data.proformaPoItemId,5)" 
                                    src="/assets/images/icons/star-filled-{{checkStarProduct('FIVE',data.proformaPoItemId,5) ?'fill':'blank'}}.png">

                                  </div>
                                </div>


                              </div>
                              <div class="col-sm-9">
                                  <h4 class="mb-2 m-desc">Review Description</h4>
                                  <textarea class="form-control txtareaReview"  rows="5" cols="40" maxlength="500" placeholder="Type Review Description"  [(ngModel)]="valReviewProducts[i]"></textarea>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="text-center"   *ngIf="showRatingVendor">
                      <button class="fhbtn1 ratingbtn mt-0 mt-sm-4 mt-md-4 mt-lg-4 mb-4 r10" (click)="postDataReview()">Submit</button>
                  </div>



                </div>
                </ng-container>
                <ng-container *ngIf="alreadyRating">
                  <div class="col-12"  *ngIf="!PageLoading">
                    <ng-container *ngIf="!onEditMode">

                      <div class="px-0 px-sm-3 px-md-5 px-lg-5" *ngIf="showRatingVendor">
                          <h2 class="heading mt-2">Rate and Review Vendor!</h2>
                          <h4 class="heading-detail mb-4">PPO ID : {{DetailRating.proformaPoNumber}}</h4>
                          

                          <div class="d-block d-sm-none">
                            <div class="text-center  mb-4">
                              <h2 class="overallrating ps-3">{{DetailRating?.totalRating | number : '1.1-1'}}<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png"></h2>
                              <small class="">Rating  Vendor Overall</small>
                            </div>

                          </div>


                            <div class="cardshadow border-green m-center m-center ps-5 pe-5 pt-4 pb-4 mb-4">
                              <div class="row">
                                <div class="col-sm-9">
                                  <p class="mt-0 mt-sm-4 mb-2 heading-text">Your transaction has been successfully done, please give us rate based on your experiences!</p>

                                  <div class="d-none d-sm-block">
                                    <h3 class="mb-0 mt-2">DID THE TRANSACTION OCCUR? 
                                      <strong *ngIf="DetailRating?.isRating" class="text-success ms-sm-4">YES</strong>
                                      <strong *ngIf="!DetailRating?.isRating" class="text-danger ms-sm-4">NO</strong>
                                      </h3>
                                  </div>
                                  <div class="d-block d-sm-none">
                                    <h3 class="mb-0 mt-2">DID THE TRANSACTION OCCUR? <br><br>
                                      <strong *ngIf="DetailRating?.isRating" class="text-success ms-sm-4">YES</strong>
                                      <strong *ngIf="!DetailRating?.isRating" class="text-danger ms-sm-4">NO</strong>
                                      </h3>
                                  </div>

                                </div>
                                <div class="col-sm-3 text-center">

                                  <div class="d-none d-sm-block">
                                    <h2 class="overallrating ps-3">{{DetailRating?.totalRating | number : '1.1-1'}}<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png"></h2>
                                    <h4 class="overalltext">Rating  Vendor Overall</h4>
                                  </div>

                                </div>



                              </div>
                            </div>
      
                            <div class="text-center">
                              <h3 class="titlerating">Rate and Review Vendor!</h3>
                            </div>
                            <div class="cardshadow px-2 px-sm-3 px-md-5 px-lg-5 pt-4 pb-4  mb-4">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3>({{DetailRating.vendorName}})</h3>
                                    </div>
                                    <div class="col-sm-3">

                                      <div *ngFor="let data of GetPpoRatingVendor?.ppoRatingData; let i = index"   >


                                        <div class="row g-0 mb-3">
                                          <div class="col-5 col-sm-12">
                                            <h4 id="{{data.question}}" class="mb-0">{{data.question}}</h4>
                                          </div>
                                          <div class="col-7 col-sm-12">
                                            <img *ngFor='let in of counter(5) ;let i = index' class="rating-icon " src="/assets/images/icons/star-filled-{{i < data.rating ?'fill':'blank'}}.png">
                                          </div>
                                        </div>








                                        

                                        

                                      </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <h4 class="mb-2">Review Description</h4>
                                        <div><p class="heading-text">{{GetPpoRatingVendor?.review}}</p></div>
                                    </div>
                                </div>
                            </div>
                      </div>
    

                      <div class="px-0 px-sm-3 px-md-5 px-lg-5" *ngIf="DetailRating.isTransactionOccur">
                        <div class="text-center">
                          <h3 class="titlerating">Rate and Review Products!</h3>
                        </div>

                          <div class="cardshadow px-2 px-sm-3 px-md-5 px-lg-5 pt-4 pb-4 mb-4" *ngFor="let data of RatingProduct; let i = index">
                              <div class="row">
                                  <div class="col-sm-3">


                                    <div class="row g-0">
                                      <div class="col-12">
                                        <h3>{{(data.productBrand) ? data.productBrand+' : ':''}}<font>{{data.productName}}</font></h3>
                                      </div>
                                      <div class="col-12 mb-3">
                                        <img *ngFor='let in of counter(5) ;let i = index' class="rating-icon " src="/assets/images/icons/star-filled-{{i < data.rating ?'fill':'blank'}}.png">
                                      </div>
                                    </div>

                                      
                                      
                                  </div>
                                  <div class="col-sm-9">
                                      <h4 class="mb-2">Review Description</h4>
                                      <div><p class="heading-text">{{data?.review}}</p></div>
                                  </div>
                              </div>
                          </div>
                      </div>
    
                      <div class="text-center"   *ngIf="showRatingVendor">
                          <button class="fhbtn3 ratingbtn mt-4 mb-4 r10" (click)="clickEditMode()"><i class="bi bi-pencil-square pe-1"></i> Edit</button>
                      </div>

                    </ng-container>


                  </div>
                </ng-container>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

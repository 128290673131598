import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/config';
import Util from 'src/app/_helpers/util';
import { LightGallery } from 'lightgallery/lightgallery';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() images?: any;
  @Input() title?: string='';
  @Input() company?: string='';
  @Input() currency?: string='';
  @Input() price?: string='';
  @Input() location?: string='';
  @Input() isConfidential?: string='N';
  @Input() url?: string='';
  @Input() isHome?: string='';

  galleryId = (Math.random() + 1).toString(36).substring(7)

  private lightGallery!: LightGallery;
  private needRefresh = false;

  constructor() { }

  ngOnInit(): void {
  }

  

  settingsGallery = {
    counter: true,
    download:false,
    selector: '.itemz'
  };
  
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }

  
}

<div class="card divshadow mb-4 position-relative">
    <div class="card-header fhbgblue bg-info text-center">
        <h5 class="text-white m-0 text-uppercase" *ngIf="!assistanceTitle"><strong>User Hint</strong></h5>
        <h5 class="text-white m-0 text-uppercase" *ngIf="assistanceTitle"><strong>{{assistanceTitle}}</strong></h5>
    </div>
    <div class="card-body p-1">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-1">
                <div class="position-absolute top-0 pt-1">
                    <!-- <div class="rounded-circle mt-2" style="width: 80px; height: 80px; background-color: darkgray;"></div> -->
                    <img src="/assets/images/icons/{{assistanceType=='vendor'?'Avatar local man.png':'Avatar local woman.png'}}" class="rounded-circle avatr">

                </div>
            </div>
            <div class="col-sm-10">
                <!-- <p class="ps-3 mt-1 mb-0" [innerHTML]="assistanceText"></p> -->

                
                <div class="row g-0">
                    <div class="col-sm-10">
                        <p class="ps-3 mt-1 mb-0 pt-2 pt-sm-3 pt-md-0 pt-lg-0 pb-1" [innerHTML]="assistanceText"></p>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-9">
                            <div class="">

                              <div class="py-1" *ngIf="assistanceStepper!=='[]'">
                                <div class="steps d-flex justify-content-between padding-top-2x padding-bottom-1x"> <!--  flex-wrap  flex-sm-nowrap -->
                                    <ng-container *ngFor="let step of arrayStepper; let i = index">
                                        <div class="step {{(step.status)?'completed':''}}">
                                            <div class="step-icon-wrap">
                                                <div class="step-icon">
                                                    <i class="bi bi-check2"></i>
                                                </div>
                                            </div>
                                            <h4 class="step-title"><font [innerHTML]="step.text"></font></h4>
                                          </div>
                                    </ng-container>
                                    

                                  <!-- <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon">
                                            <i class="bi bi-check2"></i>
                                        </div>
                                    </div>
                                    <h4 class="step-title">Confirmed Order</h4>
                                  </div>
                                  <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon">
                                            <i class="bi bi-check2"></i>
                                        </div>
                                    </div>
                                    <h4 class="step-title">Processing Order</h4>
                                  </div>
                                  <div class="step completed">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon">
                                            <i class="bi bi-check2"></i>
                                        </div>
                                    </div>
                                    <h4 class="step-title">Quality Check</h4>
                                  </div>
                                  <div class="step">
                                    <div class="step-icon-wrap">
                                        <div class="step-icon">
                                            <i class="bi bi-check2"></i>
                                        </div>
                                    </div>
                                    <h4 class="step-title">Product Dispatched</h4>
                                  </div> -->
                                </div>
                              </div>
                            </div>

                    </div>



                </div>
                <div class="col-sm-4"></div>




            </div>
        </div>
    </div>
</div>
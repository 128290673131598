import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { gql, Apollo } from 'apollo-angular';
import { LightGallery } from 'lightgallery/lightgallery';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import Util from 'src/app/_helpers/util';
import { OthersService } from 'src/app/_services/others.service';
import { PopupfeedbackComponent } from 'src/app/components/popupfeedback/popupfeedback.component';

const gettac = gql`
query{
  registrationTermAndCondition{
      id
      version
      description
  }
}
`;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  currentlang:any
  valSearch:string=''
  invalidSearch:boolean=false
  loadingSearch:boolean=false

  userdata: any
  getcompany: any
  usercompany: any
  roleName: string = ''
  getcompanyName: any = ''
  getcompanyID: any
  ValCompany: any = ''
  ValCompanyName: any
  ArrCompany = []
  notYetSelectCompany: boolean = false
  singleCompany: boolean = true
  isLoggedin:boolean=false

  roleId:string=''

  tos:boolean=false;
  tac: any = false;
  TnCsrc:any
  display ='none'

  templateId:any="0"

  pauseOnHover = true;
  pauseOnFocus = true;

  
  publicProducts:any=[]
  publicProductsLoading:boolean=true

  publicRFQ:any=[]
  publicRFQLoading:boolean=true
  

  private lightGallery!: LightGallery;
  private needRefresh = false;

  dataRfqBoard:any=[]

  valSearchHero:string=''
  invalidSearchHero:boolean=false
  loadingSearchHero:boolean=false

  isMobile:boolean=false
  showSubmenu:boolean=false
  innerWidth: number=0;
  
  maxSlideTemplate = 5
  startTemplate = 1

  constructor(
    public translate: TranslateService,
    private router: Router,
    private apollo: Apollo,
    private modalService: NgbModal,
    private http:HttpClient,
    private othersService: OthersService,
    private popupmodal:Popupmodal
  ) { 
    //this.getTemplate()
  }

  @ViewChild('carousel') carousel:NgbCarousel | undefined
  posIni: any;
  move(pos:number) {
    if(this.carousel){
      const offset = this.posIni - pos;
      if (offset < -100) this.carousel.prev()
  
      if (offset > 100) this.carousel.next();
    }

  }
  
  ngOnInit(): void {

    this.innerWidth = window.innerWidth;
    console.log('this.innerWidth',this.innerWidth);
    if(this.innerWidth < 992){
      this.isMobile=true
    } else {
      this.isMobile=false
    }


    this.currentlang = localStorage.getItem('language');
    console.log('HomepageComponent : ',this.currentlang)
    this.loadProfile()
    // console.log('window.location.href : ',window.location.href)
    // this.http.get(window.location.href, { observe: 'response' }).subscribe(res => {
    //   this.templateId = res.headers.get('X-Powered-By');
    // });
    // this.http.get<any>('url', {observe: 'response'})
    // .subscribe(resp => {
    //   console.log(resp.headers.get('X-Token'));
    // });
    // this.getTemplate()

    this.getPublicProduct()

    this.getanyoneViewRfqList()
  }

  // getTemplate() {
  //   console.log('getTemplate')
  //   var getTemplate = localStorage.getItem('templateID');
  //   if(!getTemplate){
  //     console.log('getTemplate via api')
  //     var urlGetIp = '/v1/getTemplate'
  //     this.http.get(urlGetIp).subscribe((res:any)=>{  
  //       // this.getipdetail=res;  
  //       // console.log('getipdetail',this.getipdetail);
  //       console.log('getTemplate res ',res.data);
  //       if(res.data){
  //         this.templateId = res.data.toString()
  //         localStorage.setItem('templateID',res.data.toString())
  //       } 
  //       else {
  //         this.templateId = "1"
  //       }
  //     });  
  //   } else {
  //     console.log('getTemplate via storage')
  //     this.templateId = getTemplate
  //   }
  // }

  getPublicProduct(){
    

    this.othersService
    .getPublicProduct()
    .subscribe((res:any) => {
      console.log("succes, get data:", res);
      this.publicProductsLoading = false
      
      if(res.data?.getPublicProduct){
        this.publicProducts = res.data?.getPublicProduct
        console.log('total product ',this.publicProducts?.totalData)

        if (this.publicProducts?.totalData % 6 === 0) { 
          //if divided by 6, :avoid 1,2,3,4,5,1 => 1 meet 1
          // loop 1,2,3,4,1,2
          this.maxSlideTemplate = 4
        }
      }
      // else if(res.errors){
      //   var error = res.errors[0].message
      //   console.log('error',error);
      //   var errMsg = error + '';
      //   var result = errMsg.split(":");
      //   var resMsg = result[result.length - 1];
      //   var msg = (resMsg)? resMsg: errMsg
      //   console.log('msg',msg);
      //   var errortext = msg

      //   if(res.errors[0].extensions.type){
      //     this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
      //       if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
      //         errortext = data
      //       }
      //     });
      //     var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
      //     if(forceRedirect){
      //       window.location.href = forceRedirect
      //       return
      //     }
      //   }
      //   this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      // }
      // else {
      //   this.popupmodal.showFail().subscribe((res:any) => {})
      // }
      
    });

  }

  getanyoneViewRfqList(){
    

    this.othersService
    .anyoneViewRfqList()
    .subscribe((res:any) => {
      console.log("succes, get data:", res);
      this.publicRFQLoading = false
      
      if(res.data?.anyoneViewRfqList){
        this.publicRFQ = res.data?.anyoneViewRfqList


        this.publicRFQ.forEach((value: any, key: any) => {

        //   {
        //     "rfqId": "01HXRP2JHG0MX07GK2XYZAFBTW",
        //     "rfqDisplayId": "QRJLO8ME",
        //     "procurementName": null,
        //     "description": "<fhspecdiv>[]</fhspecdiv>",
        //     "rfqVendorCategory": [
        //         "meat"
        //     ],
        //     "submitQuotationLastDate": "2024-07-13T17:00:00Z",
        //     "rfqDeliveryCountry": "Indonesia",
        //     "rfqDeliveryProvince": "Jakarta",
        //     "rfqDeliveryCity": "Jakarta pusat",
        //     "rfqDeliveryStreet": "Jln thamrin sebelah grand indonesia ",
        //     "rfqDeliveryPostalCode": null,
        //     "rfqState": "PUBLISHED",
        //     "totalParticipation": 0,
        //     "rfqItem": [
        //         {
        //             "id": "01HXRP2JMG49Y2GFBCHWBP577C",
        //             "brand": null,
        //             "description": "Daging sapi sengkel<fhspecdiv>[]</fhspecdiv>"
        //         },
        //         {
        //             "id": "01HXRP2JMG3ANYMR73PXN4Q1S0",
        //             "brand": null,
        //             "description": "Daging sapi khas dalam<fhspecdiv>[]</fhspecdiv>"
        //         }
        //     ],
        //     "isAlreadyParticipating": false,
        //     "haveMoreRfqItem": true,
        //     "rfqParticipationId": null,
        //     "quotationId": null
        // },


          var categoryComma ='';
          if(value.rfqVendorCategory){
            categoryComma = value.rfqVendorCategory.join(", ")
          }
          

          var dataBoard ={
            city:value.rfqDeliveryCity,
            country:value.rfqDeliveryCountry,
            submitQuotationLastDate:value.submitQuotationLastDate,
            totalVendorParticipation:value.totalParticipation,
            procurementName:value.procurementName,
           // rfqState:value.rfqState,
            rfqState:null,
            isAlreadyParticipating:value.isAlreadyParticipating,
            rfqVendorCategory:categoryComma,
            rfqDisplayId:value.rfqDisplayId,
            description:value.description,
            rfqItem:value.rfqItem,
            haveMoreRfqItem:value.haveMoreRfqItem,
            sponsored:false,
            //url:this.redirectUrl(value.rfqId,value.rfqParticipationId,value.quotationId)
            url:'/regis'
          }
          console.log('dataBoard : ',dataBoard)
          this.dataRfqBoard.push(dataBoard)


        })
        

      }
      // else if(res.errors){
      //   var error = res.errors[0].message
      //   console.log('error',error);
      //   var errMsg = error + '';
      //   var result = errMsg.split(":");
      //   var resMsg = result[result.length - 1];
      //   var msg = (resMsg)? resMsg: errMsg
      //   console.log('msg',msg);
      //   var errortext = msg

      //   if(res.errors[0].extensions.type){
      //     this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
      //       if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
      //         errortext = data
      //       }
      //     });
      //     var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
      //     if(forceRedirect){
      //       window.location.href = forceRedirect
      //       return
      //     }
      //   }
      //   this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      // }
      // else {
      //   this.popupmodal.showFail().subscribe((res:any) => {})
      // }
      
    });

  }
  
  globalSearchProduct(){
    var keyword = encodeURIComponent(this.valSearchHero)

    var checkKeyword = this.valSearchHero.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearchHero=true
      return
    } else{
      this.invalidSearchHero=false
    }
    this.loadingSearchHero=true
    window.location.href = '/search/'+keyword;
    this.loadingSearchHero=false
    //this.router.navigate(['/search/'+keyword])

  }
  globalSearchRFQ(){
    var keyword = encodeURIComponent(this.valSearchHero)

    var checkKeyword = this.valSearchHero.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearchHero=true
      return
    } else{
      this.invalidSearchHero=false
    }
    this.loadingSearchHero=true
    window.location.href = '/search-rfq/'+keyword;
    this.loadingSearchHero=false
    //this.router.navigate(['/search/'+keyword])
  }

  switchLang(lang:any) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentlang = lang
    //window.location.reload();
  }
  globalSearch(){
    var keyword = encodeURIComponent(this.valSearch)

    var checkKeyword = this.valSearch.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }
    this.loadingSearch=true
    window.location.href = '/search/'+keyword;
    this.loadingSearch=false
    //this.router.navigate(['/search/'+keyword])

  }

  loadProfile() {
    var getuserdata = localStorage.getItem("USERDATA")
    if (getuserdata !== null) {
      this.userdata = JSON.parse(getuserdata);
      console.log('getuserdata', this.userdata);
      this.isLoggedin=true
    }

    var getusercompany = localStorage.getItem("USERCOMPANY")
    if (getusercompany !== null) {
      this.usercompany = JSON.parse(getusercompany);
      console.log('getusercompany', this.usercompany);

      //this.roleName = this.getRoleName(this.usercompany.roleMembership)
      this.roleName= Util.getRole(this.usercompany?.roleMembership)
    }

    var company = localStorage.getItem("COMPANYDATA")
    console.log('company',company)
    console.log('(company)',typeof company)
    console.log('company',company  === 'undefined' ? 'und':'ga')
    if (company !== null) {
      if(company!=='undefined'){
        this.getcompany = JSON.parse(company);
        console.log('element', this.getcompany[0]['companyName']);
      }

    }

    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.getcompanyName = localStorage.getItem("COMPANYSELECTNAME")
      this.getcompanyID = localStorage.getItem("COMPANYSELECTID")
    } else {
      this.notYetSelectCompany = true
    }

    var getcompany = localStorage.getItem("COMPANYDATA")

    if (getcompany !== null) {
      if(getcompany!=='undefined'){
      this.ArrCompany = JSON.parse(getcompany);
      console.log(this.ArrCompany);

      if (this.ArrCompany.length > 1) {
        this.singleCompany = false
        console.log('this.singleCompany', this.singleCompany);


        if (localStorage.getItem("COMPANYSELECTID") === null) {
          //this.ChangeCompany() //open modal to choose company
          //this.open()
        }

      } else {
        this.ValCompany = this.ArrCompany[0]['companyId']
        this.ValCompanyName = this.ArrCompany[0]['companyName']
        
        //handle loop refresh location.reload()
        if (localStorage.getItem("COMPANYSELECTID") === null) {
          //this.ConfirmCompany()
        }
        console.log('this.singleCompany', this.singleCompany);
      }
    }
    }
  }
  limitText(val:any){
    var checkChar = val
    if(checkChar.length>11){
      checkChar = checkChar.substring(0,11) + '...'
    }
    return checkChar
  }
  limitTextSlides(val:any){
    var checkChar = val
    if(checkChar.length>25){
      checkChar = checkChar.substring(0,25) + '...'
    }
    return checkChar
  }

  loadSlides(i:number){

    var check =  i % this.maxSlideTemplate;
    var res = this.startTemplate + check;
    // console.log('check res : ',res)

    // startTemplate
    
    return res
  }

  redirectDashboardUrl(){
    var getrole = ''
    if(this.usercompany){
      if(this.usercompany?.roleMembership){
        getrole = this.usercompany?.roleMembership 
      }
    }
    return Util.redirectDasboard(getrole) 
  }

  sendMessage(){
    const modalRef = this.modalService.open(PopupfeedbackComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
    })
    modalRef.componentInstance.url = window.location.href
  }


  getTerm(){
    this.apollo.watchQuery<any>({
      query: gettac
    })
    .valueChanges
    .subscribe(({data, loading}) => {
      console.log(loading);
      this.tac = data.registrationTermAndCondition;
      console.log('tac',this.tac);
      this.TnCsrc = {
        url: this.tac?.description,
        withCredentials: false
       }
    })
  }

  openModalTos() {
    if(!this.tac){
      this.getTerm()
    }
  }

  openVerticallyCentered(content:any) {
    this.openModalTos()
    this.modalService.open(content, { centered: true , size: 'lg' });
  }
  
  openSubMenu(){
    if(this.showSubmenu){
      this.showSubmenu=false
    } else {
      this.showSubmenu=true
    }
  }


  settingsGallery = {
    counter: true,
    download:false,
    selector: '.itemz'
  };
  
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    //console.log('innerWidth',this.innerWidth);

    if(this.innerWidth < 992){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

}

<app-memberheader></app-memberheader>
<!-- 

<div id="wrapper" class="active" [className]="(sidebar) ? '' : 'active'">
      
    <div id="sidebar-wrapper">
        <ul id="sidebar_menu" class="sidebar-nav">
            <li class="sidebar-brand"><a id="menu-toggle" (click)='hideshowmenu()'>Menu
                <i id="main_icon" class="bi bi-list"></i>
            </a></li>
        </ul>
        <ul class="sidebar-nav" id="sidebar">     
            <li><a href="/vendor/dashboard">Dashboard<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
            <li><a href="/vendor/inbox">Inbox<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
            <li><a href="/vendor/projects">Projects<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
            <li><a href="/vendor/products">Products<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
            <li><a href="/vendor/settings">Settings<span class="sub_icon glyphicon glyphicon-link"></span></a></li>
        </ul>
    </div>
        

    <div id="page-content-wrapper">
      <div class="page-content inset">
        <router-outlet></router-outlet>
      </div>
    </div>
    
</div> -->


<div id="page-content-wrapper">
    <div class="page-content inset fhcontent">
      <router-outlet></router-outlet>
    </div>
  </div>

<app-footer></app-footer>
import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const querygetTotalUnreadMessageThread = gql`
query {
  getTotalUnreadMessageThread {
      totalUnreadMessage
      totalUnreadNotificationSystem
  }
}
`;

const queryloadMessageList = gql`
query loadMessageList($limit: Int! , $offset: Int!){
    loadMessageList(input: {
        limit: $limit
        offset: $offset
    }) {
        totalItem
        messageListData {
            messageId
            procurementId
            procurementName
            vendorId
            vendorName
            rfqId
            rfqDisplayId
            internalRfqProcurementNo
            quotationId
            quotationDisplayId
            internalQuotationVendorNo
            poNumber
            isRead
            isStar
            lastMessageDatetime
            lastMessageContent
            companyAs
        }
    }
}
`;

const queryvendorCheckThreadMessageExist = gql`
query vendorCheckThreadMessageExist($rfqId: ID!){
    vendorCheckThreadMessageExist(input: {
        rfqId: $rfqId
    }) {
        isExist
        messageId
    }
}
`;

const queryprocurementCheckThreadMessageExist = gql`
query procurementCheckThreadMessageExist($rfqId: ID!,$quotationId: ID!){
  procurementCheckThreadMessageExist(input: {
      rfqId: $rfqId
      quotationId: $quotationId
  }) {
      isExist
      messageId
  }
}
`;

const queryaskProcurement = gql`
mutation askProcurement($rfqId: ID!, $askProcurementInputData: AskInputData!){
    askProcurement(input: {
        rfqId: $rfqId
        askProcurementInputData: $askProcurementInputData
    }) 
}
`;

const queryaskVendor = gql`
mutation askVendor($rfqId: ID!, $quotationId: ID!, $askVendorInputData: AskInputData!){
  askVendor(input: {
      rfqId: $rfqId
      quotationId: $quotationId
      askVendorInputData: $askVendorInputData
  }) 
}
`;


const queryloadMessageDetail = gql`
query loadMessageDetail($messageId: ID!){
  loadMessageDetail(input: {
      messageId: $messageId
  }) {
      messageContentId
      content
      messageFrom
      messageDate
  }
}
`;



const querysendMessage = gql`
mutation sendMessage($messageId: ID!,$message: String!, $messageFrom: MessageFrom!){
  sendMessage(input: {
      messageId: $messageId
      message: $message
      messageFrom: $messageFrom
  }) 
}
`;



const querystarMessage = gql`
mutation starMessage($messageId: ID!){
  starMessage(input: {
      messageId: $messageId
  }) 
}
`;

const queryunstarMessage = gql`
mutation unstarMessage($messageId: ID!){
  unstarMessage(input: {
      messageId: $messageId
  }) 
}
`;


const queryloadNotificationSystemList = gql`

query loadNotificationSystemList($limit: Int! , $offset: Int!){
  loadNotificationSystemList(input: {
      limit: $limit
      offset: $offset
  }) {
      totalItem
      notificationData {
          notificationId
          notificationSubject
          notificationTime
          notificationContent
          isRead
          isFavorite
          notificationCategory
      }
  }
}
`;

const queryloadNotificationSystemDetail = gql`
query loadNotificationSystemDetail($notificationId: ID!){
  loadNotificationSystemDetail(input: {
      notificationId: $notificationId
  }) {
      notificationId
      notificationSubject
      notificationTime
      notificationContent
      isRead
      isFavorite
      notificationCategory
  }
}
`;

const queryloadNotificationInternalList = gql`

query loadNotificationInternalList($limit: Int! , $offset: Int!){
  loadNotificationInternalList(input: {
      limit: $limit
      offset: $offset
  }) {
      totalItem
      notificationData {
          notificationId
          notificationSubject
          notificationTime
          notificationContent
          isRead
          isFavorite
          notificationCategory
      }
  }
}
`;

const queryloadNotificationInternalDetail = gql`
query loadNotificationInternalDetail($notificationId: ID!){
  loadNotificationInternalDetail(input: {
      notificationId: $notificationId
  }) {
      notificationId
      notificationSubject
      notificationTime
      notificationContent
      isRead
      isFavorite
      notificationCategory
  }
}
`;

const querystarNotification = gql`
mutation starNotification($notificationIds:[ID!]){
  starNotification(input: {
      notificationIds: $notificationIds
  }) 
}
`;

const queryunstarNotification = gql`
mutation unStarNotification($notificationIds:[ID!]){
  unStarNotification(input: {
      notificationIds: $notificationIds
  }) 
}
`;

const querysearchAndFilterMessageList = gql`
query searchAndFilterMessageList($searchAndFilterMessageListInputData:SearchAndFilterMessageListInputData!,$limit: Int! , $offset: Int!){
  searchAndFilterMessageList(input: {
      searchAndFilterMessageListInputData: $searchAndFilterMessageListInputData
      limit: $limit
      offset: $offset
  }) {
      totalItem
      messageListData {
          messageId
          procurementId
          procurementName
          vendorId
          vendorName
          rfqId
          rfqDisplayId
          internalRfqProcurementNo
          quotationId
          quotationDisplayId
          internalQuotationVendorNo
          poNumber
          isRead
          isStar
          lastMessageDatetime
          lastMessageContent
          companyAs
      }
  }
}
`;


const querysearchAndFilterSystemList = gql`
query searchAndFilterNotificationSystemList($searchAndFilterMessageListInputData:SearchAndFilterMessageListInputData!,$limit: Int! , $offset: Int!){
  searchAndFilterNotificationSystemList(input: {
    searchAndFilterMessageListInputData: $searchAndFilterMessageListInputData
    limit: $limit
    offset: $offset
  }) {
      totalItem
      notificationData {
          notificationId
          notificationSubject
          notificationTime
          notificationContent
          isRead
          isFavorite
          notificationCategory
      }
  }
}
`;

const querysearchAndFilterInternalList = gql`
query searchAndFilterNotificationInternalList($searchAndFilterMessageListInputData:SearchAndFilterMessageListInputData!,$limit: Int! , $offset: Int!){
  searchAndFilterNotificationInternalList(input: {
    searchAndFilterMessageListInputData: $searchAndFilterMessageListInputData
    limit: $limit
    offset: $offset
  }) {
      totalItem
      notificationData {
          notificationId
          notificationSubject
          notificationTime
          notificationContent
          isRead
          isFavorite
          notificationCategory
      }
  }
}
`;

const queryreadMessage = gql`
mutation readMessage($messageIds:[ID!]){
  readMessage(input: {
      messageIds: $messageIds
  }) 
}
`;

const queryunreadMessage = gql`
mutation unreadMessage($messageIds:[ID!]){
  unreadMessage(input: {
      messageIds: $messageIds
  }) 
}
`;

const queryreadNotification = gql`
mutation readNotification($notificationIds:[ID!]){
  readNotification(input: {
    notificationIds: $notificationIds
  }) 
}
`;

const queryunreadNotification = gql`
mutation unreadNotification($notificationIds:[ID!]){
  unreadNotification(input: {
    notificationIds: $notificationIds
  }) 
}
`;

const querydeleteMessage = gql`
mutation deleteMessage($messageIds:[ID!]){
  deleteMessage(input: {
    messageIds: $messageIds
  }) 
}
`;

const querydeleteNotification = gql`
mutation deleteNotification($notificationIds:[ID!]){
  deleteNotification(input: {
    notificationIds: $notificationIds
  }) 
}
`;



const queryaskLeadVendor = gql`
mutation askLeadVendor($vendorId: ID!, $message: String!){
  askLeadVendor(input: {
      vendorId: $vendorId
      message: $message
  }) 
}
`;

const querycheckLeadsMessageExist = gql`
query checkLeadsMessageExist($id:String!){
  checkLeadsMessageExist(input: $id) {
      isExist
      leadsId
  }
}
`;

const querysendLeadMessage = gql`
mutation sendLeadsMessage($leadsId:ID!,$message:String!){
  sendLeadsMessage(input: {
      leadsId: $leadsId
      message: $message
  }) 
}
`;

const queryloadLeadsMessageList = gql`
query loadLeadsMessageList($limit: Int! , $offset: Int!){
  loadLeadsMessageList(input: {
      limit: $limit
      offset: $offset
  }) {
      totalItem
      leadsMessageListData {
          leadsMessageId
          procurementId
          procurementName
          vendorId
          vendorName
          isRead
          isStar
          lastMessageDatetime
          lastMessageContent
          companyAs
      }
  }
}
`;


const queryloadLeadsMessageDetail = gql`
query loadLeadsMessageDetail($leadsId:ID!){
  loadLeadsMessageDetail(input: {
      leadsId: $leadsId
  }) {
      messageLeadsContentId
      content
      messageFrom
      messageDate
  }
}
`;

const querystarLeadsMessage = gql`
mutation starLeadsMessage($leadsIds:[ID!]){
  starLeadsMessage(input: {
      leadsIds: $leadsIds
  }) 
}
`;

const queryunStarLeadsMessage = gql`
mutation unStarLeadsMessage($leadsIds:[ID!]){
  unStarLeadsMessage(input: {
      leadsIds: $leadsIds
  }) 
}
`;

const queryreadLeadsMessage = gql`
mutation readLeadsMessage($leadsIds:[ID!]){
  readLeadsMessage(input: {
      leadsIds: $leadsIds
  }) 
}
`;

const queryunreadLeadsMessage = gql`
mutation unreadLeadsMessage($leadsIds:[ID!]){
  unreadLeadsMessage(input: {
      leadsIds: $leadsIds
  }) 
}
`;

const querysearchAndFilterLeadMessageList = gql`
query searchAndFilterLeadMessageList($searchAndFilterMessageListInputData:SearchAndFilterMessageListInputData!,$limit: Int! , $offset: Int!){
  searchAndFilterLeadMessageList(input: {
      searchAndFilterMessageListInputData: $searchAndFilterMessageListInputData
      limit: $limit
      offset: $offset
  }) {
      totalItem
      leadsMessageListData {
          leadsMessageId
          procurementId
          procurementName
          vendorId
          vendorName
          isRead
          isStar
          lastMessageDatetime
          lastMessageContent
          companyAs
      }
  }
}
`;


const querydeleteLeads = gql`
mutation deleteLeadsMessage($leadsIds:[ID!]){
  deleteLeadsMessage(input: {
      leadsIds: $leadsIds
  }) 
}
`;

@Injectable({ providedIn: 'root' })

export class MessagingService{
    getTotalUnreadMessageThreadObs = new Subject();
    loadMessageListObs = new Subject();
    vendorCheckThreadMessageExistObs = new Subject();
    procurementCheckThreadMessageExistObs = new Subject();
    askProcurementObs = new Subject();
    askVendorObs = new Subject();
    loadMessageDetailObs = new Subject();
    sendMessageObs = new Subject();
    starMessageObs = new Subject();
    unstarMessageObs = new Subject();
    loadNotificationSystemListObs = new Subject();
    loadNotificationSystemDetailObs = new Subject();
    loadNotificationInternalListObs = new Subject();
    loadNotificationInternalDetailObs = new Subject();
    starNotificationObs = new Subject();
    unstarNotificationObs = new Subject();
    searchAndFilterMessageListObs = new Subject();
    searchAndFilterSystemListObs = new Subject();
    searchAndFilterInternalListObs = new Subject();
    readMessageObs = new Subject();
    unreadMessageObs = new Subject();
    readNotificationObs = new Subject();
    unreadNotificationObs = new Subject();
    deleteMessageObs = new Subject();
    deleteNotificationObs = new Subject();
    askLeadVendorObs = new Subject();
    checkaskLeadVendorObs = new Subject();
    sendLeadsMessageObs = new Subject();
    loadLeadsListObs = new Subject();
    loadLeadsMessageDetailObs = new Subject();
    starLeadsObs = new Subject();
    unstarLeadsObs = new Subject();
    readLeadsMessageObs = new Subject();
    unreadLeadsMessageObs = new Subject();
    searchAndFilterLeadMessageListObs = new Subject();
    deleteLeadsObs = new Subject();

    constructor(private apollo: Apollo) {}

    TotalMessage() {
        this.apollo
        .mutate({
          mutation: querygetTotalUnreadMessageThread,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getTotalUnreadMessageThreadObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getTotalUnreadMessageThreadObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getTotalMessage() {
        this.getTotalUnreadMessageThreadObs = new Subject();
        this.TotalMessage();
        return this.getTotalUnreadMessageThreadObs.asObservable();
    }

    loadMessageList(limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: queryloadMessageList,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadMessageListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadMessageListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadMessageList(limit:any, offset:any) {
        this.loadMessageListObs = new Subject();
        this.loadMessageList(limit, offset);
        return this.loadMessageListObs.asObservable();
    }

    vendorCheckThreadMessageExist(rfqId:any) {
        this.apollo
        .mutate({
          mutation: queryvendorCheckThreadMessageExist,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorCheckThreadMessageExistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorCheckThreadMessageExistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getvendorCheckThreadMessageExist(rfqId:any) {
        this.vendorCheckThreadMessageExistObs = new Subject();
        this.vendorCheckThreadMessageExist(rfqId);
        return this.vendorCheckThreadMessageExistObs.asObservable();
    }

    procurementCheckThreadMessageExist(rfqId:any,quotationId:any) {
        this.apollo
        .mutate({
          mutation: queryprocurementCheckThreadMessageExist,
          variables: {rfqId: rfqId,quotationId:quotationId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.procurementCheckThreadMessageExistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.procurementCheckThreadMessageExistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getprocurementCheckThreadMessageExist(rfqId:any,quotationId:any) {
        this.procurementCheckThreadMessageExistObs = new Subject();
        this.procurementCheckThreadMessageExist(rfqId,quotationId);
        return this.procurementCheckThreadMessageExistObs.asObservable();
    }

    askProcurement(rfqId:any,askProcurementInputData:any) {
        this.apollo
        .mutate({
          mutation: queryaskProcurement,
          variables: {rfqId: rfqId, askProcurementInputData: askProcurementInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.askProcurementObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.askProcurementObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getaskProcurement(rfqId:any,askProcurementInputData:any) {
        this.askProcurementObs = new Subject();
        this.askProcurement(rfqId,askProcurementInputData);
        return this.askProcurementObs.asObservable();
    }

    askVendor(rfqId:any,quotationId:any,askVendorInputData:any) {
        this.apollo
        .mutate({
          mutation: queryaskVendor,
          variables: {rfqId: rfqId, quotationId:quotationId, askVendorInputData: askVendorInputData},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.askVendorObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.askVendorObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getaskVendor(rfqId:any,quotationId:any,askVendorInputData:any) {
        this.askVendorObs = new Subject();
        this.askVendor(rfqId,quotationId,askVendorInputData);
        return this.askVendorObs.asObservable();
    }

    
    loadMessageDetail(messageId:any) {
        this.apollo
        .mutate({
          mutation: queryloadMessageDetail,
          variables: {messageId: messageId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadMessageDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadMessageDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadMessageDetail(messageId:any) {
        this.loadMessageDetailObs = new Subject();
        this.loadMessageDetail(messageId);
        return this.loadMessageDetailObs.asObservable();
    }

 
    sendMessage(messageId:any,message:any,messageFrom:any) {
        this.apollo
        .mutate({
          mutation: querysendMessage,
          variables: {messageId:messageId,message:message,messageFrom:messageFrom},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.sendMessageObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.sendMessageObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsendMessage(messageId:any,message:any,messageFrom:any) {
        this.sendMessageObs = new Subject();
        this.sendMessage(messageId,message,messageFrom);
        return this.sendMessageObs.asObservable();
    }

    starMessage(messageId:any) {
        this.apollo
        .mutate({
          mutation: querystarMessage,
          variables: {messageId: messageId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.starMessageObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.starMessageObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getstarMessage(messageId:any) {
        this.starMessageObs = new Subject();
        this.starMessage(messageId);
        return this.starMessageObs.asObservable();
    }

    unstarMessage(messageId:any) {
        this.apollo
        .mutate({
          mutation: queryunstarMessage,
          variables: {messageId: messageId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.unstarMessageObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.unstarMessageObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getunstarMessage(messageId:any) {
        this.unstarMessageObs = new Subject();
        this.unstarMessage(messageId);
        return this.unstarMessageObs.asObservable();
    }


    loadNotificationSystemList(limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: queryloadNotificationSystemList,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadNotificationSystemListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadNotificationSystemListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadNotificationSystemList(limit:any, offset:any) {
        this.loadNotificationSystemListObs = new Subject();
        this.loadNotificationSystemList(limit, offset);
        return this.loadNotificationSystemListObs.asObservable();
    }
    


    loadNotificationSystemDetail(notificationId:any) {
        this.apollo
        .mutate({
          mutation: queryloadNotificationSystemDetail,
          variables: {notificationId: notificationId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadNotificationSystemDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadNotificationSystemDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadNotificationSystemDetail(notificationId:any) {
        this.loadNotificationSystemDetailObs = new Subject();
        this.loadNotificationSystemDetail(notificationId);
        return this.loadNotificationSystemDetailObs.asObservable();
    }



    

    loadNotificationInternalList(limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: queryloadNotificationInternalList,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadNotificationInternalListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadNotificationInternalListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadNotificationInternalList(limit:any, offset:any) {
        this.loadNotificationInternalListObs = new Subject();
        this.loadNotificationInternalList(limit, offset);
        return this.loadNotificationInternalListObs.asObservable();
    }
    


    loadNotificationInternalDetail(notificationId:any) {
        this.apollo
        .mutate({
          mutation: queryloadNotificationInternalDetail,
          variables: {notificationId: notificationId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.loadNotificationInternalDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.loadNotificationInternalDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getloadNotificationInternalDetail(notificationId:any) {
        this.loadNotificationInternalDetailObs = new Subject();
        this.loadNotificationInternalDetail(notificationId);
        return this.loadNotificationInternalDetailObs.asObservable();
    }

    starNotification(notificationIds:any) {
        this.apollo
        .mutate({
          mutation: querystarNotification,
          variables: {notificationIds: notificationIds},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.starNotificationObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.starNotificationObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getstarNotification(notificationIds:any) {
        this.starNotificationObs = new Subject();
        this.starNotification(notificationIds);
        return this.starNotificationObs.asObservable();
    }

    unstarNotification(notificationIds:any) {
        this.apollo
        .mutate({
          mutation: queryunstarNotification,
          variables: {notificationIds: notificationIds},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.unstarNotificationObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.unstarNotificationObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getunstarNotification(notificationIds:any) {
        this.unstarNotificationObs = new Subject();
        this.unstarNotification(notificationIds);
        return this.unstarNotificationObs.asObservable();
    }

    searchAndFilterMessageList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.apollo
      .mutate({
        mutation: querysearchAndFilterMessageList,
        variables: {
          searchAndFilterMessageListInputData: searchAndFilterMessageListInputData,
          limit: limit,
          offset: offset,
        },
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchAndFilterMessageListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchAndFilterMessageListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getsearchAndFilterMessageList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.searchAndFilterMessageListObs = new Subject();
      this.searchAndFilterMessageList(searchAndFilterMessageListInputData,limit,offset);
      return this.searchAndFilterMessageListObs.asObservable();
  }

  searchAndFilterSystemList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.apollo
      .mutate({
        mutation: querysearchAndFilterSystemList,
        variables: {
          searchAndFilterMessageListInputData: searchAndFilterMessageListInputData,
          limit: limit,
          offset: offset,
        },
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchAndFilterSystemListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchAndFilterSystemListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getsearchAndFilterSystemList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.searchAndFilterSystemListObs = new Subject();
      this.searchAndFilterSystemList(searchAndFilterMessageListInputData,limit,offset);
      return this.searchAndFilterSystemListObs.asObservable();
  }

  searchAndFilterInternalList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.apollo
      .mutate({
        mutation: querysearchAndFilterInternalList,
        variables: {
          searchAndFilterMessageListInputData: searchAndFilterMessageListInputData,
          limit: limit,
          offset: offset,
        },
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchAndFilterInternalListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchAndFilterInternalListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getsearchAndFilterInternalList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.searchAndFilterInternalListObs = new Subject();
      this.searchAndFilterInternalList(searchAndFilterMessageListInputData,limit,offset);
      return this.searchAndFilterInternalListObs.asObservable();
  }


  
  readMessage(messageIds:any) {
    this.apollo
    .mutate({
      mutation: queryreadMessage,
      variables: {messageIds: messageIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.readMessageObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.readMessageObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getreadMessage(messageIds:any) {
      this.readMessageObs = new Subject();
      this.readMessage(messageIds);
      return this.readMessageObs.asObservable();
  }


  unreadMessage(messageIds:any) {
    this.apollo
    .mutate({
      mutation: queryunreadMessage,
      variables: {messageIds: messageIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.unreadMessageObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.unreadMessageObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getunreadMessage(messageIds:any) {
      this.unreadMessageObs = new Subject();
      this.unreadMessage(messageIds);
      return this.unreadMessageObs.asObservable();
  }

  readNotification(notificationIds:any) {
    this.apollo
    .mutate({
      mutation: queryreadNotification,
      variables: {notificationIds: notificationIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.readNotificationObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.readNotificationObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }
  
  getreadNotification(notificationIds:any) {
      this.readNotificationObs = new Subject();
      this.readNotification(notificationIds);
      return this.readNotificationObs.asObservable();
  }

  unreadNotification(notificationIds:any) {
    this.apollo
    .mutate({
      mutation: queryunreadNotification,
      variables: {notificationIds: notificationIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.unreadNotificationObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.unreadNotificationObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getunreadNotification(notificationIds:any) {
      this.unreadNotificationObs = new Subject();
      this.unreadNotification(notificationIds);
      return this.unreadNotificationObs.asObservable();
  }
  
  deleteMessage(messageIds:any) {
    this.apollo
    .mutate({
      mutation: querydeleteMessage,
      variables: {messageIds: messageIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.deleteMessageObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.deleteMessageObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getdeleteMessage(messageIds:any) {
      this.deleteMessageObs = new Subject();
      this.deleteMessage(messageIds);
      return this.deleteMessageObs.asObservable();
  }
 
  deleteNotification(notificationIds:any) {
    this.apollo
    .mutate({
      mutation: querydeleteNotification,
      variables: {notificationIds: notificationIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.deleteNotificationObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.deleteNotificationObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getdeleteNotification(notificationIds:any) {
      this.deleteNotificationObs = new Subject();
      this.deleteNotification(notificationIds);
      return this.deleteNotificationObs.asObservable();
  }
  
  askLeadVendor(vendorId:string, message:string) {
      this.apollo
      .mutate({
        mutation: queryaskLeadVendor,
        variables: {vendorId: vendorId, message: message},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.askLeadVendorObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.askLeadVendorObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getaskLeadVendor(vendorId:string, message:string) {
      this.askLeadVendorObs = new Subject();
      this.askLeadVendor(vendorId, message);
      return this.askLeadVendorObs.asObservable();
  }



  vendorCheckLeadsMessageExist(id:any) {
    this.apollo
    .mutate({
      mutation: querycheckLeadsMessageExist,
      variables: {id},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.checkaskLeadVendorObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.checkaskLeadVendorObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getvendorCheckLeadsMessageExist(id:any) {
      this.checkaskLeadVendorObs = new Subject();
      this.vendorCheckLeadsMessageExist(id);
      return this.checkaskLeadVendorObs.asObservable();
  }

  sendLeadMessage(leadsId:any,message:any) {
    this.apollo
    .mutate({
      mutation: querysendLeadMessage,
      variables: {leadsId:leadsId,message:message},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.sendLeadsMessageObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.sendLeadsMessageObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getsendLeadMessage(leadsId:any,message:any) {
      this.sendLeadsMessageObs = new Subject();
      this.sendLeadMessage(leadsId,message);
      return this.sendLeadsMessageObs.asObservable();
  }
  
  loadLeadsList(limit:any, offset:any) {
    this.apollo
    .mutate({
      mutation: queryloadLeadsMessageList,
      variables: {limit: limit, offset: offset},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.loadLeadsListObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.loadLeadsListObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getloadLeadsList(limit:any, offset:any) {
      this.loadLeadsListObs = new Subject();
      this.loadLeadsList(limit, offset);
      return this.loadLeadsListObs.asObservable();
  }

  loadLeadsMessageDetail(id:any) {
    this.apollo
    .mutate({
      mutation: queryloadLeadsMessageDetail,
      variables: {leadsId:id},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.loadLeadsMessageDetailObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.loadLeadsMessageDetailObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getloadLeadsMessageDetail(id:any) {
      this.loadLeadsMessageDetailObs = new Subject();
      this.loadLeadsMessageDetail(id);
      return this.loadLeadsMessageDetailObs.asObservable();
  }
  

  starLeads(leadsIds: any) {
    this.apollo
      .mutate({
        mutation: querystarLeadsMessage,
        variables: { leadsIds: leadsIds },
        errorPolicy: 'all',
      })
      .subscribe(
        (res: any) => {
          this.starLeadsObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.starLeadsObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }
  
  getstarLeads(leadsIds: any) {
    this.starLeadsObs = new Subject();
    this.starLeads(leadsIds);
    return this.starLeadsObs.asObservable();
  }
  
  unstarLeads(leadsIds: any) {
    this.apollo
      .mutate({
        mutation: queryunStarLeadsMessage,
        variables: { leadsIds: leadsIds },
        errorPolicy: 'all',
      })
      .subscribe(
        (res: any) => {
          this.unstarLeadsObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.unstarLeadsObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }
  
  getunstarLeads(leadsIds: any) {
    this.unstarLeadsObs = new Subject();
    this.unstarLeads(leadsIds);
    return this.unstarLeadsObs.asObservable();
  }

  readLeadsMessage(leadsIds: any) {
    this.apollo
      .mutate({
        mutation: queryreadLeadsMessage,
        variables: { leadsIds: leadsIds },
        errorPolicy: 'all',
      })
      .subscribe(
        (res: any) => {
          this.readLeadsMessageObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.readLeadsMessageObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }
  
  getreadLeadsMessage(leadsIds: any) {
    this.readLeadsMessageObs = new Subject();
    this.readLeadsMessage(leadsIds);
    return this.readLeadsMessageObs.asObservable();
  }
  
  unreadLeadsMessage(leadsIds: any) {
    this.apollo
      .mutate({
        mutation: queryunreadLeadsMessage,
        variables: { leadsIds: leadsIds },
        errorPolicy: 'all',
      })
      .subscribe(
        (res: any) => {
          this.unreadLeadsMessageObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.unreadLeadsMessageObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }
  
  getunreadLeadsMessage(leadsIds: any) {
    this.unreadLeadsMessageObs = new Subject();
    this.unreadLeadsMessage(leadsIds);
    return this.unreadLeadsMessageObs.asObservable();
  }

  searchAndFilterLeadMessageList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.apollo
      .mutate({
        mutation: querysearchAndFilterLeadMessageList,
        variables: {
          searchAndFilterMessageListInputData: searchAndFilterMessageListInputData,
          limit: limit,
          offset: offset,
        },
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchAndFilterLeadMessageListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchAndFilterLeadMessageListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getsearchAndFilterLeadMessageList(searchAndFilterMessageListInputData:any,limit:number,offset:number) {
      this.searchAndFilterLeadMessageListObs = new Subject();
      this.searchAndFilterLeadMessageList(searchAndFilterMessageListInputData,limit,offset);
      return this.searchAndFilterLeadMessageListObs.asObservable();
  }

  deleteLeads(leadsIds:any) {
    this.apollo
    .mutate({
      mutation: querydeleteLeads,
      variables: {leadsIds: leadsIds},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.deleteLeadsObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.deleteLeadsObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getdeleteLeads(leadsIds:any) {
      this.deleteLeadsObs = new Subject();
      this.deleteLeads(leadsIds);
      return this.deleteLeadsObs.asObservable();
  }
  

}
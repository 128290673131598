import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Popupmodal } from "./popupmodal"

export default class CheckErrortype {
    static errorType: any = 
    [
      // {
      //   "name": "CanNotDeleteOwnSelf",
      //   "redirect": "/profile"
      // },
      {
        "name": "StaffDoNotHaveAccess",
        "redirect": "",
        "backToUrl": "location.back()"
      },
      {
        "name": "AccessTokenExpiredException",
        "redirect": "/logout"
      },
      {
        "name": "VerificationTokenNotFound",
        "redirect": "/logout"
      },
      {
        "name": "TokenHasBeenExpired",
        "redirect": "/logout"
      },
      {
        "name": "NoStaffIdFoundInToken",
        "redirect": "/logout"
      },
      {
        "name": "InvalidRegistrationToken",
        "redirect": "/logout"
      },
      {
        "name": "ExpiredRegistrationToken",
        "redirect": "/logout"
      },
      {
        "name": "UnblockTokenExpiredException",
        "redirect": "/logout"
      },
      {
        "name": "ExpiredResetPasswordToken",
        "redirect": "/logout"
      },      
      {
        "name": "ExpiredRefreshToken",
        "redirect": "/logout"
      },     
      {
        "name": "TokenVerificationException",
        "redirect": "/logout"
      },       
    ]

    static title:string='Error'
    static redirect:string=''
    static textresponse:string=''
    static backToUrl:string=''

    static router: Router
    static translate:TranslateService

    static getResponse(errorType:any) { 
        this.redirect =''
        this.errorType.forEach((value: any, key: any) => {
            if(value['name']==errorType){
                this.redirect = value['redirect']
            }
        })
        return this.redirect
        //this.showmodal()
    }  
    static getBackUrl(errorType:any) { 
      this.backToUrl =''
      this.errorType.forEach((value: any, key: any) => {
          if(value['name']==errorType){
              this.backToUrl = value['backToUrl']
          }
        })
        return this.backToUrl
        //this.showmodal()
    }  
    // static showmodal(){
    //     // var gettext = this.translate.get('error.CanNotDeleteOwnSelf');
    //     // console.log('gettext: ',gettext)
    //     // this.translateService.get('error.CanNotDeleteOwnSelf').subscribe((res: string) => {
    //     //     this.textresponse = res
    //     // });
    //     let translatedValueUsingGet: string='';
    //     // this.translate
    //     //   .get('error.CanNotDeleteOwnSelf')
    //     //   .subscribe((value) => (translatedValueUsingGet = value));


    //     this.textresponse = translatedValueUsingGet


        
    // }
}
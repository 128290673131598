import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

// const queryRfqDetail = gql`
// query rfqDetail($rfqId: ID!) {
//   getRfqDetail(input: {
//       rfqId: $rfqId
//   }){
//     isAlreadyParticipating
//     rfq {
//         id
//         companyLocation {
//             id
//             label
//             country
//             province
//             city
//             street
//             postalCode
//             phone
//             taxId
//             isMainAddress
//         }
//         state
//         approvalStatus
//         ongoingApprovalStatus
//         rfqDisplayId
//         description,
//         submitQuotationLastDate
//         paymentTermRequestedInDays
//         etaRequested
//         currency
//         procurementRefNumber
//         isForFullQuote
//         isIndentAccepted
//         expiredDate
//         rfqItem {
//             id
//             name
//             brand
//             description
//             qtyNeeded
//             manufacturerSku
//             itemSequence
//             itemUnit
//         }
//         rfqVendorCategory {
//             id
//             category
//         }
//         totalVendorParticipation
//         createdDate
//         createdBy
//         modifiedDate
//         modifiedBy
//     }
//   }
// }
// `;
const queryParticipatedRfq = gql`
query participatedList($first: Int, $after: String) {
  participatedList(input: {first: $first, after: $after}){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
        cursor
        node{
            id
            rfqData {
                rfqDisplayId
                procurementName
                description
                rfqVendorCategory
                submitQuotationLastDate
                rfqDeliveryCountry
                rfqDeliveryProvince
                rfqDeliveryCity
                rfqDeliveryStreet
                rfqDeliveryPostalCode
                rfqState
                rfqItem {
                    id
                    name
                    brand
                    description
                    qtyNeeded
                    manufacturerSku
                    itemSequence
                    itemUnit
                }
                totalParticipation
            }
            quotationData {
                id
                state
            }
            poId
        }
    }
  }
}
`;

const queryparticipatedHistoryList = gql`
query participatedHistoryList($first: Int, $after: String){
  participatedHistoryList(input: {first: $first, after: $after}){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              rfqData {
                  rfqDisplayId
                  procurementName
                  description
                  rfqVendorCategory
                  submitQuotationLastDate
                  rfqDeliveryCountry
                  rfqDeliveryProvince
                  rfqDeliveryCity
                  rfqDeliveryStreet
                  rfqDeliveryPostalCode
                  rfqState
                  rfqItem {
                      id
                      name
                      brand
                      description
                      qtyNeeded
                      manufacturerSku
                      itemSequence
                      itemUnit
                  }
                  totalParticipation
              }
              quotationData {
                  id
                  state
              }
              poId
          }
      }
  }
}
`;


const queryRfqGlobalDetail = gql`
query getRfqGlobalDetail($rfqId: ID!) {
  getRfqGlobalDetail(input: {
      rfqId: $rfqId
  }){
    isAlreadyParticipating
    rfq {
        id
        companyLocation {
            id
            label
            country
            province
            city
            street
            postalCode
            phone
            taxId
            isMainAddress
        }
        state
        approvalStatus
        ongoingApprovalStatus
        rfqDisplayId
        description,
        submitQuotationLastDate
        paymentTermRequestedInDays
        etaRequested
        currency
        procurementRefNumber
        isForFullQuote
        isIndentAccepted
        expiredDate
        rfqItem {
            id
            name
            brand
            description
            qtyNeeded
            manufacturerSku
            itemSequence
            itemUnit
            fileName
        }
        rfqVendorCategory {
            id
            category
        }
        totalVendorParticipation
        createdDate
        createdBy
        modifiedDate
        modifiedBy
    } 
}
}
`;

const submitMarketLeader = gql`
query isMarketLeader($quotationId: ID!,$rfqItemId: ID!,$quotationItemId: ID!){
  isMarketLeader(input: {
      quotationId: $quotationId
      quotationItemId: $quotationItemId
      rfqItemId: $rfqItemId
  }) 
}`;


// const graphqlApprovalQuotation = gql`
// mutation changeRfqApprovalStatusDraft($action: ApprovalAction!,$rfqId: ID!){
//   changeRfqApprovalStatusDraft(input: {
//       action: $action
//       rfqId: $rfqId
//   }) 
// }`;

const graphqlApprovalQuotation = gql`
mutation changeQuotationDraftApprovalStatus($action:approvalAction!,$quotationId:ID!){
  changeQuotationDraftApprovalStatus(input: {
      action: $action
      quotationId: $quotationId
  })
}
`;

const searchParticipatedList = gql`
query searchParticipatedList($participatedListSearchInputData:ParticipatedListSearchInputData!, $first: Int, $after: String){
  searchParticipatedList(input: {
      participatedListSearchInputData: $participatedListSearchInputData
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              rfqData {
                  rfqDisplayId
                  procurementName
                  description
                  rfqVendorCategory
                  submitQuotationLastDate
                  rfqDeliveryCountry
                  rfqDeliveryProvince
                  rfqDeliveryCity
                  rfqDeliveryStreet
                  rfqDeliveryPostalCode
                  rfqState
                  rfqItem {
                      id
                      name
                      brand
                      description
                      qtyNeeded
                      manufacturerSku
                      itemSequence
                      itemUnit
                  }
                  totalParticipation
              }
              quotationData {
                  id
                  state
              }
          }
      }
  }
}
`;


const participatedRfqDetail = gql`
query participatedRfqDetail($rfqParticipatedId: ID!, $quotationId: ID) {
  participatedRfqDetail(input: {
    rfqParticipatedId: $rfqParticipatedId,
    quotationId: $quotationId
}){
    rfqData{
      rfqId
      rfqDisplayId
      procurementName
      description
      rfqVendorCategory
      submitQuotationLastDate
      rfqDeliveryCountry
      rfqDeliveryProvince
      rfqDeliveryCity
      rfqDeliveryStreet
      rfqDeliveryPostalCode
      rfqState
      paymentTermRequestedInDays
      etaRequested
      currency
      isForFullQuote
      isIndentAccepted
      rfqItem {
          id
          name
          brand
          description
          qtyNeeded
          manufacturerSku
          itemSequence
          itemUnit
          fileName
      }
      totalParticipation
    }
    quotationData{
        id
        displayQuotationId
        state
        approvalStatus
        ongoingApprovalStatus
        vendorRefNumber
        quotationDeliveryCountry
        quotationDeliveryCity
        etaOffered
        paymentTermOfferedInDays
        isForFullOrder
        isShippingCostIncluded
        otherCost
        memo
        isTaxIncluded
        taxPercentage
        taxValue
        isAutoBidActive
        maxNumberOfAutoBid
        closeReason
        quotationItem {
            id
            rfqItemId
            qtyOffered
            priceOffered
            productId
            productNameOffered
            productBrandOffered
            productManufacturerCodeOffered
            productDescOffered
            marketIntelligent{
              isMarketLeader
              lastUsageDateTime
            }
            productPrice
            fileName
        }
        quotationItemAlternative {
            id
            rfqItemId
            qtyOffered
            priceOffered
            productId
            alternativeProductName
            alternativeProductBrand
            alternativeProductManufacturerCode
            alternativeProductDesc
        }
        createdDate
        createdBy
        modifiedDate
        modifiedBy
    }
    poId
  }
}
`;


const getPoDetail = gql`
query getPoDetail($poId: String!){
  getPoDetail(input: {
      poId: $poId
  }){
      poNumber
      procurementDetailAddress{
          companyName
          companyMainCountry
          companyMainProvince
          companyMainCity
          companyMainStreet
          companyMainPostalCode
          companyMainPhone
          companyMainEmail
      }
      vendorAddress
      vendorName
      vendorPhone
      vendorEmail
      quotationOfferedDeliveryAddress
      quotationEtaOffered
      quotationPaymentTermInDays
      rfqCurrency
      procurementRefNumber
      vendorRefNumber
      vendorIsTaxIncluded
      vendorTaxPercentage
      vendorTaxValue
      PoItem {
          productManufacturerCode
          productBrand
          productDescription
          selectedQty
          productPrice
          productName
      }
      approvedBy
      approvedDate
  }
}
`;

const searchAndFilterRfqGlobalList = gql`
query searchAndFilterRfqGlobalList($rfqGlobalSearchInputData:RfqGlobalSearchInputData!, $first: Int, $after: String){
  searchAndFilterRfqGlobalList(input: {
      rfqGlobalSearchInputData: $rfqGlobalSearchInputData
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              rfqId
              rfqDisplayId
              procurementName
              description
              rfqVendorCategory
              submitQuotationLastDate
              rfqDeliveryCountry
              rfqDeliveryProvince
              rfqDeliveryCity
              rfqDeliveryStreet
              rfqDeliveryPostalCode
              rfqState
              totalParticipation
              isAlreadyParticipating
              haveMoreRfqItem
              rfqParticipationId
              quotationId
              rfqItem {
                  brand
                  description
              }
          }
      }
  }
}
`;

const participateNow = gql`
mutation participateNow($rfqId: ID!) {
  participateRfq(input: {
        rfqId: $rfqId
    }) {
      isSuccess
      rfqParticipationId
  }
}
`;

const querysearchParticipatedHistoryList = gql`
query searchParticipatedHistoryList($participatedListSearchInputData:ParticipatedListSearchInputData!,$first: Int, $after: String){
  searchParticipatedHistoryList(input: {
      participatedListSearchInputData: $participatedListSearchInputData
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              rfqData {
                  rfqId
                  rfqDisplayId
                  procurementName
                  description
                  rfqVendorCategory
                  submitQuotationLastDate
                  rfqDeliveryCountry
                  rfqDeliveryProvince
                  rfqDeliveryCity
                  rfqDeliveryStreet
                  rfqDeliveryPostalCode
                  rfqState
                  rfqItem {
                      id
                      name
                      brand
                      description
                      qtyNeeded
                      manufacturerSku
                      itemSequence
                      itemUnit
                  }
                  totalParticipation
              }
              quotationData {
                  id
                  state
              }
          }
      }
  }
}
`;


@Injectable({ providedIn: 'root' })

export class RfqVendorService{
    participatedRfqObs = new Subject();
    participatedHistoryListObs = new Subject();
    rfqdetailObs = new Subject();
    participateNowObs = new Subject();
    marketLeaderObs = new Subject();
    approvalQuotationObs = new Subject();
    searchParticipatedListObs = new Subject();
    participatedRfqDetailObs = new Subject();
    getPoDetailObs = new Subject();
    searchAndFilterRfqGlobalListObs = new Subject();
    searchParticipatedHistoryListObs = new Subject();
    
    constructor(private apollo: Apollo) {}

    ParticipatedRfq(first:any, after:any) {
      this.apollo
      .mutate({
        mutation: queryParticipatedRfq,
        variables: {first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.participatedRfqObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.participatedRfqObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getParticipatedRfq(first:any, after:any) {
        this.participatedRfqObs = new Subject();
        this.ParticipatedRfq(first, after);
        return this.participatedRfqObs.asObservable();
    }

    
    participatedHistoryList(first:any, after:any) {
      this.apollo
      .mutate({
        mutation: queryparticipatedHistoryList,
        variables: {first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.participatedHistoryListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.participatedHistoryListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getparticipatedHistoryList(first:any, after:any) {
        this.participatedHistoryListObs = new Subject();
        this.participatedHistoryList(first, after);
        return this.participatedHistoryListObs.asObservable();
    }

    rfqDetail(rfqId:any) {
        this.apollo
        .mutate({
          mutation: queryRfqGlobalDetail,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.rfqdetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.rfqdetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getrfqDetail(rfqId:any) {
        this.rfqdetailObs = new Subject();
        this.rfqDetail(rfqId);
        return this.rfqdetailObs.asObservable();
    }

    participating(rfqId:any) {
        this.apollo
        .mutate({
          mutation: participateNow,
          variables: {rfqId: rfqId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.participateNowObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.participateNowObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getparticipating(rfqId:any) {
        this.participateNowObs = new Subject();
        this.participating(rfqId);
        return this.participateNowObs.asObservable();
    }
    

    submitMarketLeader(quotationId:any,quotationItemId:any,rfqItemId:any) {
        this.apollo
        .mutate({
          mutation: submitMarketLeader,
          variables: {quotationId: quotationId,quotationItemId:quotationItemId,rfqItemId:rfqItemId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.marketLeaderObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.marketLeaderObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getSubmitMarketLead(quotationId:any,quotationItemId:any,rfqItemId:any) {
      console.log('quotationId : ',quotationId);
      console.log('quotationItemId : ',quotationItemId);
      console.log('rfqItemId : ',rfqItemId);
        this.marketLeaderObs = new Subject();
        this.submitMarketLeader(quotationId,quotationItemId,rfqItemId);
        return this.marketLeaderObs.asObservable();
    }



    PostApprovalQuotation(action:any,quotationId: any) {
      this.apollo
      .mutate({
        mutation: graphqlApprovalQuotation,
        variables: {action: action, quotationId: quotationId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.approvalQuotationObs.next(res);
          console.log("succes, PostApprovalQuotation data:", res);
        },
        (error) => {
          this.approvalQuotationObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getPostApprovalQuotation(action:any,quotationId: any) {
        this.approvalQuotationObs = new Subject();
        this.PostApprovalQuotation(action, quotationId);
        return this.approvalQuotationObs.asObservable();
    }


    filterParticipatedList(ParticipatedListSearchInput:any, first:any, after:any) {
      this.apollo
      .mutate({
        mutation: searchParticipatedList,
        variables: {participatedListSearchInputData: ParticipatedListSearchInput, first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchParticipatedListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchParticipatedListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getfilterParticipatedList(ParticipatedListSearchInput:any, first:any, after:any) {
        this.searchParticipatedListObs = new Subject();
        this.filterParticipatedList(ParticipatedListSearchInput, first, after);
        return this.searchParticipatedListObs.asObservable();
    }

    participatedRfqDetail(rfqParticipatedId:string,quotationId: string) {
      this.apollo
      .mutate({
        mutation: participatedRfqDetail,
        variables: {rfqParticipatedId: rfqParticipatedId, quotationId: quotationId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.participatedRfqDetailObs.next(res);
          console.log("succes, PostApprovalQuotation data:", res);
        },
        (error) => {
          this.participatedRfqDetailObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getparticipatedRfqDetail(rfqParticipatedId:string,quotationId: string) {
        this.participatedRfqDetailObs = new Subject();
        this.participatedRfqDetail(rfqParticipatedId, quotationId);
        return this.participatedRfqDetailObs.asObservable();
    }



    poDetail(poId:any) {
        this.apollo
        .mutate({
          mutation: getPoDetail,
          variables: {poId: poId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getPoDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getPoDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getPoDetail(poId:any) {
        this.getPoDetailObs = new Subject();
        this.poDetail(poId);
        return this.getPoDetailObs.asObservable();
    }


    searchAndFilterRfqGlobalList(rfqGlobalSearchInputData:any, first:any, after:any) {
      this.apollo
      .mutate({
        mutation: searchAndFilterRfqGlobalList,
        variables: {rfqGlobalSearchInputData: rfqGlobalSearchInputData, first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchAndFilterRfqGlobalListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchAndFilterRfqGlobalListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getsearchAndFilterRfqGlobalList(rfqGlobalSearchInputData:any, first:any, after:any) {
        this.searchAndFilterRfqGlobalListObs = new Subject();
        this.searchAndFilterRfqGlobalList(rfqGlobalSearchInputData, first, after);
        return this.searchAndFilterRfqGlobalListObs.asObservable();
    }


    searchParticipatedHistoryList(ParticipatedListSearchInput:any, first:any, after:any) {
      this.apollo
      .mutate({
        mutation: querysearchParticipatedHistoryList,
        variables: {participatedListSearchInputData: ParticipatedListSearchInput, first: first, after: after},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.searchParticipatedHistoryListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.searchParticipatedHistoryListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getsearchParticipatedHistoryList(ParticipatedListSearchInput:any, first:any, after:any) {
        this.searchParticipatedHistoryListObs = new Subject();
        this.searchParticipatedHistoryList(ParticipatedListSearchInput, first, after);
        return this.searchParticipatedHistoryListObs.asObservable();
    }
}
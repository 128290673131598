import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-procurement-rating-product',
  templateUrl: './procurement-rating-product.component.html',
  styleUrls: ['./procurement-rating-product.component.scss']
})
export class ProcurementRatingProductComponent implements OnInit {
  PageLoading:boolean=false
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }
  redirect(){
    this.router.navigate(['/procurement/rating/procurement-rating-list']);
  }
}

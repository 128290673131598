import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { MessagingService } from 'src/app/_services/messaging.services';
import { NavigationService } from 'src/app/_services/navigation';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {
  @ViewChildren('messages') messages: QueryList<any> | undefined;
  @ViewChild('content') content: ElementRef | undefined;
  @ViewChild('divToScroll') divToScroll: ElementRef | undefined;
  
  todayDate = new Date();

  disabledSendMsg:boolean=true
  noMessage:boolean=false
  noMessageSystem:boolean=false
  noMessageInternal:boolean=false
  noMessageLeads:boolean=false

  loadMessaging:boolean=false
  ValPaging:any = 8
  offset:number=0
  currentPage:number = 1

  AllMessages:any=[]


  TotalAllMessages:string=''
  vendorSendMessage:any=[]
  VendorIsMessageExist:boolean=false
  VendorMessageId:any=''

  loadingRightsideMessage:boolean=false

  procurementSendMessage:any=[]
  procurementIsMessageExist:boolean=false
  procurementMessageId:any=''

  valTextMessage:string=''

  typeChat:string = 'Transactions'
  activeMessage:string=''
  getDetaiMessage:any=null
  chatAs:string=''

  ChatList:any

  Valname:string=''
  Vallastname:string=''
  Valusername:string=''
  Valpassword:string=''
  Valemail:string=''
  ValsecondaryEmail:string=''
  ValroleMembershipId:string='32'
  ValisActive:string='true'
  

  userID:any
  actionUrl:any
  labelActionBtn:string='Save'
  labelDiv:string='Add'
  addmode:boolean=true
  editmode:boolean=false
  viewmode:boolean=false
  verify:any
  completeresendemail: boolean=false;

  //system
  loadSystem:boolean=false
  ValPagingSystem:any = 8
  offsetSystem:number=0
  AllSystem:any=[]
  TotalAllSystem:any
  dataSystem:any
  currentPageSystem:number = 1

  //internal
  loadInternal:boolean=false
  ValPagingInternal:any = 8
  offsetInternal:number=0
  AllInternal:any=[]
  TotalAllInternal:any
  dataInternal:any
  currentPageInternal:number = 1
  

  //leads
  loadLeads:boolean=false
  ValPagingLeads:any = 8
  offsetLeads:number=0
  AllLeads:any=[]
  TotalAllLeads:any
  dataLeads:any
  currentPageLeads:number = 1


  //system & internal handle
  getDetaiNotification:any=null

  //submitted = false;
  loading = false;
  UserFormInvalid:boolean=false;

  errornotif='none'
  successnotif='none'
  successnotifaddress='none'
  errortext=''

  
  goToPrevpage:boolean=false
  PageLoading:boolean=false

  UserForm: any;
  SearchForm: any;

  valSearchThread:string=''

  loadPaging:boolean=false

  displayFilter:string='none'
  valSearch:string=''
  Valstar:string=''
  Valread:string=''
  ValminDateStart: any = null
  ValminDateTo: any = null
  errorDateRange:boolean=false
  ValminDateStartPrev:any
  ValminDateToPrev:any

  dropdownListTag:any=[]
  dropdownSettingsTag:any
  selectedCat:any=[]

  FilterLoading:boolean=false

  rfqFilterForm = this.formBuilder.group({
    inmodulesearch: [''], // inmodulesearch: ['', Validators.required],
    etaRequestedStart: [''],
    etaRequestedTo: [''],
    location: [''],
    status: [''],
  })
  submitted:boolean=false

  onSearchFilterMessage:boolean=false
  invlaidSearchThread:boolean=false

  AllMessagesFilter:any=[]
  noMessageFilter:boolean=true
  TotalAllMessagesFilter:string=''
  ValPagingFilter:any = 10
  offsetFilter:number=0
  currentPageFilter:number = 1

  OnBlulk:boolean=false
  bulkType:string=''
  SelectedBulk:any=[]
  onFindText: boolean =false;
  searchText:string=''

  myAwesomeText:string='zxczxczx zxczxczxczxc zxczxcxzc'
  filter:string=''

  innerWidth:any
  public getScreenWidth: any;
  isMobile:boolean=false
  isMobileMessage:boolean=false
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messagingService: MessagingService,
    private popupmodal : Popupmodal,
    public datepipe: DatePipe,
    private navigation: NavigationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {


    
    this.LoadAllMessages()
    this.LoadAllSystem()
    this.LoadAllInternal()
    this.LoadAllLeads()
    this.UserForm = this.formBuilder.group({
      roleMembershipId: ['', [Validators.required]],
      // isActive: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
    });

    this.SearchForm = this.formBuilder.group({
      search_thread: [''],
    });

    
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
    
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  changeTab(tab:string){
    this.typeChat=tab

    //reset
    this.activeMessage = ''
    this.getDetaiNotification = null
    this.disabledSendMsg=true
    this.dataSystem=null
    this.dataInternal=null
    this.ChatList=null
    this.getDetaiMessage = null
    this.chatAs=''

    if(this.typeChat=='Internal'){
      var getTotal = this.AllInternal?.length
      this.noMessageInternal = getTotal>0?false:true
    }else if(this.typeChat=='System'){
      var getTotal = this.AllSystem?.length
      this.noMessageSystem = getTotal>0?false:true
    }else if(this.typeChat=='Leads'){
      var getTotal = this.AllLeads?.length
      this.noMessageLeads = getTotal>0?false:true
    }else {
      var getTotal = this.AllMessages?.length
      this.noMessage = getTotal>0?false:true
    }
    
  }

  LoadAllMessages(){
    this.loadPaging=true
    this.loadMessaging=true

    this.messagingService.getloadMessageList(this.ValPaging,this.offset).subscribe((res:any) => {
      console.log(res)
      this.loadMessaging=false
      if(res.data?.loadMessageList){
          
        // this.AllMessages = res.data?.loadMessageList.messageListData
        var allmsg = res.data?.loadMessageList.messageListData
        this.loadPaging=false
        allmsg.forEach((value: any, key: any) => {
          this.AllMessages.push(value)
        });

        this.TotalAllMessages = res.data?.loadMessageList.totalItem
        if(res.data?.loadMessageList.totalItem>0){
          this.noMessage = false
        }else {
          this.noMessage = true
        }
  
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  LoadAllLeads(){
    this.loadPaging=true
    this.loadLeads=true

    this.messagingService.getloadLeadsList(this.ValPaging,this.offset).subscribe((res:any) => {
      console.log(res)
      this.loadLeads=false
      if(res.data?.loadLeadsMessageList){
        var allmsg = res.data?.loadLeadsMessageList.leadsMessageListData
        this.loadPaging=false
        allmsg.forEach((value: any, key: any) => {
          this.AllLeads.push(value)
        });

        this.TotalAllLeads = res.data?.loadLeadsMessageList.totalItem
        if(res.data?.loadLeadsMessageList.totalItem>0){
          this.noMessageLeads = false
        }else {
          this.noMessageLeads = true
        }
  
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }
  
  beforeSendLeads(){
    this.sendLeadsbyId(this.activeMessage)
  }
  sendLeadsbyId(msgid:string){
    // var chatfrom = this.chatAs.toUpperCase()
    // console.log('chatfrom : ',chatfrom) 
    this.messagingService.getsendLeadMessage(msgid,this.valTextMessage).subscribe((res:any) => {
      console.log(res)
      if(res.data?.sendLeadsMessage){
        this.valTextMessage = ''
        this.GetDetailLeads(msgid)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }
  

  beforeSendMessage(){
    if(this.activeMessage !==''){
      this.sendMessagebyId(this.activeMessage)
    } else {
      this.SendMessage()
    }
  }


  SendMessage(){
    alert('send without msgid...'); return;
    // if(!this.vendorSendMessage?.rfqId){
    //   alert('rfqId is empty'); return
    // }
    
    // var askProcurementInputData = {
    //   message:this.valTextMessage
    // }
    // this.messagingService.getaskProcurement(this.vendorSendMessage?.rfqId,askProcurementInputData).subscribe((res:any) => {
    //   console.log(res)
    //   if(res.data?.askProcurement){
    //     this.valTextMessage = ''
    //     this.LoadAllMessages()
        
    //   }
    //   else if(res.errors){
    //     var error = res.errors[0].message
    //     console.log('error',error);
    //     var errMsg = error + '';
    //     var result = errMsg.split(":");
    //     var resMsg = result[result.length - 1];
    //     var msg = (resMsg)? resMsg: errMsg
    //     console.log('msg',msg);

    //     var errortext = msg

    //     console.log('res',res.errors[0].extensions.type);
    //     if(res.errors[0].extensions.type){
    //       if(res.errors[0].extensions.type == 'StaffDoNotHaveAccess'){
    //         errortext = "You don't have access to this process"
    //       } 
    //     }
    //     this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
    //   }
    //   else {
    //     this.popupmodal.showFail().subscribe((res:any) => {})
    //   }
    // });

  }

  limitText(text:any){
    if(text.length>53){
      text = text.substring(0,50) + '...'
    }
    return text
  }
  limitTitle(text:any){
    if(text.length>30){
      text = text.substring(0,27) + '...'
    }
    return text
  }
  

  activeChat(msgId:string){
    var res = false
    if(this.activeMessage==msgId){
        return true
    }
    return res
  }

  clickChat(data:any){
    this.closeFindText()
    console.log('clickChat : ',data);
    this.activeMessage = data.messageId
    this.getDetaiMessage = data
    this.disabledSendMsg=false

    this.chatAs=data.companyAs
    this.GetDetailChat(data.messageId)

  }

  clickMenuOnMobile(){
    this.innerWidth = window.innerWidth;
    if(this.innerWidth < 575){
      this.isMobileMessage = true
    }
  }
  clickCloseOnMobile(){
    this.innerWidth = window.innerWidth;
    if(this.innerWidth < 575){
      this.isMobileMessage = false
    }
  }

  GetDetailChat(messageId:any){
    this.ChatList=null
    this.loadingRightsideMessage=true
    this.disabledSendMsg=true
    this.messagingService.getloadMessageDetail(messageId).subscribe((res:any) => {
      console.log(res)
      this.loadingRightsideMessage=false
      this.disabledSendMsg=false
      if(res.data?.loadMessageDetail){
          this.ChatList = res.data?.loadMessageDetail
          this.scrollToBottom();
          this.messages?.changes.subscribe(this.scrollToBottom);

          this.isMessageRead(messageId)

          //filter read onclick
          this.isFilterRead(messageId)
          
          
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  formatDisplayId(displayId: any) {
    if(displayId){
      var re = new RegExp('.{1,4}', 'g');
      return displayId.match(re).join("-");
    } else {
      return displayId
    }

  }

  displayName(data:any){
    if(data?.companyAs=='Vendor'){
      return data?.procurementName
    } else {
      return data?.vendorName
    }
  }

  isSender(data:any,companyAs:string){
    if(companyAs=='Vendor'){
      if(data.messageFrom=='Vendor'){
        return true
      } else {
        return false
      }
    } else {
      if(data.messageFrom=='Vendor'){
        return false
      } else {
        return true
      }
    }
  }

  senderName(data:any,chatFrom:string){
    if(chatFrom=='Vendor'){
      return data.vendorName
    } else {
      return data.procurementName
    }
  }
  

  sendMessagebyId(msgid:string){
    var chatfrom = this.chatAs.toUpperCase()
    this.messagingService.getsendMessage(msgid,this.valTextMessage,chatfrom).subscribe((res:any) => {
      console.log(res)
      if(res.data?.sendMessage){
          
        this.valTextMessage = ''
        this.GetDetailChat(msgid)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  scrollToBottom = () => {
    if(this.content){
      
      try {
        this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
        if(this.divToScroll){
          this.divToScroll.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
        }
      } catch (err) {
        console.log('err try')
      }
    }

  }
  ngAfterViewInit() {
    this.scrollToBottom();
    this.messages?.changes.subscribe(this.scrollToBottom);
  }

  isMessageRead(msgId:string){
    //ux change class to read, without hit api

    this.AllMessages.forEach((value: any, key: any) => {
      if(value.messageId==msgId){
        if(value.isRead==false){
          this.AllMessages[key].isRead=true
        } 
      }
    });
  }

  setStarUnstar(msgId:string,isStar:boolean){
    // if(isStar){
    //   this.unstarMessage(msgId)
    // } else {
    //   this.starMessage(msgId)
    // }

  
    if(this.typeChat=='Internal' || this.typeChat=='System'){
      if(isStar){
        this.unstarNotification(msgId)
      } else {
        this.starNotification(msgId)
      }  
    } 
    else if(this.typeChat=='Leads'){
      var arrLeadsId = []
      arrLeadsId.push(msgId)
      if(isStar){
        this.unstarLeads(arrLeadsId)
      } else {
        this.starLeads(arrLeadsId)
      }  
    } else {
      if(isStar){
        this.unstarMessage(msgId)
      } else {
        this.starMessage(msgId)
      }  
    }


  }

  starMessage(msgId:string){
    this.messagingService.getstarMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.starMessage){
        this.isMessageStar(msgId)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  unstarMessage(msgId:string){
    this.messagingService.getunstarMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unstarMessage){
        this.isMessageStar(msgId)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  isMessageStar(msgId:string){
    // set star unstar after api, without reload api 
    this.AllMessages.forEach((value: any, key: any) => {
      if(value.messageId==msgId){
        if(value.isStar==false){
          this.AllMessages[key].isStar=true
        }else {
          this.AllMessages[key].isStar=false
        }
      }
    });
  }


    //this.popupmodal.showSuccess('mie sukses',null).subscribe((res:any) => {})
    //this.popupmodal.showError('mie gagal','/').subscribe((res:any) => {})

    

  LoadAllSystem(){
      this.loadPaging=true
      this.loadSystem=true
  
      this.messagingService.getloadNotificationSystemList(this.ValPagingSystem,this.offsetSystem).subscribe((res:any) => {
        console.log(res)
        this.loadSystem=false
        if(res.data?.loadNotificationSystemList){ 
          //this.AllSystem = res.data?.loadNotificationSystemList.notificationData
          var allsystm = res.data?.loadNotificationSystemList.notificationData
          this.loadPaging=false
          allsystm.forEach((value: any, key: any) => {
            this.AllSystem.push(value)
          });

          this.TotalAllSystem = res.data?.loadNotificationSystemList.totalItem
          console.log('TotalAllSystem : ',this.TotalAllSystem)
          if(res.data?.loadNotificationSystemList.totalItem>0){
            this.noMessageSystem = false
          }else {
            this.noMessageSystem = true
          }
        }
        else if(res.errors){
     
          var error = res.errors[0].message
          //this.loading=false
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          //alert(msg)
  
          var errortext = msg
  
          console.log('res',res.errors[0].extensions.type);
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
      });
  
  }
  openSystem(msgId:any){
    this.loadSystem=true
    this.messagingService.getloadNotificationSystemDetail(msgId).subscribe((res:any) => {
      console.log(res)
      this.loadSystem=false
      if(res.data?.loadNotificationSystemDetail){ 
        this.isSystemRead(msgId)
        this.dataSystem = res.data?.loadNotificationSystemDetail

        // var convertTime:any = this.datepipe.transform(this.dataSystem.notificationTime, 'd-MMM-YY HH:mm:ss');

        // this.popupmodal.showNotification(this.dataSystem.notificationSubject,this.dataSystem.notificationContent,convertTime).subscribe((res:any) => {})
        
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }




  LoadAllInternal(){
      this.loadInternal=true
      this.loadPaging=true
      this.messagingService.getloadNotificationInternalList(this.ValPagingInternal,this.offsetInternal).subscribe((res:any) => {
        console.log(res)
        this.loadInternal=false
        if(res.data?.loadNotificationInternalList){ 
          // this.AllInternal = res.data?.loadNotificationInternalList.notificationData

          var allint = res.data?.loadNotificationInternalList.notificationData
          this.loadPaging=false
          allint.forEach((value: any, key: any) => {
            this.AllInternal.push(value)
          });

          this.TotalAllInternal = res.data?.loadNotificationInternalList.totalItem
          if(res.data?.loadNotificationInternalList.totalItem>0){
            this.noMessageInternal = false
          }else {
            this.noMessageInternal = true
          }
        }
        else if(res.errors){
     
          var error = res.errors[0].message
          //this.loading=false
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          //alert(msg)
  
          var errortext = msg
  
          console.log('res',res.errors[0].extensions.type);
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
      });
  
  }
  openInternal(msgId:any){
    this.loadInternal=true
    this.messagingService.getloadNotificationInternalDetail(msgId).subscribe((res:any) => {
      console.log(res)
      this.loadInternal=false
      if(res.data?.loadNotificationInternalDetail){ 
        this.isInternalRead(msgId)
        this.dataInternal = res.data?.loadNotificationInternalDetail

        // var convertTime:any = this.datepipe.transform(this.dataInternal.notificationTime, 'd-MMM-YY HH:mm:ss');

        // this.popupmodal.showNotification(this.dataInternal.notificationSubject,this.dataInternal.notificationContent,convertTime).subscribe((res:any) => {})
        
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  // openLeads(msgId:any){
  //   console.log('openLeads openLeads : ',msgId)
  //   this.loadInternal=true
  //   this.messagingService.getloadLeadsMessageDetail(msgId).subscribe((res:any) => {
  //     console.log(res)
  //     this.loadInternal=false
  //     if(res.data?.loadLeadsMessageDetail){ 
  //       this.isLeadsRead(msgId)
  //       this.dataLeads = res.data?.loadLeadsMessageDetail
  //     }
  //     else if(res.errors){
   
  //       var error = res.errors[0].message
  //       //this.loading=false
  //       console.log('error',error);
  //       var errMsg = error + '';
  //       var result = errMsg.split(":");
  //       var resMsg = result[result.length - 1];
  //       var msg = (resMsg)? resMsg: errMsg
  //       console.log('msg',msg);
  //       //alert(msg)

  //       var errortext = msg

  //       console.log('res',res.errors[0].extensions.type);
  //       if(res.errors[0].extensions.type){
  //         if(res.errors[0].extensions.type == 'StaffDoNotHaveAccess'){
  //           errortext = "You don't have access to this process"
  //         } 
  //       }
  //       this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
  //     }
  //     else {
  //       this.popupmodal.showFail().subscribe((res:any) => {})
  //     }
  //   });
  // }

  clickNotification(data:any){
    console.log('clickNotification : ',data);

    this.activeMessage = data.notificationId
    this.getDetaiNotification = data
    this.disabledSendMsg=true
    this.GetDetailNotification(data.notificationId)
  }

  clickLeads(data:any){
    console.log('clickChat : ',data);
    this.activeMessage = data.leadsMessageId

    // //set manual
    // data['companyAs'] = "Vendor"


    this.getDetaiMessage = data
    this.disabledSendMsg=false

    this.chatAs=data.companyAs
    this.GetDetailLeads(data.leadsMessageId)
  }

  GetDetailNotification(messageId:string){
    if(this.typeChat=='System'){
      this.openSystem(messageId)
    }    
    else if(this.typeChat=='Internal'){
      this.openInternal(messageId)
    }  else{
      this.popupmodal.showError('Please select chat category',null).subscribe((res:any) => {})
    } 

    //filter read onclick
    this.isFilterRead(messageId)    
  }

  GetDetailLeads(messageId:string){
    this.ChatList=null
    this.loadingRightsideMessage=true
    this.disabledSendMsg=true
    this.messagingService.getloadLeadsMessageDetail(messageId).subscribe((res:any) => {
      console.log(res)
      this.loadingRightsideMessage=false
      this.disabledSendMsg=false
      if(res.data?.loadLeadsMessageDetail){
          this.ChatList = res.data?.loadLeadsMessageDetail
          this.scrollToBottom();
          this.messages?.changes.subscribe(this.scrollToBottom);

          this.isLeadsRead(messageId)

          //filter read onclick
          //this.isLeadsRead(messageId)
          
          
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    }); 
  }


  isSystemRead(msgId:string){
    //ux change class to read, without hit api
    this.AllSystem.forEach((value: any, key: any) => {
      if(value.notificationId==msgId){
        if(value.isRead==false){
          this.AllSystem[key].isRead=true
        } 
      }
    });
  }

  
  isInternalRead(msgId:string){
    //ux change class to read, without hit api
    this.AllInternal.forEach((value: any, key: any) => {
      if(value.notificationId==msgId){
        if(value.isRead==false){
          this.AllInternal[key].isRead=true
        } 
      }
    });
  }

  isLeadsRead(msgId:string){
    //ux change class to read, without hit api
    this.AllLeads.forEach((value: any, key: any) => {
      console.log('messageLeadsContentId :: ',value)
      if(value.leadsMessageId==msgId){
        if(value.isRead==false){
          this.AllLeads[key].isRead=true
        } 
      }
    });
  }
  
  starLeads(msgId:any){
    this.messagingService.getstarLeads(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.starLeadsMessage){
        this.isLeadsStar(msgId)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  unstarLeads(msgId:any){
    this.messagingService.getunstarLeads(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unStarLeadsMessage){
        this.isLeadsStar(msgId)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  isLeadsStar(msgId:string){
    // set star unstar after api, without reload api 
    this.AllLeads.forEach((value: any, key: any) => {
      if(value.leadsMessageId==msgId){
        if(value.isStar==false){
          this.AllLeads[key].isStar=true
        }else {
          this.AllLeads[key].isStar=false
        }
      }
    });
  }


  isInternalStar(msgId:string){
    // set star unstar after api, without reload api 
    this.AllInternal.forEach((value: any, key: any) => {
      if(value.notificationId==msgId){
        if(value.isFavorite==false){
          this.AllInternal[key].isFavorite=true
        }else {
          this.AllInternal[key].isFavorite=false
        }
      }
    });
  }

  isSystemStar(msgId:string){
    // set star unstar after api, without reload api 
    this.AllSystem.forEach((value: any, key: any) => {
      if(value.notificationId==msgId){
        if(value.isFavorite==false){
          this.AllSystem[key].isFavorite=true
        }else {
          this.AllSystem[key].isFavorite=false
        }
      }
    });
  }

  isFilterRead(msgId:string){
    //ux change class to read, without hit api
    this.AllMessagesFilter.forEach((value: any, key: any) => {
      if(value.notificationId==msgId){
        if(value.isRead==false){
          this.AllMessagesFilter[key].isRead=true
        } 
      }
      if(value.notificationId==msgId){
        if(value.isRead==false){
          this.AllMessagesFilter[key].isRead=true
        } 
      }
    });
  }


  starNotification(msgId:string){
    //alert('starNotification : '+msgId)
    var arrNotifId = []
    arrNotifId.push(msgId)
    this.postStarNotification(arrNotifId,'')

    if(this.typeChat=='System'){
      this.isSystemStar(msgId)
    }    
    else if(this.typeChat=='Internal'){
      this.isInternalStar(msgId)
    } 
  }
  unstarNotification(msgId:string){
    //alert('unstarNotification : '+msgId)
    var arrNotifId = []
    arrNotifId.push(msgId)
    this.postUnstarNotification(arrNotifId,'')

    if(this.typeChat=='System'){
      this.isSystemStar(msgId)
    }    
    else if(this.typeChat=='Internal'){
      this.isInternalStar(msgId)
    } 
  }

  postStarLeads(msgId:any,message:string){
    this.messagingService.getstarLeads(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.starLeadsMessage){
        // this.isMessageStar(msgId)
        if(message!==''){
          this.popupmodal.showSuccess(message,null).subscribe((res:any) => {})
          this.cancelBulk()
        }
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  postUnstarLeads(msgId:any,message:string){
    this.messagingService.getunstarLeads(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unStarLeadsMessage){
        // this.isMessageStar(msgId)
        if(message!==''){
          this.popupmodal.showSuccess(message,null).subscribe((res:any) => {})
          this.cancelBulk()
        }
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  postStarNotification(msgId:any,message:string){
    this.messagingService.getstarNotification(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.starNotification){
        // this.isMessageStar(msgId)
        if(message!==''){
          this.popupmodal.showSuccess(message,null).subscribe((res:any) => {})
          this.cancelBulk()
        }
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  postUnstarNotification(msgId:any,message:string){
    this.messagingService.getunstarNotification(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unStarNotification){
        // this.isMessageStar(msgId)
        if(message!==''){
          this.popupmodal.showSuccess(message,null).subscribe((res:any) => {})
          this.cancelBulk()
        }
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    console.log("event.target.offsetHeight : ",event.target.offsetHeight);
    console.log("event.target.scrollTop : ",event.target.scrollTop);
    console.log("event.target.scrollHeight : ",event.target.scrollHeight);


    // - 50px from bottom
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 50) { 
      console.log("End");


        console.log('typeChat : ',this.typeChat)

        if(this.typeChat=='Internal'){

          if(this.TotalAllInternal !== this.AllInternal.length){
            this.offsetInternal = this.currentPageInternal * this.ValPagingInternal
            this.currentPageInternal = this.currentPageInternal + 1
      
            console.log("currentPageInternal=",this.currentPageInternal);
            console.log("offsetInternal=",this.offsetInternal);
      
            this.LoadAllInternal()
          } else {
            console.log('all loaded')
            // alert('all loaded')
          }


        }else if(this.typeChat=='System'){

          if(this.TotalAllSystem !== this.AllSystem.length){
            this.offsetSystem = this.currentPageSystem * this.ValPagingSystem
            this.currentPageSystem = this.currentPageSystem + 1
      
            console.log("currentPageSystem=",this.currentPageSystem);
            console.log("offsetSystem=",this.offsetSystem);
      
            this.LoadAllSystem()
          } else {
            console.log('all loaded')
            // alert('all loaded')
          }


        } else {
          
          if(this.onSearchFilterMessage){ //on filter
            if(this.TotalAllMessagesFilter !== this.AllMessagesFilter.length){
              this.offsetFilter = this.currentPageFilter * this.ValPagingFilter
              this.currentPageFilter = this.currentPageFilter + 1
        
              console.log("currentPageFilter=",this.currentPageFilter);
              console.log("offsetFilter=",this.offsetFilter);
        
              var searchAndFilterMessageListInputData = this.prepareFilterMessage()

              if(this.typeChat=='Internal'){
                this.submitfilterInternal(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
              }else if(this.typeChat=='System'){
                this.submitfilterSystem(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
              } 
              else if(this.typeChat=='Leads'){
                this.submitfilterLeads(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
              }  
              else {
                this.submitfilterMessage(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
              }


            } else {
              console.log('all loaded')
              // alert('all loaded')
            }
          } else {
            if(this.TotalAllMessages !== this.AllMessages.length){
              this.offset = this.currentPage * this.ValPaging
              this.currentPage = this.currentPage + 1
        
              console.log("currentPage=",this.currentPage);
              console.log("offset=",this.offset);
        
              this.LoadAllMessages()
            } else {
              console.log('all loaded')
              // alert('all loaded')
            }
          }
          
        }


    }
  }

  openModalFilter(){
    if(this.displayFilter=="none"){
      this.displayFilter = "block";
    } else {
      this.displayFilter = "none";
    }
  }

  filterMessage(){
    this.submitted = true
    
    console.log('Valstar ',this.Valstar);
    console.log('Valread ',this.Valread);
    console.log('ValminDateStart ',this.ValminDateStart);
    console.log('ValminDateTo ',this.ValminDateTo);
    console.log('errorDateRange ',this.errorDateRange);
    console.log('valSearch ',this.valSearch);

    //check date range filter
    var errDate = 0
    if(this.ValminDateStart){
      if(!this.ValminDateTo){
        errDate++
      }
    }
    if(this.ValminDateTo){
      if(!this.ValminDateStart){
        errDate++
      }
    }

    if(errDate>0){
      this.errorDateRange =true
      return 
    } else {
      this.errorDateRange =false
    }

    if (this.rfqFilterForm.invalid) {
      return;
    }

    var searchAndFilterMessageListInputData = this.prepareFilterMessage()

    //reset filter
    this.TotalAllMessagesFilter=''
    this.offsetFilter=0
    this.currentPageFilter=1
    this.AllMessagesFilter=[]

    
    if(this.typeChat=='Internal'){
      this.submitfilterInternal(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    }else if(this.typeChat=='System'){
      this.submitfilterSystem(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    } 
    else if(this.typeChat=='Leads'){
      this.submitfilterLeads(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    } 
    else {
      this.submitfilterMessage(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    }


  }
  get f() { return this.rfqFilterForm?.controls; }

  prepareFilterMessage(){
    var searchAndFilterMessageListInputData = {}
    Object.assign(searchAndFilterMessageListInputData, { keyword: this.valSearch });
    if (this.Valread!=='') {
      var param = false
      if(this.Valread=='read'){
        param = true
      }
      Object.assign(searchAndFilterMessageListInputData, { readStatus: param });
    }
    if (this.Valstar!=='') {
      var param = false
      if(this.Valstar=='star'){
        param = true
      }
      Object.assign(searchAndFilterMessageListInputData, { isFavorite: param });
    }
    if ((this.ValminDateStart)&&(this.ValminDateTo)) {


      var convertDateStart = null
      if(this.ValminDateStart){

        var lastsubmitYear = this.ValminDateStart?.year
        var lastsubmitMonth = this.ValminDateStart?.month -1 
        var lastsubmitDay = this.ValminDateStart?.day

        // var dateETA = new Date(lastsubmitYear, lastsubmitMonth, lastsubmitDay);
        var dateETA = new Date(Date.UTC(lastsubmitYear, lastsubmitMonth, lastsubmitDay));
        convertDateStart =  Math.round(dateETA.getTime() / 1000).toString()
      }

      var convertDateEnd = null
      if(this.ValminDateTo){

        var lastsubmitYear = this.ValminDateTo?.year
        var lastsubmitMonth = this.ValminDateTo?.month -1 
        var lastsubmitDay = this.ValminDateTo?.day

        // var dateETA = new Date(lastsubmitYear, lastsubmitMonth, lastsubmitDay);
        var dateETA = new Date(Date.UTC(lastsubmitYear, lastsubmitMonth, lastsubmitDay));
        convertDateEnd =  Math.round(dateETA.getTime() / 1000).toString()
      }

      Object.assign(searchAndFilterMessageListInputData, { dateRange:{startDate:convertDateStart,endDate:convertDateEnd}});


      if(this.ValminDateStart && this.ValminDateTo){
        var startYear = this.ValminDateStart?.year
        var startMonth = this.ValminDateStart?.month -1 
        var startDay = this.ValminDateStart?.day
    
        var datestart = new Date(startYear, startMonth, startDay);
  
        this.ValminDateStartPrev = datestart
  
  
        var endYear = this.ValminDateTo?.year
        var endMonth = this.ValminDateTo?.month -1 
        var endDay = this.ValminDateTo?.day
    
        var datestop = new Date(endYear, endMonth, endDay);
        this.ValminDateToPrev = datestop
    
  
      } 

    }
    return searchAndFilterMessageListInputData
  }

  submitfilterMessage(searchAndFilterMessageListInputData:any,limit:number,offset:number){
    this.displayFilter = "none";
    this.onSearchFilterMessage = true

    //reset right side chat
    this.activeMessage = ''
    this.getDetaiMessage = null
    this.disabledSendMsg=true
    this.chatAs=''
    this.ChatList=null

    this.AllMessagesFilter=[]
    this.FilterLoading=true
    this.messagingService.getsearchAndFilterMessageList(searchAndFilterMessageListInputData,limit,offset).subscribe((res:any) => {
      this.FilterLoading=false
      console.log(res)
      if(res.data?.searchAndFilterMessageList){
        // this.isMessageStar(msgId)
        this.AllMessagesFilter = res.data?.searchAndFilterMessageList.messageListData
        this.TotalAllMessagesFilter = res.data?.searchAndFilterMessageList.totalItem
        if(res.data?.searchAndFilterMessageList.totalItem>0){
          this.noMessageFilter = false
        }else {
          this.noMessageFilter = true
        }

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  submitfilterSystem(searchAndFilterMessageListInputData:any,limit:number,offset:number){
    this.displayFilter = "none";
    this.onSearchFilterMessage = true

    //reset right side chat
    this.activeMessage = ''
    this.getDetaiMessage = null
    this.disabledSendMsg=true
    this.chatAs=''
    this.ChatList=null

    this.AllMessagesFilter=[]
    this.FilterLoading=true
    this.messagingService.getsearchAndFilterSystemList(searchAndFilterMessageListInputData,limit,offset).subscribe((res:any) => {
      this.FilterLoading=false
      console.log(res)
      if(res.data?.searchAndFilterNotificationSystemList){
        // this.isMessageStar(msgId)
        this.AllMessagesFilter = res.data?.searchAndFilterNotificationSystemList.notificationData
        this.TotalAllMessagesFilter = res.data?.searchAndFilterNotificationSystemList.totalItem
        if(res.data?.searchAndFilterNotificationSystemList.totalItem>0){
          this.noMessageFilter = false
        }else {
          this.noMessageFilter = true
        }

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  submitfilterInternal(searchAndFilterMessageListInputData:any,limit:number,offset:number){
    this.displayFilter = "none";
    this.onSearchFilterMessage = true

    //reset right side chat
    this.activeMessage = ''
    this.getDetaiMessage = null
    this.disabledSendMsg=true
    this.chatAs=''
    this.ChatList=null

    this.AllMessagesFilter=[]
    this.FilterLoading=true
    this.messagingService.getsearchAndFilterInternalList(searchAndFilterMessageListInputData,limit,offset).subscribe((res:any) => {
      this.FilterLoading=false
      console.log(res)
      if(res.data?.searchAndFilterNotificationInternalList){
        // this.isMessageStar(msgId)
        this.AllMessagesFilter = res.data?.searchAndFilterNotificationInternalList.notificationData
        this.TotalAllMessagesFilter = res.data?.searchAndFilterNotificationInternalList.totalItem
        if(res.data?.searchAndFilterNotificationInternalList.totalItem>0){
          this.noMessageFilter = false
        }else {
          this.noMessageFilter = true
        }

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  submitfilterLeads(searchAndFilterMessageListInputData:any,limit:number,offset:number){
    this.displayFilter = "none";
    this.onSearchFilterMessage = true

    //reset right side chat
    this.activeMessage = ''
    this.getDetaiMessage = null
    this.disabledSendMsg=true
    this.chatAs=''
    this.ChatList=null

    this.AllMessagesFilter=[]
    this.FilterLoading=true
    this.messagingService.getsearchAndFilterLeadMessageList(searchAndFilterMessageListInputData,limit,offset).subscribe((res:any) => {
      this.FilterLoading=false
      console.log(res)
      if(res.data?.searchAndFilterLeadMessageList){
        // this.isMessageStar(msgId)
        this.AllMessagesFilter = res.data?.searchAndFilterLeadMessageList.leadsMessageListData
        this.TotalAllMessagesFilter = res.data?.searchAndFilterLeadMessageList.totalItem
        if(res.data?.searchAndFilterLeadMessageList.totalItem>0){
          this.noMessageFilter = false
        }else {
          this.noMessageFilter = true
        }

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  clearFilter(){
    this.Valstar =''
    this.Valread =''
    this.ValminDateStart=null
    this.ValminDateTo=null
    this.errorDateRange =false
    this.valSearch=''

    this.submitted=false
    this.onSearchFilterMessage = false
  }

  onDeselectAll(event:any){
    alert('onDeselectAll : '+event)
  }
  onSelectAll(event:any){
    alert('onSelectAll : '+event)
  }
  onItemDeSelect(event:any){
    alert('onItemDeSelect : '+event)
  }
  onItemSelect(event:any){
    alert('onItemSelect : '+event)
  }
  selectedItemsTag(event:any){
    alert('selectedItemsTag : '+event)
  }
  modalCategory(){
    alert('modalCategory')
  }
  removeCat(event:any){
    alert('removeCat : '+event)
  }
  getCategoryName(event:any){
    alert('getCategoryName : '+event)
  }
  
  

  clearDate(val:any){
    this.errorDateRange =false
    if(val=='start'){
      this.ValminDateStart=null
    }
    if(val=='end'){
      this.ValminDateTo=null
    }
  }

  removeFilter(val:string){
    
    //reset right side chat
    this.activeMessage = ''
    this.getDetaiMessage = null
    this.disabledSendMsg=true
    this.chatAs=''
    this.ChatList=null


    if(val=='keyword'){
      this.valSearch=''
      this.valSearchThread=''
    }
    if(val=='star'){
      this.Valstar=''
    }
    if(val=='read'){
      this.Valread=''
    }
    if(val=='date'){
      this.ValminDateStart=null
      this.ValminDateTo=null
    }

    if(this.valSearch=='' && this.Valread=='' && this.Valstar=='' && this.ValminDateStart==null && this.ValminDateTo==null){
      this.onSearchFilterMessage=false
    } else {
      var searchAndFilterMessageListInputData = this.prepareFilterMessage()

      //reset filter
      this.TotalAllMessagesFilter=''
      this.offsetFilter=0
      this.currentPageFilter=1
      this.AllMessagesFilter=[]

      if(this.typeChat=='Internal'){
        this.submitfilterInternal(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
      }else if(this.typeChat=='System'){
        this.submitfilterSystem(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
      } 
      else if(this.typeChat=='Leads'){
        this.submitfilterLeads(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
      } 
      else {
        this.submitfilterMessage(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
      }
    }
  }

  searchbykeyword(){

    // if(this.valSearchThread.length<3){
    //   this.invlaidSearchThread = true
    //   return
    // } else {
    //   this.invlaidSearchThread = false
    // }
    var searchAndFilterMessageListInputData = {
      keyword: this.valSearchThread,
    }
    this.valSearch = this.valSearchThread

    
    //reset filter
    this.TotalAllMessagesFilter=''
    this.offsetFilter=0
    this.currentPageFilter=1
    this.AllMessagesFilter=[]


    if(this.typeChat=='Internal'){
      this.submitfilterInternal(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    }else if(this.typeChat=='System'){
      this.submitfilterSystem(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    } 
    else if(this.typeChat=='Leads'){
      this.submitfilterLeads(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    } 
    else {
      this.submitfilterMessage(searchAndFilterMessageListInputData,this.ValPagingFilter,this.offsetFilter)
    }

  }

  bulkAction(val:string){
    this.OnBlulk=true
    this.bulkType = val


    if(this.typeChat=='Internal'){
      this.SelectedBulk = []
      var array = this.AllInternal
      array.forEach((value: any) => {
        this.SelectedBulk.push(value.notificationId)
      });
      console.log('SelectedBulk Internal : ',this.SelectedBulk)
    }else if(this.typeChat=='System'){
      this.SelectedBulk = []
      var array = this.AllSystem
      array.forEach((value: any) => {
        this.SelectedBulk.push(value.notificationId)
      });
      console.log('SelectedBulk System : ',this.SelectedBulk)
    }   
    else if(this.typeChat=='Leads'){

      this.SelectedBulk = []
      var array = this.AllLeads
      array.forEach((value: any) => {
        console.log('bulkAction value : ',value)
        this.SelectedBulk.push(value.leadsMessageId)
      });
      console.log('SelectedBulk Leads : ',this.SelectedBulk)
    } 
    else {
      this.SelectedBulk = []
      var array = this.AllMessages
      array.forEach((value: any) => {
        this.SelectedBulk.push(value.messageId)
      });
      console.log('SelectedBulk Message : ',this.SelectedBulk)
    }

  }

  setCheckBox(id:string){
    var res = false

    var array = this.SelectedBulk
    array.forEach((value: any) => {
      if(value==id){
        res = true
      }
    });

    return res
  }
  OnClickCheck(id:string,oncheck:boolean){

    if(oncheck==true){
      this.SelectedBulk.push(id)
    } else {

      this.SelectedBulk.forEach((element:any,index:any)=>{
        if(element==id) this.SelectedBulk.splice(index,1);
      });

      if(this.SelectedBulk.length==0){
        this.cancelBulk()
      }


    }

    console.log('SelectedBulk :: ',this.SelectedBulk)
  }


  cancelBulk(){
    this.OnBlulk=false
    this.bulkType = ''
  }
  submitBulk(){
    if(this.bulkType=='Mark as Read'){ //seperate bulk chattype
      
      if(this.typeChat=='System' || this.typeChat=='Internal'){
        this.bulkReadNotification(this.SelectedBulk)
      }   
      else if(this.typeChat=='Leads'){
        this.bulkReadLeads(this.SelectedBulk)
      }
      else {
        this.bulkReadMessage(this.SelectedBulk)
      }

    }
    else if(this.bulkType=='Mark as Unread'){ //seperate bulk chattype
      
      if(this.typeChat=='System' || this.typeChat=='Internal'){
        this.bulkUnreadNotification(this.SelectedBulk)
      }   
      else if(this.typeChat=='Leads'){
        this.bulkUnreadLeads(this.SelectedBulk)
      }
      else {
        this.bulkUnreadMessage(this.SelectedBulk)
      }

    }
    else if(this.bulkType=='Bulk Star'){ //seperate bulk chattype

      if(this.typeChat=='Leads'){

        this.postStarLeads(this.SelectedBulk,'Success bulk star leads')
        this.SelectedBulk.forEach((element:any)=>{
          this.isLeadsStar(element)
        });

      } else {
        this.postStarNotification(this.SelectedBulk,'Success bulk star')
  
        if(this.typeChat=='System'){
          this.SelectedBulk.forEach((element:any)=>{
            this.isSystemStar(element)
          });
        }    
        else if(this.typeChat=='Internal'){
          this.SelectedBulk.forEach((element:any)=>{
            this.isInternalStar(element)
          });
        } 
        else {
          alert('cannot bulk star messages ') // api not support
        }
      }


    }
    else if(this.bulkType=='Bulk Unstar'){ //seperate bulk chattype

      if(this.typeChat=='Leads'){
        
        this.postUnstarLeads(this.SelectedBulk,'Success bulk unstar leads')
        this.SelectedBulk.forEach((element:any)=>{
          this.isLeadsStar(element)
        });

      } else {
        this.postUnstarNotification(this.SelectedBulk,'Success bulk unstar')
      
        if(this.typeChat=='System'){
          this.SelectedBulk.forEach((element:any)=>{
            this.isSystemStar(element)
          });
        }    
        else if(this.typeChat=='Internal'){
          this.SelectedBulk.forEach((element:any)=>{
            this.isInternalStar(element)
          });
        } 
        else {
          alert('cannot bulk unstar messages ') // api not support
        }
      }

    }
    else if(this.bulkType=='Bulk Delete'){ 


      const modalRef = this.modalService.open(ModalComponent, {
        modalDialogClass: 'modal-dialog-centered',
        centered: true,
        size: 'md'
      })
      modalRef.componentInstance.modalconfirm = 'modal'
      modalRef.componentInstance.title = 'Confirm Bulk Delete'
      modalRef.componentInstance.subtitle = 'Are you sure want to bulk delete?'
      modalRef.componentInstance.yesStr = 'Confirm'
      modalRef.componentInstance.noStr = 'Cancel'
  
      modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
        if (res) {
          if(this.typeChat=='Internal' || this.typeChat=='System'){
            this.bulkDeleteNotification(this.SelectedBulk)
          } 
          else if(this.typeChat=='Leads'){
            this.bulkDeleteLeads(this.SelectedBulk)
          }
          else {
            this.bulkDeleteMessage(this.SelectedBulk)
          }          
        }
      })


      
    }
    else {
      alert('Something wrong.. ') 
    }
  }

  bulkUnreadLeads(msgId:any){
    this.messagingService.getunreadLeadsMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unreadLeadsMessage){
        this.popupmodal.showSuccess('Success bulk read leads',null).subscribe((res:any) => {})
        this.cancelBulk()

        this.AllLeads.forEach((value: any, key: any) => {
          this.AllLeads[key].isRead=false
        });

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }


  bulkUnreadMessage(msgId:any){
    this.messagingService.getunreadMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unreadMessage){
        this.popupmodal.showSuccess('Success bulk unread message',null).subscribe((res:any) => {})
        this.cancelBulk()

        this.AllMessages.forEach((value: any, key: any) => {
          this.AllMessages[key].isRead=false
        });

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkReadLeads(msgId:any){
    this.messagingService.getreadLeadsMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.readLeadsMessage){
        this.popupmodal.showSuccess('Success bulk read leads',null).subscribe((res:any) => {})
        this.cancelBulk()

        this.AllLeads.forEach((value: any, key: any) => {
          this.AllLeads[key].isRead=true
        });

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }


  bulkReadMessage(msgId:any){
    this.messagingService.getreadMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.readMessage){
        this.popupmodal.showSuccess('Success bulk read message',null).subscribe((res:any) => {})
        this.cancelBulk()

        this.AllMessages.forEach((value: any, key: any) => {
          this.AllMessages[key].isRead=true
        });

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkUnreadNotification(msgId:any){
    this.messagingService.getunreadNotification(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.unreadNotification){
        if(this.typeChat=='Internal'){
          this.popupmodal.showSuccess('Success bulk unread internal',null).subscribe((res:any) => {})

          this.AllInternal.forEach((value: any, key: any) => {
            this.AllInternal[key].isRead=false
          });
        }else if(this.typeChat=='System'){
          this.popupmodal.showSuccess('Success bulk unread system',null).subscribe((res:any) => {})

          this.AllSystem.forEach((value: any, key: any) => {
            this.AllSystem[key].isRead=false
          });
        }
        
        this.cancelBulk()


      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkReadNotification(msgId:any){
    this.messagingService.getreadNotification(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.readNotification){
        if(this.typeChat=='Internal'){
          this.popupmodal.showSuccess('Success bulk read internal',null).subscribe((res:any) => {})

          this.AllInternal.forEach((value: any, key: any) => {
            this.AllInternal[key].isRead=true
          });

        }else if(this.typeChat=='System'){
          this.popupmodal.showSuccess('Success bulk read system',null).subscribe((res:any) => {})

          this.AllSystem.forEach((value: any, key: any) => {
            this.AllSystem[key].isRead=true
          });
        }

        this.cancelBulk()



      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkDeleteMessage(msgId:any){

    this.messagingService.getdeleteMessage(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.deleteMessage){
        this.popupmodal.showSuccess('Success bulk delete message',null).subscribe((res:any) => {})
        this.cancelBulk()

        for (let i = 0; i < msgId.length; i++) {
          this.AllMessages.forEach((element:any,index:any)=>{
            if(msgId[i]==element.messageId) this.AllMessages.splice(index,1);
          });
        }

        this.TotalAllMessages = this.AllMessages.length

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkDeleteLeads(msgId:any){

    this.messagingService.getdeleteLeads(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.deleteLeadsMessage){
        this.popupmodal.showSuccess('Success bulk delete leads',null).subscribe((res:any) => {})
        this.cancelBulk()

        for (let i = 0; i < msgId.length; i++) {
          this.AllLeads.forEach((element:any,index:any)=>{
            if(msgId[i]==element.leadsMessageId) this.AllLeads.splice(index,1);
          });
        }

        this.TotalAllLeads = this.AllLeads.length

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  bulkDeleteNotification(msgId:any){

    this.messagingService.getdeleteNotification(msgId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.deleteNotification){
        if(this.typeChat=='Internal'){
          this.popupmodal.showSuccess('Success bulk delete internal',null).subscribe((res:any) => {})

          for (let i = 0; i < msgId.length; i++) {
            this.AllInternal.forEach((element:any,index:any)=>{
              if(msgId[i]==element.notificationId) this.AllInternal.splice(index,1);
            });
          }

          this.TotalAllInternal = this.AllInternal.length
            
        }else if(this.typeChat=='System'){
          this.popupmodal.showSuccess('Success bulk delete system',null).subscribe((res:any) => {})

          for (let i = 0; i < msgId.length; i++) {
            this.AllSystem.forEach((element:any,index:any)=>{
              if(msgId[i]==element.notificationId) this.AllSystem.splice(index,1);
            });
          }

          this.TotalAllSystem = this.AllSystem.length

        }
        this.cancelBulk()


      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
  }

  findText(){
    this.onFindText=true

  }
  onKeyUpFindText(){
    console.log('>>',this.ChatList)

    // var markChatlist = this.ChatList
    // var resultmarkChatlist = null
    // var searchData = this.searchText
    // markChatlist.forEach((value: any, key: any) => {
    //   var textContent = value.content
    //   textContent = textContent.toString()
      
    //   var textResult = ''
    //   textContent.split('').forEach(function(val:any, i:any){
    //     console.log('val:',val)
    //     console.log('searchData.charAt(i)',searchData.charAt(i))
    //     if (val == searchData.charAt(i))
    //       textResult += "<span class='highlight'>"+val+"</span>";  
    //     else
    //       textResult += val;            
    //   });
    //   this.ChatList[key].content=textResult
    //   console.log('textResult : ',textResult)
    //   textResult = ''
      
    // });
  }
  closeFindText(){
    this.onFindText=false
    this.searchText=''
  }

  goToSearch() {
    // let el = document.getElementById('navbar');
    // el.scrollTop = el.scrollHeight;
    // window.scrollTo(0,document.body.scrollHeight);


    // var arrhighlight = document.querySelectorAll('.highlight')
    // console.log('highlight : ',arrhighlight)
    // window.scrollTo(0,document.body.scrollHeight);

    //msgdivR
    
    // //ok
    // const classElement = document.getElementsByClassName('filter_0'); // 0 3 11
    // if(classElement.length > 0){
    //   classElement[0].scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "start"
    //   });
    // }

  }

  onSubmit() {
  }

  back(): void {
    this.navigation.back()
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from 'src/app/_services/messaging.services';
import { ModalComponent } from '../modal/modal.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popupmessaging',
  templateUrl: './popupmessaging.component.html',
  styleUrls: ['./popupmessaging.component.scss']
})
export class PopupmessagingComponent implements OnInit {

  @Input() title?: string;
  @Input() sendMessageAs?: string;
  @Input() rfqid?: string;
  @Input() quotationId?: string;
  
  @Output() confirmedClick = new EventEmitter();

  sampleQuestion:any=[]
  sampleQuestionVendor=[
    {
      id:0,
      text:'Hi, apakah ready stok?',
    },
    {
      id:1,
      text:'Halo, minta contact PIC terkait?',
    },
    {
      id:2,
      text:'Bisa minta detail lokasi pengiriman?'
    }
  ]
  sampleQuestionProcurement=[
    {
      id:0,
      text:'Hi, apakah barang sudah siap dikirim?',
    },
    {
      id:1,
      text:'Halo, minta contact PIC terkait?',
    },
    {
      id:2,
      text:'Bisa minta email PIC terkait?'
    }
  ]

  sampleQuestionLeads=[
    {
      id:0,
      text:'Hi, apakah barang ini ready?',
    },
    {
      id:1,
      text:'Halo, apakah ada nomor WA ?',
    },
    {
      id:2,
      text:'Bisa minta email nya?'
    }
  ]
  
  selectedQuestion:string=''
  selectedIdQuestion:any=null
  isEmptyChat:boolean=true
  valMessage:string=''
  processMsg:boolean=false


  VendorIsMessageExist:any
  VendorMessageId:any
  modalService: any;

  LeadsMessageId:any

  ProcurementIsMessageExist:any
  ProcurementMessageId:any

  getResponse:boolean=false
  ResponseText:string=''

  sendTo:string=''

  constructor(
    public activeModal: NgbActiveModal,
    private messagingService: MessagingService,
    private router: Router,
    private popupmodal : Popupmodal,
    private translate: TranslateService,
    ) {}

  ngOnInit(): void {
    if(this.sendMessageAs){
      // this.sendTo = this.sendMessageAs.toLowerCase() == 'vendor' ?'procurement':'vendor'
      // this.sampleQuestion = this.sendMessageAs.toLowerCase() == 'vendor' ?this.sampleQuestionProcurement : this.sampleQuestionVendor

      if(this.sendMessageAs.toLowerCase() == 'vendor'){
        this.sendTo = 'procurement'
        this.sampleQuestion = this.sampleQuestionProcurement
      } else if(this.sendMessageAs.toLowerCase() == 'procurement'){
        this.sendTo = 'vendor'
        this.sampleQuestion = this.sampleQuestionVendor
      } else { // leads
        this.sendTo = 'leads'
        this.sampleQuestion = this.sampleQuestionLeads
      }

    }
  }

  enableBtn(){
    if(this.valMessage!==''){
      if(this.valMessage.length>3){
        this.isEmptyChat = false
      } else {
        this.isEmptyChat = true
      }
    } else {
      this.isEmptyChat = true
    }
   
  }

  selectData(id:any,text:string){
    if(this.selectedIdQuestion == id){
      this.selectedIdQuestion = null
      this.valMessage = ''
    } else {
      this.selectedIdQuestion = id
      this.valMessage = text
    }
    this.enableBtn()
  }

  

  submitMessage(){
    // api check message
    // ape send message
    // if(this.sendMessageAs=='VENDOR'){
    //   if(this.rfqid){
    //     this.vendorChechMessage(this.rfqid)
    //   }
    // } else {
    //   if(this.rfqid && this.quotationId){
    //     this.procurementChechMessage(this.rfqid,this.quotationId)
    //   }
    // }

    if(this.sendMessageAs=='VENDOR'){
      if(this.rfqid){
        this.vendorChechMessage(this.rfqid)
      }
    } else if(this.sendMessageAs=='PROCUREMENT'){
      if(this.rfqid && this.quotationId){
        this.procurementChechMessage(this.rfqid,this.quotationId)
      }
    } else {
      if(this.rfqid){
        this.leadsChechMessage(this.rfqid)
      }
      
      //this.leadsMessage()
    }




    //this.close()
  }

  
  leadsChechMessage(rfqid:string){
    this.isEmptyChat=true
    this.processMsg=true
    this.messagingService.getvendorCheckLeadsMessageExist(rfqid).subscribe((res:any) => {
      console.log(res)
      if(res.data?.checkLeadsMessageExist){
        this.VendorIsMessageExist = res.data?.checkLeadsMessageExist.isExist
        this.LeadsMessageId = res.data?.checkLeadsMessageExist.leadsId

        this.isEmptyChat=false
        this.processMsg=false

        if(this.VendorIsMessageExist){
           //send w this.VendorMessageId
           //alert('send with VendorMessageId')
          this.leadssendMessagebyId(this.LeadsMessageId)
          // alert('send leads with id')
        } else {
          //alert('send leads without id')
          this.leadsMessage()
        }
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)


        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });
  }

  leadsMessage(){
    this.isEmptyChat=true
    this.processMsg=true

    var vendorId = ''
    if(this.rfqid){
      vendorId = this.rfqid
    }
    this.messagingService.getaskLeadVendor(vendorId,this.valMessage).subscribe((res:any) => {
      console.log(res)
      this.isEmptyChat=false
      this.processMsg=false
      if(res.data?.askLeadVendor){
        // this.getResponse=true
        // this.ResponseText='Success send message' 
        this.successNotif()
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });

  }

  leadssendMessagebyId(msgid:string){
    this.isEmptyChat=true
    this.processMsg=true
    this.messagingService.getsendLeadMessage(msgid,this.valMessage).subscribe((res:any) => {
      console.log(res)
      this.isEmptyChat=false
      this.processMsg=false
      if(res.data?.sendLeadsMessage){
        // this.getResponse=true
        // this.ResponseText='Success send message' 
        this.successNotif()
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });
  }

  close() {
    this.confirmedClick.emit(true);
    this.activeModal.close();
  }
  successNotif(){
    this.confirmedClick.emit(true);
    this.activeModal.close();
    var notif = 'success.send_message'
    this.translate.get('success.send_message').subscribe((data:any)=> {
      notif = data
    });
    this.popupmodal.showSuccess(notif,null).subscribe((res:any) => {})
  }

  vendorChechMessage(rfqid:string){
    this.isEmptyChat=true
    this.processMsg=true
    this.messagingService.getvendorCheckThreadMessageExist(rfqid).subscribe((res:any) => {
      console.log(res)
      if(res.data?.vendorCheckThreadMessageExist){
          
        this.VendorIsMessageExist = res.data?.vendorCheckThreadMessageExist.isExist
        this.VendorMessageId = res.data?.vendorCheckThreadMessageExist.messageId

        this.isEmptyChat=false
        this.processMsg=false

        if(this.VendorIsMessageExist){
           //send w this.VendorMessageId
           //alert('send with VendorMessageId')
          this.sendMessagebyId(this.VendorMessageId)
           
        } else {
          this.vendorSendMessage()
        }
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)


        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });
  }
  

  vendorSendMessage(){
    this.isEmptyChat=true
    this.processMsg=true
    var askProcurementInputData = {
      message:this.valMessage
    }
    this.messagingService.getaskProcurement(this.rfqid,askProcurementInputData).subscribe((res:any) => {
      console.log(res)
      this.isEmptyChat=false
      this.processMsg=false
      if(res.data?.askProcurement){
        // this.getResponse=true
        // this.ResponseText='Success send message' 
        this.successNotif()
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });

  }


  procurementChechMessage(rfqid:string,quotationId:string){
    this.isEmptyChat=true
    this.processMsg=true
    this.messagingService.getprocurementCheckThreadMessageExist(rfqid,quotationId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.procurementCheckThreadMessageExist){
          
        this.ProcurementIsMessageExist = res.data?.procurementCheckThreadMessageExist.isExist
        this.ProcurementMessageId = res.data?.procurementCheckThreadMessageExist.messageId

        this.isEmptyChat=false
        this.processMsg=false

        if(this.ProcurementIsMessageExist){
          this.sendMessagebyId(this.ProcurementMessageId)
           
        } else {
          this.procurementSendMessage()
        }
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });
  }

  
  procurementSendMessage(){
    this.isEmptyChat=true
    this.processMsg=true
    var askVendorInputData = {
      message:this.valMessage
    }
    this.messagingService.getaskVendor(this.rfqid,this.quotationId,askVendorInputData).subscribe((res:any) => {
      console.log(res)
      this.isEmptyChat=false
      this.processMsg=false
      if(res.data?.askVendor){
        // this.getResponse=true
        // this.ResponseText='Success send message' 
        this.successNotif()
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });

  }


  sendMessagebyId(msgid:string){
    this.isEmptyChat=true
    this.processMsg=true
    this.messagingService.getsendMessage(msgid,this.valMessage,this.sendMessageAs).subscribe((res:any) => {
      console.log(res)
      this.isEmptyChat=false
      this.processMsg=false
      if(res.data?.sendMessage){
        // this.getResponse=true
        // this.ResponseText='Success send message' 
        this.successNotif()
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
      }
      else {
        this.getResponse=true
        this.ResponseText='Please try again later' 
      }
    });
  }







  sendMessage(){

  }

  messageChange(){
    var selected = null
    this.sampleQuestion.forEach((value: any) => {
      if(this.valMessage==value.text){
        selected = value.id
      }
    }) 
    this.selectedIdQuestion = selected
    this.enableBtn()
  }

}

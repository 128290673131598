<div class="pt-3 pb-3">
  <div class="container" *ngIf="PageLoading">
    <div>
      <div class="text-center pt-5 pb-5">
        <div class="loader loader--style8" title="7">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;"
            xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
              <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!PageLoading">
    <div class="mt-3">
      <div class="pb-5">
        <div class="mb-4">
          <div class="">
            <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
              <div class="row">
                <div class="col-sm-12">
                  <div class="shbreadcum">
                    <table>
                      <tr>
                        <td class="separator">
                          <h6 class="pt-2 mt-1 me-3"><strong><a href="/"
                                class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png"
                                  class="backicon"> Back</a></strong></h6>
                        </td>
                        <td>
                          <h6 class="mb-1 mt-2 ms-3">
                            <strong class="c1">
                                
                                <a class="c1 pointer" href="/procurement/rating/procurement-rating-list">Rating and Review List</a> > 
                              <font class="text-muted">Vendor
                              </font>
                            </strong>
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0  mb-3 divshbreadcum">

            <div class="container">
              <div class="row">

                <div class="col-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top ps-5">
                    <h4 class="mb-0 fw-bold">Rating and Review Detail</h4>
                </div>

                <div class="col-sm-9">


      
                      <div class="ps-5 pe-5">
                          <h2 class="heading mt-2">Rate and Review Product!</h2>
                          <h4 class="heading-detail">PPO ID : PO237119 <font>(PT. CAHAYA PERKASA TAMA)</font></h4>
                          <p class="mt-4 heading-text">Your transaction has been successfully done, please give us rate based on your experiences!</p>
      
      

      
                      </div>

                </div>



                <div class="col-sm-3 text-center">
                    <h2 class="overallrating ps-3">3.0<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png"></h2>
                    <h4 class="overalltext">Rating  Vendor Overall</h4>
                    
                </div>

                <div class="col-12  ps-5 pe-5 pb-3">
                    <div class="cardshadow ps-5 pe-5 pt-4 pb-4 ms-3 me-3">
                        <div class="row">
                            <div class="col-sm-3">
                                <h3>Samsung : <font>Galaxy S20 FE - Snapgragon RAM 8GB/128 GB</font></h3>
                                <h4 class="mb-0">Service Quality</h4>
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                            </div>
                            <div class="col-sm-9">
                                <h4 class="mb-2">Review Description</h4>
                                <textarea class="form-control txtareaReview"  rows="5" cols="40" maxlength="500" placeholder="Type Review Description" ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12  ps-5 pe-5 pb-3">
                    <div class="cardshadow ps-5 pe-5 pt-4 pb-4 ms-3 me-3">
                        <div class="row">
                            <div class="col-sm-3">
                                <h3>Samsung : <font>Galaxy S20 FE - Snapgragon RAM 8GB/128 GB</font></h3>
                                <h4 class="mb-0">Service Quality</h4>
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                            </div>
                            <div class="col-sm-9">
                                <h4 class="mb-2">Review Description</h4>
                                <textarea class="form-control txtareaReview"  rows="5" cols="40" maxlength="500" placeholder="Type Review Description" ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12  ps-5 pe-5 pb-3">
                    <div class="cardshadow ps-5 pe-5 pt-4 pb-4 ms-3 me-3">
                        <div class="row">
                            <div class="col-sm-3">
                                <h3>Samsung : <font>Galaxy S20 FE - Snapgragon RAM 8GB/128 GB</font></h3>
                                <h4 class="mb-0">Service Quality</h4>
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                                <img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">
                            </div>
                            <div class="col-sm-9">
                                <h4 class="mb-2">Review Description</h4>
                                <textarea class="form-control txtareaReview"  rows="5" cols="40" maxlength="500" placeholder="Type Review Description" ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 text-center">
                    <button class="fhbtn1 ratingbtn mt-4 mb-4" (click)="redirect()">Submit</button>
                </div>






              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

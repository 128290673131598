import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { config } from './../../config';
import { Tokens } from '../models/tokens';
import { LoginService } from 'src/app/_services/login.services';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';
// import { MatomoTracker } from '@ngx-matomo/tracker';

const mutationRefresh = gql`
mutation refreshAccessToken($userId: ID!, $staffId: ID, $refreshToken: JwtToken!) {
  refreshAccessToken(input: {
      userId: $userId,
      staffId: $staffId
      refreshToken: $refreshToken
  })
}
`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly USERDATA = 'USERDATA';
  private readonly COMPANYDATA = 'COMPANYDATA';
  private readonly PRODUCTLIST = 'PRODUCTLIST';

  
  private loggedUser: any;
  private userData: any;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private apollo: Apollo,
    // private readonly tracker: MatomoTracker
  ) {}

  login(user: { username: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${config.apiUrl}/login`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.username, tokens, user)),
        mapTo(true),
        catchError(error => {
          alert(error.error);
          console.log('error',error);
          return of(false);
        }));
  }

  logout() {
    return this.http.post<any>(`${config.apiUrl}/logout`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  RequestrefreshToken(userId:any,staffId:any,refreshToken:any) {
    // return this.http.post<any>(`${config.apiUrl}/refresh`, {
    //   'refreshToken': this.getRefreshToken()
    // }).pipe(tap((tokens: Tokens) => {
    //   this.storeJwtToken(tokens.jwt);
    // }));


      // //try
      // var userId:any = null
      // var staffId:any = null
      // var refreshToken:any = this.getRefreshToken()

      // var getuserdata = localStorage.getItem("USERDATA")
      // console.log('getuserdata ',getuserdata);
      // if (getuserdata !== null) {
      //   var userdata = JSON.parse(getuserdata);
      //   console.log('userdata ',userdata);
      //   console.log('userdata id ',userdata.id);
      //   userId = userdata.id
      // }
  
      // var getusercompany = localStorage.getItem("USERCOMPANY")
      // console.log('getusercompany ',getusercompany);
      // if (getusercompany !== null) {
      //   var usercompany = JSON.parse(getusercompany);
      //   console.log('usercompany ',usercompany);
      //   console.log('usercompany id ',usercompany.id);
      //   staffId = usercompany.id
      // }

      // //try direct
      // this.apollo
      // .mutate({
      //   mutation: mutationRefresh,
      //   variables: {userId: userId, staffId: staffId, refreshToken: refreshToken}
      // })
      // .subscribe(
      //   (res:any) => {
      //     console.log("succes, get data:", res);
      //     return res.data.refreshAccessToken
      //   },
      //   (error) => {
      //     console.log("there was an error sending the query", error);
      //     //this.removeTokens()
      //     return false
      //   }
      // );
      
      // try w services
    this.loginService
    .getrefreshAccessToken(userId, staffId, refreshToken)
    .subscribe((res:any) => {
      if(res.data?.refreshAccessToken){
        this.storeJwtToken(res.data.refreshAccessToken);
      } else {
          this.removeTokens()
      }

      // this.removeTokens()
      // return false
    })



  }

  getJwtToken() {
    if(this.checkExpToken()){
      console.log('not expired, all fine');
      return localStorage.getItem(this.JWT_TOKEN);
    } else {
      console.log('expred, recall get new token') 

      // refreshAccessToken call refresh token   
      
      //try
      var userId:any = null
      var staffId:any = null
      var refreshToken:any = this.getRefreshToken()

      var getuserdata = localStorage.getItem("USERDATA")
      console.log('getuserdata ',getuserdata);
      if (getuserdata !== null) {
        var userdata = JSON.parse(getuserdata);
        console.log('userdata ',userdata);
        console.log('userdata id ',userdata.id);
        userId = userdata.id

        // // matomo _paq.push(['setUserId', userdata.id]);
        // this.tracker.trackEvent('setUserId',userdata.id);

      }
  
      var getusercompany = localStorage.getItem("USERCOMPANY")
      console.log('getusercompany ',getusercompany);
      if (getusercompany !== null) {
        var usercompany = JSON.parse(getusercompany);
        console.log('usercompany ',usercompany);
        console.log('usercompany id ',usercompany.id);
        staffId = usercompany.id
      }
      

      if(userId!==null && refreshToken!==null){
        this.RequestrefreshToken(userId,staffId,refreshToken)
        return true
      }
      else {
        console.log('userId and refreshToken is null')
        //this.removeTokens() // disable for debugging!!!!
        this.removeTokens()
        return false
      }
      


      //this.removeTokens()
      // return false
    }
    // console.log('localStorage.getItem(this.JWT_TOKEN)',localStorage.getItem(this.JWT_TOKEN));
    // return localStorage.getItem(this.JWT_TOKEN);
  }

  forceLogout(){
    this.doLogoutUser()
  }

  private doLoginUser(username: string, tokens: Tokens, userData: any) {
    this.loggedUser = username;
    this.userData = userData;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.userData = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    var getTemplate = localStorage.getItem('templateID');
    var getUtm = localStorage.getItem('utm_campaign') || '';
    var ref:any = ''
    if(localStorage.getItem('ref')){
      ref = localStorage.getItem('ref');
    }

    if(!getTemplate){
      localStorage.clear();
    } else {
      localStorage.clear();
      localStorage.setItem('templateID',getTemplate);
    }
    if(ref!==''){
      localStorage.setItem('ref',ref);
    }
    if(getUtm!==''){
      localStorage.setItem('utm_campaign',getUtm);
    }
    // localStorage.clear();
  }

  private checkExpToken() {
    if(localStorage.getItem(this.JWT_TOKEN) === null){
      console.log('JWT_TOKEN key not exist');
      return false
    } else {
      var jwttoken = this.parseJwt(localStorage.getItem(this.JWT_TOKEN))
      console.log('cek jwttoken',jwttoken);
      if(jwttoken === null){
        return false
      } else {
        if (jwttoken.exp < new Date().getTime()/1000) {
          console.log("EXPIRED 456");
          return false
        } else {
          return true
        }
        
      }
      // alert('bypass return false')
      // return false
    }
  }
  private parseJwt(token: string|null) {
    if(token !==null){
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }

  };


  forceToLogin(){
    alert('forceToLogin , clear storage')
    console.log('forceToLogin , clear storage')
    // this.router.navigate(['/login']);
  }
}

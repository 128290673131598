import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popupcart',
  templateUrl: './popupcart.component.html',
  styleUrls: ['./popupcart.component.scss']
})
export class PopupcartComponent implements OnInit {

  @Output() confirmedClick = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  submit() {
    this.confirmedClick.emit(true);
  }
}

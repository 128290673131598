import { NgModule } from "@angular/core";
import { AssistanceComponent } from "./components/assistance/assistance.component";
import { BoardComponent } from "./components/rfq/board/board.component";
import { RfqPoDetailComponent } from "./views/pages/member/rfq-po-detail/rfq-po-detail.component";
import { CommonModule } from "@angular/common";
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShareComponent } from "./components/share/share.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ProductCardComponent } from "./components/product/card/product-card.component";
import { LightgalleryModule } from "lightgallery/angular/12";
// import { RfqmenuComponent } from "./views/includes/rfqmenu/rfqmenu.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        LightgalleryModule,
        // TranslateModule.forRoot({
        //     loader: {
        //       provide: TranslateLoader,
        //       useFactory: httpTranslateLoader,
        //       deps: [HttpClient]
        //     }
        // }),
        TranslateModule.forChild()
    ],
    declarations: [
        RfqPoDetailComponent,
        BoardComponent,
        ProductCardComponent,
        AssistanceComponent,
        ShareComponent
        // RfqmenuComponent
    ],
    exports: [
        RfqPoDetailComponent,
        BoardComponent,
        ProductCardComponent,
        AssistanceComponent,
        ShareComponent
        // RfqmenuComponent
    ]
    
  })
  export class SharedModule {
    constructor(public translationService: TranslateService) {

      this.translationService.store.onLangChange
        .subscribe((lang: LangChangeEvent) => {
          console.log(' ==> FeatureModule ', lang);
          this.translationService.use(lang.lang);
        });
    }
  }
  // export function httpTranslateLoader(http: HttpClient) {
  //   return new TranslateHttpLoader(http);
  // }
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings, RecaptchaV3Module } from "ng-recaptcha";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './views/pages/home/home.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { HeaderComponent } from './views/includes/header/header.component';
import { FooterComponent } from './views/includes/footer/footer.component';
import { SearchComponent } from './views/pages/search/search.component';
import { DashboardComponent } from './views/pages/member/dashboard/dashboard.component';
import { VerifyComponent } from './views/pages/verify/verify.component';
import { MemberheaderComponent } from './views/includes/memberheader/memberheader.component';
import { MembersidebarComponent } from './views/includes/membersidebar/membersidebar.component';
import { MemberlayoutComponent } from './views/includes/memberlayout/memberlayout.component';
// import { ProductsComponent } from './views/pages/member/products/products.component';
// import { ProductsDetailComponent } from './views/pages/member/products-detail/products-detail.component';
import { LayoutComponent } from './views/includes/layout/layout.component';
// import { ProductsPreviewComponent } from './views/pages/member/products-preview/products-preview.component';
// import { ProductsCompareComponent } from './views/pages/member/products-compare/products-compare.component';
// import { CartComponent } from './views/pages/procurement/cart/cart.component';
// import { ProcurementProductsComponent } from './views/pages/procurement/products/products.component';
import { ProcurementProductsDetailComponent } from './views/pages/procurement/products-detail/products-detail.component';
import { NotfoundComponent } from './views/pages/notfound/notfound.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { RfqComponent } from './views/pages/procurement/rfq/rfq.component';
// import { RfqmenuComponent } from './views/includes/rfqmenu/rfqmenu.component';
// import { RfqHistoryComponent } from './views/pages/procurement/rfq-history/rfq-history.component';
// import { RfqListComponent } from './views/pages/procurement/rfq-list/rfq-list.component';
// import { VendorRfqListComponent } from './views/pages/member/rfq-list/rfq-list.component';
// import { VendorRfqmenuComponent } from './views/includes/vendorrfqmenu/rfqmenu.component';
// import { VendorRfqPreviewComponent } from './views/pages/member/rfq-preview/rfq-preview.component';
// import { VendorRfqParticipatingInListComponent } from './views/pages/member/rfq-participating-in-list/rfq-participating-in-list.component';
// import { VendorRfqParticipatingInPreviewComponent } from './views/pages/member/rfq-participating-in-preview/rfq-participating-in-preview.component';
// import { VendorRfqComponent } from './views/pages/member/rfq/rfq.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './_services/data';
// import { RfqPreviewComponent } from './views/pages/procurement/rfq-preview/rfq-preview.component';
import { CommonModule, DatePipe } from '@angular/common';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// import { RfqQuotedComponent } from './views/pages/procurement/rfq-quoted/rfq-quoted.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import { RfqQuotedVendorComponent } from './views/pages/procurement/rfq-quoted-vendor/rfq-quoted-vendor.component';
// import { RfqQuotedPreviewComponent } from './views/pages/procurement/rfq-quoted-preview/rfq-quoted-preview.component';
// import { RfqQuotationComponent } from './views/pages/member/rfq-quotation/rfq-quotation.component';
// import { RfqQuotationCompareComponent } from './views/pages/member/rfq-quotation-compare/rfq-quotation-compare.component';
// import { RfqHistoryDetailComponent } from './views/pages/procurement/rfq-history-detail/rfq-history-detail.component';
// import { ProformaPoComponent } from './views/pages/procurement/proforma-po/proforma-po.component';
import { ProformaPoDetailComponent } from './views/pages/procurement/proforma-po-detail/proforma-po-detail.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RfqPreviewQuoteComponent } from './views/pages/member/rfq-preview-quote/rfq-preview-quote.component';
// import { RfqQuotationHistoryComponent } from './views/pages/member/rfq-quotation-history/rfq-quotation-history.component';
// import { RfqQuotationHistoryDetailComponent } from './views/pages/member/rfq-quotation-history-detail/rfq-quotation-history-detail.component';
// import { RfqDraftViewComponent } from './views/pages/procurement/rfq-draft-view/rfq-draft-view.component';
// import { RfqQuotationDraftViewComponent } from './views/pages/member/rfq-quotation-draft-view/rfq-quotation-draft-view.component';
// import { CompanyManagementComponent } from './views/pages/setting/company-management/company-management.component';
// import { CompanyDetailComponent } from './views/pages/setting/company-detail/company-detail.component';
// import { UserManagementComponent } from './views/pages/setting/user-management/user-management.component';
// import { UserDetailComponent } from './views/pages/setting/user-detail/user-detail.component';
import { VerifystaffComponent } from './views/pages/verifystaff/verifystaff.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { map } from 'rxjs/operators';
import { ResetpasswordComponent } from './views/pages/resetpassword/resetpassword.component';
import { UnblockemailComponent } from './views/pages/unblockemail/unblockemail.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgAutonumericModule } from '@angularfy/ng-autonumeric';
import { ProfileComponent } from './views/pages/profile/profile.component';
import { ProductComponent } from './views/pages/search/product/product.component';
import { ProductDetailComponent } from './views/pages/search/product-detail/product-detail.component';
// import { ReccurringComponent } from './views/pages/procurement/reccurring/reccurring.component';
// import { ProformaPoSummaryComponent } from './views/pages/procurement/proforma-po-summary/proforma-po-summary.component';
import { MessagingComponent } from './views/pages/messaging/messaging.component';
import { VendorDetailComponent } from './views/pages/search/vendor-detail/vendor-detail.component';
import { PopupmessagingComponent } from './components/popupmessaging/popupmessaging.component';
// import { AssistanceComponent } from './components/assistance/assistance.component';
import { ManageHttpInterceptor } from './interceptor/managehttp.interceptor';
import { HttpCancelService } from './_services/httpcancel.service';
import { HighLightPipe } from './_helpers/pipemark';
import { ProcurementRatingListComponent } from './views/pages/rating/procurement-rating-list/procurement-rating-list.component';
import { ProcurementRatingVendorComponent } from './views/pages/rating/procurement-rating-vendor/procurement-rating-vendor.component';
import { ProcurementRatingProductComponent } from './views/pages/rating/procurement-rating-product/procurement-rating-product.component';
import { PopupfeedbackComponent } from './components/popupfeedback/popupfeedback.component';
// import { RfqPoDetailComponent } from './views/pages/member/rfq-po-detail/rfq-po-detail.component';
// import { RfqPoExportComponent } from './views/pages/member/rfq-po-export/rfq-po-export.component';
// import { VendorRatingListComponent } from './views/pages/rating/vendor-rating-list/vendor-rating-list.component';
// import { VendorRatingProcurementComponent } from './views/pages/rating/vendor-rating-procurement/vendor-rating-procurement.component';
// import { BoardComponent } from './components/rfq/board/board.component';
import { FeedbackreportComponent } from './views/pages/feedbackreport/feedbackreport.component';
import { HomepageComponent } from './views/pages/homepage/homepage.component';
import { PopupfilterComponent } from './components/popupfilter/popupfilter.component';
import { ImgFallbackDirective } from './_helpers/img-fallback.directive';
//import { LightgalleryModule } from 'lightgallery/angular';
import { LightgalleryModule } from 'lightgallery/angular/12';
import { config } from './config';
import { CookieService } from 'ngx-cookie-service';
import { DecryptPipe, EncryptPipe } from './_helpers/enctypt-decrytp.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HelpComponent } from './views/pages/help/help.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MetadataService } from './metadata.service';
import { SharedModule } from './shared.module';
import { ApplicationInitializerFactory } from './translation.config';
import { ThankyouComponent } from './views/pages/thank-you/thank-you.component';
import { PopuphelpsurveyComponent } from './components/popuphelpsurvey/popuphelpsurvey.component';
import { SubscriptionComponent } from './views/pages/subscription/subscription.component';
import { ContactusComponent } from './views/pages/contact-us/contact-us.component';
import { HeadernonloginComponent } from './views/includes/headernonlogin/headernonlogin.component';
import { PopupcartComponent } from './components/popupcart/popupcart.component';
import { LandingShareComponent } from './views/pages/landing-share/landing-share.component';
//import { ShareComponent } from './components/share/share.component';
// import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
// import { NgxMatomoRouterModule } from '@ngx-matomo/router';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    HeadernonloginComponent,
    FooterComponent,
    SearchComponent,
    LandingShareComponent,
    DashboardComponent,
    VerifyComponent,
    MemberheaderComponent,
    MembersidebarComponent,
    MemberlayoutComponent,
    // ProductsComponent,
    // ProductsDetailComponent,
    LayoutComponent,
    // ProductsPreviewComponent,
    // ProductsCompareComponent,
    // CartComponent,
    ProcurementProductsDetailComponent,
    // ProcurementProductsComponent,
    NotfoundComponent,
    // RfqComponent,
    // RfqmenuComponent,
    // VendorRfqmenuComponent,
    // RfqHistoryComponent,
    // RfqListComponent,
    // VendorRfqListComponent,
    // VendorRfqPreviewComponent,
    // VendorRfqParticipatingInListComponent,
    // VendorRfqParticipatingInPreviewComponent,
    // VendorRfqComponent,
    // RfqPreviewComponent,
    // RfqQuotedComponent,
    // RfqQuotedVendorComponent,
    // RfqQuotedPreviewComponent,
    // RfqQuotationComponent,
    // RfqQuotationCompareComponent,
    // RfqHistoryDetailComponent,
    // ProformaPoComponent,
    ProformaPoDetailComponent,
    RfqPreviewQuoteComponent,
    // RfqQuotationHistoryComponent,
    // RfqQuotationHistoryDetailComponent,
    // RfqDraftViewComponent,
    // RfqQuotationDraftViewComponent,
    // CompanyManagementComponent,
    // CompanyDetailComponent,
    // UserManagementComponent,
    // UserDetailComponent,
    VerifystaffComponent,
    ResetpasswordComponent,
    UnblockemailComponent,
    ModalComponent,
    PopupcartComponent,
    ProfileComponent,
    ProductComponent,
    ProductDetailComponent,
    // ReccurringComponent,
    // ProformaPoSummaryComponent,
    MessagingComponent,
    VendorDetailComponent,
    PopupmessagingComponent,
    // AssistanceComponent,
    HighLightPipe,
    ImgFallbackDirective,
    ProcurementRatingListComponent,
    ProcurementRatingVendorComponent,
    ProcurementRatingProductComponent,
    PopupfeedbackComponent,
    PopuphelpsurveyComponent,
    // RfqPoDetailComponent,
    // RfqPoExportComponent,
    // VendorRatingListComponent,
    // VendorRatingProcurementComponent,
    // BoardComponent,
    FeedbackreportComponent,
    HomepageComponent,
    PopupfilterComponent,
    EncryptPipe, 
    DecryptPipe, 
    HelpComponent,
    //ShareComponent,
    ThankyouComponent,
    SubscriptionComponent,
    ContactusComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    GraphQLModule,
    HttpClientModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    RecaptchaModule,
    PdfViewerModule,
    // EditorModule,
    NgbModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    BrowserAnimationsModule,
    TagInputModule,
    LightgalleryModule,
    NgAutonumericModule,
    SharedModule,
    // NgxMatomoTrackerModule.forRoot({
    //   siteId: environment.YOUR_MATOMO_SITE_ID, // your Matomo's site ID (find it in your Matomo's settings)
    //   trackerUrl: environment.YOUR_MATOMO_SERVER_URL, // your matomo server root url
    //   scriptUrl: environment.YOUR_MATOMO_SCRIPT_URL, // your Matomo's script url
    // }),
    // NgxMatomoRouterModule
  ],
  providers: [
    DataService,
    DatePipe,
    CookieService,
    EncryptPipe, 
    DecryptPipe,
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: "6Lcq5asbAAAAAAJYwCY0Kt1Gvp0MAa9_79-1g7Gu"
    // }
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: config.captchaKey } as RecaptchaSettings,
    },
    // {
    //   provide: [RECAPTCHA_V3_SITE_KEY,HTTP_INTERCEPTORS],
    //   useValue: config.captchaKey,
    //   useClass: ManageHttpInterceptor,
    //   multi: true 
    // }
    //language for lazy load module
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [ TranslateService, Injector ],
      multi: true
    },
    MetadataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

<div class="container">



    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 d-none d-sm-block">

        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <!-- <h6 class="pt-2 mt-1"><strong class="c1">Vendor  >  <a href='/vendor/products'><font class="c1">Product List</font></a>  > <font class="c2">{{ EditProductId!==null ? 'Edit Product':'Add New Product' }}</font></strong> </h6> -->

              <table>
                <tr>
                  <td class="separator">
                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                  </td>
                  <td>
                    <h6 class="pt-2 mt-1 ms-3"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">{{ EditProductId!==null ? 'Edit Product':'Add New Product' }}</font></strong> </h6>
                  </td>  
                </tr>
              </table>


            </div>
          </div>
          <div class="col-sm-4">
            <!-- <div class="input-group mb-0 breadcumsearchdiv">
              <input type="text" class="form-control breadcumsearchinput" placeholder="Search products..." aria-label="Recipient's username" aria-describedby="button-addon2">
              <button type="button" class="btn btn-primary breadcumsearchbox align-self-center"><img class="breadcumsearchbox" src="/assets/images/icons/ic_Search.png"></button>
            </div> -->
          </div>
        </div>
    </div>

    <!-- Breadcrumb mobile -->
    <div class="d-block d-sm-none">
      <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">
        <div class="row">
          <div class="col-12">
            <div class="pt-1">
              <strong class="c1">
                <a class="c1 pointer" href="/vendor/product-list">Product List</a> >
                <font class="c2">{{ EditProductId!==null ? 'Edit Product':'Add New Product' }}</font>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>   
    

    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
        <div class="container pb-5">
    <div class="row pb-5">
        
        <div class="col-12 px-0">
            <app-assistance assistanceTitle="User Hint" assistanceText="{{'product.assistance' | translate }}" assistanceType="vendor" assistanceStepper='[]'></app-assistance>
        </div>

        <div class="col-md-12 divprodlist">
            <div class="row">
                <div class="col-sm-12 mt-0 pt-3  bg1 text-white mb-3 pb-3 rounded-top ps-3">
                    <h4 class="mb-0">{{ EditProductId!==null ? 'Edit Product':'Add New Product' }}</h4>
                </div>

                <div class="col-sm-12 mt-0 pt-0" *ngIf="successdraft">

                    <div class="alert alert-success alert-dismissible fade show rounded-1 successNotif" role="alert">
                        <strong>Success</strong> : Your data has been successfully saved as a draft!
                        <p class="btn-close successNotif" (click)="closesuccessdraft()"></p>
                      </div>
                    

                </div>

                <div class="col-sm-12 mt-0 pt-0" *ngIf="productFormInvalid && submitted">

                    <div class="alert alert-danger alert-dismissible fade show rounded-1 errorNotif" role="alert">
                        <strong>Warning</strong> : Please check all required input.
                        <!-- <p class="btn-close errorNotif" (click)="closeproductFormInvalid()"></p> -->
                      </div>
                    

                </div>

                

                <div class="col-sm-4 px-lg-5 pt-3">
      


                    <div class="row  g-0" *ngIf="!EditProductId">
                      <div class="col-9 col-sm-12 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">

                          <div class="banner-image-container pointer" [ngClass]="{ 'border-danger border-err-img': submitted && f?.primaryImg?.errors }" *ngIf="!imageSrc" (click)="uploadimg1.click()">
                            <img src="/assets/images/icons/empty-img-primary.png" appImgFallback>
                          </div>
                          
                          <div class="banner-image-container pointer" [ngClass]="{ 'border-danger border-err-img': submitted && f?.primaryImg?.errors }" *ngIf="imageSrc" (click)="uploadimg1.click()">
                            <img src="{{imageSrc}}" *ngIf="imageSrc" appImgFallback>
                          </div>

                          <p class="text-center mb-0 pb-0 thumbimgdivDelete pointer"  *ngIf="imageSrc">
                              <i class="bi bi-trash text-danger"  *ngIf="imageSrc" (click)="removePirmaryImg()"></i>
                          </p>

                      </div>
  
                      <div class="col-3 col-sm-12 col-md-12 col-lg-12">
                        <div class="row g-0">
                            <ng-container  *ngFor="let MoreImg of MoreImgCount; let i = index">
                                <div class="col-12 col-sm-4 col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1 pointer">

                                    <div class="banner-image-container secondaryimg pointer" *ngIf="!imageSrcMore[i].val"  (click)="onclickMoreImg(i)">
                                        <img class="d-block d-lg-none" src="/assets/images/icons/empty-img-primary.png" appImgFallback>
                                        <img class="d-none d-lg-block" src="/assets/images/icons/empty-img-secondary.png" appImgFallback>
                                    </div>

                                    <div class="banner-image-container secondaryimg pointer" *ngIf="imageSrcMore[i].val" (click)="MoreImgFileClick('MoreImg'+i)">
                                        <img [src]="imageSrcMore[i].val" *ngIf="imageSrcMore[i].val" appImgFallback>
                                    </div>

                                    <p class="text-center mb-0 pb-0 thumbimgdivDelete"  *ngIf="imageSrcMore[i].val">
                                        <i class="bi bi-trash text-danger"  *ngIf="imageSrcMore[i].val" (click)="MoreImgRemoveFile(i)"></i>
                                    </p>


                                  </div>

                            </ng-container>

                        </div>
                      </div>
                      <div class="col-12">
                        <div *ngIf="submitted && f?.primaryImg?.errors">
                            <p *ngIf="f?.primaryImg?.errors?.required" class="text-danger imgnotif">Primary image is required</p>
                        </div>                              
                      </div>
                      <div class="col-12" *ngIf="errorUploadImg">
                        <div>
                            <p *ngIf="errorUploadImg" class="text-danger imgnotif">Image must less than 2Mb and image format only (jpeg/jpg/png/gif)</p>
                        </div>                              
                      </div>
                    </div>

                    <div class="row  g-0" *ngIf="EditProductId">
                      <div class="col-12 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">
                          <!-- <div class="text-center bg-white border rounded-0  thumbimgdiv1empty pointer" [ngClass]="{ 'border-danger': submitted && f?.primaryImg?.errors || submitted && !imageSrc }" *ngIf="!imageSrc"  (click)="uploadimg1Upload.click()"> 
                              <h2 class="bi bi-plus-lg text-secondary thumbimg"></h2>
                          </div> -->

                          <div class="banner-image-container pointer" [ngClass]="{ 'border-danger border-err-img': submitted && f?.primaryImg?.errors || submitted && !imageSrc }" *ngIf="!imageSrc"  (click)="uploadimg1Upload.click()">
                            <img src="/assets/images/icons/empty-img-primary.png" appImgFallback>
                          </div>
                          

                          <!-- <p class="text-center mb-0 pb-0" *ngIf="!imageSrc"><i> &nbsp;</i></p> -->
                            
                          <!-- <div class="text-center bg-white border rounded-3 thumbimgdiv" *ngIf="imageSrc" (click)="uploadimg1Upload.click()">
                              <img [src]="imageSrc" *ngIf="imageSrc" class="thumbimgdivinner" appImgFallback>
                          </div> -->

                          <div class="banner-image-container pointer" *ngIf="imageSrc" (click)="uploadimg1Upload.click()">
                            <img src="{{imageSrc}}" *ngIf="imageSrc" appImgFallback>
                          </div>

                          <p class="text-center mb-0 pb-0 thumbimgdivDelete pointer"  *ngIf="imageSrc">
                              <i class="bi bi-trash text-danger"  *ngIf="imageSrc" (click)="removePirmaryImg()"></i>
                          </p>

                      </div>
  
                      <div class="col-12 col-md-12 col-lg-12">
                        <div class="row g-0">
                            <ng-container  *ngFor="let MoreImg of MoreImgCount; let i = index">
                                <div class="col-4 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1 pointer">
                                    <!-- <div class="text-center bg-white border rounded-0  thumbimgdiv2empty" *ngIf="!imageSrcMore[i].val"  (click)="onclickMoreImg(i)">
                                      <p class="bi bi-plus-lg text-secondary thumbimgsec"></p>
                                    </div> -->

                                    <div class="banner-image-container secondaryimg pointer" *ngIf="!imageSrcMore[i].val"  (click)="onclickMoreImg(i)">
                                        <img class="d-block d-lg-none" src="/assets/images/icons/empty-img-primary.png" appImgFallback>
                                        <img class="d-none d-lg-block" src="/assets/images/icons/empty-img-secondary.png" appImgFallback>
                                    </div>

                                    <div class="banner-image-container secondaryimg pointer" *ngIf="imageSrcMore[i].val" (click)="MoreImgFileClick('MoreImgUpload'+i)">
                                        <img [src]="imageSrcMore[i].val" *ngIf="imageSrcMore[i].val" appImgFallback>
                                    </div>

                                    <!-- <div class="text-center bg-white border rounded-3 thumbimgdivMore col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1 pointer" *ngIf="imageSrcMore[i].val" (click)="MoreImgFileClick('MoreImgUpload'+i)">
                                        <img [src]="imageSrcMore[i].val" *ngIf="imageSrcMore[i].val" class="thumbimgdivinnerMore" appImgFallback>
                                    </div> -->
                                    <p class="text-center mb-0 pb-0 thumbimgdivDelete"  *ngIf="imageSrcMore[i].val">
                                        <i class="bi bi-trash text-danger"  *ngIf="imageSrcMore[i].val" (click)="MoreImgRemoveFile(i)"></i>
                                    </p>


                                  </div>

                                  <!-- <p class="text-center mb-0 pb-0" *ngIf="!imageSrcMore[i].val"><i> &nbsp;</i></p> -->
                            


                            </ng-container>

                        </div>
                      </div>
                      <!-- imageSrc :: {{ imageSrc | json }}<br>
                      imageSrcName :: {{ imageSrcName | json }}<br> -->
                      <div class="col-12">
                        <div *ngIf="submitted && f?.primaryImg?.errors || submitted && !imageSrc">
                            <p *ngIf="f?.primaryImg?.errors?.required || submitted && !imageSrc" class="text-danger imgnotif">Primary image is required</p>
                        </div>                              
                      </div>
                      <div class="col-12" *ngIf="errorUploadImg">
                        <div>
                            <p *ngIf="errorUploadImg" class="text-danger imgnotif">Image must less than 2Mb and image format only (jpeg/jpg/png/gif)</p>
                        </div>                              
                      </div>
                    </div>


                    <div class="row g-0 d-none "> 
                        <div class="form-group">
                            <label for="primaryImgUpload">File</label>
                            <input #uploadimg1Upload 
                                formControlName="primaryImgUpload"
                                id="primaryImgUpload" 
                                type="file" 
                                class="form-control"
                                (change)="onFileChangeUpload($event)"
                                [(ngModel)]="ValimageFileName"
                                [ngClass]="{ 'is-invalid': submitted && f?.primaryImg?.errors }"
                                >
                        </div>
                        
                        <div *ngIf="submitted && f?.primaryImg?.errors" class="invalid-feedback">
                            <div *ngIf="f?.primaryImg?.errors?.required">Images is required at least one</div>
                        </div>
                       
    
                        <div class="form-group" *ngFor="let MoreImg of MoreImgCount; let i = index">
                            <label for="MoreImgUpload">More File {{i}}</label>
                            <input #MoreImgUpload{{i}} 
                                id="MoreImgUpload{{i}}" 
                                type="file" 
                                class="form-control"
                                [(ngModel)]="ValimageMore[i]" [ngModelOptions]="{standalone: true}"
                                (change)="onFileChangeMoreUpload($event,i)" 
                                >
                        </div>
                    </div>
                        



                    <!-- <input #fileInput4 type="file" (change)="upload($event,0)">
                    <input #fileInput1 type="file" (change)="upload($event,1)">
                    <input #fileInput2 type="file" (change)="upload($event,2)">
                    <input #fileInput3 type="file" (change)="upload($event,3)">
                    
                    <a (click)="checkUpload()" class="btn btn-primary">submit</a> -->

                    <div>
                            <div style="color: #f0a331;font-size: 10px;font-style: italic;">
                                *Image required : .jpg, .jpeg, .gif, .png
                            </div>
                            <div style="color: #f0a331;font-size: 10px;font-style: italic;">
                                *Maximum size 2MB (600x600 pixels)
                            </div>
                    </div>
                </div>
                <div class="col-sm-8 px-sm-5 pt-3 pb-sm-3">
        
                    <div class="mb-3 row">
                        <label for="name" class="col-sm-4 col-form-label">Name <font class="text-danger">* </font> <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.name' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
                            <input type="text" [(ngModel)]="Valname" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                            <div *ngIf="submitted && f?.name?.errors" class="invalid-feedback">
                                <div *ngIf="f?.name?.errors?.required">Name is required</div>
                            </div>
                        </div>
                    </div>
        
                    <div class="mb-3 row">
                        <label for="brand" class="col-sm-4 col-form-label">Brand <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.brand' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
                            <input type="text" [(ngModel)]="Valbrand" formControlName="brand" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.brand?.errors }" />
                            <div *ngIf="submitted && f?.brand?.errors" class="invalid-feedback">
                                <div *ngIf="f?.brand?.errors?.required">Brand is required</div>
                            </div>
                        </div>
                    </div>
        
        
                    <div class="mb-3 row">
                        <label for="sku" class="col-sm-4 col-form-label">Your Product Code <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.sku' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
                            <input type="text" [(ngModel)]="Valsku" formControlName="sku" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.sku?.errors }" />
                            <div *ngIf="submitted && f?.sku?.errors" class="invalid-feedback">
                                <div *ngIf="f?.sku?.errors?.required">Product Code is required</div>
                            </div>
                        </div>
                    </div>
        
                    <div class="mb-3 row">
                        <label for="manufacturerSku" class="col-sm-4 col-form-label">Manf. Product Code <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.manf' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
                            <input type="text" [(ngModel)]="ValmanufacturerSku" formControlName="manufacturerSku" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.manufacturerSku?.errors }" />
                            <div *ngIf="submitted && f?.manufacturerSku?.errors" class="invalid-feedback">
                                <div *ngIf="f?.manufacturerSku?.errors?.required">Manf. Product Code SKU is required</div>
                            </div>
                        </div>
                    </div>
        
        
        
        
        
        
        
        
                    
                    <div class="mb-3 row d-none">
                        <label for="isActivatedByUser" class="col-sm-4 col-form-label">Publish</label>
                        <div class="col-sm-8">
                            <div class="form-check form-switch mt-1">
                                <input class="form-check-input" type="checkbox" id="isActivatedByUser"  [(ngModel)]="ValisActivatedByUser" (change)="onCheckActivatedByUser($event)" formControlName="isActivatedByUser">
                            </div>
                        </div>
                    </div>
        
                    <div class="mb-3 row">
                        <label for="tags" class="col-sm-4 col-form-label">Tags <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.tag_info' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
        
                            <ng-multiselect-dropdown id="TagsList"
                            [placeholder]="'Select Tag'"
                            [settings]="dropdownSettingsTag"
                            [data]="dropdownListTag"
                            [(ngModel)]="selectedItemsTag"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeselectAll($event)"
                            [ngModelOptions]="{standalone: true}"
                            >
                            </ng-multiselect-dropdown>
                            
                            <div class="form-check form-check-inline d-none" *ngFor="let tag of tags; let i = index">
                                <input (change)="onChangeTag(tag.id,$event)" class="form-check-input" type="checkbox" id="inlineCheckbox{{tag.id}}" value="{{tag.id}}" [checked]="(SelectedTags.indexOf(tag.id)>-1)" #inlineCheckbox{{tag.id}}>
                                <label class="form-check-label" for="inlineCheckbox{{tag.id}}">{{tag.tag}}</label>
                            </div>
        
                        </div>
                    </div>
                    
        
                    
        
                    <div class="mb-3 row d-none">
                        <label for="quotationInitialPriceOffer" class="col-sm-2 col-form-label">Initial Price Offer</label>
                        <div class="col-sm-10">
                            <input type="number" [(ngModel)]="ValquotationInitialPriceOffer" formControlName="quotationInitialPriceOffer" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.quotationInitialPriceOffer?.errors }" />
                            <div *ngIf="submitted && f?.quotationInitialPriceOffer?.errors" class="invalid-feedback">
                                <div *ngIf="f?.quotationInitialPriceOffer?.errors?.required">Initial Price Offer is required</div>
                            </div>
                        </div>
                    </div>
        
        
        
        
                    
        
        
        
        
        
        
                </div>
        


                <div class="col-sm-6 px-sm-5 mt-3 pb-sm-3">
                    <div class="row g-0">
                        <div class="col-auto">
                            <div class="form-check form-switch mb-sm-3">
                                <input class="form-check-input"  (change)="onCheckDisplayPrice($event)" type="checkbox" id="isDisplayPriceOnShopfront" [(ngModel)]="ValisDisplayPriceOnShopfront" formControlName="isDisplayPriceOnShopfront">
                                <label for="isDisplayPriceOnShopfront">Display price on product page </label>
                            </div>
                        </div>
                        <div class="col-auto ps-1">
                            <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.hide_price' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup>
                        </div>
                    </div>

                    <div class="row g-0">
                        <div class="col-auto">
                            <div class="form-check  form-switch mb-3">
                                <input class="form-check-input"  (change)="onCheckDisplayProduct($event)" type="checkbox" id="isDisplayProductOnShopfront" [(ngModel)]="ValisDisplayProductOnShopfront" formControlName="isDisplayProductOnShopfront">
                                <label for="isDisplayProductOnShopfront">Display Product on Shopfront</label>
                            </div>
                        </div>
                        <div class="col-auto ps-1">
                            <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.hide_product' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup>
                        </div>
                    </div>


                    <div class="mb-3 row">
                        <label for="currency" class="col-sm-4 col-form-label">Currency <font class="text-danger  pe-1">*</font> <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.currency' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        <div class="col-sm-8">
                            <!-- {{ valcurrency }} -->
                            <!-- <select class="form-select"  formControlName="currency" [(ngModel)]="valcurrency" >
                                <option value="USD">$ USD ( United States Dollars )</option>
                                <option value="IDR">Rp IDR ( Indonesia Rupiah )</option>
                            </select> -->


                            <input formControlName="currency"  id="typeahead-format" type="text" class="form-control placeholderRfq" [(ngModel)]="valcurrency" placeholder="Input Currency" [ngbTypeahead]="search" [resultFormatter]="formatter"  [editable]='false' [ngClass]="{ 'is-invalid': submitted && f?.currency?.errors }" (ngModelChange)="getCurrency($event)" />

                            <div *ngIf="submitted && f?.currency?.errors" class="invalid-feedback">
                                <div *ngIf="f?.currency?.errors?.required">Currency is required</div>
                            </div>

                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="price" class="col-sm-4 col-form-label">Price <font class="text-danger  pe-1">*</font> <sup><i class="bi bi-info-circle pointer" triggers="click" [autoClose]="'outside'" placement="top auto" ngbTooltip="{{'product.price_info' | translate}}"  container="body"></i></sup>  </label>

   

                        <div class="col-sm-8">
                            <input  class="form-control noscroll" [ngModelOptions]="{standalone: true}"
                             [ngAutonumeric]="thousandOption" [(ngModel)]="ValpricePrev" (format)="changevalprice($event)" (change)="changevalprice($event)" placeholder='' [ngClass]="{ 'is-invalid': submitted && f?.price?.errors || submitted &&  ispricezero }" />

                            <input type="text" [(ngModel)]="Valprice" min="1" formControlName="price" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.price?.errors }" />
                            <div *ngIf="submitted && f?.price?.errors" class="invalid-feedback">
                                <div *ngIf="f?.price?.errors?.required">Price is required</div>
                            </div>
                            <p class="iserror" [hidden]="!ispricenegative">Price cannot negative</p>
                            <p class="iserror" [hidden]="!ispricezero">Price cannot zero</p>

                        </div>
                        <div class="col-sm-12">
                            <!-- <p class="fhnotes mb-0"><i><b>Initial Price offer will be automatically displayed when this item is selected for Quotation.</b> (Pricing is NOT displayed on product pages).</i></p> -->
                            
                        </div>
                    </div>



                </div>

                <div class="col-sm-6 my-2 px-sm-5 pt-1 pb-sm-3">
                    <div class="p-3 p-sm-5" style="border:1px solid #dedede;border-radius: 5px;">
                        <div class="row g-0">
                            <div class="col-auto">
                                <div class="form-check form-switch mb-sm-3">
                                    <input class="form-check-input" type="checkbox" id="isAutobidEnabled"  [(ngModel)]="ValisAutobidEnabled" (change)="onCheckEnableAutobid($event)" formControlName="isAutobidEnabled">
                                    <label for="isAutobidEnabled">Enable Auto Bid</label>
                                </div>
                            </div>
                            <div class="col-auto ps-1">
                                <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.autobit_info' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup>
                            </div>
                        </div>
                        <!-- <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" id="isAutobidEnabled"  [(ngModel)]="ValisAutobidEnabled" (change)="onCheckEnableAutobid($event)" formControlName="isAutobidEnabled">
                            <label for="isAutobidEnabled">Enable Auto Bid <sup><i class="bi bi-info-circle pointer" triggers="click" [autoClose]="'outside'" placement="top auto" [ngbTooltip]="hintEnableAutobit" container="body" [openDelay]="300" [closeDelay]="1500"></i></sup></label>
                        </div>
                        <ng-template #hintEnableAutobit>
                            <div class="d-flex flex-column">
                                <span>{{'product.autobit_info' | translate}}</span>
                                <span><a href="/" target="_blank">{{'product.autobit_info_link' | translate}}</a></span>                                
                            </div>
                        </ng-template> -->
                        <!-- <p class="fhnotes"><i><b>Auto Bid : Subscriber Level 2 Only. Upgrade your subscription to be more competitive in the markets. </b><br>The Auto Bid feature allows you to provide the fastest response to customers looking for this product, as well as  automatically offering the best pricingto customer as possible.</i></p> -->

                        <div class="mb-3 row">
                            <label for="autobidMinimumPrice" class="col-sm-4 col-form-label">Min. Price <sup><i class="bi bi-info-circle pointer" triggers="click" [autoClose]="'outside'" placement="top auto" ngbTooltip="{{'product.minprice_info' | translate}}"  container="body"></i></sup></label>
                            <div class="col-sm-8">
                                <input *ngIf="!ValisAutobidEnabled" class="form-control" disabled>
                                <input *ngIf="ValisAutobidEnabled"  [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' class="form-control noscroll {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors || submitted && checkPriceDecCalculate || submitted && invalidMinPrice }" id="autobidMinimumPricePrev" 
                                [ngAutonumeric]="thousandOption" [(ngModel)]="ValautobidMinimumPricePrev" (keypress)="changeminprice($event)" (input)="changeminprice($event)" (format)="changeminprice($event)" (change)="changeminprice($event)" placeholder=''  formControlName="autobidMinimumPricePrev"  />
                                <!-- <input type="text"  [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' id="autobidMinimumPricePrev" [(ngModel)]="ValautobidMinimumPricePrev" (keypress)="changeminprice($event)" (input)="changeminprice($event)"  formControlName="autobidMinimumPricePrev" class="form-control {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors || checkPriceDecCalculate || invalidMinPrice }" maxlength="15" min="1" autocomplete="off" /> -->
                                <input id="autobidMinimumPrice" type="text" min="1" [(ngModel)]="ValautobidMinimumPrice" formControlName="autobidMinimumPrice" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors || checkPriceDecCalculate }" />
                                <div *ngIf="submitted && f?.autobidMinimumPrice?.errors" class="invalid-feedback">
                                    <div *ngIf="f?.autobidMinimumPrice?.errors?.required">Min. Price is required</div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="submitted">
                                <!-- <p class="fhnotes mb-0"><i><b>Minimus Price is the lowest price your are willing to offer.</b>(Therefore, if there are a lower priced competitor, you will not try to outbid them).</i></p> -->
                                <p class="iserror m-0" [hidden]="!checkPriceDecCalculate">Min price must be less than or equal to price.</p>

                                <p class="iserror m-0" [hidden]="!invalidMinPrice">Min price is required</p>
                            </div>
                        </div>


                        <div class="mb-3 row">
                            <label for="autobidPriceDecrement" class="col-sm-4 col-form-label">Discount Increments <sup><i class="bi bi-info-circle pointer" triggers="click" [autoClose]="'outside'" placement="top auto" ngbTooltip="{{'product.discount_inc_info' | translate}}"  container="body"></i></sup> </label>
                            <div class="col-sm-8">
                                <input *ngIf="!ValisAutobidEnabled" class="form-control" disabled>
                                <input *ngIf="ValisAutobidEnabled" [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' class="form-control noscroll {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors || submitted && checkDiscIncCalculate || submitted && invalidautobidPriceDecrement }"  id="autobidPriceDecrementPrev" 
                                [ngAutonumeric]="thousandOption" [(ngModel)]="ValautobidPriceDecrementPrev" (keypress)="changevalautobit($event)" (input)="changevalautobit($event)"  (format)="changevalautobit($event)" (change)="changevalautobit($event)"  placeholder=''  formControlName="autobidPriceDecrementPrev"  />

                                <!-- <input type="text" [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' id="autobidPriceDecrementPrev" [(ngModel)]="ValautobidPriceDecrementPrev" (keypress)="changevalautobit($event)" (input)="changevalautobit($event)"  formControlName="autobidPriceDecrementPrev" class="form-control {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors || checkDiscIncCalculate || invalidautobidPriceDecrement }" maxlength="15" min="1" autocomplete="off" /> -->
                                <input type="text" id="autobidPriceDecrement" min="1" [(ngModel)]="ValautobidPriceDecrement" formControlName="autobidPriceDecrement" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors || checkDiscIncCalculate }" />
                                <div *ngIf="submitted && f?.autobidPriceDecrement?.errors" class="invalid-feedback">
                                    <div *ngIf="f?.autobidPriceDecrement?.errors?.required">Discount Increments is required</div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="submitted">
                                <!-- <p class="fhnotes mb-0"><i><b>Discount Increments is how much discount you are willing to offer on each bid.</b> (The Price offer minus the Discount Increments must NOT be lower than the Minimum Price).</i></p> -->
                                <p class="iserror m-0" [hidden]="!checkDiscIncCalculate">Discount Increments must less then price.</p>

                                <p class="iserror m-0" [hidden]="!invalidautobidPriceDecrement">Discount Increments is required</p>
                            </div>
                        </div>




                    </div>

                </div>

        
                <!-- <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label for="currency" class="col-sm-4 col-form-label">Currency</label>
                        <div class="col-sm-8">
                            <select class="form-select bgcurr">
                                <option value="USD">$ USD ( United States Dollars )</option>
                                <option value="IDR">Rp IDR ( Indonesia Rupiah )</option>
                            </select>
        
        
        
        
                        </div>
                    </div>
                </div>
        
                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label for="price" class="col-sm-4 col-form-label">Price *</label>
                        <div class="col-sm-8">
                            <input type="text" id="priceprev" [(ngModel)]="ValpricePrev" (keypress)="changevalprice($event)" (input)="changevalprice($event)"  formControlName="priceprev" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.price?.errors }" maxlength="15" min="1" autocomplete="off"   />
                            <input type="text" [(ngModel)]="Valprice" min="1" formControlName="price" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.price?.errors }" />
                            <div *ngIf="submitted && f?.price?.errors" class="invalid-feedback">
                                <div *ngIf="f?.price?.errors?.required">Price is required</div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <p class="fhnotes mb-0"><i><b>Initial Price offer will be automatically displayed when this item is selected for Quotation.</b> (Pricing is NOT displayed on product pages).</i></p>
                            
                        </div>
                    </div>
                </div>
        
                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label for="autobidPriceDecrement" class="col-sm-4 col-form-label">Discount Increments</label>
                        <div class="col-sm-8">
                            <input type="text" [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' id="autobidPriceDecrementPrev" [(ngModel)]="ValautobidPriceDecrementPrev" (keypress)="changevalautobit($event)" (input)="changevalautobit($event)"  formControlName="autobidPriceDecrementPrev" class="form-control {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors || checkDiscIncCalculate }" maxlength="15" min="1" autocomplete="off" />
                            <input type="text" id="autobidPriceDecrement" min="1" [(ngModel)]="ValautobidPriceDecrement" formControlName="autobidPriceDecrement" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors || checkDiscIncCalculate }" />
                            <div *ngIf="submitted && f?.autobidPriceDecrement?.errors" class="invalid-feedback">
                                <div *ngIf="f?.autobidPriceDecrement?.errors?.required">Discount Increments is required</div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <p class="fhnotes mb-0"><i><b>Discount Increments is how much discount you are willing to offer on each bid.</b> (The Price offer minus the Discount Increments must NOT be lower than the Minimum Price).</i></p>
                            <p class="fhnotes text-danger" [hidden]="!checkDiscIncCalculate"><i><b>Discount Increments must less then price.</b></i></p>
                        </div>
                    </div>
                </div>
        
                <div class="col-sm-6">
                    <div class="mb-3 row">
                        <label for="autobidMinimumPrice" class="col-sm-4 col-form-label">Min. Price</label>
                        <div class="col-sm-8">
                            <input type="text"  [readonly]='!ValisAutobidEnabled' [disabled]='!ValisAutobidEnabled' id="autobidMinimumPricePrev" [(ngModel)]="ValautobidMinimumPricePrev" (keypress)="changeminprice($event)" (input)="changeminprice($event)"  formControlName="autobidMinimumPricePrev" class="form-control {{  !ValisAutobidEnabled ? 'isdisabled':'' }}" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors || checkPriceDecCalculate }" maxlength="15" min="1" autocomplete="off" />
                            <input id="autobidMinimumPrice" type="text" min="1" [(ngModel)]="ValautobidMinimumPrice" formControlName="autobidMinimumPrice" class="form-control d-none" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors || checkPriceDecCalculate }" />
                            <div *ngIf="submitted && f?.autobidMinimumPrice?.errors" class="invalid-feedback">
                                <div *ngIf="f?.autobidMinimumPrice?.errors?.required">Min. Price is required</div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <p class="fhnotes mb-0"><i><b>Minimus Price is the lowest price your are willing to offer.</b>(Therefore, if there are a lower priced competitor, you will not try to outbid them).</i></p>
                            <p class="fhnotes text-danger" [hidden]="!checkPriceDecCalculate"><i><b>Min price must be less than or equal to price.</b></i></p>
                        </div>
                    </div>
                </div>
        
        
        
                <div class="col-sm-12 mb-3 d-none">
                    <div class="form-check">
                        <input class="form-check-input"  (change)="onCheckDisplayProduct($event)" type="checkbox" id="isDisplayProductOnShopfront" [(ngModel)]="ValisDisplayProductOnShopfront" formControlName="isDisplayProductOnShopfront">
                        <label for="isDisplayProductOnShopfront">Display on Shopfront</label>
                    </div>
                </div>
                
        
        
        
                <div class="col-sm-6 mb-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="isAutobidEnabled"  [(ngModel)]="ValisAutobidEnabled" (change)="onCheckEnableAutobid($event)" formControlName="isAutobidEnabled">
                        <label for="isAutobidEnabled">Enable Auto Bid</label>
                    </div>
                    <p class="fhnotes"><i><b>Auto Bid : Subscriber Level 2 Only. Upgrade your subscription to be more competitive in the markets. </b><br>The Auto Bid feature allows you to provide the fastest response to customers looking for this product, as well as  automatically offering the best pricingto customer as possible.</i></p>
                </div>
        
                <div class="col-sm-6 mb-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input"  (change)="onCheckDisplayPrice($event)" type="checkbox" id="isDisplayPriceOnShopfront" [(ngModel)]="ValisDisplayPriceOnShopfront" formControlName="isDisplayPriceOnShopfront">
                        <label for="isDisplayPriceOnShopfront">Display price on product page</label>
                    </div>
                </div>
         -->
        
        
                <div class="col-sm-12 mb-3 px-sm-5 pt-1 pb-1">
        
                    <!-- <div class="mb-3 row">
                        <label for="description" class="col-sm-2 col-form-label">Description</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="Valdescription" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors }" />
                            <div *ngIf="submitted && f?.description?.errors" class="invalid-feedback">
                                <div *ngIf="f?.description?.errors?.required">Description is required</div>
                            </div>
                        </div>
                    </div> -->
        
        
        
                <label class="mb-3" for="description"><strong>Description <font class="text-danger">* </font> </strong> <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.description' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
        
                    <!-- <editor
                    formControlName="tinymce"
                    (onInit)="handleEditorInit($event)"
                    [init]="{
                        base_url: '/tinymce', 
                        suffix: '.min', 
                        plugins: 'wordcount lists advlist code',
                        toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code',
                        menubar: false,
                        min_height: 550
                    }"
                    [ngClass]="{ 'tinymceErr is-invalid': submitted && f.tinymce?.errors }"
                    [(ngModel)]="Valdescription"
                    ></editor>
                    <div *ngIf="submitted && f?.tinymce?.errors" class="invalid-feedback">
                        <div *ngIf="f?.tinymce?.errors?.required">body is required</div>
                    </div> -->
        
        
                    <textarea formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors }" rows="5"  [(ngModel)]="Valdescription"></textarea>
                    <div *ngIf="submitted && f?.description?.errors" class="invalid-feedback">
                        <div *ngIf="f?.description?.errors?.required">Description is required</div>
                    </div>
                </div>
        
        
                <div class="col-sm-12 mb-sm-5 pb-3 px-sm-5 pt-1">
                    <!-- <label class="mb-3" for="description"><strong>Description</strong></label> -->
                    <div class="row">
        
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <label>Label <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.label' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        </div>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <label>Value <sup><i class="bi bi-info-circle pointer" placement="top auto" ngbTooltip="{{'product.value' | translate}}"  container="body" triggers="click" [autoClose]="'outside'"></i></sup></label>
                        </div>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                        </div>
                    </div>
                    <div class="row mb-2"  *ngFor='let in of countarrFhSpec;let i = index'>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <input type="input" value="{{in.label}}" placeholder="Color" class="form-control" (input)="changeLabelSpec(in,$event)" [ngClass]="{ 'is-invalid': emptyLabelSpec[i].error }" maxlength="20" required>
                        </div>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <input type="input" value="{{in.value}}" placeholder="Black" class="form-control" (input)="changeValueSpec(in,$event)" [ngClass]="{ 'is-invalid': emptyValueSpec[i].error }" maxlength="50" required>
                        </div>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3"> 
                            <a class="btn btn-danger pointer text-center text-danger"  (click)="removeSpec(in)">
                                <i class="bi bi-x text-white"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <input type="input" class="form-control" placeholder="Color" [(ngModel)]="valspecLabel" [ngClass]="{ 'is-invalid': valspecLabelErr }" [ngModelOptions]="{standalone: true}" (input)="firstLabelSpec($event)"  maxlength="20">
                        </div>
                        <div class="col-4 col-lg-2 col-md-2 col-sm-3">
                            <input type="input" class="form-control" placeholder="Black" [(ngModel)]="valspecValue" [ngClass]="{ 'is-invalid': valspecValueErr }" [ngModelOptions]="{standalone: true}" (input)="firstValueSpec($event)" maxlength="50">
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-6">
        
                            <div class="row g-0">
                                <div class="col-auto">
                                    <a class="btn btn-danger pointer text-center text-danger me-2" *ngIf="ValSpecEmpty" (click)="clearSpec()">
                                        <i class="bi bi-x text-white"></i>
                                    </a>
                                </div>

                                <div class="col-auto d-none d-sm-block">
                                    <a class="btn btn-dark bg2 pointer"  (click)="addSpec()"> + Specs </a>
                                </div>
                                <div class="col-auto d-block d-sm-none">
                                    <a class="btn btn-dark bg2 pointer" style="padding:6px 15px !important" (click)="addSpec()"> + </a>
                                </div>

                            </div>


                            
                            
                        </div>
                    </div>
                </div>
        
                <div class="col-sm-12 mb-3 d-none">
                    {{ countarrFhSpec | json }}
                    <br>
                    
                    emptyLabelSpec :: {{ emptyLabelSpec | json }}
                    <br>
                    emptyValueSpec :: {{ emptyValueSpec | json }}
                </div>
        
            </div>


    
    
    

        </div>
        <!-- <div class="col-md-2 sticky-xl-top sticky-lg-bottom sticky-md-top  topmore">

            <button type="button" (click)="submitaction('draft')" class="btn btn-dark fhbgblack w-100 rounded-1 mb-2"  *ngIf="!pageloading">Save Draft</button>
            <button class="btn btn-dark fhbgblack w-100 rounded-1 mb-2" *ngIf="pageloading" disabled>Loading..</button>


            <button type="button" (click)="submitaction('preview')" class="btn btn-dark fhbgblack w-100 rounded-1 mb-2" *ngIf="!pageloading">Preview Shopfront</button>
            <button class="btn btn-dark fhbgblack w-100 rounded-1 mb-2" *ngIf="pageloading" disabled>Loading..</button>

            <button  type="button" (click)="submitaction('done')" class="btn btn-primary fhbgblue w-100 rounded-1 mb-2" *ngIf="!pageloading">Done</button>
            <button class="btn btn-primary fhbgblue w-100 rounded-1 mb-2" *ngIf="pageloading" disabled>Loading..</button>
            
            <a class="btn btn-danger fhbgred w-100 rounded-1 mb-2" href="/vendor/products">Cancel</a>


            <div class="mt-3 d-none" *ngIf="successResponse">
                <div class="alert alert-success alert-dismissible fade show" role="alert" >
                    <p class="mb-0" [innerHTML]="successResponseName"></p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>               
            </div>

        </div> -->




        <!-- <div class="col-sm-2"></div> -->
    </div>
        </div>
    </form>
</div>  



<div class="fixed-bottom">
    <div class="bg-white pt-3 pb-3 d-none d-sm-block">
      <div class="container">
        <div class="row">
          <div class="col-6">

            <button type="button" (click)="submitaction('draft')" class="btn btn-dark fhbtn3 rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4"  *ngIf="!pageloading">Save Draft</button>
            <button class="btn btn-dark fhbdark rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button>
  
            <a class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2 ps-4 pe-4" href="/vendor/product-list">Cancel</a>

  
          </div>
          <div class="col-6 text-end">

            <button  type="button" (click)="submitaction('done')" class="btn btn-primary fhbcyan rounded-1 roundedmore fixwbtn mb-2 ms-3 ps-4 pe-4" *ngIf="!pageloading">Done</button>
            <button class="btn btn-primary fhbcyan rounded-1 roundedmore fixwbtn mb-2 ms-3 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button>

            <button type="button" (click)="submitaction('preview')" class="btn btn-link btnlink rounded-1 roundedmore c1 mb-2 ms-3" *ngIf="!pageloading"><strong>Preview Shopfront</strong></button>
            <button class="btn btn-dark fhbgblack rounded-1 roundedmore  mb-2 ms-3" *ngIf="pageloading" disabled>Loading..</button>


  
            
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white pt-3 pb-3 d-block d-sm-none">
        <div class="container">
          <div class="row g-0">
            <div class="col-2">
                <div class="px-1">


                    <div ngbDropdown placement="top-right" class="d-inline-block mbtnmore w-100">
                        <a class="btn btn-link btnlinkmore rounded-1 roundedmore w-100 text-dark" ngbDropdownToggle>
                            <i class="bi bi-three-dots"></i>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <button class="fw-bold py-2" ngbDropdownItem ngbDropdownToggle><i class="bi bi-x-lg"></i> Lainnya</button>
                            <a class="py-2 c1 fw-bold" *ngIf="!pageloading" (click)="submitaction('draft')" ngbDropdownItem>Save Draft</a>
                            <a class="py-2 c1 fw-bold disabled" disabled *ngIf="pageloading" ngbDropdownItem>Loading..</a>
                            <hr class="m-0">
                            <a class=" text-danger fw-bold py-2" href="/vendor/product-list" ngbDropdownItem>Cancel</a>
                        </div>
                    </div>

                </div>


                <!-- <button type="button" (click)="submitaction('draft')" class="btn btn-dark fhbtn3 rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4"  *ngIf="!pageloading">Save Draft</button>
                <button class="btn btn-dark fhbdark rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button>
      
                <a class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2 ps-4 pe-4" href="/vendor/product-list">Cancel</a> -->
            </div>
            <div class="col-5">
            <div class="px-1">
                <button  type="button" (click)="submitaction('done')" class="btn btn-primary fhbcyan mbtndone rounded-1 roundedmore w-100 ps-4 pe-4" *ngIf="!pageloading">Done</button>
                <button class="btn btn-primary fhbcyan mbtndone rounded-1 roundedmore w-100 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button>
            </div>


            </div>
            <div class="col-5">
            <div class="px-1">
                <button type="button" (click)="submitaction('preview')" class="btn btn-link btnlink rounded-1 roundedmore w-100 c1" *ngIf="!pageloading"><strong>Preview Shopfront</strong></button>
                <button class="btn btn-dark fhbgblack rounded-1 roundedmore w-100" *ngIf="pageloading" disabled>Loading..</button>
            </div>

  
            </div>
          </div>
        </div>
    </div>

  </div>
  
  


<!-- Modal -->
<!-- <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydraft}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="draftimgicon" src="/assets/images/icons/draft-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully<br>saved as a draft!</p>
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <a href="/vendor/product/detail/{{ EditProductId }}" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5">PREVIEW</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div> -->

<!-- Modal -->
<!-- <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydone}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1" *ngIf="!(PrevEditProductId)">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold"  *ngIf="!(PrevEditProductId)">Your data has been successfully saved!</p>
          
          <h3 class="text-center mt-3 mb-2 f1" *ngIf="(PrevEditProductId)">Changes Saved Successfully!</h3>
          <p class="text-center mt-0 mb-4 fw-bold" *ngIf="(PrevEditProductId)">To Publish the changes, a Manager Approval is needed!</p>

              
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 " *ngIf="currentstate!=='UNPUBLISHED'">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/product/detail/{{EditProductId}}" data-bs-dismiss="modal" *ngIf="!(PrevEditProductId)">DONE</a>
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/product/compare/{{EditProductId}}" data-bs-dismiss="modal" *ngIf="(PrevEditProductId)">DONE</a>
          </div>

          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 " *ngIf="currentstate=='UNPUBLISHED'">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/product/detail/{{EditProductId}}" data-bs-dismiss="modal">DONE</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div> -->

<div class="container d-none">         
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 mt-3"></div>
            <div class="col-sm-6">
                <h3 class="pt-3 mb-0"><i class="bi bi-plus-circle"></i> Add Product</h3> 
            </div>
            <div class="col-sm-6">
                <p class="pt-3 mb-0 text-end"><a href="/vendor/product-list" class="btn  pull-right"><i class="bi bi-x-lg"></i></a></p>
            </div>
            <div class="col-sm-12">
                <hr>
            </div>
            <div class="col-sm-12">

                <div class="mb-3 row">
                    <label for="name" class="col-sm-2 col-form-label">Product Name</label>
                    <div class="col-sm-10">
                        <input type="text" [(ngModel)]="Valname" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                        <div *ngIf="submitted && f?.name?.errors" class="invalid-feedback">
                            <div *ngIf="f?.name?.errors?.required">Product name is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="brand" class="col-sm-2 col-form-label">Brand</label>
                    <div class="col-sm-10">
                        <input type="text" [(ngModel)]="Valbrand" formControlName="brand" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.brand?.errors }" />
                        <div *ngIf="submitted && f?.brand?.errors" class="invalid-feedback">
                            <div *ngIf="f?.brand?.errors?.required">Brand is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="description" class="col-sm-2 col-form-label">Description</label>
                    <div class="col-sm-10">
                        <input type="text" [(ngModel)]="Valdescription" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors }" />
                        <div *ngIf="submitted && f?.description?.errors" class="invalid-feedback">
                            <div *ngIf="f?.description?.errors?.required">Description is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="sku" class="col-sm-2 col-form-label">SKU</label>
                    <div class="col-sm-10">
                        <input type="text" [(ngModel)]="Valsku" formControlName="sku" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.sku?.errors }" />
                        <div *ngIf="submitted && f?.sku?.errors" class="invalid-feedback">
                            <div *ngIf="f?.sku?.errors?.required">SKU is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="manufacturerSku" class="col-sm-2 col-form-label">Manufacturer SKU</label>
                    <div class="col-sm-10">
                        <input type="text" [(ngModel)]="ValmanufacturerSku" formControlName="manufacturerSku" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.manufacturerSku?.errors }" />
                        <div *ngIf="submitted && f?.manufacturerSku?.errors" class="invalid-feedback">
                            <div *ngIf="f?.manufacturerSku?.errors?.required">Manufacturer SKU is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="price" class="col-sm-2 col-form-label">Price</label>
                    <div class="col-sm-10">
                        <input type="number" [(ngModel)]="Valprice" formControlName="price" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.price?.errors }" />
                        <div *ngIf="submitted && f?.price?.errors" class="invalid-feedback">
                            <div *ngIf="f?.price?.errors?.required">Price is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="imageFileName" class="col-sm-2 col-form-label">Images</label>
                    <div class="col-sm-10">
                        <!-- <input type="text" [(ngModel)]="ValimageFileName" formControlName="imageFileName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.imageFileName?.errors }" />
                        <div *ngIf="submitted && f?.imageFileName?.errors" class="invalid-feedback">
                            <div *ngIf="f?.imageFileName?.errors?.required">Images is required</div>
                        </div> -->

                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="text-center bg-white border rounded-3  thumbimgdiv" [ngClass]="{ 'border-danger': submitted && f?.primaryImg?.errors }" *ngIf="!imageSrc"  (click)="uploadimg1.click()">
                                    <h2 class="bi bi-plus-circle text-secondary thumbimg"></h2>
                                </div>
                                <p class="text-center mb-0 pb-0" *ngIf="!imageSrc"><i> &nbsp;</i></p>
                                
                                <div class="text-center bg-white border rounded-3 thumbimgdiv" *ngIf="imageSrc" (click)="uploadimg1.click()">
                                    <img [src]="imageSrc" *ngIf="imageSrc" class="thumbimgdivinner">
                                </div>
                                <p class="text-center mb-0 pb-0 thumbimgdivDelete"  *ngIf="imageSrc">
                                    <i class="bi bi-trash text-danger"  *ngIf="imageSrc" (click)="removePirmaryImg()"></i>
                                </p>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let MoreImg of MoreImgCount; let i = index">

                                <div class="text-center bg-white border rounded-3  thumbimgdiv" *ngIf="!imageSrcMore[i].val"  (click)="MoreImgFileClick('MoreImg'+i)">
                                    <h2 class="bi bi-plus-circle text-secondary thumbimg"></h2>
                                </div>
                                <p class="text-center mb-0 pb-0" *ngIf="!imageSrcMore[i].val"><i> &nbsp;</i></p>
                                
                                <div class="text-center bg-white border rounded-3 thumbimgdiv" *ngIf="imageSrcMore[i].val" (click)="MoreImgFileClick('MoreImg'+i)">
                                    <img [src]="imageSrcMore[i].val" *ngIf="imageSrcMore[i].val" class="thumbimgdivinner">
                                </div>
                                <p class="text-center mb-0 pb-0 thumbimgdivDelete"  *ngIf="imageSrcMore[i].val">
                                    <i class="bi bi-trash text-danger"  *ngIf="imageSrcMore[i].val" (click)="MoreImgRemoveFile(i)"></i>
                                </p>
                            </div>

                            <div class="col-12">
                                <div *ngIf="submitted && f?.primaryImg?.errors">
                                    <p *ngIf="f?.primaryImg?.errors?.required" class="text-danger imgnotif">Primary image is required</p>
                                </div>                              
                            </div>
                        </div>


                        
                        <div class="form-group d-none">
                            <label for="primaryImg">File</label>
                            <input #uploadimg1 
                                formControlName="primaryImg"
                                id="primaryImg" 
                                type="file" 
                                class="form-control"
                                (change)="onFileChange($event)"
                                [(ngModel)]="ValimageFileName"
                                [ngClass]="{ 'is-invalid': submitted && f?.primaryImg?.errors }"
                                >
                        </div>
                        
                        <!-- <div *ngIf="submitted && f?.primaryImg?.errors" class="invalid-feedback">
                            <div *ngIf="f?.primaryImg?.errors?.required">Images is required at least one</div>
                        </div> -->
                       

                        <div class="form-group d-none" *ngFor="let MoreImg of MoreImgCount; let i = index">
                            <label for="MoreImg">More File {{i}}</label>
                            <input #MoreImg{{i}} 
                                id="MoreImg{{i}}" 
                                type="file" 
                                class="form-control"
                                [(ngModel)]="ValimageMore[i]" [ngModelOptions]="{standalone: true}"
                                (change)="onFileChangeMore($event,i)" 
                                >
                        </div>

                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="isDisplayPriceOnShopfront" class="col-sm-2 col-form-label">Display Price</label>
                    <div class="col-sm-10">
                        <div class="form-check form-switch mt-1">
                            <input class="form-check-input"  (change)="onCheckDisplayPrice($event)" type="checkbox" id="isDisplayPriceOnShopfront" [(ngModel)]="ValisDisplayPriceOnShopfront" formControlName="isDisplayPriceOnShopfront">
                        </div>
                    </div>
                </div>


                <div class="mb-3 row">
                    <label for="isDisplayProductOnShopfront" class="col-sm-2 col-form-label">Display on Shopfront</label>
                    <div class="col-sm-10">
                        <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="isDisplayProductOnShopfront"  [(ngModel)]="ValisDisplayProductOnShopfront" (change)="onCheckDisplayProduct($event)" formControlName="isDisplayProductOnShopfront">
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="isActivatedByUser" class="col-sm-2 col-form-label">Publish</label>
                    <div class="col-sm-10">
                        <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="isActivatedByUser"  [(ngModel)]="ValisActivatedByUser" (change)="onCheckActivatedByUser($event)" formControlName="isActivatedByUser">
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="tags" class="col-sm-2 col-form-label">Tags</label>
                    <div class="col-sm-10">

                        <div class="form-check form-check-inline" *ngFor="let tag of tags; let i = index">
                            <input (change)="onChangeTag(tag.id,$event)" class="form-check-input" type="checkbox" id="inlineCheckbox{{tag.id}}" value="{{tag.id}}" #inlineCheckbox{{tag.id}}>
                            <label class="form-check-label" for="inlineCheckbox{{tag.id}}">{{tag.tag}}</label>
                        </div>

                    </div>
                </div>

                <div class="mb-3 row">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                </div>




                <div class="mb-3 row">
                    <label for="quotationInitialPriceOffer" class="col-sm-2 col-form-label">Initial Price Offer</label>
                    <div class="col-sm-10">
                        <input type="number" [(ngModel)]="ValquotationInitialPriceOffer" formControlName="quotationInitialPriceOffer" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.quotationInitialPriceOffer?.errors }" />
                        <div *ngIf="submitted && f?.quotationInitialPriceOffer?.errors" class="invalid-feedback">
                            <div *ngIf="f?.quotationInitialPriceOffer?.errors?.required">Initial Price Offer is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="autobidMinimumPrice" class="col-sm-2 col-form-label">Minimum Price</label>
                    <div class="col-sm-10">
                        <input type="number" [(ngModel)]="ValautobidMinimumPrice" formControlName="autobidMinimumPrice" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.autobidMinimumPrice?.errors }" />
                        <div *ngIf="submitted && f?.autobidMinimumPrice?.errors" class="invalid-feedback">
                            <div *ngIf="f?.autobidMinimumPrice?.errors?.required">Minimum Price is required</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="autobidPriceDecrement" class="col-sm-2 col-form-label">Price Decrement</label>
                    <div class="col-sm-10">
                        <input type="number" [(ngModel)]="ValautobidPriceDecrement" formControlName="autobidPriceDecrement" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.autobidPriceDecrement?.errors }" />
                        <div *ngIf="submitted && f?.autobidPriceDecrement?.errors" class="invalid-feedback">
                            <div *ngIf="f?.autobidPriceDecrement?.errors?.required">Price decrement is required</div>
                        </div>
                    </div>
                </div>
                

                <div class="mb-3 row">
                    <label for="isAutobidEnabled" class="col-sm-2 col-form-label">Enable Autobid</label>
                    <div class="col-sm-10">
                        <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="isAutobidEnabled"  [(ngModel)]="ValisAutobidEnabled" (change)="onCheckEnableAutobid($event)" formControlName="isAutobidEnabled">
                        </div>
                    </div>
                </div>


            </div>


            <div class="col-sm-12" *ngIf="successResponse">
                <div class="alert alert-success alert-dismissible fade show" role="alert" >
                    <p class="mb-0" [innerHTML]="successResponseName"></p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>               
            </div>

            <div class="col-6">
                <div class="mb-3 text-start">
                    
                </div>
            </div>
            <div class="col-6">   
                <div class="mb-3 text-end ">
                    <a href="/vendor/products" class="btn btn-outline-secondary pull-left me-3  ps-5 pe-5">Back</a>
                    <button *ngIf="!pageloading" class="btn btn-secondary ps-5 pe-5">Save</button>
                    <button *ngIf="pageloading" class="btn btn-secondary ps-5 pe-5" disabled>Loading..</button>

                </div>
            </div>



            <div class="col-sm-12">
                <div class="pb-5">
                </div>
            </div>
        </div>
    </form>  
</div>

    <!-- errorcurrency Modal -->
    <div class="modal" id="errorcurrency" tabindex="-1" role="dialog" [ngStyle]="{'display':errorcurrency}">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content rounded-0">
            <div class="modal-body pt-4 pb-4 ps-4 pe-4">
              <div class="text-center">
                <img class="saveimgicon" src="/assets/images/error.png">
              </div>
              <h3 class="text-center mt-3 mb-2 f1">Currency not set!</h3>
              <p class="text-center mt-0 mb-4 fw-bold">Please set default currency in company setting.</p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
                <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/setting/company-detail">Setting</a>
              </div>
            </div>
          </div>
        </div>
    </div>


    <!-- Error Modal -->
    <div class="modal" id="errorModal" tabindex="-1" role="dialog" [ngStyle]="{'display':errornotif}">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content rounded-0">
            <div class="modal-body pt-4 pb-4 ps-4 pe-4">
              <div class="text-center">
                <img class="saveimgicon" src="/assets/images/error.png">
              </div>
              <h3 class="text-center mt-3 mb-2 f1">Error!</h3>
              <p class="text-center mt-0 mb-4 fw-bold">{{errortext}}</p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
                <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="closeErrorModal()">CLOSE</a>
              </div>
            </div>
          </div>
        </div>
    </div>


    
<router-outlet></router-outlet>
<ng-container *ngIf="!cookie">
    <div class="w-100 {{modalCookie?'d-none':'d-block'}}">
        <div class="sticky-bottom w-100 p-3 mb-3 divCookie">
            <div class="boxCookie p-3 my-2 mx-2">
                <div class="row g-0" style="display: flex;align-items: center;">
                    <!-- <div class="col-12 col-md-7 col-lg-8">
                        <p class="mb-0">
                            By clicking “Accept all cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. Please refer to our <a class="text-decoration-underline pointer" href="#">Cookie Policy</a>.
                        </p>
                    </div>
                    <div class="col-12 col-md-5 col-lg-4">
                        <div class="row g-0 py-2">
                            <div class="col-12 col-md-6 col-lg-7 text-center text-md-end order-2 order-md-1">
                                <a class="btn btn-sm btnCookie" (click)="cookiesSetting()">Manage cookies</a>
                            </div>
                            <div class="col-12 col-md-6 col-lg-5 text-center text-md-end order-1 order-md-2">
                                <a class="btn btn-sm btn-dark text-white btnCookie" (click)="accpetAll()">Accept all cookies</a>  
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12 col-md-9 col-lg-10">
                        <p class="mb-0">
                            By clicking “Accept all cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. Please refer to our <a class="text-decoration-underline pointer" (click)="ShowCookiePolicy()">Terms and Conditions</a>.
                        </p>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2">
                        <div class="row g-0 py-2">
                            <div class="col-12 text-center text-md-end">
                                <a class="btn btn-sm btn-dark text-white btnCookie" (click)="accpetAll()">Accept all cookies</a>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <ng-template #cookieModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Cookie Settings</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="cookieForm" (ngSubmit)="onSubmit()">  
            <p class="textCookie">When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>


  
            <div>

                <div class="row g-0 mb-3">
                    <div class="col-11">
                        <h6 class="mb-0 pointer" (click)="isCollapsed0 = ! isCollapsed0">
                            Strictly Necessary <sup><i class="bi bi-question-circle-fill"></i></sup>
                        </h6>
                    </div>
                    <div class="col-1 text-end">
                        <div class="form-check form-switch">
                            <input class="form-check-input disabled" type="checkbox" id="flexSwitchCheckCheckedDisabled" checked disabled>
                        </div>
                    </div>
                    <div class="col-12">
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed0">
                            <p class="textCookie mb-0">
                                These cookies are necessary for our website to function properly and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms or where they’re essential to provide you with a service you have requested. You cannot opt-out of these cookies. You can set your browser to block or alert you about these cookies, but if you do, some parts of the site will not then work. These cookies do not store any personally identifiable information.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row g-0 mb-3">
                    <div class="col-11">
                        <h6 class="mb-0 pointer" (click)="isCollapsed1 = ! isCollapsed1">
                            Performance Cookies <sup><i class="bi bi-question-circle-fill"></i></sup>
                        </h6>
                    </div>
                    <div class="col-1 text-end">
                        <div class="form-check form-switch">
                            <input class="form-check-input" (change)="onCheckPerformanceCookies($event)" type="checkbox" id="PerformanceCookies" [(ngModel)]="ValPerformanceCookies" formControlName="PerformanceCookies">
                        </div>
                    </div>
                    <div class="col-12">
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed1">
                            <p class="textCookie mb-0">
                                These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site, which helps us optimize your experience. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not be able to use your data in this way.<br>
                                <a href="#">Cookie details</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row g-0 mb-3">
                    <div class="col-11">
                        <h6 class="mb-0 pointer" (click)="isCollapsed2 = ! isCollapsed2">
                            Functional Cookies <sup><i class="bi bi-question-circle-fill"></i></sup>
                        </h6>
                    </div>
                    <div class="col-1 text-end">
                        <div class="form-check form-switch">
                            <input class="form-check-input" (change)="onCheckFunctionalCookies($event)" type="checkbox" id="FunctionalCookies" [(ngModel)]="ValFunctionalCookies" formControlName="FunctionalCookies">
                        </div>
                    </div>
                    <div class="col-12">
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
                            <p class="textCookie mb-0">
                                These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly. <br>
                                <a href="#">Cookie details</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row g-0 mb-3">
                    <div class="col-11">
                        <h6 class="mb-0 pointer" (click)="isCollapsed3 = ! isCollapsed3">
                            Targeting Cookies <sup><i class="bi bi-question-circle-fill"></i></sup>
                        </h6>
                    </div>
                    <div class="col-1 text-end">
                        <div class="form-check form-switch">
                            <input class="form-check-input" (change)="onCheckTargetingCookies($event)" type="checkbox" id="TargetingCookies" [(ngModel)]="ValTargetingCookies" formControlName="TargetingCookies">
                        </div>
                    </div>
                    <div class="col-12">
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed3">
                            <p class="textCookie mb-0">
                                These cookies are used to make advertising messages more relevant to you and may be set through our site by us or by our advertising partners. They may be used to build a profile of your interests and show you relevant advertising on our site or on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device.<br>
                                <a href="#">Cookie details</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <!-- ValPerformanceCookies : {{ValPerformanceCookies}}<br>
            ValFunctionalCookies : {{ValFunctionalCookies}}<br>
            ValTargetingCookies : {{ValTargetingCookies}}<br> -->
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary fhbcyan pointer" (click)="c('Save click')">Confirm My Choices</button>
        </div>
    </ng-template>
</ng-container>


<ng-container *ngIf="modalUpdateTos">
    <ng-template #tosModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h6 class="modal-title text-center" id="modal-basic-title"><b>Please review and agree to our updated term, condition, and privacy policy to use this service.</b></h6>
            <!-- <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button> -->
        </div>
        <div class="modal-body">
            <!-- <object *ngIf="tac" 
            [data]="getSafeUrl(tac.description)"
            type="application/pdf"
            width="100%"
            height="400px">
            </object> -->

            <div  *ngIf="TnCsrc" >
                <pdf-viewer 
                [src]="TnCsrc" 
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                style="width: 100%; height: 400px;"
                >
                </pdf-viewer>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary fhbcyan pointer" (click)="c('Save click')">
                {{clickCookiePolicy?'Close':'Agree'}}
            </button>
        </div>
    </ng-template>

</ng-container>
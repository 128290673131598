
<div class="bghome">
  <div class="container pt-3 pb-3">
    <div class="row m-lg-5 ps-lg-5 pe-lg-5"> <!--  m-5 ps-5 pe-5 -->
      <div class="col-sm-12 ps-5 pe-5 pb-3 pt-3">
        <div class="box row p-1 pb-4 pt-4 m-3">
          <div class="col-sm-3 text-center">
            <img src="/assets/images/icons/Avatar local man.png" class="imgAvatar">
          </div>
          <div class="col-sm-9">
            <div class="card boxDetail">
              <div class="card-header bgV text-center">
                Vendor :
              </div>
              <div class="card-body p-4">
                <p class="card-text bgVtext">Cepetdapet mencarikan customer untuk anda tanpa komisi !!</p>
                <div class="{{(isMobile)?'text-center':'text-end'}}">
                  <a (click)="scroll(vendor)" class="btn btn-primary  ctaBtn">Click Here to Learn More</a>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 ps-5 pe-5 pb-3 pt-3">
        <div class="box row p-1 pb-4 pt-4 m-3">
          <div class="col-sm-9 order-2 order-sm-1 order-md-1 order-lg-1">
            <div class="card boxDetail">
              <div class="card-header bgP text-center">
                Procurement :
              </div>
              <div class="card-body p-4">
                <p class="card-text bgPtext">Cepetdapet mencarikan vendor yang menyediakan produk yang anda cari</p>
                <div class="{{(isMobile)?'text-center':'text-end'}}">
                  <a (click)="scroll(procurement)" class="btn btn-primary ctaBtn">Click Here to Learn More</a>
                </div>


                <div class="input-group mb-0 mt-5">
                  <input type="text" class="form-control mb-0" placeholder="Cari apa?" aria-label="Cari apa?" aria-describedby="button-addon2" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}">
                  <button class="btn btn-outline-secondary ctaBtn" type="button" id="button-addon2" (click)="globalSearch()"><i class="bi bi-search text-white"></i></button>
                </div>
                <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>

              </div>
            </div>
          </div>
          <div class="col-sm-3 text-center order-1 order-sm-2 order-md-2  order-lg-2">
            <img src="/assets/images/icons/Avatar local woman.png" class="imgAvatar mt-md-5 mt-lg-5">
          </div>

        </div>
      </div>
      <div class="col-sm-12 text-center">
        <p class="mt-5 mb-5"><b>Scroll down to learn more</b></p>
      </div>
    </div>
  </div>
</div>


<div id="vendor-section" class="divsection pb-4 pt-3" #vendor>
  <div class="row g-0">
    <!-- <div class="col-sm-5">
      <div class="leftSide parentTopLeft w-100">
        <div class="">
          <div class="">
            <div class="DivTopLeft">
              <p class="mb-0">Apakah Cepetdapet adalah Market Place ?</p>

              <p class="mb-0">Apa bedanya ?</p>  
            </div>

          </div>

          
        </div>
        <div class="DivImgTopLeft">
          <img src="/assets/images/icons/amico@2x.png" class="imgTopLeft">
        </div>
        
      </div>
    </div>
    <div class="col-sm-7">
      <div class="row g-0">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <div class="text-end">
            <div class="DivTopRight">
              <p class="m-0 p-2">
                Vendors, Manfaat dan layanan <img src="/assets/images/icons/Avatar local man.png" class="imgIcon ms-3">
              </p>
              </div>
          </div>
        </div>

 
        <div class="col-sm-12">
          <div class="pt-3 pe-5 ps-5 pb-3 row g-0">
            <div class="col-sm-12">
              <div class="p-2">
                <div class="whiteBox">
                  <p class="whiteTitle">Bagaimana Cepetdapet mencarikan customer untuk anda ?</p>
                  <p class="wihteDetail">Cepetdapet memakai AI untuk mencocokan produk yang anda jual terhadap RFQ (Request for Quotation) yang di publikasikan oleh Customer (Procurement)</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="p-2">
                <div class="whiteBox">
                  <p class="whiteTitle">Kenapa Vendor menggunakan Cepetdapet ?</p>
                  <p class="wihteDetail">Selain lebih cepat dan mudah menemukan customer, kami juga menawarkan fitur Autobid, Market Intelligence, RFQ Matching System yang akan memudahkan transaksi anda</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="p-2">
                <div class="whiteBox">
                  <p class="whiteTitle">Kenapa Procurement menggunakan Cepetdapet ?</p>
                  <p class="wihteDetail">Dengan Cepetdapet, Procurement lebih mudah dan cepat untuk menerima penawaran dari Vendor seperti anda</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div> -->


    <div class="col-sm-5 order-2 order-sm-1 order-md-1 order-lg-1">
      <div class="leftSide parentTopLeft {{(isMobile)?'w-75':'w-100'}}">
        <div class="">
          <div class="mt-4 mt-sm-0 mt-md-0 mt-lg-0 mb-4 mb-sm-0 mb-md-0 mb-lg-0">
            <div class="DivTopLeft">
              <p class="mb-0">Apakah Cepetdapet adalah Market Place ?</p>

              <p class="mb-0">Apa bedanya ?</p>  
            </div>

          </div>

          
        </div>
        
      </div>
    </div>
    <div class="col-sm-7 order-1 order-sm-2 order-md-2 order-lg-2">
      <div class="row g-0">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <div class="{{(isMobile)?'ms-5':''}} text-end">
            <div class="DivTopRight">
              <p class="m-0 p-2">
                Vendors, Manfaat dan layanan <img src="/assets/images/icons/Avatar local man.png" class="imgIcon ms-0 ms-sm-3 ms-md-3 ms-lg-3">
              </p>
              </div>
          </div>
        </div>

      </div>

    </div>


    <div class="col-sm-12  order-3 order-sm-3 order-md-3 order-lg-3">
      <div class="container">
        <div class="row g-0">
          <div class="col-sm-5">
            <div class="row g-0">
              <div class="col-12 order-2 order-sm-1 order-md-1 order-lg-1">
                <div class="text-center">
                  <img src="/assets/images/icons/amico@2x.png" class="imgTopLeft mt-3 mb-3
                  ps-5 pe-5 ps-lg-0 pe-lg-0 ps-md-0 pe-md-0">
                </div>
              </div>
              <div class="col-12 order-1 order-sm-2 order-md-2 order-lg-2">
                <div class="DivBtnLeft 
                p-0 ps-3 pe-3 mt-0
                p-sm-5 ps-sm-3 pe-sm-3 mt-sm-5
                p-md-5 ps-md-3 pe-md-3 mt-md-5
                p-lg-5 ps-lg-3 pe-lg-3 mt-lg-5
                ">
                  <p class="mb-3 whiteTitle"><b>Cepetdapet bukanlah Market Place.</b></p>
                  <p>Produk yang Anda jual adalah milik Anda. Kami tidak menerima komisi dari transaksi Anda.
                    Dengan begitu, baik Vendor dan Customer dapat melakukan transaksi di harga terbaik.</p>
                </div>
              </div>
            </div>


            
      
            
          </div>
          <div class="col-sm-7">
            <div class="row g-0 align-items-center">
      
              <div class="col-sm-12">
                <div class="
                pt-0 pe-0 ps-0 pb-0 
                pt-md-3 pe-md-5 ps-md-5 pb-md-3
                pt-lg-3 pe-lg-5 ps-lg-5 pb-lg-3
                row g-0">
                  <div class="col-sm-12">
                    <div class="p-2">
                      <div class="whiteBox">
                        <p class="whiteTitle">Bagaimana Cepetdapet mencarikan customer untuk anda ?</p>
                        <p class="wihteDetail">Cepetdapet memakai AI untuk mencocokan produk yang anda jual terhadap RFQ (Request for Quotation) yang di publikasikan oleh Customer (Procurement)</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="p-2">
                      <div class="whiteBox">
                        <p class="whiteTitle">Kenapa Vendor menggunakan Cepetdapet ?</p>
                        <p class="wihteDetail">Selain lebih cepat dan mudah menemukan customer, kami juga menawarkan fitur Autobid, Market Intelligence, RFQ Matching System yang akan memudahkan transaksi anda</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="p-2">
                      <div class="whiteBox">
                        <p class="whiteTitle">Kenapa Procurement menggunakan Cepetdapet ?</p>
                        <p class="wihteDetail">Dengan Cepetdapet, Procurement lebih mudah dan cepat untuk menerima penawaran dari Vendor seperti anda</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
              <div class="DivBtnRight p-2 text-center">
                <img src="/assets/images/icons/vendor-hmpage.png" class="img-fluid vndrimg">
              </div>
            </div>
      
          </div>
        </div>
      </div>
    </div>




  </div>
</div>



<div id="procurement-section" class="divsection pt-5 pb-5" #procurement>
  <div class="row g-0">
    <div class="col-sm-7">
      <div class="row g-0">
        <div class="col-sm-9">
          <div class="">
            <div class="DivTopRightProc">
              <p class="m-0 p-2">
                <img src="/assets/images/icons/Avatar local woman.png" class="imgIcon pe-3"> Procurements, Manfaat dan Layanan 
              </p>
              </div>
          </div>
        </div>


      </div>

    </div>

    <div class="col-sm-5">
      <div class="leftSide parentTopLeft w-100">
        <div class="">
          <div class="row g-0">
            <div class="col-sm-2"></div>
            <div class="col-sm-10 {{(isMobile)?'ps-5':''}} ">
              <div class="DivTopLeftProc text-end">
                <p class="mb-1 mt-1">Apakah  keuntungan menggunakan Cepetdapet ?</p>
              </div>
            </div>


          </div>

          
        </div>



        <!-- <div class="DivImgTopLeft text-center">
          <img src="/assets/images/icons/proc-img-homepage.png" class="imgTopLeft">
        </div> -->
        
      </div>
    </div>

    <div class="col-sm-12">
      <div class="container">
        <div class="row g-0">
          <div class="col-sm-6  
          pt-2 pe-2 ps-2 pb-2 
          pt-md-5 pe-md-5 ps-md-5 pb-md-2
          pt-lg-5 pe-lg-5 ps-lg-5 pb-lg-2
          order-2 order-sm-1 order-md-1 order-lg-1">



          <div class="DivBtnLeft p-3">
            <p class="mb-3 whiteTitle"><b>Cepetdapet menghemat waktu anda dalam mencari Vendor.</b></p>
            <p class="wihteDetail">
              <br>
              Daripada menghubungi Vendor satu persatu, anda hanya perlu membuat satu RFQ (Request For Quotation) dan banyak Vendor akan mengirimkan Quotation.<br><br>
              
              Algoritma Cepetdapet menggunakan AI untuk mencarikan Vendor yang cocok untuk anda dan interface kami akan mempermudah dalam membandingkan dan memilih Vendor.</p>
          </div>



          </div>
          <div class="col-sm-6 
          pt-2 pe-2 ps-2 pb-2 
          pt-md-0 pe-md-5 ps-md-5 pb-md-5
          pt-lg-0 pe-lg-5 ps-lg-5 pb-lg-5
          order-1 order-sm-2 order-md-2 order-lg-2">


          <div class="text-center">
            <img src="/assets/images/icons/proc-img-homepage.png" class="imgTopLeft {{(isMobile)?'pt-5':''}}">
          </div>




          </div>
          <div class="col-sm-6  
          pt-2 pe-2 ps-2 pb-2 
          pt-md-1 pe-md-5 ps-md-5 pb-md-2
          pt-lg-1 pe-lg-5 ps-lg-5 pb-lg-2
          order-3 order-sm-3 order-md-3 order-lg-3">

          <div class="w-100">
            <div class="text-center ">

              <div class="text-center">
                <img src="/assets/images/icons/proc-hmpage.png" class="img-fluid mt-4 p-4 mt-md-0 mt-lg-0 procimg">
              </div>
             
            </div>
          </div>


          </div>
          <div class="col-sm-6 
          pt-2 pe-2 ps-2 pb-2 
          pt-md-0 pe-md-5 ps-md-5 pb-md-5
          pt-lg-0 pe-lg-5 ps-lg-5 pb-lg-5
          order-4 order-sm-4 order-md-4 order-lg-4">


          <div class="w-100">
            <div class="text-center ">

              <div class="procBtnRight  mt-lg-0 mt-md-3 mt-sm-1 mt-1 mb-3">
                <p class="procBtnRightText mb-0 pb-3">Apa biaya Procurement menggunakan Cepetdapet ?</p>
                <p class="procBtnRightTitle p-0 m-0">Kami TIDAK MENARIK KOMISI atas transaksi anda</p>
              </div>
  
              <div class="DivBtnLeft p-4">
  
                <p>
                  Interface Cepetdapet mempermudah anda dalam bertransaksi, namun jika diinginkan, anda bebas bertransaksi diluar sistem, setelah menemukan Vendor yang cocok.
                  Dengan begitu, Vendor dapat menawarkan harga yang terbaik untuk anda.
                </p>
              </div>
  
  
            </div>
          </div>


          </div>
        </div>
      </div>
    </div>


  </div>
</div>


      <main>
      


        <!-- <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <svg class="bd-placeholder-img" width="100%" height="350px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#4b4a5d"></rect></svg>
              <div class="carousel-caption d-none d-md-block">
                
                <h5>First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
              </div>
            </div>
            <div class="carousel-item">
              <svg class="bd-placeholder-img" width="100%" height="350px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#4b4a5d"></rect></svg>
              <div class="carousel-caption d-none d-md-block">
                <h5>Second slide label</h5>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
            <div class="carousel-item">
              <svg class="bd-placeholder-img" width="100%" height="350px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#4b4a5d"></rect></svg>
              <div class="carousel-caption d-none d-md-block">
                <h5>Third slide label</h5>
                <p>Some representative placeholder content for the third slide.</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> -->


        <!-- <div class="container mt-5">
          <div class="row">
            <div class="col-sm-3">
              
              <svg class="bd-placeholder-img" width="100%" height="70px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
              <svg class="bd-placeholder-img" width="100%" height="416px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
            </div>

            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>

        
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>
                <div class="col-sm-4 mb-4">
                  <svg class="bd-placeholder-img" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
                  <svg class="bd-placeholder-img" width="100%" height="95px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#dbdbdb"></rect></svg>
                </div>


              </div>
            </div>

          </div>
        </div> -->


      
        <!-- <div class="album py-5 d-none">
          <div class="container">
            
            <h2 class="pb-3 text-center">Subcribtion Info</h2>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription A Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=1">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription B Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=2">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription C Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=3">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card shadow-sm">
                  <svg class="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#6c757d"></rect></svg>
      
                  <div class="card-body text-center">
                    <h5>Subscription D Info</h5>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    Message board content<br>
                    <div class="mt-3">
                      <div class="btn-group text-center">
                        <a class="btn btn-sm btn-outline-secondary" href="regis?subplan=4">Start Subscribe</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>







            </div>
          </div>
        </div> -->
      
      </main>
      
      <!-- <table class="table table-dark table-striped" *ngIf="allBooks.length > 0">
        <thead>
          <tr>
            <th scope="col">Book</th>
            <th scope="col">Author</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let book of allBooks">
            <td>{{book.title}}</td>
            <td>{{book.author}}</td>
          </tr>

        </tbody>
      </table> -->

<!-- <app-footer></app-footer> -->
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LightGallery } from 'lightgallery/lightgallery';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { OthersService } from 'src/app/_services/others.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  PageLoading:boolean=true
  items:any=['a','b','c']

  limit:number=100
  offset:number=0 // page-1
  totalAll:number=0
  currentpage:number=1
  totalpage:number=1
  hasprev:boolean=false
  hasnext:boolean=false
  dataCart:any=[]
  noData:boolean=false

  itemDelete:any

  private lightGallery!: LightGallery;
  private needRefresh = false;

  SelectedBulk:any=[]
  disableCreateRfq:boolean=false
  
  valIsCheckedAll:boolean=false

  constructor(
    private othersService: OthersService,
    private modalService: NgbModal,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.PageLoading = false
    // this.items =[]
    this.getCartList()
  }

  getCartList(){
    this.PageLoading=true
    this.offset = this.limit * (this.currentpage -1)


    this.othersService.getCartList(this.limit,this.offset).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.getCartList){
          
        this.dataCart=[]
        var alldata = res.data?.getCartList.cartProductData

        var idCart:any = []
        alldata.forEach((value: any, key: any) => {
          this.dataCart.push(value)
          idCart.push(value.cartId)
        });
        console.log('dataCart: ',this.dataCart)
        
        //this.SelectedBulk = idCart //    // unchecked all 
        this.disableCreateRfq = true

        this.totalAll = res.data?.getCartList.totalData
        if(res.data?.getCartList.totalData>0){
          this.noData = false
        }else {
          this.noData = true
        }
  
        this.loadPaging()
        this.PageLoading=false
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  loadPaging(){
    var gettotal = Math.ceil(this.totalAll/this.limit)

    if(this.currentpage==gettotal){
      this.hasnext=false
    } else {
      this.hasnext=true
    }

    if(this.currentpage==1){
      this.hasprev=false
    } else {
      this.hasprev=true
    }

    this.totalpage = gettotal

    if(this.totalpage==0){
      this.hasnext=false
    }
  }

  delete(item:any){
    this.itemDelete = item
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'md'
    })
    modalRef.componentInstance.modalconfirm = 'modal'
    modalRef.componentInstance.title = 'Confirm Delete'
    modalRef.componentInstance.subtitle = 'Are you sure want to delete <b>"'+item.productName+'"</b> from Cart ?'
    modalRef.componentInstance.yesStr = 'Delete'
    modalRef.componentInstance.noStr = 'Cancel'

    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res) this.confirmDelete(item)
    })
  }
  confirmDelete(item:any){
    var arrDelete = []
    arrDelete.push(item.cartId)
    this.othersService.postdeleteProductFromCart(arrDelete).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.deleteProductFromCart){
        this.getCartList()
        this.PageLoading=false

        this.SelectedBulk.forEach((v:any, k:any) => {
          if(v==item.cartId) this.SelectedBulk.splice(k,1);
        })

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  createRfq(){

    this.disableCreateRfq=true

    var prepareRfq = {}
    // prepareRfq = 
    // {
    //     // "id": "01HERSZB63NQ31J64VV4ZGEHAK",
    //     // "companyLocation": {
    //     //     "id": "01HE4PSKK1RW80V98J4MY2JY8B",
    //     //     "label": "warehouse",
    //     //     "country": "Indonesia",
    //     //     "province": "bintaro",
    //     //     "city": "Tangerang Selatan",
    //     //     "street": "jln. mawar no 22 ",
    //     //     "postalCode": "11234",
    //     //     "phone": null,
    //     //     "taxId": null,
    //     //     "isMainAddress": true,
    //     //     "__typename": "RfqCompanyLocation"
    //     // },
    //     // "state": "HALT",
    //     // "approvalStatus": 4,
    //     // "ongoingApprovalStatus": null,
    //     // "rfqDisplayId": "thc6QgW2",
    //     // "description": "<fhspecdiv>[]</fhspecdiv>",
    //     // "submitQuotationLastDate": "2023-12-08T17:00:00Z",
    //     // "paymentTermRequestedInDays": 200,
    //     // "etaRequested": null,
    //     // "currency": "IDR",
    //     // "procurementRefNumber": null,
    //     // "isForFullQuote": false,
    //     // "isIndentAccepted": true,
    //     // "expiredDate": "2024-01-07T17:00:00Z",
    //     // "rfqItem": [
    //     //     {
    //     //         "id": "01HERSZB86WE3TY3CKDKCE7VEN",
    //     //         "name": null,
    //     //         "brand": "ASUS",
    //     //         "description": "Vivobook 14X (M1403, AMD Ryzen™ 5000 Series Mobile Processor)<fhspecdiv>[{\"label\":\"Color\",\"value\":\"Black\"},{\"label\":\"Processor\",\"value\":\"AMD Ryzen™ 5 5600H\"},{\"label\":\"Memory\",\"value\":\"8 Gb\"},{\"label\":\"Storage\",\"value\":\" 512GB M.2 NVMe™ PCIe® 3.0 SSD\"}]</fhspecdiv>",
    //     //         "qtyNeeded": 10,
    //     //         "manufacturerSku": "M1403QA",
    //     //         "itemSequence": 1,
    //     //         "itemUnit": "Pcs",
    //     //         "fileName": [
    //     //             "https://rfqitem-cepetdapet-dev.s3.ap-southeast-1.amazonaws.com/01HERSZB63NQ31J64VV4ZGEHAK/01HERSZB86WE3TY3CKDKCE7VEN/1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240517T061246Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIA2LY4HGG47AK2IOKK%2F20240517%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=99ace24703a174c45395a6df0c0a093b59d0970985c9d35327121d170aeb3153",
    //     //             "https://rfqitem-cepetdapet-dev.s3.ap-southeast-1.amazonaws.com/01HERSZB63NQ31J64VV4ZGEHAK/01HERSZB86WE3TY3CKDKCE7VEN/2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240517T061246Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIA2LY4HGG47AK2IOKK%2F20240517%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=b14ec937bc1789aad63e2a751946108c84c4fedc0810669929cb5a244ac6143b"
    //     //         ],
    //     //         "__typename": "RfqItem"
    //     //     },
    //     //     {
    //     //         "id": "01HERSZB86HQY2R3282CPPJG72",
    //     //         "name": null,
    //     //         "brand": "Samsung",
    //     //         "description": "Galaxy S23 FE<fhspecdiv>[{\"label\":\"Color\",\"value\":\"Graphite\"},{\"label\":\"Ram\",\"value\":\"8 Gb\"},{\"label\":\"Storage\",\"value\":\"128 Gb\"}]</fhspecdiv>",
    //     //         "qtyNeeded": 10,
    //     //         "manufacturerSku": null,
    //     //         "itemSequence": 2,
    //     //         "itemUnit": "Pcs",
    //     //         "fileName": [
    //     //             "https://rfqitem-cepetdapet-dev.s3.ap-southeast-1.amazonaws.com/01HERSZB63NQ31J64VV4ZGEHAK/01HERSZB86HQY2R3282CPPJG72/1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240517T061246Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIA2LY4HGG47AK2IOKK%2F20240517%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9db6b80c2beda5097c0574383662736314c3eac869ee090b190e7b7844c71a51"
    //     //         ],
    //     //         "__typename": "RfqItem"
    //     //     },
    //     //     {
    //     //         "id": "01HERSZB86TVE5Z9JMN7XQFP8P",
    //     //         "name": null,
    //     //         "brand": "Xiaomi ",
    //     //         "description": "Xiaomi Pad 6<fhspecdiv>[{\"label\":\"Color\",\"value\":\"Gravity Gray\"},{\"label\":\"Ram\",\"value\":\"8 Gb\"},{\"label\":\"Storage\",\"value\":\"256GB\"}]</fhspecdiv>",
    //     //         "qtyNeeded": 10,
    //     //         "manufacturerSku": null,
    //     //         "itemSequence": 3,
    //     //         "itemUnit": "Pcs",
    //     //         "fileName": [
    //     //             "https://rfqitem-cepetdapet-dev.s3.ap-southeast-1.amazonaws.com/01HERSZB63NQ31J64VV4ZGEHAK/01HERSZB86TVE5Z9JMN7XQFP8P/1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240517T061246Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIA2LY4HGG47AK2IOKK%2F20240517%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=983b2e67f73753860aca62e62d15055ac2531f21e58da6212b24037de2948f3b"
    //     //         ],
    //     //         "__typename": "RfqItem"
    //     //     },
    //     //     {
    //     //         "id": "01HERSZB8641H6W3TARP9EP61E",
    //     //         "name": null,
    //     //         "brand": null,
    //     //         "description": "Iphone 15<fhspecdiv>[]</fhspecdiv>",
    //     //         "qtyNeeded": 10,
    //     //         "manufacturerSku": null,
    //     //         "itemSequence": 4,
    //     //         "itemUnit": "Pcs",
    //     //         "fileName": [],
    //     //         "__typename": "RfqItem"
    //     //     }
    //     // ],
    //     // "rfqVendorCategory": [
    //     //     {
    //     //         "id": "01HERSZB7RF3HJWN88EY0B7JT8",
    //     //         "category": "steal",
    //     //         "__typename": "RfqVendorCategory"
    //     //     },
    //     //     {
    //     //         "id": "01HERSZB7RK5HJK5WY3NX9C56H",
    //     //         "category": "water",
    //     //         "__typename": "RfqVendorCategory"
    //     //     }
    //     // ],
    //     // "totalVendorParticipation": 3,
    //     // "createdDate": "2023-11-09T01:08:33.603356Z",
    //     // "createdBy": "01H8GFNQDYHT80ERME0NBPDJZT",
    //     // "modifiedDate": "2024-01-07T17:00:01.996443Z",
    //     // "modifiedBy": "SYSTEM HALT",
    //     // "__typename": "Rfq"
    // }


    console.log('redirectRepeatRfq')
    var itemsCart:any = []
    this.dataCart.forEach((value: any, key: any) => {
      this.SelectedBulk.forEach((v:any, k:any) => {
        if(v == value.cartId){
          var prepareData = {
            "name": value.productName,
            "brand": value.productBrand,
            "description": value.productDescription,
            "qtyNeeded": 1,
            "manufacturerSku": "",
            "itemSequence": 1,
            "itemUnit": "",
            "fileName": value.imageUrl
          }
    
          // //set upload images
          // var imgData:any = []
          // if(value.imageUrl.length>0){
          //   prepareData['fileName'] = value.imageUrl
          // } else {
          //   prepareData['fileName'] = imgData
          // }
          
    
    
          itemsCart.push(prepareData)
        }
      });

    });
    console.log('itemsCart : ',itemsCart)


    prepareRfq = 
    {
        // "id": "01HERSZB63NQ31J64VV4ZGEHAK",
        // "companyLocation": {
        //     "id": "01HE4PSKK1RW80V98J4MY2JY8B",
        //     "label": "warehouse",
        //     "country": "Indonesia",
        //     "province": "bintaro",
        //     "city": "Tangerang Selatan",
        //     "street": "jln. mawar no 22 ",
        //     "postalCode": "11234",
        //     "phone": null,
        //     "taxId": null,
        //     "isMainAddress": true,
        //     "__typename": "RfqCompanyLocation"
        // },
        // "companyLocation": {
        //   "id": null,
        //   "label": "warehouse",
        //   "country": "Indonesia",
        //   "province": "bintaro",
        //   "city": "Tangerang Selatan",
        //   "street": "jln. mawar no 22 ",
        //   "postalCode": "11234",
        //   "phone": null,
        //   "taxId": null,
        //   "isMainAddress": true,
        //   "__typename": "RfqCompanyLocation"
        // },
        // "state": "HALT",
        // "approvalStatus": 4,
        // "ongoingApprovalStatus": null,
        // "rfqDisplayId": "thc6QgW2",
        // "description": "<fhspecdiv>[]</fhspecdiv>",
        // "submitQuotationLastDate": "2023-12-08T17:00:00Z",
        // "paymentTermRequestedInDays": 200,
        // "etaRequested": null,
        // "currency": "IDR",
        // "procurementRefNumber": null,
        // "isForFullQuote": false,
        // "isIndentAccepted": true,
        // "expiredDate": "2024-01-07T17:00:00Z",
        "rfqItem":itemsCart,
        "rfqVendorCategory": [],
        // "totalVendorParticipation": 3,
        // "createdDate": "2023-11-09T01:08:33.603356Z",
        // "createdBy": "01H8GFNQDYHT80ERME0NBPDJZT",
        // "modifiedDate": "2024-01-07T17:00:01.996443Z",
        // "modifiedBy": "SYSTEM HALT",
        "__typename": "Rfq"
    }

    



    localStorage.setItem("CARTSTORAGE",JSON.stringify(prepareRfq))

    this.router.navigate(['procurement/rfq']);


  }

  onCheckCart(id:string,oncheck:boolean){
    console.log('id : ',id)
    console.log('oncheck : ',oncheck)
    if(oncheck==true){
      
      var exist = false
      this.SelectedBulk.forEach((element:any,index:any)=>{
        if(element==id){
          exist = true
        }
      });

      if(!exist){
        this.SelectedBulk.push(id)
      }

    } else {

      this.SelectedBulk.forEach((element:any,index:any)=>{
        if(element==id) this.SelectedBulk.splice(index,1);
      });


    }

    if(this.SelectedBulk.length==0){
      this.disableCreateRfq = true
    } else {
      this.disableCreateRfq = false
    }

    if(this.SelectedBulk.length == this.totalAll){
      this.valIsCheckedAll = true
    } else {
      this.valIsCheckedAll = false
    }
    console.log('SelectedBulk :: ',this.SelectedBulk)
  }

  setCheckBox(id:string){
    var res = false

    var array = this.SelectedBulk
    array.forEach((value: any) => {
      if(value==id){
        res = true
      }
    });
    return res
  }

  checkAll(){
    if(this.valIsCheckedAll){
      this.disableCreateRfq = true
      this.valIsCheckedAll = false

      this.SelectedBulk = []
    } else {

      var idCart:any = []
      this.dataCart.forEach((value: any, key: any) => {
        idCart.push(value.cartId)
      });
      this.SelectedBulk = idCart
      this.disableCreateRfq = false
      this.valIsCheckedAll = true

    }


  }

  settingsGallery = {
    counter: true,
    download:false,
    selector: '.itemz'
  };
  
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


}

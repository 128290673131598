export default class Option{
    static thousand(){
        return {
            digitGroupSeparator: ',',
            decimalCharacter: '.',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            currencySymbolPlacement: 's',
            roundingMethod: 'U',
            minimumValue: '0',
            modifyValueOnWheel:false
        }
    }
    static qtythousand(){
        return {
            digitGroupSeparator: ',',
            decimalCharacter: '.',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            currencySymbolPlacement: 's',
            roundingMethod: 'U',
            minimumValue: '0',
            decimalPlaces: 0,
            modifyValueOnWheel:false
        }
    }
    static autobid(){
        return {
            digitGroupSeparator: '',
            decimalCharacter: '.',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            // currencySymbolPlacement: '',
            roundingMethod: 'U',
            minimumValue: '1',
            maximumValue: '9999',
            decimalPlaces: 0,
            modifyValueOnWheel:false
        }
    }
    static autobidFeeMember(){
        return {
//            digitGroupSeparator: '',
            // decimalCharacter: '.',
            // decimalCharacterAlternative: '.',
            // currencySymbol: '',
            // currencySymbolPlacement: '',
            // roundingMethod: 'U',
            minimumValue: '0',
            maximumValue: '5',
            decimalPlaces: 0,
            modifyValueOnWheel:false
        }
    }
    static autobidDisabled(){
        return {
            digitGroupSeparator: ',',
            decimalCharacter: '.',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            // currencySymbolPlacement: '',
            roundingMethod: 'U',
            minimumValue: '0',
            decimalPlaces: 0,
            modifyValueOnWheel:false,
            readOnly:true
        }
    }
    static paymentTerm(){
        return {
            digitGroupSeparator: '',
            decimalCharacter: '.',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            currencySymbolPlacement: 'p',
            roundingMethod: 'U',
            minimumValue: '0',
            maximumValue: '99999',
            decimalPlaces: 0,
            modifyValueOnWheel:false
        }
    }
    

    static standard(){
        return "en-US"
    }
    static decimal(){
        return {minimumFractionDigits: 2, maximumFractionDigits: 2}
    }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { config } from 'src/app/config';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { RatingService } from 'src/app/_services/rating.services';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-procurement-rating-list',
  templateUrl: './procurement-rating-list.component.html',
  styleUrls: ['./procurement-rating-list.component.scss']
})
export class ProcurementRatingListComponent implements OnInit {
  PageLoading:boolean=false

  limit:number=config.defaultPagingRating
  offset:number=0 // page-1
  totalAll:number=0
  currentpage:number=1
  totalpage:number=1
  hasprev:boolean=false
  hasnext:boolean=false

  loadingrating:boolean=false
  noData:boolean=false

  AllRatingData:any=[]

  constructor(
    public modalService: NgbModal,
    private router: Router,
    private popupmodal : Popupmodal,
    private ratingService: RatingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getPorcurementRatingVendorList()
  }




  getPorcurementRatingVendorList(){
    this.loadingrating=true
    this.offset = this.limit * (this.currentpage -1)


    this.ratingService.getProcurementGetPpoListNeedRating(this.limit,this.offset).subscribe((res:any) => {
      console.log(res)
      this.loadingrating=false
      if(res.data?.procurementGetPpoListNeedRating){
          
        this.AllRatingData=[]
        var alldata = res.data?.procurementGetPpoListNeedRating.procurementPpoNeedRatingListData

        alldata.forEach((value: any, key: any) => {
          this.AllRatingData.push(value)
        });


        this.totalAll = res.data?.procurementGetPpoListNeedRating.totalItem
        if(res.data?.procurementGetPpoListNeedRating.totalItem>0){
          this.noData = false
        }else {
          this.noData = true
        }
  
        this.loadPaging()
        this.loadingrating=false
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  loadPaging(){
    var gettotal = Math.ceil(this.totalAll/this.limit)
    console.log('gettotal = '+this.totalAll)
    console.log('gettotal = '+this.limit)
    console.log('gettotal = '+gettotal)
    if(this.currentpage==gettotal){
      this.hasnext=false
    } else {
      this.hasnext=true
    }

    if(this.currentpage==1){
      this.hasprev=false
    } else {
      this.hasprev=true
    }

    this.totalpage = gettotal


    console.log('totalpage hasprev',this.hasprev);
    console.log('totalpage hasnext',this.hasnext);

    console.log('totalpage gettotal',gettotal);
    console.log('totalpage currentpage',this.currentpage);
    console.log('totalpage totalAll',this.totalAll);
    console.log('totalpage totalpage',this.totalpage);

    if(this.totalpage==0){
      this.hasnext=false
    }
    console.log('totalpage xxxx',this.totalAll);
  }


  gotopage(page:any){
    this.currentpage=page
    this.getPorcurementRatingVendorList()
  }

  ratingicon(total:number){
    console.log('===========================')

    var multiplier = Math.pow(1, 0);

    //var roundedup = Math.round(total * multiplier) / multiplier;  //Math.ceil(Number(total))
    var roundeddown = Math.floor(total);
    console.log('total : ',total)
    console.log('roundeddown : ',roundeddown)


    var getdecimal = total - roundeddown  
    console.log('getdecimal = roundeddown - total  : ',getdecimal)

    console.log('getdecimal : ',getdecimal)
    var getempty = 5 - roundeddown
    console.log('getempty : ',getempty)
    console.log('roundedup - getdecimal : ',roundeddown - getdecimal)
    // console.log('getdecimal - roundedup : ',getdecimal - roundedup)
    var res = ''
    var notZero = 0
    if(roundeddown -getdecimal  == 0){
      for (let index = 0; index < total; index++) {
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
      }
    } else {
      for (let index = 0; index < roundeddown-1; index++) {
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
      }
      console.log('getdecimal total : ',total)
      console.log('getdecimal : ',getdecimal)
      if(getdecimal>0.5 && getdecimal<1){
        notZero=1
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-three-quarter.png">'
      } else if(getdecimal<0.5 && getdecimal>0){
        notZero=1
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-quarter.png">'
      } else if(getdecimal==0){
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
      } else {
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-fill.png">'
        res += '<img class="rating-icon" src="/assets/images/icons/star-filled-half.png">'
      }
    }

    for (let index = 0; index < getempty - notZero; index++) {
      res += '<img class="rating-icon" src="/assets/images/icons/star-filled-blank.png">'
    }
    console.log('===========================')
    return res

  }

}

import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { gql, Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { onError } from "apollo-link-error";
// import 'tinymce/icons/default';
import AutoNumeric from 'autonumeric';
import { NavigationService } from 'src/app/_services/navigation';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import Getcurrency from 'src/app/_helpers/currency';
import { Observable, OperatorFunction, Subscription, forkJoin, from, of } from 'rxjs';
import {catchError, concatMap, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { ProductService } from 'src/app/_services/product.services';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from 'src/app/components/modal/modal.component';
import Option from 'src/app/_helpers/option';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { TranslateService } from '@ngx-translate/core';
import { UploadImagesService } from 'src/app/_services/uploadImages.services';
import CheckErrortype from 'src/app/_helpers/check-errortype';

const getTags = gql`
query{
  getCompanyTagList{
      id
      tag
      isActive
  }
}
`;

const postProduct = gql`
mutation insertCompanyProduct($name: String!, $brand: String, $description: String , $sku: String, $manufacturerSku: String, $price: Float!, $imageFileName: [String!], $isDisplayPriceOnShopfront: Boolean! = false, $isDisplayProductOnShopfront: Boolean! = false, $tags: [ID!], $quotationInitialPriceOffer: Float, $autobidMinimumPrice: Float, $autobidPriceDecrement: Float, $isAutobidEnabled: Boolean! = false, $isActivatedByUser: Boolean! = false){
  insertCompanyProduct(input: {
      name: $name,
      brand: $brand,
      description: $description,
      sku: $sku,
      manufacturerSku: $manufacturerSku,
      price: $price,
      imageFileName: $imageFileName,
      isDisplayPriceOnShopfront:  $isDisplayPriceOnShopfront,
      isDisplayProductOnShopfront: $isDisplayProductOnShopfront,
      tags: $tags,
      quotationInitialPriceOffer: $quotationInitialPriceOffer,
      autobidMinimumPrice: $autobidMinimumPrice,
      autobidPriceDecrement: $autobidPriceDecrement,
      isAutobidEnabled: $isAutobidEnabled,
      isActivatedByUser: $isActivatedByUser
  }){
      id
      name
      brand
      description
      sku
      manufacturerSku
      price
      imageUrl
      tags{
          id
          tag
      }
      isDisplayPriceOnShopfront
      isDisplayProductOnShopfront
      quotationInitialPriceOffer
      autobidMinimumPrice
      autobidPriceDecrement
      isAutobidEnabled

      state
      publishApprovalStatus
      publishedOngoingApprovalStatus
      isActivatedByUser
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;

const postmutationProduct = gql`
mutation insertCompanyProduct($CompanyProductInsertInput: CompanyProductInsertInput!){
  insertCompanyProduct(input: $CompanyProductInsertInput){
      id
      name
      brand
      description
      sku
      manufacturerSku
      price
      imageUrl
      tags{
          id
          tag
      }
      isDisplayPriceOnShopfront
      isDisplayProductOnShopfront
      quotationInitialPriceOffer
      autobidMinimumPrice
      autobidPriceDecrement
      isAutobidEnabled
      currency
      state
      publishApprovalStatus
      publishedOngoingApprovalStatus
      isActivatedByUser
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;



const editProduct = gql`
mutation updateCompanyProduct($productId: ID!,$name: String!, $brand: String, $description: String , $sku: String, $manufacturerSku: String, $price: Float!, $imageFileName: [String!], $isDisplayPriceOnShopfront: Boolean! = false, $isDisplayProductOnShopfront: Boolean! = false, $tags: [ID!], $quotationInitialPriceOffer: Float, $autobidMinimumPrice: Float, $autobidPriceDecrement: Float, $isAutobidEnabled: Boolean! = false) {
  updateCompanyProduct(input: {
      productId: $productId
      companyProductUpdateInputData:
      {
          name: $name,
          brand: $brand,
          description: $description,
          sku: $sku,
          manufacturerSku: $manufacturerSku,
          price: $price,
          imageFileName: $imageFileName,
          isDisplayPriceOnShopfront:  $isDisplayPriceOnShopfront,
          isDisplayProductOnShopfront: $isDisplayProductOnShopfront,
          tags: $tags,
          quotationInitialPriceOffer: $quotationInitialPriceOffer,
          autobidMinimumPrice: $autobidMinimumPrice,
          autobidPriceDecrement: $autobidPriceDecrement,
          isAutobidEnabled: $isAutobidEnabled
      }
  }) {
      __typename,
      ...on CompanyProduct {
          id
          name
          brand
          description
          sku
          manufacturerSku
          price
          imageUrl
          tags{
              id
              tag
          }
          isDisplayPriceOnShopfront
          isDisplayProductOnShopfront
          quotationInitialPriceOffer
          autobidMinimumPrice
          autobidPriceDecrement
          isAutobidEnabled
          state
          publishApprovalStatus
          publishedOngoingApprovalStatus
          isActivatedByUser
          createdDate
          createdBy
          modifiedDate
          modifiedBy
      }
      ... on CompanyProductDraft {
          id
          draftName
          draftBrand
          draftDescription
          draftSku
          draftManufacturerSku
          draftPrice
          draftImageUrl
          draftTags{
              id
              tag
          }
          draftIsDisplayPriceOnShopfront
          draftIsDisplayProductOnShopfront
          draftQuotationInitialPriceOffer
          draftAutobidMinimumPrice
          draftAutobidPriceDecrement
          draftIsAutobidEnabled
          draftCreatedDate
          draftCreatedBy
          draftModifiedDate
          draftModifiedBy
     }
  }
}
`;



const editmutationProduct = gql`
mutation updateCompanyProduct($companyProductUpdateInputData: CompanyProductUpdateInputData!,$productId:ID!) {
  updateCompanyProduct(input: { 
    productId: $productId
    companyProductUpdateInputData: $companyProductUpdateInputData
  }) {
      __typename,
      ...on CompanyProduct {
          id
          name
          brand
          description
          sku
          manufacturerSku
          price
          imageUrl
          tags{
              id
              tag
          }
          isDisplayPriceOnShopfront
          isDisplayProductOnShopfront
          quotationInitialPriceOffer
          autobidMinimumPrice
          autobidPriceDecrement
          isAutobidEnabled
          state
          publishApprovalStatus
          publishedOngoingApprovalStatus
          isActivatedByUser
          createdDate
          createdBy
          modifiedDate
          modifiedBy
      }
      ... on CompanyProductDraft {
          id
          draftName
          draftBrand
          draftDescription
          draftSku
          draftManufacturerSku
          draftPrice
          draftImageUrl
          draftTags{
              id
              tag
          }
          draftIsDisplayPriceOnShopfront
          draftIsDisplayProductOnShopfront
          draftQuotationInitialPriceOffer
          draftAutobidMinimumPrice
          draftAutobidPriceDecrement
          draftIsAutobidEnabled
          draftCreatedDate
          draftCreatedBy
          draftModifiedDate
          draftModifiedBy
     }
  }
}
`;


@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss']
})
export class ProductsDetailComponent implements OnInit {
  pageloading: boolean = false;
  submitted = false;
  tags: any = []
  SelectedTags: any = []
  prevSelectedTags:any = []
  imageSrc: string = ''
  imageSrcName:string=''
  imageSrcData:any=null
  imageSrcMore: any = []
  imageSrcMoreData:any=[]

  successResponse: boolean = false;
  successResponseName: string = ''

  MoreImgCount = new Array(3);
  MoreImgArr: any = []
  CombineImg: any = []
  CombineImgData:any=[]

  checkPriceDecCalculate: boolean = false;
  checkDiscIncCalculate: boolean = false;

  displaydraft = 'none'
  displaydone = 'none'

  divFhSpec: any = [] // <fhspecdiv></fhspecdiv>
  countarrFhSpec: any = []

  valspecLabel = ''
  valspecValue = ''

  emptyLabelSpec: any = []
  emptyValueSpec: any = []

  ValSpecEmpty: boolean = false

  successdraft: boolean = false
  actionbtn: any = '' //draft , preview, done

  productFormInvalid: boolean = false

  getEditData: any

  productForm:any


  //model
  Valname: string = ''
  Valbrand: string = ''
  Valdescription: string = ''
  Valsku: string = ''
  ValmanufacturerSku: string = ''
  Valprice: any = ''
  ValimageFileName: string = ''
  ValisDisplayPriceOnShopfront: boolean = true
  ValisDisplayProductOnShopfront: boolean = true
  Valtags: string = ''
  ValquotationInitialPriceOffer: string = ''
  ValautobidMinimumPrice: any = ''
  ValautobidPriceDecrement: any = ''
  ValisAutobidEnabled: boolean = false
  ValisActivatedByUser: boolean = false

  ValprimaryImg: string = ''
  FilenamePirmaryImg: string = ''
  ValimageMore: any = []

  EditProductId: any
  PassData: any
  valspecValueErr: boolean = false;
  valspecLabelErr: boolean = false;
  valcurrency:string=''
  //EditTags:any

  ValpricePrev: any = ''
  ValautobidPriceDecrementPrev: any = ''
  ValautobidMinimumPricePrev: any = ''


  dropdownListTag: any;
  selectedItemsTag: any = [];
  dropdownSettingsTag: any;

  errornotif:string='none'
  errortext:string=''

  errorcurrency:string='none'

  PrevEditProductId:string=''

  currentstate:string=''
  ispricenegative:boolean=false
  ispricezero:boolean=false

  thousandOption = Option.thousand()

  states = Getcurrency.getAll();

  // formatter = (result: string) => result.toUpperCase();
  formatter = (result: string) => result;

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.states.filter((v: string) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  invalidMinPrice: boolean=false;
  invalidautobidPriceDecrement: boolean=false;

  arrImgName:string[]=[]
  arrImgFile:any[]=[]
  UrlUpload:string=''
  successUpload:number=0
  totalUpload:number=0

  productImagePreSignedUrl:string[]=[]
  
  errorUploadImg:boolean=false

  
  UpdateimageData:any=[] 
  getImgDetail:string[]=[]
  imgAwsName:any[]=[]
    
  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private productService: ProductService,
    private modalService: NgbModal,
    private popupmodal : Popupmodal,
    private translate: TranslateService,
    private uploadImagesService: UploadImagesService
  ) { 


    
  }



  ngOnInit(): void {

    document.activeElement?.classList.contains('noscroll')

    document.addEventListener("wheel", function(event) {
      //console.log('event : ',event);
      // console.log('document : ',document.activeElement);
      // if (document.activeElement.type === "number" &&
      //   document.activeElement.classList.contains("noscroll")) {
      //   document.activeElement.blur();
      // }

      // if(event.isTrusted){
      //   if (document.activeElement instanceof HTMLElement) {
      //     document.activeElement.blur();
      //     document.getElementsByClassName
      //   } 
      // }
      if(document.activeElement?.classList.contains('noscroll')){
        //console.log('contain noscroll'); 
        // document.activeElement.blur();
        // console.log('activeElement : ',document.activeElement);
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

      }
    });


    var getdefaultCurrency = localStorage.getItem("defaultCurrency")
    console.log('getdefaultCurrency : ',getdefaultCurrency);
    if(!(getdefaultCurrency) || getdefaultCurrency ==null || getdefaultCurrency =='null'){
      //this.errorcurrency='block'
    } else {
      this.valcurrency = getdefaultCurrency
    }



    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new bootstrap.Tooltip(tooltipTriggerEl)
    // })

    this.dropdownSettingsTag = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };



    // new AutoNumeric('.myInput', { options });
    // new AutoNumeric('.myInput', { currencySymbol : '$' });
    // new AutoNumeric('.testInput', {
    //   aSep: '.',
    //   aDec: ',',
    //   aForm: true,
    //   vMax: '999999999',
    //   vMin: '-999999999'
    // });

    // $(".testInput").autoNumeric('init', {
    //   aSep: '.',
    //   aDec: ',',
    //   aForm: true,
    //   vMax: '999999999',
    //   vMin: '-999999999'
    // });



    this.EditProductId = this.route.snapshot.paramMap.get('ProductId');
    console.log('EditProductId', this.EditProductId);
    if (this.EditProductId) {
      this.getDataEdit(this.EditProductId)
      this.PrevEditProductId =this.EditProductId

      this.productForm = this.formBuilder.group({
        name: ['', Validators.required],
        brand: [''],
        // description: ['', Validators.required],
        description: ['', Validators.required],
        sku: [''],
        manufacturerSku: [''],
        price: ['', Validators.required],
        // imageFileName: [''],
        isDisplayPriceOnShopfront: [''],
        isDisplayProductOnShopfront: [''],
        tags: [''],
        quotationInitialPriceOffer: [''],
        autobidMinimumPrice: [''],
        autobidPriceDecrement: [''],
        isAutobidEnabled: [''],
        isActivatedByUser: [''],
        primaryImg: [''],
        //tinymce: ['', Validators.required],
        priceprev: [''],
        autobidPriceDecrementPrev: [''],
        autobidMinimumPricePrev: [''],
        currency:['', Validators.required]
      });

    } else {

      this.productForm = this.formBuilder.group({
        name: ['', Validators.required],
        brand: [''],
        // description: ['', Validators.required],
        description: ['', Validators.required],
        sku: [''],
        manufacturerSku: [''],
        price: ['', Validators.required],
        // imageFileName: [''],
        isDisplayPriceOnShopfront: [''],
        isDisplayProductOnShopfront: [''],
        tags: [''],
        quotationInitialPriceOffer: [''],
        autobidMinimumPrice: [''],
        autobidPriceDecrement: [''],
        isAutobidEnabled: [''],
        isActivatedByUser: [''],
        primaryImg: ['', Validators.required],
        //tinymce: ['', Validators.required],
        priceprev: [''],
        autobidPriceDecrementPrev: [''],
        autobidMinimumPricePrev: [''],
        currency:['', Validators.required]
      });

    }

    this.getTag()

    for (let i = 0; i < this.MoreImgCount.length; i++) {
      this.imageSrcMore.push({ 'id': i, 'val': '', 'name': '' })
    }
    //console.log('imageSrcMore',this.imageSrcMore);


    // var test = this.data.currentMessage.subscribe(message => this.PassData = message)
    // this.data.currentMessage.subscribe(message => {
    //   console.log('xxxxxxxxxx',message)
    // });



    // this.data.currentArr.subscribe(currentArr => {
    //   console.log('xxxx currentArr xxxxxx',currentArr)
    // });

  }
  get f() { return this.productForm?.controls; }

  ngAfterViewInit(): void {
    // new AutoNumeric('#priceprev');
    // new AutoNumeric('#autobidPriceDecrementPrev');
    // new AutoNumeric('#autobidMinimumPricePrev');
  }
  getDataEdit(ProductId: any) {
    console.log('edit', ProductId);


    this.productService
    .getProductDetail(ProductId)
    .subscribe((res:any) => {
      if(res.data){
        console.log("succes, get data:", res);
        console.log("res.data.companyProduct : ", res.data.companyProduct);

        this.getEditData = res.data.companyProduct

        //model
        this.Valname = res.data.companyProduct.name
        this.Valbrand = res.data.companyProduct.brand
        this.Valdescription = res.data.companyProduct.description
        this.Valsku = res.data.companyProduct.sku
        this.ValmanufacturerSku = res.data.companyProduct.manufacturerSku
        // this.Valprice = parseInt(res.data.companyProduct.price)
        this.ValimageFileName = '' //res.data.companyProduct.imageUrl[0]
        this.ValisDisplayPriceOnShopfront = res.data.companyProduct.isDisplayPriceOnShopfront
        this.ValisDisplayProductOnShopfront = res.data.companyProduct.isDisplayProductOnShopfront
        this.Valtags = '' //res.data.companyProduct.tags
        this.ValquotationInitialPriceOffer = res.data.companyProduct.quotationInitialPriceOffer
        //this.ValautobidMinimumPrice = (res.data.companyProduct.autobidMinimumPrice==0)?'':res.data.companyProduct.autobidMinimumPrice // res.data.companyProduct.autobidMinimumPrice
        //this.ValautobidPriceDecrement = (res.data.companyProduct.autobidPriceDecrement==0)?'':res.data.companyProduct.autobidPriceDecrement //res.data.companyProduct.autobidPriceDecrement
        this.ValisAutobidEnabled = res.data.companyProduct.isAutobidEnabled
        this.ValisActivatedByUser = res.data.companyProduct.isActivatedByUser
        this.ValprimaryImg = '' //res.data.companyProduct.imageUrl[0]
        this.FilenamePirmaryImg = '' //res.data.companyProduct.imageUrl[0]
        this.ValimageMore = []

        this.imgAwsName = []
        if(res.data.companyProduct.imageUrl.length>0){

          var i = 0
          res.data.companyProduct.imageUrl.forEach((e:string,k:number) => {
            if(k==0){
              // this.productForm.controls['primaryImg'].setValue(e);
              this.ValprimaryImg = e
              this.FilenamePirmaryImg = e
              this.ValimageFileName = e
              this.imageSrc=e
            } else {
              this.ValimageMore.push(e)

              this.imageSrcMore[i].val=e
              i++
            }

            var filename = Number(e.substring(e.lastIndexOf('/')+1).split('.')[0])
            console.log('filename : ',e.substring(e.lastIndexOf('/')+1).split('.')[0])
            console.log('filename : ',filename)
            if(!isNaN(filename)){
              console.log('filename => ',filename)
              this.imgAwsName.push(filename)
            }
      

          });


          this.getImgDetail = res.data.companyProduct.imageUrl
        }
        console.log('imgAwsName : ',this.imgAwsName)

        // this.ValpricePrev =  parseInt(res.data.companyProduct.price)
        

        // this.ValpricePrev = (Math.round(parseInt(res.data.companyProduct.price) * 100) / 100).toLocaleString()
        this.ValpricePrev = parseFloat(res.data.companyProduct.price).toFixed(2)
        this.ValautobidMinimumPricePrev = res.data.companyProduct.autobidMinimumPrice
        this.ValautobidPriceDecrementPrev = res.data.companyProduct.autobidPriceDecrement

        this.currentstate = res.data.companyProduct.states
        this.valcurrency = res.data.companyProduct.currency

        //this.Valprice = res.data.companyProduct.price.toString()
        // this.Valprice = parseInt(res.data.companyProduct.price)
        this.Valprice = parseFloat(res.data.companyProduct.price).toFixed(2)
        this.ValautobidMinimumPrice = res.data.companyProduct.autobidMinimumPrice
        this.ValautobidPriceDecrement = res.data.companyProduct.autobidPriceDecrement

        this.selectedItemsTag = []
        res.data.companyProduct.tags.forEach((obj: any, key: any) => {
          this.SelectedTags.push(obj.id)
          this.selectedItemsTag.push({ item_id: obj.id, item_text: obj.tag })
          this.prevSelectedTags.push(obj.id)
        });

        // seperate desc
        this.Valdescription = res.data.companyProduct.description

        this.divFhSpec = []
        this.Valdescription = ''
        var desctext = res.data.companyProduct.description

        var a = res.data.companyProduct.description;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);

        if (getdetaildesc) {
          console.log('>>>>>>>', getdetaildesc[0]);
          console.log('>>>>>>>', getdetaildesc[1]);

          this.divFhSpec = getdetaildesc[1]
          this.divFhSpec = JSON.parse(this.divFhSpec)
          //this.Valdescription = desctext.split(getdetaildesc[0]).pop()
          this.Valdescription = desctext.replace(getdetaildesc[0], '')

          this.divFhSpec.forEach((obj: any, key: any) => {
            this.emptyLabelSpec.push({ error: false })
            this.emptyValueSpec.push({ error: false })
          });


          console.log('>>>>>>>', desctext);
        }
        else {
          this.Valdescription = res.data.companyProduct.description
        }

        this.countarrFhSpec = this.divFhSpec




      }
      else if(res.errors){

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });


    

    // var allprod = localStorage.getItem("PRODUCTLIST")
    // var parse = allprod;
    // if (parse) {
    //   var allprodArr = JSON.parse(parse);
    //   var x = 0
    //   allprodArr.forEach((obj: any) => {

    //     if (obj.node.id == ProductId) {
    //       //set data
    //       console.log(obj.node)

    //       this.getEditData = obj.node

    //       //model
    //       this.Valname = obj.node.name
    //       this.Valbrand = obj.node.brand
    //       this.Valdescription = obj.node.description
    //       this.Valsku = obj.node.sku
    //       this.ValmanufacturerSku = obj.node.manufacturerSku
    //       // this.Valprice = parseInt(obj.node.price)
    //       this.ValimageFileName = '' //obj.node.imageUrl[0]
    //       this.ValisDisplayPriceOnShopfront = obj.node.isDisplayPriceOnShopfront
    //       this.ValisDisplayProductOnShopfront = obj.node.isDisplayProductOnShopfront
    //       this.Valtags = '' //obj.node.tags
    //       this.ValquotationInitialPriceOffer = obj.node.quotationInitialPriceOffer
    //       //this.ValautobidMinimumPrice = (obj.node.autobidMinimumPrice==0)?'':obj.node.autobidMinimumPrice // obj.node.autobidMinimumPrice
    //       //this.ValautobidPriceDecrement = (obj.node.autobidPriceDecrement==0)?'':obj.node.autobidPriceDecrement //obj.node.autobidPriceDecrement
    //       this.ValisAutobidEnabled = obj.node.isAutobidEnabled
    //       this.ValisActivatedByUser = obj.node.isActivatedByUser
    //       this.ValprimaryImg = '' //obj.node.imageUrl[0]
    //       this.FilenamePirmaryImg = '' //obj.node.imageUrl[0]
    //       this.ValimageMore = []

    //       // this.ValpricePrev =  parseInt(obj.node.price)
    //       this.ValpricePrev = (Math.round(obj.node.price * 100) / 100).toLocaleString()
    //       this.ValautobidMinimumPricePrev = obj.node.autobidMinimumPrice
    //       this.ValautobidPriceDecrementPrev = obj.node.autobidPriceDecrement

    //       this.currentstate = obj.node.states
    //       this.valcurrency = obj.node.currency

    //       this.Valprice = obj.node.price.toString()
    //       this.ValautobidMinimumPrice = obj.node.autobidMinimumPrice
    //       this.ValautobidPriceDecrement = obj.node.autobidPriceDecrement

    //       this.selectedItemsTag = []
    //       obj.node.tags.forEach((obj: any, key: any) => {
    //         this.SelectedTags.push(obj.id)
    //         this.selectedItemsTag.push({ item_id: obj.id, item_text: obj.tag })
    //       });

    //       // seperate desc
    //       this.Valdescription = obj.node.description

    //       this.divFhSpec = []
    //       this.Valdescription = ''
    //       var desctext = obj.node.description

    //       var a = obj.node.description;
    //       var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);

    //       if (getdetaildesc) {
    //         console.log('>>>>>>>', getdetaildesc[0]);
    //         console.log('>>>>>>>', getdetaildesc[1]);

    //         this.divFhSpec = getdetaildesc[1]
    //         this.divFhSpec = JSON.parse(this.divFhSpec)
    //         //this.Valdescription = desctext.split(getdetaildesc[0]).pop()
    //         this.Valdescription = desctext.replace(getdetaildesc[0], '')

    //         this.divFhSpec.forEach((obj: any, key: any) => {
    //           this.emptyLabelSpec.push({ error: false })
    //           this.emptyValueSpec.push({ error: false })
    //         });


    //         console.log('>>>>>>>', desctext);
    //       }
    //       else {
    //         this.Valdescription = obj.node.description
    //       }

    //       this.countarrFhSpec = this.divFhSpec

    //       // this.EditTags.forEach((el: any) => {
    //       //   console.log('el',el);
    //       //   console.log('inlineCheckbox'+el.id+'');
    //       //   // var check={'target':{'checked':true}}
    //       //   // this.onChangeTag(el.id, check)
    //       //   this.MoreImgFileClick('inlineCheckbox'+el.id+'')

    //       // });


    //     }
    //     x++
    //   });
    // }



    // console.log('parse',parse);
    // console.log('allprod',allprod);

  }

  onSubmit() {
    this.submitted = true;
    console.log(this.productForm?.value);

    this.CombineImg = [];
    this.CombineImg.push(this.FilenamePirmaryImg)

    this.imageSrcMore.forEach((obj: any) => {
      if (obj.name !== '') {
        this.CombineImg.push(obj.name)
      }
    });
    console.log('CombineImg', this.CombineImg);

    console.log('productForm', this.productForm);
    this.productFormInvalid = false
    if (this.productForm.invalid) {
      console.log('productForm invalid');
      this.productFormInvalid = true
      return;
    }


    // initial price - price decrement , harus  > minimum price
    var checkPriceDec = parseInt(this.Valprice) - parseInt(this.ValautobidPriceDecrement)

    // console.log(' '+checkPriceDec+' > '+this.ValautobidMinimumPrice+'');
    // console.log('Valprice>> ',parseInt(this.Valprice));
    // console.log('ValautobidPriceDecrement>> ',parseInt(this.ValautobidPriceDecrement));
    console.log('ValautobidMinimumPrice>> ', parseInt(this.ValautobidMinimumPrice));
    console.log('checkPriceDec>> ', checkPriceDec);


    // if (parseInt(this.ValautobidMinimumPrice) > checkPriceDec) {
    //   console.log('ValautobidMinimumPrice > checkPriceDec price');
    //   this.checkPriceDecCalculate = true
    //   this.productFormInvalid = true
    //   return;
    // } else {
    //   this.checkPriceDecCalculate = false
    //   this.productFormInvalid = false
    // }
    this.checkMinPrice()
    

    //check desc spec input
    // emptyLabelSpec : any = []
    // emptyValueSpec : any = []

    // valspecValueErr: boolean=false;
    // valspecLabelErr:boolean=false;

    if(this.valspecValue !==''){
      if(this.valspecLabel ==''){
        alert('Label spec is empty.');
        this.valspecLabelErr = true
        return;
      }
    }  
    if(this.valspecLabel !==''){
      if(this.valspecValue ==''){
        alert('Value spec is empty.');
        this.valspecValueErr = true
        return;
      }
    } 
    if (this.valspecLabel !== '' && this.valspecValue !== '') {
      this.countarrFhSpec.push({ label: this.valspecLabel, value: this.valspecValue })
      this.valspecValue = ''
      this.valspecLabel = ''
      this.valspecLabelErr = false
      this.valspecValueErr = false

      this.emptyLabelSpec.push({ error: false })
      this.emptyValueSpec.push({ error: false })

      this.ValSpecEmpty = false
    }


    if (this.valspecLabelErr) {
      alert('Please check the input again.');
      return;
    }
    if (this.valspecValueErr) {
      alert('Please check the input again..');
      return;
    }

    var err = 0;
    this.emptyLabelSpec.forEach((obj: any, key: any) => {
      if (this.emptyLabelSpec[key].error) {
        err++
      }
    });
    this.emptyValueSpec.forEach((obj: any, key: any) => {
      if (this.emptyValueSpec[key].error) {
        err++
      }
    });

    if (err > 0) {
      alert('Please check the input again...');
      return;
    }


    this.SelectedTags = []
    console.log('>>> ',this.selectedItemsTag.length);
    if (this.selectedItemsTag.length > 0) {
      this.selectedItemsTag.forEach((obj: any) => {
        if(this.SelectedTags.indexOf(obj.item_id) !== -1){
        } else {
          this.SelectedTags.push(obj.item_id)
        }
        // this.SelectedTags.push(obj.item_id)
      });
    }
    // console.log('selectedItemsTag : ',this.selectedItemsTag);
    // console.log('SelectedTags : ',this.SelectedTags);
    // alert('cek tag post data');
    // return;

    if(this.productFormInvalid){
      return
    }

    if (this.EditProductId == '' || this.EditProductId == null) {
        //upload image
        this.postImage()
      //this.SubmitData()
    } else {

      //upload update image
      this.postUpdateImage()
      //this.EditData()
    }
  }
  SubmitData() {
    console.log('productForm validd');

    var name = this.Valname
    var brand = this.Valbrand
    // var description = this.Valdescription
    var sku = this.Valsku
    var manufacturerSku = this.ValmanufacturerSku
    var price = parseFloat(this.Valprice).toFixed(2) //this.Valprice
    var imageFileName = this.CombineImg
    var isDisplayPriceOnShopfront = this.ValisDisplayPriceOnShopfront
    var isDisplayProductOnShopfront = this.ValisDisplayProductOnShopfront
    // var tags = this.SelectedTags
    var tags = this.SelectedTags
    // var quotationInitialPriceOffer = parseInt(this.ValquotationInitialPriceOffer)
    var quotationInitialPriceOffer = parseFloat(this.Valprice).toFixed(2) //this.Valprice

    var autobidMinimumPrice = this.ValautobidMinimumPrice
    var autobidPriceDecrement = (this.ValautobidPriceDecrement)?this.ValautobidPriceDecrement:null
    var isAutobidEnabled = this.ValisAutobidEnabled
    var isActivatedByUser = this.ValisActivatedByUser

    //combine spec label value array with desc
    var description = this.Valdescription + '<fhspecdiv>' + JSON.stringify(this.countarrFhSpec) + '</fhspecdiv>'


    if (!this.ValisAutobidEnabled) {
      var autobidMinimumPrice = null
      var autobidPriceDecrement = null
    }


    console.log('name', name);
    console.log('brand', brand);
    console.log('description', description);
    console.log('sku', sku);
    console.log('manufacturerSku', manufacturerSku);
    console.log('price', price);
    console.log('imageFileName', imageFileName);
    console.log('isDisplayPriceOnShopfront', isDisplayPriceOnShopfront);
    console.log('isDisplayProductOnShopfront', isDisplayProductOnShopfront);
    console.log('tags', tags);
    console.log('quotationInitialPriceOffer', quotationInitialPriceOffer);
    console.log('autobidMinimumPrice', autobidMinimumPrice);
    console.log('autobidPriceDecrement', autobidPriceDecrement);
    console.log('isAutobidEnabled', isAutobidEnabled);
    console.log('isActivatedByUser', isActivatedByUser);

    this.successResponse = false;
    this.successResponseName = ''

    let ArrData:any
    ArrData ={
      name: name,
      brand: brand,
      description: description,
      sku: sku,
      manufacturerSku: manufacturerSku,
      price: price,
      imageFileName: imageFileName,
      isDisplayPriceOnShopfront: isDisplayPriceOnShopfront,
      isDisplayProductOnShopfront: isDisplayProductOnShopfront,
      tags: tags,
      quotationInitialPriceOffer: quotationInitialPriceOffer,
      autobidMinimumPrice: autobidMinimumPrice,
      autobidPriceDecrement: autobidPriceDecrement,
      isAutobidEnabled: isAutobidEnabled,
      isActivatedByUser: isActivatedByUser,
      currency:this.valcurrency
    }

    //     console.log('ArrData : ',ArrData);
    // alert('post insert data');
    // return;


    this.apollo
      .mutate({
        mutation: postmutationProduct,
        variables: {CompanyProductInsertInput: ArrData}
        //variables: {
          // name: name,
          // brand: brand,
          // description: description,
          // sku: sku,
          // manufacturerSku: manufacturerSku,
          // price: price,
          // imageFileName: imageFileName,
          // isDisplayPriceOnShopfront: isDisplayPriceOnShopfront,
          // isDisplayProductOnShopfront: isDisplayProductOnShopfront,
          // tags: tags,
          // quotationInitialPriceOffer: quotationInitialPriceOffer,
          // autobidMinimumPrice: autobidMinimumPrice,
          // autobidPriceDecrement: autobidPriceDecrement,
          // isAutobidEnabled: isAutobidEnabled,
          // isActivatedByUser: isActivatedByUser
        //}
      })
      .subscribe(
        (res: any) => {
          console.log("succes, get data:", res);
          if (res.data) {
            //this.successregistration=true;
            //alert('Success add product.');
            this.successResponse = true;
            this.successResponseName = 'Product <strong>' + res.data.insertCompanyProduct.name + '</strong> added successfully.'

            this.EditProductId = res.data.insertCompanyProduct.id
            if (this.actionbtn == 'draft') {
              //draft
              this.successdraft = true
              window.scroll(0, 0)
              this.getEditData = res.data.insertCompanyProduct

              this.prevSelectedTags=[]
              res.data.insertCompanyProduct.tags.forEach((obj: any, key: any) => {
                this.prevSelectedTags.push(obj.id)
              });

            }
            else if (this.actionbtn == 'preview') {
              //preivew
              //this.openmodaldraft()

              const modalRef = this.modalService.open(ModalComponent, {
                modalDialogClass: 'modal-dialog-centered',
                centered: true,
                size: 'sm'
              })
      
              var titleNotif = 'Success!'
              var descNotif = 'Your data has been successfully saved as a draft!'
              var redirectNotif ='/vendor/product/detail/'+this.EditProductId
      
              
              modalRef.componentInstance.modalinfo = 'info'
              modalRef.componentInstance.infoTitle = titleNotif
              modalRef.componentInstance.infoSuccess = descNotif
              modalRef.componentInstance.infoFailed = null
              modalRef.componentInstance.closeTxt = 'PREVIEW'
      
              modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                if (res){
                  this.router.navigate([redirectNotif]);
                } 
              })

            }
            else {
              //done
              //this.openmodaldone()

              this.resetData()

              const modalRef = this.modalService.open(ModalComponent, {
                modalDialogClass: 'modal-dialog-centered',
                centered: true,
                size: 'sm'
              })
      
              var titleNotif = 'Success'
              var descNotif = 'Success saved data!'
              var redirectNotif ='/vendor/product-list'
      
              
              if(this.PrevEditProductId!==''){
                titleNotif = 'Changes Saved Successfully!'
                descNotif = 'To Publish the changes, a Manager Approval is needed!'
                redirectNotif = '/vendor/product/compare/'+this.EditProductId
              } else {
                titleNotif = 'Success!'
                descNotif = 'Your data has been successfully saved!'
                redirectNotif = '/vendor/product/detail/'+this.EditProductId
              }
              if(this.currentstate=='UNPUBLISHED'){
                redirectNotif = '/vendor/product/detail/'+this.EditProductId
              } 
 
              modalRef.componentInstance.modalinfo = 'info'
              modalRef.componentInstance.infoTitle = titleNotif
              modalRef.componentInstance.infoSuccess = descNotif
              modalRef.componentInstance.infoFailed = null
              modalRef.componentInstance.closeTxt = 'DONE'
      
              modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                if (res){
                  this.router.navigate([redirectNotif]);
                } 
              })
      


              
            }

          }
          else {
            alert('Failed, please try again later.');
          }
          //this.pageloading=false;

        },
        error => {

          this.pageloading = false
          console.log('error',error);
          var errMsg = error + '';
          var res = errMsg.split(":");
          var resMsg = res[res.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          //alert(msg)
          this.popupmodal.showError(msg,null).subscribe((res:any) => {})

          // console.log("there was an error sending the query", error);

          // // const errorLink = onError(({ networkError }) => {
          // //   const networkErrorRef:HttpErrorResponse = networkError as HttpErrorResponse;
          // //   if (networkErrorRef && networkErrorRef.status === 401) {
          // //     console.log('Going to login page...');
          // //   }
          // // });
          // // console.log(errorLink)

          // onError(({ graphQLErrors, networkError }) => {
          //   if (graphQLErrors)
          //     graphQLErrors.map(({ message, locations, path }) =>
          //       console.log(
          //         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          //       ),
          //     );

          //   if (networkError) console.log(`[Network error]: ${networkError}`);
          // });


          // // }
          // alert('Oops.. Something went wrong.');

          // var errMsg = error + '';
          // var res = errMsg.split(":");
          // var resMsg = res[res.length - 1];
          // var msg = (resMsg) ? resMsg : errMsg
          // alert(msg)

          // //this.pageloading=false;
        }
      );

    // //dummy data
    // this.successResponse=true;
    // this.successResponseName='res.data.insertCompanyProduct.name'
    // this.resetData()

  }

  EditData() {

    console.log('edit data');
    // console.log('this.Valprice',this.Valprice);
    // console.log('this.ValautobidMinimumPrice',this.ValautobidMinimumPrice);
    console.log('this.getEditData', this.getEditData);


    var name = this.Valname
    var brand = this.Valbrand
    var description = this.Valdescription
    var sku = this.Valsku
    var manufacturerSku = this.ValmanufacturerSku
    var price = parseFloat(this.Valprice).toFixed(2) // this.Valprice
    var imageFileName = this.CombineImg
    var isDisplayPriceOnShopfront = this.ValisDisplayPriceOnShopfront
    var isDisplayProductOnShopfront = this.ValisDisplayProductOnShopfront
    var currency = this.valcurrency
    var tags = this.SelectedTags
    // var quotationInitialPriceOffer = parseInt(this.ValquotationInitialPriceOffer)   || 0
    var quotationInitialPriceOffer = parseFloat(this.Valprice).toFixed(2) // this.Valprice

    var autobidMinimumPrice = this.ValautobidMinimumPrice
    var autobidPriceDecrement = (this.ValautobidPriceDecrement)?this.ValautobidPriceDecrement:null
    var isAutobidEnabled = this.ValisAutobidEnabled

    if (!this.ValisAutobidEnabled) {
      var autobidMinimumPrice = null
      var autobidPriceDecrement = null
    }


    //combine spec label value array with desc
    var description = this.Valdescription + '<fhspecdiv>' + JSON.stringify(this.countarrFhSpec) + '</fhspecdiv>'


    console.log('name', name);
    console.log('brand', brand);
    console.log('description', description);
    console.log('sku', sku);
    console.log('manufacturerSku', manufacturerSku);
    console.log('price', price);
    console.log('imageFileName', imageFileName);
    console.log('isDisplayPriceOnShopfront', isDisplayPriceOnShopfront);
    console.log('isDisplayProductOnShopfront', isDisplayProductOnShopfront);
    console.log('tags', tags);
    console.log('quotationInitialPriceOffer', quotationInitialPriceOffer);
    console.log('autobidMinimumPrice', autobidMinimumPrice);
    console.log('autobidPriceDecrement', autobidPriceDecrement);
    console.log('isAutobidEnabled', isAutobidEnabled);
    // console.log('isActivatedByUser',isActivatedByUser);


    var productId = this.EditProductId

    this.successResponse = false;
    this.successResponseName = ''



    var EditVar = {}
    //Object.assign(EditVar, { productId: productId });



    // Object.assign(EditVar, {description : description});
    // Object.assign(EditVar, {imageFileName : imageFileName});
    // Object.assign(EditVar, {isDisplayPriceOnShopfront : isDisplayPriceOnShopfront});
    // Object.assign(EditVar, {tags : tags});
    // Object.assign(EditVar, {isAutobidEnabled : isAutobidEnabled});

    if(this.UpdateimageData.length>0){
      Object.assign(EditVar, { imageFileName: this.UpdateimageData });
    }

    if (this.getEditData['isDisplayPriceOnShopfront'] !== isDisplayPriceOnShopfront) {
      Object.assign(EditVar, { isDisplayPriceOnShopfront: isDisplayPriceOnShopfront });
    }

    if (this.getEditData['isDisplayProductOnShopfront'] !== isDisplayProductOnShopfront) {
      Object.assign(EditVar, { isDisplayProductOnShopfront: isDisplayProductOnShopfront });
    }

    if (this.getEditData['currency'] !== currency) {
      Object.assign(EditVar, { currency: currency });
    }

    

    if (this.getEditData['name'] !== name) {
      Object.assign(EditVar, { name: name });
    }
    if (this.getEditData['brand'] !== brand) {
      Object.assign(EditVar, { brand: brand });
    }
    if (this.getEditData['sku'] !== sku) {
      Object.assign(EditVar, { sku: sku });
    }

    if (this.getEditData['manufacturerSku'] !== manufacturerSku) {
      Object.assign(EditVar, { manufacturerSku: manufacturerSku });
    }

    // console.log('price get data : ',this.getEditData['price']);
    // console.log('price submit data : ',price);

    // console.log('price submit gettofix : ',parseFloat(this.getEditData['price']).toFixed(2));
    // console.log('price submit tofix : ',parseFloat(price).toFixed(2));
    

    // let num = 1000000.01;
    // let text = num.toLocaleString(Option.standard(),Option.decimal());
    // console.log('price test : ',text);


    // if(this.getEditData['price']!==parseInt(price)){
    if(parseFloat(this.getEditData['price']).toFixed(2)!==parseFloat(price).toFixed(2)){  
    Object.assign(EditVar, { price: price });
    }

    //if (this.getEditData['quotationInitialPriceOffer'] !== parseInt(quotationInitialPriceOffer)) {
    if(parseFloat(this.getEditData['quotationInitialPriceOffer']).toFixed(2)!==parseFloat(quotationInitialPriceOffer).toFixed(2)){   
      Object.assign(EditVar, { quotationInitialPriceOffer: quotationInitialPriceOffer });
    }


    var varautobidMinimumPrice:any = parseFloat(autobidMinimumPrice).toFixed(2)
    var varautobidPriceDecrement:any = parseFloat(autobidPriceDecrement).toFixed(2)

    if(isNaN(varautobidMinimumPrice)){
      varautobidMinimumPrice = 0
    }
    if(isNaN(varautobidPriceDecrement)){
      varautobidPriceDecrement = 0
    }

    if (Math.round(varautobidMinimumPrice * 100) !== Math.round(this.getEditData['autobidMinimumPrice'] * 100)){
      Object.assign(EditVar, { autobidMinimumPrice: autobidMinimumPrice });
    }
    if (Math.round(varautobidPriceDecrement * 100) !== Math.round(this.getEditData['autobidPriceDecrement'] * 100)){
      Object.assign(EditVar, { autobidPriceDecrement: autobidPriceDecrement });
    }

    // if (this.getEditData['autobidMinimumPrice'] !== autobidMinimumPrice) {
    //   Object.assign(EditVar, { autobidMinimumPrice: autobidMinimumPrice });
    // }

    // if (this.getEditData['autobidPriceDecrement'] !== autobidPriceDecrement) {
    //   Object.assign(EditVar, { autobidPriceDecrement: autobidPriceDecrement });
    // }

    if (this.getEditData['isAutobidEnabled'] !== isAutobidEnabled) {
      Object.assign(EditVar, { isAutobidEnabled: isAutobidEnabled });
    }

    if (this.getEditData['description'] !== description) {
      Object.assign(EditVar, { description: description });
    }

    console.log('> tags',tags)
    console.log('> prevSelectedTags',this.prevSelectedTags)

    if(JSON.stringify(this.prevSelectedTags)!==JSON.stringify(tags)){
      Object.assign(EditVar, { tags: tags});
    } 

    console.log('EditVar', EditVar);

    console.log('get tag ',this.getEditData['tags']);
    console.log('tag ',this.selectedItemsTag);
    console.log('tag ',tags);

    if(!Object.keys(EditVar).length){
      //alert('no edit data');

      const modalRef = this.modalService.open(ModalComponent, {
        modalDialogClass: 'modal-dialog-centered',
        centered: true,
        backdrop : 'static',
        keyboard : false,
        size: 'sm'
      })
      modalRef.componentInstance.modalinfo = 'info'
      modalRef.componentInstance.infoTitle = 'Opps!' // 'Success!' 
      modalRef.componentInstance.infoSuccess = null
      modalRef.componentInstance.infoFailed = 'No changes data for this product.'
      modalRef.componentInstance.closeTxt = 'Close'

      return
    }






    const head = `mutation updateCompanyProduct($productId: ID!,`;

    // const head_var= `$name: String!, $brand: String, $description: String , $sku: String, $manufacturerSku: String, $price: Float!, $imageFileName: [String!], $isDisplayPriceOnShopfront: Boolean! = false, $isDisplayProductOnShopfront: Boolean! = false, $tags: [ID!], $quotationInitialPriceOffer: Float, $autobidMinimumPrice: Float, $autobidPriceDecrement: Float, $isAutobidEnabled: Boolean! = false`

    var head_var = '';

    var headvar
    //POST changes only
    var headvars: string | any[] = [];
    (this.getEditData['name'] !== name) ? headvars.push('$name: String!') : '';
    (this.getEditData['brand'] !== brand) ? headvars.push('$brand: String') : '';
    (this.getEditData['description'] !== description) ? headvars.push('$description: String') : '';
    (this.getEditData['sku'] !== sku) ? headvars.push('$sku: String') : '';
    (this.getEditData['manufacturerSku'] !== manufacturerSku) ? headvars.push('$manufacturerSku: String') : '';
    (this.getEditData['price'] !== parseInt(price)) ? headvars.push('$price: Float!') : '';
    // (this.getEditData['imageFileName'] !== imageFileName) ? headvars.push('$imageFileName: [String!]') : '';
    (this.getEditData['isDisplayPriceOnShopfront'] !== isDisplayPriceOnShopfront) ? headvars.push('$isDisplayPriceOnShopfront: Boolean! = false') : '';
    (this.getEditData['isDisplayProductOnShopfront'] !== isDisplayProductOnShopfront) ? headvars.push('$isDisplayProductOnShopfront: Boolean! = false') : '';

    //(this.getEditData['tags'] !== tags) ? headvars.push('$tags: [ID!]') : '';
    if(JSON.stringify(this.prevSelectedTags)!==JSON.stringify(tags)){ //if(JSON.stringify(this.getEditData['tags'])!==JSON.stringify(this.selectedItemsTag)){
      headvars.push('$tags: [ID!]')
    }

    (this.getEditData['quotationInitialPriceOffer'] !== parseInt(quotationInitialPriceOffer)) ? headvars.push('$quotationInitialPriceOffer: Float') : '';


    if (Math.round(varautobidMinimumPrice * 100) !== Math.round(this.getEditData['autobidMinimumPrice'] * 100)){
      headvars.push('$autobidMinimumPrice: Float')
    }
    if (Math.round(varautobidPriceDecrement * 100) !== Math.round(this.getEditData['autobidPriceDecrement'] * 100)){
      headvars.push('$isAutobidEnabled: Boolean! = false')
    }

    // (this.getEditData['autobidMinimumPrice'] !== autobidMinimumPrice) ? headvars.push('$autobidMinimumPrice: Float') : '';
    // (this.getEditData['autobidPriceDecrement'] !== autobidPriceDecrement) ? headvars.push('$autobidPriceDecrement: Float') : '';


    (this.getEditData['isAutobidEnabled'] !== isAutobidEnabled) ? headvars.push('$isAutobidEnabled: Boolean! = false') : '';



    console.log('headvars to post:', headvars);


    if (headvars.length > 0) {
      console.log('>0');
      for (headvar in headvars) {

        if (typeof headvar[headvar] !== 'object') {
          head_var += headvars[headvar].toString() + ', ';

        }

      }
    }
    console.log('head_var', head_var);




    const head_close = `) { updateCompanyProduct(input: { productId: $productId companyProductUpdateInputData: {`;

    const header = head + head_var + head_close;

    let body = '';

    var field


    //POST changes only
    var fields: string | any[] = [];
    (this.getEditData['name'] !== name) ? fields.push('name : $name') : '';
    (this.getEditData['brand'] !== brand) ? fields.push('brand : $brand') : '';
    (this.getEditData['description'] !== description) ? fields.push('description : $description') : '';
    (this.getEditData['sku'] !== sku) ? fields.push('sku : $sku') : '';
    (this.getEditData['manufacturerSku'] !== manufacturerSku) ? fields.push('manufacturerSku : $manufacturerSku') : '';
    (this.getEditData['price'] !== parseInt(price)) ? fields.push('price : $price') : '';
    // (this.getEditData['imageFileName'] !== imageFileName) ? fields.push('imageFileName : $imageFileName') : '';
    (this.getEditData['isDisplayPriceOnShopfront'] !== isDisplayPriceOnShopfront) ? fields.push('isDisplayPriceOnShopfront : $isDisplayPriceOnShopfront') : '';
    (this.getEditData['isDisplayProductOnShopfront'] !== isDisplayProductOnShopfront) ? fields.push('isDisplayProductOnShopfront : $isDisplayProductOnShopfront') : '';

    //(this.getEditData['tags'] !== tags) ? fields.push('tags : $tags') : '';
    if(JSON.stringify(this.prevSelectedTags)!==JSON.stringify(tags)){//if(JSON.stringify(this.getEditData['tags'])!==JSON.stringify(this.selectedItemsTag)){
      fields.push('tags : $tags')
    }
    


    (this.getEditData['quotationInitialPriceOffer'] !== parseInt(quotationInitialPriceOffer)) ? fields.push('quotationInitialPriceOffer : $quotationInitialPriceOffer') : '';
    (this.getEditData['autobidMinimumPrice'] !== autobidMinimumPrice) ? fields.push('autobidMinimumPrice : $autobidMinimumPrice') : '';
    (this.getEditData['autobidPriceDecrement'] !== autobidPriceDecrement) ? fields.push('autobidPriceDecrement : $autobidPriceDecrement') : '';
    (this.getEditData['isAutobidEnabled'] !== isAutobidEnabled) ? fields.push('isAutobidEnabled : $isAutobidEnabled') : '';

    console.log('fields to post:', fields);


    if (fields.length > 0) {
      console.log('>0');
      for (field in fields) {

        if (typeof fields[field] !== 'object') {
          body += fields[field].toString() + ' ';

        }

      }
    }
    console.log('body', body);


    const foot: String = '}  }) {      __typename,      ...on CompanyProduct {id          name          brand    description          sku    manufacturerSku    price    imageUrl    tags{        id        tag    }    isDisplayPriceOnShopfront    isDisplayProductOnShopfront    quotationInitialPriceOffer    autobidMinimumPrice    autobidPriceDecrement    isAutobidEnabled    state        publishApprovalStatus    publishedOngoingApprovalStatus    isActivatedByUser    createdDate    createdBy    modifiedDate    modifiedBy      }      ... on CompanyProductDraft {          id          draftName          draftBrand          draftDescription          draftSku          draftManufacturerSku          draftPrice          draftImageUrl          draftTags{              id              tag          }          draftIsDisplayPriceOnShopfront          draftIsDisplayProductOnShopfront          draftQuotationInitialPriceOffer          draftAutobidMinimumPrice          draftAutobidPriceDecrement          draftIsAutobidEnabled          draftCreatedDate          draftCreatedBy          draftModifiedDate          draftModifiedBy     }   }}';





    const query = header + body + foot;
    console.log('test query ', query);



    // this.apollo.watchQuery<any>({
    //   //query: getTags
    //   query: gql`${query}`,
    // })
    // .valueChanges
    // .subscribe(({data}) => {
    //   this.tags = data.getCompanyTagList;
    // })


    console.log('EditVar : ',EditVar);
    // alert('post edit data');
    // return;



    this.apollo
      .mutate({
        //mutation: editProduct,


        // mutation: gql`${query}`,
        //variables: EditVar


        mutation: editmutationProduct,
        variables: {
          productId: this.EditProductId,
          companyProductUpdateInputData : EditVar
        },

        // variables: {
        //   productId : productId,
        //   name: name,
        //   brand: brand,
        //   description: description,
        //   sku: sku,
        //   manufacturerSku: manufacturerSku,
        //   price: price,
        //   imageFileName: imageFileName,
        //   isDisplayPriceOnShopfront:  isDisplayPriceOnShopfront,
        //   isDisplayProductOnShopfront: isDisplayProductOnShopfront,
        //   tags: tags,
        //   quotationInitialPriceOffer: quotationInitialPriceOffer,
        //   autobidMinimumPrice: autobidMinimumPrice,
        //   autobidPriceDecrement: autobidPriceDecrement,
        //   isAutobidEnabled: isAutobidEnabled,
        //   // isActivatedByUser: isActivatedByUser
        // }
      })
      .subscribe(
        (res: any) => {
          console.log("succes, get data edit:", res);
          if (res.data) {
            this.successResponse = true;

            if (res.data.updateCompanyProduct.name) {
              this.successResponseName = 'Product <strong>' + res.data.updateCompanyProduct.name + '</strong> edit successfully.'
            } else {
              this.successResponseName = 'Product <strong>' + res.data.updateCompanyProduct.draftName + '</strong> added on draft, need to approval to confirm the changes.'
            }

            //this.EditProductId =res.data.insertCompanyProduct.id
            if (this.actionbtn == 'draft') {
              //draft
              this.successdraft = true
              window.scroll(0, 0)
            }
            else if (this.actionbtn == 'preview') {
              //preivew
              // this.openmodaldraft()

              const modalRef = this.modalService.open(ModalComponent, {
                modalDialogClass: 'modal-dialog-centered',
                centered: true,
                size: 'sm'
              })
      
              var titleNotif = 'Success!'
              var descNotif = 'Your data has been successfully saved as a draft!'
              var redirectNotif ='/vendor/product/detail/'+this.EditProductId
      
              
              modalRef.componentInstance.modalinfo = 'info'
              modalRef.componentInstance.infoTitle = titleNotif
              modalRef.componentInstance.infoSuccess = descNotif
              modalRef.componentInstance.infoFailed = null
              modalRef.componentInstance.closeTxt = 'PREVIEW'
      
              modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                if (res){
                  this.router.navigate([redirectNotif]);
                } 
              })

            }
            else {
              //done
              //this.openmodaldone()
              this.resetData()


              const modalRef = this.modalService.open(ModalComponent, {
                modalDialogClass: 'modal-dialog-centered',
                centered: true,
                size: 'sm'
              })
      
              var titleNotif = 'Success'
              var descNotif = 'Success saved data!'
              var redirectNotif ='/vendor/rfq-participating-in-list'
      
              
              if(this.PrevEditProductId!==''){
                titleNotif = 'Changes Saved Successfully!'
                descNotif = 'To Publish the changes, a Manager Approval is needed!'
                redirectNotif = '/vendor/product/compare/'+this.EditProductId
              } else {
                titleNotif = 'Success!'
                descNotif = 'Your data has been successfully saved!'
                redirectNotif = '/vendor/product/detail/'+this.EditProductId
              }
              if(this.currentstate=='UNPUBLISHED'){
                redirectNotif = '/vendor/product/detail/'+this.EditProductId
              } 
 
              modalRef.componentInstance.modalinfo = 'info'
              modalRef.componentInstance.infoTitle = titleNotif
              modalRef.componentInstance.infoSuccess = descNotif
              modalRef.componentInstance.infoFailed = null
              modalRef.componentInstance.closeTxt = 'DONE'
      
              modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
                if (res){
                  this.router.navigate([redirectNotif]);
                } 
              })
      



            }

          }
          else {
            alert('Failed, please try again later.');
          }
          //this.pageloading=false;

        },
        error => {
          console.log("there was an error sending the query", error);
          //alert('Oops.. Something went wrong.');

          var errMsg = error + '';
          var res = errMsg.split(":");
          var resMsg = res[res.length - 1];
          var msg = (resMsg) ? resMsg : errMsg
          //alert(msg)

          const modalRef = this.modalService.open(ModalComponent, {
            modalDialogClass: 'modal-dialog-centered',
            centered: true,
            backdrop : 'static',
            keyboard : false,
            size: 'sm'
          })
          modalRef.componentInstance.modalinfo = 'info'
          modalRef.componentInstance.infoTitle = 'Opps!' // 'Success!' 
          modalRef.componentInstance.infoSuccess = null
          modalRef.componentInstance.infoFailed = msg
          modalRef.componentInstance.closeTxt = 'Close'

          //this.pageloading=false;
        }
      );

    // //dummy data
    // this.successResponse=true;
    // this.successResponseName='res.data.insertCompanyProduct.name'





  }

  resetData() {
    this.Valname = ''
    this.Valbrand = ''
    this.Valdescription = ''
    this.Valsku = ''
    this.ValmanufacturerSku = ''
    this.Valprice = ''
    this.ValimageFileName = ''
    this.ValisDisplayPriceOnShopfront = true
    this.ValisDisplayProductOnShopfront = true
    this.Valtags = ''
    this.ValquotationInitialPriceOffer = ''
    this.ValautobidMinimumPrice = ''
    this.ValautobidPriceDecrement = ''
    this.ValisAutobidEnabled = false
    this.ValisActivatedByUser = false
    this.ValprimaryImg = ''
    this.FilenamePirmaryImg = ''
    this.ValimageMore = []
    this.productForm.reset();
    this.submitted = false;
    this.imageSrc=''

    this.imageSrcMore = []
    for (let i = 0; i < this.MoreImgCount.length; i++) {
      this.imageSrcMore.push({ 'id': i, 'val': '', 'name': '' })
    }

    this.SelectedTags = []

    var clist = document.getElementsByTagName("input");
    for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }

    this.ValisDisplayPriceOnShopfront = true
    this.ValisDisplayProductOnShopfront = true

    this.imageSrc = ''
    this.ValimageFileName = ''
  }

  getTag() {
    this.apollo.watchQuery<any>({
      query: getTags
    })
      .valueChanges
      .subscribe(({ data }) => {
        this.tags = data.getCompanyTagList;

        this.dropdownListTag = []
        this.tags.forEach((obj: any) => {
          if (obj.isActive) {
            this.dropdownListTag.push({ item_id: obj.id, item_text: obj.tag })
          }
        });
        console.log('dropdownListTag', this.dropdownListTag);

      })
  }


  onChangeTag(val: any, check: any) {
    console.log('onChangeTag', val)
    console.log('check', check)
    if (check.target.checked) {
      this.SelectedTags.push(val)
    } else {
      const index: number = this.SelectedTags.indexOf(val);
      this.SelectedTags.splice(index, 1);
    }
    console.log('this.tags', this.SelectedTags);
  }

  onCheckDisplayPrice(check: any) {
    if (check.target.checked) {
      console.log('onCheckDisplayPrice checked')
      this.ValisDisplayPriceOnShopfront = true
    } else {
      console.log('onCheckDisplayPrice unchecked')
      this.ValisDisplayPriceOnShopfront = false
    }
  }
  onCheckDisplayProduct(check: any) {
    if (check.target.checked) {
      console.log('onCheckDisplayProduct checked')
      this.ValisDisplayProductOnShopfront = true
    } else {
      console.log('onCheckDisplayProduct unchecked')
      this.ValisDisplayProductOnShopfront = false
    }
  }

  onCheckEnableAutobid(check: any) {
    if (check.target.checked) {
      console.log('onCheckEnableAutobid checked')
      this.ValisAutobidEnabled = true
    } else {
      console.log('onCheckEnableAutobid unchecked')
      this.ValisAutobidEnabled = false
    }
    this.checkMinPrice()
    
  }

  onCheckActivatedByUser(check: any) {
    if (check.target.checked) {
      console.log('onCheckActivatedByUser checked')
      this.ValisActivatedByUser = true
    } else {
      console.log('onCheckActivatedByUser unchecked')
      this.ValisActivatedByUser = false
    }
  }

  validateImg(event:any){
    this.errorUploadImg=false
    var img = event.target.files[0]

    let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif']; //,'image/bmp'
    
    var errorimg = 0
    if(img){
      if(allowedExtension.indexOf(img.type)>-1){
        console.log('img ok')
        //alert(img.name+' ok')
      } else {
        console.log('img not ok')
        //alert(img.name+' not ok')
        errorimg++
      }

      const fsize = img.size;
      const file = Math.round((fsize / 1024));
      // The size of the file.
      if (file >= 2048) {
          //alert("File too Big, please select a file less than 2mb");
          console.log('img file over 2mb ')
          errorimg++
      } 

    }
    if(errorimg>0){
      this.errorUploadImg=true
      return false
    } else{
      return true
    }
  }

  onFileChange(event: any) {
    if(!this.validateImg(event)){
      //alert('stopp')
      this.ValimageFileName=''
      this.FilenamePirmaryImg = ''
      this.imageSrcData = null
      return
    }
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          console.log('WidthHeight', img);
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          console.log('Width and Height', width, height);
        };
        this.imageSrc = reader.result as string;
      };

      this.FilenamePirmaryImg = event.target.files[0].name
      this.imageSrcData = event.target.files[0]
    }
  }

  removePirmaryImg() {
    this.imageSrc = ''
    this.ValimageFileName = ''
  }


  onFileChangeMore(event: any, index: number) {
    // console.log('index',index);
    // console.log('event',event);


    if(!this.validateImg(event)){
      //alert('stopp')
      this.MoreImgRemoveFile(index)
      return
    }

    console.log('event.target.files', event.target.files);
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          console.log('Width and Height', width, height);
        };
        var imgPrevMore = reader.result as string;
        //console.log('imgPrevMore', imgPrevMore);

        // this.imageSrcMore.splice(index, 0, imgPrevMore);
        //this.imageSrcMore.push({'id':index,'val':imgPrevMore});
        //console.log('imageSrcMore',this.imageSrcMore);

        var x = 0
        this.imageSrcMore.forEach((obj: any) => {
          if (obj.id == index) {
            this.imageSrcMore[x].val = imgPrevMore
            this.imageSrcMore[x].name = event.target.files[0].name
            this.imageSrcMore[x].data = event.target.files[0]
          }
          x++
        });


      };

    }


  }

  MoreImgFileClick(id: any) {
    console.log('id', id);
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    console.log(element);
    element.click();
  }

  MoreImgRemoveFile(id: any) {
    var x = 0
    this.imageSrcMore.forEach((obj: any) => {
      if (obj.id == id) {
        this.imageSrcMore[x].val = ''
        this.imageSrcMore[x].name = ''
        this.imageSrcMore[x].data = null
      }
      x++
    });

    this.ValimageMore[id] = ''
    console.log(this.ValimageMore);
    console.log(this.imageSrcMore);
  }

  
  onclickMoreImg(idx:number){
    console.log(idx); 
    let element:HTMLElement = document.getElementById('MoreImg'+idx) as HTMLElement;
    element.click();
  }

  openmodaldraft() {
    if (this.displaydraft == "none") {
      this.displaydraft = "block";
    } else {
      this.displaydraft = "none";
    }
  }

  openmodaldone() {
    if (this.displaydone == "none") {
      this.displaydone = "block";
    } else {
      this.displaydone = "none";
    }
  }


  submitaction(val: any) {
    window.scroll(0, 0)
    this.actionbtn = ''
    this.actionbtn = val

    if (val == 'preview') {
      localStorage.removeItem("previewShopSide");
      this.addSpec() 
      // if (this.valspecLabel !== '' && this.valspecValue !== '') {
      //   this.countarrFhSpec.push({ label: this.valspecLabel, value: this.valspecValue })
      // }
      var combinedescription = this.Valdescription + '<fhspecdiv>' + JSON.stringify(this.countarrFhSpec) + '</fhspecdiv>'

      //remap tag text only
      var gettag:any = []
      console.log('>>> ',this.selectedItemsTag.length);
      console.log('>>> ',this.selectedItemsTag);
      if (this.selectedItemsTag.length > 0) {
        this.selectedItemsTag.forEach((obj: any) => {
          if(gettag.indexOf(obj.gettag) !== -1){
          } else {
            gettag.push(obj.item_text)
          }
        });
      }
      console.log('>>> gettag ',gettag);



      var moreImg:any = []
      this.imageSrcMore.forEach((element:string,i:number) => {
        if(this.imageSrcMore[i].val){
          moreImg.push(this.imageSrcMore[i].val)
        }
        
      });

      var setdata ={ 
        name: this.Valname,
        brand: this.Valbrand,
        description: combinedescription,
        sku: this.Valsku,
        manufacturerSku: this.ValmanufacturerSku,
        price: this.Valprice,
        imageFileName: this.imageSrc,
        isDisplayPriceOnShopfront: this.ValisDisplayPriceOnShopfront,
        isDisplayProductOnShopfront: this.ValisDisplayProductOnShopfront,
        tags: gettag,
        quotationInitialPriceOffer: this.ValquotationInitialPriceOffer,
        autobidMinimumPrice: this.ValautobidMinimumPrice,
        autobidPriceDecrement: this.ValautobidPriceDecrement,
        isAutobidEnabled: this.ValisAutobidEnabled,
        isActivatedByUser: this.ValisActivatedByUser,
        primaryImg: this.ValprimaryImg,
        imageMore: moreImg,
        currency: this.valcurrency,
        pricePrev: this.ValpricePrev,
        autobidPriceDecrementPrev: this.ValautobidPriceDecrementPrev,
        autobidMinimumPricePrev: this.ValautobidMinimumPricePrev,
        vendorLogo:localStorage.getItem("COMPANY_LOGO")
      }
      console.log('setdata',setdata);
      localStorage.setItem("previewShopSide",JSON.stringify(setdata));

      window.open('/vendor/product/detail/preview-shop-side/', "_blank");
      return
    }
    this.onSubmit()
  }
  closesuccessdraft() {
    this.successdraft = false
  }

  closeproductFormInvalid() {
    this.productFormInvalid = false
  }

  handleEditorInit(event: any) {
    console.log('handleEditorInit:: ', event)
  }

  removeSpec(i: any) {
    console.log('removeSpec:: ', i)

    var index: number = this.countarrFhSpec.indexOf(i);
    if (index !== -1) {
      this.countarrFhSpec.splice(index, 1);

      this.emptyLabelSpec.splice(index, 1)
      this.emptyValueSpec.splice(index, 1)
    }


    console.log('countarrFhSpec remove:: ', this.countarrFhSpec)
  }

  firstLabelSpec(val: any) {
    if (val?.target.value !== '') {
      this.valspecLabelErr = false
    } else {
      this.valspecLabelErr = true
    }
    this.ValSpecEmpty = true
  }
  firstValueSpec(val: any) {
    if (val?.target.value !== '') {
      this.valspecValueErr = false
    } else {
      this.valspecValueErr = true
    }
    this.ValSpecEmpty = true
  }

  changeLabelSpec(i: any, val: any) {
    console.log('changeLabelSpec:: ', i)
    console.log('val:: ', val?.target.value)

    var index: number = this.countarrFhSpec.indexOf(i);

    if (val?.target.value !== '') {
      this.emptyLabelSpec[index].error = false
    } else {
      this.emptyLabelSpec[index].error = true
    }

    this.countarrFhSpec.forEach((obj: any, key: any) => {
      if (key == index) {
        console.log('>>', obj);
        this.countarrFhSpec[key].label = val?.target.value
      }
    });



  }
  changeValueSpec(i: any, val: any) {
    console.log('changeValueSpec:: ', i)

    var index: number = this.countarrFhSpec.indexOf(i);
    if (val?.target.value !== '') {
      this.emptyValueSpec[index].error = false
    } else {
      this.emptyValueSpec[index].error = true
    }

    this.countarrFhSpec.forEach((obj: any, key: any) => {
      if (key == index) {
        console.log('>>', obj);
        this.countarrFhSpec[key].value = val?.target.value
      }
    });

  }

  addSpec() {
    if (this.valspecLabel == '') {
      this.valspecLabelErr = true
    } else {
      this.valspecLabelErr = false
    }
    if (this.valspecValue == '') {
      this.valspecValueErr = true
    } else {
      this.valspecValueErr = false
    }


    if ((this.valspecLabel == '' && this.valspecValue == '') || (this.valspecLabel == '' || this.valspecValue == '')) {
      this.ValSpecEmpty = true
    }

    if (this.valspecLabel !== '' && this.valspecValue !== '') {
      this.countarrFhSpec.push({ label: this.valspecLabel, value: this.valspecValue })
      this.valspecValue = ''
      this.valspecLabel = ''
      this.valspecLabelErr = false
      this.valspecValueErr = false

      this.emptyLabelSpec.push({ error: false })
      this.emptyValueSpec.push({ error: false })

      this.ValSpecEmpty = false
    }


    // if(this.countarrFhSpec.length>0){
    //   if(this.valspecLabel=='' && this.valspecValue==''){
    //     this.valspecValueErr = false
    //     this.valspecLabelErr = false
    //   }
    // }


  }

  clearSpec() {
    this.valspecLabelErr = false
    this.valspecValueErr = false
    this.ValSpecEmpty = false
    this.valspecValue = ''
    this.valspecLabel = ''
  }

  changevalprice(e: any) {
    var numStr = String(e?.target?.value);
    console.log('numStr', numStr);
    var newval = numStr.replace(/,/g, '')
    if (newval.startsWith('0')) {
      console.log('ada 0')
      newval = newval.substring(1);
    }
    console.log('newval changevalprice: ', newval);
    if (numStr == 'undefined') {
      this.Valprice = null
    } else {
      this.Valprice = newval
    }
    this.checkMinPrice()
  }

  changevalautobit(e: any) {
    var numStr = String(e?.target?.value);
    console.log('numStr', numStr);
    var newval = numStr.replace(/,/g, '')
    if (newval.startsWith('0')) {
      console.log('ada 0')
      newval = newval.substring(1);
    }
    console.log('newval changevalautobit: ', newval);
    if (numStr == 'undefined') {
      this.ValautobidPriceDecrement = null
    } else {
      //this.ValautobidPriceDecrement = newval
      this.ValautobidPriceDecrement = parseFloat(newval).toFixed(2)
    }
    this.checkMinPrice()
    
  }

  changeminprice(e: any) {
    var numStr = String(e?.target?.value);
    console.log('numStr', numStr);
    var newval = numStr.replace(/,/g, '')
    if (newval.startsWith('0')) {
      console.log('ada 0')
      newval = newval.substring(1);
    }
    console.log('newval changeminprice: ', newval);
    if (numStr == 'undefined') {
      this.ValautobidMinimumPrice = null
    } else {
      //this.ValautobidMinimumPrice = newval
      this.ValautobidMinimumPrice = parseFloat(newval).toFixed(2)
    }
    this.checkMinPrice()
    
  }

  back(): void {
    this.navigation.back()
  }

  onItemSelect(item: any) {
    console.log('onItemSelect : ',item);

    // this.selectedItemsTag.push(item)

    var exist = false
    this.selectedItemsTag.forEach((obj: any, key: any) => {
      if (obj.item_id == item.item_id) {
        exist=true
      }
    });

    if(!exist){
      this.selectedItemsTag.push(item)
    } 


  }

  onItemDeSelect(item: any) {
    console.log('onItemDeSelect : ',item);

    this.selectedItemsTag.forEach((obj: any, key: any) => {
      if (obj.id == item.item_id) {
        this.selectedItemsTag.splice(key, 1);
      }
    });
    console.log(this.selectedItemsTag);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll : ',items);

    this.selectedItemsTag = items
    console.log(this.selectedItemsTag);
  }

  onDeselectAll(items: any) {
    console.log('onDeselectAll : ',items);

    this.selectedItemsTag = []
    console.log(this.selectedItemsTag);
  }

  closeErrorModal(){
    this.errornotif='none'
    this.errortext=''
  }

  checkMinPrice(){
    this.ispricezero = false
    this.ispricenegative = false
    this.checkDiscIncCalculate = false
    this.checkPriceDecCalculate = false

    this.invalidMinPrice=false
    this.invalidautobidPriceDecrement = false

    if(this.ValisAutobidEnabled){
      var a:any = parseFloat(this.Valprice).toFixed(2)
      var b:any = parseFloat(this.ValautobidMinimumPrice).toFixed(2)
      var c:any = parseFloat(this.ValautobidPriceDecrement).toFixed(2)
      if (Math.round(a * 100) < Math.round(b * 100)){   // if(parseInt(this.Valprice) <  parseInt(this.ValautobidMinimumPrice)) {      
        this.checkPriceDecCalculate = true
        console.log('if1 ',this.Valprice+' < '+this.ValautobidMinimumPrice);
      }
      if (Math.round(c * 100) > Math.round(a * 100)){   //if (parseInt(this.ValautobidPriceDecrement) > parseInt(this.Valprice)) {   
        this.checkDiscIncCalculate = true
        console.log('if2 ',this.ValautobidPriceDecrement+' > '+this.Valprice);
      }
      if(Math.round(a * 100) < 100){   // if(parseInt(this.Valprice)<0){
        this.ispricenegative = true
        console.log('if3 ',this.Valprice+' < 0');
      }
      if(this.Valprice =='.00' || this.Valprice =='0.00'){
        this.ispricezero = true
        console.log('if4 ',this.Valprice+' == 0.00 / .00');
      }
    } else {
      if(parseInt(this.Valprice)<0){
        this.ispricenegative = true
        console.log('if5 ',this.Valprice+' < 0 parse int');
      }
      if(this.Valprice =='.00' || this.Valprice =='0.00'){
        this.ispricezero = true
        console.log('if6 ',this.Valprice+' else < 0 parse int');
      }
    }



    



    if(this.ValisAutobidEnabled){
      console.log('ccc : ',parseInt(this.ValautobidMinimumPrice));

      if(this.ValautobidMinimumPrice){
        if(Math.round(b * 100) < 0){  // if(parseInt(this.ValautobidMinimumPrice)<0){
          this.invalidMinPrice=true
          console.log('if7 ',this.ValautobidMinimumPrice+' < 0');
        } else {

          if(isNaN(this.ValautobidMinimumPrice)){
            this.invalidMinPrice=true
          } else {
            this.invalidMinPrice=false
          }

          console.log('else if7 ',this.ValautobidMinimumPrice+' else < 0');
        }
      } else {
        this.invalidMinPrice=true
        console.log('else f8  ',this.ValautobidMinimumPrice+' not true');
      }

      console.log('ccc : ',parseInt(this.ValautobidPriceDecrement));
      if(this.ValautobidPriceDecrement){
        if(Math.round(c * 100) < 0){ // if(parseInt(this.ValautobidPriceDecrement)<0){
          this.invalidautobidPriceDecrement=true
          console.log('if9 ',this.ValautobidMinimumPrice+' < 0');
        } else {
          if(isNaN(this.ValautobidPriceDecrement)){  
            this.invalidautobidPriceDecrement=true
          } else {
            this.invalidautobidPriceDecrement=false
          }
          
          console.log('if10 ',this.ValautobidMinimumPrice+' else < 0');
        }
      } else {
        this.invalidautobidPriceDecrement=true
        console.log('if11 ',this.ValautobidMinimumPrice+' not true');
      }
      
    } 






    if(this.ispricezero || this.ispricenegative || this.checkDiscIncCalculate || this.checkPriceDecCalculate || this.invalidMinPrice || this.invalidautobidPriceDecrement){
      this.productFormInvalid = true
      return false
    } else {
      this.productFormInvalid = false
      return true
    }

  }

  getCurrency(newValue:any) {
    if(newValue){
      var getCur = newValue.match(/\(([^)]+)\)/)
      console.log('getCur',getCur);
      if(getCur){
        console.log('true',getCur[1]);
        this.valcurrency= getCur[1]
      } else {
        console.log('false',newValue);
        this.valcurrency = newValue
      }
    }
    console.log('getCurrency valcurrency',this.valcurrency);

  } 



  onFileChangeUpload(event: any) {
    if(!this.validateImg(event)){
      //alert('stopp')
      this.ValimageFileName=''
      this.FilenamePirmaryImg = ''
      this.imageSrcData = null
      return
    }
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          console.log('WidthHeight', img);
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          console.log('Width and Height', width, height);
        };
        this.imageSrc = reader.result as string;
      };

      this.FilenamePirmaryImg = event.target.files[0].name
      this.imageSrcData = event.target.files[0]
    }
  }



  onFileChangeMoreUpload(event: any, index: number) {
    // console.log('index',index);
    // console.log('event',event);


    if(!this.validateImg(event)){
      //alert('stopp')
      this.MoreImgRemoveFile(index)
      return
    }

    console.log('event.target.files', event.target.files);
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          console.log('Width and Height', width, height);
        };
        var imgPrevMore = reader.result as string;
        //console.log('imgPrevMore', imgPrevMore);

        // this.imageSrcMore.splice(index, 0, imgPrevMore);
        //this.imageSrcMore.push({'id':index,'val':imgPrevMore});
        //console.log('imageSrcMore',this.imageSrcMore);

        var x = 0
        this.imageSrcMore.forEach((obj: any) => {
          if (obj.id == index) {
            this.imageSrcMore[x].val = imgPrevMore
            this.imageSrcMore[x].name = event.target.files[0].name
            this.imageSrcMore[x].data = event.target.files[0]
          }
          x++
        });


      };

    }


  }



  executeIndependentTasksInParallel() {

    var ArrFile: any[] = []
    var ArrUrl: any[]= []
    var timedelay = 500
    this.CombineImgData.forEach((element: string | Blob, key: number) => {
      if(this.productImagePreSignedUrl[key]){
        timedelay = timedelay + (1+key)
        ArrFile.push({'file':element,'url':this.productImagePreSignedUrl[key],'time':timedelay})
        ArrUrl.push(this.productImagePreSignedUrl[key])
      }
    })
    ArrUrl = [ArrUrl]



    //rename file to 1.2.3.4jpg

    this.pageloading = true
    from(ArrUrl)
      .pipe(
        concatMap((x:any,k:any) => {
          console.log('x :',x)
          console.log('k :',k)
          return forkJoin(
            x.map((t:any,key:any) => {
              console.log('t ::: ',t)
              console.log('key ::: ',key)
              console.log('ArrFile[k][file] ::: ',ArrFile[key]['file'])
              console.log('ArrFile[k][time] ::: ',ArrFile[key]['time'])
              return this.uploadImagesService.uploadAwsS3(t,ArrFile[key]['file'],ArrFile[key]['time']).pipe(catchError(value => of({type: k, failed: true})));
            })
          );
        })
      )
      .subscribe((data:any) => {
        console.log('executeIndependentTasksInParallel done: ',data);

          var fail = 0
          if(data?.length>0){
            for(let result of data){
              if(result?.failed){
                fail++
              }
            }
          }

          this.pageloading = false

          console.log('executeIndependentTasksInParallel fail: ',fail);
          if(fail>0){
            //alert('ada gagal upload')
            this.popupmodal.showError('Images failed to upload, please reupload again',null).subscribe((res:any) => {})
          } else {
            this.SubmitData()
          }
      });

  }


  postImage(){
    

    this.CombineImg = [];
    this.CombineImgData = [];
    this.CombineImg.push(this.FilenamePirmaryImg)
    this.CombineImgData.push(this.imageSrcData)
    
    this.imageSrcMore.forEach((obj: any) => {
      if (obj.name !== '') {
        this.CombineImg.push(obj.name)
        this.CombineImgData.push(obj.data)
      }
    });

    //---- replace img 1,2,3,4-----
    console.log('CombineImgData :: ',this.CombineImgData)
    this.CombineImgData.forEach((element: string | Blob, key: number) => {

      this.CombineImgData[key] = new File([element], (key+1)+".jpg");
      this.CombineImg[key] = (key+1)+".jpg"
    })
    console.log('CombineImgData :: ',this.CombineImgData)
    //----/replace img 1,2,3,4-----

    var filename = this.Valname
    //var arrfile = this.arrImgName
    var arrfile = this.CombineImg
    var arrfileData = this.CombineImgData
    console.log('---- combine ----')
    console.log('productname', filename);
    console.log('arrfile', arrfile);
    console.log('arrfileData', arrfileData);

    // var filename = this.Valname
    // var arrfile = this.arrImgName

    this.totalUpload=arrfileData.length
    this.successUpload=0
    console.log('this.totalUpload : ',this.totalUpload)
    console.log('this.successUpload : ',this.successUpload)

    this.uploadImagesService.getproductImagePreSignedUrl(arrfile,filename).subscribe((res:any) => {

      console.log(res)
      if(res.data?.productImagePreSignedUrl){


        this.productImagePreSignedUrl = res.data?.productImagePreSignedUrl
        this.executeIndependentTasksInParallel()
  
  
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      

    });




  }

  postUpdateImage(){
    

    console.log('this.imageSrc : ', this.imageSrc);
    console.log('this.ValimageFileName : ', this.ValimageFileName);


    console.log('this.imageSrcData : ', this.imageSrcData);
    console.log('this.imageSrcMore : ', this.imageSrcMore);

    

    if(!this.imageSrc){
      return
    }


    this.CombineImg = [];
    this.CombineImgData = [];

    if(this.imageSrcData){
      this.CombineImg.push(this.FilenamePirmaryImg)
      this.CombineImgData.push(this.imageSrcData)
    }

    

    console.log('---- postUpdateImage ----')
    console.log('CombineImg', this.CombineImg);
    console.log('CombineImgData', this.CombineImgData);
    console.log('imageSrcMore', this.imageSrcMore);



    var getLastIdImg =0;
    var getLAstIdForUpload = 0
    this.getImgDetail.forEach((obj: any) => {

      var filename = Number(obj.substring(obj.lastIndexOf('/')+1).split('.')[0])
      if(!isNaN(filename)){
        console.log('filename => ',filename)
        if(getLastIdImg<filename){
          getLastIdImg = filename
        }
      }


    })
    getLAstIdForUpload = getLastIdImg
    console.log('getLastIdImg => ',getLastIdImg)
    

    this.imageSrcMore.forEach((obj: any) => {
      if (obj.data) {
        this.CombineImg.push(obj.name)
        this.CombineImgData.push(obj.data)
      }
    });







    //UpdateimageData
    console.log('UpdateimageData : ',this.UpdateimageData)
    //check changes image
    console.log('imgAwsName : ',this.imgAwsName)
    
    this.UpdateimageData=[]
    //set first img
    if(this.imageSrcData){
      getLastIdImg++
      this.UpdateimageData.push(''+getLastIdImg+'.jpg')
    } else{
      // use exist
      if(this.imgAwsName[0]){
        this.UpdateimageData.push(''+this.imgAwsName[0]+'.jpg')
      }
      
    }
    //set more img
    var exst=1
    this.imageSrcMore.forEach((element: string , key: number) => {
      console.log('imageSrcMore element >> ',element)
      console.log('imageSrcMore element val >> ',this.imageSrcMore[key].val)
      if(this.imageSrcMore[key].data){
        getLastIdImg++
        this.UpdateimageData.push(''+getLastIdImg+'.jpg')

      } else {
        // console.log('element >> ',element)
        // console.log('element val >> ',this.imageSrcMore[key].val)
        //if(this.imageSrcMore[key].val!==''){
          //this.UpdateimageData.push(''+(exst+1)+'.jpg')
          if(this.imgAwsName[key+1]){
            this.UpdateimageData.push(''+this.imgAwsName[key+1]+'.jpg')
          }
          exst++
        //}

      }
    })

   
    //if no changes images.
    console.log(' exst ::',exst)
    console.log('getLastIdImg ::',getLastIdImg)
    if(exst>=getLastIdImg){
      // this.UpdateimageData=[]
      // this.CombineImg=[]
      // this.CombineImgData=[]
    } else {
      
      // //---- replace img 1,2,3,4-----
      console.log('CombineImgData :: ',this.CombineImgData)
      this.CombineImgData.forEach((element: string | Blob, key: number) => {
        this.CombineImgData[key] = new File([element], (getLastIdImg+1)+".jpg");
        this.CombineImg[key] = (getLastIdImg+1)+".jpg"
        getLastIdImg++
      })

      console.log('CombineImg :: ',this.CombineImg)
      console.log('CombineImgData :: ',this.CombineImgData)
      // //----/replace img 1,2,3,4-----


    }

    console.log('post UpdateimageData ::',this.UpdateimageData)


    console.log('getLAstIdForUpload : ',getLAstIdForUpload)

    // //---- replace img 1,2,3,4-----
    console.log('CombineImgData :: ',this.CombineImgData)
    this.CombineImgData.forEach((element: string | Blob, key: number) => {
      getLAstIdForUpload++
      this.CombineImgData[key] = new File([element], (getLAstIdForUpload)+".jpg");
      this.CombineImg[key] = (getLAstIdForUpload)+".jpg"
    })

    console.log('CombineImg :: ',this.CombineImg)
    console.log('CombineImgData :: ',this.CombineImgData)
    // //----/replace img 1,2,3,4-----



    var filename = this.Valname
    //var arrfile = this.arrImgName
    var arrfile = this.CombineImg
    var arrfileData = this.CombineImgData
    console.log('---- combine ----')
    console.log('productname', filename);
    console.log('arrfile', arrfile);
    console.log('arrfileData', arrfileData);

    // var filename = this.Valname
    // var arrfile = this.arrImgName

    this.totalUpload=arrfileData.length
    this.successUpload=0
    console.log('this.totalUpload : ',this.totalUpload)
    console.log('this.successUpload : ',this.successUpload)
    // alert('prepare upload img')
    // return

    //if update image
    if(arrfile.length>0){
      console.log('arrfile',arrfile)

      this.uploadImagesService.getproductImagePreSignedUrl(arrfile,filename).subscribe((res:any) => {

      
        console.log(res)
        var delaytime = 125
        if(res.data?.productImagePreSignedUrl){
  
  
          this.productImagePreSignedUrl = res.data?.productImagePreSignedUrl
          this.executeIndependentTasksInParallelUpdate()
    
  
        }
        else if(res.errors){
          var error = res.errors[0].message
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          var errortext = msg
  
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            // //force redirect on specific errortype
            // var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            // if(forceRedirect){
            //   window.location.href = forceRedirect
            //   return
            // }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
  
      });
    }
    //else update image, go to update
    else {
      // alert('submit edit data')
      // return
      this.EditData()
    }

  }

  executeIndependentTasksInParallelUpdate() {

    var ArrFile: any[] = []
    var ArrUrl: any[]= []
    var timedelay = 500
    this.CombineImgData.forEach((element: string | Blob, key: number) => {
      if(this.productImagePreSignedUrl[key]){
        timedelay = timedelay + (1+key)
        ArrFile.push({'file':element,'url':this.productImagePreSignedUrl[key],'time':timedelay})
        ArrUrl.push(this.productImagePreSignedUrl[key])
      }
    })
    ArrUrl = [ArrUrl]

    //rename file to 1.2.3.4jpg

    this.pageloading = true
    from(ArrUrl)
      .pipe(
        concatMap((x:any,k:any) => {
          console.log('x :',x)
          console.log('k :',k)
          return forkJoin(
            x.map((t:any,key:any) => {
              console.log('t ::: ',t)
              console.log('key ::: ',key)
              console.log('ArrFile[k][file] ::: ',ArrFile[key]['file'])
              console.log('ArrFile[k][time] ::: ',ArrFile[key]['time'])
              return this.uploadImagesService.uploadAwsS3(t,ArrFile[key]['file'],ArrFile[key]['time']).pipe(catchError(value => of({type: k, failed: true})));
            })
          );
        })
      )
      .subscribe((data:any) => {
        console.log('executeIndependentTasksInParallel done: ',data);

          var fail = 0
          if(data?.length>0){
            for(let result of data){
              if(result?.failed){
                fail++
              }
            }
          }

          this.pageloading = false

          console.log('executeIndependentTasksInParallel fail: ',fail);
          if(fail>0){
            //alert('ada gagal upload')
            this.popupmodal.showError('Images failed to upload, please reupload again',null).subscribe((res:any) => {})
          } else {
            // alert('submit edit data, done upload img')
            // return
            this.EditData()
          }
      });

  }


}

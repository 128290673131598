<div class="helpbg">
    <div class="helpbgLR">
        <div class="helpdiv">
            <div class="py-5 text-center">
                <h3 class="text-white">Ada pertanyaan mengenai Cepetdapet ?</h3>
                <h4 class="text-white">Jelajahi halaman ini untuk lebih mengerti tentang kami. Silahkan hubungi kami jika Anda tidak dapat menemukan jawaban yang Anda inginkan di sini.</h4>
            </div>
        </div>
    </div>

</div>
<div class="bg-white">

    <div *ngIf="PageLoading" class="text-center  mb-4">
        <div class="text-center pt-5 pb-5">
            <div class="loader loader--style8" title="7">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
                style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                    repeatCount="indefinite" />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                    repeatCount="indefinite" />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                    repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                    repeatCount="indefinite" />
                </rect>
              </svg>
            </div>
          </div>
      </div>
    

    <div  *ngIf="!PageLoading" class="container {{ redirectLoading ? 'onloading':''}}">
        <div class="row pt-4 pb-5" *ngIf="!onSearch">
            <!-- help root menu on frontpage -->
            <div class="col-12" *ngIf="arrPath.length<2">

                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 col-sm-8 py-5">

                        <div class="input-group mb-3 searchHelpDiv">
                            <input type="text" class="form-control" placeholder="Ketik kata kunci" aria-label="Ketik kata kunci" aria-describedby="button-addon2"  [(ngModel)]="valSearch" (keydown.enter)="helpSearch()" [ngModelOptions]="{standalone: true}">
                            <button class="btn" type="button" id="button-addon2" (click)="helpSearch()"><i class="bi bi-search"></i></button>
                        </div>
                        <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>

                    </div>
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 text-center pb-3">
                        <h3 class="searchHelpTitle">Pilih Topik Sesuai Kendalamu</h3>
                    </div>

       
                    <div *ngFor="let menu of mainMenu; let i = index" class="col-sm-3 mb-3">
                        <a href="/help/{{menu.slugUrl}}" (click)="setloading()">
                            <div class="boxCategory text-center p-1">
                                <img src="{{menu.logoUrl}}" class="boxCategoryImg mt-4 mb-2">
                                <br>
                                <p class="mb-4">{{menu.menuName}}</p>
                            </div>
                        </a>
                    </div>
 

                    <div class="col-sm-12 mb-5 pb-5" ></div>
                    
                </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 order-2 order-sm-1" *ngIf="arrPath.length>1">
                <div class="helpbox p-3 mb-4 sidemenu">
                    
                    <div *ngFor="let menu of remapSideMenu; let i = index">

                        <div *ngIf="!menu.isHaveChild">

                            <div class="pointer row g-0 divsidemenu {{isActive(menu.slugUrl)==true?'isActive':''}} {{ (menu.isParentActive)?'isActive': '' }} {{menu.levelIndex>1?'listmenu':''}} {{menu.isParentOpen?'':'hidemenu'}} {{contentData?.helpSideMenuId==menu.helpSideMenuId?'isActive':''}} {{contentData?.helpSideMenuId==menu.helpSideMenuId?'isActive':''}}" (click)="openMenu(menu.helpSideMenuId,menu.levelIndex)">
                                
                                <div class="{{menu.isHaveChild?'col-10':'col-12'}}"> 
                                    <div class="{{ spacingDiv(menu.levelIndex) }} m-3">
                                        <a  href="{{checkurl(menu.slugUrl)}}" class="{{menu.levelIndex>1?isActive(menu.slugUrl)==true || contentData?.helpSideMenuId==menu.helpSideMenuId ?'ChildisActive':'':''}}" (click)="setloading()">
                                        <div class="row g-0">
                                            <div class="col-2" *ngIf="menu.levelIndex>1">•</div>
                                            <!-- <div class="{{menu.levelIndex>1?'col-10':'col-12'}}">{{menu.menuName}} parentOpen:{{menu.isParentOpen}} menuOpen:{{menu.isMenuOpen}}</div> -->
                                            <div class="{{menu.levelIndex>1?'col-10':'col-12'}}">{{menu.menuName}}</div>
                                        </div>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div *ngIf="menu.isHaveChild" class="col-2">
                                    <div class="my-3">
                                        <!-- <i class="bi bi-chevron-{{menu.isMenuOpen?'up':'down'}}"></i> -->
                                        <i class="bi bi-chevron-{{checkisChildOpen(menu.helpSideMenuId)?'up':'down'}}"></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="menu.isHaveChild">
                            <div class="pointer row g-0 divsidemenu {{isActive(menu.slugUrl)==true?'isActive':''}} {{ (menu.isParentActive)?'isActive': '' }} {{menu.levelIndex>1?'listmenu':''}} {{menu.isParentOpen?'':'hidemenu'}} {{contentData?.helpSideMenuId==menu.helpSideMenuId?'isActive':''}}" (click)="openMenu(menu.helpSideMenuId,menu.levelIndex)">
                                
                                <div class="{{menu.isHaveChild?'col-10':'col-12'}}"> 
                                    <div class="{{ spacingDiv(menu.levelIndex) }} m-3">
                                        <a  href="{{checkurl(menu.slugUrl)}}" class="{{menu.levelIndex>1?isActive(menu.slugUrl)==true || contentData?.helpSideMenuId==menu.helpSideMenuId ?'ChildisActive':'':''}}" (click)="setloading()">
                                        <div class="row g-0">
                                            <div class="col-2" *ngIf="menu.levelIndex>1">•</div>
                                            <!-- <div class="{{menu.levelIndex>1?'col-10':'col-12'}}">{{menu.menuName}} parentOpen:{{menu.isParentOpen}} menuOpen:{{menu.isMenuOpen}}</div> -->
                                            <div class="{{menu.levelIndex>1?'col-10':'col-12'}}">{{menu.menuName}}</div>
                                        </div>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div *ngIf="menu.isHaveChild" class="col-2">
                                    <div class="my-3">
                                        <!-- <i class="bi bi-chevron-{{menu.isMenuOpen?'up':'down'}}"></i> -->
                                        <i class="bi bi-chevron-{{checkisChildOpen(menu.helpSideMenuId)?'up':'down'}}"></i>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-8 col-lg-9 order-1 order-sm-2"  *ngIf="arrPath.length>1">

                <!-- list menu data -->
                <ng-container *ngIf="menuListData">

                    <div  *ngFor="let menu of menuListData; let i = index"  class="p-3 helpbox mb-4">
                        <a href="/help/detail/{{menu.url}}" (click)="setloading()">
                        <div class="helptitle">
                            {{menu.title}}
                        </div>
                        </a>
                        <div class="helptext pt-2" *ngIf="menu.shortDesc">
                            <div class="shortDescText">{{menu.shortDesc}}</div>
                        </div>
                        <p class="m-0 pt-2"><a class="linkMore" href="/help/detail/{{menu.url}}" (click)="setloading()">Selengkapnya</a></p>
                    </div>
                </ng-container>

                <!-- content data -->
                <ng-container *ngIf="contentData">

                    <div class="helpcontent p-3">

                        <div class="helptitle">
                            {{detailTitleContent}}
                        </div>

                        <div class="helptext py-3">
                            <div [innerHtml]="detailHelpContent"></div>
                        </div>
                        <!-- {{contentData?.helpSideMenuId | json }}<br><hr><br>
                        {{checkParentByContent(contentData?.helpSideMenuId)}}<br><hr><br> -->
    
                        <div class="mb-3"  *ngIf="!submitted">
                            <h5><b>Apakah informasi ini berguna ?</b></h5>
                        </div>
                        <div  *ngIf="!submitted">
                            <button id="mtmHelpFeedbackBtnYes" class="btn btn-thumb me-3" (click)="successHelp()"><i class="bi bi-hand-thumbs-up"></i> Ya</button>
                            <button class="btn btn-thumb" (click)="helpSurvey()"><i class="bi bi-hand-thumbs-down"></i> Tidak</button>
                        </div>
                        <div  *ngIf="submitted">
                            <div class="alert alert-info alert-sm" role="alert">
                                Terima kasih. Masukan anda sudah kami terima.
                              </div>
                        </div>
                    </div>

                </ng-container>


                <!-- empty list / content -->
                <ng-container *ngIf="totalEmptyData>0">

                    <ng-container *ngIf="PageLoading">
                        Loading..
                    </ng-container>
                    <ng-container *ngIf="!PageLoading">
 
                        <div class="row d-flex align-items-center justify-content-between">
                            <div class="col-12 text-center py-3">
                                <h3 class="searchHelpTitle">Pencarian tidak ditemukan.</h3>
                            </div>
    
                            <div class="col-12 col-sm-2"></div>
                            <div class="col-12 col-sm-8 py-2">
        
                                <div class="input-group mb-3 searchHelpDiv">
                                    <input type="text" class="form-control" placeholder="Ketik kata kunci" aria-label="Ketik kata kunci" aria-describedby="button-addon2"  [(ngModel)]="valSearch" (keydown.enter)="helpSearch()" [ngModelOptions]="{standalone: true}">
                                    <button class="btn" type="button" id="button-addon2" (click)="helpSearch()"><i class="bi bi-search"></i></button>
                                </div>
                                <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>
        
                            </div>
                            <div class="col-12 col-sm-2"></div>
    
                        </div>   
                    </ng-container>

                </ng-container>
     
            </div>

            <div class="col-12 d-none" *ngIf="!url1 && !url2 && !url3">

                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 col-sm-8 py-5">

                        <div class="input-group mb-3 searchHelpDiv">
                            <input type="text" class="form-control" placeholder="Ketik kata kunci" aria-label="Ketik kata kunci" aria-describedby="button-addon2">
                            <button class="btn" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
                        </div>

                    </div>
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 text-center pb-3">
                        <h3 class="searchHelpTitle">Pilih Topik Sesuai Kendalamu</h3>
                    </div>

                    <!-- ic_window-apps-24-filled@3x.png
                    ic_storefront-bold@3x.png
                    ic_round-shopping-cart-checkout@3x.png
                    ic_handshake-fill@3x.png -->

                    <div class="col-sm-3 mb-3">
                        <a href="/help/tentang-cepetdapet" (click)="setloading()">
                            <div class="boxCategory text-center p-1">
                                <img src="/assets/images/icons/ic_window-apps-24-filled@3x.png" class="boxCategoryImg mt-4 mb-2">
                                <br>
                                <p class="mb-4">Tentang Cepetdapet</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-sm-3 mb-3">
                        <a href="/help/tentang-vendor" (click)="setloading()">
                            <div class="boxCategory text-center p-1">
                                <img src="/assets/images/icons/ic_storefront-bold@3x.png" class="boxCategoryImg mt-4 mb-2">
                                <br>
                                <p class="mb-4">Tentang Vendor</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-sm-3 mb-3">
                        <a href="/help/tentang-procurement" (click)="setloading()">
                            <div class="boxCategory text-center p-1">
                                <img src="/assets/images/icons/ic_round-shopping-cart-checkout@3x.png" class="boxCategoryImg mt-4 mb-2">
                                <br>
                                <p class="mb-4">Tentang Procurement</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-sm-3 mb-3">
                        <a href="/help/cara-bertransaksi" (click)="setloading()">
                            <div class="boxCategory text-center p-1">
                                <img src="/assets/images/icons/ic_handshake-fill@3x.png" class="boxCategoryImg mt-4 mb-2">
                                <br>
                                <p class="mb-4">Cara Bertransaksi</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-12 mb-5 pb-5" ></div>
                    
                </div>

            </div>


            <div class="col-12 col-sm-6 col-md-4 col-lg-3 order-2 order-sm-1 d-none" *ngIf="url1 || url2 || url3 || !PageLoading">
                <div class="helpbox p-3 mb-4 sidemenu">
                    


                <!-- 
                <ngb-accordion id="acc1" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="detailAcc">
                    <ng-template ngbPanelTitle>
                    <span>&#9733; <b>Fancy</b> title &#9733;</span>
                    </ng-template>
                    <ng-template ngbPanelContent> Hello World!!!! </ng-template>
                </ngb-panel>
                </ngb-accordion> -->

                    <ngb-accordion id="acc1" #acc="ngbAccordion" activeIds="toggle-{{isMenuOpen('menu')}}" (panelChange)="toggleAccordian($event)" [closeOthers]="false"> <!-- custom-panel-1 : change to function return id open toogle -->
                        <ngb-panel  *ngFor="let menu of sidebarmenu; let i = index"  id="toggle-{{menu.id}}" title="{{ menu.text }}" cardClass="menu1 {{menu.child.length==0 ? 'nochild' : ''}}">
                            <!-- <ng-template ngbPanelHeader>
                              <div class="d-flex align-items-center justify-content-between">
                                <h5 class="m-0">{{ menu.text }}</h5>
                                <button ngbPanelToggle class="btn btn-link p-0">{{menu.isOpen}}</button>
                              </div>
                            </ng-template> -->
                            <ng-template ngbPanelContent>

                                <!-- sub menu -->
                                    <ngb-accordion id="accsub" #accsub="ngbAccordion" activeIds="toggle-{{isMenuOpen('submenu')}}"  (panelChange)="toggleAccordian($event)"  [closeOthers]="false">
                                        <ngb-panel  *ngFor="let submenu of menu.child; let j = index"   id="toggle-{{submenu.id}}" title="{{ submenu.text }}" cardClass="menu2 {{submenu.child.length==0 ? 'nochildsub' : ''}}" >
                                            <ng-template ngbPanelContent>
                                                
                                                <!-- child sub menu -->
                                                    <ngb-accordion id="accchildsub" #accchildsub="ngbAccordion"  activeIds="toggle-{{isMenuOpen('childsubmenu')}}"   (panelChange)="toggleAccordian($event)"  [closeOthers]="true">
                                                        <ngb-panel  *ngFor="let childsubmenu of submenu.child; let k = index"   id="toggle-{{childsubmenu.id}}" title="&#x2022; &nbsp; {{ childsubmenu.text }}" cardClass="menu3 nochildsubchl">
                                                        </ngb-panel>
                                                    </ngb-accordion>

                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>

                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>


                    <!-- <div class="p-5">

                          
                          <hr/>
                          <button class="btn btn-sm btn-outline-primary mr-2" (click)="acc.toggle('toggle-2')">Toggle second 2</button>
                          <button class="btn btn-sm btn-outline-primary mr-2" (click)="accsub.toggle('toggle-22')">Toggle second 22</button> 
                          <button class="btn btn-sm btn-outline-primary mr-2" (click)="accchildsub.toggle('toggle-222')">Toggle second 222</button>

                          <button class="btn btn-sm btn-outline-primary mr-2" (click)="acc.toggle('toggle-1')">Toggle first</button>
                          <button class="btn btn-sm btn-outline-primary mr-2" (click)="acc.toggle('toggle-2')">Toggle second</button>
                          
                    </div> -->
    
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-8 col-lg-9 order-1 order-sm-2 d-none"  *ngIf="!HelpLoading">

                <ng-container *ngIf="listChild.length>0">

                    <div *ngFor="let child of listChild; let i = index" >
                        <div class="p-3 helpbox mb-4">
                            <a href="/help/{{child.url}}" (click)="setloading()">
                            <div class="helptitle">
                                {{child.title}}
                            </div>
                            </a>
                            <div class="helptext pt-2" *ngIf="child.shortDesc">
                                <div class="shortDescText">{{child.shortDesc}}</div>
                            </div>
                            <p class="m-0 pt-2"><a class="linkMore" href="/help/{{child.url}}" (click)="setloading()">Selengkapnya</a></p>
                        </div>
                    </div>

                </ng-container>
                <ng-container *ngIf="listChild.length==0 && (detailTitle && detailHelp)">

                    <div>
                        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                            <ol class="breadcrumb divbreadc">
                              <li class="breadcrumb-item">
                                <a href="/help" (click)="setloading()">{{'menu.help'|translate}}</a>
                              </li>
                              <li *ngFor="let nav of breadcrumb; let i = index" class="breadcrumb-item {{(breadcrumb.length-1==i) ?'active':''}}" >
                                <a *ngIf="breadcrumb.length-1!==i" href="/help/{{nav.url}}" (click)="setloading()" aria-current="page">{{nav.text}}</a>
                                <span *ngIf="breadcrumb.length-1==i">{{nav.text}}</span>
                              </li>
    
                              <!-- <li class="breadcrumb-item"><a href="#">Home</a></li>
                              <li class="breadcrumb-item"><a href="#">Home</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Library</li> -->
                            </ol>
                        </nav>
                    </div>
    
    


                    <div class="helpcontent p-3">

                        <div class="helptitle">
                            {{detailTitle}}
                        </div>

                        <div class="helptext py-3">
                            <div [innerHtml]="detailHelp"></div>
                        </div>
    
                        <div class="mb-3">
                            <h5><b>Apakah informasi ini berguna ?</b></h5>
                        </div>
                        <div>
                            <button class="btn btn-thumb me-3"><i class="bi bi-hand-thumbs-up"></i> Ya</button>
                            <button class="btn btn-thumb"><i class="bi bi-hand-thumbs-down"></i> Tidak</button>
                        </div>
                    </div>
                </ng-container>


            </div>

            <div class="col-12 col-sm-6 col-md-8 col-lg-9 order-1 order-sm-2 d-none" *ngIf="HelpLoading">
                <div class="text-center pt-5 pb-5">
                    <div class="loader loader--style8" title="7">
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                      </svg>
                    </div>
                </div>  
            </div>

            <div class="col-12 py-4 helpmorediv order-3 d-none">
                <div class="row">
                    <div class="col-sm-12 col-lg-5 py-2">
                        <h3 class="helpmore">Tidak menemukan apa yang Anda cari ?</h3>
                        <p class="helpmore">Berbincang dengan kami atau kirim email pada kami</p>
                    </div>

                    <div class="col-4 col-sm-4 col-md-4 col-lg-2 p-0 p-sm-1">
                        <a href="#">
                            <div class="helpmorebox p-2 m-1">
                                <table width="100%">
                                    <tr>
                                        <td rowspan="2">
                                            <i class="bi bi-chat px-2"></i>
                                        </td>
                                        <td><b>Chat Support</b></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Live Chat
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </a>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-2 p-0 p-sm-1">
                        <a href="#">
                            <div class="helpmorebox p-2 m-1">
                                <table width="100%">
                                    <tr>
                                        <td rowspan="2">
                                            <i class="bi bi-telephone px-2"></i>
                                        </td>
                                        <td><b>Hotline</b></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            (021) 554433
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </a>

                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-2 p-0 p-sm-1">
                        <a href="#">
                            <div class="helpmorebox p-2 m-1">
                                <table width="100%">
                                    <tr>
                                        <td rowspan="2">
                                            <i class="bi bi-envelope px-2"></i>
                                        </td>
                                        <td><b>Kirim Email</b></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            info@fh.id
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </a>

                    </div>
                    
                </div>

            </div>
        </div>

        <div class="row pt-4 pb-5" *ngIf="onSearch">
            <div class="col-12">

                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-12 col-sm-2"></div>
                    <div class="col-12 col-sm-8 pt-5">

                        <div class="input-group mb-3 searchHelpDiv">
                            <input type="text" class="form-control" placeholder="Ketik kata kunci" aria-label="Ketik kata kunci" aria-describedby="button-addon2"  [(ngModel)]="valSearch" (keydown.enter)="helpSearch()" [ngModelOptions]="{standalone: true}">
                            <button class="btn" type="button" id="button-addon2" (click)="helpSearch()"><i class="bi bi-search"></i></button>
                        </div>
                        <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>

                    </div>
                    <div class="col-12 col-sm-2"></div>

                    

                    <div class="col-12">
                        <!-- list menu data -->
                        <ng-container *ngIf="searchResult.length>0">
                            <div class="text-center py-3">
                                <p>
                                    {{searchResult.length}} hasil untuk : <b>{{onValSearch}}</b>
                                </p>
                            </div>
                            <div  *ngFor="let menu of searchResult; let i = index"  class="p-3 helpbox mb-4">
                                <a href="/help/detail/{{menu.url}}" (click)="setloading()">
                                <div class="helptitle">
                                    {{menu.title}}
                                </div>
                                </a>
                                <div class="helptext pt-2" *ngIf="menu.shortDesc">
                                    <div class="shortDescText">{{menu.shortDesc}}</div>
                                </div>
                                <p class="m-0 pt-2"><a class="linkMore" href="/help/detail/{{menu.url}}" (click)="setloading()">Selengkapnya</a></p>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="searchResult.length==0">
                            <div class="text-center px-5">
                                <img class="py-3" style="max-width:70px;" src="/assets/images/icons/ic_search-minus-outline@3x.png">
                                <h3><b>{{'result_not_found' | translate}}</b></h3>
                                <p class="pb-3">{{'result_not_found_detail' | translate}}</p>
                            </div>
                        </ng-container>

                        <div class="text-center pt-3">
                            <a href="/help" style="padding: 8px 12px;border-radius: 8px;box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);background-color: #3d85ff;color:#fff">
                                Kembali ke halaman bantuan
                            </a>
                        </div>
                    </div>                    

                    <div class="col-sm-12 mb-5 pb-5" ></div>
                    
                </div>

            </div>
        </div>
    </div>
</div>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/_services/navigation';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, OperatorFunction, Subscription } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import Getcurrency from 'src/app/_helpers/currency';
import { gql, Apollo } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/_services/company.services';
import { StaffService } from 'src/app/_services/staff.services';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Util from 'src/app/_helpers/util';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import CheckErrortype from 'src/app/_helpers/check-errortype';

const PostInsertUser = gql`
mutation insertCompanyStaff($roleMembershipId:Int!,$name:String!,$email:EmailAddress!){
  insertCompanyStaff(input: {
      companyStaffInsertInputData: {
          roleMembershipId: $roleMembershipId
          userData: {
              name: $name
              email: $email
          }
      }
  }){
      id
      role
      isActive
      userData{
          name
          email
          secondaryEmail
      }
  }
}
`;

const PostUpdateUser = gql`
mutation updateCompanyStaffs($targetStaffId:ID!,$isActive:Boolean,$roleMembershipId:Int){
  updateCompanyStaffs(input: {
      companyStaffsUpdateInputData: [
          {
              targetStaffId: $targetStaffId
              isActive: $isActive
              roleMembershipId:$roleMembershipId
          }
      ]
  }){
      id
      role
      isActive
      userData{
          name
          email
          secondaryEmail
      }
  }
}
`;


const postResendEmail = gql`
mutation resendVerificationEmail($email:String!){
  resendVerificationEmail(input: {
      email: $email
  })
}
`;


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  //ValCompanyname:string=''
  
  Valname:string=''
  Vallastname:string=''
  Valusername:string=''
  Valpassword:string=''
  Valemail:string=''
  ValsecondaryEmail:string=''
  ValroleMembershipId:string='32'
  ValisActive:string='true'
  

  userID:any
  actionUrl:any
  labelActionBtn:string='Save'
  labelDiv:string='Add'
  addmode:boolean=true
  editmode:boolean=false
  viewmode:boolean=false
  verify:any
  completeresendemail: boolean=false;


  submitted = false;
  loading = false;
  UserFormInvalid:boolean=false;

  errornotif='none'
  successnotif='none'
  successnotifaddress='none'
  errortext=''

  
  goToPrevpage:boolean=false
  PageLoading:boolean=false

  states = Getcurrency.getAll();

  // formatter = (result: string) => result.toUpperCase();
  formatter = (result: string) => result;

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.states.filter((v: string) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  UserForm: any;


  ChangePassForm : any;
  Valoldpassword:string=''
  Valnewpassword:string=''
  Valrenewpassword:string=''
  chgpasssubmitted = false;

  isChangePassword: boolean=false;

  listRegisterdCompany:any=[]
    
  ValcompanyStaffId:any=''

  constructor(
    private formBuilder: FormBuilder,
    private navigation: NavigationService,
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private staffService: StaffService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private popupmodal : Popupmodal,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getRegisteredCompany()
    // this.userID = this.route.snapshot.paramMap.get('id');
    // this.actionUrl = this.route.snapshot.paramMap.get('action');
    // this.verify = this.route.snapshot.paramMap.get('verify');


    var getusercompany = localStorage.getItem("USERCOMPANY")
    if (getusercompany !== null) {
      var usercompany = JSON.parse(getusercompany);
      this.userID = usercompany.id
    }

    this.actionUrl = 'view';
    this.verify = null;
    

    console.log('userID : ', this.userID);
    console.log('actionUrl : ', this.actionUrl);

    if (this.userID !== '' && this.actionUrl !== '') {
      if(this.actionUrl=='view'||this.actionUrl=='edit'||this.actionUrl=='delete'){
        //this.getDataUser(this.userID)

        
        var getuserdata = localStorage.getItem("USERDATA")
        if (getuserdata !== null) {
          var userdata = JSON.parse(getuserdata);
          this.Valname=userdata.fullname
          this.Valemail=userdata.email
          this.ValsecondaryEmail=userdata.secondaryEmail
          this.ValisActive ='true'
        }

        var getusercompany = localStorage.getItem("USERCOMPANY")
        if (getusercompany !== null) {
          var usercompany = JSON.parse(getusercompany);
          this.ValroleMembershipId = usercompany.roleMembership
          this.ValcompanyStaffId = usercompany.id
        }

        if(this.actionUrl=='view'){
          this.labelDiv='View'
          this.viewmode=true
        }
        else if(this.actionUrl=='edit'){
          this.labelDiv='Edit'
          this.editmode=true
        }  
        this.labelActionBtn='Edit'
        
        this.addmode=false

      } 
    }

    if(this.addmode){
      this.UserForm = this.formBuilder.group({
        roleMembershipId: ['', [Validators.required]],
        // isActive: ['', [Validators.required]],
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
      });
    } else {
      this.UserForm = this.formBuilder.group({
        // roleMembershipId: ['', [Validators.required]],
        // isActive: ['', [Validators.required]],
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],
      });
    }

    
    this.ChangePassForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
      renewpassword: ['', [Validators.required]],
    },
    {
      validator: MustMatch('newpassword', 'renewpassword')
    }
    );

    //this.CompanyDetail()
    // var getcompanyname = localStorage.getItem("COMPANYSELECTNAME")
    // if(getcompanyname){
    //   this.ValCompanyname=getcompanyname
    // }

    // this.getCompanyLoc()
    // this.getTag()

    // this.dropdownSettingsTag = {
    //   singleSelection: false,
    //   idField: 'item_id',
    //   textField: 'item_text',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };
    
  }
  get f() { return this.UserForm?.controls; }

  get g() { return this.ChangePassForm?.controls; }
  
  // CompanyDetail() {
  //   this.PageLoading=true    

  //   this.companyService
  //   .getCompanyName()
  //   .subscribe((res:any) => {
  //     this.PageLoading=false 
  //     if(res.data.company){
  //       this.ValCompanyname=res.data.company.name
  //     }
  //     else if(res.errors){

  //       var error = res.errors[0].message
  //       this.loading=false
  //       console.log('error',error);
  //       var errMsg = error + '';
  //       var result = errMsg.split(":");
  //       var resMsg = result[result.length - 1];
  //       var msg = (resMsg)? resMsg: errMsg
  //       console.log('msg',msg);
  //       //alert(msg)
  //       this.errornotif='block'
  //       this.errortext = msg

  //       if(res.errors[0].extensions.type){
  //         if(res.errors[0].extensions.type == 'StaffDoNotHaveAccess'){
  //           this.errortext = "You don't have access to this process"
  //           this.goToPrevpage=true
  //           this.PageLoading=true 
  //         } 
  //       }


  //     }
      
  //   });

  // }

  getRegisteredCompany(){
    this.staffService
    .getregistedCompany()
    .subscribe((res:any) => {
      this.PageLoading=false 
      console.log('res : ',res);

      if(res.data){
        console.log('getregistedCompany', 'res', res)

        if(res.data.viewUserRegisteredCompanyTable){
          this.listRegisterdCompany = res.data.viewUserRegisteredCompanyTable
        }

      }
      else if(res.errors){

        var error = res.errors[0].message
        this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        if(res.errors[0].extensions.type){

          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            console.log(data);

            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
            
          });
          
        }

        
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            //window.location.reload();
          } 
        })

       
      }
      
    });

  }

  getRole(id:any){
    return Util.getRole(id)
  }

  ConfirmLeave(staffid:any, companyName:any){


    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'md'
    })
    modalRef.componentInstance.modalconfirm = 'modal'
    modalRef.componentInstance.title = 'Confirm Leave Company'
    modalRef.componentInstance.subtitle = 'Are you sure want to leave <b>"'+companyName+'"</b> ?'
    modalRef.componentInstance.yesStr = 'Confirm'
    modalRef.componentInstance.noStr = 'Cancel'

    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res) this.leaveCompany(staffid)
    })
  }

  leaveCompany(staffid:any){
   // alert(staffid);

   this.PageLoading=true
   this.staffService
   .poststaffLeaveCompany(staffid)
   .subscribe((res:any) => {
     this.PageLoading=false 
     console.log('res : ',res);

     const modalRef = this.modalService.open(ModalComponent, {
       modalDialogClass: 'modal-dialog-centered',
       centered: true,
       backdrop : 'static',
       keyboard : false,
       size: 'sm'
     })

     
     if(res.data.userLeaveCompany){
       console.log('poststaffLeaveCompany', 'res', res)
 
       modalRef.componentInstance.modalinfo = 'info'
       modalRef.componentInstance.infoTitle = 'Success!' // 'Success!' 
       modalRef.componentInstance.infoSuccess = 'Success leave company'
       modalRef.componentInstance.infoFailed = null
       modalRef.componentInstance.closeTxt = 'Close'
 
       modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
         if (res){
           this.getRegisteredCompany()
         } 
       })


     }
     else if(res.errors){

       var error = res.errors[0].message
       this.loading=false
       console.log('error',error);
       var errMsg = error + '';
       var result = errMsg.split(":");
       var resMsg = result[result.length - 1];
       var msg = (resMsg)? resMsg: errMsg
       console.log('msg',msg);

       var errortext = msg

       if(res.errors[0].extensions.type){

         this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
           console.log(data);

           if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
             errortext = data
           }
           
         });
         
       }

       modalRef.componentInstance.modalinfo = 'info'
       modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
       modalRef.componentInstance.infoSuccess = null
       modalRef.componentInstance.infoFailed = errortext
       modalRef.componentInstance.closeTxt = 'Close'

       modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
         if (res){
           window.location.reload();
         } 
       })

      
     }
     
   });

  }

  back(): void {
    this.navigation.back()
  }

  onSubmit() {
    this.submitted = true

    var errSubmit = 0


    console.log(this.UserForm)
    if (this.UserForm.invalid) {
      console.log('this.UserForm.invalid');
      this.UserFormInvalid=true
      errSubmit++
    }

    if(errSubmit>0){
      window.scroll(0,0)
      return
    }
    
    this.UserFormInvalid=false

    
    if(this.editmode){
      this.UpdateUser()
    }else {
      this.InsertUser()
    }
    
  
  }

  InsertUser(){
    // var stat:boolean=false
    // if(this.ValisActive=='true'){
    //   stat=true
    // }

    var ArrDataPost={ 
      roleMembershipId:Number(this.ValroleMembershipId),
      // isActive:stat, 
      email:this.Valemail,
      name:this.Valname
    }

    this.loading=true
    this.apollo
      .mutate({
        mutation: PostInsertUser,
        variables: ArrDataPost
      })
      .subscribe(
        (res: any) => {
          console.log('PostInsertUser', 'res', res)
          this.loading=false
          this.successnotif='block'
        },
        error => {
          this.loading=false
          console.log('error',error);
          var errMsg = error + '';
          var res = errMsg.split(":");
          var resMsg = res[res.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          //alert(msg)
          this.errornotif='block'
          this.errortext = msg
      })    
  }

  UpdateUser(){
    var stat:boolean=false
    if(this.ValisActive=='true'){
      stat=true
    }

    var ArrDataPost={ 
      roleMembershipId:Number(this.ValroleMembershipId), 
      isActive:stat, 
      targetStaffId:this.userID
    }

    this.loading=true
    this.apollo
      .mutate({
        mutation: PostUpdateUser,
        variables: ArrDataPost
      })
      .subscribe(
        (res: any) => {
          console.log('PostInsertUser', 'res', res)
          this.loading=false
          this.successnotif='block'
        },
        error => {
          this.loading=false
          console.log('error',error);
          var errMsg = error + '';
          var res = errMsg.split(":");
          var resMsg = res[res.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          //alert(msg)
          this.errornotif='block'
          this.errortext = msg
      })    

  }


  closerfqFormInvalid(){
    this.UserFormInvalid=false
  }



  closeModal(){
    this.successnotif='none'
    this.successnotifaddress='none'
    this.errortext=''
  }

  closeErrorModal(){
    this.errornotif='none'
  }

  getDataUser(id:any){



    // this.PageLoading=true    

    // this.staffService
    // .getstaffDetail(id)
    // .subscribe((res:any) => {
    //   this.PageLoading=false 
    //   if(res.data.companyStaff){


    //     console.log('getDataUser', 'res', res)
    //     this.loading=false

    //     this.Valname=res.data.companyStaff.userData.name
    //     this.Valemail=res.data.companyStaff.userData.email
    //     this.ValsecondaryEmail=res.data.companyStaff.userData.secondaryEmail
    //     this.ValroleMembershipId=res.data.companyStaff.roleId.toString()




    //     this.ValisActive =(res.data.companyStaff.isActive==true)?'true':'false'

    //   }
    //   else if(res.errors){

    //     var error = res.errors[0].message
    //     this.loading=false
    //     console.log('error',error);
    //     var errMsg = error + '';
    //     var result = errMsg.split(":");
    //     var resMsg = result[result.length - 1];
    //     var msg = (resMsg)? resMsg: errMsg
    //     console.log('msg',msg);
    //     //alert(msg)
    //     this.errornotif='block'
    //     this.errortext = msg

    //     if(res.errors[0].extensions.type){
    //       if(res.errors[0].extensions.type == 'StaffDoNotHaveAccess'){
    //         this.errortext = "You don't have access to this process"
    //         this.goToPrevpage=true
    //         this.PageLoading=true 
    //       } 
    //     }


    //   }
      
    // });

  }

  redirect(){
    this.router.navigate(['/setting/staff-management']);
  }

  getRoleName(){
    var Rolename =''
    if(this.ValroleMembershipId=='32'){
      Rolename='Procurement Staff'
    } 
    else if(this.ValroleMembershipId=='16'){
      Rolename='Vendor Staff'
    }
    else if(this.ValroleMembershipId=='8'){
      Rolename='Procurement Manage'
    }
    else if(this.ValroleMembershipId=='4'){
      Rolename='Vendor Manager'
    }
    else if(this.ValroleMembershipId=='2'){
      Rolename='Senior Manager'
    }
    else if(this.ValroleMembershipId=='1'){
      Rolename='Owner'
    } else {
      Rolename='Procurement Staff'
    }
    return Rolename
  }

  chooseRole(id:any): void{
    // setTimeout(()=>{
    //   this.ValroleMembershipId=id
    //   console.log('ValroleMembershipId : ',this.ValroleMembershipId);
    // }, 1000);
      this.ValroleMembershipId=id
      this.getRoleName()
  }
  // ngAfterContentChecked(): void {
  //   this.cdr.detectChanges();
  // }

  ChangeToEdit(){
    this.actionUrl = 'edit';
    this.labelDiv='Edit'
    this.editmode=true
    this.viewmode=false
    this.labelActionBtn='Update'
  }
  ChangeToView(){
    this.actionUrl = 'view';
    this.labelDiv='View'
    this.editmode=false
    this.viewmode=true  
    this.labelActionBtn='Edit'
  }
  ChangePassword(){
    if(this.isChangePassword){
      this.isChangePassword=false
    } else {
      this.isChangePassword=true
    }
  }
  onSubmitChangePass(){
    this.chgpasssubmitted=true

    if (this.ChangePassForm.invalid) {
      return
    }

    


    this.loading=true
    this.staffService
    .getupdatePassword(this.Valoldpassword,this.Valnewpassword)
    .subscribe((res:any) => {
      this.PageLoading=false 
      console.log('res : ',res);

      const modalRef = this.modalService.open(ModalComponent, {
        modalDialogClass: 'modal-dialog-centered',
        centered: true,
        backdrop : 'static',
        keyboard : false,
        size: 'sm'
      })

      
      if(res.data){
        console.log('getupdatePassword', 'res', res)
        this.loading=false
        this.chgpasssubmitted=false


        
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Success!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = 'Password successfully updated'
        modalRef.componentInstance.infoFailed = null
        modalRef.componentInstance.closeTxt = 'Close'
  
        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.isChangePassword=false;
            this.Valoldpassword='';
            this.Valnewpassword='';
            this.Valrenewpassword='';
            console.log('close modal success');

            //window.location.reload();

          } 
        })


      }
      else if(res.errors){

        var error = res.errors[0].message
        this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        if(res.errors[0].extensions.type){

          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            console.log(data);

            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
            
          });
          
        }

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            //window.location.reload();
          } 
        })

       
      }
      
    });


  }

  // resendEmail(email:any){

  //   this.completeresendemail=true
  //   this.apollo
  //     .mutate({
  //       mutation: postResendEmail,
  //       variables: {email:email}
  //     })
  //     .subscribe(
  //       (res: any) => {
  //         console.log('PostInsertUser', 'res', res)
  //         this.completeresendemail=true
  //         //this.successnotif='block'
  //       },
  //       error => {
  //         this.completeresendemail=false
  //         console.log('error',error);
  //         var errMsg = error + '';
  //         var res = errMsg.split(":");
  //         var resMsg = res[res.length - 1];
  //         var msg = (resMsg)? resMsg: errMsg
  //         //alert(msg)
  //         this.errornotif='block'
  //         this.errortext = msg
  //     })    
  // }

  resendEmail(email:any,companyStaffId:any){
    this.staffService
    .postResendEmail(email,companyStaffId)
    .subscribe((res:any) => {
      this.completeresendemail=false
      console.log('res : ',res)
      if(res.data?.resendVerificationEmail){
        this.completeresendemail=true

        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Success!' 
        modalRef.componentInstance.infoSuccess = 'We have sent an invitation email to the staff.'
        modalRef.componentInstance.infoFailed = null
        modalRef.componentInstance.closeTxt = 'Close'

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });
 
  }

}
<div class="container mt-0 mt-sm-4 mt-md-4 mt-lg-4 ">
    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3  d-none d-sm-block">
        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <table>
                <tr>
                  <td class="separator">
                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                  </td>
                  <td>
                    <h6 class="pt-2 mt-1  ms-3"><strong class="c1"><font class="c2">Search Products
                    </font></strong> </h6>
                  </td>  
                </tr>
              </table>
            </div>
          </div>
          <div class="col-sm-4">
          </div>
        </div>
    </div>

      <!-- Breadcrumb mobile -->
  <div class="d-block d-sm-none">

    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">


      <div class="row">
        <div class="col-6">
          <div class="pt-1">
            <strong class="c1">
              <strong class="c1">
                <font class="text-muted">Search Products
                </font>
              </strong>
            </strong>
          </div>

        </div>
        <div class="col-6 text-end">

        </div>
      </div>

    </div>
  </div>
    
    <form>
        <div class="container">
            <div class="row">
                  <div class="col-md-12 divprodlist">
                      <div class="row">
                          <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top ps-3">
                            <h4 class="mb-0">Detail Product</h4>
                          </div>              
                          <div class="col-sm-4 p-3 px-3 px-md-4 px-lg-5 p-md-5 p-lg-5 py-lg-3 pt-3">
                            <ng-container *ngIf="!isMobile">
                              <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" class="row g-2" *ngIf="itemsGallery?.length>0">
                                <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12':'col-4'}}" [attr.href]="item.src">
                                  <!-- <img class="img-fluid p-1" src="{{ item.thumb }}" appImgFallback /> -->

                                  <div class="banner-image-container pointer {{i==0?'':'secondaryimg'}} ">
                                    <img src="{{ item.thumb }}" appImgFallback>
                                  </div>

                                </a>
                              </lightgallery>
                              <div class="row g-0" *ngIf="itemsGallery?.length==0">
                                <img class="img-fluid p-1" src="/assets/images/notfound.png" appImgFallback />
                              </div>
                            </ng-container>
                            <ng-container *ngIf="isMobile">
                              <!-- <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" class="row g-0">
                                <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12':'col-4'}}" [attr.href]="item.src">
                                  <img class="img-fluid p-1" src="{{ item.thumb }}" appImgFallback />
                                </a>
                              </lightgallery> -->

                              <lightgallery [settings]="settingsGalleryMobile" [onInit]="onInitGallery" *ngIf="itemsGallery?.length>0">
                                <div class="row g-0">

                                  <div class="col-9">
                                    <ng-container *ngFor="let item of itemsGallery; let i = index">
                                      <a  class="text-center itemz" [attr.href]="item.src" *ngIf="i==0">
                                        <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" appImgFallback />
                                      </a>
                                    </ng-container>
                                    
                                  </div>
                                  <div class="col-3">
                                    <div class="row g-0">

                                      <div *ngFor="let item of itemsGallery; let i = index" class="col-12">
                                        <a  class="text-center itemz" [attr.href]="item.src" *ngIf="i!==0">
                                          <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" appImgFallback />
                                        </a>
                                      </div>

                                    </div>
                                    
                                  </div>
                                </div>
                                <!-- <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12':'col-4'}}" [attr.href]="item.src">
                                  <img class="img-fluid p-1" src="{{ item.thumb }}" appImgFallback />
                                </a> -->
                              </lightgallery>

                              <div class="row g-0" *ngIf="itemsGallery?.length==0">
                                <img class="img-fluid p-1" src="/assets/images/notfound.png" appImgFallback />
                              </div>

                            </ng-container>


                            <!-- <div class="d-none d-sm-block">
                              <div class="row g-0">

                                

                                  <div class="col-sm-12 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[0]}}">
                                  </div>
                  
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[1]}}">
                                  </div>   
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[2]}}">
                                  </div>   
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[3]}}">
                                  </div>                              
                
                  
                              </div>
                            </div>

                            <div class="d-block d-sm-none">
                              <div class="row">
  
                                <div class="col-9">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[0]}}">
                                </div>
                                <div class="col-3">
                                  <div class="row">
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[1]}}">
                                    </div>   
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[2]}}">
                                    </div>   
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[3]}}">
                                    </div>     
                                  </div>
                                </div>
                                
                              </div>                                   
                            </div> -->
      
                            
                          </div>
                          <div class="col-sm-8 px-3 px-md-4 px-lg-5 pb-3 pt-3">

                            <div class="row">
                              <div class="col-10">
                                <h3 class="mb-3"><strong class="textName">{{resdata?.name}}</strong></h3>
                              </div>
                              <div class="col-2 text-end">
                                <app-share *ngIf="resdata?.name" shareType="product" shareData="{{shareData | json}}" shareDetail="{{'' | json}}"  shareTitle="{{resdata.name}}" shareUrl="{{currentUrl}}"></app-share>
                              </div>

                              <div class="col-sm-12">
  
                                <table class="table">
  
                                  <tr>
                                      <td width="32%">Brand</td>
                                      <td width="4%">:</td>
                                      <td width="64%">{{(resdata?.brand) ? resdata?.brand:'-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Product Code</td>
                                      <td>:</td>
                                      <td>{{(resdata?.sku) ? resdata?.sku:'-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Manf Code</td>
                                      <td>:</td>
                                      <td>{{(resdata?.manufacturerSku)? resdata?.manufacturerSku : '-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Tag</td>
                                      <td>:</td>
                                      <td *ngIf="resdata?.tags.length>0">
                                          <span class="badge rounded-pill bg-secondary me-1 bgtags c1 badgetag"  *ngFor="let tags of resdata?.tags; let i = index">{{ tags.tag }}
                                          </span>
                                      </td>
                                      <td *ngIf="resdata?.tags.length==0">-</td>
                                  </tr>

                              </table>
  
                              </div>
  
                              <div *ngIf="resdata?.price>0" class="d-block d-sm-none">
                                <h3 *ngIf="isLogin">
                                  <strong>{{resdata?.currency}} {{resdata?.price | number}}</strong>
                                </h3>
                                <h3 *ngIf="!isLogin">
                                  <strong></strong>
                                </h3>
                                <br>
                              </div>

                              <div class="container">
                                <div class="row position-relative">
                                  <div class="col"> 

                                    <div class="{{isLogin ? '':'opacity01'}}">
                                        <table class="mb-2 mb-sm-4">
                                          <tr>
                                              <td>
                                                <img *ngIf="!isLogin" class="rounded-circle me-2" style="width: 40px; height: 40px;" src="/assets/images/default-company.jpeg">

                                                <img *ngIf="isLogin" class="rounded-circle 123 avatar me-2" src="{{(vendorLogo)?vendorLogo:'/assets/images/default-company.jpeg'}}"  appImgFallback="/assets/images/default-company.jpeg">
                                                
                                              </td>
                                              <td>
                                                <!-- <strong class="ms-2 me-2" [hidden]="isLogin">**********</strong>
                                                <strong class="ms-2 me-2 {{(vendorName)?'pointer':''}}" [hidden]="!isLogin" (click)="redirect()">{{ (vendorName)?vendorName:'**********' }}</strong> -->


                                                <span  (click)="redirect()" *ngIf="vendorName" class="badge rounded-pill bg-info cpname text-dark limit-text badge-limit pointer">
                                                  <img src="/assets/images/icons/vendor-icon.png" class="vendor-icon pe-1">
                                                  <font class="limit-text">{{ vendorName }}</font>
                                                </span>
                                                <span *ngIf="!vendorName"
                                                  class="badge rounded-pill bg-info ncpname text-dark limit-text badge-limit"
                                                  >
                                                  <img src="/assets/images/icons/vendor-confidential.png" class="vendor-icon pe-1">
                                                  <font class="limit-text">Confidential</font>
                                                </span>

                                                <br>
                                                <div *ngIf="vendorName" class="vendorLoc">
                                                  <small><i class="bi bi-geo-alt-fill" style="color: #1769b5"></i> {{vendorLocation}} &nbsp;&nbsp;
                                                    
                                                    <ng-container *ngIf="isCompanySetPKP">
                                                      |&nbsp;&nbsp; Tax Status:  {{(isPKP)?'PKP':'non-PKP'}}
                                                    </ng-container>
                                                  </small>
                                                </div>
                                                

                                              </td> 
                                              <td>
                                              <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                              <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall"> -->
                                              </td>
                                          </tr>
                                        </table>
    
                                        <div class="col-12 mt-3 pt-1 d-none">
                                            <div class="row text-center mb-3">
                                              <div class="col text-center">
                                                  <div class="boxratingtitle">Customer Satisfaction</div>
                                                  <div class="boxrating pt-4 pb-4">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                                      <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                                                  </div>
                                              </div>
                                              <div class="col text-center">
                                                      <div class="boxratingtitle">Product Rating</div>
                                                      <div class="boxrating pt-3 pb-3">
                                                          <h1 class="fw-bold fw-bold mb-1 mt-1 boxratingtext">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid starsmall"></h1>
                                                      </div>
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">Viewed Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">Quoted Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">On Auction Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                            </div>
                                        </div>
                                        
    
                                        <div>
                                          <div class="d-none d-sm-block">
                                            <h2 *ngIf="resdata?.price>0" class="text-end">
                                              <div *ngIf="isLogin">
                                                <strong class="texPrice">{{resdata?.currency}} {{resdata?.price | number}}</strong>
                                              </div>
                                              <div *ngIf="!isLogin">
                                                <strong class="texPrice"></strong>
                                              </div>
                                            </h2>
                                            <div  class="text-end pt-3 pb-4">

                                              <a class="cart-btn mx-2" (click)="addToCart()">
                                                <!-- <img class="cart-icon" src="/assets/images/icons/icon-cart.png">  -->
                                                <i class="bi bi-cart"></i>
                                                Add to cart
                                              </a>

                                              <a (click)="sendMessage(vendorId)" class="ask-btn">
                                                <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Ask Customer
                                              </a>
                                            </div>



                                          </div>




                                            <!-- <div *ngIf="resdata?.price>0" class="p-4 d-none d-sm-block" style="border:1px solid #b7b7b7;border-radius: 5px;">
                                              <table>
                                                  <tr>
                                                  <td><strong class="pe-5">Price</strong></td>
                                                  <td *ngIf="isLogin"><strong class="texPrice">{{resdata?.currency}} {{resdata?.price | number}}</strong></td>
                                                  <td *ngIf="!isLogin"><strong class="texPrice"></strong></td>
                                                  </tr>
                                              </table>
                                            </div> -->
            
                                        </div>
                                    </div>                                    
                                  
                                  </div>
                                  <div class="col position-absolute">

                                    <div class="" [hidden]="isLogin">
                                      <div class="notif p-5 text-center">
                                          <p><strong>Please register to contact this seller.</strong></p>
                                          <a href="/login" class="btn btn-primary bg1 fhbtn1t me-2 pointer">Login</a> or <a href="/regis" class="btn btn-primary bg1 fhbtn1 ms-2 pointer">Register</a>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>







  

                              <!-- <div class="col-sm-6">
                                
                                <table class="table">
                                  <tr>
                                      <td width="25%">Price</td>
                                      <td width="5%">:</td>
                                      <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{resdata.currency}} {{resdata.price | number}}</strong></h2></td>
                                  </tr>
                                  <tr>
                                      <td>Min. Price</td>
                                      <td>:</td>
                                      <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{resdata.currency}} {{resdata.autobidMinimumPrice | number}}</strong></h2></td>
                                  </tr>
                              </table>
                                
                              </div> -->
                            </div>
                          </div>
              
  
                          <div class="col-12 mt-3 p-5 pt-1 d-none">
                            <div class="ps-5 pe-5 row">
                              <div class="col text-center">
                                  <div class="boxratingtitle">Customer Satisfaction</div>
                                  <div class="boxrating pt-4 pb-4">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                                  </div>
                              </div>
                              <div class="col text-center">
                                    <div class="boxratingtitle">Product Rating</div>
                                    <div class="boxrating pt-3 pb-3">
                                        <h1 class="fw-bold fw-bold mb-1 mt-1">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid"></h1>
                                    </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">Viewed Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">Quoted Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">On Auction Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                          </div>
                          </div>
                  
                          <div class="col-sm-12 mb-3 px-3 px-md-4 px-lg-5 pt-1 pb-1 {{isLogin ? '':'opacity01'}}">
                  
                  
                          <!-- <div class="pt-2 pb-2 text-white text-uppercase "><strong class="c1">Description</strong></div> -->
                          
  
                          <div class="" style="border:1px solid #18877d;">
                          <div class="desclabel text-white text-uppercase bg1">Description</div>
                          <!-- <div style="white-space: pre-line" [innerHTML]="Valdescription"></div> -->
                          <div *ngIf="isLogin" class="p-4" style="white-space: pre-line">
                            <div [innerHTML]="divshotdesc(resdata?.description)"></div>
                          </div>
                          <div *ngIf="!isLogin" class="p-4" style="white-space: pre-line">
                            <div></div>
                          </div>
  
                          </div>


  
  
                          </div>
  
                          <div *ngIf="isLogin" class="p-0 px-3 px-md-4 px-lg-5 mb-3 mt-1">
                              <div [innerHtml]="divspec(resdata?.description)"></div>
                          </div>
                          <div class="d-block d-sm-none py-2 {{isLogin ? '':'opacity01'}}">
                            <div  class="text-end pt-3 pb-4">

                              <!-- <a (click)="sendMessage(vendorId)" class="ask-btn ask-btn-full">
                                <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Add to Cart
                              </a> -->
                              <a class="btn btn-dark btn mb-2 w-100 cart-btn py-2 {{isLogin ? '':'disabled'}}" (click)="addToCart()">
                                <img class="carticon" src="/assets/images/icons/icon-cart.png"> Add to cart
                              </a>

                              <a *ngIf="isLogin" (click)="sendMessage(vendorId)" class="ask-btn ask-btn-full">
                                <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Ask Customer
                              </a>
                              <a *ngIf="!isLogin" class="ask-btn ask-btn-full disabled" style="cursor: context-menu;">
                                <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Ask Customer
                              </a>
                            </div>
                          </div>
                          <div *ngIf="!isLogin" class="p-0 px-5 mb-3 mt-1">
                            <div></div>
                        </div>
                  
                      </div>
  
                  </div>
  
                  <div class="col-12 mt-4 mb-4 p-0">
  
                  </div>
  
            </div>
        </div>
    </form>
  </div>  



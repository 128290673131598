import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { OthersService } from 'src/app/_services/others.service';

@Component({
  selector: 'app-feedbackreport',
  templateUrl: './feedbackreport.component.html',
  styleUrls: ['./feedbackreport.component.scss']
})
export class FeedbackreportComponent implements OnInit {

  PageLoading:boolean=false

  limit:number=10
  offset:number=0 // page-1
  totalAll:number=0
  currentpage:number=1
  totalpage:number=1
  hasprev:boolean=false
  hasnext:boolean=false

  loadingrating:boolean=false
  noData:boolean=false

  AllFeedbackData:any=[]
  valstatusupdate:any=[]
  constructor(
    private othersService: OthersService,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.LoadReportAndFeedback()
  }

  getStatus(id:string){
    var res = ''
    this.valstatusupdate.forEach((value: any, key: any) => {
      if(value.id==id){
        res = value.value
      }
    });
    return res
  }

  LoadReportAndFeedback(){
    this.loadingrating=true
    this.offset = this.limit * (this.currentpage -1)


    this.othersService.getLoadReportAndFeedback(this.limit,this.offset).subscribe((res:any) => {
      console.log(res)
      this.loadingrating=false
      if(res.data?.loadReportAndFeedback){
          
        this.AllFeedbackData=[]
        var alldata = res.data?.loadReportAndFeedback.reportAndFeedback

        alldata.forEach((value: any, key: any) => {
          this.AllFeedbackData.push(value)
          var param = value.reportAndFeedbackId
          var val = value.status
          this.valstatusupdate.push({value:val,id:param})
        });
        console.log('valstatusupdate: ',this.valstatusupdate)
        


        this.totalAll = res.data?.loadReportAndFeedback.totalItem
        if(res.data?.loadReportAndFeedback.totalItem>0){
          this.noData = false
        }else {
          this.noData = true
        }
  
        this.loadPaging()
        this.loadingrating=false
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  loadPaging(){
    var gettotal = Math.ceil(this.totalAll/this.limit)
    console.log('gettotal = '+this.totalAll)
    console.log('gettotal = '+this.limit)
    console.log('gettotal = '+gettotal)
    if(this.currentpage==gettotal){
      this.hasnext=false
    } else {
      this.hasnext=true
    }

    if(this.currentpage==1){
      this.hasprev=false
    } else {
      this.hasprev=true
    }

    this.totalpage = gettotal


    console.log('totalpage hasprev',this.hasprev);
    console.log('totalpage hasnext',this.hasnext);

    console.log('totalpage gettotal',gettotal);
    console.log('totalpage currentpage',this.currentpage);
    console.log('totalpage totalAll',this.totalAll);
    console.log('totalpage totalpage',this.totalpage);

    if(this.totalpage==0){
      this.hasnext=false
    }
    console.log('totalpage xxxx',this.totalAll);
  }

  gotopage(page:any){
    this.currentpage=page
    this.LoadReportAndFeedback()
  }

  limitText(val:any){
    var checkChar = val
    if(checkChar.length>11){
      checkChar = checkChar.substring(0,10) + '...'
    }
    return checkChar
  }
}

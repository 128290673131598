import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import {gql, Apollo} from 'apollo-angular';
import Util from 'src/app/_helpers/util';
import { TranslateService } from '@ngx-translate/core';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { CompanyService } from 'src/app/_services/company.services';
import { DataService } from 'src/app/_services/data';
import { Subscription } from 'rxjs';
import CheckErrortype from 'src/app/_helpers/check-errortype';




// const mutationChooseCompany = gql`
// mutation chooseCompany($companyId: ID!, $loginEventId: ID!) {
//   chooseCompany(input: {companyId: $companyId,  loginEventId: $loginEventId}) {
//       __typename,
//       ...on ChooseCompanyResponse {
//           isFirstTimeLogin,
//           staff {
//               id,
//               roleMembership,
//               isActive,
//               companyId,
//               createdDate,
//               createdBy,
//               modifiedDate,
//               modifiedBy
//           },
//           accessToken
//       }
//       ...on DomainError {
//           errorMessage
//       }
//       ...on UnacceptedTermConditionError {
//           latestTermCondition {
//               id
//           }
//       }
//   }
// }
// `;

const getCompanyCurrency = gql`
query{
  company{
    defaultCurrency
  }
}
`;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  sidebar:boolean=false
  jwttoken:any
  jwtreftoken:any
  innerWidth: number=0;
  ValCompany:any=''
  ValCompanyName:any
  ArrCompany=[]
  userdata:any

  singleCompany:boolean=true
  display='none'
  emptyCompany:boolean=false
  confirmLoading:boolean=false
  errChooseCompany:string=''
  PageLoading:boolean=false
  
  subscription:boolean=false
  resChooseCompany:any

  message:string | undefined;
  subscriptionFromComponent: Subscription | undefined;
  
  constructor(
    private authService: AuthService, 
    private router: Router,
    private popupmodal : Popupmodal,
    private translate: TranslateService,
    private companyService: CompanyService,
    private data: DataService,
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    this.PageLoading=true

    this.subscriptionFromComponent = this.data.currentMessage.subscribe(message => this.message = message)
    //alert(this.message)
    console.log('dashboard this.message',this.message);
    console.log('dashboard this.subscriptionFromComponent',this.subscriptionFromComponent);


    this.getCurrency()
    return // redirect by role from dashboard, redirect after get currency

    this.jwttoken = this.parseJwt(localStorage.getItem("JWT_TOKEN"))
    console.log(this.jwttoken);

    this.jwtreftoken = this.parseJwt(localStorage.getItem("REFRESH_TOKEN"))
    console.log(this.jwtreftoken);
    
    if(localStorage.getItem("COMPANYSELECTID") !== null){
      this.ValCompany=localStorage.getItem("COMPANYSELECTID")
      this.ValCompanyName=localStorage.getItem("COMPANYSELECTNAME")
      console.log('get from storage: ',this.ValCompany);
      console.log('get from storage: ',this.ValCompanyName);
    }

  //   //dummy data
  //   this.userdata ={
  //     "id": "01F4GV0RNDQMYGCRTSE9XA3PPZ",
  //     "fullname": "SM 1",
  //     "salutation": null,
  //     "profilePictureUrl": null,
  //     "email": "SMdemo1@safari.com",
  //     "secondaryEmail": null,
  //     "createdDate": "2021-04-30T07:31:18.317260Z",
  //     "createdBy": "01F4GSR8YWZ14S63N32486XR0M",
  //     "modifiedDate": "2021-05-10T12:39:08.657829Z",
  //     "modifiedBy": "01F4GV0RNDQMYGCRTSE9XA3PPZ"
  // }
  //   this.jwttoken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJzdWIiOiIwMUY0R1YwUk5EUU1ZR0NSVFNFOVhBM1BQWiIsImlzcyI6InBybSIsImV4cCI6MTYyNDI3MzU4NCwiaWF0IjoxNjI0MjU1NTg0fQ.EqAygi-RdTgJSyqqQE0cSlfNkpfmsQd4on_t6KEAVhP1Dfg4M1vAm2bK8rVaR5ylcDTjTr23r9XVW0EVfDCLMw'
  //   this.jwtreftoken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJzdWIiOiIwMUY0R1YwUk5EUU1ZR0NSVFNFOVhBM1BQWiIsImxlaWQiOiIwMUY4UEpUUEtERUtaM1hXU1A4OENFOVI5RSIsImlzcyI6InBybSIsImV4cCI6MTYyNDM0MTk4NCwiaWF0IjoxNjI0MjU1NTg0fQ.Seyp8SvTvUII-1mhQvQQoNjqBEqaYeV-FD0TCDLkjuScCfaqvrx8ktJcWrAGNFFngUgdNTWr_6tgDWhXIZzz7g'
  //   // close dummy data


    this.innerWidth = window.innerWidth;
    console.log('this.innerWidth',this.innerWidth);
    if(this.innerWidth < 992){
      this.hideshowmenu()
    }
    this.loadCompany()

    //this.checktokenexp()

    // this.ChangeCompany() //open modal to choose company
  }

  getCurrency(){

    var getdefaultCurrency = localStorage.getItem("defaultCurrency")
    if (getdefaultCurrency == null) {
      this.apollo.watchQuery<any>({
        query: getCompanyCurrency
      })
        .valueChanges
        .subscribe(({ data }) => {
          console.log('data defaultCurrency', data);
          if(data.company?.defaultCurrency){
            localStorage.setItem("defaultCurrency",data.company.defaultCurrency)
          }
          
          this.redirectByRole()


        }) 
    } else{
      this.redirectByRole()
    }

  }


  hideshowmenu(){
    if(!this.sidebar){
      this.sidebar=true
    } else {
      this.sidebar=false
    }
  }

  parseJwt(token: string|null) {
    if(token !==null){
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }

  };
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    //console.log('innerWidth',this.innerWidth);

    if(this.innerWidth < 992){
      this.hideshowmenu()
    }
  }

  loadCompany(){


    var getcompany = localStorage.getItem("COMPANYDATA")
    //var getcompany = '[{"companyId":"DEMO1","companyName":"Test Company demo1","__typename":"CompanyChoiceItem"},{"companyId":"DEMO2","companyName":"Test Company demo2","__typename":"CompanyChoiceItem"},{"companyId":"DEMO3","companyName":"Test Company demo3","__typename":"CompanyChoiceItem"}]';
    //var getcompany = '[{"companyId":"DEMO1","companyName":"Test Company demo1","__typename":"CompanyChoiceItem"}]';

    //console.log('getcompany',getcompany);


    if(getcompany !== null){
      //var getcompanyArr = getcompany.slice(1,-1);
      //console.log('getcompanyArr',getcompanyArr);
      // var arr = getcompanyArr.split(',');
      // console.log(arr);

      // this.ArrCompany = JSON.parse(getcompanyArr);
      // console.log(this.ArrCompany);

      this.ArrCompany = JSON.parse(getcompany);
      console.log(this.ArrCompany);

      
      if(this.ArrCompany.length>1){
        this.singleCompany=false
        console.log('this.singleCompany',this.singleCompany);

        if(localStorage.getItem("COMPANYSELECTID") !== null){
          this.display = "none";
          this.PageLoading = false
          //this.router.navigate(['/']);
        } else {
          // modal from header
          //this.ChangeCompany() //open modal to choose company
        }

      } else {
        this.ValCompany=this.ArrCompany[0]['companyId']
        this.ValCompanyName=this.ArrCompany[0]['companyName']
        this.ConfirmCompany()

        console.log('this.singleCompany',this.singleCompany);
      }

      
    }
    
  }



  // check exp token, should in one auth services
  checktokenexp(){
    console.log('cccc', this.jwttoken.exp);
    if (this.jwttoken.exp < new Date().getTime()/1000) {
        console.log("EXPIRED 123");
    }
  }

  ChangeCompany() {
    console.log('openModal >> ',this.singleCompany);
    if(this.display=="none"){
      this.display = "block";
    } else {
      this.display = "none";
    }
    this.emptyCompany=false;



    if(this.resChooseCompany){
      if(this.resChooseCompany.data.chooseCompany.isFirstTimeLogin && this.resChooseCompany.data.chooseCompany.roleMembership ==1){
        //redirect to subscription plan / show subcription plan
        this.subscription=true
        this.display = "none";
        this.PageLoading=true
        console.log('===========');
      }
    }   

  }
  ConfirmCompany() {
    console.log(this.ValCompany);
    if(this.ValCompany!==''){

      var companyId = this.ValCompany
      var loginEventId = this.jwtreftoken['leid']

      this.confirmLoading = true
      this.errChooseCompany = ''
      
      this.emptyCompany=false

      this.companyService.getmutationChooseCompany(companyId,loginEventId).subscribe((res:any) => {
        console.log(res)
    
        if(res.data?.chooseCompany){
          console.log("succes, get data:", res);
          this.display = "none";//close modal

          this.confirmLoading = false
          this.PageLoading=false


          this.ArrCompany.forEach((val: any, k: any) => {
            console.log('ArrCompany v : ',val['companyId'])
            console.log('ArrCompany v : ',val.companyId)
            console.log('ArrCompany k : ',k)
            if(this.ValCompany==val.companyId){
              this.ValCompany=this.ArrCompany[k]['companyId']
              this.ValCompanyName=this.ArrCompany[k]['companyName']
            }
            
          })
          console.log('ValCompany',this.ValCompany);
          console.log('ValCompanyName',this.ValCompanyName);
          localStorage.setItem("COMPANYSELECTID",this.ValCompany)
          localStorage.setItem("COMPANYSELECTNAME",this.ValCompanyName)
          

          
          if(res.data?.chooseCompany){
            localStorage.removeItem("USERCOMPANY")
            localStorage.setItem("USERCOMPANY",JSON.stringify(res.data.chooseCompany.staff))
            
            if(res.data.chooseCompany.isFirstTimeLogin){
              this.subscription=true
               this.display = "none";
              this.PageLoading=true
              this.router.navigate(['/setting/company-detail']);
              return
              
            } else {
              localStorage.setItem("JWT_TOKEN",res.data.chooseCompany.accessToken)
              this.router.navigate(['/dashboard']);
            }

            //if any update TOS
            if(res.data?.chooseCompany.latestTermCondition.id){
              localStorage.setItem("NEEDCONFIRMTOS", 'Y')
              localStorage.setItem("LATESTTOS", res.data?.chooseCompany.latestTermCondition.id)
            }

          }
        }
        else if(res.errors){
          var error = res.errors[0].message
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          var errortext = msg
  
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
      });

      return


        // this.apollo
        //   .mutate({
        //     mutation: mutationChooseCompany,
        //     variables: {companyId: companyId, loginEventId: loginEventId}
        //   })
        //   .subscribe(
        //     (res:any) => {
        //       console.log("succes, get data:", res);
        //       this.display = "none";

        //       this.confirmLoading = false
        //       this.PageLoading=false

  
        //       this.ArrCompany.forEach((val: any, k: any) => {
        //         console.log('ArrCompany v : ',val['companyId'])
        //         console.log('ArrCompany v : ',val.companyId)
        //         console.log('ArrCompany k : ',k)
        //         if(this.ValCompany==val.companyId){
        //           this.ValCompany=this.ArrCompany[k]['companyId']
        //           this.ValCompanyName=this.ArrCompany[k]['companyName']
        //         }
                
        //       })
        //       console.log('ValCompany',this.ValCompany);
        //       console.log('ValCompanyName',this.ValCompanyName);
        //       localStorage.setItem("COMPANYSELECTID",this.ValCompany)
        //       localStorage.setItem("COMPANYSELECTNAME",this.ValCompanyName)
              
        //       if(res.data){
        //         localStorage.removeItem("USERCOMPANY")
        //         localStorage.setItem("USERCOMPANY",JSON.stringify(res.data.chooseCompany.staff))
                
        //         if(res.data.chooseCompany.isFirstTimeLogin){
        //           this.subscription=true
        //            this.display = "none";
        //           this.PageLoading=true
        //           this.router.navigate(['/setting/company-detail']);
        //           return
                  
        //         } else {
        //           localStorage.setItem("JWT_TOKEN",res.data.chooseCompany.accessToken)
        //           this.router.navigate(['/dashboard']);
        //         }
        //       }
        //     },
        //     error => {
        //       console.log("there was an error sending the query", error);
        //       this.confirmLoading = false
        //       this.errChooseCompany = error           
        //     }
        //   );



    } else {
      this.emptyCompany=true;
      this.errChooseCompany = ''
    }

    
  }

  redirectByRole(){
                      //--redirect dashboard--//
                      var getusercompany = localStorage.getItem("USERCOMPANY")
                      var redirect = '/dashboard';
                      console.log('redirectDasboard 2 : ', usercompany);
                      if (getusercompany !== null) {
                        var usercompany = JSON.parse(getusercompany);
                        redirect = Util.redirectDasboard(usercompany.roleMembership)
                        //alert('redirect to : '+redirect)

                        //if have url before login
                        var returnUrl = localStorage.getItem("returnUrl") 
                        if(returnUrl !== null){
                          localStorage.removeItem("returnUrl") 
                          this.router.navigate([returnUrl]);
                          return
                        }

                        if(this.router.url == redirect){
                          location.reload() 
                        } else {
                          this.router.navigate([redirect]);
                        }
    
                      } else {
                        this.router.navigate([redirect]);
                      }
                      return
                      //--redirect dashboard--//
  }

}

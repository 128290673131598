<div class="container">



    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 d-none d-sm-block">

        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <!-- <h6 class="pt-2 mt-1"><strong class="c1">Vendor  >  <a href='/vendor/products'><font class="c1">Product List</font></a>  > <font class="c2">Product Detail</font></strong> </h6> -->

              <table>
                <tr>
                  <td class="separator">
                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                  </td>
                  <td>
                    <h6 class="pt-2 mt-1  ms-3"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">Compare Product</font></strong> </h6>
                  </td>  
                </tr>
              </table>


            </div>
          </div>
          <div class="col-sm-4">
            <!-- <div class="input-group mb-0 breadcumsearchdiv">
              <input type="text" class="form-control breadcumsearchinput" placeholder="Search products..." aria-label="Recipient's username" aria-describedby="button-addon2">
              <button type="button" class="btn btn-primary breadcumsearchbox align-self-center"><img class="breadcumsearchbox" src="/assets/images/icons/ic_Search.png"></button>
            </div> -->
          </div>
        </div>
    </div>
    <!-- Breadcrumb mobile -->
    <div class="d-block d-sm-none">
      <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">
        <div class="row">
          <div class="col-12">
            <div class="pt-1">
              <strong class="c1">
                <a class="c1 pointer" href="/vendor/product-list">Product List</a> >
                <font class="text-muted">Compare Product
                </font>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>    


    <form>
        <div class="container">


      <div class="row pb-5">
        <div class="col-sm-12 mt-0 pt-0" *ngIf="successdraft">

            <div class="alert alert-success alert-dismissible fade show rounded-1 successNotif" role="alert">
                <strong>Success</strong> : Your data has been successfully saved as a draft!
                <p class="btn-close successNotif" (click)="closesuccessdraft()"></p>
            </div>
   
        </div>

        <div class="col-sm-12 mt-0 pt-0" *ngIf="StatusComparation">

            <div class="alert alert-info alert-dismissible fade show rounded-1 infoNotif" role="alert">

                <div class="row">
                  <div class="col-md-10"><p class="mt-1 mb-0">Info : There is a change regarding this product! Please click <strong>"Check Now"</strong> button to see more details on the changes.  </p></div>
                  <div class="col-md-2 text-end"><a class="btn btn-info mb-0 text-white bgNotif" href="/vendor/product/compare/{{EditProductId}}">Check Now</a></div>
                </div>

            </div>
   
        </div>
        
        <div class="col-sm-12 mt-0 pt-0" >

            <div class="alert alert-info alert-dismissible fade show rounded-1 infoNotif" role="alert">


              <div class="row">
                <div class="col-md-12"><p class="mt-1 mb-0"><strong>Note :</strong> This is an unapproved revised Product. The original, approved Product will be replaced by this one when this revision
                  is approved. Click <strong><a  href="/vendor/product/detail/{{EditProductId}}" target="_blank">here</a></strong> to open the original Product in a new tab.</p></div>
              </div>


            </div>
   
        </div>

        <div class="col-md-12 divprodlist">
            <div class="row">
                <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-0 pb-3 rounded-top ps-3">
                  <h4 class="mb-0">Detail Product</h4>
                </div>              
                
                <div class="col-sm-4 p-sm-5 pt-3">

                  <lightgallery [settings]="settingsGallery" id="previewGallery" [onInit]="onInitGallery" class="row g-0">
                    <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12':'col-4'}}" [attr.href]="item.src">
                      <img class="img-fluid p-1 {{existingProductimageUrl[i]!==draftProductdraftImageUrl[i] ?'borderYellow':''}}" src="{{ item.thumb }}" />
                    </a>
                  </lightgallery>
                  
                </div>
                <div class="col-sm-8 p-sm-5 pt-3">

                  <div class="d-block d-sm-none">

                    <div class="row m-padding">

                      <div class="col-12">
                        <h3><strong class="textName" [innerHTML]='comparepage(draftProductdraftName,existingProductname)'></strong></h3>
                      </div>

                      <div class="col-5 col-sm-3">Brand</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">
                        <div [innerHTML]='comparepage(draftProductdraftBrand,existingProductbrand)'></div>
                      </div>

                      <div class="col-5 col-sm-3">Product Code</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8"><div [innerHTML]='comparepage(draftProductdraftSku,existingProductsku)'></div></div>

                      <div class="col-5 col-sm-3">Manf. Code</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8"><div [innerHTML]='comparepage(draftProductdraftManufacturerSku,existingProductmanufacturerSku)'></div></div>

                      <div class="col-5 col-sm-3">Tag</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">

                              <div *ngIf="isTagsChanges">
                                <span class="badge rounded-pill bg-secondary me-1 bgtags  c1 badgetag"  *ngFor="let tags of SelectedTagsDraft; let i = index"><div class="highlight">{{ tags }}</div>
                                </span>
                              </div>

                              <div *ngIf="!isTagsChanges">
                                <span class="badge rounded-pill bg-secondary me-1 bgtags  c1 badgetag"  *ngFor="let tags of SelectedTagsExist; let i = index">{{ tags }}
                                </span>
                              </div>
                       
                      </div>

                      <div class="col-5 col-sm-3">Display Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">

                              
                        <div *ngIf="draftProductdraftIsDisplayPriceOnShopfront">
                          <span *ngIf='draftProductdraftIsDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable"><div class="highlightbadge">Enable</div>
                          </span>
                          <span *ngIf='!draftProductdraftIsDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable"><div class="highlight">Disable</div>
                          </span>
                        </div>
                        <div *ngIf="!draftProductdraftIsDisplayPriceOnShopfront">
                          <span *ngIf='existingProductisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                          </span>
                          <span *ngIf='!existingProductisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                          </span>
                        </div>

                        
                      </div>

                      <div class="col-5 col-sm-3 pe-0">Display Product on Shopfront</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">

                        <div *ngIf="draftProductdraftIsDisplayProductOnShopfront">
                          <span *ngIf='draftProductdraftIsDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable"><div class="highlightbadge">Enable</div>
                          </span>
                          <span *ngIf='!draftProductdraftIsDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable"><div class="highlight">Disable</div>
                          </span>
                        </div>
                        <div *ngIf="!draftProductdraftIsDisplayProductOnShopfront">
                          <span *ngIf='existingProductisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                          </span>
                          <span *ngIf='!existingProductisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                          </span>
                        </div>

                      </div>

                      <div class="col-5 col-sm-3">Auto Bid</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">
                        <span *ngIf='ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                        </span>
                        <span *ngIf='!ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                        </span>
                      </div>
                      
                      <div *ngIf='ValisAutobidEnabled' class="col-5 col-sm-2">Discount Increments</div>
                      <div *ngIf='ValisAutobidEnabled' class="col-1 col-sm-1">:</div>
                      <div *ngIf='ValisAutobidEnabled' class="col-6 col-sm-9">
                        {{valcurrency}} {{ValautobidPriceDecrement | number}}
                      </div>


                      <div class="col-5 col-sm-2">Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-2">
                        <p class="mb-0 pt-0 pb-0 ps-0 pe-0">
                          <strong class="texPrice">{{(draftProductdraftPrice)?valcurrency+' ':''}}</strong><strong  class="texPrice"  [innerHTML]="comparepage(draftProductdraftPrice,existingProductprice)"></strong>
                        </p>
                      </div>

                      <div class="col-5 col-sm-2">Min. Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-2">
                        <p class="mb-0 pt-0 pb-0 ps-0 pe-0">
                        <strong class="texPrice">{{(draftProductdraftAutobidMinimumPrice)?valcurrency+' ':''}}</strong><strong class="texPrice" [innerHTML]="comparepage(draftProductdraftAutobidMinimumPrice,existingProductautobidMinimumPrice)"></strong>
                        </p>
                      </div>

                    </div>

                  </div>

                  <div class="d-none d-sm-block">


                  <div class="row">
                    <div class="col-12">
                      <h3><strong class="textName" [innerHTML]='comparepage(draftProductdraftName,existingProductname)'></strong></h3>
                    </div>
                    <div class="col-sm-6">

                      <table class="table mb-0">

                        <tr>
                            <td width="62%">Brand</td>
                            <td width="5%">:</td>
                            <td width="33%">
                              <div [innerHTML]='comparepage(draftProductdraftBrand,existingProductbrand)'></div>
                            </td>
                        </tr>
                        <tr>
                            <td>Product Code</td>
                            <td>:</td>
                            <td>
                              <div [innerHTML]='comparepage(draftProductdraftSku,existingProductsku)'></div>
                            </td>
                        </tr>
                        <tr>
                            <td>Manf Code</td>
                            <td>:</td>
                            <td><div [innerHTML]='comparepage(draftProductdraftManufacturerSku,existingProductmanufacturerSku)'></div></td>
                        </tr>

                        
                        

                    </table>

                    </div>
                    <div class="col-sm-6">
                      
                      <table class="table mb-0">
                        <tr>
                            <td>Price</td>
                            <td>:</td>
                            <td><p class="mb-0 pt-0 pb-0 ps-0 pe-0">
                              <strong class="texPrice">{{(draftProductdraftPrice)?valcurrency+' ':''}}</strong><strong  class="texPrice"  [innerHTML]="comparepage(draftProductdraftPrice,existingProductprice)"></strong>
                              </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Min. Price</td>
                            <td>:</td>
                            <td><p class="mb-0 pt-0 pb-0 ps-0 pe-0">
                              <strong class="texPrice">{{(draftProductdraftAutobidMinimumPrice)?valcurrency+' ':''}}</strong><strong class="texPrice" [innerHTML]="comparepage(draftProductdraftAutobidMinimumPrice,existingProductautobidMinimumPrice)"></strong>
                          </p></td>
                        </tr>
                    </table>
                      
                    </div>

                    <div class="col-sm-12">
                      <table class="table">
                        <tr>
                            <td width="30%">Tag</td>
                            <td width="2.5%">:</td>
                            <td>

                              <div *ngIf="isTagsChanges">
                                <span class="badge rounded-pill bg-secondary me-1 bgtags  c1 badgetag"  *ngFor="let tags of SelectedTagsDraft; let i = index"><div class="highlight">{{ tags }}</div>
                                </span>
                              </div>

                              <div *ngIf="!isTagsChanges">
                                <span class="badge rounded-pill bg-secondary me-1 bgtags  c1 badgetag"  *ngFor="let tags of SelectedTagsExist; let i = index">{{ tags }}
                                </span>
                              </div>

                            </td>
                        </tr>
                        <tr>
                            <td>Display Price</td>
                            <td>:</td>
                            <td>

                              <div *ngIf="draftProductdraftIsDisplayPriceOnShopfront!==null">
                                <span *ngIf='draftProductdraftIsDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable"><div class="highlightbadge">Enable</div>
                                </span>
                                <span *ngIf='!draftProductdraftIsDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable"><div class="highlight">Disable</div>
                                </span>
                              </div>
                              <div *ngIf="!draftProductdraftIsDisplayPriceOnShopfront==null">
                                <span *ngIf='existingProductisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                                </span>
                                <span *ngIf='!existingProductisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                                </span>
                              </div>

                            </td>
                        </tr>
                        <tr>
                          <td>Display Product on Shopfront</td>
                          <td>:</td>
                          <td>

                            <div *ngIf="draftProductdraftIsDisplayProductOnShopfront!==null">
                              <span *ngIf='draftProductdraftIsDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable"><div class="highlightbadge">Enable</div>
                              </span>
                              <span *ngIf='!draftProductdraftIsDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable"><div class="highlight">Disable</div>
                              </span>
                            </div>
                            <div *ngIf="!draftProductdraftIsDisplayProductOnShopfront==null">
                              <span *ngIf='existingProductisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                              </span>
                              <span *ngIf='!existingProductisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                              </span>
                            </div>


                          </td>
                        </tr>

                        <tr>
                          <td>Auto Bid</td>
                          <td>:</td>
                          <td>
                            <div *ngIf="draftProductdraftIsAutobidEnabled!==null">
                              <span *ngIf='draftProductdraftIsAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable"><div class="highlightbadge">Enable</div>
                              </span>
                              <span *ngIf='!draftProductdraftIsAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable"><div class="highlight">Disable</div>
                              </span>
                            </div>
                            <div *ngIf="draftProductdraftIsAutobidEnabled==null">
                              
                              <span *ngIf='existingProductisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                              </span>
                              <span *ngIf='!existingProductisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                              </span>
                            </div>
                          </td>
                        </tr>

                        <tr *ngIf='ValisAutobidEnabled'>
                          <td>Discount Increments</td>
                          <td>:</td>
                          <td>
                            {{valcurrency}} {{ValautobidPriceDecrement | number}}
                          </td>
                        </tr>                        
                      </table>
                    </div>

                    <div class="col-12 mt-3 p-5 pt-1 ps-0 d-none">
                      <div class="ps-2 pe-5 row">
                        <div class="col text-center">
                            <div class="boxratingtitle">Customer Satisfaction</div>
                            <div class="boxrating pt-2 pb-2">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                            </div>
                        </div>
                        <div class="col text-center">
                              <div class="boxratingtitle">Product Rating</div>
                              <div class="boxrating pt-1 pb-1">
                                  <h1 class="fw-bold fw-bold mb-1 mt-1">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid"></h1>
                              </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">Viewed Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">Quoted Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">On Auction Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                     </div>
                    </div>

                  </div>

                  </div>

                </div>
    

                <div class="col-sm-12 mb-0 mt-0  px-sm-5 pt-1 pb-1">
        
                  <div class="pt-2 pb-2 text-white text-uppercase ">
                    <strong class="{{(isDescChanges)?'highlight':''}} fhc1">Description</strong>
                  </div>
                  
                  <div class="p-4" style="border:1px solid #18877d;">
                    <div style="white-space: pre-line" class="{{(isDescChanges)?'highlightxxx':''}}">
                      <div [innerHTML]="comparepage(desc1draft,desc1exist)"></div>           
                    </div>

                  </div>

                  <div class="p-0 px-5 mb-5 mt-1">
                    <div class="px-sm-4 pt-2">
                      <div [innerHTML]="comparepagespec(desc2exist,desc2draft)"></div> 
                    </div>
                      
                  </div>

                </div>
            </div>
        <div class="col-12 mt-4 mb-4 p-0">
        </div>
      </div>
      </div>



        </div>
    </form>
</div>  


<div class="fixed-bottom">
  <div class="d-none" [ngStyle]="{'display':displaytimeline}">
    <div class="divtimeline p-1">
      <div class="card timelinecard">
        <div class="card-header bgtimelineheader">
          <div class="row p-0 ">
            <div class="col-9">
              Product Update Status
            </div>
            <div class="col-3 text-end">
              <i class="bi bi-x-lg text-end pointer" (click)="OpenModalTimeLine()"></i>
            </div>
          </div>

  
        </div>
        <div class="card-body">

          <div class="timeline">
            <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1 {{ StatusApproval==4 ? 'activeLine':'inactiveLine' }} inactiveIcon {{ (StatusApproval==2 || StatusApproval==4) ? 'activeIcon':'' }}"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4) && Valstate=='UNPUBLISHED'}">
              <div class="timeline__event__icon ">
                <i class="lni-cake"></i>
                <div class="timeline__event__date inactiveTimeline"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4)}">
                  Approval Requested
                </div>
              </div>
              <!-- <div class="timeline__event__content ">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div> -->
            </div>
            <!-- <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2 inactiveIcon {{ StatusApproval==4  ? 'activeIcon':'' }} {{ StatusApproval==4 && Valstate=='PUBLISHED' ? 'activeLine':'inactiveLine' }} ">
              <div class="timeline__event__icon">
                <i class="lni-burger"></i>
                <div class="timeline__event__date inactiveTimeline {{ Valstate!=='UNPUBLISHED' ? 'activeTimeline':'' }}" [ngClass]="{'activeTimeline': StatusApproval==4}">
                  Product Approved
                </div>
              </div>
            </div> -->
            <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3 inactiveIcon {{ StatusApproval==4 && Valstate=='PUBLISHED'  ? 'activeIcon':'' }}" style="margin-bottom: 10px !important;">
              <div class="timeline__event__icon">
                <i class="lni-slim"></i>
                <div class="timeline__event__date inactiveTimeline" [ngClass]="{'activeTimeline': StatusApproval==4 && Valstate=='PUBLISHED'}">
                  Product Updated
                </div>
              </div>
              <!-- <div class="timeline__event__content">
                <p>Lorem, ipsum dolor sit amet.</p>
          
              </div> -->
            </div>

          
          </div>
          <div class="text-center">
            <p class="texttimeline" [hidden]='StatusApproval!==1'>Click the “Request Approval” button to process product listing. </p>

            <p class="texttimeline" [hidden]='StatusApproval!==2' *ngIf="!CanApproveReject">Please wait, your product is being review</p>
            <p class="texttimeline" [hidden]='StatusApproval!==2'*ngIf="CanApproveReject">Click the “Approve” button to process product listing or “Reject” to ignore. </p>
            
            <p class="texttimeline" [hidden]='StatusApproval!==4' *ngIf="Valstate!=='PUBLISHED'">Your product is has been approved, click “Publish” button to continue to listing.</p>
            
            <p class="texttimeline" *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Congratulations! Your product is succesfully Updated!</p>
            
            

            <!-- <button type="button" class="btn btn-primary fhbgblack mt-3 mb-3" disabled>Approval Requested</button> -->



            <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan rounded-1 mb-2 reqApprvBtn"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
            <button type="button" class="btn btn-dark fhbcyan rounded-1 mb-2 disabled reqApprvBtn"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>
  
  
  
  
  
            <!-- <button type="button" (click)="openModalApproveReject()" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button> -->

            <!-- <button type="button" (click)="openModalApproveReject('REJECT')" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button>

            <button type="button" (click)="openModalApproveReject('APPROVE')" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button> -->

            <div class="row g-0">
              <div class="col-6">
                <button type="button" class="btn btn-sm apprrejBtn cancelbtn  fsmall text-white btn-danger" (click)="openModalApproveReject('REJECT')" [hidden]='StatusApproval!==2'>Reject</button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-sm  text-white apprrejBtn  fsmall btn-success" (click)="openModalApproveReject('APPROVE')" [hidden]='StatusApproval!==2'>Approve</button>
              </div>
            </div>

            
            
            
  
            <!-- <font *ngIf="!CanApproveReject" class="fhdisabledbtn rounded-1 mb-2 text-center pt-2 pb-2 ps-3 pe-3 reqApprvBtn" [hidden]='StatusApproval!==2'>Approval Requested</font> -->
  
  
  
  
            
            <!-- <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled ps-3 pe-3" [hidden]='StatusApproval!==4'>Approved</font> -->

              <!-- <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled ps-3 pe-3" *ngIf='StatusApproval==4 && Valstate=="UNPUBLISHED"'>Approved</font> -->

            <!-- <div *ngIf="StatusApproval==4 && Valstate!=='PUBLISHED'">

            
              <button type="button" (click)="submitPublish()" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="!LoadingreqeustPublish" [disabled]="StatusApproval!==4">Publish</button>
              <button type="button" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="LoadingreqeustPublish" disabled>Loading..</button>
            </div> -->


              <font class="fhpublished rounded-1 mb-2 text-center pt-2 pb-2  ps-3 pe-3"  *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Updated</font>

            


          </div>


        </div>
      </div>
    </div>
    
  </div>

  <div class="" [ngStyle]="{'display':displaytimeline}">
    <div class="divtimeline p-1">
      <div class="card timelinecard shadow">
        <div class="card-header bgtimelineheader">
          <div class="row p-0 ">
            <div class="col-9">
              Product Update Status
            </div>
            <div class="col-3 text-end">
              <i class="bi bi-x-lg text-end pointer" (click)="OpenModalTimeLine()"></i>
            </div>
          </div>

  
        </div>
        <div class="card-body">

          <div class="mobile-stepper d-block d-sm-none">
            <div class="steps d-flex justify-content-between padding-top-2x padding-bottom-1x  pt-2 pb-3">

              <div class="step" [ngClass]="{'completed': (StatusApproval==2 || StatusApproval==4) }">
                <div class="step-icon-wrap">
                    <div class="step-icon">
                        <i class="bi bi-check2"></i>
                    </div>
                </div>
                <h4 class="step-title">Approval<br>Requested</h4>
              </div>

              <div class="step"  [ngClass]="{'completed': StatusApproval==4 && Valstate=='PUBLISHED'}">
                <div class="step-icon-wrap">
                    <div class="step-icon">
                        <i class="bi bi-check2"></i>
                    </div>
                </div>
                <h4 class="step-title">Product<br>Updated</h4>
              </div>


  


            </div>
          </div>


          <div class="timeline d-none d-sm-block">
            <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1 {{ StatusApproval==4 ? 'activeLine':'inactiveLine' }} inactiveIcon {{ (StatusApproval==2 || StatusApproval==4) ? 'activeIcon':'' }}"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4) && Valstate=='UNPUBLISHED'}">
              <div class="timeline__event__icon ">
                <i class="lni-cake"></i>
                <div class="timeline__event__date inactiveTimeline"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4)}">
                  Approval Requested
                </div>
              </div>
            </div>
            <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3 inactiveIcon {{ StatusApproval==4 && Valstate=='PUBLISHED'  ? 'activeIcon':'' }}" style="margin-bottom: 10px !important;">
              <div class="timeline__event__icon">
                <i class="lni-slim"></i>
                <div class="timeline__event__date inactiveTimeline" [ngClass]="{'activeTimeline': StatusApproval==4 && Valstate=='PUBLISHED'}">
                  Product Updated
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <p class="texttimeline" [hidden]='StatusApproval!==1'>Click the “Request Approval” button to process product listing. </p>

            <p class="texttimeline" [hidden]='StatusApproval!==2' *ngIf="!CanApproveReject">Please wait, your product is being review</p>
            <p class="texttimeline" [hidden]='StatusApproval!==2'*ngIf="CanApproveReject">Click the “Approve” button to process product listing or “Reject” to ignore. </p>
            
            <p class="texttimeline" [hidden]='StatusApproval!==4' *ngIf="Valstate!=='PUBLISHED'">Your product is has been approved, click “Publish” button to continue to listing.</p>
            
            <p class="texttimeline" *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Congratulations! Your product is succesfully Updated!</p>
            

            <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan rounded-1 mb-2 reqApprvBtn"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
            <button type="button" class="btn btn-dark fhbcyan rounded-1 mb-2 disabled reqApprvBtn"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>
  


            <div class="row g-0">
              <div class="col-6">
                <button type="button" class="btn btn-sm apprrejBtn cancelbtn  fsmall text-white btn-danger" (click)="openModalApproveReject('REJECT')" [hidden]='StatusApproval!==2'>Reject</button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-sm  text-white apprrejBtn  fsmall btn-success" (click)="openModalApproveReject('APPROVE')" [hidden]='StatusApproval!==2'>Approve</button>
              </div>
            </div>
              <font class="fhpublished rounded-1 mb-2 text-center pt-2 pb-2  ps-3 pe-3"  *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Updated</font>

          </div>

        </div>
      </div>
    </div>
    
  </div>

  <div class="bg-white pt-3 pb-3 d-none d-sm-block">
    <div class="container">
      <div class="row">
        <div class="col-8">

          <a type="button"  class="btn btn-link  rounded-1 roundedmore fixwbtn mb-2 c1 ms-3 text-decoration-none" href="/vendor/product/detail/{{EditProductId}}"><strong><i class="bi bi-chevron-left text-bold me-2 bolder"></i> Back</strong></a>

          <!-- <button type="button" class="btn btn-link  rounded-1 roundedmore  mb-2 c1  me-3" ><strong>Market Intelligence</strong></button>


          
          <a type="button"  class="btn btn-dark fhbdark rounded-1 roundedmore fixwbtn mb-2  me-3" href="/vendor/product/edit/{{EditProductId}}" *ngIf="!pageloading"><i class="bi bi-pencil-square"></i> Edit</a>

          <button  type="button" (click)="openmodaldelete()" class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="!pageloading"> <i class="bi bi-trash"></i> Delete</button>
          <button class="btn btn-primary fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="pageloading" disabled>Loading..</button> -->



        </div>
        <div class="col-4 text-end">
          <!-- <button type="button" (click)="submitPublish()" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="!LoadingreqeustPublish" [hidden]="Valstate=='PUBLISHED'" [disabled]="StatusApproval!==4">Publish</button>
          <button type="button" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="LoadingreqeustPublish" disabled>Loading..</button>

          <button type="button" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  [hidden]="Valstate!=='PUBLISHED'" disabled>Published</button> -->





          <button type="button" class="btn btn-link  rounded-1 roundedmore mb-2 c1 ms-3" (click)="OpenModalTimeLine()"><strong>Approval Status <font class="bi bi-caret-{{displaytimeline=='none'?'up':'down'}}-fill"></font></strong></button>




          
          <!-- <div class="d-none">

          <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan rounded-1 mb-2"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
          <button type="button" class="btn btn-dark fhbcyan rounded-1 mb-2 disabled"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>





          <button type="button" (click)="openModalApproveReject()" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</button>
          

          <font *ngIf="!CanApproveReject" class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</font>





          <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled" [hidden]='StatusApproval!==4'>Approved</font>
          

        </div> -->

          
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white pt-2 pb-2 d-block d-sm-none">
    <div class="container">
      <div class="row">
        <div class="col-6">

          <a type="button"  class="btn btn-link  rounded-1 roundedmore fixwbtn my-1 c1 text-decoration-none" href="/vendor/product/detail/{{EditProductId}}"><strong><i class="bi bi-chevron-left text-bold me-2 bolder"></i> Back</strong></a>

        </div>
        <div class="col-6">

          <button type="button" class="btn btn-link  rounded-1 roundedmore my-1 c1 w-100 mbtnbrdc1" (click)="OpenModalTimeLine()"><strong>Approval Status <font class="bi bi-caret-{{displaytimeline=='none'?'up':'down'}}-fill"></font></strong></button>

        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydraft}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="draftimgicon" src="/assets/images/icons/draft-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully<br>saved as a draft!</p>
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <!-- <button type="button w-100" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" (click)="openmodaldraft()">DONE</button> -->
            <a href="/product/detail/{{ EditProductId }}" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5">PREVIEW</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydone}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully saved!</p>
          
          
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/product-list" data-bs-dismiss="modal">DONE</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>



<!-- Modal -->
<!-- <div class="modal" id="deleteModal" tabindex="-1"  [ngStyle]="{'display':displaydelete}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Delete Product</strong></h5>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-4 mb-4">
          Are you sure want to delete <b>"{{ Valname }}"</b> Product ?
        </h6>


        
        <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
          <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall" (click)='openmodaldelete()'>Cancel</button>
          <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="confirmdelete()"  data-bs-dismiss="modal">Delete</button>
        </div>

      </div>

    </div>
  </div>
</div> -->



<!-- Modal -->
<div class="modal" id="approveRejectModal" tabindex="-1"  [ngStyle]="{'display':displayApproveReject}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Confirm</strong></h5>
        <!-- <button type="button" class="btn text-white" data-bs-dismiss="modal" (click)="openModalApproveReject('CLOSE')"><i class="bi bi-x-lg"></i></button> -->
      </div>
      <div class="modal-body ps-4 pe-4">
        <h6 class="text-center mt-4 mb-4">
          <!-- Confirm Product : <b>{{ existingProductname }}</b>  -->

          <div *ngIf="valApproveReject=='APPROVE'">Do you want to approve and publish the changes to product  <b>{{ existingProductname }}</b> ?</div>
          <div *ngIf="valApproveReject=='REJECT'">Do you want to reject the changes to product <b>{{ existingProductname }}</b> ?</div>


        </h6>


        
        <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
          <div class="row">
            <div class="col-6">
              <button type="button" class="btn  text-white pe-5 ps-5 text-uppercase fsmall btn-secondary"  (click)="openModalApproveReject('CLOSE')">Cancel</button>
            </div>
            <div class="col-6">
              <button type="button" class="btn pe-5 ps-5 cancelbtn text-uppercase fsmall text-white btn-danger" data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'REJECT')" *ngIf="valApproveReject=='REJECT'">Reject</button>
              <button type="button" class="btn  text-white pe-5 ps-5 text-uppercase fsmall btn-success"  data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'APPROVE')" *ngIf="valApproveReject=='APPROVE'">Approve</button>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>




<!-- Modal -->
<!-- <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaypublish}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Product published!</p>
          
          
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">

          </div>
  
        </div>
  
      </div>
    </div>
</div> -->

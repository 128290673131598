<div class="container" *ngIf="!previewShopSide">



    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 d-none d-sm-block">

        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <!-- <h6 class="pt-2 mt-1"><strong class="c1">Vendor  >  <a href='/vendor/products'><font class="c1">Product List</font></a>  > <font class="c2">Product Detail</font></strong> </h6> -->

              <table>
                <tr>
                  <td class="separator">
                    <!-- <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6> -->
                    <h6 class="pt-2 mt-1 me-3"><strong><a href='/vendor/product-list' class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                  </td>
                  <td>
                    <h6 class="pt-2 mt-1  ms-3"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">Product Detail</font></strong> </h6>
                  </td>  
                </tr>
              </table>


            </div>
          </div>
          <div class="col-sm-4">
            <!-- <div class="input-group mb-0 breadcumsearchdiv">
              <input type="text" class="form-control breadcumsearchinput" placeholder="Search products..." aria-label="Recipient's username" aria-describedby="button-addon2">
              <button type="button" class="btn btn-primary breadcumsearchbox align-self-center"><img class="breadcumsearchbox" src="/assets/images/icons/ic_Search.png"></button>
            </div> -->
          </div>
        </div>
    </div>
  
    
    <!-- Breadcrumb mobile -->
    <div class="d-block d-sm-none">
      <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">
        <div class="row">
          <div class="col-12">
            <div class="pt-1">
              <strong class="c1">
                <a class="c1 pointer" href="/vendor/product-list">Product List</a> >
                <font class="text-muted">Product Detail
                </font>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>    

    <form>
        <div class="container">
    <div class="row pb-5">


        <div class="col-sm-12 mt-0 pt-0" *ngIf="successdraft">

            <div class="alert alert-success alert-dismissible fade show rounded-1 successNotif" role="alert">
                <strong>Success</strong> : Your data has been successfully saved as a draft!
                <p class="btn-close successNotif" (click)="closesuccessdraft()"></p>
              </div>
   
        </div>

        <div class="col-sm-12 mt-0 pt-0" *ngIf="StatusComparation">

            <div class="alert alert-info alert-dismissible fade show rounded-1 infoNotif" role="alert">


                <div class="row">
                  <div class="col-md-10"><p class="mt-1 mb-0">Info : There is a change regarding this product! Please click <strong>"Check Now"</strong> button to see more details on the changes.  </p></div>
                  <div class="col-md-2 text-end"><a class="btn btn-info mb-0 text-white bgNotif" href="/vendor/product/compare/{{EditProductId}}">Check Now</a></div>
                </div>





            </div>
   
        </div>
        




        <div class="col-md-12 divprodlist">
            <div class="row">
                <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-0 pb-3 rounded-top ps-3">
                  <h4 class="mb-0">Detail Product</h4>
                </div>              
                

                <div class="col-sm-4 p-sm-5 pt-3">
                  <!-- appImgFallback : <img src="https://productimage-cepetdapet-dev.s3.ap-southeast-1.amazonaws.com/company444/test%20star1/star-filled-fill.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230503T050210Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1799&X-Amz-Credential=AKIA2LY4HGG47AK2IOKK%2F20230503%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=88f1a36820cdd1985f03d7857315e3ee40f849dd5126c9cfed5edd047521bab1" appImgFallback />
                  <br><br>

                  appImgFallback : <img src="{{ValimageFileName}}" appImgFallback="https://via.placeholder.com/100" /> -->




                  <div class="d-none d-sm-block">
                    <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                      <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12 text-center':'col-4'}}" [attr.href]="item.src">
                        <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                      </a>
                    </lightgallery>
                  </div>

                  <div class="d-block d-sm-none">
                    <lightgallery [settings]="settingsGalleryMobile" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                      <div class="col-9">
                        <ng-container *ngFor="let item of itemsGallery; let i = index">
                          <a *ngIf="i==0" class="text-center itemz" [attr.href]="item.src">
                            <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                          </a>
                        </ng-container>
                        
                      </div>
                      <div class="col-3">
                        <div class="row g-0">
                          <ng-container *ngFor="let item of itemsGallery; let i = index">
                            <div class="col-12">
                              <a *ngIf="i!==0" class="text-center itemz" [attr.href]="item.src">
                                <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                              </a>
                            </div>
                          </ng-container>
                        </div>
                      </div>

                    </lightgallery>
                  </div>


                  <!-- <div class="row  g-0">
                    <div class="col-9 col-sm-8 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">
                        <div class="text-center bg-light border rounded-0  thumbimgdiv1" *ngIf="!ValimageFileName"> 
                            <h2 class="bi bi-plus-lg text-secondary thumbimg"></h2>
                        </div>

                        <div class="text-center bg-white border-0 rounded-0 thumbimgdiv1" *ngIf="ValimageFileName">
                          <img [src]="ValimageFileName" class="thumbimgdivinner" appImgFallback>
                        </div>

                    </div>

                    <div class="col-3 col-sm-4 col-md-12 col-lg-12">
                      <div class="row g-0">
                        <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1" *ngFor="let MoreImg of imageSrcMore; let i = index">
                          <div class="text-center bg-white border-0 rounded-0  thumbimgdiv2" *ngIf="!MoreImg?.url">
                          </div>

                          <div class="text-center bg-white border-0 rounded-0  thumbimgdiv2" *ngIf="MoreImg?.url">
                            <img [src]="MoreImg?.url" class="thumbimgdivinnerMore" appImgFallback>
                          </div>

                        </div>
                      </div>
                    </div>


                  </div> -->
      
      
                  
                </div>
                <div class="col-sm-8 p-sm-5 pt-3">

                  <div class="d-block d-sm-none">

                    <div class="row m-padding">

                      <div class="col-12">
                        <h3 class="mb-3"><strong class="textName">{{Valname}}</strong></h3>
                      </div>

                      <div class="col-5 col-sm-3">Brand</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">{{Valbrand}}</div>

                      <div class="col-5 col-sm-3">Product Code</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">{{Valsku}}</div>

                      <div class="col-5 col-sm-3">Manf. Code</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">{{ValmanufacturerSku}}</div>

                      <div class="col-5 col-sm-3">Tag</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8"><span class="badge rounded-pill bg-secondary me-1 bgtags c1 badgetag"  *ngFor="let tags of Valtags; let i = index">{{ tags?.tag }}
                      </span></div>

                      <div class="col-5 col-sm-3">Display Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">
                        <span *ngIf='ValisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                        </span>
                        <span *ngIf='!ValisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                        </span>
                      </div>

                      <div class="col-5 col-sm-3 pe-0">Display Product on Shopfront</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">
                        <span *ngIf='ValisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                        </span>
                        <span *ngIf='!ValisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                        </span>
                      </div>

                      <div class="col-5 col-sm-3">Auto Bid</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-8">
                        <span *ngIf='ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                        </span>
                        <span *ngIf='!ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                        </span>
                      </div>
                      
                      <div *ngIf='ValisAutobidEnabled' class="col-5 col-sm-2">Discount Increments</div>
                      <div *ngIf='ValisAutobidEnabled' class="col-1 col-sm-1">:</div>
                      <div *ngIf='ValisAutobidEnabled' class="col-6 col-sm-9">
                        {{Valcurrency}} {{ValautobidPriceDecrement | number}}
                      </div>


                      <div class="col-5 col-sm-2">Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-2"><p class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{Valcurrency}} {{Valprice | number}}</strong></p></div>

                      <div class="col-5 col-sm-2">Min. Price</div>
                      <div class="col-1 col-sm-1">:</div>
                      <div class="col-6 col-sm-2">
                        <p class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice" *ngIf="ValautobidMinimumPrice">{{Valcurrency}} {{ValautobidMinimumPrice | number}}</strong>
                        <strong class="texPrice" *ngIf="!ValautobidMinimumPrice"> - 
                        </strong>
                      </p>
                      </div>

                    </div>

                  </div>

                  <div class="d-none d-sm-block">


                  <div class="row">
                    <div class="col-12">
                      <h3 class="mb-3"><strong class="textName">{{Valname}}</strong></h3>
                    </div>
                    <div class="col-sm-6">

                      <table class="table mb-0">

                        <tr>
                            <td width="62%">Brand</td>
                            <td width="5%">:</td>
                            <td width="33%">{{Valbrand}}</td>
                        </tr>
                        <tr>
                            <td>Product Code</td>
                            <td>:</td>
                            <td>{{Valsku}}</td>
                        </tr>
                        <tr>
                            <td>Manf Code</td>
                            <td>:</td>
                            <td>{{ValmanufacturerSku}}</td>
                        </tr>

                        
                        

                    </table>

                    </div>
                    <div class="col-sm-6">
                      
                      <table class="table mb-0">
                        <tr>
                            <td>Price</td>
                            <td>:</td>
                            <td><p class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{Valcurrency}} {{Valprice | number}}</strong></p></td>
                        </tr>
                        <tr>
                            <td>Min. Price</td>
                            <td>:</td>
                            <td><p class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice" *ngIf="ValautobidMinimumPrice">{{Valcurrency}} {{ValautobidMinimumPrice | number}}</strong>
                            <strong class="texPrice" *ngIf="!ValautobidMinimumPrice"> - 
                            </strong>
                          </p></td>
                        </tr>
                    </table>
                      
                    </div>

                    <div class="col-sm-12">
                      <table class="table">
                        <tr>
                            <td width="30%">Tag</td>
                            <td width="2.5%">:</td>
                            <td>

                              <span class="badge rounded-pill bg-secondary me-1 bgtags c1 badgetag"  *ngFor="let tags of Valtags; let i = index">{{ tags?.tag }}
                              </span>

                            </td>
                        </tr>
                        <tr>
                            <td>Display Price</td>
                            <td>:</td>
                            <td>
                              <!-- {{(ValisDisplayPriceOnShopfront ? 'YES':'NO')}} -->
                              <span *ngIf='ValisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                              </span>
                              <span *ngIf='!ValisDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                              </span>
                            </td>
                        </tr>
                        <tr>
                          <td>Display Product on Shopfront</td>
                          <td>:</td>
                          <td>
                            <!-- {{(ValisDisplayProductOnShopfront ? 'YES':'NO')}} -->
                            <span *ngIf='ValisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                            </span>
                            <span *ngIf='!ValisDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td>Auto Bid</td>
                          <td>:</td>
                          <td>
                            <!-- {{(ValisDisplayProductOnShopfront ? 'YES':'NO')}} -->
                            <span *ngIf='ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                            </span>
                            <span *ngIf='!ValisAutobidEnabled' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                            </span>
                          </td>
                        </tr>

                        <tr *ngIf='ValisAutobidEnabled'>
                          <td>Discount Increments</td>
                          <td>:</td>
                          <td>
                            {{Valcurrency}} {{ValautobidPriceDecrement | number}}
                          </td>
                        </tr>                        
                      </table>
                    </div>

                    <div class="col-12 mt-3 p-5 pt-1 ps-0 d-none">
                      <div class="ps-2 pe-5 row">
                        <div class="col text-center">
                            <div class="boxratingtitle">Customer Satisfaction</div>
                            <div class="boxrating pt-2 pb-2">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                            </div>
                        </div>
                        <div class="col text-center">
                              <div class="boxratingtitle">Product Rating</div>
                              <div class="boxrating pt-1 pb-1">
                                  <h1 class="fw-bold fw-bold mb-1 mt-1">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid"></h1>
                              </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">Viewed Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">Quoted Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                        <div class="col text-center">
                            <div class="boxratingtitle">On Auction Today</div>
                            <div class="boxrating pt-1 pb-1">
                              <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                            </div>
                        </div>
                     </div>
                    </div>

                  </div>

                  </div>

                    <!-- <table class="table">
                        <tr>
                            <td colspan="3">
                                <h3><strong>{{Valname}}</strong></h3>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">Brand</td>
                            <td width="5%">:</td>
                            <td width="70%">{{Valbrand}}</td>
                        </tr>
                        <tr>
                            <td>Product Code</td>
                            <td>:</td>
                            <td>{{Valsku}}</td>
                        </tr>
                        <tr>
                            <td>Manf Code</td>
                            <td>:</td>
                            <td>{{ValmanufacturerSku}}</td>
                        </tr>
                        <tr>
                            <td>Tag</td>
                            <td>:</td>
                            <td>

                              <span class="badge rounded-pill bg-secondary me-1 bgtags"  *ngFor="let tags of Valtags; let i = index">{{ tags?.tag }}
                              </span>

                            </td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td>:</td>
                            <td><h4 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong>{{Valprice | number}}</strong></h4></td>
                        </tr>
                        <tr>
                            <td>Min. Price</td>
                            <td>:</td>
                            <td><h4 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong>{{ValautobidMinimumPrice | number}}</strong></h4></td>
                        </tr>
                        <tr>
                            <td>Display Price</td>
                            <td>:</td>
                            <td>{{(ValisDisplayPriceOnShopfront ? 'YES':'NO')}}</td>
                        </tr>
                    </table> -->
        
                </div>
    


        
        
        
                <div class="col-sm-12 mb-0 mt-0  px-sm-5 pt-1 pb-1">
        
                    <!-- <div class="mb-3 row">
                        <label for="description" class="col-sm-2 col-form-label">Description</label>
                        <div class="col-sm-10">
                            <input type="text" [(ngModel)]="Valdescription" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors }" />
                            <div *ngIf="submitted && f?.description?.errors" class="invalid-feedback">
                                <div *ngIf="f?.description?.errors?.required">Description is required</div>
                            </div>
                        </div>
                    </div> -->
        
        
        
                <div class="pt-2 pb-2 text-white text-uppercase "><strong class="fhc1">Description</strong></div>
                

                <div class="p-4" style="border:1px solid #18877d;">

                <!-- <div style="white-space: pre-line" [innerHTML]="Valdescription"></div> -->
                <div style="white-space: pre-line">{{divshotdesc(Valdescription)}}</div>

                </div>

                </div>

                <div class="p-0 px-5 mb-5 mt-1">
                  <!-- <table class="table table-bordered w-50">
                    <tbody>
                      <tr *ngFor="let FhSpec of divFhSpec; let i = index">
                        <td>{{ FhSpec.label }}</td><td>{{ FhSpec.value }}</td>
                      </tr>
                    </tbody>
                  </table> -->
                  <div class="px-sm-4 pt-2">
                    <div [innerHtml]="divspec(Valfulldescription)"></div>
                  </div>
                    
                </div>
        

        


            </div>


    
    
    

        </div>

        <div class="col-12 mt-4 mb-4 p-0">
          <!-- <div class="text-center" style="background-color:#c4c4c4; padding:150px 0px;">
            <h4 class="f1"><strong>*ADS*</strong></h4>
          </div> -->
        </div>

        <!-- <div class="col-md-2 sticky-xl-top sticky-lg-bottom sticky-md-top  topmore">



            <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan w-100 rounded-1 mb-2"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
            <button type="button" class="btn btn-dark fhbcyan w-100 rounded-1 mb-2 disabled"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>





            <button type="button" (click)="openModalApproveReject()" *ngIf="CanApproveReject" class="btn fhbcyan w-100 rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</button>
            

            <p *ngIf="!CanApproveReject" class="fhbcyan w-100 rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</p>





            <p class="fhbcyan w-100 rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled" [hidden]='StatusApproval!==4'>Approved</p>
            






            <button type="button" (click)="submitPublish()" class="btn btn-dark fhbgblack w-100 rounded-1 mb-2"  *ngIf="!LoadingreqeustPublish" [hidden]="Valstate=='PUBLISHED'" [disabled]="StatusApproval!==4">Publish</button>
            <button type="button" class="btn btn-dark fhbgblack w-100 rounded-1 mb-2"  *ngIf="LoadingreqeustPublish" disabled>Loading..</button>

            <button type="button" class="btn btn-dark fhbgblack w-100 rounded-1 mb-2"  [hidden]="Valstate!=='PUBLISHED'" disabled>Published</button>


            <a type="button"  class="btn btn-dark fhbgblue w-100 rounded-1 mb-2" href="/vendor/product/edit/{{EditProductId}}" *ngIf="!pageloading">Edit</a>


            <div class="mt-5 pt-5">

                <button  type="button" (click)="openmodaldelete()" class="btn btn-danger fhbgred w-100 rounded-1 mb-2" *ngIf="!pageloading">Delete</button>
                <button class="btn btn-primary fhbgred w-100 rounded-1 mb-2" *ngIf="pageloading" disabled>Loading..</button>

                <button  type="button" class="btn btn-primary bg2 w-100 rounded-1 mb-2" *ngIf="!pageloading">Market Intelligence</button>
                <button class="btn btn-primary bg2 w-100 rounded-1 mb-2" *ngIf="pageloading" disabled>Loading..</button>

            </div>


            <div class="mt-3 d-none" *ngIf="successResponse">
                <div class="alert alert-success alert-dismissible fade show" role="alert" >
                    <p class="mb-0" [innerHTML]="successResponseName"></p>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>               
            </div>

        </div> -->




        <!-- <div class="col-sm-2"></div> -->
    </div>
        </div>
    </form>
</div>  


<div class="fixed-bottom"  *ngIf="!previewShopSide">
  <div class="" [ngStyle]="{'display':displaytimeline}">
    <div class="divtimeline p-1">
      <div class="card timelinecard shadow">
        <div class="card-header bgtimelineheader">
          <div class="row p-0 ">
            <div class="col-9">
              Product Status Timeline
            </div>
            <div class="col-3 text-end">
              <i class="bi bi-x-lg text-end pointer" (click)="OpenModalTimeLine()"></i>
            </div>
          </div>

  
        </div>
        <div class="card-body">

          <div class="mobile-stepper d-block d-sm-none">
            <div class="steps d-flex justify-content-between padding-top-2x padding-bottom-1x  pt-2 pb-3">

              
              <div class="step" [ngClass]="{'completed': (StatusApproval==2 || StatusApproval==4) }">
                <div class="step-icon-wrap">
                    <div class="step-icon">
                        <i class="bi bi-check2"></i>
                    </div>
                </div>
                <h4 class="step-title">Approval<br>Requested</h4>
              </div>
              
              <div class="step" [ngClass]="{'completed': StatusApproval==4 }">
                <div class="step-icon-wrap">
                    <div class="step-icon">
                        <i class="bi bi-check2"></i>
                    </div>
                </div>
                <h4 class="step-title">Product<br>Approved</h4>
              </div>


              <div class="step"  [ngClass]="{'completed': StatusApproval==4 && Valstate=='PUBLISHED'}">
                <div class="step-icon-wrap">
                    <div class="step-icon">
                        <i class="bi bi-check2"></i>
                    </div>
                </div>
                <h4 class="step-title">Product<br>Published</h4>
              </div>



            </div>
          </div>


          <div class="timeline d-none d-sm-block">
            <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1 {{ StatusApproval==4 ? 'activeLine':'inactiveLine' }} inactiveIcon {{ (StatusApproval==2 || StatusApproval==4) ? 'activeIcon':'' }}"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4) && Valstate=='UNPUBLISHED'}">
              <div class="timeline__event__icon ">
                <i class="lni-cake"></i>
                <div class="timeline__event__date inactiveTimeline"  [ngClass]="{'activeTimeline': (StatusApproval==2 || StatusApproval==4)}">
                  Approval Requested
                </div>
              </div>
              <!-- <div class="timeline__event__content ">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div> -->
            </div>
            <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2 inactiveIcon {{ StatusApproval==4  ? 'activeIcon':'' }} {{ StatusApproval==4 && Valstate=='PUBLISHED' ? 'activeLine':'inactiveLine' }} ">
              <div class="timeline__event__icon">
                <i class="lni-burger"></i>
                <div class="timeline__event__date inactiveTimeline {{ Valstate!=='UNPUBLISHED' ? 'activeTimeline':'' }}" [ngClass]="{'activeTimeline': StatusApproval==4}">
                  Product Approved
                </div>
              </div>
              <!-- <div class="timeline__event__content">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div> -->
            </div>
            <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3 inactiveIcon {{ StatusApproval==4 && Valstate=='PUBLISHED'  ? 'activeIcon':'' }}" style="margin-bottom: 10px !important;">
              <div class="timeline__event__icon">
                <i class="lni-slim"></i>
                <div class="timeline__event__date inactiveTimeline" [ngClass]="{'activeTimeline': StatusApproval==4 && Valstate=='PUBLISHED'}">
                  Product Published
                </div>
              </div>
              <!-- <div class="timeline__event__content">
                <p>Lorem, ipsum dolor sit amet.</p>
          
              </div> -->
            </div>

          
          </div>
          <div class="text-center">
            <p class="texttimeline" [hidden]='StatusApproval!==1'>Click the “Request Approval” button to process product listing. </p>

            <p class="texttimeline" [hidden]='StatusApproval!==2' *ngIf="!CanApproveReject">Please wait, your product is being review</p>
            <p class="texttimeline" [hidden]='StatusApproval!==2'*ngIf="CanApproveReject">Click the “Approve” button to process product listing or “Reject” to ignore. </p>
            
            <p class="texttimeline" [hidden]='StatusApproval!==4' *ngIf="Valstate!=='PUBLISHED'">Your product is has been approved, click “Publish” button to continue to listing.</p>
            
            <p class="texttimeline" *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Congratulations! Your product is succesfully Published!</p>
            
            

            <!-- <button type="button" class="btn btn-primary fhbgblack mt-3 mb-3" disabled>Approval Requested</button> -->



            <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan rounded-1 mb-2"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
            <button type="button" class="btn btn-dark fhbcyan rounded-1 mb-2 disabled"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>
  
  
  
  
  
            <!-- <button type="button" (click)="openModalApproveReject()" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button> -->

            <!-- <button type="button" (click)="openModalApproveReject('REJECT')" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button>

            <button type="button" (click)="openModalApproveReject('APPROVE')" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 pe-2" [hidden]='StatusApproval!==2'>Approval Requested</button> -->

            <div class="row g-0">
              <div class="col-6">
                <button type="button" class="btn btn-sm apprrejBtn cancelbtn  fsmall text-white btn-danger" (click)="openModalApproveReject('REJECT')" *ngIf="CanApproveReject"  [hidden]='StatusApproval!==2'>Reject</button>
              </div>
              <div class="col-6">
                <button type="button" class="btn btn-sm  text-white apprrejBtn  fsmall btn-success" (click)="openModalApproveReject('APPROVE')" *ngIf="CanApproveReject"  [hidden]='StatusApproval!==2'>Approve</button>
              </div>
            </div>

            
            
            
  
            <!-- <font *ngIf="!CanApproveReject" class="fhdisabledbtn rounded-1 mb-2 text-center text-bold pt-2 pb-2 ps-3 pe-3" [hidden]='StatusApproval!==2'><strong>Approval Requested</strong></font> -->
  
  
  
  
            
            <!-- <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled ps-3 pe-3" [hidden]='StatusApproval!==4'>Approved</font> -->

              <!-- <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled ps-3 pe-3" *ngIf='StatusApproval==4 && Valstate=="UNPUBLISHED"'>Approved</font> -->

            <div *ngIf="StatusApproval==4 && Valstate!=='PUBLISHED'">

            
              <button type="button" (click)="submitPublish()" class="btn btn-dark fhbcyan rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="!LoadingreqeustPublish" [disabled]="StatusApproval!==4">Publish</button>
              <button type="button" class="btn btn-dark fhbcyan rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="LoadingreqeustPublish" disabled>Loading..</button>
            </div>


              <font class="fhpublished rounded-1 mb-2 text-center pt-2 pb-2  ps-3 pe-3"  *ngIf='StatusApproval==4 && Valstate=="PUBLISHED"'>Product Published!</font>

            


          </div>


        </div>
      </div>
    </div>
    
  </div>
  <div class="bg-white pt-3 pb-3 d-none d-sm-block">
    <div class="container">
      <div class="row">
        <div class="col-8">

          <!-- <button type="button" class="btn btn-link  rounded-1 roundedmore  mb-2 c1  me-3" ><strong>Market Intelligence</strong></button> -->


          
          <a type="button"  class="btn fhbtn3 fhbdark rounded-1 roundedmore fixwbtn mb-2 text-white me-3" href="/vendor/product/edit/{{EditProductId}}" *ngIf="!pageloading"><i class="bi bi-pencil-square"></i> Edit</a>

          <button  type="button" (click)="openmodaldelete()" class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="!pageloading"> <i class="bi bi-trash"></i> Delete</button>
          <button class="btn btn-primary fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="pageloading" disabled>Loading..</button>



        </div>
        <div class="col-4 text-end">
          <!-- <button type="button" (click)="submitPublish()" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="!LoadingreqeustPublish" [hidden]="Valstate=='PUBLISHED'" [disabled]="StatusApproval!==4">Publish</button>
          <button type="button" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  *ngIf="LoadingreqeustPublish" disabled>Loading..</button>

          <button type="button" class="btn btn-dark fhbgblack rounded-1 roundedmore fixwbtn mb-2 ms-3"  [hidden]="Valstate!=='PUBLISHED'" disabled>Published</button> -->





          <button type="button" class="btn btn-link  rounded-1 roundedmore mb-2 c1 ms-3" (click)="OpenModalTimeLine()"><strong>Approval Status <font class="bi bi-caret-{{displaytimeline=='none'?'up':'down'}}-fill"></font></strong></button>




          
          <!-- <div class="d-none">

          <button type="button" (click)="reqeustApproval(EditProductId,'REQUEST')" class="btn btn-dark fhbcyan rounded-1 mb-2"  *ngIf="!LoadingreqeustApproval" [hidden]='StatusApproval!==1'>Request Approval</button>
          <button type="button" class="btn btn-dark fhbcyan rounded-1 mb-2 disabled"  *ngIf="LoadingreqeustApproval" [hidden]='StatusApproval!==1' disabled>Loading...</button>





          <button type="button" (click)="openModalApproveReject()" *ngIf="CanApproveReject" class="btn fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</button>
          

          <font *ngIf="!CanApproveReject" class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2" [hidden]='StatusApproval!==2'>Approval Requested</font>





          <font class="fhbcyan rounded-1 mb-2 text-center text-white pt-2 pb-2 txtdisabled" [hidden]='StatusApproval!==4'>Approved</font>
          

        </div> -->

          
        </div>
      </div>
    </div>
  </div>


  <div class="bg-white pt-3 pb-3 d-block d-sm-none">
    <div class="container">
      <div class="row g-0">
        <div class="col-2">
            <div class="px-1">


                <div ngbDropdown placement="top-right" class="d-inline-block mbtnmore w-100">
                    <a class="btn btn-link btnlinkmore rounded-1 roundedmore w-100 text-dark" ngbDropdownToggle>
                        <i class="bi bi-three-dots"></i>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                        <button class="fw-bold py-2" ngbDropdownItem ngbDropdownToggle><i class="bi bi-x-lg"></i> Lainnya</button>

                        <a (click)="openmodaldelete()" class="text-danger fw-bold py-2" *ngIf="!pageloading" ngbDropdownItem> <i class="bi bi-trash"></i> Delete</a>
                        <a class="text-danger fw-bold py-2 disabled" *ngIf="pageloading" disabled ngbDropdownItem>Loading..</a>

                    </div>
                </div>

            </div>


            <!-- <button type="button" (click)="submitaction('draft')" class="btn btn-dark fhbtn3 rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4"  *ngIf="!pageloading">Save Draft</button>
            <button class="btn btn-dark fhbdark rounded-1 roundedmore fixwbtn mb-2 me-3 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button>
  
            <a class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2 ps-4 pe-4" href="/vendor/product-list">Cancel</a> -->
        </div>
        <div class="col-5">
        <div class="px-1">
            <!-- <button  type="button" (click)="submitaction('done')" class="btn btn-primary fhbcyan mbtndone rounded-1 roundedmore w-100 ps-4 pe-4" *ngIf="!pageloading">Done</button>
            <button class="btn btn-primary fhbcyan mbtndone rounded-1 roundedmore w-100 ps-4 pe-4" *ngIf="pageloading" disabled>Loading..</button> -->

            <a type="button"  class="btn fhbtn3 fhbdark rounded-1 roundedmore w-100 text-white mbtnbrdc3" href="/vendor/product/edit/{{EditProductId}}" *ngIf="!pageloading"><i class="bi bi-pencil-square"></i> Edit</a>

            

        </div>


          
        





        </div>
        <div class="col-5">
        <div class="px-1">
            <button type="button" class="btn btn-text  rounded-1 roundedmore w-100 c1 mbtnbrdc1 limitTxt" (click)="OpenModalTimeLine()"><strong>Approval Status <font class="miconbold px-0 bi bi-chevron-{{displaytimeline=='none'?'up':'down'}}"></font></strong></button>
        </div>


        </div>
      </div>
    </div>
  </div>  

</div>


<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydraft}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="draftimgicon" src="/assets/images/icons/draft-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully<br>saved as a draft!</p>
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <!-- <button type="button w-100" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" (click)="openmodaldraft()">DONE</button> -->
            <a href="/procurement/product/detail/{{ EditProductId }}" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5">PREVIEW</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydone}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully saved!</p>
          
          
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/product-list" data-bs-dismiss="modal">DONE</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>



<!-- Modal -->
<div class="modal" id="deleteModal" tabindex="-1"  [ngStyle]="{'display':displaydelete}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Delete Product</strong></h5>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-4 mb-4">
          Are you sure want to delete <b>"{{ Valname }}"</b> Product ?
        </h6>


        
        <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
          <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall" (click)='openmodaldelete()'>Cancel</button>
          <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="confirmdelete()"  data-bs-dismiss="modal">Delete</button>
        </div> -->

        <div class="row justify-content-center g-0 p-3">
          <div class="col-6 col-sm-4">
            <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall" (click)='openmodaldelete()'>Cancel</button>
          </div>
          <div class="col-6 col-sm-4">
            <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="confirmdelete()"  data-bs-dismiss="modal">Delete</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal" id="approveRejectModal" tabindex="-1"  [ngStyle]="{'display':displayApproveReject}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Confirm</strong></h5>
        <button type="button" class="btn text-white" data-bs-dismiss="modal" (click)="openModalApproveReject('CLOSE')"><i class="bi bi-x-lg"></i></button>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-4 mb-4">
          <!-- Confirm Product : <b>{{ Valname }}</b>  -->

          <div *ngIf="valApproveReject=='APPROVE'">Do you want to approve product <b>{{ Valname }}</b> ?</div>
          <div *ngIf="valApproveReject=='REJECT'">Do you want to reject product <b>{{ Valname }}</b> ?</div>


        </h6>


        
        <div class="d-grid gap-2 d-flex justify-content-center mb-4 ">
          
          <button type="button" class="btn  text-white pe-5 ps-5 text-uppercase fsmall btn-secondary"  (click)="openModalApproveReject('CLOSE')">Cancel</button>
          
          

          <button type="button" class="btn pe-5 ps-5 cancelbtn text-uppercase fsmall text-white btn-danger" data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'REJECT')" *ngIf="valApproveReject=='REJECT'">Reject</button>
          <button type="button" class="btn  text-white pe-5 ps-5 text-uppercase fsmall btn-success"  data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'APPROVE')" *ngIf="valApproveReject=='APPROVE'">Approve</button>
        </div>

      </div>

    </div>
  </div>
</div>






<div class="container d-none" *ngIf="previewShopSide">
  <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
      <div class="row">
        <div class="col-sm-8">
          <div class="shbreadcum">
            <table>
              <tr>
                <td class="separator">
                  <h6 class="pt-2 mt-1 me-3 pointer"><strong><a (click)="closepage()" class="c1"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                </td>
                <td>
                  <h6 class="pt-2 mt-1  ms-3"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">Preview Shop Side</font></strong> </h6>
                </td>  
              </tr>
            </table>
          </div>
        </div>
        <div class="col-sm-4">
        </div>
      </div>
  </div>
  
  <form>
      <div class="container">
          <div class="row pb-5">
                <div class="col-md-12 divprodlist">
                    <div class="row">
                        <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-0 pb-3 rounded-top ps-3">
                          <h4 class="mb-0">Detail Product</h4>
                        </div>              
                        <div class="col-sm-4 p-sm-5 pt-3">
                          <div class="d-none d-sm-block">
                            <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                              <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12 text-center':'col-4'}}" [attr.href]="item.src">
                                <img class="img-fluid p-1" src="{{ item.thumb }}" />
                              </a>
                            </lightgallery>
                          </div>
       
                          <div class="d-block d-sm-none">
                            <lightgallery [settings]="settingsGalleryMobile" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                              <div class="col-9">
                                <ng-container *ngFor="let item of itemsGallery; let i = index">
                                  <a *ngIf="i==0" class="text-center itemz" [attr.href]="item.src">
                                    <img class="img-fluid p-1" src="{{ item.thumb }}" />
                                  </a>
                                </ng-container>
                                
                              </div>
                              <div class="col-3">
                                <div class="row g-0">
                                  <ng-container *ngFor="let item of itemsGallery; let i = index">
                                    <div class="col-12">
                                      <a *ngIf="i!==0" class="text-center itemz" [attr.href]="item.src">
                                        <img class="img-fluid p-1" src="{{ item.thumb }}" />
                                      </a>
                                    </div>
                                  </ng-container>
                                </div>
                              </div>

                            </lightgallery>
                          </div>
              
                          
                        </div>
                        <div class="col-sm-8 pe-5 pb-5 pt-3">
                
                          <div class="row">
                            <div class="col-12">
                              <h3 class="mb-3"><strong class="textName">{{dataShopSide.name}}</strong></h3>
                            </div>
                            <div class="col-sm-12 mb-2">
                              <table>
                                <tr>
                                  <td>
                                    <img class="rounded-circle" style="width: 40px; height: 40px;" src="/assets/images/default-company.jpeg">
                                  </td>
                                  <td><strong class="ms-2 me-2">{{companyName}}</strong></td>
                                  <td>
                                    <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                    <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall"> -->
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div class="col-sm-12">

                              <table class="table">

                                <tr>
                                    <td width="32%">Brand</td>
                                    <td width="4%">:</td>
                                    <td width="64%">{{dataShopSide.brand}}</td>
                                </tr>
                                <tr>
                                    <td>Product Code</td>
                                    <td>:</td>
                                    <td>{{dataShopSide.sku}}</td>
                                </tr>
                                <tr>
                                    <td>Manf Code</td>
                                    <td>:</td>
                                    <td>{{dataShopSide.manufacturerSku}}</td>
                                </tr>
                                <tr>
                                    <td>Tag</td>
                                    <td>:</td>
                                    <td>
                                      <span class="badge rounded-pill bg-secondary me-1 bgtags c1 badgetag"  *ngFor="let tags of dataShopSide.tags; let i = index">{{ tags }}
                                      </span>

                                    </td>
                                </tr>
                                <tr class="d-none">
                                    <td>Display Price</td>
                                    <td>:</td>
                                    <td>
                                      <!-- {{(ValisDisplayPriceOnShopfront ? 'YES':'NO')}} -->
                                      <span *ngIf='dataShopSide.isDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                                      </span>
                                      <span *ngIf='!dataShopSide.isDisplayPriceOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                                      </span>
                                    </td>
                                </tr>
                                <tr class="d-none">
                                  <td>Display Product on Shopfront</td>
                                  <td>:</td>
                                  <td>
                                    <!-- {{(ValisDisplayProductOnShopfront ? 'YES':'NO')}} -->
                                    <span *ngIf='dataShopSide.isDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgenable">Enable
                                    </span>
                                    <span *ngIf='!dataShopSide.isDisplayProductOnShopfront' class="badge rounded-pill ps-2 pe-2 pt-1 pb-1 bgdisable">Disable
                                    </span>
                                  </td>
                              </tr>
                            </table>

                            </div>

                            <div class="mb-3 d-none">
                              <strong><small>Product Rating</small></strong>
                              <table>
                                <tr>
                                  <td>
                                    <strong class="texPrice">4.8</strong>
                                  </td>
                                  <td>
                                    <img src="/assets/images/icons/rating-star.png" class="img-fluid starsmall">
                                  </td>
                                </tr>
                              </table>
                            </div>

                            <div>

                              <div class="p-4" style="border:1px solid #b7b7b7;border-radius: 5px;" [hidden]="!dataShopSide.isDisplayPriceOnShopfront">
                                <table>
                                  <tr>
                                    <td><strong class="pe-5">Price</strong></td>
                                    <td><strong class="texPrice">{{dataShopSide.currency}} {{dataShopSide.price | number}}</strong></td>
                                  </tr>
                                </table>
                              </div>

                            </div>
                            <!-- <div class="col-sm-6">
                              
                              <table class="table">
                                <tr>
                                    <td width="25%">Price</td>
                                    <td width="5%">:</td>
                                    <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{dataShopSide.currency}} {{dataShopSide.price | number}}</strong></h2></td>
                                </tr>
                                <tr>
                                    <td>Min. Price</td>
                                    <td>:</td>
                                    <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{dataShopSide.currency}} {{dataShopSide.autobidMinimumPrice | number}}</strong></h2></td>
                                </tr>
                            </table>
                              
                            </div> -->
                          </div>
                        </div>
            

                        <div class="col-12 mt-3 p-5 pt-1 d-none">
                          <div class="ps-5 pe-5 row">
                            <div class="col text-center">
                                <div class="boxratingtitle">Customer Satisfaction</div>
                                <div class="boxrating pt-4 pb-4">
                                    <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                    <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                    <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                    <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                    <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                                </div>
                            </div>
                            <div class="col text-center">
                                  <div class="boxratingtitle">Product Rating</div>
                                  <div class="boxrating pt-3 pb-3">
                                      <h1 class="fw-bold fw-bold mb-1 mt-1">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid"></h1>
                                  </div>
                            </div>
                            <div class="col text-center">
                                <div class="boxratingtitle">Viewed Today</div>
                                <div class="boxrating pt-3 pb-3">
                                  <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                                </div>
                            </div>
                            <div class="col text-center">
                                <div class="boxratingtitle">Quoted Today</div>
                                <div class="boxrating pt-3 pb-3">
                                  <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                </div>
                            </div>
                            <div class="col text-center">
                                <div class="boxratingtitle">On Auction Today</div>
                                <div class="boxrating pt-3 pb-3">
                                  <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                </div>
                            </div>
                        </div>
                        </div>
                
                        <div class="col-sm-12 mb-3 p-5 pt-1 pb-1">
                
                
                        <!-- <div class="pt-2 pb-2 text-white text-uppercase "><strong class="c1">Description</strong></div> -->
                        

                        <div class="" style="border:1px solid #18877d;">
                        <div class="desclabel text-white text-uppercase bg1">Description</div>
                        <!-- <div style="white-space: pre-line" [innerHTML]="Valdescription"></div> -->
                        <div class="p-4" style="white-space: pre-line">{{divshotdesc(dataShopSide.description)}}</div>

                        </div>

                        </div>

                        <div class="p-0 px-5 mb-5 mt-1">
                          <div class="px-sm-4 pt-2">
                            <div [innerHtml]="divspec(dataShopSide.description)"></div>
                          </div>
                            <!-- <div [innerHtml]="divspec(dataShopSide.fulldescription)"></div> -->
                            
                        </div>
                
                    </div>

                </div>

                <div class="col-12 mt-4 mb-4 p-0">

                </div>

          </div>
      </div>
  </form>
</div>  

<div class="container" *ngIf="previewShopSide">

  <div class="container mt-0 mt-sm-4 mt-md-4 mt-lg-4 ">
    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3  d-none d-sm-block">
        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <table>
                <tr>
                  <td class="separator">
                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="closepage()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                  </td>
                  <td>
                    <h6 class="pt-2 mt-1  ms-3"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">Preview Shop Side</font></strong> </h6>
                  </td>  

                  
                </tr>
              </table>
            </div>
          </div>
          <div class="col-sm-4">
          </div>
        </div>
    </div>
  
      <!-- Breadcrumb mobile -->
  <div class="d-block d-sm-none">
  
    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">
  
  
      <div class="row">
        <div class="col-12">
          <div class="pt-1">

            <strong class="c1">
              <a class="c1">Vendor</a> > 
              <a class="c1 pointer" href="/vendor/product-list">Product List</a> > 
              <font class="text-muted">Preview Shop Side
              </font>
            </strong>
          </div>
  

        </div>
      </div>
  
    </div>
  </div>
    
    <form>
        <div class="container">
            <div class="row">
                  <div class="col-md-12 divprodlist">
                      <div class="row">
                          <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top ps-3">
                            <h4 class="mb-0">Detail Product</h4>
                          </div>              
                          <div class="col-sm-4 p-3 p-md-5 p-lg-5 py-lg-3 pt-3">
  


                            <div class="d-none d-sm-block">
                              <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                                <a *ngFor="let item of itemsGallery; let i = index" class="text-center {{i==0?'col-12 text-center':'col-4'}}" [attr.href]="item.src">
                                  <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                                </a>
                              </lightgallery>
                            </div>
          
                            <div class="d-block d-sm-none">
                              <lightgallery [settings]="settingsGalleryMobile" [onInit]="onInitGallery" id="shopsideGallery" class="row g-0">
                                <div class="col-9">
                                  <ng-container *ngFor="let item of itemsGallery; let i = index">
                                    <a *ngIf="i==0" class="text-center itemz" [attr.href]="item.src">
                                      <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                                    </a>
                                  </ng-container>
                                  
                                </div>
                                <div class="col-3">
                                  <div class="row g-0">
                                    <ng-container *ngFor="let item of itemsGallery; let i = index">
                                      <div class="col-12">
                                        <a *ngIf="i!==0" class="text-center itemz" [attr.href]="item.src">
                                          <img class="img-fluid p-1 imgcard" src="{{ item.thumb }}" />
                                        </a>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
          
                              </lightgallery>
                            </div>
                                      

                            <!-- <div class="d-none d-sm-block">
                              <div class="row g-0">
  
                                
  
                                  <div class="col-sm-12 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[0]}}">
                                  </div>
                  
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[1]}}">
                                  </div>   
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[2]}}">
                                  </div>   
                                  <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[3]}}">
                                  </div>                              
                
                  
                              </div>
                            </div>
  
                            <div class="d-block d-sm-none">
                              <div class="row">
  
                                <div class="col-9">
                                    <img class="img-fluid border-0" src="{{resdata?.imageUrl[0]}}">
                                </div>
                                <div class="col-3">
                                  <div class="row">
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[1]}}">
                                    </div>   
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[2]}}">
                                    </div>   
                                    <div class="col-12 pb-3">
                                      <img class="img-fluid border-0" src="{{resdata?.imageUrl[3]}}">
                                    </div>     
                                  </div>
                                </div>
                                
                              </div>                                   
                            </div> -->
      
                            
                          </div>
                          <div class="col-sm-8 px-3 px-sm-5 pb-3 pt-3">
  
                            <div class="row">
                              <div class="col-10">
                                <h3 class="mb-3"><strong class="textName">{{dataShopSide.name}}</strong></h3>
                              </div>
                              <div class="col-2">
                                <div class="d-inline-block divShare">
                                  <button class="btn btn-text btn-disabled text-end mt-2" disabled>
                                    <i class="bi bi-share"></i>
                                  </button>
                                </div>
                              </div>
                              <div class="col-sm-12">
  
                                <table class="table">
  
                                  <tr>
                                      <td width="32%">Brand</td>
                                      <td width="4%">:</td>
                                      <td width="64%">{{(dataShopSide?.brand) ? dataShopSide?.brand:'-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Product Code</td>
                                      <td>:</td>
                                      <td>{{(dataShopSide?.sku) ? dataShopSide?.sku:'-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Manf Code</td>
                                      <td>:</td>
                                      <td>{{(dataShopSide?.manufacturerSku)? dataShopSide?.manufacturerSku : '-'}}</td>
                                  </tr>
                                  <tr>
                                      <td>Tag</td>
                                      <td>:</td>
                                      <td *ngIf="dataShopSide?.tags.length>0">
                                          <span class="badge rounded-pill bg-secondary me-1 bgtags c1 badgetag"  *ngFor="let tags of dataShopSide?.tags; let i = index">{{ tags }}
                                          </span>
                                      </td>
                                      <td *ngIf="dataShopSide?.tags.length==0">-</td>
                                  </tr>
  
                              </table>
  
                              </div>
  
                              <div *ngIf="dataShopSide?.isDisplayPriceOnShopfront" class="d-block d-sm-none">
                                <h3>
                                  <strong>{{dataShopSide?.currency}} {{dataShopSide?.price | number}}</strong>
                                </h3>
                                <br>
                              </div>
  
                              <div class="container">
                                <div class="row position-relative">
                                  <div class="col"> 
  
                                    <div>
                                        <table class="mb-2 mb-sm-4">
                                          <tr>
                                              <td>
                                                <img  class="rounded-circle avatar" src="{{(dataShopSide.vendorLogo)?dataShopSide.vendorLogo:'/assets/images/default-company.jpeg'}}" style="width: 40px; height: 40px;">
                                                
                                              </td>
                                              <td>
                                                <!-- <strong class="ms-2 me-2">{{companyName}}</strong> -->

                                               
                                                <span *ngIf="vendorName" class="badge rounded-pill bg-info cpname text-dark limit-text badge-limit pointer">
                                                  <img src="/assets/images/icons/vendor-icon.png" class="vendor-icon pe-1">
                                                  <font class="limit-text">{{ vendorName }}</font>
                                                </span>
                                                <span *ngIf="!vendorName"
                                                  class="badge rounded-pill bg-info ncpname text-dark limit-text badge-limit"
                                                  >
                                                  <img src="/assets/images/icons/vendor-confidential.png" class="vendor-icon pe-1">
                                                  <font class="limit-text">Confidential</font>
                                                </span>

                                                <br>
                                                <div *ngIf="vendorName" class="vendorLoc">
                                                  <small><i class="bi bi-geo-alt-fill" style="color: #1769b5"></i> {{vendorLocation}} &nbsp;&nbsp;
                                                    
                                                    <ng-container *ngIf="isCompanySetPKP">
                                                      |&nbsp;&nbsp; Tax Status:  {{(isPKP)?'PKP':'non-PKP'}}
                                                    </ng-container>
                                                  </small>
                                                </div>


                                              </td> 
                                              <td>
                                              <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                              <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall"> -->
                                              </td>
                                          </tr>
                                        </table>
    
                                        <div class="col-12 mt-3 pt-1 d-none">
                                            <div class="row text-center mb-3">
                                              <div class="col text-center">
                                                  <div class="boxratingtitle">Customer Satisfaction</div>
                                                  <div class="boxrating pt-4 pb-4">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingiconsmall">
                                                      <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                                                  </div>
                                              </div>
                                              <div class="col text-center">
                                                      <div class="boxratingtitle">Product Rating</div>
                                                      <div class="boxrating pt-3 pb-3">
                                                          <h1 class="fw-bold fw-bold mb-1 mt-1 boxratingtext">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid starsmall"></h1>
                                                      </div>
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">Viewed Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">Quoted Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                              <div class="col text-center">
                                                  <!-- <div class="boxratingtitle">On Auction Today</div>
                                                  <div class="boxrating pt-3 pb-3">
                                                      <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                                  </div> -->
                                              </div>
                                            </div>
                                        </div>
                                        
    
                                        <div>
        

                                          <div *ngIf="dataShopSide?.isDisplayPriceOnShopfront">
                                            <div class="d-none d-sm-block">
                                              <h2 *ngIf="dataShopSide.price>0" class="text-end">
                                                <div>
                                                  <strong class="texPrice">{{dataShopSide.currency}} {{dataShopSide.price | number}}</strong>
                                                </div>
                                              </h2>
                                              <div  class="text-end pt-3 pb-4">

                                                <a class="cart-btn mx-2">
                                                  <i class="bi bi-cart"></i>
                                                  Add to cart
                                                </a>

                                                <a class="ask-btn">
                                                  <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Ask Customer
                                                </a>
                                              </div>
            
            
            
                                            </div>
            
            
            
            
                                              <!-- <div *ngIf="resdata?.price>0" class="p-4 d-none d-sm-block" style="border:1px solid #b7b7b7;border-radius: 5px;">
                                                <table>
                                                    <tr>
                                                    <td><strong class="pe-5">Price</strong></td>
                                                    <td *ngIf="isLogin"><strong class="texPrice">{{resdata?.currency}} {{resdata?.price | number}}</strong></td>
                                                    <td *ngIf="!isLogin"><strong class="texPrice"></strong></td>
                                                    </tr>
                                                </table>
                                              </div> -->
              
                                          </div>
                      
                                        </div>
                                    </div>                                    
                                  
                                  </div>
                                </div>
                              </div>
  
  
  
  
  
  
  
  
  
                              <!-- <div class="col-sm-6">
                                
                                <table class="table">
                                  <tr>
                                      <td width="25%">Price</td>
                                      <td width="5%">:</td>
                                      <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{resdata.currency}} {{resdata.price | number}}</strong></h2></td>
                                  </tr>
                                  <tr>
                                      <td>Min. Price</td>
                                      <td>:</td>
                                      <td><h2 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong class="texPrice">{{resdata.currency}} {{resdata.autobidMinimumPrice | number}}</strong></h2></td>
                                  </tr>
                              </table>
                                
                              </div> -->
                            </div>
                          </div>
              
  
                          <div class="col-12 mt-3 p-5 pt-1 d-none">
                            <div class="ps-5 pe-5 row">
                              <div class="col text-center">
                                  <div class="boxratingtitle">Customer Satisfaction</div>
                                  <div class="boxrating pt-4 pb-4">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <!-- <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon">
                                      <img src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png" class="img-fluid ratingicon"> -->
                                  </div>
                              </div>
                              <div class="col text-center">
                                    <div class="boxratingtitle">Product Rating</div>
                                    <div class="boxrating pt-3 pb-3">
                                        <h1 class="fw-bold fw-bold mb-1 mt-1">4.8<img src="/assets/images/icons/rating-star.png" class="img-fluid"></h1>
                                    </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">Viewed Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">5<img src="/assets/images/icons/rating-down.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">Quoted Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                              <div class="col text-center">
                                  <div class="boxratingtitle">On Auction Today</div>
                                  <div class="boxrating pt-3 pb-3">
                                    <h1 class="fw-bold fw-bold mb-1 mt-1">2<img src="/assets/images/icons/rating-up.png" class="img-fluid align-top pt-2"></h1>
                                  </div>
                              </div>
                          </div>
                          </div>
                  
                          <div class="col-sm-12 mb-3 px-3 px-sm-5 pt-1 pb-1">
                  
                  
                          <!-- <div class="pt-2 pb-2 text-white text-uppercase "><strong class="c1">Description</strong></div> -->
                          
  
                          <div class="" style="border:1px solid #18877d;">
                          <div class="desclabel text-white text-uppercase bg1">Description</div>
                          <!-- <div style="white-space: pre-line" [innerHTML]="Valdescription"></div> -->
                          <div class="p-4" style="white-space: pre-line">
                            <div [innerHTML]="divshotdesc(dataShopSide?.description)"></div>
                          </div>
  
                          </div>
  
                          
                          <div class="d-block d-sm-none py-2">
                            <div  class="text-end pt-3 pb-4">

                              <a class="btn btn-dark cart-btn mb-2 py-2 w-100"><img class="carticon" src="/assets/images/icons/icon-cart.png"> Add to cart</a>

                              <a   class="ask-btn ask-btn-full">
                                <img src="/assets/images/icons/qrfq-chat.jpg" class="ask-icon"> Ask Customer
                              </a>
                            </div>
                          </div>

  

                          </div>
  
                          <div class="p-0 px-5 mb-3 mt-1">
                              <!-- <div [innerHtml]="divspec(resdata.fulldescription)"></div> -->
                              <div [innerHtml]="divspec(dataShopSide?.description)"></div>
                          </div>
                  
                      </div>
  
                  </div>
  
                  <div class="col-12 mt-4 mb-4 p-0">
  
                  </div>
  
            </div>
        </div>
    </form>
  </div>    


</div>




<div class="fixed-bottom"  *ngIf="previewShopSide">
  <div class="bg-white pt-3 pb-3">
    <div class="container">
      <div class="row g-0">
        <div class="col-8">
          <!-- <button type="button" class="btn btn-link  rounded-1 roundedmore  mb-2 c1  me-3" ><strong>Market Intelligence</strong></button>
          <a type="button"  class="btn btn-dark fhbdark rounded-1 roundedmore fixwbtn mb-2  me-3" href="/vendor/product/edit/{{EditProductId}}" *ngIf="!pageloading"><i class="bi bi-pencil-square"></i> Edit</a>
          <button  type="button" (click)="openmodaldelete()" class="btn btn-danger fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="!pageloading"> <i class="bi bi-trash"></i> Delete</button>
          <button class="btn btn-primary fhbgred rounded-1 roundedmore fixwbtn mb-2  me-3" *ngIf="pageloading" disabled>Loading..</button> -->
        </div>
        <div class="col-4 text-end">
          <button type="button" class="btn btn-link  rounded-1 roundedmore fixwbtn mb-2 c1 ms-3" (click)="closepage()"><strong>Close</strong></button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import Util from 'src/app/_helpers/util';
import { CompanyService } from 'src/app/_services/company.services';
import { MessagingService } from 'src/app/_services/messaging.services';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-popupfeedback',
  templateUrl: './popupfeedback.component.html',
  styleUrls: ['./popupfeedback.component.scss']
})
export class PopupfeedbackComponent implements OnInit {

  @Input() url?: string;
  
  @Output() confirmedClick = new EventEmitter();

  valUrl:string=''
  valCompany:any='[not login yet]'
  valCompanyId:any
  valRole:string='[not login yet]'
  valEmail:string='[not login yet]'
  valName:string='[not login yet]'
  valMessage:string=''
  valIssue:string=''
  isLogin:boolean=false

  processMsg:boolean=false
  getResponse:any
  ResponseText:string=''

  usercompany: any;
  roleName: string = '';

  isInvalid:boolean=true

  constructor(
    public activeModal: NgbActiveModal,
    private companyService: CompanyService,
    private router: Router,
    private popupmodal : Popupmodal,
    ) {}

  ngOnInit(): void {
    this.valUrl = (this.url)?this.url:''

    var getuserdata = localStorage.getItem("USERDATA")
    if (getuserdata !== null) {
      var userdata = JSON.parse(getuserdata);
      this.valEmail=userdata.email
      this.valName=userdata.fullname
    }


    var getusercompany = localStorage.getItem("USERCOMPANY")
    
    if (getusercompany !== null) {
      this.usercompany = JSON.parse(getusercompany);
      console.log('getusercompany', this.usercompany);

      this.valRole= this.getRole(this.usercompany.roleMembership)
    }

    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.valCompany = localStorage.getItem("COMPANYSELECTNAME")
      this.valCompanyId = localStorage.getItem("COMPANYSELECTID")

      this.isLogin = true
    } 

  }

  getRole(id:any){
    return Util.getRole(id)
  }

  submitMessage(): void{
    this.leadsMessage()
  }

  
 
  leadsMessage(){

    var prepareData:any = 
      {
        'feedbackIssueType': this.valIssue,
        'reportedUrl': this.valUrl,
      }
      
      if(this.valMessage){
        var notesobj = {'notes':this.valMessage}
        Object.assign(prepareData, notesobj);
      }

      if(this.isLogin){
        var companyNameobj = {'companyName':this.valCompany}
        Object.assign(prepareData, companyNameobj);

        var userEmail = {'userEmail':this.valEmail}
        Object.assign(prepareData, userEmail);

        var userPositionobj = {'userPosition':this.valRole}
        Object.assign(prepareData, userPositionobj);
      }

    console.log('prepareData : ',prepareData)

    this.processMsg=true
    this.companyService.getgiveReportAndFeedback(prepareData).subscribe((res:any) => {
      console.log(res)
      this.close()
      this.processMsg=false
      if(res.data?.giveReportAndFeedback){
        this.popupmodal.showSuccess("Success send Report and Feedback",null).subscribe((res:any) => {})
      }
      else if(res.errors){
   
        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = '<div class="alert alert-danger" role="alert">'+msg+'</div>'

        this.getResponse=true
        this.ResponseText=errortext 
        this.popupmodal.showError(this.ResponseText,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
    });

  }



  close() {
    this.confirmedClick.emit(true);
    this.activeModal.close();
  }

  changeIssue(val:any){
    this.valIssue = val
    if(val=='' || this.valMessage==''){
      this.isInvalid=true
    } else {
      this.isInvalid=false
    }
  }
  messageChange(){
    if(this.valIssue=='' || this.valMessage==''){
      this.isInvalid=true
    } else {
      this.isInvalid=false
    }
  }
}


<!-- 8 -->
<div class="text-center pt-5 pb-5" *ngIf="state=='loading'">
    <div class="bggraddiv h-100">
        <div class=" mt-5 row justify-content-center g-0">
            <div class="col-sm-6 pt-5 pb-5 bg-white loader loader--style8">
                <div class="pt-5 mt-5"></div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                  <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                  <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                    <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  </rect>
                </svg>
                <div class="pb-5 mb-5"></div>
            </div>
        </div>

    </div>
    <div class="bggradlayer"></div>

</div>  


<div *ngIf="state=='success'" class="bggraddiv">

    <div class="row justify-content-center g-0">

        <div class="col-auto mb-5">
              
            <div class="card divshadow mt-5 mb-5 border-0 rounded-1">
              <div class="card-line"></div>
              <div class="card-body">

                  <div class="text-center mt-4">
                      <img src="/assets/images/icons/Group 518.png" class="img-fluid">
                  </div>

                  <br>
                  <div class="row justify-content-center mb-3">
                      <div class="col-9 text-center ">
                        <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
                        <p class="text-center mt-0 fw-bold mb-0">Registration Completed</p>
                      </div>
                  </div>

              </div>
              <div class="card-footer border-0 bg-white">
                  <div class="text-center mb-4">
                      <a href='/login' class="text-center f1 btn btn-secondary btn-success btn-block w-50  pt-2 pb-2 mtmSuccessRegistrationBtn">Done</a>
                  </div> 
              </div>
            </div>    
          </div> 


        <!-- <div class="mt-5 mb-5 col-sm-4 divshadow bg-white">
              <div class="text-center mt-3">
                <img class="draftimgicon" src="/assets/images/icons/done-save.png">
              </div>  
              <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
              <p class="text-center mt-0 mb-4 fw-bold">Registration Completed</p>
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 pb-3">
                <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" href='/login'>Done</a>
              </div>
        </div> -->

    </div>
    <div class="bggradlayer"></div>

    <!-- <div class="alert alert-success text-center mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Registration Completed</h4>
        <a href="login" class="btn btn-success mt-2">Login</a>
      </div> -->
</div>

<div *ngIf="state=='alredyverified'" class="bggraddiv">

    <div class="row justify-content-center g-0 mb-3">
        <div class="mt-5 mb-5 col-sm-4 divshadow bg-white border-0 rounded-1">
            <div class="card-line"></div>
            <div class="p-3">
                <div class="text-center py-5">
                    <img src="/assets/images/icons/Group 518.png" class="img-fluid">
                </div>
                  <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
                  <p class="text-center mt-0 mb-4 fw-bold">Successfully verified</p>
                  
                  <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 pb-3">
                    <a class="btn btn-secondary f1 btn-success fw-bold pt-2 pb-2 pe-5 ps-5 mtmSuccessRegistrationBtn" data-bs-dismiss="modal" href='/login'>Done</a>
                  </div>
            </div>

        </div>

    </div>
    <div class="bggradlayer"></div>

    <!-- <div class="alert alert-success text-center mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Registration Completed</h4>
        <a href="login" class="btn btn-success mt-2">Login</a>
      </div> -->
</div>

<div *ngIf="state=='redirect'" class="bggraddiv">


  <div class="container">
      <div class="row justify-content-md-center pt-5 pb-5">



        <div class="text-center pt-5 pb-5" >
          <div class="bggraddiv h-100">
              <div class=" mt-5 row justify-content-center g-0">
                  <div class="col-sm-6 pt-5 pb-5 bg-white loader loader--style8">
                      <div class="pt-5 mt-5"></div>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                          <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                          <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        </rect>
                      </svg>
                      <div class="pb-5 mb-5"></div>
                  </div>
              </div>
      
          </div>
          <div class="bggradlayer"></div>
      
      </div> 
      </div>  
  </div>         
  <div class="bggradlayer"></div>

  
  <!-- <div class="alert alert-danger text-center mt-3 mb-3" role="alert">
      <h4 class="alert-heading">Token Expired</h4>
      <a href="" class="btn btn-danger mt-2">Back</a>
    </div> -->
</div>

<div class="">
<app-footer></app-footer>
</div>
<header id="web-header">
	<div class="main-container">
		<div class="header-left">
			<a href="{{redirectDashboardUrl()}}">
				<img src="/assets/images/logofh-cepetdapet.png" srcset="/assets/images/logofh-cepetdapet.png 1x, /assets/images/logofh-cepetdapet.png 2x" sizes="(max-width: 800px) 230px" alt="Cepetdapet" loading="lazy" width="230" height="60" class="logo">
			</a>
		</div><!-- .header-left -->
		<div class="header-right">

			<div class="child" *ngIf="!isMobile">
				<a href="/">
					<span class="content">
						<span class="text menu-text">{{'menu.home' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a class="menu-select pointer" (click)="openSubMenu()">
					<span class="content">
						<span class="text menu-text">{{'menu.about' | translate}}</span>
					</span>
				</a>
				<ng-container *ngIf="showSubmenu">
					<div class="divSubmenu">
						<div class="submenu">
							<a id="mtmManfaatProcurement" href="#manfaat-procurement" (click)="openSubMenu()">Procurement</a>
						</div>
						<div class="submenu">
							<a id="mtmManfaatVendor" href="#manfaat-vendor" (click)="openSubMenu()">Vendor</a>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a href="/subscription">
					<span class="content">
						<span class="text menu-text">{{'menu.subscription' | translate}}</span>
					</span>
				</a>
			</div>
			<div class="child" *ngIf="!isMobile">
				<a href="/help">
					<span class="content">
						<span class="text menu-text">{{'menu.help' | translate}}</span>
					</span>
				</a>
			</div>

			<div class="child d-none">
				<a href="" class="lang-select">
					<span class="content">
						<span class="text text-uppercase">{{currentlang}}</span>
					</span><!-- .content -->
				</a>
			</div><!-- .child -->
			<div class="child group-buttons">



					<!-- <a href="/login" *ngIf="!isLoggedin" class="btn btn-cta2">{{'menu.login' | translate}}</a>
					<a href="/regis" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost">{{'menu.regis' | translate}}</a> -->
					<div class="n-1-hide n-768-show ">
						<!-- <a href="/" class="btn py-2 ">Beranda</a>
						<a  class="btn py-2 ">Tentang</a>
						<a href="/subscription" class="btn py-2 ">Beranggalan</a>
						<a href="/help" class="btn py-2 ">Bantuan</a> -->





						

						<a href="/login" *ngIf="!isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" class="btn py-2 btn-cta">Register</a>
		
					</div>



					<ng-container *ngIf="isLoggedin" class="d-none">
						<!-- <font class="fhc2 pointer" [hidden]="getcompanyName==''">
						<a href="{{redirectDashboardUrl()}}"><h2 class="mt-2 mb-2"><b>{{limitText(userdata.fullname)}}</b></h2></a>
						</font> -->

						<!-- <a href="/logout" *ngIf="isLoggedin" class="btn btn-cta btn-ghost py-2">Login</a>
						<a href="/regis" *ngIf="isLoggedin" class="btn py-2 btn-cta">Register</a> -->

					</ng-container>
			</div><!-- .child -->
			<div class="child hamburger-container" *ngIf="isMobile">
				<button class="hamburger hamburger--spin" type="button" aria-label="Menu button">
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div><!-- .child -->
		</div><!-- .header-right -->
	</div><!-- .main-container -->
</header>

<div id="mobile-menu-container">
	<div class="main-container">
		<div class="mobile-menu-content">
			<nav class="mobile-nav">
				<ul>
					<li class="nav-home">
						<a href="/">
							<span class="text">{{'menu.home' | translate}}</span>
						</a>
					</li>
					<!-- <li class="nav-about">
						<a href="">
							<span class="text">About Us</span>
						</a>
					</li> -->
					<li class="nav-product has-sub" *ngIf="isMobile">
						<a href="">
							<span class="text">{{'menu.about' | translate}}</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="#manfaat-vendor" id="mtmManfaatVendor">
										<span class="text">Vendor</span>
									</a>
								</li>
								<li>
									<a href="#manfaat-procurement" id="mtmManfaatProcurement">
										<span class="text">Procurement</span>
									</a>
								</li>
							</ul>
						</div>
					</li>
					<!-- <li class="nav-news has-sub">
						<a href="">
							<span class="text">News &amp; Articles</span>
						</a>
						<div class="sub-nav">
							<ul>
								<li>
									<a href="">
										<span class="text">News</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Articles</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">Promo</span>
									</a>
								</li>
								<li>
									<a href="">
										<span class="text">View All</span>
									</a>
								</li>
							</ul>
						</div>
					</li> -->



					<li class="nav-contact">
						<a href="/subscription">
							<span class="text">{{'menu.subscription' | translate}}</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="/help">
							<span class="text">{{'menu.help' | translate}}</span>
						</a>
					</li>
					<!-- 
					<li class="nav-contact">
						<a href="">
							<span class="text">Contact Us</span>
						</a>
					</li>
					<li class="nav-contact">
						<a href="#section-faq">
							<span class="text">FAQ</span>
						</a>
					</li> -->
					<li class="nav-contact buttons n-1-show n-768-hide ">
						<a href="/regis" class="btn py-2 btn-cta mt-3 btnpillMenu text-white">Register</a>
						<a href="/logout" class="btn btn-cta btn-ghost  py-2 btnpillMenu mt-3">Login</a>

	
	
						<!-- <ng-container *ngIf="isLoggedin">
							<font class="fhc2 pointer" [hidden]="getcompanyName==''">
							<a href="{{redirectDashboardUrl()}}"><h2 class="mt-2 mb-2 pt-2 ms-3"><b>{{limitText(userdata.fullname)}}</b></h2></a>
							</font>
						</ng-container> -->

					</li>
					<li class="nav-contact buttons">
						<ng-container *ngIf="isLoggedin">
								<!-- <a href="/logout" class="btn btn-cta btn-ghost mobile-btn">Logout</a> -->

								<!-- <a href="/logout" class="btn py-2 btn-cta text-white btnpillMenu mt-2">Logout</a> -->

						</ng-container>

			

					</li>
				</ul>
			</nav><!-- .mobile-nav -->
		</div><!-- .mobile-menu-content -->
	</div><!-- .main-container -->
	<a href="" class="close-mobile-nav">Close Menu</a>
</div><!-- #mobile-menu-container -->

<div id="search-box" class="floating-box-container">
	<div class="content">
		<div class="box-area">
			<h2 class="ngc-maintitle">What do you want to search?</h2>
			<form class="search-form">
				<input type="text" placeholder="Type here..." id="search-input-text" class="input-text"  [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}" required>
				<button class="submit-btn"  (click)="globalSearch()"><span class="fas fa-search icon"></span></button>
			</form><!-- .search-form -->
			<small  *ngIf="invalidSearch"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
		</div><!-- .box-area -->
	</div><!-- .content -->
	<a href="" class="close-search">Close Search</a>
</div><!-- .floating-box-container -->

<div id="lang-box" class="floating-box-container">
	<div class="content">
		<div class="box-area">
			<h2 class="ngc-maintitle">Change Language</h2>
			<div class="language-list">
				<div class="child">
					<a (click)="switchLang('en')" class="{{currentlang=='en'?'selected':''}} clickLanguage">
						<span class="span-wrapper">
							<span class="icon-wrapper">
								<img src="/assets/homepage/images/flag-en-44px.gif" alt="ENGLISH" class="flag-icon" width="22" height="17">
							</span><!-- .icon-wrapper -->
							<span class="text-wrapper">
								English
								<span *ngIf="currentlang=='en'" class="far fa-check icon"></span>
							</span><!-- .text-wrapper -->
						</span><!-- .span-wrapper -->
					</a>
				</div><!-- .child -->
				<div class="child">
					<a (click)="switchLang('id')" class="{{currentlang=='id'?'selected':''}} clickLanguage">
						<span class="span-wrapper">
							<span class="icon-wrapper">
								<img src="/assets/homepage/images/flag-id-44px.gif" alt="BAHASA INDONESIA" class="flag-icon" width="22" height="17">
							</span><!-- .icon-wrapper -->
							<span class="text-wrapper">
								Bahasa Indonesia
								<span *ngIf="currentlang=='id'" class="far fa-check icon"></span>
							</span><!-- .text-wrapper -->
						</span><!-- .span-wrapper -->
					</a>
				</div><!-- .child -->
			</div><!-- .language-list -->
		</div><!-- .box-area -->
	</div><!-- .content -->
	<a href="" class="close-lang">Close Language Selection</a>
</div><!-- .floating-box-container -->

<div *ngIf="templateId=='0'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div class="position-relative">
			<div class="position-absolute top-50 start-0 d-none d-lg-block" id="boxSearch">
				<div class="container mx-5" id="divboxSearch">
					<div id="borderSearch"></div>
					<div class="text-center  p-3">
						<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
						<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
						<div class="px-2 mb-3">
							<div class="input-group">
								<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
								<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
							</div>
							<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>


						</div>
						<div class="px-2">
							<div class="btn-group w-100" role="group">
								<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
								<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
							</div>
						</div>


					</div>
				</div>
			</div>

			<div class="d-none d-md-block">
				<ngb-carousel  #carouselTemp2 [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/slider1/Desktop/Homepage_Banner1.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Desktop/Homepage_Banner2.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Desktop/Homepage_Banner3.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Desktop/Homepage_Banner4.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
			<div class="d-block d-md-none">
				<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/slider1/Mobile/(M)Homepage_Banner1.1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Mobile/(M)Homepage_Banner2.1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Mobile/(M)Homepage_Banner3.1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/slider1/Mobile/(M)Homepage_Banner4.1.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>



		</div>
		
		<div class="d-block d-lg-none p-2 p-sm-3">
			<div class="container" id="divboxSearchMobile">
				<div id="borderSearch"></div>
				<div class="text-center  p-3">
					<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
					<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
					<div class="px-2 mb-3">
						<div class="input-group">
							<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
							<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
						</div>
						<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
					</div>
					<div class="px-2">
						<div class="btn-group w-100" role="group">
							<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
							<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
						</div>
					</div>


				</div>
			</div>
		</div>

	</section>

	<section class="section py-0 bgTemplate">
		<div class="main-container px-0">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="py-2 bannerCarousel">
						<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">

							<ng-template ngbSlide *ngFor="let product of publicProducts?.publicProductData; let i = index">

								<div class="bannerDiv  mx-2 mx-md-4 row p-1 p-md-2 align-items-center bannerDiv{{ loadSlides(i) }}">
									<div class="row p-1 p-md-2 align-items-center">
										<div class="col-8 col-sm-8">
											<div class="ms-md-9 ps-3 ps-sm-5 ps-lg-3">
												<p class="mb-0 textBannerCompany" *ngIf="product?.vendorName">{{ product?.vendorName }}</p>
												<h4 class="mt-2 mb-0 textBannerProduct">{{ limitTextSlides(product?.productName) }}</h4>
												<div class="py-3">
													<a href="/product/{{(product.companyDomain)?product.companyDomain:'view/'+product.vendorId}}/{{product.productSlugUrl}}" class="btnBanner">Lihat Detail Produk</a>
												</div>
												
											</div>

										</div>
										<div class="col-4  col-sm-4">
											<ng-container *ngFor="let img of product?.imagesUrl; let x = index">
												<!-- <img *ngIf="x==0" src="{{ img }}" class="img-fluid pe-2 pe-md-4"> -->

												<div *ngIf="x==0"  class="mx-auto banner-image-container">
													<img src="{{ img }}" alt="Image">
												</div>


											</ng-container>
											
										</div>
									</div>
								</div>
			
			
							</ng-template>


							<!-- <ng-template ngbSlide>
								<div class="picsum-img-wrapper">
								<a href="https://cepetdapet.com/vendor-detail/PTAsaMaterialIndonesia" target="_blank"><img src="/assets/images/homepage/slider2/BannerProduct_PT Asa Material Indonesia.jpg" class="w-100 h-auto"></a>
								</div>
							</ng-template>
							<ng-template ngbSlide>
								<div class="picsum-img-wrapper">
									<a href="https://cepetdapet.com/vendor-detail/PTDuaNovemberAnugrah" target="_blank"><img src="/assets/images/homepage/slider2/BannerProduct_PT Dua November Anugrah.jpg" class="w-100 h-auto"></a>
								</div>
							</ng-template>
							<ng-template ngbSlide>
								<div class="picsum-img-wrapper">
									<a href="https://cepetdapet.com/vendor-detail/PTGarudaMachinery" target="_blank"><img src="/assets/images/homepage/slider2/BannerProduct_PT Garuda Machinery.jpg" class="w-100 h-auto"></a>
								</div>
							</ng-template> -->
						</ngb-carousel>
					</div>

				</div>
				<div class="col-lg-6">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5>RFQ Terbaru</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer" href="/regis">Tambahkan RFQ anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicRFQLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>

						<div class="col-12" *ngIf="!publicRFQLoading">
							<div class="py-2 scrollspy-rfq">
								<ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
									<app-board [rfq]="rfqboard" [forceMobile]="true"></app-board>
								</ng-container>
							</div>


						</div>
						
					</div>
				</div>
			</div>
		</div>
	</section>


</div>

<ng-container *ngIf="templateId=='1'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div (touchstart)="posIni = $event.changedTouches[0].pageX"
		(touchend)="move($event.changedTouches[0].pageX)">
			<ngb-carousel  #carousel [interval]="6000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus">
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner1.1.jpg" class="d-none d-sm-block w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner1.jpg" class="d-block d-sm-none w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner2.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner2.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner3.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner3.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner4.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner4.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
			</ngb-carousel>		
		</div>
	</section>
</ng-container>
<div *ngIf="templateId=='2'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div class="position-relative">
			<div class="position-absolute top-50 start-0 d-none d-md-block" id="boxSearch">
				<div class="container mx-5" id="divboxSearch">
					<div id="borderSearch"></div>
					<div class="text-center  p-3">
						<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
						<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
						<div class="px-2 mb-3">
							<div class="input-group">
								<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
								<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
							</div>
							<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>


						</div>
						<div class="px-2">
							<div class="btn-group w-100" role="group">
								<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
								<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
							</div>
						</div>


					</div>
				</div>
			</div>

			<div class="d-none d-sm-block">
				<ngb-carousel  #carouselTemp2 [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/Homepage_Banner1.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner2.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner3.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/Homepage_Banner4.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
			<div class="d-block d-sm-none">
				<ngb-carousel  #carouselTemp2M [interval]="6000" [showNavigationArrows]="true">
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
						<img src="/assets/images/homepage/(M)Homepage_Banner1.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner2.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner3.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
					<ng-template ngbSlide>
						<div class="picsum-img-wrapper">
							<img src="/assets/images/homepage/(M)Homepage_Banner4.2.jpg" class="w-100 h-auto">
						</div>
					</ng-template>
				</ngb-carousel>
			</div>



		</div>
		
		<div class="d-block d-md-none p-2 p-sm-3">
			<div class="container" id="divboxSearchMobile">
				<div id="borderSearch"></div>
				<div class="text-center  p-3">
					<h2 class="text-white pt-3 mb-0">Ingin kembangkan bisnis?</h2>
					<p class="text-white">Temukan produk dan peluang bisnis lainnya bersama kami</p>
					<div class="px-2 mb-3">
						<div class="input-group">
							<span class="input-group-text border-0 px-2 iconSearch" id="basic-addon1"><i class="bi bi-search" aria-hidden="true"></i></span>
							<input type="text" class="form-control border-0 rounded-end ps-1" placeholder="Cari apa yang Anda butuhkan" [(ngModel)]="valSearchHero" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearchHero">
						</div>
						<small class="fst-italic" *ngIf="invalidSearchHero"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
					</div>
					<div class="px-2">
						<div class="btn-group w-100" role="group">
							<button type="button" class="btn btn-danger btnSearchL py-2" (click)="globalSearchProduct()">Search Product</button>
							<button type="button" class="btn btn-warning btnSearchR py-2" (click)="globalSearchRFQ()">Search RFQ</button>
						</div>
					</div>


				</div>
			</div>
		</div>

	</section>
</div>
<ng-container *ngIf="templateId=='3'">
	<section class="section has-padding mt-2 mt-sm-3 pb-0">
		<div (touchstart)="posIni = $event.changedTouches[0].pageX"
		(touchend)="move($event.changedTouches[0].pageX)">
			<ngb-carousel  #carousel [interval]="6000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus">
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner1.1.jpg" class="d-none d-sm-block w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner1.jpg" class="d-block d-sm-none w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner2.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner2.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner3.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner3.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
				<ng-template ngbSlide>
					<div class="picsum-img-wrapper">
					<img src="/assets/images/homepage/Homepage_Banner4.1.jpg" class="d-none d-sm-block  w-100 h-auto">
					<img src="/assets/images/homepage/(M)Homepage_Banner4.jpg" class="d-block d-sm-none  w-100 h-auto">
					</div>
				</ng-template>
			</ngb-carousel>		
		</div>


	</section>
	<section class="section py-0 bgTemplate">
		<div class="main-container px-0">
			<div class="row">
				<div class="col-lg-7">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5>Produk Pilihan</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer" href="/regis">Tambahkan produk anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicProductsLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>


						<div class="col-6 col-md-3 p-2" *ngFor="let product of publicProducts?.publicProductData; let i = index">
							<app-product-card 
								isHome="Y"
								[images]="product?.imagesUrl"
								[title]="product?.productName"
								[company]="product?.vendorName"
								[currency]="product.currency?product.currency:'IDR'"
								[price]="product?.productPrice"
								location="{{ product.vendorCity? product.vendorCity+', ':''}}{{ product.vendorCountry}}"
								[isConfidential]="product.vendorName?'Y':'N'"
								url="/product/{{(product.companyDomain)?product.companyDomain:'view/'+product.vendorId}}/{{product.productSlugUrl}}"
							>
							</app-product-card>
						</div>

					</div>
				</div>
				<div class="col-lg-5">
					<div class="row align-items-center divTemplate">
						<div class="col-5">
							<h5>RFQ Terbaru</h5>
						</div>
						<div class="col-7 text-end">
							<a class="pointer" href="/regis">Tambahkan RFQ anda</a>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-12" *ngIf="publicRFQLoading">

							<div class="text-center pt-5 pb-5">
								<div class="loader loader--style8" title="7">
								  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
									<rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
									</rect>
									<rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
									  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
									</rect>
								  </svg>
								</div>
							</div>  

						</div>

						<div class="col-12" *ngIf="!publicRFQLoading">
							<div class="py-2">
								<ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
									<app-board [rfq]="rfqboard" [forceMobile]="true"></app-board>
								</ng-container>
							</div>


						</div>
						
					</div>
				</div>
			</div>
		</div>
	</section>
</ng-container>




<section id="bukan-marketplace" class="section section-marketplace">
	<div class="main-container">
		<h2>Cepetdapet <span style="color: #3d85ff;">Bukanlah</span> Market Place</h2>

		<div class="flow-market d-none">
			<div class="vp-avatar" data-aos="fade-right">
				<div class="vp-avatar_media">
					<img src="/assets/homepage/images/home/Vendor@2x.png" alt="Vendor" loading="lazy" width="160" height="160">
				</div><!-- .vp-avatar_media -->
				<p style="color: #3d85ff;">Vendor</p>
			</div><!-- .vp-avatar -->
			<picture class="flow-image"  data-aos="zoom-in">
				<source media="(max-width: 760px)" srcset="/assets/homepage/images/home/home4.png">
				<source media="(min-width: 761px)" srcset="/assets/homepage/images/home/home4.png">
				<img
					srcset="/assets/homepage/images/home/home4.png, 
						/assets/homepage/images/home/home4.png"
					src="/assets/homepage/images/home//home4.png" 
					width="1000" height="400"
					alt="image caption here">
			</picture>
			<div class="vp-avatar" data-aos="fade-left">
				<div class="vp-avatar_media">
					<img src="/assets/homepage/images/home/Procurement@2x.png" alt="Procurement" loading="lazy" width="160" height="160">
				</div><!-- .vp-avatar_media -->
				<p style="color: #ff7752;">Procurement</p>
			</div><!-- .vp-avatar -->
		</div><!-- .flow-market -->


		<div class="flow-market">

			<picture class="flow-image"  data-aos="zoom-in">
				<source media="(max-width: 760px)" srcset="/assets/homepage/images/home/cepetdapet_flow_mobile.png">
				<source media="(min-width: 761px)" srcset="/assets/homepage/images/home/cepetdapet_flow.png">
				<img
					srcset="/assets/homepage/images/home/cepetdapet_flow.png, 
						/assets/homepage/images/home/cepetdapet_flow.png"
					src="/assets/homepage/images/home/cepetdapet_flow.png" 
					width="1000" height="400"
					alt="image caption here">
			</picture>

	</div>
	</div>
	<div class="divhomeVideo">
		<div class="section-vendor">
			<img src="/assets/homepage/images/element/el-1.png" role="presentation" width="170" class="el-image"> 
		</div>
		</div>
	<div class="divhomeVideo">
		<div class="section-vendor">
			<img src="/assets/homepage/images/element/el-1.png" role="presentation" width="170" class="el-image"> 
		</div>

		
		<p class="ngc-intro">Cepetdapet bukanlah marketplace.<br>
			Kami <strong>tidak</strong> menerima fee dari transaksi anda. Dengan misi, memberikan dan mempertemukan baik vendor dan customer agar mendapatkan harga terbaik!</p>
	</div><!-- .main-container -->
	<img src="/assets/homepage/images/element/el-4.png" role="presentation" width="232" height="206" class="el-image"> 
</section>




<section class="section-hero mt-0 pt-0 mt-sm-3 pt-sm-3 mt-md-3 pt-md-3 mt-lg-5 pt-lg-5 d-none">
	<div class="main-container  pt-5">
		<div class="hero-content">
			<h1 class="mb-4">Cepet Dapet</h1>
			<p>Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor.</p>
			<p>Kami bukan market place dan tidak menarik komisi. </p>
			<div class="buttons d-none">
				<a href="/login" class="btn py-2 btn-cta">Login</a>
				<!-- <a href="" class="btn btn-cta2">Info Lainnya</a> -->
			</div><!-- .buttons -->
		</div><!-- .hero-content -->
		<div class="vp-heros">
			<div class="item">
				<div class="vp-hero vendor" style="background-color: #b9e4f1;">
					<div>
						<h2>Anda Sebagai<br>Vendor</h2>
						<p>Cepetdapet mencarikan customer untuk Anda, tanpa komisi!</p>
					</div>
					<img src="/assets/images/home1v.png" alt="image caption" width="400" height="400">
					<!-- <a id="mtmManfaatVendor" href="#manfaat-vendor" class="btn btnHome">Pelajari Lebih Lanjut</a> -->
				</div><!-- .vp-hero -->
			</div><!-- .item -->
			<div class="item">
				<div class="vp-hero procurement" style="background-color: #ffde8d;">
					<div>
						<h2>Anda Sebagai<br>Procurement</h2>
						<p>Cepetdapet mencarikan vendor yang menyediakan produk yang Anda cari!</p>
					</div>
					<img src="/assets/images/home1p.png" alt="image caption" width="400" height="400">
					<!-- <a id="mtmManfaatProcurement" href="#manfaat-procurement" class="btn btnHome">Pelajari Lebih Lanjut</a> -->
				</div><!-- .vp-hero -->
			</div><!-- .item -->
		</div><!-- .vp-hero -->
	</div><!-- .main-container -->
	<img src="/assets/homepage/images/element/el-1.png" role="presentation" width="162" height="104" class="el-image"> 
</section>

<section class="section-search" style="background-color: #fee1d3;">
	<div class="main-container">
		<h2 class="mt-3 mb-3">Cari produk yang Anda butuhkan:</h2>
		<form class="form-search">
			<div class="input-wrap">
				<input type="text" placeholder="Ketik nama produk di sini" required class="input-text" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}" [disabled]="loadingSearch">
				<button class="btn-submit" aria-label="Search button" (click)="globalSearch()" [disabled]="loadingSearch">
					<span *ngIf="!loadingSearch" class="fas fa-search icon"></span>
					<span *ngIf="loadingSearch" class="fas fa-spinner fa-pulse"></span>
				</button>
				<div class="spinner-border text-secondary" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div><!-- .input-wrap -->
			<small class="fst-italic" *ngIf="invalidSearch"><i class="text-danger">Please type keyword minimum 3 character.</i></small>
		</form>
		


	</div><!-- .main-container -->
</section>

<section id="manfaat-vendor" class="section has-padding section-vendor" style="background-color: #eafaff;">
	<div class="main-container">
		<div class="vendor-procurement vendor">
			<div class="ngc-text mt-5" data-aos="fade-left">
				<h2 class="mt-5">MANFAAT dan Layanan untuk
					<br><span style="color: #3d85ff;">VENDOR :</span></h2>
				<p class="ngc-intro">
					Lebih cepat dapat Customer karena sistem kami menggunakan AI untuk terus menerus mencarikan prospek untuk Anda.
				</p>

				<div class="row">
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-vendor-1-1x.png" srcset="/assets/homepage/images/home/icon-vendor-1-1x.png 1x, /assets/homepage/images/home/icon-vendor-1-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Powered by AI</h3>
								<p>Cepetdapet memakai AI untuk mencocokan produk yang Anda jual terhadap RFQ (Request for Quotation) yang di publikasikan oleh Customer (Procurement).</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-vendor-2-1x.png" srcset="/assets/homepage/images/home/icon-vendor-2-1x.png 1x, /assets/homepage/images/home/icon-vendor-2-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Segudang Fitur Pintar</h3>
								<p>Cepetdapet juga memiliki fitur Autobid, Market Intelligence, RFQ Matching System yang akan memudahkan transaksi Anda.</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-vendor-3-1x.png" srcset="/assets/homepage/images/home/icon-vendor-3-1x.png 1x, /assets/homepage/images/home/icon-vendor-3-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Mempermudah Procurement</h3>
								<p>Dengan Cepetdapet, Procurement lebih mudah dan cepat untuk menerima penawaran dari Vendor seperti Anda.</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/Frame 1906@2x.png" srcset="/assets/homepage/images/home/Frame 1906.png, /assets/homepage/images/home/Frame 1906@2x.png" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Berbasis Cloud</h3>
								<p>Mudah dan cepat digunakan dimanapun agar dapat lebih banyak RFQ</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
				</div>

			</div><!-- .ngc-text -->
			<div class="ngc-media">
				<!-- <img src="/assets/homepage/images/home/vendor-hero-560.png" alt="image caption" loading="lazy" width="560" height="480" data-aos="fade-right"> -->
				<img src="/assets/images/homepage/about/(D)img_homepage_vendor.png" alt="image caption" loading="lazy" class="img-fluid p-md-5 p-2" data-aos="fade-right">
			</div><!-- .ngc-media -->
		</div><!-- .vendor-procurement -->

	</div><!-- .main-container -->
	<img src="/assets/homepage/images/element/el-2.png" role="presentation" width="254" height="100" class="el-image"> 
	<div class="BgBlue"></div>
</section>

<section id="manfaat-procurement" class="section has-padding section-procurement" style="background-color: #fbf0d9;">
	<div class="main-container pe-0">
		<div class="vendor-procurement procurement">
			<div class="ngc-text" data-aos="fade-right">
				<h2 class="mt-5 pt-5">MANFAAT dan Layanan untuk<br><span style="color: #ff7752;">PROCUREMENT :</span></h2>
				<p class="ngc-intro">Cara mudah dan cepat untuk mencari penawaran. Cukup posting RFQ satu kali, dan sistem kami akan mencarikan Vendor untuk Anda. </p>

				<div class="row">
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-procurement-1-1x.png" srcset="/assets/homepage/images/home/icon-procurement-1-1x.png 1x, /assets/homepage/images/home/icon-procurement-1-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Menghemat Waktu Anda</h3>
								<p>Tidak lagi menghubungi Vendor satu persatu, Anda hanya perlu membuat satu RFQ (Request For Quotation) dan banyak Vendor akan mengirimkan Quotation.</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
					<div class="col-12 col-sm-6 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-procurement-2-1x.png" srcset="/assets/homepage/images/home/icon-procurement-2-1x.png 1x, /assets/homepage/images/home/icon-procurement-2-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Algoritma Berbasis AI</h3>
								<p>Algoritma Cepetdapet sudah menggunakan AI untuk mencarikan Vendor yang cocok untuk Anda dan interface kami akan mempermudah dalam membandingkan dan memilih Vendor.</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->						
					</div>
					<div class="col-12 p-2">
						<div class="vp-card" data-aos="fade-up">
							<div class="vp-card_icon">
								<img src="/assets/homepage/images/home/icon-procurement-3-1x.png" srcset="/assets/homepage/images/home/icon-procurement-3-1x.png 1x, /assets/homepage/images/home/icon-procurement-3-2x.png 2x" alt="image caption" loading="lazy" width="80" height="80">
							</div><!-- .vp-card_icon -->
							<div class="vp-card_content">
								<h3>Tanpa Biaya Komisi</h3>
								<p>Cepetdapet bertujuan mempertemukan Anda dengan Vendor, dan mempermudah Anda dalam bertransaksi. Anda bebas bertransaksi di luar sistem, setelah menemukan Vendor yang cocok. Kami tidak menarik komisi atas transaksi Anda. Dengan begitu, Vendor dapat menawarkan harga yang terbaik untuk Anda</p>
							</div><!-- .vp-card_content -->
						</div><!-- .vp-card -->
					</div>
				</div>


			</div><!-- .ngc-text -->
			<div class="ngc-media" data-aos="fade-left">
				<!-- <img src="/assets/homepage/images/home/procurement-hero-560.png" alt="image caption" loading="lazy" width="560" height="480"> -->
				 <img src="/assets/images/homepage/about/(D)img_homepage_proc.png" alt="image caption" loading="lazy" class="img-fluid ps-2 ps-sm-4 py-2 py-sm-4 ">
			</div><!-- .ngc-media -->
		</div><!-- .vendor-procurement -->
	</div><!-- .main-container -->
	<img src="/assets/homepage/images/element/el-3.png" role="presentation" width="210" height="170" class="el-image">
	<div class="BgYellow"></div> 
</section>




<section class="section has-padding" style="background-color: #fff6ef;">
	<div class="main-container">



		<div class="pt-3 pt-sm-5 mt-2 mt-sm-5 mb-0 pb-1 px-sm-5" id="homeVideo">

			<div class="mb-4 mb-sm-1">
				<div class="row">
					<div class="col-sm-1"></div>
					<div class="col-sm-10">
						<h2>Solusi cepet dapet <span class="vVend">Vendor</span> dan <span class="vProc">Procurement</span> </h2>
						<p class="mb-0">Bergabunglah dengan Cepetdapet dan temukan peluang baru dalam proses pengadaan.
							Tonton video kami sekarang dan temukan mengapa Cepetdapet adalah platform pilihan untuk pertemuan yang lebih baik antara Vendor dan Procurement.</p>
					</div>
					<div class="col-sm-1"></div>
				</div>
			</div>



		</div>


	</div>
	<div class="divhomeVideo">
		<div class="section-vendor">
			<img src="/assets/homepage/images/element/el-1.png" role="presentation" width="170" class="el-image"> 
		</div>

		

		<div class="px-sm-5">
			<div class="main-container">
				<div class="p-0 p-sm-5 pt-4 pt-sm-2 ">
					<div class="row">
						<div class="col-sm-1"></div>
						<div class="col-sm-10 p-sm-4">
							<div id="mtmHomeYoutube" class="ratio ratio-16x9 mtmHomeYoutube">
								<iframe id="mtmHomeIframeYoutube" class=" p-3 p-sm-4 border-4 mtmHomeIframeYoutube" style="background: #eafaff;z-index: 2; border-radius: 10px;" src="https://www.youtube.com/embed/4YwalW5yHqQ?si=TFRRnN2w7kiQ0FFI&rel=0" title="CepetDapet YouTube video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
						</div>
						<div class="col-sm-1"></div>
					</div>

				</div>

			</div>
		</div>

		<div class="section-procurement">
			<img src="/assets/homepage/images/element/el-3.png" role="presentation" width="200"  class="el-image">
		</div>		
	</div>

		
	
</section>



<section id="coba-gratis" class="section has-padding d-none" style="background-color: #f1f3ff;">
	<div class="main-container">
		<div class="price-intro">
			<h2>Anda Bisa Mencoba Dulu Dengan <span style="color: #3d85ff;">Gratis</span>!</h2>

			<p class="ngc-intro">Et et optio sunt illo eligendi et aliquid. Omnis id voluptatem at est non quaerat sit ut. Sed voluptatibus omnis aut et fuga est itaque. Dolorem nam ducimus. Magnam consectetur qui distinctio. Consequatur consequatur quo laudantium eum.</p>
		</div><!-- .price-intro -->

		<div class="pricing-area d-none">
			<div class="item" data-aos="zoom-in">
				<div class="pricing-box" data-aos="fade">
					<div class="pricing-box_content">
						<h3>Free</h3>
						<p>For starter who want to try.</p>
						<p class="pricing-price">
							Rp0 <span>/ bulan</span>
						</p>
						<ul class="feature-list">
							<li>Feature 1</li>
							<li>Feature 2</li>
							<li>Feature 3</li>
							<li>Feature 4</li>
							<li>Feature 5</li>
							<li>Feature 6 <span class="badge badge-red">LIMITED</span></li>
							<li class="status-warning">Feature 7 <span class="badge badge-blue">DEMO</span></li>
							<li class="status-warning">Feature 8 <span class="badge badge-orange">5 USERS</span></li>
							<li class="status-not-available">Feature 9</li>
							<li class="status-not-available">Feature 10</li>
						</ul><!-- .feature-list -->
					</div><!-- .pricing-box_content -->
					<a href="" class="btn btn-cta2">Coba Gratis</a>
				</div><!-- .pricing-box -->
			</div><!-- .item -->
			<div class="item featured" data-aos="zoom-out">
				<div class="pricing-box featured">
					<div class="pricing-box_content">
						<h3>Pro <span class="badge badge-blue">MOST LOVED</span></h3>
						<p>The most essentials.</p>
						<p class="pricing-price">
							Rp200.000 <span>/ bulan</span>
						</p>
						<ul class="feature-list">
							<li>Feature 1</li>
							<li>Feature 2</li>
							<li>Feature 3</li>
							<li>Feature 4</li>
							<li>Feature 5</li>
							<li>Feature 6</li>
							<li>Feature 7</li>
							<li>Feature 8</li>
							<li class="status-not-available">Feature 9</li>
							<li class="status-not-available">Feature 10</li>
						</ul><!-- .feature-list -->
					</div><!-- .pricing-box_content -->
					<a href="" class="btn btn-cta">Coba Sekarang</a>
				</div><!-- .pricing-box -->
			</div><!-- .item -->
			<div class="item" data-aos="zoom-in">
				<div class="pricing-box" data-aos="fade">
					<div class="pricing-box_content">
						<h3 style="color: #3d85ff;">Premium</h3>
						<p>Unlimited features.</p>
						<p class="pricing-price">
							Rp500.000 <span>/ bulan</span>
						</p>
						<ul class="feature-list">
							<li>Feature 1 <span class="badge badge-green">UNLIMITED</span></li>
							<li>Feature 2 <span class="badge badge-yellow">EXTRA 1</span></li>
							<li>Feature 3</li>
							<li>Feature 4</li>
							<li>Feature 5</li>
							<li>Feature 6</li>
							<li>Feature 7</li>
							<li>Feature 8</li>
							<li>Feature 9</li>
							<li>Feature 10</li>
						</ul><!-- .feature-list -->
					</div><!-- .pricing-box_content -->
					<a href="" class="btn btn-cta2">Daftar Sekarang</a>
				</div><!-- .pricing-box -->
			</div><!-- .item -->
		</div><!-- .pricing-area -->
	</div><!-- .main-container -->
</section>

<section id="subscription"  class="sectionpricing py-5">
	<div class="main-container pb-3">
		<div class="text-center titlepricing py-5 py-5">
			<h2 class="mt-5 pt-5">Berlangganan Fitur <font style="color:#e09f1f;">Premium</font></h2>
				<h4 class="m-0">Pilih Plan sesuai kebutuhan anda dan dapatkan benefit dari <a href="/subscription"><font style="color:#007cc2;">berlangganan</font></a> fitur premium</h4>
		</div>
		<div class="mb-5 pb-5"></div>
		<div class="d-none py-5">
			<div class="table-responsive divpricing shadow">
				<table class="table align-middle tablepricing">
					<thead>
						<tr class="thpricing">
							<th>
								<h3 class="my-3">Penawaran Fitur</h3>
							</th>
							<th>
								<h5 class="mb-0">Introduction Period *</h5>
								<h3 class="mb-0">Gratis *</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Free Member</h5>
								<h3 class="mb-0">Gratis</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Professional</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Business</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">SME</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Corporate</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Paticipate in RFQ</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> Tanpa Batas</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 55/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 200/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 400/bulan</td>
						</tr>
						<tr>
							<td>Market Intelligent</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 6/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 150/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 400/bulan</td>
						</tr>
						<tr>
							<td>Active Product</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 50/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 100/periode</td>
						</tr>
						<tr>
							<td>RFQ Match Notification</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 100/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 1/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 60/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 200/bulan</td>
						</tr>
						<tr>
							<td>Business Tag</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 15/periode</td>
						</tr>
						<tr>
							<td>Users</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 3/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
						</tr>
						<tr>
							<td>Autobid</td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-check-circle-fill text-success"></i></td>
							<td><i class="bi bi-check-circle-fill text-success"></i></td>
						</tr>
					</tbody>

					<!-- Partisipasi RFQ
					
					
					
					
					
					
	
					<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 100/bulan</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
					<td><i class="bi bi-x-circle-fill text-danger"></i></td> -->
	
	
				</table>
			</div>
		</div>

	</div>
</section>

<section id="section-faq" class="section d-none">
	<div class="main-container">
		<div class="faq-intro">
			<div class="content">
				<h2><span style="color: #3d85ff;">Pertanyaan</span> yang Sering Diajukan</h2>
				<p class="ngc-intro">Jelly beans cheesecake caramels wafer jelly beans chupa chups macaroon bonbon. Ice cream lemon drops tootsie roll.</p>
			</div><!-- .content -->
			<img src="/assets/homepage/images/home/faq-470.jpg" alt="image caption" loading="lazy" width="470" height="400" data-aos="fade">
		</div><!-- .faq-intro -->
		<div class="faq-questions">
			<div class="filter-child no-radius line opened" >
				<h2 class="ngc-title">
					<a href="" class="fc-toggle no-radius">What cake tart ice cream muffin jujubes?</a>
				</h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Marshmallow cake gummies biscuit toffee gingerbread wafer dragée. Jelly beans cheesecake caramels wafer jelly beans chupa chups macaroon bonbon.</p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">Is there cream lemon drops tootsie roll?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
						<ul>
							<li>Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium dui.</li>
							<li>Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Do.</li>
							<li>Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros.</li>
						</ul>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">Why shortbread sugar plum cotton candy?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Candy gummies cupcake gingerbread gummi bears fruitcake. Lollipop halvah chupa chups cheesecake gummies. Macaroon toffee lemon drops jelly macaroon liquorice. </p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">Where cake tiramisu marzipan marzipan?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
						<ul>
							<li>Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium dui.</li>
							<li>Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Do.</li>
							<li>Mauris non tempor quam, et lacinia sapien. Mauris accumsan eros.</li>
						</ul>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">What if marshmallow sugar plum croissant?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">What ice cream cookie biscuit carrot cake?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">Am I carrot cake jujubes candy pudding ice cream?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
			<div class="filter-child no-radius line ">
				<h2 class="ngc-title"><a href="" class="fc-toggle no-radius">Where is marshmallow tootsie roll?</a></h2>
				<div class="fc-content">
					<div class="nuke-wysiwyg">
						<p>Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee, twee 90's PBR Echo Park sartorial try-hard freegan Portland ennui. Selvage jean shorts 90's, Vice American Apparel try-hard food truck Shoreditch fap lomo Wes Anderson. Art party Thundercats High Life Tumblr, sartorial vinyl deep v fixie McSweeney's fap tousled Intelligentsia mumblecore. Deep v twee +1, roof party Thundercats PBR Etsy. Flexitarian Williamsburg tote bag iPhone America.</p>
					</div><!-- .nuke-wysiwyg -->
				</div><!-- .fc-content -->
			</div><!-- .filter-child -->
		</div>
	</div><!-- .main-container -->
</section>

<!-- <a href="//api.whatsapp.com/send?phone=6281234567890" class="floating-whatsapp" aria-label="Chat with us using WhatsApp!">
	<span class="fab fa-whatsapp icon"></span>
</a> -->

<footer id="web-footer">
	<div class="main-container">
		<div class="footer-top">
			<div class="footer-child child-1 d-none">
				<img src="/assets/homepage/images/logo-white-200.png" srcset="/assets/homepage/images/logo-white-200.png 200w, /assets/homepage/images/logo-white-400.png 400w" alt="Cepetdapet" loading="lazy" width="200" height="52" class="footer-logo">
			</div><!-- .footer-child -->
			<div class="footer-child child-2 d-none">
				<h3 class="ngc-title">Hubungi Kami</h3>
				<ul class="contact-info">
					<li>
						<span class="fas fa-map-marker-alt icon"></span>
						<span>Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia</span>
					</li>
					<li>
						<span class="fas fa-phone icon flip-x"></span>
						<a href="tel:0215554433">(021) 555 4433</a>
					</li>
					<li>
						<span class="fas fa-envelope icon"></span>
						<a href="mailto:info@cepetdapet.com">info@cepetdapet.com</a>
					</li>
				</ul><!-- .contact-info -->
			</div><!-- .footer-child -->
			<div class="footer-child child-3 d-none">
				<h3 class="ngc-title">Links</h3>
				<ul class="footer-nav">
					<li>
						<a href="index.html">Homepage</a>
					</li>
					<li>
						<a href="">Syarat & Ketentuan</a>
					</li>
					<li>
						<a href="">Report & Feedback</a>
					</li>
					<li>
						<a href="#section-faq">Pertanyaan Yang Sering Diajukan</a>
					</li>
					<li>
						<a href="/help">Help</a>
					</li>
					<li>
						<a href="">Subscription</a>
					</li>
				</ul><!-- .footer-nav -->
			</div><!-- .footer-child -->

				<div class="row p-4 lh-lg w-100">
					<div class="col-sm-5">
					  <img src="/assets/images/Logo FH with Text - White.png" style="max-width:220px;" class="logofooter">
					  <!-- <p class="addressfooter">
						Perkantoran ABC , Jl. Mawar Melati, Blok A no 1-2 Jakarta Barat - Indonesia<br>
						Phone : (021 555 4433) <br>
						E-mail : info@website.com<br>
					  </p> -->
					</div>
					<div class="col-sm-7">
					  <div class="row g-0">
						<div class="col-sm-6">
						  <!-- <strong>Tautan</strong><br> -->
						  <a class="listfooter" href="/">{{'menu.home'|translate}}</a><br>
						  <a class="listfooter pointer" (click)="sendMessage()">{{'menu.report_feedback'|translate}}</a><br>
						  <a class="listfooter" href="/subscription">{{'menu.subscription'|translate}}</a><br>
						</div>
						<div class="col-sm-6">
						  <!-- <br> -->
						  <a class="listfooter pointer" (click)="openVerticallyCentered(content)">{{'menu.tos'|translate}}</a><br>
						  <a class="listfooter" href="/help">{{'menu.help'|translate}}</a><br>
						  <a class="listfooter" href="/contact-us">{{'menu.contact_us'|translate}}</a><br>
						</div>
					  </div>
					</div>
			
				  </div>


		</div><!-- .footer-top -->
		<div class="footer-bottom">
			<ul class="socmed-nav d-none">
				<li>
					<a href="" target="_blank" class="soc-fb" aria-label="Visit our Facebook"><span class="fab fa-facebook-f icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-linkedin" aria-label="Visit our LinkedIn"><span class="fab fa-linkedin-in icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-ig" aria-label="Visit our Instagram"><span class="fab fa-instagram icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-youtube" aria-label="Visit our Youtube channel"><span class="fab fa-youtube icon"></span></a>
				</li>
				<li>
					<a href="" target="_blank" class="soc-twitter" aria-label="Visit our Twitter"><span class="fab fa-twitter icon"></span></a>
				</li>
			</ul><!-- .socmed-nav -->
			<p class="copyright">Copyright &copy; 2023 Cepetdapet. All Rights Reserved.</p>
		</div><!-- .footer-bottom -->
	</div><!-- .main-container -->
</footer>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title px-3">Term & Conditions</h4>
  </div>
	<div class="modal-body">
		<div class="pt-3 pb-3 pe-4 ps-4 border-1">

				<div  *ngIf="TnCsrc" >
					<pdf-viewer 
					[src]="TnCsrc" 
					[original-size]="false"
					[show-all]="true"
					[fit-to-page]="false"
					[zoom]="1"
					[zoom-scale]="'page-width'"
					[stick-to-page]="false"
					[render-text]="true"
					[external-link-target]="'blank'"
					[autoresize]="true"
					[show-borders]="false"
					style="width: 100%; height: 400px;"
					>
					</pdf-viewer>
				</div>

		</div>
	</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light mb-3 mx-3" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<div *ngIf="templateId!=='0'">
	<!-- <h1 id="templateId">{{templateId |json}}</h1> -->
	<input type="hidden" id="templateId" value="{{templateId}}">
</div>


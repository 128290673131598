import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {
  @Input() assistanceTitle?: string;
  @Input() assistanceText?: string;
  @Input() assistanceShown?: boolean;
  @Input() assistanceType?: string;
  @Input() assistanceStepper?: string;

  arrayStepper:any

  @Output() confirmedClick = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    //console.log('assistanceStepper : ',this.assistanceStepper)
    if(this.assistanceStepper){
      this.arrayStepper = JSON.parse(this.assistanceStepper);
    }

  }

  show(data:any){
    alert('xx')
  }
}

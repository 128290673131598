import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const querycompanyProducts = gql`
query companyProducts($first: Int, $after: String) {
  companyProducts(input: {first: $first, after: $after}) {
        pageInfo{
            endCursor
            hasNextPage
        }
        edges{
            cursor
            node{
                id
                name
                brand
                description
                sku
                manufacturerSku
                price
                imageUrl
                tags{
                    id
                    tag
                }
                isDisplayPriceOnShopfront
                isDisplayProductOnShopfront
                quotationInitialPriceOffer
                autobidMinimumPrice
                autobidPriceDecrement
                isAutobidEnabled
                currency
                state    
                publishApprovalStatus
                publishedOngoingApprovalStatus
                isActivatedByUser
                createdDate
                createdBy
                modifiedDate
                modifiedBy
            }
        }
  }
}
`;



const postDraftApproval = gql`
mutation changeCompanyProductDraftApproval($action:approvalAction!,$productId:ID!){
  changeCompanyProductDraftApproval(input: {
      action: $action
      productId: $productId
  })
}
`;


const getProductDetail = gql`
query companyProduct($productId: ID!){
  companyProduct(input: $productId){
      id
      name
      brand
      description
      sku
      manufacturerSku
      price
      imageUrl
      tags{
          id
          tag
      }
      isDisplayPriceOnShopfront
      isDisplayProductOnShopfront
      quotationInitialPriceOffer
      autobidMinimumPrice
      autobidPriceDecrement
      isAutobidEnabled
      currency
      state
      publishApprovalStatus
      publishedOngoingApprovalStatus
      isActivatedByUser
      createdDate
      createdBy
      modifiedDate
      modifiedBy
  }
}
`;


const postApproval = gql`
mutation changeCompanyProductsApproval($action:approvalAction!,$productsId:[String!]){
  changeCompanyProductsApproval(input: {
      action: $action
      productsId: $productsId
  })
}
`;


const deleteProducts = gql`
mutation deleteCompanyProducts($productsId: [ID!]){
  deleteCompanyProducts(input: {
      productsId: $productsId
  })
}
`;


const postActiveDeactive = gql`
mutation activateDeactivateProducts($action:activeDeactive,$productsId:[String!]){
  activateDeactivateProducts(input: {
      action: $action
      productsId: $productsId
  })
}
`;

const postPublish = gql`
mutation publishProducts($productsId:[String!]){
  publishProducts(input: {
      productsId: $productsId
  })
}
`;


const globalSearchList = gql`
query userGlobalSearchProduct($keyword:String!,$limit:Int!,$offset:Int!){
  userGlobalSearchProduct(input: {
      keyword: $keyword
      limit: $limit
      offset: $offset
  }){
      totalData
      productGlobalDetail {
          vendorName
          productName
          productBrand
          productDescription
          productSlugUrl
          companyDomain
          productPrice
          currency
          imagesUrl
          vendorId
          vendorCountry
          vendorCity
      } 
  }
}
`

const globalSearchDetail = gql`
query companyProductGlobalView($companyDomainIdInput:CompanyDomainIdInput!,$productSlugUrl:String!){
  companyProductGlobalView(input: {
      companyDomainIdInput: $companyDomainIdInput
      productSlugUrl:$productSlugUrl
  }){
    companyProduct{
      id
      name
      brand
      description
      sku
      manufacturerSku
      price
      currency
      imageUrl
      tags{
          tag
      }
      isDisplayPriceOnShopfront
    }
    vendorName
  }
}
`

const searchProduct = gql`
query searchProduct($keyword:String,$first:Int,$after:String){
  searchProduct(input: {
      keyword: $keyword
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              name
              brand
              description
              sku
              manufacturerSku
              price
              imageUrl
              tags{
                  id
                  tag
              }
              isDisplayPriceOnShopfront
              isDisplayProductOnShopfront
              quotationInitialPriceOffer
              autobidMinimumPrice
              autobidPriceDecrement
              isAutobidEnabled

              state    
              publishApprovalStatus
              publishedOngoingApprovalStatus
              isActivatedByUser
              createdDate
              createdBy
              modifiedDate
              modifiedBy
          }
      }
  }
}
`

const searchProductQuotation = gql`
query searchProductQuotation($keyword:String,$first:Int,$after:String){
  searchProductQuotation(input: {
      keyword: $keyword
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              id
              name
              brand
              description
              sku
              manufacturerSku
              price
              imageUrl
              tags{
                  id
                  tag
              }
              isDisplayPriceOnShopfront
              isDisplayProductOnShopfront
              quotationInitialPriceOffer
              autobidMinimumPrice
              autobidPriceDecrement
              isAutobidEnabled
              state    
              publishApprovalStatus
              publishedOngoingApprovalStatus
              isActivatedByUser
              createdDate
              createdBy
              modifiedDate
              modifiedBy
              currency
          }
      }
  }
}
`


const getVendorProductList = gql`
query getVendorProductList($vendorId:ID!,$limit:Int!,$offset:Int!){
  getVendorProductList(input: {
      vendorId: $vendorId
      limit: $limit
      offset: $offset
  }) {
      totalData
      productVendorData {
          productName
          currency                
          price
          imageUrl
          productSlugUrl
      }
  }
}
`

const searchVendorProductList = gql`
query searchVendorProductList($vendorId:ID!,$keyword:String!,$limit:Int!,$offset:Int!){
  searchVendorProductList(input: {
      keyword: $keyword
      vendorId: $vendorId
      limit: $limit
      offset: $offset
  }) {
      totalData
      productVendorData {
          productName
          currency                
          price
          imageUrl
          productSlugUrl
      }
  }
}
`

const getVendorInfo = gql`
query getVendorInfo($companyDomain:String!){
  getVendorInfo(input: {
      companyDomain: $companyDomain
  }) {
          vendorId
          vendorName
          vendorCountry
          vendorCity
          vendorProvince
          vendorDomain
          isPKP
  }
}
`

const addCompanyToFavourite = gql`
mutation addCompanyToFavourite($targetAs: TargetAs!, $targetCompanyId: ID!){
  addCompanyToFavourite(input: {
      targetAs: $targetAs
      targetCompanyId: $targetCompanyId
  }
  )
}
`

const removeCompanyFromFavourite = gql`
mutation removeCompanyFromFavourite($targetAs: TargetAs!, $targetCompanyId: ID!){
  removeCompanyFromFavourite(input: {
      targetAs: $targetAs
      targetCompanyId: $targetCompanyId
  }
  )
}
`

const isVendorAlreadyFavourite = gql`
query isVendorAlreadyFavourite($vendorId: ID!){
  isVendorAlreadyFavourite(input: {
      vendorId: $vendorId
  })
}
`

const isVendorBlackList = gql`
query isVendorBlackList($vendorId: ID!){
  isVendorBlackList(input: {
      vendorId: $vendorId
  })
}
`


const addCompanyToBlacklist = gql`
mutation addCompanyToBlacklist($targetAs: TargetAs!, $targetCompanyId: ID!){
  addCompanyToBlacklist(input: {
      targetAs: $targetAs
      targetCompanyId: $targetCompanyId
  }
  )
}
`

const removeCompanyFromBlacklist = gql`
mutation removeCompanyFromBlacklist($targetAs: TargetAs!, $targetCompanyId: ID!){
  removeCompanyFromBlacklist(input: {
      targetAs: $targetAs
      targetCompanyId: $targetCompanyId
  }
  )
}
`

// const getVendorLogo = gql`
// query getVendorLogo($logo:String!){
//   getVendorLogo(input: $logo)
// }
// `
// const getVendorLogo = gql`
// query getVendorLogo($vendorDomain:String,$vendorId:String){
//   getVendorLogo(input: {
//       vendorDomain: $vendorDomain
//       vendorId: $vendorId
//   })
// }
// `

const getVendorLogobyId = gql`
query getVendorLogo($vendorId:String){
  getVendorLogo(input: {
      vendorId: $vendorId
  })
}
`

const getVendorLogobyDomain = gql`
query getVendorLogo($vendorDomain:String){
  getVendorLogo(input: {
      vendorDomain: $vendorDomain
  })
}
`




@Injectable({ providedIn: 'root' })

export class ProductService{

    productlistObs = new Subject();
    postUpdateproduct = new Subject();
    getproductObs = new Subject();
    postApprovalObs = new Subject();
    postPublishObs = new Subject();
    postActiveDeactiveObs = new Subject();
    postDeleteObs = new Subject();
    globalproductlistObs = new Subject();
    globalproductldetailObs = new Subject();
    searchproductObs = new Subject();
    searchProductQuotationObs = new Subject();
    getVendorProductListObs = new Subject();
    searchVendorProductListObs = new Subject();
    getVendorInfoObs = new Subject();
    addCompanyToFavouriteObs = new Subject();
    removeCompanyFromFavouriteObs = new Subject();
    isVendorAlreadyFavouriteObs = new Subject();
    addCompanyToBlacklistObs = new Subject();
    removeCompanyFromBlacklistObs = new Subject();
    isVendorBlackListObs = new Subject();
    getVendorLogoObs = new Subject();

    constructor(private apollo: Apollo) {}

    productsList(first:any, after:any) {
        this.apollo
        .mutate({
          mutation: querycompanyProducts,
          variables: {first: first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.productlistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.productlistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getproductsList(first:any, after:any) {
        this.productlistObs = new Subject();
        this.productsList(first, after);
        return this.productlistObs.asObservable();
    }

  
    postUpdateProducts(action:any,productId: any) {
      this.apollo
      .mutate({
        mutation: postDraftApproval,
        variables: {action: action, productId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.postUpdateproduct.next(res);
          console.log("succes, postUpdateProducts data:", res);
        },
        (error) => {
          this.postUpdateproduct.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getPostUpdateProduct(action:any,productId: any) {
        this.postUpdateproduct = new Subject();
        this.postUpdateProducts(action, productId);
        return this.postUpdateproduct.asObservable();
    }

    ProductDetail(productId: any) {
      this.apollo
      .mutate({
        mutation: getProductDetail,
        variables: {productId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.getproductObs.next(res);
          console.log("succes, getproductObs data:", res);
        },
        (error) => {
          this.getproductObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getProductDetail(productId: any) {
      this.getproductObs = new Subject();
      this.ProductDetail(productId);
      return this.getproductObs.asObservable();
    }  

    PostApproval(action:any,productId: any) {
      this.apollo
      .mutate({
        mutation: postApproval,
        variables: {action: action, productsId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.postApprovalObs.next(res);
          console.log("succes, PostApproval data:", res);
        },
        (error) => {
          this.postApprovalObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    SubmitPostApproval(action:any,productId: any) {
        this.postApprovalObs = new Subject();
        this.PostApproval(action,productId);
        return this.postApprovalObs.asObservable();
    }

    PostPublish(productId: any) {
      this.apollo
      .mutate({
        mutation: postPublish,
        variables: {productsId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.postPublishObs.next(res);
          console.log("succes, PostApproval data:", res);
        },
        (error) => {
          this.postPublishObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    SubmitPostPublish(productId: any) {
        this.postPublishObs = new Subject();
        this.PostPublish(productId);
        return this.postPublishObs.asObservable();
    }

    PostActiveDeactive(action:any,productId: any) {
      
      var actionSubmit = (action=='ACTIVATE')? `ACTIVATE`:`DEACTIVATE`
      
      action
      this.apollo
      .mutate({
        mutation: postActiveDeactive,
        variables: {action: actionSubmit,productsId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.postActiveDeactiveObs.next(res);
          console.log("succes, PostApproval data:", res);
        },
        (error) => {
          this.postActiveDeactiveObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    SubmitPostActiveDeactive(action:any,productId: any) {
        this.postActiveDeactiveObs = new Subject();
        this.PostActiveDeactive(action,productId);
        return this.postActiveDeactiveObs.asObservable();
    }


    PostDelete(productId: any) {
      this.apollo
      .mutate({
        mutation: deleteProducts,
        variables: {productsId: productId},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.postDeleteObs.next(res);
          console.log("succes, PostApproval data:", res);
        },
        (error) => {
          this.postDeleteObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    SubmitPostDelete(productId: any) {
        this.postDeleteObs = new Subject();
        this.PostDelete(productId);
        return this.postDeleteObs.asObservable();
    }

    globalSearchList(keyword:any, limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: globalSearchList,
          variables: {keyword: keyword, limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.globalproductlistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.globalproductlistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getglobalSearchList(keyword:any, limit:any, offset:any) {
      this.globalproductlistObs = new Subject();
      this.globalSearchList(keyword, limit, offset);
      return this.globalproductlistObs.asObservable();
    }


    globalSearchDetail(companyDomainIdInput:any, productSlugUrl:any) {
        this.apollo
        .mutate({
          mutation: globalSearchDetail,
          variables: {companyDomainIdInput: companyDomainIdInput, productSlugUrl: productSlugUrl},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.globalproductldetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.globalproductldetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getglobalSearchDetail(companyDomainIdInput:any, productSlugUrl:any) {
      this.globalproductldetailObs = new Subject();
      this.globalSearchDetail(companyDomainIdInput, productSlugUrl);
      return this.globalproductldetailObs.asObservable();
    }
    

    searchProduct(keyword:any, first:any, after:any) {
        this.apollo
        .mutate({
          mutation: searchProduct,
          variables: {keyword: keyword, first:first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.searchproductObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.searchproductObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchProduct(keyword:any, first:any, after:any) {
        this.searchproductObs = new Subject();
        this.searchProduct(keyword, first, after);
        return this.searchproductObs.asObservable();
    }

    
    searchProductQuotation(keyword:any, first:any, after:any) {
        this.apollo
        .mutate({
          mutation: searchProductQuotation,
          variables: {keyword: keyword, first:first, after: after},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.searchProductQuotationObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.searchProductQuotationObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchProductQuotation(keyword:any, first:any, after:any) {
        this.searchProductQuotationObs = new Subject();
        this.searchProductQuotation(keyword, first, after);
        return this.searchProductQuotationObs.asObservable();
    }


    VendorProductList(vendorId:any, limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: getVendorProductList,
          variables: {vendorId: vendorId, limit:limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getVendorProductListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getVendorProductListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getVendorProductList(vendorId:any, limit:any, offset:any) {
        this.getVendorProductListObs = new Subject();
        this.VendorProductList(vendorId, limit, offset);
        return this.getVendorProductListObs.asObservable();
    }
    


    searchVendorProductList(vendorId:any, keyword:string, limit:any, offset:any) {
        this.apollo
        .mutate({
          mutation: searchVendorProductList,
          variables: {vendorId: vendorId, keyword:keyword, limit:limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.searchVendorProductListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.searchVendorProductListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchVendorProductList(vendorId:any,keyword:string, limit:any, offset:any) {
        this.searchVendorProductListObs = new Subject();
        this.searchVendorProductList(vendorId, keyword, limit, offset);
        return this.searchVendorProductListObs.asObservable();
    }

    VendorInfo(companyDomain: any) {
      this.apollo
      .mutate({
        mutation: getVendorInfo,
        variables: {companyDomain: companyDomain},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.getVendorInfoObs.next(res);
          console.log("succes, PostApproval data:", res);
        },
        (error) => {
          this.getVendorInfoObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getVendorInfo(companyDomain: any) {
        this.getVendorInfoObs = new Subject();
        this.VendorInfo(companyDomain);
        return this.getVendorInfoObs.asObservable();
    }


    addCompanyToFavourite(targetAs:any, targetCompanyId:any) {
        this.apollo
        .mutate({
          mutation: addCompanyToFavourite,
          variables: {targetAs: targetAs, targetCompanyId: targetCompanyId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.addCompanyToFavouriteObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.addCompanyToFavouriteObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getaddCompanyToFavourite(targetAs:any, targetCompanyId:any) {
      this.addCompanyToFavouriteObs = new Subject();
      this.addCompanyToFavourite(targetAs, targetCompanyId);
      return this.addCompanyToFavouriteObs.asObservable();
    }


    removeCompanyToFavourite(targetAs:any, targetCompanyId:any) {
        this.apollo
        .mutate({
          mutation: removeCompanyFromFavourite,
          variables: {targetAs: targetAs, targetCompanyId: targetCompanyId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.removeCompanyFromFavouriteObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.removeCompanyFromFavouriteObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getremoveCompanyToFavourite(targetAs:any, targetCompanyId:any) {
      this.removeCompanyFromFavouriteObs = new Subject();
      this.removeCompanyToFavourite(targetAs, targetCompanyId);
      return this.removeCompanyFromFavouriteObs.asObservable();
    }

    
    isVendorAlreadyFavourite(vendorId:any) {
        this.apollo
        .mutate({
          mutation: isVendorAlreadyFavourite,
          variables: {vendorId: vendorId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.isVendorAlreadyFavouriteObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.isVendorAlreadyFavouriteObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getisVendorAlreadyFavourite(vendorId:any) {
      this.isVendorAlreadyFavouriteObs = new Subject();
      this.isVendorAlreadyFavourite(vendorId);
      return this.isVendorAlreadyFavouriteObs.asObservable();
    }

    isVendorBlackList(vendorId: any) {
      this.apollo
        .mutate({
          mutation: isVendorBlackList,
          variables: { vendorId: vendorId },
          errorPolicy: 'all',
        })
        .subscribe(
          (res: any) => {
            this.isVendorBlackListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.isVendorBlackListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getisVendorBlackList(vendorId:any) {
      this.isVendorBlackListObs = new Subject();
      this.isVendorBlackList(vendorId);
      return this.isVendorBlackListObs.asObservable();
    }

    addCompanyToBlacklist(targetAs:any, targetCompanyId:any) {
        this.apollo
        .mutate({
          mutation: addCompanyToBlacklist,
          variables: {targetAs: targetAs, targetCompanyId: targetCompanyId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.removeCompanyFromBlacklistObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.removeCompanyFromBlacklistObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getaddCompanyToBlacklist(targetAs:any, targetCompanyId:any) {
      this.removeCompanyFromBlacklistObs = new Subject();
      this.addCompanyToBlacklist(targetAs, targetCompanyId);
      return this.removeCompanyFromBlacklistObs.asObservable();
    }


    removeCompanyFromBlacklist(targetAs:any, targetCompanyId:any) {
        this.apollo
        .mutate({
          mutation: removeCompanyFromBlacklist,
          variables: {targetAs: targetAs, targetCompanyId: targetCompanyId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.removeCompanyFromFavouriteObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.removeCompanyFromFavouriteObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getremoveCompanyFromBlacklist(targetAs:any, targetCompanyId:any) {
      this.removeCompanyFromFavouriteObs = new Subject();
      this.removeCompanyFromBlacklist(targetAs, targetCompanyId);
      return this.removeCompanyFromFavouriteObs.asObservable();
    }

    VendorLogo(companyDomain:string,vendorId:string) {
        var setVariable = {}
        if(vendorId==''){
          setVariable = {
            vendorDomain:companyDomain
          }
        } else {
          setVariable = {
            vendorId:vendorId
          }
        }
        this.apollo
        .mutate({
          mutation: (vendorId=='')?getVendorLogobyDomain:getVendorLogobyId,
          variables: setVariable,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getVendorLogoObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getVendorLogoObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getVendorLogo(companyDomain:string,vendorId:string) {
      this.getVendorLogoObs = new Subject();
      this.VendorLogo(companyDomain,vendorId);
      return this.getVendorLogoObs.asObservable();
    }
    
}
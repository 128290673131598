import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const QueryprocurementGetPpoListNeedRating = gql`
query procurementGetPpoListNeedRating($limit:Int!, $offset:Int!){
    procurementGetPpoListNeedRating(input: {
        limit: $limit
        offset: $offset
    }){
        totalItem
        procurementPpoNeedRatingListData {
            proformaPoId
            proformaPoNumber
            rfqDisplayId
            vendorName
            isRating
            proformaPoDate
            totalRating
            isTransactionOccur
        }
    }
}
`;


const QueryprocurementGetPpoRatingDetail = gql`
query procurementGetPpoRatingDetail($proformaPoId:ID!){
  procurementGetPpoRatingDetail(input: {
      proformaPoId: $proformaPoId
  }){
      proformaPoId
      proformaPoNumber
      rfqDisplayId
      vendorName
      isRating
      proformaPoDate
      totalRating
      isTransactionOccur
  }
}
`;

const QueryloadRatingCompanyQuestion = gql`
query loadRatingCompanyQuestion($questionLang:QuestionLang!){
  loadRatingCompanyQuestion(input: {
      questionLang: $questionLang
  }){
      companyRatingQuestionId
      question
  }
}
`;

const QueryprocurementGetPpoRatingProduct = gql`
query procurementGetPpoRatingProduct($proformaPoId:ID!){
  procurementGetPpoRatingProduct(input: {
      proformaPoId: $proformaPoId
  }){
      proformaPoItemId
      productName
      productBrand
      productDescription
      rating
      review
  }
}
`;

const QueryprocurementGiveRatingToVendor= gql` 
mutation procurementGiveRatingToVendor($ProcurementGiveRatingToVendorInput : ProcurementGiveRatingToVendorInput!){
  procurementGiveRatingToVendor(input:$ProcurementGiveRatingToVendorInput)
}
`;

const QueryprocurementGetPpoRatingVendor= gql` 
query procurementGetPpoRatingVendor($proformaPoId:ID!,$questionLang:QuestionLang!){
  procurementGetPpoRatingVendor(input: {
      proformaPoId: $proformaPoId
      questionLang: $questionLang
  }){
      ppoRatingData {
          rating
          question
      }
      review
      reviewDate
  }
}`;

const QueryprocurementUpdateRatingToVendor= gql` 
mutation procurementUpdateRatingToVendor($proformaPoId: ID!,$updateRatingToVendorDataInput: UpdateRatingToVendorDataInput!){
  procurementUpdateRatingToVendor(input:
    {
      proformaPoId:$proformaPoId
      updateRatingToVendorDataInput:$updateRatingToVendorDataInput
    }
    )
}
`;

const QueryisActiveSentPoExistToRating= gql` 
query isActiveSentPoExistToRating($rfqid: String!){
  isActiveSentPoExistToRating(input: $rfqid)
}
`;


const QueryisReceivePo= gql` 
query isReceivePo($rfqParticipatedId:ID!){
  isReceivePo(input: {
      rfqParticipatedId: $rfqParticipatedId
  })
}
`;







const QueryvendorGetPpoListNeedRating = gql`
query vendorGetPpoListNeedRating($limit:Int!, $offset:Int!){
  vendorGetPpoListNeedRating(input: {
      limit: $limit
      offset: $offset
  }){
      totalItem
      vendorPpoNeedRatingListData {
          proformaPoId
          proformaPoNumber
          rfqDisplayId
          quotationDisplayId
          procurementName
          isRating
          proformaPoDate
          totalRating
          isTransactionOccur
      }
  }
}
`;



const QueryvendorGetPpoRatingDetail = gql`
query vendorGetPpoRatingDetail($proformaPoId:ID!){
  vendorGetPpoRatingDetail(input: {
      proformaPoId: $proformaPoId
  }){
      proformaPoId
      proformaPoNumber
      rfqDisplayId
      quotationDisplayId
      procurementName
      isRating
      proformaPoDate
      totalRating
      isTransactionOccur
  }
}
`;

const QueryvendorGetPpoRatingProcurement= gql` 
query vendorGetPpoRatingProcurement($proformaPoId:ID!,$questionLang:QuestionLang!){
  vendorGetPpoRatingProcurement(input: {
    proformaPoId: $proformaPoId
    questionLang: $questionLang
  }){
      ppoRatingData {
          rating
          question
      }
      review
      reviewDate
  }
}`;


const QueryvendorUpdateRatingToProcurement= gql` 
mutation vendorUpdateRatingToProcurement($proformaPoId: ID!,$updateRatingToProcurementDataInput: UpdateRatingToProcurementDataInput!){
  vendorUpdateRatingToProcurement(input: {
    proformaPoId:$proformaPoId
    updateRatingToProcurementDataInput:$updateRatingToProcurementDataInput
  })
}

`;


const QueryvendorGiveRatingToProcurement= gql` 
mutation vendorGiveRatingToProcurement($VendorGiveRatingToProcurementInput : VendorGiveRatingToProcurementInput!){
  vendorGiveRatingToProcurement(input:$VendorGiveRatingToProcurementInput)
}
`;


@Injectable({ providedIn: 'root' })

export class RatingService{
    ProcurementGetPpoListNeedRatingObs = new Subject();
    ProcurementGetPpoRatingDetailObs = new Subject();
    LoadRatingCompanyQuestionObs = new Subject();
    ProcurementGetPpoRatingProductObs = new Subject();
    procurementGiveRatingToVendorObs = new Subject();
    procurementGetPpoRatingVendorObs = new Subject();
    procurementUpdateRatingToVendorObs = new Subject();
    isActiveSentPoExistToRatingObs = new Subject();

    isReceivePoObs = new Subject();
    vendorGetPpoListNeedRatingObs = new Subject();
    vendorGetPpoRatingDetailObs = new Subject();
    vendorGetPpoRatingProcurementObs = new Subject();
    vendorUpdateRatingToProcurementObs = new Subject();
    vendorGiveRatingToProcurementObs = new Subject();


    constructor(private apollo: Apollo) {}

    ProcurementGetPpoListNeedRating(limit:number, offset:number) {
        this.apollo
        .mutate({
          mutation: QueryprocurementGetPpoListNeedRating,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.ProcurementGetPpoListNeedRatingObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.ProcurementGetPpoListNeedRatingObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getProcurementGetPpoListNeedRating(limit:any, offset:any) {
        this.ProcurementGetPpoListNeedRatingObs = new Subject();
        this.ProcurementGetPpoListNeedRating(limit, offset);
        return this.ProcurementGetPpoListNeedRatingObs.asObservable();
    }

    ProcurementGetPpoRatingDetail(proformaPoId:string) {
        this.apollo
        .mutate({
          mutation: QueryprocurementGetPpoRatingDetail,
          variables: {proformaPoId: proformaPoId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.ProcurementGetPpoRatingDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.ProcurementGetPpoRatingDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getProcurementGetPpoRatingDetail(proformaPoId:string) {
        this.ProcurementGetPpoRatingDetailObs = new Subject();
        this.ProcurementGetPpoRatingDetail(proformaPoId);
        return this.ProcurementGetPpoRatingDetailObs.asObservable();
    }
      
    LoadRatingCompanyQuestion(questionLang:string) {
        this.apollo
        .mutate({
          mutation: QueryloadRatingCompanyQuestion,
          variables: {questionLang: questionLang},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.LoadRatingCompanyQuestionObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.LoadRatingCompanyQuestionObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getLoadRatingCompanyQuestion(questionLang:string) {
        this.LoadRatingCompanyQuestionObs = new Subject();
        this.LoadRatingCompanyQuestion(questionLang);
        return this.LoadRatingCompanyQuestionObs.asObservable();
    }

    procurementGetPpoRatingProduct(proformaPoId:string) {
        this.apollo
        .mutate({
          mutation: QueryprocurementGetPpoRatingProduct,
          variables: {proformaPoId: proformaPoId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.ProcurementGetPpoRatingProductObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.ProcurementGetPpoRatingProductObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getprocurementGetPpoRatingProduct(proformaPoId:string) {
        this.ProcurementGetPpoRatingProductObs = new Subject();
        this.procurementGetPpoRatingProduct(proformaPoId);
        return this.ProcurementGetPpoRatingProductObs.asObservable();
    }
    

    procurementGiveRatingToVendor(data:string) {
        this.apollo
        .mutate({
          mutation: QueryprocurementGiveRatingToVendor,
          variables: {ProcurementGiveRatingToVendorInput:data},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.procurementGiveRatingToVendorObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.procurementGiveRatingToVendorObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getprocurementGiveRatingToVendor(data:any) {
        this.procurementGiveRatingToVendorObs = new Subject();
        this.procurementGiveRatingToVendor(data);
        return this.procurementGiveRatingToVendorObs.asObservable();
    }
      
    procurementGetPpoRatingVendor(proformaPoId:string,questionLang:string) {
        this.apollo
        .mutate({
          mutation: QueryprocurementGetPpoRatingVendor,
          variables: {proformaPoId: proformaPoId,questionLang : questionLang},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.procurementGetPpoRatingVendorObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.procurementGetPpoRatingVendorObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getprocurementGetPpoRatingVendor(proformaPoId:string, questionLang:string) {
        this.procurementGetPpoRatingVendorObs = new Subject();
        this.procurementGetPpoRatingVendor(proformaPoId,questionLang);
        return this.procurementGetPpoRatingVendorObs.asObservable();
    }

    procurementUpdateRatingToVendor(proformaPoId:string,updateRatingToVendorDataInput:any) {
        this.apollo
        .mutate({
          mutation: QueryprocurementUpdateRatingToVendor,
          variables: {
            proformaPoId:proformaPoId,
            updateRatingToVendorDataInput:updateRatingToVendorDataInput
          },
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.procurementUpdateRatingToVendorObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.procurementUpdateRatingToVendorObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getprocurementUpdateRatingToVendor(proformaPoId:string,updateRatingToVendorDataInput:any) {
        this.procurementUpdateRatingToVendorObs = new Subject();
        this.procurementUpdateRatingToVendor(proformaPoId,updateRatingToVendorDataInput);
        return this.procurementUpdateRatingToVendorObs.asObservable();
    }

    isActiveSentPoExistToRating(data:string) {
        this.apollo
        .mutate({
          mutation: QueryisActiveSentPoExistToRating,
          variables: {rfqid:data},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.isActiveSentPoExistToRatingObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.isActiveSentPoExistToRatingObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getisActiveSentPoExistToRating(data:any) {
        this.isActiveSentPoExistToRatingObs = new Subject();
        this.isActiveSentPoExistToRating(data);
        return this.isActiveSentPoExistToRatingObs.asObservable();
    }
    

    isReceivePo(data:string) {
        this.apollo
        .mutate({
          mutation: QueryisReceivePo,
          variables: {rfqParticipatedId:data},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.isReceivePoObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.isReceivePoObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getisReceivePo(data:any) {
        this.isReceivePoObs = new Subject();
        this.isReceivePo(data);
        return this.isReceivePoObs.asObservable();
    }


    VendorGetPpoListNeedRating(limit:number, offset:number) {
        this.apollo
        .mutate({
          mutation: QueryvendorGetPpoListNeedRating,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorGetPpoListNeedRatingObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorGetPpoListNeedRatingObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getVendorGetPpoListNeedRating(limit:any, offset:any) {
        this.vendorGetPpoListNeedRatingObs = new Subject();
        this.VendorGetPpoListNeedRating(limit, offset);
        return this.vendorGetPpoListNeedRatingObs.asObservable();
    }
    
    VendorGetPpoRatingDetail(proformaPoId:string) {
        this.apollo
        .mutate({
          mutation: QueryvendorGetPpoRatingDetail,
          variables: {proformaPoId: proformaPoId},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorGetPpoRatingDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorGetPpoRatingDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getvendorGetPpoRatingDetail(proformaPoId:string) {
        this.vendorGetPpoRatingDetailObs = new Subject();
        this.VendorGetPpoRatingDetail(proformaPoId);
        return this.vendorGetPpoRatingDetailObs.asObservable();
    }
    
    vendorGetPpoRatingProcurement(proformaPoId:string,questionLang:string) {
        this.apollo
        .mutate({
          mutation: QueryvendorGetPpoRatingProcurement,
          variables: {proformaPoId: proformaPoId,questionLang : questionLang},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorGetPpoRatingProcurementObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorGetPpoRatingProcurementObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getvendorGetPpoRatingProcurement(proformaPoId:string, questionLang:string) {
        this.vendorGetPpoRatingProcurementObs = new Subject();
        this.vendorGetPpoRatingProcurement(proformaPoId,questionLang);
        return this.vendorGetPpoRatingProcurementObs.asObservable();
    }

    
    vendorUpdateRatingToProcurement(proformaPoId:string,updateRatingToProcurementDataInput:any) {
        this.apollo
        .mutate({
          mutation: QueryvendorUpdateRatingToProcurement,
          variables: {
            proformaPoId:proformaPoId,
            updateRatingToProcurementDataInput:updateRatingToProcurementDataInput
          },
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorUpdateRatingToProcurementObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorUpdateRatingToProcurementObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getvendorUpdateRatingToProcurement(proformaPoId:string,updateRatingToProcurementDataInput:any) {
        this.vendorUpdateRatingToProcurementObs = new Subject();
        this.vendorUpdateRatingToProcurement(proformaPoId,updateRatingToProcurementDataInput);
        return this.vendorUpdateRatingToProcurementObs.asObservable();
    }

    


    vendorGiveRatingToProcurement(data:string) {
        this.apollo
        .mutate({
          mutation: QueryvendorGiveRatingToProcurement,
          variables: {VendorGiveRatingToProcurementInput:data},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.vendorGiveRatingToProcurementObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.vendorGiveRatingToProcurementObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getvendorGiveRatingToProcurement(data:any) {
        this.vendorGiveRatingToProcurementObs = new Subject();
        this.vendorGiveRatingToProcurement(data);
        return this.vendorGiveRatingToProcurementObs.asObservable();
    }
    
}
<main class="bggraddiv">
    <div class="container"  *ngIf="!successregistration">
        
        <div class="row justify-content-center 
        pt-5 pb-5 mb-2 
        ps-3 pe-3
        ps-sm-0 pe-sm-0
        ps-md-0 pe-md-0
        ps-lg-0 pe-lg-0
        g-0">
            <div class="col-sm-12 col-md-12 col-lg-9 divshadow">
                <div class="row g-0">
                    <div class="col-sm-12 col-md-6 col-lg-6 bglogin d-none d-md-block">

                        <div id="carouselRegister" class="carousel slide h-100  d-flex align-items-center " data-bs-ride="carousel"  data-interval="3000">
                            <div class="carousel-indicators">
                              <button type="button" data-bs-target="#carouselRegister" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselRegister" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselRegister" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img src="/assets/images/login/Register-Img1.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/images/login/Register-Img2.png" class="d-block w-100" alt="...">
                              </div>
                              <div class="carousel-item">
                                <img src="/assets/images/login/Register-Img3.png" class="d-block w-100" alt="...">
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselRegister" data-bs-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselRegister" data-bs-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 bg-white">
                        <div class="w-100 text-center fhgrad">
                            <a href="/"><img class="img-fluid fh-logo-div pt-2 pb-2" src="/assets/images/logofh-cepetdapet.png"></a>
                        </div>
                        <div class="paddinglogin divlogin">
                            <h3 class="f1 pb-0 mb-0 texttitlelogin"><strong>Growth your business!</strong></h3>
                            <p class="f1l pt-0 mt-0 mb-4 textlogin">Register your account</p>

                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                                <div class="form-group mb-3">
                                    <label for="companyname">Company Name</label>
                                    <input type="text" [(ngModel)]="ValCompanyname" formControlName="companyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.companyname?.errors }" />
                                    <div
                                    class="errtxt"
                                    role="alert"
                                    *ngIf="submitted && f?.companyname?.errors"
                                    >
                                    <div *ngIf="f?.companyname?.errors?.required">Company name is required</div>
                                    </div>
                                </div>

                                <div class="form-group mb-3">
                                    <label for="country">Country</label>
                                    <select formControlName="country" [(ngModel)]="ValCountry" class="form-select form-control" [ngClass]="{ 'is-invalid': submitted && f?.country?.errors }">
                                        <option></option>
                                        <option *ngFor="let country of countries" [ngValue]="country.id">{{country.countryName}}</option>
                                    </select>
                                    <div
                                    class="errtxt"
                                    role="alert"
                                    *ngIf="submitted && f?.country?.errors"
                                    >
                                    <div *ngIf="f?.country?.errors?.required">Country is required</div>
                                    </div>
                                </div>



                                <div class="form-group mb-3">
                                    <label for="email">Email <sup><i class="bi bi-info-circle pointer" triggers="click" [autoClose]="'outside'" placement="top auto" ngbTooltip="{{'company_management.register_account.email_info' | translate}}"  container="body"></i></sup></label>
                                    <input type="text" [(ngModel)]="ValEmail" formControlName="email" max="254" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.email?.errors }" />
                                    <div
                                    class="errtxt"
                                    role="alert"
                                    *ngIf="submitted && f?.email?.errors"
                                    >
                                    <div *ngIf="f?.email?.errors?.required">Email is required</div>
                                    <!-- <div *ngIf="f?.email?.errors?.email">Email must be a valid email address</div> -->
                                    <div *ngIf="f?.email?.errors?.pattern">Email must be a valid email address</div>
                                    </div>
                                </div>

                                
                                <div class="mb-3 row">
                                    <div class="col-sm-12">
                                        <div class="form-check">
                                            <input type="checkbox" [(ngModel)]="tos" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f?.acceptTerms?.errors }"  (click)="openModal()" />
                                            <label for="acceptTerms" class="form-check-label"  (click)="openModal()">Agree to Terms and Conditions</label>
                                            <!-- <div *ngIf="submitted && f?.acceptTerms?.errors" class="invalid-feedback">Agree to Term and Condition is required</div> -->
                                        </div>
                                    </div>
                                </div>

    
                                <div class="mb-3 row">

                                    <div class="col-sm-12">
                                        <!-- <re-captcha
                                        (resolved)="resolved($event)"
                                        (error)="onError($event)"
                                        errorMode="handled"
                                        siteKey="6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU"
                                      ></re-captcha> -->
    
                                      <!-- <div *ngIf="cpatchaText=='' ? 'd-none' : '' "> capcha required </div> -->
    
                                           
    
                                        <re-captcha
                                            [(ngModel)]="cpatchaText"
                                            name="captcha"
                                            formControlName="inputcaptcha"
                                            
                                            siteKey="{{config.captchaKey}}"
                                        ></re-captcha> <!--  required -->
    
    
                                        <!-- <div class="invalid-feedback">
                                            <div [hidden]="f?.inputcaptcha?.valid || f?.inputcaptcha?.pristine">Captcha must be solved</div>
                                            <div [hidden]="f?.form?.valid">Captcha is required</div>
                                        </div> -->
    
                                        <!-- <div [hidden]="f?.inputcaptcha?.valid || f?.inputcaptcha?.pristine" class="invalid-feedback">
                                            Captcha must be solved
                                        </div>
                                        <div [hidden]="!f?.inputcaptcha?.valid" class="success">Captcha is valid</div>
                                        <div [hidden]="f?.form?.valid" class="invalid-feedback">
                                            The form must be filled out
                                        </div>
                                        <div [hidden]="!f?.form?.valid" class="success">
                                            The form is valid
                                        </div> -->
    
    
                                        <div *ngIf="submitted">
                                            <div class="errmsg"  [hidden]="f?.inputcaptcha?.valid">
                                                Captcha is required
                                            </div>
                                        </div>   
    
                                    </div>
                                </div>


                                <div class="form-group mt-3">
                                    <button *ngIf="!pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2">
                                        Register
                                    </button>
                                    <button *ngIf="pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2" disabled>
                                    Loading..
                                    </button>
                                    
                                </div>




                                <div class="form-group mt-3 text-center">
                                    <div class="col-12">
                                    
                                    </div>
                                    <div class="col-12 tb">
                                        <p class="c1 rememberme">
                                            <strong>
                                                Already have account?
                                        <a href="login" class="c1 rememberme">Login here</a> </strong></p>    
                                    </div>
                                    <div class="col-12 tb border-0">
                                        <p class="c1 rememberme mb-1">
                                            <strong>
                                                
                                        <a (click)="clickReffcode()" class="c1 rememberme pointer">Input referral code
                                           <small><i class="bi bi-chevron-{{showRefCode?'up':'down'}}"></i></small> 
                                        </a> </strong></p> 
                                        <div *ngIf="showRefCode">
                                            
                                            <input type="text" class="form-control" maxlength="10" placeholder="Input Referral Code" aria-label="Input Referral Code" aria-describedby="button-addon2"  [(ngModel)]="ValReference" [ngModelOptions]="{standalone: true}" [disabled]="isFromUrl">
                                            <input type="hidden" class="form-control" maxlength="10" placeholder="Input Referral Code" aria-label="Input Referral Code" aria-describedby="button-addon2"  [(ngModel)]="ValReference" [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>


                                </div>
                                <!-- <div class="form-group">
                                    <div
                                    class="alert alert-danger mt-1 p-2"
                                    role="alert"
                                    *ngIf="f.submitted && isLoginFailed"
                                    >
                                    {{ errorMessage }}
                                    </div>
                                </div> -->





                            </form> 
                        </div>
                           
                    </div>
                </div>
            </div>
        </div>


        
        <div class="bggradlayer"></div> 
    </div>
    
                      
    <div class="container" *ngIf="successregistration">
        <div class="row justify-content-md-center pt-5 pb-5">

            <div class="col-md-6">
              
              <div class="card divshadow mt-5 mb-5">
                <div class="card-body pb-5">
                    <div class="text-center">
                        <a href="/"><img class="img-fluid fh-logo-div pt-2 pb-2" src="/assets/images/logofh-cepetdapet.png"></a>
                    </div>

                    <br>

                    
                    <div class="text-center">
                        <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 39.875V28.9493C14.5133 27.0452 15.275 25.2229 16.6207 23.8758C17.9664 22.5288 19.788 21.7652 21.692 21.75H152.308C156.281 21.75 159.5 24.9763 159.5 28.9493V145.051C159.487 146.955 158.725 148.777 157.379 150.124C156.034 151.471 154.212 152.235 152.308 152.25H21.692C19.7839 152.248 17.9546 151.489 16.606 150.139C15.2575 148.789 14.5 146.959 14.5 145.051V137.75H145V52.925L87 105.125L14.5 39.875ZM0 72.5H36.25V87H0V72.5ZM0 108.75H58V123.25H0V108.75Z" fill="#174559" fill-opacity="0.5"/>
                            </svg>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-9 text-center ">
                            <h3 class="f1">Email Verification</h3>
                            <h5 class="card-title f1l">We have sended link to activated you account to your email</h5>
                        </div>
                    </div>


                  
                  <p></p>
                  <!-- <h5 class="card-title">Thanks for your registration, please check your inbox!</h5>
                  <p class="card-text">We have sent an activation link to your email. If you did not receive it please check your spam folder. Do contact us when you can’t find the confirmation mail. Please click the link provided in the email to finalise your registration.</p> -->

                  <div class="text-center">
                    <a href="/" class="text-center f1 btn btn-secondary btn-block w-50 bg1 pt-2 pb-2">Back</a>
                  </div>
                  
                </div>
              </div>
            </div>

        </div>
        <div class="bggradlayer"></div> 
    </div>                                 
    
                    
</main>

      <main class="d-none">
      
        <div class="container" *ngIf="!successregistration">
            
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-2">

                </div>
                <div class="col-sm-8">
                    <div class="card mt-5">
                        <div class="card-body">
                            <div class="mb-3 row">
                                <label for="companyname" class="col-sm-2 col-form-label">Company Name</label>
                                <div class="col-sm-10">
                                    <input type="text" [(ngModel)]="ValCompanyname" formControlName="companyname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.companyname?.errors }" />
                                    <div *ngIf="submitted && f?.companyname?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.companyname?.errors?.required">Company name is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="country" class="col-sm-2 col-form-label">Country</label>
                                <div class="col-sm-10">
                                    <select formControlName="country" [(ngModel)]="ValCountry" class="form-select form-control" [ngClass]="{ 'is-invalid': submitted && f?.country?.errors }">
                                        <option *ngFor="let country of countries" [ngValue]="country.id">{{country.countryName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f?.country?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.country?.errors?.required">Country is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="mb-3 row">
                                <label for="email" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="text" [(ngModel)]="ValEmail" formControlName="email" max="254" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.email?.errors }" />
                                    <div *ngIf="submitted && f?.email?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.email?.errors?.required">Email is required</div>
                                        <!-- <div *ngIf="f?.email?.errors?.email">Email must be a valid email address</div> -->
                                        <div *ngIf="f?.email?.errors?.pattern">Email must be a valid email address</div>
                                    </div>     
                                </div>
                            </div>
                            <!-- <div class="mb-3 row">
                                <label for="backupemail" class="col-sm-2 col-form-label">Backup Email</label>
                                <div class="col-sm-10">
                                    <input type="text" [(ngModel)]="ValBackupemail" formControlName="backupemail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.backupemail?.errors }" />
                                    <div *ngIf="submitted && f?.backupemail?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.backupemail?.errors?.required">Backup Email is required</div>
                                        <div *ngIf="f?.backupemail?.errors?.pattern">Backup email must be a valid email address</div>
                                        <div *ngIf="f.backupemail.errors?.MustDifferent">Backup email must different with Email</div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="mb-3 row">
                                <label for="name" class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                    <input type="text" [(ngModel)]="ValName" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                                    <div *ngIf="submitted && f?.name?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.name?.errors?.required">Name is required</div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="mb-3 row">
                                <label for="" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <div class="form-check">
                                        <input type="checkbox" [(ngModel)]="tos" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f?.acceptTerms?.errors }"  (click)="openModal()" />
                                        <label for="acceptTerms" class="form-check-label"  (click)="openModal()">Agree to Terms and Conditions</label>
                                        <!-- <div *ngIf="submitted && f?.acceptTerms?.errors" class="invalid-feedback">Agree to Term and Condition is required</div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 row">
                                <label class="col-sm-2"></label>
                                <div class="col-sm-10">
                                    <!-- <re-captcha
                                    (resolved)="resolved($event)"
                                    (error)="onError($event)"
                                    errorMode="handled"
                                    siteKey="6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU"
                                  ></re-captcha> -->

                                  <!-- <div *ngIf="cpatchaText=='' ? 'd-none' : '' "> capcha required </div> -->

                                       

                                    <re-captcha
                                        [(ngModel)]="cpatchaText"
                                        name="captcha"
                                        formControlName="inputcaptcha"
                                        (resolved)="resolved($event)"
                                        (error)="onError($event)"
                                        siteKey="{{config.captchaKey}}"
                                    ></re-captcha> <!--  required -->


                                    <!-- <div class="invalid-feedback">
                                        <div [hidden]="f?.inputcaptcha?.valid || f?.inputcaptcha?.pristine">Captcha must be solved</div>
                                        <div [hidden]="f?.form?.valid">Captcha is required</div>
                                    </div> -->

                                    <!-- <div [hidden]="f?.inputcaptcha?.valid || f?.inputcaptcha?.pristine" class="invalid-feedback">
                                        Captcha must be solved
                                    </div>
                                    <div [hidden]="!f?.inputcaptcha?.valid" class="success">Captcha is valid</div>
                                    <div [hidden]="f?.form?.valid" class="invalid-feedback">
                                        The form must be filled out
                                    </div>
                                    <div [hidden]="!f?.form?.valid" class="success">
                                        The form is valid
                                    </div> -->


                                    <div *ngIf="submitted">
                                        <div class="errmsg"  [hidden]="f?.inputcaptcha?.valid">
                                            Captcha is required
                                        </div>
                                    </div>   

                                </div>
                            </div>




                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    
                </div>

                <div class="col-sm-12">
                    <div class="mb-3 text-center mt-3">

                        <button *ngIf="!pageloading" class="btn btn-secondary">Register</button>
                        <button *ngIf="pageloading" class="btn btn-secondary" disabled>Loading..</button>

                    </div>
                </div>
            </div>
            </form>  
        </div>

        <div class="container" *ngIf="successregistration">
            <div class="row justify-content-md-center mt-5 mb-5">
                <div class="col col-lg-1">
                </div>
                <div class="col-md-auto">
                  
                  <div class="card">
                    <div class="card-body text-center pb-5">
                      <i class="bi bi-envelope bigicon"></i>
                      <h5 class="card-title">Kami telah mengirimkan link aktivasi ke email anda</h5>
                      <p></p>
                      <!-- <h5 class="card-title">Thanks for your registration, please check your inbox!</h5>
                      <p class="card-text">We have sent an activation link to your email. If you did not receive it please check your spam folder. Do contact us when you can’t find the confirmation mail. Please click the link provided in the email to finalise your registration.</p> -->
                      <a href="/" class="btn btn-outline-secondary">Back</a>
                    </div>
                  </div>
                </div>
                <div class="col col-lg-1">
                </div>
            </div>
        </div>
      
      </main>
      

      <!-- Modal -->
      <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
      <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':display}">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content  rounded-0 border-0">
            <div class="modal-header fhgrad rounded-0  text-center">
              <h5 class="modal-title text-white f1 c1" id="exampleModalLabel">Terms and Conditions</h5>
              <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button> -->
            </div>
            <div class="modal-body">
                <div class="pt-3 pb-3 pe-4 ps-4 border-1">
                    <!-- <object *ngIf="tac" 
                    [data]="getSafeUrl(tac?.description)"
                    type="application/pdf"
                    width="100%"
                    height="400px">
                    </object> -->

                        <div  *ngIf="TnCsrc" >
                            <pdf-viewer 
                            [src]="TnCsrc" 
                            [original-size]="false"
                            [show-all]="true"
                            [fit-to-page]="false"
                            [zoom]="1"
                            [zoom-scale]="'page-width'"
                            [stick-to-page]="false"
                            [render-text]="true"
                            [external-link-target]="'blank'"
                            [autoresize]="true"
                            [show-borders]="false"
                            style="width: 100%; height: 400px;"
                            >
                            </pdf-viewer>
                        </div>

                </div>
                <div class="pt-3 pb-3 pe-4 ps-4 text-end">
                    <button type="button" class="btn c1 f1" data-bs-dismiss="modal" (click)="closeModal('N')">Decline</button>
                    <button type="button" class="btn btn-secondary bg1 f1" data-bs-dismiss="modal" (click)="closeModal('Y')">Agree</button>
                </div>
            </div>
            <!-- <div class="modal-footer">

            </div> -->
          </div>
        </div>
      </div>
  

<app-footer></app-footer>
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { CompanyService } from 'src/app/_services/company.services';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-memberlayout',
  templateUrl: './memberlayout.component.html',
  styleUrls: ['./memberlayout.component.scss']
})
export class MemberlayoutComponent implements OnInit {
  sidebar:boolean=false
  innerWidth: number=0;
  public href: string = "";
  jwtreftoken: any
  constructor(
    private ElByClassName: ElementRef,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
    private popupmodal : Popupmodal,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    console.log('this.innerWidth',this.innerWidth);
    if(this.innerWidth < 992){
      this.hideshowmenu()
    }

   
    this.href = this.router.url;
    //alert('this.href : '+this.href)
    if(this.href=='/tos-applied'){
      // alert('/logout')
      this.jwtreftoken = this.parseJwt(localStorage.getItem("REFRESH_TOKEN"))
      console.log(this.jwtreftoken);
      var loginEventId = this.jwtreftoken['leid']   
      var companyId = localStorage.getItem("COMPANYSELECTID") || ''
      this.companyService.getmutationChooseCompany(companyId,loginEventId).subscribe((res:any) => {
        console.log(res)
    
        if(res.data?.chooseCompany){
          console.log("succes, get data:", res);

          if (res.data.chooseCompany.staff) {

            localStorage.removeItem("USERCOMPANY")
            localStorage.setItem("USERCOMPANY", JSON.stringify(res.data.chooseCompany.staff))
 
            localStorage.setItem("JWT_TOKEN", res.data.chooseCompany.accessToken)
            if(this.router.url=='/dashboard'){
              location.reload() 
            } else {
              this.router.navigate(['/dashboard']);
            }
            return
          } 
        }
        else if(res.errors){
          var error = res.errors[0].message
          console.log('error',error);
          var errMsg = error + '';
          var result = errMsg.split(":");
          var resMsg = result[result.length - 1];
          var msg = (resMsg)? resMsg: errMsg
          console.log('msg',msg);
          var errortext = msg
  
          if(res.errors[0].extensions.type){
            //get translate error, if exsit, replace errortext
            this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
              if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
                errortext = data
              }
            });
            //force redirect on specific errortype
            var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
            if(forceRedirect){
              window.location.href = forceRedirect
              return
            }
          }
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        else {
          this.popupmodal.showFail().subscribe((res:any) => {})
        }
        
      });


    }
  }
  parseJwt(token: string | null) {
    if (token !== null) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

  };

  hideshowmenu(){
    if(!this.sidebar){
      this.sidebar=true
    } else {
      this.sidebar=false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    //console.log('innerWidth',this.innerWidth);

    if(this.innerWidth < 992){
      this.hideshowmenu()
    }
  }

}

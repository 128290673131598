import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {gql, Apollo} from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from 'src/app/_services/navigation';
import Util from 'src/app/_helpers/util';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ProductService } from 'src/app/_services/product.services';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { LightGallery } from 'lightgallery/lightgallery';




const postDraftApproval = gql`
mutation changeCompanyProductDraftApproval($action:approvalAction!,$productId:ID!){
  changeCompanyProductDraftApproval(input: {
      action: $action
      productId: $productId
  })
}
`;


const getCompareProduct = gql`
query companyProductNeedApprovalChanges($productId: ID!){
  companyProductNeedApprovalChanges(input: $productId){
      existingProduct{
          id
          name
          brand
          description
          sku
          manufacturerSku
          price
          imageUrl
          tags{
              id
              tag
          }
          isDisplayPriceOnShopfront
          isDisplayProductOnShopfront
          quotationInitialPriceOffer
          autobidMinimumPrice
          autobidPriceDecrement
          isAutobidEnabled
          state    
          publishApprovalStatus
          publishedOngoingApprovalStatus
          isActivatedByUser
          createdDate
          createdBy
          modifiedDate
          modifiedBy
          currency
      }
      draftProduct{
          id
          draftName
          draftBrand
          draftDescription
          draftSku
          draftManufacturerSku
          draftPrice
          draftImageUrl
          draftTags{
              id
              tag
          }
          draftIsDisplayPriceOnShopfront
          draftIsDisplayProductOnShopfront
          draftQuotationInitialPriceOffer
          draftAutobidMinimumPrice
          draftAutobidPriceDecrement
          draftIsAutobidEnabled
          draftCreatedDate
          draftCreatedBy
          draftModifiedDate
          draftModifiedBy
      }
  }
}
`;



@Component({
  selector: 'app-products-compare',
  templateUrl: './products-compare.component.html',
  styleUrls: ['./products-compare.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsCompareComponent implements OnInit {

  datacompare:any

  pageloading: boolean = false;
  submitted = false;
  tags:any=[]
  SelectedTagsExist:any=[]
  SelectedTagsDraft:any=[]
  imageSrc: string=''
  imageSrcMore:any=[]

  successResponse:boolean=false;
  successResponseName:string=''

  MoreImgCount = new Array(3);
  MoreImgArr:any = []
  CombineImg:any = []

  checkPriceDecCalculate:boolean=false;
  checkDiscIncCalculate:boolean=false;

  

  divFhSpec :any=[] // <fhspecdiv></fhspecdiv>
  countarrFhSpec:any =[]

  valspecLabel=''
  valspecValue=''

  emptyLabelSpec : any = []
  emptyValueSpec : any = []

  ValSpecEmpty:boolean=false

  successdraft:boolean=false
  actionbtn:any='' //draft , preview, done

  StatusApproval:number=1 // 1->OPEN , 2->REQUESTED, 4->APPROVED  
  LoadingreqeustApproval:boolean=false
  StatusComparation:boolean=false 
  CanApproveReject:boolean=false

  LoadingreqeustPublish:boolean=false

  displayApproveReject='none'

  productForm = this.formBuilder.group({
    name: ['', Validators.required],
    brand: [''],
    // description: ['', Validators.required],
    description: [''],
    sku: [''],
    manufacturerSku: [''],
    price: ['', Validators.required],
    // imageFileName: [''],
    isDisplayPriceOnShopfront:  [''],
    isDisplayProductOnShopfront: [''],
    tags: [''],
    quotationInitialPriceOffer: [''],
    autobidMinimumPrice: [''],
    autobidPriceDecrement: [''],
    isAutobidEnabled: [''],
    isActivatedByUser: [''],
    primaryImg: [''],
    tinymce: ['', Validators.required],
  });


  //model
  Valname:string=''
  Valbrand:string=''
  Valdescription:string=''
  Valsku:string=''
  ValmanufacturerSku:string=''
  Valprice:string=''
  ValimageFileName:string=''
  ValisDisplayPriceOnShopfront:boolean=true
  ValisDisplayProductOnShopfront:boolean=false
  Valtags:any=''
  ValquotationInitialPriceOffer:string=''
  ValautobidMinimumPrice:string=''
  ValautobidPriceDecrement:string=''
  ValisAutobidEnabled:boolean=false
  ValisActivatedByUser:boolean=false

  Valstate:string=''

  ValprimaryImg:string=''
  FilenamePirmaryImg:string=''
  ValimageMore:any=[]

  EditProductId:any
  PassData:any
  valspecValueErr: boolean=false;
  valspecLabelErr:boolean=false;
  //EditTags:any


  Valdescriptiondraft:string=''
  divFhSpecdraft :any=[] // <fhspecdiv></fhspecdiv>

  
  existingProductid: any
  existingProductname: any
  existingProductbrand: any
  existingProductdescription: any
  existingProductsku: any
  existingProductmanufacturerSku: any
  existingProductCurrency: any
  existingProductprice: any
  existingProductimageUrl: any
  existingProducttags: any
  existingProductisDisplayPriceOnShopfront: any
  existingProductisDisplayProductOnShopfront: any
  existingProductquotationInitialPriceOffer: any
  existingProductautobidMinimumPrice: any
  existingProductautobidPriceDecrement: any
  existingProductisAutobidEnabled: any
  existingProductstate: any  
  existingProductpublishApprovalStatus: any
  existingProductpublishedOngoingApprovalStatus: any
  existingProductisActivatedByUser: any
  existingProductcreatedDate: any
  existingProductcreatedBy: any
  existingProductmodifiedDate: any
  existingProductmodifiedBy: any


  draftProductid: any
  draftProductdraftName: any
  draftProductdraftBrand: any
  draftProductdraftDescription: any
  draftProductdraftSku: any
  draftProductdraftManufacturerSku: any
  draftProductdraftCurrency: any
  draftProductdraftPrice: any
  draftProductdraftImageUrl: any
  draftProductdraftTags: any
  draftProductdraftIsDisplayPriceOnShopfront: any
  draftProductdraftIsDisplayProductOnShopfront: any
  draftProductdraftQuotationInitialPriceOffer: any
  draftProductdraftAutobidMinimumPrice: any
  draftProductdraftAutobidPriceDecrement: any
  draftProductdraftIsAutobidEnabled: any
  draftProductdraftCreatedDate: any
  draftProductdraftCreatedBy: any
  draftProductdraftModifiedDate: any
  draftProductdraftModifiedBy: any


  publishedOngoingApprovalStatus:boolean=false
  displayRequestApproval:string='none'

  displaydraft='none'
  displaydone='none'
  displaypublish='none'
  displaytimeline='none'

  dataShopSide:any
  valApproveReject:string=''
  isTagsChanges: boolean = false;
  isDescChanges: boolean = false;
  isDescSpecChanges : boolean = false;

  desc1exist:string=''
  desc2exist:any=[]
  desc1draft:string=''
  desc2draft:any=[]

  valcurrency:string=''

  
  lightboxImg:any = [];
  private lightGallery!: LightGallery;
  private needRefresh = false;

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private modalService: NgbModal,
    private productService: ProductService,
    private translate: TranslateService,
    private popupmodal : Popupmodal,
  ) { }


  ngOnInit(): void {
    this.EditProductId = this.route.snapshot.paramMap.get('ProductId');
    console.log('EditProductId',this.EditProductId);
    if(this.EditProductId !==''){
      this.getCompare(this.EditProductId)
    }
    
    //this.getTag()
    
    for (let i = 0; i < this.MoreImgCount.length; i++) {
      this.imageSrcMore.push({'id':i,'val':'','name':''})
    }
    //console.log('imageSrcMore',this.imageSrcMore);


    // var test = this.data.currentMessage.subscribe(message => this.PassData = message)
    // this.data.currentMessage.subscribe(message => {
    //   console.log('xxxxxxxxxx',message)
    // });



    // this.data.currentArr.subscribe(currentArr => {
    //   console.log('xxxx currentArr xxxxxx',currentArr)
    // });

    this.getUserStatus();
  }
  get f() { return this.productForm?.controls; }

  settingsGallery = {
    counter: true,
    download:false,
  };
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


  getUserStatus(){
    var usercompany = localStorage.getItem("USERCOMPANY")
    console.log('usercompany --- ',usercompany);
    //check if owner, show approve or reject

    if(usercompany){
      var arrUserCompany = JSON.parse(usercompany)
      console.log(arrUserCompany.roleMembership)
     //roleMembership
      // if (1,2,3) //1,2,4 can approve / reject
      if(arrUserCompany.roleMembership==1 || arrUserCompany.roleMembership==2 || arrUserCompany.roleMembership==4){
        this.CanApproveReject=true
      }

    }
    


  }

  getCompare(ProductId: any){
    this.apollo.watchQuery<any>({
      query: getCompareProduct,
      variables: {
        productId: ProductId,
      },
    })
    .valueChanges
    .subscribe(({data}) => {

      //if empty, redirect to preview
      if(!data.companyProductNeedApprovalChanges.draftProduct){
        this.router.navigate(['/vendor/product/detail/'+this.EditProductId])
      }

      // this.tags = data.getCompanyTagList;
      console.log('get compare detail ',data);
      this.datacompare= data.companyProductNeedApprovalChanges

      this.valcurrency= data.companyProductNeedApprovalChanges.existingProduct.currency

      if(data.companyProductNeedApprovalChanges.existingProduct.publishedOngoingApprovalStatus=='1'){
        this.publishedOngoingApprovalStatus=true
      } else {
        this.publishedOngoingApprovalStatus=false
      }

      this.StatusApproval = data.companyProductNeedApprovalChanges.existingProduct.publishedOngoingApprovalStatus

      //seperate value
            this.existingProductid=data.companyProductNeedApprovalChanges.existingProduct.id
            this.existingProductname=data.companyProductNeedApprovalChanges.existingProduct.name
            this.existingProductbrand=data.companyProductNeedApprovalChanges.existingProduct.brand
            this.existingProductdescription=data.companyProductNeedApprovalChanges.existingProduct.description
            this.existingProductsku=data.companyProductNeedApprovalChanges.existingProduct.sku
            this.existingProductmanufacturerSku=data.companyProductNeedApprovalChanges.existingProduct.manufacturerSku
            this.existingProductCurrency = data.companyProductNeedApprovalChanges.existingProduct.currency
            this.existingProductprice= (data.companyProductNeedApprovalChanges.existingProduct.price) ? this.transformDecimal(data.companyProductNeedApprovalChanges.existingProduct.price):null
            this.existingProductimageUrl=data.companyProductNeedApprovalChanges.existingProduct.imageUrl
            this.existingProducttags=data.companyProductNeedApprovalChanges.existingProduct.tags
            this.existingProductisDisplayPriceOnShopfront=data.companyProductNeedApprovalChanges.existingProduct.isDisplayPriceOnShopfront
            this.existingProductisDisplayProductOnShopfront=data.companyProductNeedApprovalChanges.existingProduct.isDisplayProductOnShopfront
            this.existingProductquotationInitialPriceOffer=data.companyProductNeedApprovalChanges.existingProduct.quotationInitialPriceOffer
            this.existingProductautobidMinimumPrice= (data.companyProductNeedApprovalChanges.existingProduct.autobidMinimumPrice) ? this.transformDecimal(data.companyProductNeedApprovalChanges.existingProduct.autobidMinimumPrice):null
            this.existingProductautobidPriceDecrement=data.companyProductNeedApprovalChanges.existingProduct.autobidPriceDecrement
            this.existingProductisAutobidEnabled=data.companyProductNeedApprovalChanges.existingProduct.isAutobidEnabled
            this.existingProductstate=data.companyProductNeedApprovalChanges.existingProduct.state  
            this.existingProductpublishApprovalStatus=data.companyProductNeedApprovalChanges.existingProduct.publishApprovalStatus
            this.existingProductpublishedOngoingApprovalStatus=data.companyProductNeedApprovalChanges.existingProduct.publishedOngoingApprovalStatus
            this.existingProductisActivatedByUser=data.companyProductNeedApprovalChanges.existingProduct.isActivatedByUser
            this.existingProductcreatedDate=data.companyProductNeedApprovalChanges.existingProduct.createdDate
            this.existingProductcreatedBy=data.companyProductNeedApprovalChanges.existingProduct.createdBy
            this.existingProductmodifiedDate=data.companyProductNeedApprovalChanges.existingProduct.modifiedDate
            this.existingProductmodifiedBy=data.companyProductNeedApprovalChanges.existingProduct.modifiedBy

            data.companyProductNeedApprovalChanges.existingProduct.tags.forEach((obj: any, key: any) => {
              this.SelectedTagsExist.push(obj.tag)
            });


            this.draftProductid = data.companyProductNeedApprovalChanges.draftProduct?.id
            this.draftProductdraftName = data.companyProductNeedApprovalChanges.draftProduct?.draftName
            this.draftProductdraftBrand = data.companyProductNeedApprovalChanges.draftProduct?.draftBrand
            this.draftProductdraftDescription = data.companyProductNeedApprovalChanges.draftProduct?.draftDescription
            this.draftProductdraftSku = data.companyProductNeedApprovalChanges.draftProduct?.draftSku
            this.draftProductdraftManufacturerSku = data.companyProductNeedApprovalChanges.draftProduct?.draftManufacturerSku
            this.draftProductdraftCurrency = data.companyProductNeedApprovalChanges.draftProduct?.draftCurrency
            this.draftProductdraftPrice = (data.companyProductNeedApprovalChanges.draftProduct?.draftPrice) ? this.transformDecimal(data.companyProductNeedApprovalChanges.draftProduct?.draftPrice):null
            this.draftProductdraftImageUrl = data.companyProductNeedApprovalChanges.draftProduct?.draftImageUrl
            this.draftProductdraftTags = data.companyProductNeedApprovalChanges.draftProduct?.draftTags
            this.draftProductdraftIsDisplayPriceOnShopfront = data.companyProductNeedApprovalChanges.draftProduct?.draftIsDisplayPriceOnShopfront
            this.draftProductdraftIsDisplayProductOnShopfront = data.companyProductNeedApprovalChanges.draftProduct?.draftIsDisplayProductOnShopfront
            this.draftProductdraftQuotationInitialPriceOffer = data.companyProductNeedApprovalChanges.draftProduct?.draftQuotationInitialPriceOffer
            this.draftProductdraftAutobidMinimumPrice = (data.companyProductNeedApprovalChanges.draftProduct?.draftAutobidMinimumPrice) ? this.transformDecimal(data.companyProductNeedApprovalChanges.draftProduct?.draftAutobidMinimumPrice):null
            this.draftProductdraftAutobidPriceDecrement = data.companyProductNeedApprovalChanges.draftProduct?.draftAutobidPriceDecrement
            this.draftProductdraftIsAutobidEnabled = data.companyProductNeedApprovalChanges.draftProduct?.draftIsAutobidEnabled
            this.draftProductdraftCreatedDate = data.companyProductNeedApprovalChanges.draftProduct?.draftCreatedDate
            this.draftProductdraftCreatedBy = data.companyProductNeedApprovalChanges.draftProduct?.draftCreatedBy
            this.draftProductdraftModifiedDate = data.companyProductNeedApprovalChanges.draftProduct?.draftModifiedDate
            this.draftProductdraftModifiedBy = data.companyProductNeedApprovalChanges.draftProduct?.draftModifiedBy
      
            data.companyProductNeedApprovalChanges.draftProduct?.draftTags.forEach((obj: any, key: any) => {
              this.SelectedTagsDraft.push(obj.tag)
            });


            var JsontagExist = JSON.stringify(this.SelectedTagsExist) 
            var JsontagDraft = JSON.stringify(this.SelectedTagsDraft) 
            if(JsontagExist !== JsontagDraft){
              this.isTagsChanges = true
            }

          // seperate desc
          this.Valdescription = data.companyProductNeedApprovalChanges.existingProduct.description

          this.divFhSpec = []
          this.Valdescription = ''
          var desctext = data.companyProductNeedApprovalChanges.existingProduct.description

          var a = data.companyProductNeedApprovalChanges.existingProduct.description;
          var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);

          if(getdetaildesc){
            console.log('>>>>>>>',getdetaildesc[0]);
            console.log('>>>>>>>',getdetaildesc[1]);

            this.divFhSpec = getdetaildesc[1]
            this.divFhSpec = JSON.parse(this.divFhSpec)
            this.Valdescription = desctext.split(getdetaildesc[0]).pop()
            this.Valdescription = desctext.replace(getdetaildesc[0],'')

            // this.divFhSpec.forEach((obj: any, key: any)  => {
            //   this.emptyLabelSpec.push({error:false})
            //   this.emptyValueSpec.push({error:false})
            // });


            console.log('>>>>>>>',desctext);
          }
          else {
            this.Valdescription = data.companyProductNeedApprovalChanges.existingProduct.description
          }



          this.desc1draft=this.divshotdesc(data.companyProductNeedApprovalChanges.draftProduct?.draftDescription)
          this.desc2draft=this.divspec2arr(data.companyProductNeedApprovalChanges.draftProduct?.draftDescription)
          this.desc1exist=this.divshotdesc(data.companyProductNeedApprovalChanges.existingProduct?.description)
          this.desc2exist=this.divspec2arr(data.companyProductNeedApprovalChanges.existingProduct?.description)

          console.log('desc2draft : ',this.desc2draft);
          console.log('desc2exist : ',this.desc2exist);
          // seperate desc draft
          this.Valdescriptiondraft = data.companyProductNeedApprovalChanges.draftProduct?.draftDescription

          this.divFhSpecdraft = []
          //this.Valdescriptiondraft = ''
          var desctextdraft = data.companyProductNeedApprovalChanges.draftProduct?.draftDescription
          
          var b = data.companyProductNeedApprovalChanges.draftProduct?.draftDescription
          var getdetaildescdraft = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(b);
          console.log('000 getdetaildescdraft 00 ',getdetaildescdraft);
          if(getdetaildescdraft){
            console.log('>>>>>>>',getdetaildescdraft[0]);
            console.log('>>>>>>>',getdetaildescdraft[1]);

            this.divFhSpecdraft = getdetaildescdraft[1]
            this.divFhSpecdraft = JSON.parse(this.divFhSpecdraft)
            this.Valdescriptiondraft = desctextdraft.split(getdetaildescdraft[0]).pop()

            console.log('>>>>>>> divFhSpecdraft',this.divFhSpecdraft);
            console.log('>>>>>>> Valdescriptiondraft',this.Valdescriptiondraft);
          }
          else {
            this.Valdescriptiondraft = data.companyProductNeedApprovalChanges.draftProduct?.draftDescription
          }

          var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(this.Valdescription);
          console.log('getdetaildesc >> ',getdetaildesc);
          console.log('Valdescription >> ',this.Valdescription);
          console.log('Valdescriptiondraft >> ',this.Valdescriptiondraft);
          if(this.Valdescriptiondraft){
            if(getdetaildesc){
              var JsondescExist = JSON.stringify(getdetaildesc) 
              var JsondescDraft = JSON.stringify(this.Valdescriptiondraft) 
              console.log('JsondescExist',JsondescExist);
              console.log('JsondescDraft',JsondescDraft);
              if(JsondescExist !== JsondescDraft){
                this.isDescChanges = true
              }
            } else {
              var JsondescExist = JSON.stringify(data.companyProductNeedApprovalChanges.existingProduct.description) 
              var JsondescDraft = JSON.stringify(data.companyProductNeedApprovalChanges.draftProduct?.draftDescription) 
              console.log('JsondescExist 1',JsondescExist);
              console.log('JsondescDraft 1',JsondescDraft);
              if(JsondescExist !== JsondescDraft){
                this.isDescChanges = true
              }
            }
          }



          var ArrImg = (this.draftProductdraftImageUrl)?this.draftProductdraftImageUrl:[];

          var idGallery = 0
          ArrImg.forEach((v:string,k:number) => {
            var src = v;
            var thumb = v;

            this.itemsGallery = [
              ...this.itemsGallery,
              {
                id: idGallery,
                src: src,
                thumb: thumb,
              }
            ];
            this.needRefresh = true;
            idGallery++

          });
    })
  }

  openModalApproveReject(val:any){
    this.valApproveReject = val
    if(this.displayApproveReject=="none"){
      this.displayApproveReject = "block";
    } else {
      this.displayApproveReject = "none";
    }
  }
  
  openModalRequestApproval(){
    if(this.displayRequestApproval=="none"){
      this.displayRequestApproval = "block";
    } else {
      this.displayRequestApproval = "none";
    }
  }


  submitaction(val:any){
    this.actionbtn = ''
    this.actionbtn = val
    //this.onSubmit()
    console.log('val',val);

  }
  closesuccessdraft(){
    this.successdraft = false
  }


  testmodal(){
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'md'
    })
    modalRef.componentInstance.modalconfirm = 'modal'
    modalRef.componentInstance.title = 'ini title'
    modalRef.componentInstance.subtitle = 'Are you sure want to delete <b>"xxx"</b> Product ?'
    modalRef.componentInstance.yesStr = 'Delete'
    modalRef.componentInstance.noStr = 'Cancel'

    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res) this.actionUnsubscribe()
    })
  }

  actionUnsubscribe(){

    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'sm'
    })

    modalRef.componentInstance.modalinfo = 'info'
    modalRef.componentInstance.infoTitle = 'Something Error' // 'Success!' 
    modalRef.componentInstance.infoSuccess = null
    modalRef.componentInstance.infoFailed = 'Please reload the page again.'
    modalRef.componentInstance.closeTxt = 'Tutup'

  }
  

  // getTag(){
  //   this.apollo.watchQuery<any>({
  //     query: getTags
  //   })
  //   .valueChanges
  //   .subscribe(({data}) => {
  //     this.tags = data.getCompanyTagList;
  //   })
  // }





  comparepage(newElem:any,oldElem:any){
    // console.log('comparepage');
    // console.log('newElem',newElem);
    // console.log('oldElem',oldElem);
    if(newElem===null){
      newElem=''
    } 
    if(oldElem===null){
      oldElem=''
    }
    var text = ''
    if((oldElem) && (newElem)){
      var oldText = oldElem.toString(),newElem = newElem.toString();
      if(newElem && oldElem && (oldElem !==newElem)){
        newElem.split('').forEach(function(val:any, i:any){
          if (val != oldText.charAt(i))
            text += "<span class='highlight'>"+val+"</span>";  
          else
            text += val;            
        });
      } else {
        text = oldElem
      }
    } else {
      text =  "<span class='highlight'>"+newElem+"</span>";  
    }

    if(!newElem){
      text = oldElem
    }

      //newElem.html(text); 

    return text
  }


  comparepagespec(textexist:any,textdraft:any){
    var combineres = ''
    combineres +='<ul>';
    console.log('textexist :: ',textexist)
    console.log('textdraft :: ',textdraft)
    //if spec draft more than exist
    if(textexist.length>textdraft.length){
      console.log('iff ')
      if(textexist){
        textexist.forEach((obj: any, key: any) => {
          if(textdraft){
            if(textdraft[key]){
              combineres += '<li>'
              combineres += this.comparepage(textdraft[key].label,textexist[key].label)
              combineres += ' : '
              combineres += this.comparepage(textdraft[key].value,textexist[key].value)
              combineres += '</li>'
            } else {
              combineres += '<li>'
              combineres += this.comparepage('',textexist[key].label)
              combineres += ' : '
              combineres += this.comparepage('',textexist[key].value)
              combineres += '</li>'
            }
          }
        });
      }
    }
    //if spec exist more than draft
    else{
      console.log('else ')
      if(textdraft){
        textdraft.forEach((obj: any, key: any) => {

          if(textexist){
            if(textexist[key]){
              combineres += '<li>'
              combineres += this.comparepage(textdraft[key].label,textexist[key].label)
              combineres += ' : '
              combineres += this.comparepage(textdraft[key].value,textexist[key].value)
              combineres += '</li>'
            } else {
              combineres += '<li>'
              combineres += this.comparepage(textdraft[key].label,'')
              combineres += ' : '
              combineres += this.comparepage(textdraft[key].value,'')
              combineres += '</li>'
            }
          }
        });
      }
    }

    console.log('combineres :: ',combineres)

    combineres +='</ul>';
    return combineres


      var test = this.comparepage(JSON.stringify(textdraft),JSON.stringify(textexist))
    return test
    var combineres = ''

    var result:any = null
    if(textexist.match(/<li>(.*?)<\/li>/g)){
      result = textexist.match(/<li>(.*?)<\/li>/g).map(function(val:any){
        return val.replace(/<\/?li>/g,'');
      });
    }
    console.log('result :: ',result)

    var resultdraft:any = null
    if(textdraft.match(/<li>(.*?)<\/li>/g)){
      resultdraft = textdraft.match(/<li>(.*?)<\/li>/g).map(function(val:any){
        return val.replace(/<\/?li>/g,'');
      });
    }
    console.log('resultdraft :: ',resultdraft)

    combineres +='<ul>';
    // if(result){
    //   result.forEach((obj: any, key: any) => {
    //     if(resultdraft){
    //       if(resultdraft[key]){
    //         combineres += '<li>'+this.comparepage(obj,resultdraft[key])+'</li>'
    //       }
    //     }
    //   });
    // }

    //if spec draft more than exist
    if(resultdraft.length>result.length){
      if(resultdraft){
        resultdraft.forEach((obj: any, key: any) => {
          if(result){
            if(result[key]){
              combineres += '<li>'+this.comparepage(obj,result[key])+'</li>'
            }
          }
        });
      }
    }
    //if spec exist more than draft
    else{
      if(result){
        result.forEach((obj: any, key: any) => {
          if(resultdraft){
            if(resultdraft[key]){
              combineres += '<li>'+this.comparepage(obj,resultdraft[key])+'</li>'
            }
          }
        });
      }
    }



    combineres +='</ul>';
    return combineres
    
    // console.log('comparepage');
    // console.log('newElem',newElem);
    // console.log('oldElem',oldElem);

    // var text = ''
    // if((oldElem) && (newElem)){
    //   var oldText = oldElem.toString(),newElem = newElem.toString();
    //   if(newElem && oldElem && (oldElem !==newElem)){
    //     newElem.split('').forEach(function(val:any, i:any){
    //       if (val != oldText.charAt(i))
    //         text += "<span class='highlight'>"+val+"</span>";  
    //       else
    //         text += val;            
    //     });
    //   } else {
    //     text = oldElem
    //   }
    //}

    // if(!newElem){
    //   text = oldElem
    // }

    //   //newElem.html(text); 

    // return text
  }

  // reqeustDraftApproval(id:any,action:any){
  //   console.log('id',id);

  //   this.LoadingreqeustApproval=true

  //   this.apollo
  //   .mutate({
  //     mutation: postDraftApproval,
  //     variables: {action:action,productId: id}
  //   })
  //   .subscribe(
  //     (res:any) => {

  //       console.log("succes request approval, get data:", res);

  //       this.router.navigate(['/vendor/product/detail/'+this.EditProductId]);
        
  //     },
  //     error => {
  //       alert(error)
  //       console.log("there was an error sending the approval query", error);   
  //       alert('Error while approval product. Please refresh and try again.')     
  //       this.openModalApproveReject('')  
  //     }
  //   );


  // }

  back(): void {
    this.navigation.back()
  }

  divspec(val:any){
    return Util.formatSpecDesc(val)
  }
  divspec2arr(val:any){
    return Util.fhspectoarray(val)
  }


  
  divshotdesc(val:any){
    return Util.formatShortDesc(val)
  }
  
  closepage(){
    window.close();
  }

  OpenModalTimeLine(){
    if(this.displaytimeline=="none"){
      this.displaytimeline = "block";
    } else {
      this.displaytimeline = "none";
    }
    console.log('displaytimeline',this.displaytimeline);
  }

  reqeustApproval(id:any,action:any){
    console.log('id',id);
    console.log('action',action);

    this.LoadingreqeustApproval=true

    // //call API
    // this.apollo
    // .mutate({
    //   mutation: postDraftApproval,
    //   variables: {action:action,productId: id}
    // })
    // .subscribe(
    //   (res:any) => {
    //     this.LoadingreqeustApproval=false
    //     this.updatetimeline(action)
    //   },
    //   error => {
    //     this.LoadingreqeustApproval=false

    //   }
    // );


    this.productService
    .getPostUpdateProduct(action,id)
    .subscribe((res:any) => {


      if(res.data.changeCompanyProductDraftApproval){
        console.log("succes, get data:", res);
        this.updatetimeline(action)


        this.LoadingreqeustApproval=false
        this.displayApproveReject = "none"
      }
      else if(res.errors){

        var error = res.errors[0].message
        //this.loading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        //alert(msg)

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);

        this.LoadingreqeustApproval=false
        this.displayApproveReject = "none";

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  updatetimeline(action:any){
    

    if(action=='APPROVE'){
      this.StatusApproval=4
      console.log('appr reject',this.StatusApproval)

      // auto published
      this.Valstate="PUBLISHED";

      const modalRef = this.modalService.open(ModalComponent, {
        modalDialogClass: 'modal-dialog-centered',
        centered: true,
        backdrop : 'static',
        keyboard : false,
        size: 'sm'
      })
  

      modalRef.componentInstance.modalinfo = 'info'
      modalRef.componentInstance.infoTitle = 'Success!' // 'Success!' 
      modalRef.componentInstance.infoSuccess = 'Product successfully updated'
      modalRef.componentInstance.infoFailed = null
      modalRef.componentInstance.closeTxt = 'Close'

      modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
        if (res) this.router.navigate(['/vendor/product/detail/'+this.EditProductId])
      })
      
    }
    else if(action=='REJECT'){
      this.StatusApproval=1
    }
    else {
      console.log('request')
      this.StatusApproval=2
      console.log('request',this.StatusApproval)
    }

  }

  openmodaldelete(){
    alert('copy lg dari product preview compenonet');
  }
  openModalPublished(){
    if(this.displaypublish=="none"){
      this.displaypublish = "block";
    } else {
      this.displaypublish = "none";
    }
  }

  transformDecimal(num:any) {
    // return this._decimalPipe.transform(num, '1.2-2');
    return formatNumber(Number(num), 'en-US', '1.0-0')
  }

}

<section id="subscription"  class="sectionpricing py-5">
	<div class="main-container pb-3">
		<div class="text-center titlepricing p-5">
			<h2 class="">CepetDapet <font style="color:#007cc2;">Premium</font></h2>
				<h4 class="m-0">Jika Cepetdapet sudah bermanfaat untuk Anda dan Anda ingin menggunakan lebih sering, maka kami menyediakan paket langganan dibawah ini.
          Sebelum kami menyediakan manfaat yang cukup, Anda selalu dapat menggunakan paket tidak berbayar.</h4>
		</div>
    <div class="">
      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                Introduction Period
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ Tanpa Batas</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 2/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">10 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 100/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 5</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 2</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-2-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">Free</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3" href="/regis">Register</a>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                Free member
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ 5/bulan</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 2/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">10 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 1/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 5</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 2</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-2-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">Free</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3 disabled">Coming Soon</a>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                Professional
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ 20/bulan</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 2/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">10 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 5/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 5</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 2</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-2-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">IDR 399,000</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3 disabled">Coming Soon</a>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                Business
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ 55/bulan</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 6/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">20 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 20/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 5</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 2</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-2-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">IDR 1,000,000</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3 disabled">Coming Soon</a>
              </div>

            </div>

          </div>
        </div>

      </div>



      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                SME
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ 200/bulan</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 150/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">50 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 60/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 10</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 3</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">IDR 3,500,000</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3 disabled">Coming Soon</a>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="Product-PricePlan-Board mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-flex align-items-center justify-content-center h-100">
              <span class="Introduction-Period py-3">
                Corporate
              </span>
            </div>
          </div>
          <div class="col-sm-6 p-3 borderBoard">
            <div class="list-PricePlan container">
              <div class="row">
                <div class="col-12">
                  <p class="titlePlan">
                    Benefit paket:
                  </p>
                </div>
                <div class="col-sm-6 px-2">
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Participate in RFQ 400/bulan</p>
                  </div>

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Market Intelligent 400/bulan</p>
                  </div>                 

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">100 Active Products</p>
                  </div>     
                  
                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">RFQ Match Notification 200/bulan</p>
                  </div>               
                  
                  
                </div>
                <div class="col-sm-6 px-2">

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Business Tag 15</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Users 5</p>
                  </div>  

                  <div class="d-flex align-items-center">
                    <img style="max-width: 24px;" src="/assets/images/icons/Group 518-base.png">
                    <p class="textPlan mb-2 ps-2">Autobid</p>
                  </div>  
                  
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 p-3">
            <div class="d-flex align-items-end justify-content-center h-100">
              <div class="w-100 pb-3 pe-0 pe-sm-5">
                <h3 class="actionTitle m-0 pt-3">IDR 6,250,000</h3>
                <p class="actionDesc m-0 pt-0 pb-3">Per Bulan</p>
                <a class="btn btn-fluid w-100 btn-primary actionBtn text-white m-0 mb-3 disabled">Coming Soon</a>
              </div>

            </div>

          </div>
        </div>

      </div>

    </div>
		<div class="py-5 d-none">
			<div class="table-responsive divpricing shadow">
				<table class="table align-middle tablepricing">
					<thead>
						<tr class="thpricing">
							<th>
								<h3 class="my-3">Penawaran Fitur</h3>
							</th>
							<th>
								<h5 class="mb-0">Introduction Period *</h5>
								<h3 class="mb-0">Gratis *</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Free Member</h5>
								<h3 class="mb-0">Gratis</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Professional</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Business</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">SME</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
							<th>
								<h5 class="mb-0">Corporate</h5>
								<h3 class="mb-0">Coming Soon</h3>
								<p class="mb-0">per month</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Paticipate in RFQ</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> Tanpa Batas</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 55/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 200/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 400/bulan</td>
						</tr>
						<tr>
							<td>Market Intelligent</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 6/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 150/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 400/bulan</td>
						</tr>
						<tr>
							<td>Active Product</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 50/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 100/periode</td>
						</tr>
						<tr>
							<td>RFQ Match Notification</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 100/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 1/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 20/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 60/bulan</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 200/bulan</td>
						</tr>
						<tr>
							<td>Business Tag</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 15/periode</td>
						</tr>
						<tr>
							<td>Users</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 3/periode</td>
							<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
						</tr>
						<tr>
							<td>Autobid</td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-x-circle-fill text-danger"></i></td>
							<td><i class="bi bi-check-circle-fill text-success"></i></td>
							<td><i class="bi bi-check-circle-fill text-success"></i></td>
						</tr>
					</tbody>

					<!-- Partisipasi RFQ
					
					
					
					
					
					
	
					<td><i class="bi bi-check-circle-fill text-success"></i> 2/bulan</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 10/periode</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 100/bulan</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 5/periode</td>
					<td><i class="bi bi-check-circle-fill text-success"></i> 2/periode</td>
					<td><i class="bi bi-x-circle-fill text-danger"></i></td> -->
	
	
				</table>
			</div>
		</div>

	</div>
</section>
import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const queryCompany = gql`
query{
  company{
      id
      displayId
      name
      isCompanyNameHiddenInRfq
      logoImageUrl
      primaryEmail
      billingEmail
      procurementEmail
      salesEmail
      website
      locations{
          id
          label
          country
          province
          city
          street
          postalCode
          phone
          taxId
          isMainAddress
      }
      type
      userClassifiedIndustry
      systemClassifiedIndustry
      defaultCurrency
      isTaxExempt
      documents{
          id
          type
          imageUrl
          uploadedFileName
      }
      corporateGroup
      vendorToInvite{
          id
          name
          contact
      }
      subscriptionChoiceIdDuringRegistration
      isAutoRenewSubscription
      registrationIp
      registeredCountry {
          id
          countryName
          currency
      }
      companyTag {
          id
          tag
          isActive
      }
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      isPKP
  }
}
`;

const queryCompanyName = gql`
query{
  company{
    name
  }
}
`;

const querygiveReportAndFeedback = gql`
mutation giveReportAndFeedback($ReportAndFeedbackInput:ReportAndFeedbackInput!){
  giveReportAndFeedback(input: $ReportAndFeedbackInput)
}
`;

const mutationChooseCompany = gql`
mutation chooseCompany($companyId: ID!, $loginEventId: ID!) {
  chooseCompany(input: {companyId: $companyId,  loginEventId: $loginEventId}) {
      __typename,
      ...on ChooseCompanyResponse {
          isFirstTimeLogin,
          staff {
              id,
              roleMembership,
              isActive,
              companyId,
              createdDate,
              createdBy,
              modifiedDate,
              modifiedBy
          },
          accessToken
      }
      ...on DomainError {
          errorMessage
      }
      ...on UnacceptedTermConditionError {
          latestTermCondition {
              id
          }
      }
  }
}
`;

const getCompanyCurrency = gql`
query{
  company{
    defaultCurrency
  }
}
`;



const agreeToTermCondition = gql`
mutation agreeToTermCondition($termConditionId: ID!){
  agreeToTermCondition(input: {
      termConditionId:$termConditionId
  })
}
`;

const getCompanyLogo = gql`
query{
  getCompanyLogo
}

`;

const deleteCompanyLocation = gql`
mutation deleteCompanyLocation($companyLocationId:ID!){
  deleteCompanyLocation(input: {
      companyLocationId: $companyLocationId
  })
}
`;

@Injectable({ providedIn: 'root' })

export class CompanyService{

    queryCompanyObs = new Subject();
    CompanyNameObs = new Subject();
    mutationChooseCompanyObs = new Subject();
    getCompanyCurrencyObs = new Subject();
    agreeToTermConditionObs = new Subject();
    getCompanyLogoObs = new Subject();
    deleteCompanyLocationObs = new Subject();

    constructor(private apollo: Apollo) {}

    CompanyDetail() {
        this.apollo
        .mutate({
          mutation: queryCompany,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.queryCompanyObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.queryCompanyObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getCompanyDetail() {
        this.queryCompanyObs = new Subject();
        this.CompanyDetail();
        return this.queryCompanyObs.asObservable();
    }

    CompanyName() {
      this.apollo
      .mutate({
        mutation: queryCompanyName,
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.CompanyNameObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.CompanyNameObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getCompanyName() {
      this.CompanyNameObs = new Subject();
      this.CompanyName();
      return this.CompanyNameObs.asObservable();
    }
    

    giveReportAndFeedback(ReportAndFeedbackInput:any) {
      this.apollo
      .mutate({
        mutation: querygiveReportAndFeedback,
        errorPolicy: 'all',
        variables: {ReportAndFeedbackInput: ReportAndFeedbackInput},
      })
      .subscribe(
        (res:any) => {
          this.CompanyNameObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.CompanyNameObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    getgiveReportAndFeedback(ReportAndFeedbackInput:any) {
      this.CompanyNameObs = new Subject();
      this.giveReportAndFeedback(ReportAndFeedbackInput);
      return this.CompanyNameObs.asObservable();
    }


    mutationChooseCompany(companyId:string,loginEventId:string) {
        this.apollo
        .mutate({
          mutation: mutationChooseCompany,
          errorPolicy: 'all',
          variables: {
            companyId: companyId,
            loginEventId: loginEventId
          },
        })
        .subscribe(
          (res:any) => {
            this.mutationChooseCompanyObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.mutationChooseCompanyObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getmutationChooseCompany(companyId:string,loginEventId:string) {
        this.mutationChooseCompanyObs = new Subject();
        this.mutationChooseCompany(companyId,loginEventId);
        return this.mutationChooseCompanyObs.asObservable();
    }

    getCompanyCurrency() {
        this.apollo
        .mutate({
          mutation: getCompanyCurrency,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getCompanyCurrencyObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getCompanyCurrencyObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getgetCompanyCurrency() {
        this.getCompanyCurrencyObs = new Subject();
        this.getCompanyCurrency();
        return this.getCompanyCurrencyObs.asObservable();
    }

    
    CompanyLogo() {
        this.apollo
        .mutate({
          mutation: getCompanyLogo,
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getCompanyLogoObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getCompanyLogoObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getCompanyLogo() {
        this.getCompanyLogoObs = new Subject();
        this.CompanyLogo();
        return this.getCompanyLogoObs.asObservable();
    }

    agreeToTermCondition(termConditionId:any) {
        this.apollo
        .mutate({
          mutation: agreeToTermCondition,
          errorPolicy: 'all',
          variables: {
            termConditionId: termConditionId
          },
        })
        .subscribe(
          (res:any) => {
            this.agreeToTermConditionObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.agreeToTermConditionObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getagreeToTermCondition(termConditionId:any) {
        this.agreeToTermConditionObs = new Subject();
        this.agreeToTermCondition(termConditionId);
        return this.agreeToTermConditionObs.asObservable();
    }


    deleteCompanyLocation(companyLocationId:any) {
      this.apollo
      .mutate({
        mutation: deleteCompanyLocation,
        errorPolicy: 'all',
        variables: {companyLocationId: companyLocationId},
      })
      .subscribe(
        (res:any) => {
          this.deleteCompanyLocationObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.deleteCompanyLocationObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
    }

    postdeleteCompanyLocation(companyLocationId:any) {
      this.deleteCompanyLocationObs = new Subject();
      this.deleteCompanyLocation(companyLocationId);
      return this.deleteCompanyLocationObs.asObservable();
    }

}
<div class="helpbg">
  <div class="helpbgLR">
      <div class="helpdiv">
          <div class="py-5 text-center">
              <h3 class="text-white">Hubungi Cepatdapet</h3>
              <h4 class="text-white">Kami senang mendengar dari Anda, silakan hubungi kami.</h4>
          </div>
      </div>
  </div>
</div>
<div class="bg-white">
  <div class="container mb-3 mb-sm-5">
    <div class="row justify-content-center">
      <div class="col-sm-8 p-3 p-sm-5">
        <div class="boxContact p-3 p-sm-5">
          <form [formGroup]="contactForm" id="contactDiv" (ngSubmit)="onSubmit()">

            <div class="row justify-content-end">
              <div class="col-sm-6">

                <div class="form-group mb-3">
                  <label for="name">Name</label>
                  <input type="text" placeholder="Tuliskan nama Anda" [(ngModel)]="ValName" formControlName="name"  maxlength="200" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" />
                  <div
                  class="errtxt"
                  role="alert"
                  *ngIf="submitted && f?.name?.errors"
                  >
                  <div *ngIf="f?.name?.errors?.required">Name is required</div>
                  </div>
                </div>
  
              </div>
              <div class="col-sm-6">
                
                <div class="form-group mb-3">
                  <label for="email">Email</label>
                  <input type="text" placeholder="your@mail.com" [(ngModel)]="ValEmail" formControlName="email" maxlength="200" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.email?.errors }" />
                  <div
                  class="errtxt"
                  role="alert"
                  *ngIf="submitted && f?.email?.errors"
                  >
                  <div *ngIf="f?.email?.errors?.required">Email is required</div>
                  <div *ngIf="f?.email?.errors?.pattern">Email must be a valid email address</div>
                  </div>
                </div>                

              </div>
              <div class="col-sm-12">
                
                <div class="form-group mb-3">
                  <label for="subject">Subjek</label>
                  <input type="text" placeholder="Tuliskan judul pesan" [(ngModel)]="ValSubject"  maxlength="200" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.subject?.errors }" />
                  <div
                  class="errtxt"
                  role="alert"
                  *ngIf="submitted && f?.subject?.errors"
                  >
                  <div *ngIf="f?.subject?.errors?.required">Subject is required</div>
                  </div>
                </div>

              </div>
              <div class="col-sm-12">
                
                <div class="form-group mb-3">
                  <label for="message">Message</label>
                  <!-- <input type="text" placeholder="Tuliskan pesan" [(ngModel)]="ValMessage" formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.message?.errors }" />
                  <div
                  class="errtxt"
                  role="alert"
                  *ngIf="submitted && f?.message?.errors"
                  >
                  <div *ngIf="f?.message?.errors?.required">Message is required</div>
                  </div> -->


                  <textarea [(ngModel)]="ValMessage" formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.message?.errors }" placeholder="Tuliskan pesan" row="4" maxlength="1000"></textarea>
                  <div *ngIf="submitted && f?.message?.errors" class="invalid-feedback">
                      <div class="errtxt" role="alert" *ngIf="f?.message?.errors?.required">Message is required</div>
                  </div>

                </div>

              </div>
              <div class="col-md-5">


                <div class="form-group mt-3">
                  <button *ngIf="!pageloading" class="f1 btn btn-secondary btn-block w-100 btnContact pt-2 pb-2 text-uppercase">
                      Kirim
                  </button>
                  <button *ngIf="pageloading" class="f1 btn btn-secondary btn-block w-100 btnContact pt-2 pb-2 text-uppercase" disabled>
                  Loading..
                  </button>
                </div>
  

              </div>
            </div>

          </form> 
        </div>
      </div>
    </div>
  </div>
</div>
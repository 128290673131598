import { Component, HostListener, OnInit } from '@angular/core';
import { gql, Apollo } from 'apollo-angular';
import { NavigationService } from 'src/app/_services/navigation';
import { ActivatedRoute, Router } from '@angular/router';
import Util from 'src/app/_helpers/util';
import { RfqVendorService } from 'src/app/_services/rfqvendor.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'src/app/config';
import { DataService } from 'src/app/_services/data';
import { PopupmessagingComponent } from 'src/app/components/popupmessaging/popupmessaging.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { LightGallery } from 'lightgallery/lightgallery';
import { OthersService } from 'src/app/_services/others.service';
import { Meta } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/services/auth.service';


// const queryRfq = gql`
// query rfqDetail($rfqId: ID!) {
//   getRfqDetail(input: {
//       rfqId: $rfqId
//   }){
//     isAlreadyParticipating
//     rfq {
//         id
//         companyLocation {
//             id
//             label
//             country
//             province
//             city
//             street
//             postalCode
//             phone
//             taxId
//             isMainAddress
//         }
//         state
//         approvalStatus
//         ongoingApprovalStatus
//         rfqDisplayId
//         description,
//         submitQuotationLastDate
//         paymentTermRequestedInDays
//         etaRequested
//         currency
//         procurementRefNumber
//         isForFullQuote
//         isIndentAccepted
//         expiredDate
//         rfqItem {
//             id
//             name
//             brand
//             description
//             qtyNeeded
//             manufacturerSku
//             itemSequence
//             itemUnit
//         }
//         rfqVendorCategory {
//             id
//             category
//         }
//         totalVendorParticipation
//         createdDate
//         createdBy
//         modifiedDate
//         modifiedBy
//     }
//   }
// }
// `;

// const participateNow = gql`
// mutation participateNow($rfqId: ID!) {
//   participateRfq(input: {
//         rfqId: $rfqId
//     }) {
//       isSuccess
//       rfqParticipationId
//   }
// }
// `;

@Component({
  selector: 'app-landing-share',
  templateUrl: './landing-share.component.html',
  styleUrls: ['./landing-share.component.scss']
})
export class LandingShareComponent implements OnInit {
  rfqId: any
  DataRfq: any = []
  PageLoading: boolean = false
  rfqVendorCategory: any
  displaydone: string = 'none'
  displayconfirm: string = 'none'
  displayerror: string = 'none'
  errortext: string = ''
  divFhSpec: any = []
  isAlreadyParticipating: boolean = false
  productList: any = []
  arrMoreLessDesc: any=[];
  rfqParticipatedId:string=''
  config:any

  public getScreenWidth: any;
  isMobile:boolean=false  

  dataRfqBoard:any=[]
  currentUrl:string=''
  isLogin:boolean=false

  notFound:boolean=false

  lightboxImg:any = [];
  private lightGallery!: LightGallery;
  private needRefresh = false;

  utm_campaign:string=''
  limitItems:boolean=true
  maxShownItem:number=4

  constructor(
    private apollo: Apollo,
    private navigation: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private rfqVendorService: RfqVendorService,
    private othersService: OthersService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private popupmodal : Popupmodal,
    private meta: Meta,
    private authService :AuthService,
    private sharedata: DataService
  ) { 
    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.isLogin = true
    } 
    // console.log('s cek login')
    // if (this.authService.isLoggedIn()) {
    //   console.log('s login valid')
    //   this.isLogin = true
    // } else {
    //   console.log('s login invalid')
    // }

  }

  ngOnInit(): void {

    if(this.route.snapshot.queryParamMap){
      localStorage.removeItem('utm_campaign');
      if(this.route.snapshot.queryParamMap.get('utm_campaign')){
        this.utm_campaign = this.route.snapshot.queryParamMap.get('utm_campaign') || ''
        localStorage.setItem('utm_campaign', this.utm_campaign);
      } 
    }

    this.updateOG()

    this.currentUrl = window.location.href;
    this.config = config
    
    console.log('rfq-detail', 'ngOnInit()')
    this.rfqId = this.route.snapshot.paramMap.get('RfqId');
    console.log('rfq-detail', 'this.rfqId', this.rfqId)
    console.log('rfq-detailxxx', this.rfqId.split('?')[0])
    if(this.rfqId.split('?')[0]){
      console.log('rfq-split ', this.rfqId.split('?')[0])
      this.rfqId = this.rfqId.split('?')[0];
    }
    this.PageLoading = true
    console.log('rfq-detail', 'this.PageLoading', this.PageLoading)
    if(this.isLogin){
      window.location.href = '/vendor/rfq-not-participating-in/detail/'+this.rfqId
      return
    } 
    this.rfqDetail()

    this.getIsMobile()
  }
  updateOG(){
    this.meta.updateTag({property: 'og:image', content: 'https://cepetdapet.com/assets/Share_RFQ.jpg'});
    this.meta.updateTag({name: 'twitter:image', content: 'https://cepetdapet.com/assets/Share_RFQ.jpg'});
  }

  expandItems(){
    if(this.limitItems){
      this.limitItems=false
    } else {
      this.limitItems=true
    }
  }

  getIsMobile(){
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getIsMobile()
  }

  settingsGallery = {
    counter: true,
    download:false,
  };
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if(this.itemsGallery.length>0){
        this.lightGallery.refresh();
        this.needRefresh = false;
      }

    }
  }

  back(): void {
    this.navigation.back()
  }

  formatDisplayId(displayId: any) {
    if(displayId){
      var re = new RegExp('.{1,4}', 'g');
      return displayId.match(re).join("-");
    } else {
      return '';
    }

  }


  readMore(){
    console.log('readMore');
  }
  divspec(val:any){
    return Util.fhspectocomma(val)
  }
  divDetailspec(val:any){
    var divdet = Util.formatShortDesc(val)
    // if(divdet.length>50){
    //   divdet = divdet.substring(0, 50) + ' ...'
    // }
    return divdet
  }
  divDetailspecLess(val:any){
    //return Util.formatShortDesc(val)
    var checkChar = Util.formatShortDesc(val)
    if(checkChar){
      if(checkChar.length>50){
        checkChar = checkChar.substring(0,50) + '...'
      }
    } else {
      return ''
    }

    return checkChar
  }
  divDetailspecLessMobile(val:any){
    //return Util.formatShortDesc(val)
    var checkChar = Util.formatShortDesc(val)
    if(checkChar){
      if(checkChar.length>30){
        checkChar = checkChar.substring(0,30) + '...'
      }
    } else {
      return ''
    }

    return checkChar
  }
  MoreLessDesc(i:any,action:string){
    this.arrMoreLessDesc[i]=action; 
    console.log('arrMoreLessDesc',this.arrMoreLessDesc);
  }

  AccordionHideShow(i: any) {
    console.log('--------123------');

    if (this.productList[i]['collapse'] == true) {
      this.productList[i]['collapse'] = false
    } else {
      this.productList[i]['collapse'] = true
    }
    this.productList[i]['specification'] = "spec"
    console.log('to', this.productList);
    console.log('--------------');
  }
  
  formatShortDesc(shortdesc: any) {
    console.log('shortdesc',shortdesc);
    var desctext = shortdesc;
    var a = shortdesc;
    var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
    console.log('rfq-detail', 'getdetaildesc', getdetaildesc);
    if (getdetaildesc) {
      console.log('rfq-detail', 'getdetaildesc[0]', getdetaildesc[0]);
      console.log('rfq-detail', 'getdetaildesc[1]', getdetaildesc[1]);

      this.divFhSpec = getdetaildesc[1]
      this.divFhSpec = JSON.parse(this.divFhSpec)
      return desctext.replace(getdetaildesc[0], '')
    } else {
      return shortdesc;
    }
  }

  rfqDetail() {


    this.PageLoading=true
    this.othersService
    .getanyoneViewRfqDetail(this.rfqId)
    .subscribe((res:any) => {
      //this.PageLoading=false 
      console.log('res : ',res);

      
      if(res.data.anyoneViewRfqDetail){
        console.log('getupdatePassword', 'res', res)
        this.PageLoading=false

          console.log('rfq-detail', 'res', res)
          this.DataRfq = res.data.anyoneViewRfqDetail
          console.log('rfq-detail', 'this.DataRfq', this.DataRfq)
          console.log('rfq-detail', 'this.DataRfq length', this.DataRfq.length)
          this.rfqVendorCategory = this.DataRfq.rfqVendorCategory.map((val: any) => { return val.category }).join(", ")

  
          var dataBoard ={
            city:this.DataRfq.companyLocation.city,
            country:this.DataRfq.companyLocation.country,
            submitQuotationLastDate:this.DataRfq.submitQuotationLastDate,
            totalVendorParticipation:this.DataRfq.totalVendorParticipation,
            procurementName:'',
            rfqState:'',
            isAlreadyParticipating:this.DataRfq.isAlreadyParticipating,
            rfqVendorCategory:this.rfqVendorCategory,
            rfqDisplayId:this.DataRfq.rfqDisplayId,
            description:this.DataRfq.description,
            rfqItem:this.DataRfq.rfqItem,
            haveMoreRfqItem:'',
            sponsored:false,
            url:''
          }
          console.log('dataBoard : ',dataBoard)
          this.dataRfqBoard.push(dataBoard)
  
  


          this.PageLoading = false
          this.isAlreadyParticipating = res.data.anyoneViewRfqDetail.isAlreadyParticipating

          this.productList = res.data.anyoneViewRfqDetail.rfqItem
          this.productList.forEach((value: any, key: any) => {
            this.productList[key]['collapse']=true
          })
          var arrprod = res.data.anyoneViewRfqDetail.rfqItem
          arrprod.forEach((e:any) => {
            this.arrMoreLessDesc.push('less')
          })

          if(res.data.anyoneViewRfqDetail.state !=='PUBLISHED'){
            this.notFound=true
          }
      }
      else if(res.errors){
        this.notFound=true
        return

        var error = res.errors[0].message
        //this.PageLoading=false
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
          
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })


        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Opps Something Wrong!' // 'Success!' 
        modalRef.componentInstance.infoSuccess = null
        modalRef.componentInstance.infoFailed = errortext
        modalRef.componentInstance.closeTxt = 'Close'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            //window.location.reload();
            this.back()
          } 
        })

       
      }
      
    });



    // console.log('rfq-detail', 'rfqDetail()')
    // console.log('rfq-detail', 'this.rfqId', this.rfqId)
    // this.apollo
    //   .mutate({
    //     mutation: queryRfq,
    //     variables: { rfqId: this.rfqId }
    //   })
    //   .subscribe(
    //     (res: any) => {
    //       console.log('rfq-detail', 'res', res)
    //       this.DataRfq = res.data.getRfqDetail.rfq
    //       console.log('rfq-detail', 'this.DataRfq', this.DataRfq)
    //       console.log('rfq-detail', 'this.DataRfq length', this.DataRfq.length)
    //       this.rfqVendorCategory = this.DataRfq.rfqVendorCategory.map((val: any) => { return val.category }).join(", ")
    //       this.PageLoading = false
    //       this.isAlreadyParticipating = res.data.getRfqDetail.isAlreadyParticipating

    //       this.productList = res.data.getRfqDetail.rfq.rfqItem
    //       this.productList.forEach((value: any, key: any) => {
    //         this.productList[key]['collapse']=true
    //       })
    //       var arrprod = res.data.getRfqDetail.rfq.rfqItem
    //       arrprod.forEach((e:any) => {
    //         this.arrMoreLessDesc.push('less')
    //       })

    //     },
    //     error => {
    //       this.PageLoading = false
    //       this.DataRfq = []
    //       console.log('rfq-detail', 'error queryRfq', error);
    //     })
  }

  participateNow() {
    console.log('rfq-detail', 'participateNow()')
    console.log('rfq-detail', 'this.rfqId', this.rfqId)
    this.PageLoading=true


    this.rfqVendorService.getparticipating(this.rfqId).subscribe((res:any) => {
      this.PageLoading=false
      console.log('res ',res)
      if(res.data?.participateRfq){
        console.log('rfq-detail', 'res', res)
        //this.displaydone = "block"; 123zxc
        if(res.data.participateRfq.rfqParticipationId){
          this.rfqParticipatedId = res.data.participateRfq.rfqParticipationId
        }


        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          size: 'md'
        })

        var titleNotif = 'Success'
        var descNotif = 'Success saved data!'
        var redirectNotif ='/vendor/rfq-participating-in-list'

        
        this.translate.get('rfq.modal_participate_title').subscribe((data:any)=> {
          titleNotif = data;
        });
        this.translate.get('rfq.modal_participate_detail').subscribe((data:any)=> {
          descNotif = data;
        });
        if(this.rfqParticipatedId==''){
          redirectNotif = '/vendor/rfq-participating-in-list'
        } else {
          redirectNotif = '/vendor/rfq-participating-in/detail/'+this.rfqParticipatedId+'/null'
        }

        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = titleNotif
        modalRef.componentInstance.infoSuccess = descNotif
        modalRef.componentInstance.infoFailed = null
        modalRef.componentInstance.closeTxt = 'DONE'

        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate([redirectNotif]);
          } 
        })

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

    return


    // this.apollo
    //   .mutate({
    //     mutation: participateNow,
    //     variables: { rfqId: this.rfqId }
    //   })
    //   .subscribe(
    //     (res: any) => {
    //       console.log('rfq-detail', 'res', res)
    //       this.displaydone = "block";
    //       if(res.data.participateRfq.rfqParticipationId){
    //         this.rfqParticipatedId = res.data.participateRfq.rfqParticipationId
    //       }
    //     },
    //     error => {
    //       this.displayerror = "block";
    //       var errMsg = error + '';
    //       var res = errMsg.split(":");
    //       var resMsg = res[res.length - 1];
    //       var msg = (resMsg) ? resMsg : errMsg
    //       this.errortext = msg
    //       console.log('rfq-detail', 'error participateNow', error);
    //       console.log('rfq-detail', 'res', res);
    //       console.log('rfq-detail', 'resMsg', resMsg);
    //       console.log('rfq-detail', 'msg', msg);
    //     })
  }

  closeError() {
    this.displayerror = 'none'
  }

  sendMessage(){

    const modalRef = this.modalService.open(PopupmessagingComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'md'
    })


    modalRef.componentInstance.title = 'Ask Procurement'
    modalRef.componentInstance.sendMessageAs = 'VENDOR'
    modalRef.componentInstance.rfqid = this.rfqId
    modalRef.componentInstance.quotationId = null
    
  }
  
}

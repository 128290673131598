<div class="container bgprodlist">

  <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 d-none d-sm-block">

    <div class="row">
      <div class="col-sm-10">
        <div class="shbreadcum">
          <table>
            <tr>
              <td class="separator">
                <h6 class="pt-2 mt-1 me-3">
                  <strong>
                    <a href="/" class="c1 pointer">
                      <img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back
                    </a>
                  </strong>
                </h6>
              </td>
              <td>
                <h6 class="mb-1 mt-2 ms-3">
                  <strong class="c1">
                    <!-- <font class="c1 pointer">RFQ</font> >  -->
                    <font class="c2" *ngIf="!OnRfqSearch">Search Products</font>
                    <font class="c2" *ngIf="OnRfqSearch">Search RFQ</font>
                  </strong>
                </h6>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-sm-2"> 
        <table style="height: 100%; width: 100%;" class="text-end">
          <tbody>
            <tr>
              <td class="align-middle">
              </td>
              <td class="align-middle">
              </td>
              <td class="align-middle">
                <!-- <img src="/assets/images/filter.svg" class="ms-3 pointer"> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <!-- Breadcrumb mobile -->
  <div class="d-block d-sm-none">

    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">


      <div class="row">
        <div class="col-6">
          <div class="pt-1">
            <strong class="c1">
              <strong class="c1">
                <font class="text-muted" *ngIf="!OnRfqSearch">Search Products
                </font>
                <font class="text-muted" *ngIf="OnRfqSearch">Search RFQ
                </font>
              </strong>
            </strong>
          </div>

        </div>
        <div class="col-6 text-end">

        </div>
      </div>

    </div>
  </div>

  <div *ngIf="!OnRfqSearch">
  
    <div class="pb-3">
  
      <div class="row"  *ngIf="AlldataProducts?.length > 0" >
        <div class="col-sm-8">
          <p class="m-0 pt-2">
            <i class="mt-1">
              <strong id="mtmSearchTotalData">{{totaldata}}</strong> results for <strong>“<font id="mtmSearchKeyword">{{prevkeyword}}</font>”</strong>
              <!-- Page: {{currentpage}} -->
            </i>
          </p>

        </div>
        <div class="col-sm-4">
  
          <div class="row divPaging" *ngIf="AlldataProducts?.length > 0" >

            <div class="col-12" *ngIf="!pagingloading">
              <div class="row g-0 mb-3 justify-content-end">
                <div class="col-auto pe-3 pt-2 c1">
                  Show
                </div>
                <div class="col-auto">
                  <select class="form-select fhselect" #t (change)="productPerPage(t.value)" [(ngModel)]="ValPaging">
                    <!-- <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="20">20</option> -->
                    <option value="20" selected>20</option>
                    <option value="40">40</option>
                    <option value="80">80</option>
                    <!-- <option value="120">120</option> -->
                  </select>
                </div>
                <div class="col-auto ps-1 pe-1">
                  <div class="btn p-0 ps-3" *ngIf="hasPrevPage==true; else elsePrev" (click)="loadPrev()">
                    <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                  </div>
                  <ng-template #elsePrev>
                    <div class=" disable-btn p-0 ps-3">
                      <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                    </div>
                  </ng-template>
                </div>
                <div class="col-auto  ps-1 pe-1">
                  <div class="btn p-0  ps-2" *ngIf="hasNextPage==true; else elseNext" (click)="loadNext()">
                    <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                  </div>
                  <ng-template #elseNext>
                    <div class=" disable-btn  p-0 ps-2">
                      <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                    </div>
                  </ng-template>
                </div>
              </div>     
            </div>

            <div class="col-12" *ngIf="pagingloading">
              <div class="row g-0 mb-3 justify-content-end">
                <div class="col-auto pe-3 pt-2 c1">
                  Show
                </div>
                <div class="col-auto">
                  <select class="form-select fhselect disabled" [(ngModel)]="ValPaging" disabled>
                    <option value=" "> </option>
                    <!-- <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="4">4</option> -->
                    <!-- <option value="20" selected>20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option> -->
                    <option value="20" selected>20</option>
                    <option value="40">40</option>
                    <option value="80">80</option>
                    <!-- <option value="120">120</option> -->
                  </select>
                </div>
                <div class="col-auto ps-1 pe-1">
                  <div class=" disable-btn p-0 ps-3">
                    <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                  </div>
                </div>
                <div class="col-auto  ps-1 pe-1">
                  <div class=" disable-btn  p-0 ps-2">
                    <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                  </div>
                </div>
              </div>     
            </div>

          </div>
  
          
        </div>
      </div>
  
  
    </div>

      <div *ngIf="PageLoading">
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>  
      </div>   
  
      <div class="pb-5"  *ngIf="!PageLoading">
        
        <div *ngIf="AlldataProducts?.length == 0"  class="empty-product">
          <div class="row">
            <div class="col-12 text-center  pb-3">
              <i><strong id="mtmSearchTotalData">{{totaldata}}</strong> results for <strong>“<font id="mtmSearchKeyword">{{prevkeyword}}</font>”</strong></i>
            </div>
          </div>
          <div class="text-center px-5">
            <img class="py-3" style="max-width:70px;" src="/assets/images/icons/ic_search-minus-outline_db@3x.png">
            <h3><b>{{'result_not_found' | translate}}</b></h3>
            <p class="pb-3">{{'result_not_found_detail' | translate}}</p>
          </div>
        </div>
  
  
        <div class="mb-4" *ngIf="AlldataProducts?.length > 0">

          <!-- companyDomain: "domainvendor 2940"
          productBrand: null
          productDescription: "Detect your pregnancy early with sensitive pregnancy midstream tests Unlike similarly styled tests you can find at the drugstore, these tests can be used just 6 to 8 days after conception (or for those who are fertility charting, 7-10 days after ovulation) &ndash; days before your first missed period! 99.9% accurate Super sensitive, detects hCG at levels of just 20 mlU/ml hCG A fraction of the cost of drugstore pregnancy tests Tests have approximately a 2-year expiration date See Test Instructions See Product Demonstration Video See our pregnancy tests as featured in the movie \"The Pregnancy Pact\" Read our Pregnancy Test Buyer's Guide"
          productName: "Pregnancy Midstream Tests"
          productPrice: 89
          productSlugUrl: "slug_vendor 2940"
          vendorName: "vendor 2940" -->


          <div class="row row-cols-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
            <div class="col mb-4" *ngFor="let product of AlldataProducts; let i = index">
              <app-product-card 
                [images]="product?.imagesUrl"
                [title]="product?.productName"
                [company]="product?.vendorName"
                [currency]="product.currency?product.currency:'IDR'"
                [price]="product?.productPrice"
                location="{{ product.vendorCity? product.vendorCity+', ':''}}{{ product.vendorCountry}}"
                [isConfidential]="product.vendorName?'Y':'N'"
                url="/product/{{(product.companyDomain)?product.companyDomain:'view/'+product.vendorId}}/{{product.productSlugUrl}}"
              >
              </app-product-card>
            </div>
          </div>









        </div> 
  
        <div *ngIf="LastResult" class="fhgrad row justify-content-md-center mb-4">
            <div class="p-4 text-center col-sm-7">
              <font class="textlast">Oops! We are sorry. To see more result of <i>“{{keyword}}”</i> you need to Login first. 
                If you do not have account, please register to new account.</font>
                <br>
                <a href="/login" class="btn btn-primary bg1 fhbtn1t mt-2 btnlast">Login</a>
                <br>
                <a href="/regis" class="btn btn-primary bg1 fhbtn1 mt-2 btnlast">Register</a>
            </div>
        </div>


        <div class="row"  *ngIf="AlldataProducts?.length > 0" >
          <div class="col-sm-8">
            <p class="m-0 pt-2">
              <i class="mt-1">
                <!-- <strong>{{totaldata}}</strong> results for <strong>“<font [innerHTML]="prevkeyword"></font>”</strong> -->
                Page {{currentpage}} of {{totalpage}}
              </i>
            </p>
          </div>
          <div class="col-sm-4">
    
            <div class="row divPaging" *ngIf="AlldataProducts?.length > 0" >
  
              <div class="col-12" *ngIf="!pagingloading">
                <div class="row g-0 mb-3 justify-content-end">
                  <div class="col-auto pe-3 pt-2 c1">
                    Show
                  </div>
                  <div class="col-auto">
                    <select class="form-select fhselect" #t (change)="productPerPage(t.value)" [(ngModel)]="ValPaging">
                      <!-- <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="4">4</option> -->
                      <!-- <option value="20" selected>20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option> -->
                      <option value="20" selected>20</option>
                      <option value="40">40</option>
                      <option value="80">80</option>
                      <!-- <option value="120">120</option> -->
                    </select>
                  </div>
                  <div class="col-auto ps-1 pe-1">
                    <div class="btn p-0 ps-3" *ngIf="hasPrevPage==true; else elsePrev" (click)="loadPrev()">
                      <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                    </div>
                    <ng-template #elsePrev>
                      <div class=" disable-btn p-0 ps-3">
                        <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-auto  ps-1 pe-1">
                    <div class="btn p-0  ps-2" *ngIf="hasNextPage==true; else elseNext" (click)="loadNext()">
                      <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                    </div>
                    <ng-template #elseNext>
                      <div class=" disable-btn  p-0 ps-2">
                        <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                      </div>
                    </ng-template>
                  </div>
                </div>     
              </div>

              <div class="col-12" *ngIf="pagingloading">
                <div class="row g-0 mb-3 justify-content-end">
                  <div class="col-auto pe-3 pt-2 c1">
                    Show
                  </div>
                  <div class="col-auto">
                    <select class="form-select fhselect disabled" [(ngModel)]="ValPaging" disabled>
                      <option value=""> </option>
                      <!-- <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="4">4</option> -->
                      <!-- <option value="20" selected>20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option> -->

                      <option value="20" selected>20</option>
                      <option value="40">40</option>
                      <option value="80">80</option>
                      <!-- <option value="120">120</option> -->
                    </select>
                  </div>
                  <div class="col-auto ps-1 pe-1">
                    <div class=" disable-btn p-0 ps-3">
                      <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                    </div>
                  </div>
                  <div class="col-auto  ps-1 pe-1">
                    <div class=" disable-btn  p-0 ps-2">
                      <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                    </div>
                  </div>
                </div>     
              </div>
              
  
  
  
            </div>
    
            
          </div>
        </div>
  
      </div>
  
  </div>
  <div *ngIf="OnRfqSearch">
    <div class="pb-3">

      <div *ngIf="PageLoading">
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>  
      </div>  


      <div *ngIf="dataRfqBoard?.length == 0"  class="empty-product">
        <div class="row">
          <div class="col-12 text-center  pb-3">
            <i><strong>{{totaldata}}</strong> results for <strong>“<font>{{prevkeyword}}</font>”</strong> in RFQ</i>
          </div>
        </div>
        <div class="text-center px-5">
          <img class="py-3" style="max-width:70px;" src="/assets/images/icons/ic_search-minus-outline_db@3x.png">
          <h3><b>{{'result_not_found' | translate}}</b></h3>
          <p class="pb-3">{{'result_not_found_detail' | translate}}</p>
        </div>
      </div>

      <div class="py-2">
        <ng-container *ngFor="let rfqboard of dataRfqBoard; let i = index">
          <app-board [rfq]="rfqboard"></app-board>
        </ng-container>
      </div>


        <div *ngIf="LastResult" class="fhgrad row justify-content-md-center mb-4">
            <div class="p-4 text-center col-sm-7">
              <font class="textlast">Oops! We are sorry. To see more result of <i>“{{keyword}}”</i> you need to Login first. 
                If you do not have account, please register to new account.</font>
                <br>
                <a href="/login" class="btn btn-primary bg1 fhbtn1t mt-2 btnlast">Login</a>
                <br>
                <a href="/regis" class="btn btn-primary bg1 fhbtn1 mt-2 btnlast">Register</a>
            </div>
        </div>

    </div>
  </div>
</div>
  

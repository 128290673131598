<div class="bg5 pt-3 pb-3">

  <div class="container">

    <!-- Breadcrumb -->
    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 d-none">
      <div class="row">
        <div class="col-sm-7">
          <div class="shbreadcum">
            <table>
              <tr>
                <td class="separator">
                  <h6 class="pt-2 mt-1 me-3">
                    <strong>
                      <a (click)="back()" class="c1 pointer">
                        <img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back
                      </a>
                    </strong>
                  </h6>
                </td>
                <td>
                  <h6 class="mb-1 mt-2 ms-3">
                    <strong class="c1">
                      <a class="c1 pointer" href="/vendor/rfq-board">RFQ</a> > <a class="c1 pointer" href="/vendor/rfq-participating-in-list"> Participating In > </a> <font
                        class="text-muted">Detail RFQ</font>
                    </strong>
                  </h6>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-sm-5">
        </div>
      </div>
    </div>

    <div class="mt-3">

      <!-- Saat page loading -->
      <div *ngIf="PageLoading">
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
              style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                  repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>
      </div>

      <!-- Saat page tidak loading -->
      <div class="pb-5" *ngIf="!PageLoading">

        <!-- Jika RFQ tidak ditemukan  -->
        <div *ngIf="DataRfq?.length == 0" class="empty-product">
          <div class="bg-secondary bg-gradient rounded-3 pt-5 pb-5 mb-5 text-center">
            <h1 class="text-light bi bi-file-earmark-ruled" style="font-size: 80px;"></h1>
            <h6 class="text-light">RFQ ID Not Found.</h6>
          </div>
        </div>

        <!-- Jika ada RFQ -->
        <div class="mb-4" *ngIf="DataRfq != []">
          <!-- RFQ Summary -->
          <div class="card divrfqlist">
            <div class="card-header bg-dark card-header-bg">
              <div class="row c3 fw-bold">
                <div class="col-4 text-start ps-5 pe-0">RFQ # {{this.formatDisplayId(DataRfq.rfqDisplayId)}}</div>
                <div class="col-3 text-start ps-5">Submit quote before : {{DataRfq.submitQuotationLastDate | date:'d MMM y'}}
                </div>
                <div class="col-1 text-center"></div>
                <div class="col-4 text-end pe-4">Vendor Participating : {{ DataRfq.totalParticipation }}</div>
              </div>
            </div>
            <div class="card-body">
              <div class="row text-start ps-3">
                <div class="col-10" [ngSwitch]="DataRfq.rfqState | uppercase">
                  <i class="bi bi-geo-alt-fill bi-geo-color"></i> {{DataRfq.rfqDeliveryCity}},
                  {{DataRfq.rfqDeliveryCountry}}
                  <span *ngSwitchCase="'UNPUBLISHED'" class="badge rounded-pill bg-unpublished ms-3"> UNPUBLISHED
                  </span>
                  <span *ngSwitchCase="'PUBLISHED'" class="badge rounded-pill bg-published ms-3"> PUBLISHED </span>
                  <span *ngSwitchCase="'EXPIRED'" class="badge rounded-pill bg-expired ms-3"> EXPIRED </span>
                </div>
                <div class="col-2 text-center">
                  <span class="badge bg-success p-2">PARTICIPATING</span>
                </div>
              </div>
              <div class="row text-start ps-3 fw-bold">
                <div class="col-12">
                  Category : {{rfqVendorCategory}}
                </div>
              </div>
              <div class="row text-start ps-3">
                <!-- <div class="col-12">
                  <p class="card-text">{{divDetailspec(DataRfq.description)}}</p>
                </div> -->
              </div>
            </div>
          </div>

          <!-- Quotation -->
          <form [formGroup]="rfqForm" (ngSubmit)="onSubmit()">
            <div class="container pt-4 pb-5" id="rfqFormDiv">
              <div class="row pb-5">
                <div class="col-md-12 divprodlist">
                  <div class="row rounded-top fhbgcyan text-white">
                    <div class="col-sm-6 mt-0 pt-3 mb-3 ps-5">
                      <h4 class="mb-0">Detail RFQ
                      </h4>
                    </div>
                    <div class="col-sm-6 mt-0 pt-3 mb-3 pe-5 text-end">
                    </div>
                  </div>
                  <!-- Detail -->
                  <!-- Section 1 -->
                  <div class="row">
                    <div class="col-sm-12 text-start ps-5 mt-4 pb-0">
                      <div class="row mt-2">
                        <div class="col-1 text-start fw-bold c1">Quoted for</div> 
                        <div class="col-11 text-start fw-bold c1">: {{DataRfq.procurementName}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-11 m-5 mt-3 p-4 ps-5 pe-5 rfq-detail">
                      <div class="row mt-2">
                        <div class="col-5 text-start fw-bold c1">Customer Requests</div>
                        <div class="col-2"></div>
                        <div class="col-2 text-start fw-bold">Currency</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-2 text-start">{{DataRfq.currency}}</div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-5 text-start fw-bold">Shipping Address</div>
                        <div class="col-2"></div>
                        <div class="col-2 text-start fw-bold">ETA Requested</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-2 text-start">{{DataRfq.etaRequested | date:'d MMM y'}}</div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-5 text-start">{{DataRfq.rfqDeliveryStreet}}</div>
                        <div class="col-2"></div>
                        <div class="col-2 text-start fw-bold">RFQ Valid Until</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-2 text-start">{{DataRfq.submitQuotationLastDate | date:'d MMM y'}}</div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-5 text-start">{{DataRfq.rfqDeliveryCity}}</div>
                        <div class="col-2"></div>
                        <div class="col-2 text-start fw-bold">Payment Term</div>
                        <div class="col-1 text-center">:</div>
                        <div class="col-2 text-start">{{DataRfq.paymentTermRequestedInDays}} days</div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-5 text-start">{{DataRfq.rfqDeliveryProvince}}</div>
                        <div class="col-2"></div>
                        <div class="col-5 text-start text-success fw-bold">
                          {{DataRfq.isForFullQuote ? "Only Full Quotes Accepted" : ""}}</div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-5 text-start">{{DataRfq.rfqDeliveryCountry}}</div>
                        <div class="col-2"></div>
                        <div class="col-5 text-start text-success fw-bold">
                          {{DataRfq.isIndentAccepted ? "Indent (Backorder) Accepted" : ""}}</div>
                      </div>
                    </div>
                  </div>
                  <!-- Line break -->
                  <div class="row">
                    <div class="col-sm-12 ps-5 pe-5 mb-3">
                      <hr class="mt-0 mb-0">
                    </div>
                  </div>
                  <!-- Section 2 -->
                  <div class="row">
                    <div class="col-sm-10 p-5 pt-3 pb-1">
                      <label class="" for="listproduct"><strong>List of Product</strong></label>
                    </div>
                    <div class="col-sm-2 p-5 pt-3 pb-1">
                    </div>
                    <!-- Product List -->
                    <div class="col-sm-12 p-5 pt-0 pb-0">
                      <div class="mb-3 row">
                        <div class="container">
                          <ul class="list-group rounded-0">
                            <li class="list-group-item bg1 p-0 mb-3 rounded shadow border-0 b1 hvshadow"
                              *ngFor="let product of productList; let i = index"
                              [ngClass]="{ 'bg4': submitted && (CheckVal(ValmanufacturerSku[i]) || CheckVal(Valbrand[i]) || CheckVal(ValqtyNeeded[i]) ) }">
                              <div class="row m-0 p-0 pointer" (click)="AccordionHideShow(i)">
                                <div class="col-sm-1 p-2 ps-3 fw-bold text-white">{{i+1}}</div>
                                <div class="col-sm-10 p-2 text-center text-white"><strong>{{product.brand}}</strong> :
                                  {{divDetailspecLess(product.description)}}
                                </div>
                                <div class="col-sm-1 p-2 text-end fw-bold text-white">
                                  <img
                                    src="/assets/images/icons/{{ (product.collapse) ? 'ic_Dup@2x.png':'ic_Ddown@2x.png' }}"
                                    class="ps-2 pe-2 icondropdown">
                                </div>
                              </div>
                              <!-- Expanded Content -->
                              <div
                                class="bg-white divshadow m-0 p-5 pt-4 pb-3 rounded-bottom row {{ (product.collapse) ? '':'d-none' }}">
                                <div class="col-sm-12 p-4 ms-0 me-5 mb-4 product-detail">
                                  <div class="row mt-2">
                                    <div class="col-2 text-start fw-bold f14">Brand</div>
                                    <div class="col-1 text-center f14">:</div>
                                    <div class="col-2 text-start f14">{{product.brand}}</div>
                                    <div class="col-2"></div>
                                    <div class="col-2 text-start fw-bold"></div>
                                    <div class="col-1 text-center"></div>
                                    <div class="col-2 text-start"></div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-2 text-start fw-bold f14">Manf. Prod. No.</div>
                                    <div class="col-1 text-center f14">:</div>
                                    <div class="col-2 text-start f14">{{product.manufacturerSku}}</div>
                                    <div class="col-2"></div>
                                    <div class="col-2 text-start fw-bold f14">QTY Requested</div>
                                    <div class="col-1 text-center f14">:</div>
                                    <div class="col-2 text-start f14">{{product.qtyNeeded}} {{product.itemUnit}}</div> 
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-2 text-start fw-bold f14">Product Desc.</div>
                                    <div class="col-1 text-center f14">:</div>
                                    <div class="col-2 text-start f14">
                                      <font *ngIf="!(divDetailspecLess(product.description).length>50)">{{ divDetailspecLess(product.description) }}</font>
    
                                      <div *ngIf="arrMoreLessDesc[i]=='less' && divDetailspecLess(product.description).length>50">
                                        {{ divDetailspecLess(product.description) }}
                                        <strong><span class="c1 pointer" (click)="MoreLessDesc(i,'more')">Read More</span></strong>
                                      </div>
                                      <div *ngIf="arrMoreLessDesc[i]=='more' && divDetailspecLess(product.description).length>50">
                                        {{ divDetailspec(product.description) }}
                                        <strong><span class="c1 pointer" (click)="MoreLessDesc(i,'less')">Read Less</span></strong>                   
                                      </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-2 text-start fw-bold f14">Specification</div>
                                    <div class="col-1 text-center f14">:</div>
                                    <div class="col-2 text-start f14" [innerHTML]="divspec(product.description)"></div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="accordion d-none" id="accordionContainer">
                          <div class="accordion-item" *ngFor="let category of cateories; let i = index">
                            <h2 class="accordion-header" [id]="'heading' + i">
                              <button (click)="AccordionHideShow(i)"
                                class="accordion-button {{ (accordionStatus[i]) ? 'collapsed' : '' }}" type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i"
                                [attr.aria-expanded]="accordionStatus[i]" [attr.aria-controls]="'collapse' + i">
                                {{ category.title }}
                              </button>
                            </h2>
                            <div [id]="'collapse' + i" class="accordion-collapse collapse"
                              [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionContainer">
                              <div class="accordion-body">
                                Accordion Item #1 123
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Fixed Bottom Button -->
    <div class="fixed-bottom shadow d-none">
      <div class="bg-white pt-3 pb-3">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <a (click)="sendMessage()" class="btn btn-primary fhbtn3 rounded-1 roundedmore mb-2 ms-3 ps-4 pe-4">Ask Customer</a>
            </div>
            <div class="col-6 text-end">
              <button type="button"
                class="btn btn-primary fhbcyan rounded-1 roundedmore mb-2 ms-3 ps-4 pe-4" (click)="openQuotation()">{{ quotationId!=='null' ? 'Edit':'Create' }} Quote</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirm Modal -->
    <div class="modal fade" id="confirmModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0 border-0">
          <div class="modal-header bg1 rounded-0 text-center">
            <h5 class="text-white f1 mb-0"><strong>Participate Confirmation</strong></h5>
          </div>
          <div class="modal-body">
            <h6 class="text-center mt-4 mb-4">
              Are you sure want to participate?
            </h6>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3">
              <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall"
                data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall"
                data-bs-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div class="modal" id="successModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displaydone}">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
          <div class="modal-body pt-4 pb-4 ps-4 pe-4">
            <div class="text-center">
              <img class="saveimgicon" src="/assets/images/icons/done-save.png">
            </div>
            <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
            <p class="text-center mt-0 mb-4 fw-bold">You has been successfully participated!</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/rfq-board"
                data-bs-dismiss="modal">DONE</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal" id="errorModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayerror}">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
          <div class="modal-body pt-4 pb-4 ps-4 pe-4">
            <div class="text-center">
              <img class="saveimgicon" src="/assets/images/error.png">
            </div>
            <h3 class="text-center mt-3 mb-2 f1">Error!</h3>
            <p class="text-center mt-0 mb-4 fw-bold">{{errortext}}</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="closeError()">CLOSE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const QueryloadReportAndFeedback = gql`
query loadReportAndFeedback($limit:Int!, $offset:Int!){
    loadReportAndFeedback(input: {
        limit: $limit
        offset: $offset
    }){
        totalItem
        reportAndFeedback {
            reportAndFeedbackId
            companyName
            reportedUrl
            userEmail
            userPosition
            feedbackIssueType
            notes
            status
        }
    }
}
`;

const contactUs = gql`
mutation contactUs($name:String!,$email:String!,$subject:String!,$content:String!){
  contactUs(input: {
      name: $name
      email: $email
      subject: $subject
      content: $content
  })
}
`;

const getCartList = gql`
query getCartList($limit:Int!, $offset:Int!){
  getCartList(input: {
      limit: $limit
      offset: $offset
  }) {
      totalData
      cartProductData {
          cartId
          vendorName
          productName
          productBrand
          productDescription
          productSlugUrl
          companyDomain
          productPrice
          currency
          isDeleted
          isActive
          isActiveByUser
          imageUrl
      }
  }
}
`;

const addProductToCart = gql`
mutation addProductToCart($productId:ID!){
  addProductToCart(input: {
      productId: $productId
  })
}
`;

const deleteProductFromCart = gql`
mutation deleteProductFromCart($cartItemId:[ID]){
  deleteProductFromCart(input: {
    cartItemId: $cartItemId
  })
}
`;

const getPublicProduct = gql`
query {
  getPublicProduct{
      totalData
      publicProductData {
          vendorName
          productName
          productBrand
          productDescription
          productSlugUrl
          companyDomain
          vendorId
          productPrice
          currency
          imagesUrl
          vendorCountry
          vendorCity
      } 
  }
}
`;

const anyoneViewRfqList = gql`
query {
  anyoneViewRfqList{
      rfqId
      rfqDisplayId
      procurementName
      description
      rfqVendorCategory
      submitQuotationLastDate
      rfqDeliveryCountry
      rfqDeliveryProvince
      rfqDeliveryCity
      rfqDeliveryStreet
      rfqDeliveryPostalCode
      rfqState
      totalParticipation
      rfqItem {
          id
          brand
          description
      }
      isAlreadyParticipating
      haveMoreRfqItem
      rfqParticipationId
      quotationId
  }
}
`;

const anyoneSearchRfq = gql`
query anyoneSearchRfq($keyword:String!,$first:Int!,$after:String){
  anyoneSearchRfq(input: {
      rfqGlobalSearchInputData: {
          keyword: $keyword
      }
      first: $first
      after: $after
  }){
      pageInfo{
          endCursor
          hasNextPage
      }
      edges{
          cursor
          node{
              rfqId
              rfqDisplayId
              procurementName
              description
              rfqVendorCategory
              submitQuotationLastDate
              rfqDeliveryCountry
              rfqDeliveryProvince
              rfqDeliveryCity
              rfqDeliveryStreet
              rfqDeliveryPostalCode
              rfqState
              totalParticipation
              isAlreadyParticipating
              haveMoreRfqItem
              rfqParticipationId
              quotationId
              rfqItem {
                  brand
                  description
              }
          }
      }
  }
}
`;

const anyoneViewRfqDetail = gql`
query anyoneViewRfqDetail($rfqId:ID!){
    anyoneViewRfqDetail(input: {
        rfqId: $rfqId
    }){
        id
        companyLocation {
            id
            label
            country
            province
            city
            street
            postalCode
            phone
            taxId
            isMainAddress
        }
        state
        approvalStatus
        ongoingApprovalStatus
        rfqDisplayId
        description,
        submitQuotationLastDate
        paymentTermRequestedInDays
        etaRequested
        currency
        procurementRefNumber
        isForFullQuote
        isIndentAccepted
        expiredDate
        rfqItem {
            id
            name
            brand
            description
            qtyNeeded
            manufacturerSku
            itemSequence
            itemUnit
            fileName
        }
        rfqVendorCategory {
            id
            category
        }
        totalVendorParticipation
        createdDate
        createdBy
        modifiedDate
        modifiedBy
    }
}  
`;

@Injectable({ providedIn: 'root' })

export class OthersService{
    QueryloadReportAndFeedbackObs = new Subject();
    contactUsObs = new Subject();
    getCartListObs = new Subject();
    addProductToCartObs = new Subject();
    deleteProductFromCartObs = new Subject();
    getPublicProductObs = new Subject();
    anyoneViewRfqListObs = new Subject();
    anyoneSearchRfqObs = new Subject();
    anyoneViewRfqDetailObs = new Subject();

    constructor(private apollo: Apollo) {}

    LoadReportAndFeedback(limit:number, offset:number) {
        this.apollo
        .mutate({
          mutation: QueryloadReportAndFeedback,
          variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.QueryloadReportAndFeedbackObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.QueryloadReportAndFeedbackObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getLoadReportAndFeedback(limit:any, offset:any) {
        this.QueryloadReportAndFeedbackObs = new Subject();
        this.LoadReportAndFeedback(limit, offset);
        return this.QueryloadReportAndFeedbackObs.asObservable();
    }

    contactUs(name:string, email:string, subject:string, content:string) {
        this.apollo
        .mutate({
          mutation: contactUs,
          variables: {
            name: name, 
            email: email,
            subject: subject,
            content: content,
          },
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.contactUsObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.contactUsObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    postcontactUs(name:string, email:string, subject:string, content:string) {
        this.contactUsObs = new Subject();
        this.contactUs(name, email, subject, content);
        return this.contactUsObs.asObservable();
    }

    CartList(limit:number, offset:number) {
      this.apollo
      .mutate({
        mutation: getCartList,
        variables: {limit: limit, offset: offset},
        errorPolicy: 'all',
      })
      .subscribe(
        (res:any) => {
          this.getCartListObs.next(res);
          console.log("succes, get data:", res);
        },
        (error) => {
          this.getCartListObs.next(error)
          console.log("there was an error sending the query", error);
        }
      );
  }

  getCartList(limit:any, offset:any) {
      // this.getCartListObs = new Subject();
      this.CartList(limit, offset);
      return this.getCartListObs.asObservable();
  }

  
  addProductToCart(productId:string) {
    this.apollo
    .mutate({
      mutation: addProductToCart,
      variables: {
        productId: productId
      },
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.addProductToCartObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.addProductToCartObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  postaddProductToCart(productId:string) {
      this.addProductToCartObs = new Subject();
      this.addProductToCart(productId);
      return this.addProductToCartObs.asObservable();
  }  

  deleteProductFromCart(cartItemId:any) {
    this.apollo
    .mutate({
      mutation: deleteProductFromCart,
      variables: {
        cartItemId
      },
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.deleteProductFromCartObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.deleteProductFromCartObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  postdeleteProductFromCart(cartItemId:any) {
      this.deleteProductFromCartObs = new Subject();
      this.deleteProductFromCart(cartItemId);
      return this.deleteProductFromCartObs.asObservable();
  }  
  

  PublicProduct() {
    this.apollo
    .mutate({
      mutation: getPublicProduct,
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.getPublicProductObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.getPublicProductObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  getPublicProduct() {
      this.getPublicProductObs = new Subject();
      this.PublicProduct();
      return this.getPublicProductObs.asObservable();
  } 


  ViewRfqList() {
    this.apollo
    .mutate({
      mutation: anyoneViewRfqList,
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.anyoneViewRfqListObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.anyoneViewRfqListObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }

  anyoneViewRfqList() {
      this.anyoneViewRfqListObs = new Subject();
      this.ViewRfqList();
      return this.anyoneViewRfqListObs.asObservable();
  } 

  anyoneSearchRfq(keyword:string, first:any, after:any) {
    this.apollo
    .mutate({
      mutation: anyoneSearchRfq,
      variables: {keyword: keyword, first: first, after: after},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.anyoneSearchRfqObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.anyoneSearchRfqObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }
  
  getanyoneSearchRfq(keyword:string, first:any, after:any) {
    this.anyoneSearchRfqObs = new Subject();
    this.anyoneSearchRfq(keyword, first, after);
    return this.anyoneSearchRfqObs.asObservable();
  }
  
  anyoneViewRfqDetail(rfqId:string) {
    this.apollo
    .mutate({
      mutation: anyoneViewRfqDetail,
      variables: {rfqId: rfqId},
      errorPolicy: 'all',
    })
    .subscribe(
      (res:any) => {
        this.anyoneViewRfqDetailObs.next(res);
        console.log("succes, get data:", res);
      },
      (error) => {
        this.anyoneViewRfqDetailObs.next(error)
        console.log("there was an error sending the query", error);
      }
    );
  }
  
  getanyoneViewRfqDetail(rfqId:string) {
    this.anyoneViewRfqDetailObs = new Subject();
    this.anyoneViewRfqDetail(rfqId);
    return this.anyoneViewRfqDetailObs.asObservable();
  }
  

}
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordion, NgbAccordionConfig, NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { HelpService } from 'src/app/_services/help.services';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PopuphelpsurveyComponent } from 'src/app/components/popuphelpsurvey/popuphelpsurvey.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  providers: [NgbAccordionConfig]
})
export class HelpComponent implements OnInit {
  disabled = false;
  sidebarmenu:any = []
  listChild:any=[]
  detailTitle:string=''
  detailHelp:any
  HelpLoading:boolean=false
  redirectLoading:boolean=false

  PageLoading:boolean=false
  mainMenu:any
  sideMenu:any
  remapSideMenu:any
  maxlevelIndex:number=0
  arrMAxLEvel:any[]=[]
  arrPath:string[]=[]
  pathDetail:string=''
  menuListData:any[]=[]
  contentData:any
  detailTitleContent:string=''
  detailHelpContent:any
  noData:boolean=false
  totalEmptyData:number=0
  
  valSearch:string=''
  invalidSearch:boolean=false
  onSearch:boolean=false
  onValSearch:string=''
  searchResult:any=[]

  submitted:boolean=false

  constructor(
    //config: NgbAccordionConfig,
    private route : ActivatedRoute,
    private router : Router,
    private http: HttpClient,
    protected _sanitizer: DomSanitizer,
    private helpService: HelpService,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
    private modalService: NgbModal,
    ) { 
    //config.closeOthers = true;
    this.getJSON().subscribe(data => {
      console.log('sidebarmenu > ',data);
      this.sidebarmenu = data
     });


  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  public getJSONDetail(category:string): Observable<any> {
    return this.http.get('assets/help/category/'+category+'.json');
  }

  @ViewChild('acc')
  acc!: NgbAccordion;

  @ViewChild('accsub')
  accsub!: NgbAccordion;

  @ViewChild('accchildsub')
  accchildsub!: NgbAccordion;
  
  breadcrumb:any[]=[]

  private _jsonURL = 'assets/help/sidebar.json';

  urlCategory:string=''
  urlHelp:string=''

  url1:string=''
  url2:string=''
  url3:string=''
  url4:string=''

  ngOnInit(): void {
    // this.getBreadcrum()

     var category  = this.route.snapshot.paramMap.get('url1');
     var parent  = this.route.snapshot.paramMap.get('url2');
     var child = this.route.snapshot.paramMap.get('url3');
     var subchild = this.route.snapshot.paramMap.get('url4');
     console.log('category',category);
     console.log('parent',parent);
     console.log('child',child);
     console.log('subchild',subchild);
     if(category){
       this.url1= category
     } 
     if(parent){
      this.url2= parent
     } 
     if(child){
      this.url3= child
     } 
     if(subchild){
      this.url4= subchild
     } 
     console.log('url1',this.url1);
     console.log('url2',this.url2);
     console.log('url3',this.url3);
     console.log('url4',this.url4);

     //this.getContentHelpByUrl()

     var href = this.router.url;
     console.log('href : ',href);
     var arrHref = href.split('/');
     console.log('arrHref : ',arrHref);
     for (let i = 0; i < arrHref.length; i++) {
      if(arrHref[i] !== ''){
        this.arrPath.push(arrHref[i])
      }
     }
     console.log('arrPath : ',this.arrPath);

     if(this.arrPath.length>1){
      this.getSideMenu()

      var href = this.router.url;
      var slug = href.replace('/help/','');
      console.log('slug : ',slug)
      console.log('href : ',href)


      // console.log('slug :: ',slug)

      var arrSlug = slug.split('/');
      var setSlug = '';
      if(arrSlug.length>0){
        if(arrSlug[0]=='detail'){
          setSlug = arrSlug[1]
          this.getContentDetail(setSlug)
        } else {
          this.getMenuContentList(slug)
        }
      }
  
        
  

      
      

     } else {
      //home help
      this.getMainMenu()
     } 
     this.redirectLoading=false
  }
  getMainMenu(){
    this.PageLoading=true
    this.helpService.getHelpMainMenu().subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.getHelpMainMenu){
        this.mainMenu = res.data?.getHelpMainMenu
        console.log('this.mainMenu',this.mainMenu)

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }
  getSideMenu(){
    this.PageLoading=true
    this.helpService.getHelpSideMenu().subscribe((res:any) => {
      console.log('>>',res)
      this.PageLoading=false
      if(res.data?.getHelpSideMenu){
        this.sideMenu = res.data?.getHelpSideMenu
        console.log('this.sideMenu',this.sideMenu)
        //this.setActiveMenu(this.url1)

        // get max index
        this.sideMenu.forEach((value: any, key: any) => {
          if(value.levelIndex>this.maxlevelIndex){
            this.maxlevelIndex = value.levelIndex
          }
        });
        console.log('maxlevelIndex : ',this.maxlevelIndex)
        this.arrMAxLEvel = new Array(this.maxlevelIndex);
  
        this.remapSideMenu = res.data?.getHelpSideMenu
        this.remapArr(res.data?.getHelpSideMenu)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });      

  }
  getMenuContentList(slug:string){
    this.PageLoading=true
    this.helpService.getMenuContentList(slug).subscribe((res:any) => {
      console.log('>>',res)
      this.PageLoading=false
      if(res.data?.getMenuContentList){
        var response = res.data?.getMenuContentList
        if(Array.isArray(response)){
          this.menuListData = res.data?.getMenuContentList

          if(this.menuListData.length==0){
            this.totalEmptyData =  this.totalEmptyData + 1
          }

        } 
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        //this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });   
  }
  getContentDetail(slug:string){
    this.PageLoading=true
    this.helpService.getMenuContentDetail(slug).subscribe((res:any) => {
      console.log('>>',res)
      this.PageLoading=false
      if(res.data){
       this.contentData = res.data?.getMenuContentDetail

        this.detailHelpContent = this.getSafeHtml(this.contentData?.content)
        this.detailTitleContent = this.contentData?.title

        if(!this.contentData){
          this.totalEmptyData =  this.totalEmptyData + 1
        }
        this.setActiveParentByContent(this.contentData?.helpSideMenuId)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        //this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });   
  }
  checkData(resMenu:boolean,resContent:boolean){
    // !contentData &&  menuListData?.length==0
    let allEmpty = 0
    if(resMenu){
      allEmpty++
    }
    if(resContent){
      allEmpty++
    }
    if (allEmpty>1){
      this.noData=true
    }
  }

  moveArray(array:any, to:any, from:any){
    const item = array[from];
    array.splice(from, 1);
    array.splice(to, 0, item);
    // console.log('moveArray ',array)
    return array;
  }
  remapArr(arr:any){
    var array:any = arr

    array.forEach((value: any, key: any) => {
      array.forEach((v: any, k: any) => {
        if(v.parentId==value.helpSideMenuId){
          this.moveArray(array,key+v.tabIndex,k)
        }
      })
    })

    //check set parent open and child close
    var arrParent:any=[]
    array.forEach((value: any, key: any) => {
      console.log('value : ',value)
      if(value.levelIndex ==0){
        array[key]['isMainMenu']= true
        array[key]['isParentOpen']= true
      } else {
        array[key]['isMainMenu']= false
        array[key]['isParentOpen']= false
      }
      array[key]['isMenuOpen']= false
      

      if(value.parentId){
        if(!arrParent.includes(value.parentId)){
          arrParent.push(value.parentId)
        }
      }
    })

    // add total child
    array.forEach((value: any, key: any) => {
      if(arrParent.includes(value.helpSideMenuId)){
        array[key]['isHaveChild']= true
      } else {
        array[key]['isHaveChild']= false
      }
    })
    console.log('arrParent : ',arrParent)

    this.remapSideMenu = array
    console.log('moveArray remapSideMenu ',this.remapSideMenu)
  }

  checkurl(href:string){
    //replace http  dev prod
    var res = ''
    var originalString = href;
    res = originalString.replace("http://117.102.124.83:17173/help/", "");
    res = res.replace("https://cepetdapet.com/help/", "");
    return '/help/'+res
  }

  isActive(slugUrl:string){
    var href = this.router.url;
    var res = false
    if('/help/'+slugUrl==href){
      res = true 
    }
    return res
  }

  isParentOpen(idx:number,level:number,id:string){
    var parentopen = false
    if(this.remapSideMenu){
      this.remapSideMenu.forEach((value: any, key: any) => {
        if(value.isMenuOpen){
          
          if(id==value.helpSideMenuId){
            console.log('id=helpSideMenuId ',id)
            if(this.remapSideMenu[key].parentId==value.helpSideMenuId){
              parentopen = true
              console.log('iniisOpen')
            } else {
              parentopen = false
              console.log('onoisOpen')
            }
          }
        }
      })
    }

    return parentopen
  }

  openMenu(parentId : string,levelIndex:number){

    if(this.remapSideMenu){
   
      console.log('levelIndex : ',levelIndex)
      //all level above hide
      this.remapSideMenu.forEach((value: any, key: any) => {
        console.log('value.levelIndex : ',value.levelIndex)
        if(value.levelIndex>levelIndex){
          console.log('levelIndex > value.levelIndex')
          this.remapSideMenu[key].isMenuOpen=false
          //this.remapSideMenu[key].isParentOpen=false 
        } 
      })
      
      this.remapSideMenu.forEach((value: any, key: any) => {
        if(value.helpSideMenuId==parentId){

          if(this.remapSideMenu[key].isMenuOpen==true){
            this.remapSideMenu[key].isMenuOpen=false
            console.log('ini')
          } else {
            this.remapSideMenu[key].isMenuOpen=true
            console.log('ono')
          }
        } 

      })


      this.remapSideMenu.forEach((value: any, key: any) => {
        if(value.parentId==parentId){

          if(this.remapSideMenu[key].isParentOpen==true){
            this.remapSideMenu[key].isParentOpen=false
            console.log('p ini')
          } 
          else {
            this.remapSideMenu[key].isParentOpen=true
            console.log('p ono')


          }
        } 
        else {

          if(value.levelIndex>levelIndex){
            console.log('levelIndex > value.levelIndex')
            this.remapSideMenu[key].isParentOpen=false
            //this.remapSideMenu[key].isMenuOpen=false
          } 
          this.remapSideMenu[key].isMenuOpen=false
        }
      })
      
      console.log('>> ',this.remapSideMenu)

    }
  }

  checkisChildOpen(parentId:string){
    var res = false
    this.remapSideMenu.forEach((value: any, key: any) => {
      if(value.parentId==parentId){
        if(value.isParentOpen==true){
          res = true
        }
      } 
    })
    return res
  }

  setActiveParentByContent(menuid:string){
    var res = false
    if(this.remapSideMenu){

      this.remapSideMenu.forEach((value: any, key: any) => {
        if(value.helpSideMenuId==menuid){
          console.log('setActiveParentByContent value.parentId : ', value.parentId)
          console.log('setActiveParentByContent value.levelIndex : ', value.levelIndex)

          this.checkisChildOpen(value.parentId)

        } 
      })
    }
    return res
  }

  spacingDiv(total:number){
    if(total){
      var textres = ''
      // for (let i = 1; i <= total; i++) {
      //   textres +=' ― '
      // }
      for (let i = 1; i <= total; i++) {
        textres =' ps-'+(total+1)+' '
      }
      return textres

    } else {
      return '';
    }
  }


  helpSearch(){
    var keyword = encodeURIComponent(this.valSearch)
    //this.router.navigate(['/search/'+keyword]);

    // var checkKeyword = this.valSearch.length
    // console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }
    

    this.PageLoading=true
    this.helpService.getsearchContentList(this.valSearch).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.searchContentList){
        this.onSearch=true
        this.searchResult=res.data?.searchContentList
        this.onValSearch=this.valSearch
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  getContentHelpByUrl(){
    this.HelpLoading = true
    if((this.url1) && (this.url2) && (this.url3) && (this.url4)){ 
      this.getJSONDetail(this.url1).subscribe(data => {
        console.log('getJSONDetail',data);
        
        data.forEach((e:any) => {
          e.child.forEach((el:any) => {
            el.child.forEach((elm:any) => {
              if(elm.url==this.url1+'/'+this.url2+'/'+this.url3+'/'+this.url4){
                this.detailHelp = this.getSafeHtml(elm.desc)
                this.detailTitle = elm.title

                var arrDetail = elm.desc.match(/<iframe(.)>.*?<\/iframe>/g);
                console.log('arrDetail => ',arrDetail)
                var parser = new DOMParser();
                var parsedHtml    = parser.parseFromString(elm.desc, "text/html");
                let pTags = parsedHtml.getElementsByTagName("iframe");
                console.log('pTags => ',pTags)
            }
            })

          })
        });

        

        this.setActiveMenu(this.url1+'/'+this.url2+'/'+this.url3+'/'+this.url4)
      });
    } 
    else if((this.url1) && (this.url2) && (this.url3)){ 
      this.getJSONDetail(this.url1).subscribe(data => {
        console.log('getJSONDetail',data);
        
        data.forEach((e:any) => {
          e.child.forEach((el:any) => {
            if(el.url==this.url1+'/'+this.url2+'/'+this.url3){
              if(el.child.length>0){
                this.listChild = el.child
              } 
              else {
                this.detailHelp = this.getSafeHtml(el.desc)
                this.detailTitle = el.title
  
                var arrDetail = el.desc.match(/<iframe(.)>.*?<\/iframe>/g);
                console.log('arrDetail => ',arrDetail)
                var parser = new DOMParser();
                var parsedHtml    = parser.parseFromString(el.desc, "text/html");
                let pTags = parsedHtml.getElementsByTagName("iframe");
                console.log('pTags => ',pTags)
              }

            }
          })
        });

        

        this.setActiveMenu(this.url1+'/'+this.url2+'/'+this.url3)
      });
    } 
    else if((this.url1) && (this.url2)){
      this.getJSONDetail(this.url1).subscribe(data => {
        console.log('getJSONDetail',data);
        data.forEach((element:any) => {
          console.log('element.child == ',element.child)
          
          if(element.url==this.url1+'/'+this.url2){

            if(element.child.length>0){
              this.listChild = element.child
            } else {
              this.detailHelp = element.desc
              this.detailTitle = element.title
            }

          }

        });
        


        

        this.setActiveMenu(this.url1+'/'+this.url2)
      });
    }
    else if((this.url1)){
      this.getJSONDetail(this.url1).subscribe(data => {
        console.log('getJSONDetail',data);
        this.listChild = data
        this.setActiveMenu(this.url1)
      });
    }
    else {

    }
    this.HelpLoading = false

  }

  getSafeUrl(fileName: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(fileName);
  }
  getSafeHtml(content:any) {
    return this._sanitizer.bypassSecurityTrustHtml(content);
  }


  goToPage(url:string){
    console.log('goToPage  : ',url)
    //this.router.navigate([url]);
    window.location.href = url
    //this.redirectLoading=true
  }
  setloading(){
    //this.redirectLoading=true
  }

  public toggleAccordian( props:NgbPanelChangeEvent ): void{
    props.nextState // true === panel is toggling to an open state 
                               // false === panel is toggling to a closed state
    props.panelId    // the ID of the panel that was clicked
    //props.preventDefault(); // don't toggle the state of the selected panel
    console.log('props.nextState : ',props.nextState)
    console.log('props.panelId : ',props.panelId)

    var getid =  parseInt(props.panelId.split('-')[1]);

    //console.log('data detail : ',this.getDetailbyId(getid))
    
    var data:any = this.getDetailbyId(getid)
    console.log('data  : ',data)
    this.listChild=[]
    if(data){
      if(data.child.length>0){
        this.getContentHelpByUrl()
      } else {
        this.goToPage('help/'+data.url)
      }
    }

  }

  getDetailbyId(id:number){
    this.HelpLoading=true
    console.log('getDetailbyId id : ',id)
    var data = null
    console.log('getDetailbyId this.sidebarmenu : ',this.sidebarmenu)
    this.sidebarmenu.forEach((e:any) => {
      if(e.id==id){
        console.log('e => ',e)
        data = e
      }
      e.child.forEach((el:any) => {
        if(el.id==id){
          console.log('el => ',el)
          data = el
        }
        el.child.forEach((elm:any) => {
          if(elm.id==id){
            console.log('elm => ',elm)
            data = elm
          }
        });
      });
    });
    this.HelpLoading=false
    return data
  }

  setActiveMenu(url:string){
    console.log('setActiveMenu url :: ',url)
    var arrUrl = url.split('/')
    console.log('setActiveMenu arrUrl :: ',arrUrl)
    this.sidebarmenu.forEach((e: any, i: any) => {
      console.log('setActiveMenu e.url : ',e.url)
      console.log('setActiveMenu includes : ',arrUrl.includes(e.url))
      if(arrUrl.includes(e.url)){
        this.sidebarmenu[i].isOpen=true
      }
      if(e.url==url){

        // this.sidebarmenu[i].isOpen=true
        this.sidebarmenu[i].isActive=true
      } 
      // else {
      //   this.sidebarmenu[i].isOpen=false
      //   this.sidebarmenu[i].isActive=false
      // }

      e.child.forEach((el:any,id:any) => {
        console.log('el.url : ',el.url)
        if(arrUrl.includes(el.url)){
          this.sidebarmenu[i].child[id].isOpen=true
        }
        if(el.url==url){
          // this.sidebarmenu[i].isOpen=true
          // this.sidebarmenu[i].child[id].isOpen=true
          this.sidebarmenu[i].child[id].isActive=true
        } 
        // else {
        //   this.sidebarmenu[i][id].isOpen=false
        //   this.sidebarmenu[i][id].isActive=false
        // }

        el.child.forEach((elm:any,idx:any) => {
          console.log('elm.url : ',elm.url)

          if(arrUrl.includes(elm.url)){
            this.sidebarmenu[i].child[id].child[idx].isOpen=true
          }

          if(elm.url==url){
            // this.sidebarmenu[i].isOpen=true
            // this.sidebarmenu[i].child[id].isOpen=true
            // this.sidebarmenu[i].child[id].child[idx].isOpen=true
            this.sidebarmenu[i].child[id].child[idx].isActive=true
          } 
          // else {
          //   this.sidebarmenu[i][id][idx].isOpen=false
          //   this.sidebarmenu[i][id][idx].isActive=false
          // }
        });

      });
    });

    console.log('sidebarmenu : ',this.sidebarmenu)
    this.getBreadcrum()
}

  toggleAcc() {
    // Solution 1: Provide panel id and toggle
    this.acc.toggle('detailAcc');
    //console.log(this.acc.panels.map(x => x.id));

    // Solution 2: Iterate panels array to get panel id and toggle
    // for (let panelId of this.acc.panels.map((x:any) => x.id)) {
    //   this.acc.toggle(panelId);
    // }
  }

  checkParent(id:any){
    console.log('checkParent id : ',id)
    if(id==2 || id==22 || id == 222 ){
      return true
    } else {
      return false
    }
  }
  isMenuOpen(menu:string){ 
    // menu = menu / submenu / childsubmenu
    var id = 0
    this.sidebarmenu.forEach((e:any) => {
      if(menu=='menu' && e.isOpen==true){
        id=e.id
      }
      e.child.forEach((el:any) => {
        if(menu=='submenu' && el.isOpen==true){
          id=el.id
        }
        el.child.forEach((elm:any) => {
          if(menu=='childsubmenu' && elm.isOpen==true){
            id=elm.id
          }
        });
      });
    });
    return id
  }

  getBreadcrum(){
    //var idOpen = this.getActiveMenu()
    //console.log('getActiveMenu : ',idOpen)
    var arrBreadcrum: any[] = []
    this.breadcrumb = []
    console.log('getBreadcrum sidebarmenu :: ',this.sidebarmenu)
    //if(idOpen){
      this.sidebarmenu.forEach((e:any) => {
        if(e.isOpen==true){
          arrBreadcrum.push({'text':e.text,'url':e.url})
        }
        e.child.forEach((el:any) => {
          if(el.isOpen==true){
            arrBreadcrum.push({'text':e.text,'url':e.url})
            arrBreadcrum.push({'text':el.text,'url':el.url})
          }
          el.child.forEach((elm:any) => {
            if(elm.isOpen==true){
              arrBreadcrum.push({'text':e.text,'url':e.url})
              arrBreadcrum.push({'text':el.text,'url':el.url})
              arrBreadcrum.push({'text':elm.text,'url':elm.url})
            }
          });
        });
      });
    //}
    this.breadcrumb = arrBreadcrum

  }

  getActiveMenu(){
      var activeMenu = null
      console.log('getActiveMenu : ',this.getActiveMenu)
      this.sidebarmenu.forEach((e:any) => {
        if(e.isActive==true){
          activeMenu = e.url
        }
        e.child.forEach((el:any) => {
          if(el.isActive==true){
            activeMenu = el.url
          }
          el.child.forEach((elm:any) => {
              if(elm.isActive==true){
                activeMenu = elm.url
              }
          });
        });
      });
      return activeMenu
  }

  openAccordion(){
    console.log('this.acc ',this.acc)
    console.log('this.accsub ',this.accsub)
    console.log('this.accchildsub ',this.accchildsub)
    //var activeMenu = this.getActiveMenu()
  //   setTimeout(() => {
  //     console.log('this.acc ',this.acc)
  //     console.log('this.accsub ',this.accsub)
  //     console.log('this.accchildsub ',this.accchildsub)

  //     this.acc.toggle('toggle-2');
  //     this.accsub.toggle('toggle-22');
  //     this.accchildsub.toggle('toggle-222');
  //  }, 4000);


    // this.sidebarmenu.forEach((e:any) => {
    //   if(e.isOpen){
    //     this.acc.toggle('toggle-'+e.id+'');
    //   }
    //   e.child.forEach((el:any) => {
    //     if(el.isOpen){
    //       this.accsub.toggle('toggle-'+el.id+'');
    //     }
    //     el.child.forEach((elm:any) => {
    //       if(elm.isOpen){
    //         this.accchildsub.toggle('toggle-'+elm.id+'');
    //       }
    //     })
    //   });
    // });




    // if(activeMenu){
    //   var ArrParent1:any = []
    //   var ArrParent2:any = []
    //   var parent1 = null
    //   var parent2 = null

    //   //get all parent
    //   this.sidebarmenu.forEach((e:any) => {
    //     ArrParent1.push(e.id)
    //     e.child.forEach((el:any) => {
    //       ArrParent2.push(el.id)
    //       if(el.id==activeMenu){
    //         parent1 = el.id
    //       }
    //       el.child.forEach((elm:any) => {
    //         if(elm.id==activeMenu){
    //           parent2 = el.id
    //         }
    //       })
    //     });
    //   });
    //   console.log('parent1 : ',parent1)
    //   console.log('parent2 : ',parent2)
    //   if(parent1){
    //     this.acc.toggle('toggle-'+parent1+'');
    //     this.accsub.toggle('toggle-'+activeMenu+'');
    //   }
    //   if(parent2){
    //     this.accsub.toggle('toggle-'+parent2+'');
    //     this.accchildsub.toggle('toggle-'+activeMenu+'');
    //   }
    //   if(parent1!==null && parent2!==null){
    //     this.acc.toggle('toggle-'+activeMenu+'');
    //   }
      
    // }
    // this.acc.toggle('toggle-2');
    // // this.accsub.toggle('toggle-22');
    // // this.accchildsub.toggle('toggle-222');
  }

  helpSurvey(){
    const modalRef = this.modalService.open(PopuphelpsurveyComponent, {
      modalDialogClass: '',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'md',
      windowClass: 'ngmodalAddMenu-modal'
    })
    // modalRef.componentInstance.url = window.location.href

    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res){
       this.submitted=true
      } 
    })

  }


  successHelp(){
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered helpsuccess',
      centered: true,
      backdrop : 'static',
      keyboard : false,
      size: 'sm'
    })
    modalRef.componentInstance.modalhelp = 'info'
    modalRef.componentInstance.infoTitle = 'Terima Kasih' 
    modalRef.componentInstance.infoSuccess = 'Masukan Anda telah kami terima. Kami senang jika dapat membantu Anda.'
    modalRef.componentInstance.infoFailed = null
    modalRef.componentInstance.closeTxt = 'Tutup'

    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res){
       this.submitted=true
      } 
    })
  }


  ngAfterViewInit() {
    //should call after get api, active id, for demo ux, put in ngAfterViewInit
    this.openAccordion()
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    location.reload()
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/_services/navigation';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor(
    private navigation: NavigationService
  ) { }

  ngOnInit(): void {
  }
  back(): void {
    this.navigation.back()
  }
}

<div class="container">

    <div class="vh-100">
        <div class="row align-items-center h-100">
            <div class="col-md-12">
                <div class="text-center">
                    <img src="/assets/images/404-not-found 2.png" class="img-fluid">
                </div>
                <div class="text-center">
                    <h3 class="mt-3">{{'not_found_title' | translate}}</h3>
                    <p>{{'not_found_text' | translate}}</p>
                    <a class="btn btn-outline-secondary fhbtn1" (click)="back()"><strong>{{'not_found_btn' | translate}}</strong></a>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="mt-5 bg-secondary bg-gradient rounded-3 pt-5 pb-5 mb-4 text-center">
        <h1 class="text-light bi bi-exclamation-diamond" style="font-size: 80px;"></h1>
        <h1 class="text-light">404 Not Found</h1>
    </div>    
    <div class="text-center">
        <a class="btn btn-outline-secondary" (click)="back()"><strong>Back</strong></a>
    </div> -->

</div>
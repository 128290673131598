<app-header *ngIf="!isLogin"></app-header>
<div *ngIf="!isLogin" id="page-content-wrapper">
    <div class="page-content inset {{(isHomePage)?'fhcontentHome':'fhcontent'}} {{(isSearchPage)?'':'homePadding'}}">
      <router-outlet></router-outlet>
    </div>
</div>

<app-memberheader *ngIf="isLogin"></app-memberheader>
<div *ngIf="isLogin" id="page-content-wrapper">
    <div class="page-content inset {{(isHomePage)?'fhcontentHome':'fhcontent'}}">
      <router-outlet></router-outlet>
    </div>
</div>

<a id="totop" class="ps-1 pe-1 {{showtotop?'show':''}}" (click)="gototop()"><h3 class="bi bi-arrow-up-short mb-0"></h3></a>

<app-footer></app-footer>
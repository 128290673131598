import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { Books } from 'src/app/models/books';
 
// const Get_MyBooks = gql`
// query{
//     books {
//       title
//       author
//     }
// }
// `;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  valSearch:string=''
  invalidSearch:boolean=false

  allBooks:Books[] = [];

  public getScreenWidth: any;
  isMobile:boolean=false

  constructor(
    private router: Router,
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    // this.apollo.watchQuery<any>({
    //   query: Get_MyBooks
    // })
    // .valueChanges
    // .subscribe(({data, loading}) => {
    //   console.log(loading);
    //   this.allBooks = data.books;
    //   console.log('xxx',this.allBooks);
    // })
    
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
    
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if(this.getScreenWidth<575){
      this.isMobile=true
    } else {
      this.isMobile=false
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }


  globalSearch(){
    var keyword = encodeURIComponent(this.valSearch)

    var checkKeyword = this.valSearch.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }
    window.location.href = '/search/'+keyword;
    //this.router.navigate(['/search/'+keyword])

  }

}

<header *ngIf="!PageLoading && !isHomePage" class="bg-white fixed-top fhgrad">
  <ng-container *ngIf="!isMobile">
  <div class="container fhmenu">
    <div class="row align-self-center divmenufh m-0 g-0">
      <div class="col-sm-4 col-md-3 col-lg-3">
        <a href="{{getcompanyName==''?'/':'/dashboard'}}"><img class="img-fluid fh-logo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>
        <!-- <a href="{{getcompanyName==''?'/':redirectDashboardUrl()}}"><img class="img-fluid fh-logo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a> -->
      </div>
      <div class="col-sm-4 col-md-6 col-lg-6 text-center align-self-center">
        <div class="btn-group w-100 {{invalidSearch?'mt-4':''}} ps-2 pe-2" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <!-- <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle fhsearchdropbtn fhbtn2"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{'menu.select_module' | translate}}
            </button> -->
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
            </ul>
          </div>
          <input type="text" class="form-control searchinput f1" placeholder="{{'menu.search' | translate}}" aria-label="Search" aria-describedby="btnGroupAddon" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}">
          <button type="button" class="btn btn-primary  align-self-center fhsearchiconbtn" (click)="globalSearch()"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>

        </div>
        <small *ngIf="invalidSearch"><font class="text-danger invSearch">Please type keyword minimum 3 character.</font></small>
      </div>
      <div
        class="col-sm-4 col-md-3 col-lg-3 text-end align-self-center justify-content-md-end justify-content-sm-end">

        <form class="">
          <div class="dropdown">
            <div class="row p-0 m-0">
              <div class="col-0 col-lg-2"></div>
              <div class="col-2 ps-0 pe-0">
                <div class="text-center pt-1">
                  <img class="rounded-circle pointer avatar"  src="{{companyLogo}}" appImgFallback="/assets/images/default-company.jpeg" (click)="open()">
                </div>
              </div>
              <div class="col-8 col-lg-6 text-start "  (click)="open()">
                <font class="fhc2 pointer" [hidden]="getcompanyName==''">
                  <b class="limitTxt">{{limitText(getcompanyName)}}</b><br><b class="limitTxt">{{limitText(userdata.fullname)}}</b>
                </font>
              </div>
              <div class="col-2 ps-0 pe-0">


                <div ngbDropdown class="d-inline-block">
                  <button class="btn dropdown-toggle fhc2 ps-0 pe-0" id="dropdownBasic1" ngbDropdownToggle>
                    <img class="fhusericon" src="/assets/images/icons/user@3x.png">
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right fhddmenu">
                    <a ngbDropdownItem class="dropdownbg pointer" href="/setting/company-management"><img src="/assets/images/icons/company_setting-icon-user.jpg" class="fhddicon">Company Management</a>
                    <a ngbDropdownItem class="dropdownbg pointer" href="/profile"><img src="/assets/images/icons/profile-icon-user.jpg" class="fhddicon">Profile</a>
                    <!-- <button ngbDropdownItem class="dropdownbg">Change Password</button> -->
                    <hr>
                    <button ngbDropdownItem (click)="logout()" class="dropdownbg"><img src="/assets/images/icons/logout-icon-user.jpg" class="fhddicon">Logout</button>
                  </div>
                </div>

              </div>
            </div>



          </div>
        </form>

      </div>
    </div>

  </div>

  <nav class=" fhbgmenu">
    <div class="container">
      <!-- <a class="navbar-brand dashicons_menu"><img class="fhusericon" src="/assets/images/icons/dashicons_menu.png"></a> -->

      <div class="row g-0">
        <div class="col-3 ">
          <a class="nav-link active fw-bold pointer ps-0" (click)="hideshowmenu()"><img class="fhdashicon"
              src="/assets/images/icons/dashicons_menu@3x.png"></a>
        </div>
        <div class="col-9 text-end py-2">
          <div class="">



            
            <!-- <div ngbDropdown class="d-inline-block">
              <button class="btn dropdown-toggle fhc2 ps-0 pe-0 flagbtn" id="dropdownBasic1" ngbDropdownToggle>
                <img class="fhusericon" src="/assets/images/country/{{currentlang}}.png">
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right">
                <div><p class="mb-0 ps-2 pe-2 selectlang">{{'menu.select_language' | translate}} :</p></div>
                <button ngbDropdownItem (click)="switchLang('en')" class="dropdownbg selectlang">English</button>
                <button ngbDropdownItem (click)="switchLang('id')" class="dropdownbg selectlang">Indonesia</button>
              </div>
            </div> -->
            


            <a href="/cart" class="text-white fw-bold pointer ps-4 123">
              <img class="fhrighticon" src="/assets/images/icons/clarity_shopping-cart-solid@3x.png">
              <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalCart>0 && totalCart<99">{{totalCart}}</span>
              <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalCart>=100">99+</span>
            </a>
            <a href="/messaging" class="text-white fw-bold pointer ps-4">
              <img class="fhrighticon" src="/assets/images/icons/fluent_mail-16-filled@3x.png">

              <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalNotification>0  && totalNotification<99">{{totalNotification}}</span>
              <span class="badge bg-danger fhnotif bgmessage" *ngIf="totalMessages>0 && totalMessages<99">{{totalMessages}}</span>

              <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalNotification>=100">99+</span>
              <span class="badge bg-danger fhnotif bgmessage" *ngIf="totalMessages>=100">99+</span>

            </a>
            <a href="/procurement/cart" class="text-white fw-bold pointer ps-4 d-none">
              <img class="fhrighticon" src="/assets/images/icons/clarity_shopping-cart-solid@3x.png">
              <!-- <span class="badge bg-danger fhnotif">7</span> -->
            </a>
          </div>

        </div>
      </div>


      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <!-- <div class="collapse navbar-collapse container-fluid ps-0 pe-0" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">

          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold pointer" (click)="hideshowmenu()"><img class="fhusericon"  src="/assets/images/icons/dashicons_menu.png"></a>
          </li>


          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold " href="/">Home</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold " href="/vendor/products">Product</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">RFQ</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">Supliers</a>
          </li>
          <li class="nav-item pe-5">
            <a class="nav-link active fw-bold ">Inbox <span class="badge bg-danger fhnotif">18</span></a>
          </li>
        </ul>

        <div class="d-flex">
          <div class="nav-item">
            <a href="/procurement/cart" class="text-white fw-bold pointer">My Cart <img class="cart2icon" src="/assets/images/icons/icon-cart2.png"><span class="badge bg-danger fhnotif">7</span></a>
          </div>

        </div>

      </div> -->

    </div>
  </nav>
  </ng-container>
  <ng-container *ngIf="isMobile">

    <div class="row g-0">
      <div class="col-1">
        <a class="nav-link active fw-bold pointer ps-0 pt-3 pe-2" (click)="hideshowmenu()"><img class="fhdashicon"
          src="/assets/images/icons/dashicons_menu@3x.png"></a>
      </div>
      <div class="col-6 ps-0 pe-0">
        <a href="{{getcompanyName==''?'/':'/dashboard'}}"><img class="img-fluid pt-2" style="max-width:178.4px;width:100%" src="/assets/images/logofh-cepetdapet.png"></a>
      </div>

      <div class="col-5 px-0 text-center">

        <table class="w-100">
          <tr>
            <td>
              <div class="pt-3 pe-2 text-end">
                <img class="rounded-circle pointer avatar"  src="{{companyLogo}}" appImgFallback="/assets/images/default-company.jpeg" (click)="open()">
              </div>
            </td>
            <td>
              <div ngbDropdown class="d-inline-block  pt-3">
                <button class="btn dropdown-toggle fhc2 p-0" id="dropdownBasic1" ngbDropdownToggle>
                  <img class="fhusericon" src="/assets/images/icons/user@3x.png">
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right fhddmenu">
                  <a ngbDropdownItem class="dropdownbg pointer" href="/setting/company-management"><img src="/assets/images/icons/company_setting-icon-user.jpg" class="fhddicon">Company Management</a>
                  <a ngbDropdownItem class="dropdownbg pointer" href="/profile"><img src="/assets/images/icons/profile-icon-user.jpg" class="fhddicon">Profile</a>
                  <!-- <button ngbDropdownItem class="dropdownbg">Change Password</button> -->
                  <hr>
                  <button ngbDropdownItem (click)="logout()" class="dropdownbg"><img src="/assets/images/icons/logout-icon-user.jpg" class="fhddicon">Logout</button>
                </div>
              </div>
            </td>
            <td>
              <div class="pe-1  pt-3">


                <a href="/cart" class="text-white fw-bold pointer me-2">
                  <img class="fhrighticon" src="/assets/images/icons/clarity_shopping-cart-solid@3x.png">
                    <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalCart>0 && totalCart<99">{{totalCart}}</span>
                    <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalCart>=100">99+</span>
                </a>

              <a href="/messaging" class="text-white fw-bold pointer  pt-3">
                <img class="fhrighticon" src="/assets/images/icons/fluent_mail-16-filled@3x.png">

                <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalNotification>0  && totalNotification<99">{{totalNotification}}</span>
                <span class="badge bg-danger fhnotif bgmessage" *ngIf="totalMessages>0 && totalMessages<99">{{totalMessages}}</span>
  
                <span class="badge bg-danger fhnotif bgnotification" *ngIf="totalNotification>=100">99+</span>
                <span class="badge bg-danger fhnotif bgmessage" *ngIf="totalMessages>=100">99+</span>

              </a>
              </div>
            </td>
          </tr>
        </table>


      </div>
      <div class="col-12 pb-2">

        <div class="btn-group w-100 {{invalidSearch?'':''}} ps-2 pe-2" role="group" aria-label="Button group with nested dropdown">
          <div class="btn-group" role="group">
            <!-- <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle fhsearchdropbtn fhbtn2"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{'menu.select_module' | translate}}
            </button> -->
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
              <li><a class="dropdown-item f1" href="#">Dropdown link</a></li>
            </ul>
          </div>
          <input type="text" class="form-control searchinput f1" placeholder="{{'menu.search' | translate}}" aria-label="Search" aria-describedby="btnGroupAddon" [(ngModel)]="valSearch" (keydown.enter)="globalSearch()" [ngModelOptions]="{standalone: true}">
          <button type="button" class="btn btn-primary  align-self-center fhsearchiconbtn" (click)="globalSearch()"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>

        </div>
        <small *ngIf="invalidSearch"><font class="text-danger invSearch ps-2">Please type keyword minimum 3 character.</font></small>
      </div>

    </div>
    
  </ng-container>
  
</header>

<nav class="navbar  bg-transparent  fixed-top p-0 d-none" *ngIf="isHomePage">
  <div  *ngIf="!isMobile" class="container-fluid divmenufh2 m-lg-4 m-sm-1 p-1 bg-white">

    <a href="{{getcompanyName==''?'/':'/dashboard'}}"><img class="img-fluid fh-logo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>

    <!-- <a href="{{getcompanyName==''?'/':redirectDashboardUrl()}}"><img class="img-fluid fh-logo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a> -->

    <div class="d-flex">
      <div class="p-3 ps-1 pe-1">
        <img class="rounded-circle pointer" style="width: 40px; height: 40px;" src="/assets/images/default-company.jpeg" (click)="open()">
      </div>
      <div class="p-3 ps-1 pe-1">
        <font class="fhc2 pointer" [hidden]="getcompanyName==''">
          <b  (click)="open()">{{limitText(getcompanyName)}}</b><br><b (click)="hideshowmenu()">{{limitText(userdata.fullname)}}</b>
        </font>
      </div>
      
      <button class="navbar-toggler" type="button"> 
      
        <div ngbDropdown display="dynamic" placement="bottom-end">
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button"> 
            <span class="navbar-toggler-icon "><strong><h3 class="bi bi-list menuicon" style="color:#ee7824;"></h3></strong></span>  
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu menufhiconlist">
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"> <i class="pe-3 bi bi-info-circle"></i>About Us</a>
            <div class="dropdown-divider"></div>
            <ngb-accordion #acc="ngbAccordion">
              <ngb-panel>
  
                <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                      <i class="pe-3 bi bi-lightbulb"></i>Solution
                    </div>
                </ng-template>
  
  
                <ng-template ngbPanelContent>
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Procurement</a>
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Vendor</a>   
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-coin"></i>Subscription</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-briefcase"></i>Career</a>
  
          </div>
        </div>
  
      </button>

    </div>

  </div>
  <div *ngIf="isMobile" class="w-100 mobileNav">
    <div class="divmenufh2 container bg-white">
      <div class="row">
        <div class="col-10">
          <a href="{{getcompanyName==''?'/':'/dashboard'}}"><img class="img-fluid fh-logo-mobile pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>
          <!-- <a href="{{getcompanyName==''?'/':redirectDashboardUrl()}}"><img class="img-fluid fh-logo-mobile pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a> -->

          
        </div>
        <div class="col-2 text-end">
          <span>
           <h4 class="bi bi-list menuicon m-2 pointer" (click)="fullMenu()" style="color:#ee7824;"></h4>
          </span>  
        </div>
      </div>
    </div>
  </div>  
</nav>


<div class="fhsidebar d-flex flex-column flex-shrink-0   {{(sidebar) ? 'fhsidebaractive' : ''}}">
  <div class="w-100">
    <div class="row g-0 slideout {{(sidebar) ? 'slidein' : ''}}">
      <div class="col-sm-12 bg-light hfsidebarcontent" style="max-width: 315px;overflow: auto;">
        <div class="d-flex flex-column flex-shrink-0" style="height: 100vh;">
          <div class="bgsidebarhead  pt-4 pb-4">
            <table class="table mt-2 mb-2 m-0">
              <tr>
                <td class="text-end">
                  <h2 class="mt-0 mb-0 pt-0 pb-0 bi bi-person-circle text-white"></h2>
                </td>
                <td class="text-white">
                  <h5 class="p-0 m-0 f1"><strong>Hello, {{userdata?.fullname }}</strong></h5>
                  <h6 class="p-0 m-0 f1l subtitle">{{getcompanyName}} : {{roleName}}</h6>
                </td>
              </tr>
            </table>
          </div>
          <ul class="nav flex-column mb-auto">
            <!-- <li class="nav-item menusidediv pointer" *ngFor="let menu of sidebarmenu; let i = index">
              <a *ngIf="(menu.parent)" href="{{menu.url}}" class="nav-link f1 mt-2 mb-2">
                <img class="sideicon {{ (menu.parent)? 'ps-5':'' }}" src="{{menu.icon}}"> {{menu.text}}
              </a>
            </li> -->
            <ng-container *ngFor="let menu of sidebarmenu; let i = index">
              <li class="nav-item menusidediv pointer {{ arrowmenu(menu.id,menu.parent) }}">
              <a href="{{menu.url}}" class="nav-link f1 mt-2 mb-2" *ngIf="(menu.parent); else Parent">
                <img class="sideicon ps-5" src="{{menu.icon}}"> {{menu.text}}
              </a>
              <ng-template #Parent>
                <a *ngIf="menu.url!==''" href="{{menu.url}}" class="nav-link f1 mt-2 mb-2">
                  <img class="sideicon" src="{{menu.icon}}"> {{menu.text}}
                </a>
                <a *ngIf="menu.url==''" class="nav-link f1 mt-2 mb-2" (click)="hideshowChild(menu.id)">
                  <div class="row">
                    <div class="col-10">
                      <img class="sideicon" src="{{menu.icon}}"> {{menu.text}}
                    </div>
                    <div class="col-2  text-end">
                      <b><i class="bi {{(dispaymenu[menu.id])?'bi-chevron-up':'bi-chevron-down'}}  arrowmenu"></i></b> 
                    </div>
                  </div>
                  
                </a>
              </ng-template>
              </li>
            </ng-container>

            
          </ul>
          <div class="dropdown">
            <ul class="nav flex-column mb-auto">
              <li class="nav-item menusidediv menusidefooter">
                <a (click)="logout()" class="nav-link f1 pointer mt-2 mb-2">
                  <img class="sideicon" src="/assets/images/icons/ic_round-log-out.png"> Log Out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col pt-3 ps-3" (click)="hideshowmenu()">
        <i class="pointer bi bi-x-lg text-white {{(sidebar) ? '' : 'd-none'}}"></i>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0">
      <!-- <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Select Company</h5>
                </div> -->
      <div class="modal-body">

        <div class="pb-4 pt-4 ps-3 pe-3 row g-0">
          <div class="col-sm-12">
            <p [hidden]="notYetSelectCompany" class="text-end closemdl" (click)="ChangeCompany()"><i
                class="bi bi-x-lg colgrey"></i></p>
            <div>

                <div class="text-center">
                  <img src="/assets/images/logofh-cepetdapet.png" class="img-fluid">
                </div>
                <!-- <svg width="153" viewBox="0 0 203 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="203" height="58.2602" rx="29.1301" fill="black" fill-opacity="0.6"/>
                  <path d="M60.98 42H66.38V15.72H60.98V42ZM79.0716 42.504C84.3636 42.504 88.6116 38.364 88.6116 33C88.6116 27.636 84.3636 23.496 79.0716 23.496C73.7796 23.496 69.5316 27.636 69.5316 33C69.5316 38.364 73.7796 42.504 79.0716 42.504ZM79.0716 37.248C76.7316 37.248 74.9316 35.556 74.9316 33C74.9316 30.444 76.7316 28.752 79.0716 28.752C81.4116 28.752 83.2116 30.444 83.2116 33C83.2116 35.556 81.4116 37.248 79.0716 37.248ZM104.978 24V25.836C103.79 24.36 102.062 23.496 99.7219 23.496C94.6099 23.496 90.8659 27.672 90.8659 32.64C90.8659 37.608 94.6099 41.784 99.7219 41.784C102.062 41.784 103.79 40.92 104.978 39.444V41.1C104.978 43.656 103.43 44.952 100.91 44.952C98.5339 44.952 97.4899 43.944 96.8059 42.684L92.1979 45.348C93.8539 48.3 96.9859 49.704 100.73 49.704C105.518 49.704 110.234 47.148 110.234 41.1V24H104.978ZM100.622 36.816C98.0659 36.816 96.2659 35.124 96.2659 32.64C96.2659 30.156 98.0659 28.464 100.622 28.464C103.178 28.464 104.978 30.156 104.978 32.64C104.978 35.124 103.178 36.816 100.622 36.816ZM122.936 42.504C128.228 42.504 132.476 38.364 132.476 33C132.476 27.636 128.228 23.496 122.936 23.496C117.644 23.496 113.396 27.636 113.396 33C113.396 38.364 117.644 42.504 122.936 42.504ZM122.936 37.248C120.596 37.248 118.796 35.556 118.796 33C118.796 30.444 120.596 28.752 122.936 28.752C125.276 28.752 127.076 30.444 127.076 33C127.076 35.556 125.276 37.248 122.936 37.248Z" fill="white"/>
                  </svg>                             -->

              <br>
              <div class="text-center  mb-4">
                <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M52.0833 109.375H72.9167M98.9583 109.375V26.0417C98.9583 23.279 97.8609 20.6295 95.9074 18.676C93.9539 16.7225 91.3043 15.625 88.5417 15.625H36.4583C33.6957 15.625 31.0461 16.7225 29.0926 18.676C27.1391 20.6295 26.0417 23.279 26.0417 26.0417V109.375H98.9583ZM98.9583 109.375H109.375H98.9583ZM98.9583 109.375H72.9167H98.9583ZM26.0417 109.375H15.625H26.0417ZM26.0417 109.375H52.0833H26.0417ZM46.875 36.4583H52.0833H46.875ZM46.875 57.2917H52.0833H46.875ZM72.9167 36.4583H78.125H72.9167ZM72.9167 57.2917H78.125H72.9167ZM52.0833 109.375V83.3333C52.0833 81.952 52.6321 80.6272 53.6088 79.6505C54.5856 78.6737 55.9103 78.125 57.2917 78.125H67.7083C69.0897 78.125 70.4144 78.6737 71.3912 79.6505C72.3679 80.6272 72.9167 81.952 72.9167 83.3333V109.375H52.0833Z"
                    stroke="#174559" stroke-opacity="0.5" stroke-width="11" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>

            </div>
          </div>
          <div class="col-sm-12 mb-4">
            <select [(ngModel)]="ValCompany" class="form-select form-control" id="selectCompany" name="selectCompany">
              <option value=''> - select company - </option>
              <option *ngFor="let company of ArrCompany" [ngValue]="company['companyId']">{{company['companyName']}}
              </option>
            </select>
          </div>
          <div class="col-sm-12">
            <button *ngIf="!confirmLoading" type="button" class="btn btn-secondary w-100 bg1 pt-2 pb-2 fw-bold"
              data-bs-dismiss="modal" (click)="ConfirmCompany()">OK</button>
            <button *ngIf="confirmLoading" class="btn btn-secondary btn-block w-100 bg1 pt-2 pb-2 fw-bold" disabled>
              Loading..
            </button>

          </div>

          <div class="col-md-12 mt-4" *ngIf="emptyCompany">
            <div class="alert alert-warning" role="alert">
              <i class="bi bi-exclamation-circle"></i> Please select company.
            </div>
          </div>

          <div class="col-md-12 mt-4" *ngIf="errChooseCompany!==''">
            <div class="alert alert-danger" role="alert">
              <i class="bi bi-dash-circle"></i> {{errChooseCompany}}
            </div>
          </div>

        </div>

      </div>
      <!-- <div class="modal-footer">

                </div> -->
    </div>
  </div>
</div>


<a id="totop" class="ps-1 pe-1 membertotop bg1 {{showtotop?'show':''}}" (click)="gototop()"><h3 class="bi bi-arrow-up-short mb-0"></h3></a>

<a id="totop" class="ps-1 pe-1 membertotop {{showtotop?'show':''}}" (click)="gototop()">
  <h3 class="bi bi-arrow-up-short mb-0"></h3>
</a>




<ng-template #content let-modal>
  <!-- <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0"> -->
      <!-- <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Select Company</h5>
                </div> -->
      <div class="modal-body">

        <div class="pb-4 pt-4 ps-3 pe-3 row g-0">
          <div class="col-sm-12">
            <!-- <p [hidden]="notYetSelectCompany" class="text-end closemdl" (click)="open()"><i
                class="bi bi-x-lg colgrey"></i></p> -->

            <p [hidden]="notYetSelectCompany" class="text-end closemdl" (click)="modal.dismiss('Cross click')"><i
                class="bi bi-x-lg colgrey"></i></p>

            <div>
              <div class="row">
                <div class="col-6">
                  <div class="text-center">
                    <img src="/assets/images/logofh-cepetdapet.png" class="img-fluid">
                  </div>
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
              </div>

                <!-- <svg width="153" viewBox="0 0 203 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="203" height="58.2602" rx="29.1301" fill="black" fill-opacity="0.6"/>
                  <path d="M60.98 42H66.38V15.72H60.98V42ZM79.0716 42.504C84.3636 42.504 88.6116 38.364 88.6116 33C88.6116 27.636 84.3636 23.496 79.0716 23.496C73.7796 23.496 69.5316 27.636 69.5316 33C69.5316 38.364 73.7796 42.504 79.0716 42.504ZM79.0716 37.248C76.7316 37.248 74.9316 35.556 74.9316 33C74.9316 30.444 76.7316 28.752 79.0716 28.752C81.4116 28.752 83.2116 30.444 83.2116 33C83.2116 35.556 81.4116 37.248 79.0716 37.248ZM104.978 24V25.836C103.79 24.36 102.062 23.496 99.7219 23.496C94.6099 23.496 90.8659 27.672 90.8659 32.64C90.8659 37.608 94.6099 41.784 99.7219 41.784C102.062 41.784 103.79 40.92 104.978 39.444V41.1C104.978 43.656 103.43 44.952 100.91 44.952C98.5339 44.952 97.4899 43.944 96.8059 42.684L92.1979 45.348C93.8539 48.3 96.9859 49.704 100.73 49.704C105.518 49.704 110.234 47.148 110.234 41.1V24H104.978ZM100.622 36.816C98.0659 36.816 96.2659 35.124 96.2659 32.64C96.2659 30.156 98.0659 28.464 100.622 28.464C103.178 28.464 104.978 30.156 104.978 32.64C104.978 35.124 103.178 36.816 100.622 36.816ZM122.936 42.504C128.228 42.504 132.476 38.364 132.476 33C132.476 27.636 128.228 23.496 122.936 23.496C117.644 23.496 113.396 27.636 113.396 33C113.396 38.364 117.644 42.504 122.936 42.504ZM122.936 37.248C120.596 37.248 118.796 35.556 118.796 33C118.796 30.444 120.596 28.752 122.936 28.752C125.276 28.752 127.076 30.444 127.076 33C127.076 35.556 125.276 37.248 122.936 37.248Z" fill="white"/>
                  </svg>                             -->

              <br>
              <div class="text-center  mb-4">
                <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M52.0833 109.375H72.9167M98.9583 109.375V26.0417C98.9583 23.279 97.8609 20.6295 95.9074 18.676C93.9539 16.7225 91.3043 15.625 88.5417 15.625H36.4583C33.6957 15.625 31.0461 16.7225 29.0926 18.676C27.1391 20.6295 26.0417 23.279 26.0417 26.0417V109.375H98.9583ZM98.9583 109.375H109.375H98.9583ZM98.9583 109.375H72.9167H98.9583ZM26.0417 109.375H15.625H26.0417ZM26.0417 109.375H52.0833H26.0417ZM46.875 36.4583H52.0833H46.875ZM46.875 57.2917H52.0833H46.875ZM72.9167 36.4583H78.125H72.9167ZM72.9167 57.2917H78.125H72.9167ZM52.0833 109.375V83.3333C52.0833 81.952 52.6321 80.6272 53.6088 79.6505C54.5856 78.6737 55.9103 78.125 57.2917 78.125H67.7083C69.0897 78.125 70.4144 78.6737 71.3912 79.6505C72.3679 80.6272 72.9167 81.952 72.9167 83.3333V109.375H52.0833Z"
                    stroke="#174559" stroke-opacity="0.5" stroke-width="11" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>

            </div>
          </div>
          <div class="col-sm-12 mb-4">
            <select [(ngModel)]="ValCompany" class="form-select form-control" id="selectCompany" name="selectCompany">
              <option value=''> - select company - </option>
              <option *ngFor="let company of ArrCompany" [ngValue]="company['companyId']">{{company['companyName']}}
              </option>
            </select>
          </div>
          <div class="col-sm-12">
            <button *ngIf="!confirmLoading" type="button" class="btn btn-secondary w-100 bg1 pt-2 pb-2 fw-bold"
              data-bs-dismiss="modal" (click)="ConfirmCompany()">OK</button>
            <button *ngIf="confirmLoading" class="btn btn-secondary btn-block w-100 bg1 pt-2 pb-2 fw-bold" disabled>
              Loading..
            </button>

          </div>

          <div class="col-md-12 mt-4" *ngIf="emptyCompany">
            <div class="alert alert-warning" role="alert">
              <i class="bi bi-exclamation-circle"></i> Please select company.
            </div>
          </div>

          <div class="col-md-12 mt-4" *ngIf="errChooseCompany!==''">
            <div class="alert alert-danger" role="alert">
              <i class="bi bi-dash-circle"></i> {{errChooseCompany}}
            </div>
          </div>

        </div>

      </div>
      <!-- <div class="modal-footer">

                </div> -->
    <!-- </div>
  </div> -->
</ng-template>  
import { Injectable } from '@angular/core';
import {Query,Mutation, gql, Apollo} from 'apollo-angular';
import { Subject } from 'rxjs';

const getHelpMainMenu = gql`
query {
    getHelpMainMenu {
        helpSideMenuId
        menuName
        slugUrl
        logoUrl
    }
}
`;

const getHelpSideMenu = gql`
query {
  getHelpSideMenu {
      helpSideMenuId
      menuName
      levelIndex
      tabIndex
      parentId
      slugUrl
      isMainPage
  }
}
`;

const getMenuContentList = gql`
query getMenuContentList($helpMenuSlugUrl:String!){
  getMenuContentList(input: {
      helpMenuSlugUrl: $helpMenuSlugUrl
  }) {
      helpContentId
      helpSideMenuId
      title
      content
      url
  }
}
`;

const getMenuContentDetail = gql`
query getMenuContentDetail($helpContentSlugUrl:String!){
  getMenuContentDetail(input: {
      helpContentSlugUrl: $helpContentSlugUrl
  }) {
      helpContentId
      helpSideMenuId
      title
      content
      url
  }
}

`;

const searchContentList = gql`
query searchContentList($keyword:String!){
  searchContentList(input: {
      keyword: $keyword
  }) {
      helpContentId
      helpSideMenuId
      title
      content
      url
  }
}
`;


@Injectable({ providedIn: 'root' })

export class HelpService{
    getHelpMainMenuObs = new Subject();
    getHelpSideMenuObs = new Subject();
    getMenuContentListObs = new Subject();
    getMenuContentDetailObs = new Subject();
    searchContentListObs = new Subject();

    constructor(private apollo: Apollo) {}

    HelpMainMenu() {
        this.apollo
        .mutate({
          mutation: getHelpMainMenu,
        //   variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getHelpMainMenuObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getHelpMainMenuObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getHelpMainMenu() {
        this.getHelpMainMenuObs = new Subject();
        this.HelpMainMenu();
        return this.getHelpMainMenuObs.asObservable();
    }

    HelpSideMenu() {
        this.apollo
        .mutate({
          mutation: getHelpSideMenu,
        //   variables: {limit: limit, offset: offset},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getHelpSideMenuObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getHelpSideMenuObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getHelpSideMenu() {
        this.getHelpSideMenuObs = new Subject();
        this.HelpSideMenu();
        return this.getHelpSideMenuObs.asObservable();
    }


    MenuContentList(helpMenuSlugUrl:string) {
        this.apollo
        .mutate({
          mutation: getMenuContentList,
          variables: {helpMenuSlugUrl: helpMenuSlugUrl},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getMenuContentListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getMenuContentListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getMenuContentList(helpMenuSlugUrl:string) {
        this.getMenuContentListObs = new Subject();
        this.MenuContentList(helpMenuSlugUrl);
        return this.getMenuContentListObs.asObservable();
    }

    MenuContentDetail(helpContentSlugUrl:string) {
        this.apollo
        .mutate({
          mutation: getMenuContentDetail,
          variables: {helpContentSlugUrl: helpContentSlugUrl},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.getMenuContentDetailObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.getMenuContentDetailObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getMenuContentDetail(helpContentSlugUrl:string) {
        this.getMenuContentDetailObs = new Subject();
        this.MenuContentDetail(helpContentSlugUrl);
        return this.getMenuContentDetailObs.asObservable();
    }

    searchContentList(keyword:string) {
        this.apollo
        .mutate({
          mutation: searchContentList,
          variables: {keyword: keyword},
          errorPolicy: 'all',
        })
        .subscribe(
          (res:any) => {
            this.searchContentListObs.next(res);
            console.log("succes, get data:", res);
          },
          (error) => {
            this.searchContentListObs.next(error)
            console.log("there was an error sending the query", error);
          }
        );
    }

    getsearchContentList(keyword:string) {
        this.searchContentListObs = new Subject();
        this.searchContentList(keyword);
        return this.searchContentListObs.asObservable();
    }
    
    
}
<div ngbAutofocus>

    <div id="modalCd" class="modal-dialog modal-lg modal-dialog-centered m-0">
        <div class="modal-content  rounded-4 border-0">
            <div class="modal-header bghsurvey rounded-0  text-center">
                <h4 class="text-white f1 mb-0 w-100"><strong>Berikan Kami Saran</strong></h4>
                <!-- <button type="button" class="btn text-white ps-0 pe-0" data-bs-dismiss="modal" (click)="activeModal.close();"><i class="bi bi-x-lg"></i></button> -->
            </div>
            <div class="modal-body filterdiv p-5" *ngIf="!getResponse">
                <div class="text-center">
                    <img src="/assets/images/icons/helpsurvey01.png" class="img-fluid" style="max-width: 120px;">
                    <p class="my-3">Maaf jika artikel kami kurang membantu. Beri tahu bagaimana kami bisa membantu Anda dengan lebih baik:</p>
                </div>

                <div class="ps-4 pe-4">

                <p class="mb-2"><b>Deskripsi</b></p>

                    <textarea class="form-control" row="4"  style="height:100%;min-height: 100px;" maxlength="500" placeholder="Tulisakan catatan"  [(ngModel)]="valMessage" [ngModelOptions]="{standalone: true}"  [disabled]="processMsg" (ngModelChange)="messageChange()"></textarea>
                    <div class="text-center mt-3 mb-2">

                        
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn  hcancelbtn text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close();">Close</button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn  hsendbtn text-white pe-5 ps-5 text-uppercase fsmall" (click)="submitMessage()" [disabled]="isInvalid"  id="mtmHelpFeedbackBtnNo">{{processMsg?'Loading...':'Kirim'}}</button>
                    </div>
                </div>

                        

                        
                    </div>
                </div>
            </div>
            <div  *ngIf="getResponse">
                <div class="text-center mt-3 mb-4">
                    <div class="m-4">
                        <p><font [innerHtml]="ResponseText"></font></p>
                    </div>



                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="close()">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
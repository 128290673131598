<div class="">

    <div class="container">

        <!-- Breadcrumb -->
        <div class="d-none d-sm-block">
            <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
            <div class="row">
                <div class="col-sm-12">
                <div class="shbreadcum">
                    <table>
                    <tr>
                        <td class="separator">
                        <h6 class="pt-2 mt-1 me-3">
                            <strong>
                            <a href="/" class="c1 pointer">
                                <img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back
                            </a>
                            </strong>
                        </h6>
                        </td>
                        <td>
                        <h6 class="mb-1 mt-2 ms-3">
                            <strong class="c1">
                            <font class="text-muted">My Cart</font>
                            </strong>
                        </h6>
                        </td>
                    </tr>
                    </table>
                </div>
                </div>
                <!-- <div class="col-sm-5">
                </div> -->
            </div>
            </div>
        </div>
        <div class="d-block d-sm-none">
        <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
            <div class="row">
                <div class="col-10">
                <div class="pt-1">
                    <strong class="c1">
                    <font class="c2">My Cart</font>
                    </strong>
                </div>

                </div>
                <div class="col-2 text-end">
                </div>
            </div>
        </div>
        </div>


        <div class="mt-0">

            <!-- Saat page loading -->
            <div *ngIf="PageLoading">
              <div class="text-center pt-5 pb-5">
                <div class="loader loader--style8" title="7">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
                    style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                    <rect x="8" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                    <rect x="16" y="10" width="4" height="10" fill="#333" opacity="0.2">
                      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                      <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                    </rect>
                  </svg>
                </div>
              </div>
            </div>
      
            <!-- Saat page tidak loading -->
            <div class="pb-5" *ngIf="!PageLoading">
      
                <div class="card divrfqlist mt-4" *ngIf="dataCart.length>0">
                    <div class="card-body">
                        <h3 class="mb-3 mt-2">Items in My Cart</h3>

                        
                          <div class="form-check ms-3 my-3">
                            <input class="form-check-input" type="checkbox" [checked]="valIsCheckedAll"
                              (click)="checkAll()" id="selectall">
                            <label class="form-check-label pointer" (click)="checkAll()" for="flexCheckDefault">
                              <strong>Select All</strong>
                            </label>
                          </div>

                        <ng-container *ngFor="let item of dataCart; let i = index">
                          <div class="card cartList p-3 mb-3 ps-1 ps-sm-3">
                              <div class="d-none d-sm-block">
                                  <div class="row">
                                      <div class="col-2">
                                          <!-- <img class="img-fluid" src="/assets/images/notfound.png"> -->


                                          <table>
                                            <tr>
                                              <td>
                                                <div class="form-check">
                                                  <input class="form-check-input" type="checkbox"  id="checkbox_mobile_{{i}}" (click)="onCheckCart(item.cartId,tm.checked)" #tm
                                                  [checked]="setCheckBox(item.cartId)">
                                                </div>
                                              </td>
                                              <td>

                                                <img class="img-fluid" *ngIf="item?.imageUrl.length==0" src="/assets/images/notfound.png">

                                                <div *ngIf="item?.imageUrl.length>0" id="carouselControls{{i}}" class="carousel slide" data-bs-ride="carousel"  data-bs-interval="false">
                                                  <!-- <div class="carousel-inner">
                                                      <div class="carousel-item" *ngFor="let img of item?.imageUrl; let x = index">
                                                        <img [src]="img" class="d-block w-100" alt="{{item.productName}}">
                                                      </div>           
                                                  </div> -->
      
                                                    <div class="carousel-inner">
      
                                                      <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" id="cartGallery{{i}}">
      
                                                        <ng-container *ngFor="let img of item?.imageUrl; let x = index">
                                                          <div class="carousel-item {{x==0?'active':''}}">
      
                                                            <a class="itemz" [attr.href]="img">
                                                              <img [src]="img" class="d-block w-100" alt="{{item.productName}}">
                                                            </a>
      
                                                          </div>  
                                                        </ng-container>
                                                      </lightgallery>
                                                    </div>
                                                  
      
      
                                                  <button *ngIf="item?.imageUrl.length>1" class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carouselControls'+ i" data-bs-slide="prev">
                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Previous</span>
                                                  </button>
                                                  <button *ngIf="item?.imageUrl.length>1" class="carousel-control-next" type="button" [attr.data-bs-target]="'#carouselControls'+ i" data-bs-slide="next">
                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Next</span>
                                                  </button>
                                                </div>
                                              </td>
                                            </tr>
                                          </table>

                                      </div>
                                      <div class="col-8">
                                          <a target="_blank" href="/product/{{item.companyDomain}}/{{item.productSlugUrl}}"> 
                                            <h4>{{item.productName}}</h4>
                                          </a>               
                                          <table>
                                              <tr>
                                                  <td style="min-width: 100px;">Price</td>
                                                  <td class="px-2">:</td>
                                                  <td>{{item.currency}} {{item.productPrice | number}}</td>
                                              </tr>
                                              <tr>
                                                  <td style="min-width: 100px;">Vendor</td>
                                                  <td class="px-2">:</td>
                                                  <td>{{item.companyDomain}}</td>
                                              </tr>
                                          </table>
  
                                      </div>
                                      <div class="col-2 position-relative">
                                          <div class="px-2 position-absolute bottom-0 end-0">
                                              <div class="row g-2 justify-content-center wact">
                                                  <div class="col-4">

                                                    <a target="_blank" href="/product/{{item.companyDomain}}/{{item.productSlugUrl}}"> 
                                                      <img src="/assets/images/icons/product-view@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </a>

                                                  </div>
                                                  <div class="col-4">
                                                    <img (click)="delete(item)" src="/assets/images/icons/product-delete@3x.png" class="iconuser ms-2 me-1 pointer">
                                                  </div>
                                                </div> 
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="d-block d-sm-none">
                                <table>
                                  <tr>
                                    <td>
                                      <div class="form-check">
                                        <input class="form-check-input" type="checkbox"  id="checkbox_{{i}}" (click)="onCheckCart(item.cartId,t.checked)" #t
                                        [checked]="setCheckBox(item.cartId)">
                                      </div>
                                    </td>
                                    <td>

                                      <div class="row">
                                        <div class="col-3">
                                            <!-- <img class="img-fluid" src="/assets/images/notfound.png"> -->
                                            <img class="img-fluid" *ngIf="item?.imageUrl.length==0" src="/assets/images/notfound.png">

                                            <div *ngIf="item?.imageUrl.length>0" id="carouselControls_mobile{{i}}" class="carousel slide" data-bs-ride="carousel"  data-bs-interval="false">
                                              <!-- <div class="carousel-inner">
                                                  <div class="carousel-item" *ngFor="let img of item?.imageUrl; let x = index">
                                                    <img [src]="img" class="d-block w-100" alt="{{item.productName}}">
                                                  </div>           
                                              </div> -->
  
                                                <div class="carousel-inner">
  
                                                  <lightgallery [settings]="settingsGallery" [onInit]="onInitGallery" id="cartGallery_mobile{{i}}">
  
                                                    <ng-container *ngFor="let img of item?.imageUrl; let x = index">
                                                      <div class="carousel-item {{x==0?'active':''}}">
  
                                                        <a class="itemz" [attr.href]="img">
                                                          <img [src]="img" class="d-block w-100" alt="{{item.productName}}">
                                                        </a>
  
                                                      </div>  
                                                    </ng-container>
                                                  </lightgallery>
                                                </div>
                                              
  
  
                                              <button *ngIf="item?.imageUrl.length>1" class="carousel-control-prev" type="button" [attr.data-bs-target]="'#carouselControls_mobile'+ i" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                              </button>
                                              <button *ngIf="item?.imageUrl.length>1" class="carousel-control-next" type="button" [attr.data-bs-target]="'#carouselControls_mobile'+ i" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                              </button>
                                            </div>
  
  
                                        </div>
                                        <div class="col-9">
                                            <a target="_blank" href="/product/{{item.companyDomain}}/{{item.productSlugUrl}}"> 
                                              <h4>{{item.productName}}</h4>
                                            </a>
                                        </div>
                                        <div class="col-9">
                                            <table>
                                                <tr>
                                                    <td style="min-width: 70px;">Price</td>
                                                    <td class="px-2">:</td>
                                                    <td>{{item.currency}} {{item.productPrice | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="min-width: 70px;">Vendor</td>
                                                    <td class="px-2">:</td>
                                                    <td>{{item.companyDomain}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-3 position-relative">
                                            <div class="px-2 position-absolute bottom-0 end-0">
                                                <div class="row g-2 justify-content-center wact">
                                                    <div class="col-6">
  
                                                      <a target="_blank" href="/product/{{item.companyDomain}}/{{item.productSlugUrl}}"> 
                                                        <img src="/assets/images/icons/product-view@3x.png" class="iconuser ms-2 me-1 pointer">
                                                      </a>
  
                                                    </div>
                                                    <div class="col-6">
                                                      <img src="/assets/images/icons/product-delete@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </div>
                                                  </div> 
                                            </div>
                                        </div>
                                    </div>  

                                    </td>
                                  </tr>
                                </table>
                                
                                  
                              </div>
                          </div>


                      </ng-container>



                        <!-- <ng-container *ngFor="let item of items; let i = index">
                            <div class="card cartList p-3 mb-3">
                                <div class="d-none d-sm-block">
                                    <div class="row">
                                        <div class="col-2">
                                            <img class="img-fluid" src="/assets/images/notfound.png">
                                        </div>
                                        <div class="col-8">
                                            <h4>Good Brand BE489WB CAM 622 Encode Deadbolt Smart Lock | WiFi Touchscreen Keypad (Matte Black)</h4>
                                            <table>
                                                <tr>
                                                    <td style="min-width: 100px;">Price</td>
                                                    <td class="px-2">:</td>
                                                    <td>IDR 999,000</td>
                                                </tr>
                                                <tr>
                                                    <td style="min-width: 100px;">Vendor</td>
                                                    <td class="px-2">:</td>
                                                    <td>Demo Company</td>
                                                </tr>
                                            </table>
    
                                        </div>
                                        <div class="col-2 position-relative">
                                            <div class="px-2 position-absolute bottom-0 end-0">
                                                <div class="row g-2 justify-content-center wact">
                                                    <div class="col-4">
                                                      <img src="/assets/images/icons/product-view@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </div>
                                                    <div class="col-4">
                                                      <img src="/assets/images/icons/product-delete@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </div>
                                                  </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block d-sm-none">
                                    <div class="row">
                                        <div class="col-3">
                                            <img class="img-fluid" src="/assets/images/notfound.png">
                                        </div>
                                        <div class="col-9">
                                            <h4>Good Brand BE489WB CAM 622 Encode Deadbolt Smart Lock | WiFi Touchscreen Keypad (Matte Black)</h4>

    
                                        </div>
                                        <div class="col-8">
                                            <table>
                                                <tr>
                                                    <td style="min-width: 70px;">Price</td>
                                                    <td class="px-2">:</td>
                                                    <td>IDR 999,000</td>
                                                </tr>
                                                <tr>
                                                    <td style="min-width: 70px;">Vendor</td>
                                                    <td class="px-2">:</td>
                                                    <td>Demo Company</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="col-4 position-relative">
                                            <div class="px-2 position-absolute bottom-0 end-0">
                                                <div class="row g-2 justify-content-center wact">
                                                    <div class="col-6">
                                                      <img src="/assets/images/icons/product-view@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </div>
                                                    <div class="col-6">
                                                      <img src="/assets/images/icons/product-delete@3x.png" class="iconuser ms-2 me-1 pointer">
                                                    </div>
                                                  </div> 
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>


    
                            </div>


                        </ng-container> -->
                        <div class="w-100 py-4 my-3 position-relative d-none d-sm-block">
                            <div class="position-absolute bottom-0 end-0">
                                <button class="btn btn-primary fhbgcyan px-4" [disabled]="disableCreateRfq" (click)="createRfq()">Create RFQ {{ SelectedBulk.length>0 ? '('+SelectedBulk.length+')':'' }}</button>
                            </div>
                        </div>
                        <div class="w-100 pb-2 mt-3 position-relative d-block d-sm-none">
                            <div class="d-grid gap-2">
                                <button class="btn btn-primary fhbgcyan px-4 w-100 fw-bold py-2" [disabled]="disableCreateRfq" (click)="createRfq()">Create RFQ {{ SelectedBulk.length>0 ? '('+SelectedBulk.length+')':'' }}</button>
                            </div>
                        </div>
                    </div>
                    
                </div>


                <div class="card divrfqlist mt-4" *ngIf="dataCart.length==0">
                  <div class="card-body">
                    <div class="py-5 px-5 text-center">
                      <h1 class="display-1 bi bi-cart"></h1>
                      <h4 class="mb-3 mt-2">Cart is empty</h4>
                    </div>
                  </div> 
                </div>

            </div>
      
      
          </div>


    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { gql, Apollo } from 'apollo-angular';
import Util from 'src/app/_helpers/util';
import { PopupfeedbackComponent } from 'src/app/components/popupfeedback/popupfeedback.component';

const gettac = gql`
query{
  registrationTermAndCondition{
      id
      version
      description
  }
}
`;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html', 
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  currentlang:any
  valSearch:string=''
  invalidSearch:boolean=false
  loadingSearch:boolean=false

  userdata: any
  getcompany: any
  usercompany: any
  roleName: string = ''
  getcompanyName: any = ''
  getcompanyID: any
  ValCompany: any = ''
  ValCompanyName: any
  ArrCompany = []
  notYetSelectCompany: boolean = false
  singleCompany: boolean = true
  isLoggedin:boolean=false

  roleId:string=''


  constructor(
    public translate: TranslateService,
    private router: Router,
    private apollo: Apollo,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('language');
    console.log('HomepageComponent : ',this.currentlang)
    this.loadProfile()
  }

  switchLang(lang:any) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.currentlang = lang
    //window.location.reload();
  }
  globalSearch(){
    var keyword = encodeURIComponent(this.valSearch)

    var checkKeyword = this.valSearch.length
    console.log('checkKeyword : ',checkKeyword)
    if(keyword.length<3){
      this.invalidSearch=true
      return
    } else{
      this.invalidSearch=false
    }
    this.loadingSearch=true
    window.location.href = '/search/'+keyword;
    //this.router.navigate(['/search/'+keyword])

  }

  loadProfile() {
    var getuserdata = localStorage.getItem("USERDATA")
    if (getuserdata !== null) {
      this.userdata = JSON.parse(getuserdata);
      console.log('getuserdata', this.userdata);
      this.isLoggedin=true
    }

    var getusercompany = localStorage.getItem("USERCOMPANY")
    if (getusercompany !== null) {
      this.usercompany = JSON.parse(getusercompany);
      console.log('getusercompany', this.usercompany);

      //this.roleName = this.getRoleName(this.usercompany.roleMembership)
      this.roleName= Util.getRole(this.usercompany?.roleMembership)
    }

    var company = localStorage.getItem("COMPANYDATA")
    if (company !== null) {
      this.getcompany = JSON.parse(company);
      console.log('element', this.getcompany[0]['companyName']);
    }

    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.getcompanyName = localStorage.getItem("COMPANYSELECTNAME")
      this.getcompanyID = localStorage.getItem("COMPANYSELECTID")
    } else {
      this.notYetSelectCompany = true
    }

    var getcompany = localStorage.getItem("COMPANYDATA")

    if (getcompany !== null) {

      this.ArrCompany = JSON.parse(getcompany);
      console.log(this.ArrCompany);

      if (this.ArrCompany.length > 1) {
        this.singleCompany = false
        console.log('this.singleCompany', this.singleCompany);


        if (localStorage.getItem("COMPANYSELECTID") === null) {
          //this.ChangeCompany() //open modal to choose company
          //this.open()
        }

      } else {
        this.ValCompany = this.ArrCompany[0]['companyId']
        this.ValCompanyName = this.ArrCompany[0]['companyName']
        
        //handle loop refresh location.reload()
        if (localStorage.getItem("COMPANYSELECTID") === null) {
          //this.ConfirmCompany()
        }
        console.log('this.singleCompany', this.singleCompany);
      }
    }

  }
  limitText(val:any){
    var checkChar = val
    if(checkChar.length>11){
      checkChar = checkChar.substring(0,11) + '...'
    }
    return checkChar
  }
}

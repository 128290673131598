<div class="container">



    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">

        <div class="row">
          <div class="col-sm-8">
            <div class="shbreadcum">
              <h6 class="pt-2 mt-1"><strong class="c1">Vendor  >  <a href='/vendor/product-list'><font class="c1">Product List</font></a>  > <font class="c2">Product Detail</font></strong> </h6>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="input-group mb-0 breadcumsearchdiv">
              <input type="text" class="form-control breadcumsearchinput" placeholder="Search products..." aria-label="Recipient's username" aria-describedby="button-addon2">
              <button type="button" class="btn btn-primary breadcumsearchbox align-self-center fhbtn2"><img class="fhsearchicon" src="/assets/images/icons/ic_Search@3x.png"></button>
            </div>
          </div>
        </div>
    </div>
    

    <form>
        <div class="container pb-5 divshbreadcum">
            <div class="row pb-5">

                <div class="col-sm-12 mt-0 pt-3"></div>




                


                <div class="col-md-12">
                    <div class="row">

                        <div class="col-sm-3">
            
                            <div class="row  g-0">
                                <div class="col-sm-12 col-md-12 col-lg-12 ps-1 pe-1 pt-1 pb-1">
                                    <div class="text-center bg-light border rounded-0  thumbimgdiv1"> 
                                        <h2 class="bi bi-plus-lg text-secondary thumbimg"></h2>
                                    </div>
                                </div>
                
                
                                <div class="col-sm-12 col-md-4 col-lg-4 ps-1 pe-1 pt-1 pb-1" *ngFor="let MoreImg of MoreImgCount; let i = index">
                
                                    <div class="text-center bg-light border rounded-0  thumbimgdiv2">
                                        <p class="bi bi-plus-lg text-secondary thumbimgsec"></p>
                                    </div>
                                    
                                </div>
                    
                
                            </div>
                
                
                            
                        </div>
                        <div class="col-sm-9">
                

                            <table class="table">
                                <tr>
                                    <td colspan="3" width="85%">
                                        <h3 class="mb-0 p-0"><strong>{{Valname}}</strong></h3>
                                    </td>
                                    <td width="15%">
                                        <img class="wishicon" src="/assets/images/icons/icon-wishlist.jpg">
                                    </td>
                                </tr>
                                <tr>
                                  <td colspan="4">
                                    <svg class="bd-placeholder-img rounded-circle" width="45px" height="45px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#c4c4c4"></rect></svg> Vendor Name <img class="rankicon" src="/assets/images/icons/icon-gold.jpg">
                                  </td>
                                </tr>
                                <tr>
                                    <td width="25%">Brand</td>
                                    <td width="5%">:</td>
                                    <td width="70%">{{Valbrand}}</td>
                                </tr>
                                <tr>
                                    <td>Product Code</td>
                                    <td>:</td>
                                    <td>{{Valsku}}</td>
                                </tr>
                                <tr>
                                    <td>Manf Code</td>
                                    <td>:</td>
                                    <td>{{ValmanufacturerSku}}</td>
                                </tr>

                                <tr>
                                    <td><br></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <!-- <tr>
                                    <td>Tag</td>
                                    <td>:</td>
                                    <td>

                                    <span class="badge rounded-pill bg-secondary me-1 bgtags"  *ngFor="let tags of Valtags; let i = index">{{ tags?.tag }}
                                    </span>

                                    </td>
                                </tr>
                                <tr>
                                    <td>Price</td>
                                    <td>:</td>
                                    <td><h4 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong>{{Valprice | number}}</strong></h4></td>
                                </tr>
                                <tr>
                                    <td>Min. Price</td>
                                    <td>:</td>
                                    <td><h4 class="mb-0 pt-0 pb-0 ps-0 pe-0"><strong>{{ValautobidMinimumPrice | number}}</strong></h4></td>
                                </tr>
                                <tr>
                                    <td>Display Price</td>
                                    <td>:</td>
                                    <td>{{(ValisDisplayPriceOnShopfront ? 'YES':'NO')}}</td>
                                </tr> -->

                                <tr>
                                    <td colspan="4">
                                        <small><strong class="p-0">Product Rating</strong></small>
                                        <br>
                                        <h2><strong class="p-0">4.8</strong><img class="starticon p-0" src="/assets/images/icons/icon-start.jpg"></h2>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="4">
                                        <div class="row g-0 pricediv">
                                            <div class="col-sm-9 p-3">
                                                <table class="table m-0">
                                                    <tr>
                                                        <td width="20%"><strong class="pb-2">Price </strong></td>
                                                        <td width="80%"><strong class="pricetext">$ {{Valprice | number}}</strong></td>
                                                    </tr>
                                                </table>
                                                
                                            </div>
                                            <div class="col-sm-3 text-center">
                                                
                                                <a class="btn btn-dark btn mb-2 w-100 bg1"><img class="carticon" src="/assets/images/icons/icon-cart.png"> Add to cart</a>
                                                
                                                <a class="btn btn-dark btn w-100">Contact Vendor</a>
                                                
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                
                        </div>
                
                    </div>
                </div>


                <div class="col-md-12">
                    <div class="row">
                        


                
                
                
                        <div class="col-sm-12 mb-3">
                
                            <!-- <div class="mb-3 row">
                                <label for="description" class="col-sm-2 col-form-label">Description</label>
                                <div class="col-sm-10">
                                    <input type="text" [(ngModel)]="Valdescription" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors }" />
                                    <div *ngIf="submitted && f?.description?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.description?.errors?.required">Description is required</div>
                                    </div>
                                </div>
                            </div> -->
                
                
                
                        <div class="bg1 pt-2 pb-2 ps-3 pe-3 text-white text-uppercase descdiv text-center">Description</div>
                        <br>

                        <div class=""></div>

                        <div style="white-space: pre-line" [innerHTML]="Valdescription"></div>
                        
                        </div>

                        <div>
                        <table class="table table-bordered w-50">
                            <tbody>
                            <tr *ngFor="let FhSpec of divFhSpec; let i = index">
                                <td>{{ FhSpec.label }}</td><td>{{ FhSpec.value }}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                

                


                    </div>


            
            
            

                </div>






                <!-- <div class="col-sm-2"></div> -->
            </div>
        </div>
    </form>

    <div class="mt-5 mb-5">
        <svg class="bd-placeholder-img" width="100%" height="257px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg>
    </div>
    
</div>  

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydraft}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="draftimgicon" src="/assets/images/icons/draft-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully<br>saved as a draft!</p>
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <!-- <button type="button w-100" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" (click)="openmodaldraft()">DONE</button> -->
            <a href="/procurement/product/detail/{{ EditProductId }}" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5">PREVIEW</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaydone}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Your data has been successfully saved!</p>
          
          
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/vendor/products" data-bs-dismiss="modal">DONE</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>



<!-- Modal -->
<div class="modal" id="deleteModal" tabindex="-1"  [ngStyle]="{'display':displaydelete}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Delete Product</strong></h5>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-4 mb-4">
          Are you sure want to delete <b>"{{ Valname }}"</b> Product ?
        </h6>


        
        <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
          <button type="button" class="btn c1 f1 pe-5 ps-5 cancelbtn text-uppercase fsmall" (click)='openmodaldelete()'>Cancel</button>
          <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="confirmdelete()"  data-bs-dismiss="modal">Delete</button>
        </div>

      </div>

    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal" id="approveRejectModal" tabindex="-1"  [ngStyle]="{'display':displayApproveReject}">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Confirm</strong></h5>
        <button type="button" class="btn text-white" data-bs-dismiss="modal" (click)="openModalApproveReject()"><i class="bi bi-x-lg"></i></button>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-4 mb-4">
          Confirm Product : <b>{{ Valname }}</b> 
        </h6>


        
        <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
          
          <button type="button" class="btn pe-5 ps-5 cancelbtn text-uppercase fsmall text-white btn-danger" data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'REJECT')">Reject</button>
          <button type="button" class="btn  text-white pe-5 ps-5 text-uppercase fsmall btn-success"  data-bs-dismiss="modal" (click)="reqeustApproval(EditProductId,'APPROVE')">Approve</button>
        </div>

      </div>

    </div>
  </div>
</div>




<!-- Modal -->
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':displaypublish}">    
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-0">
  
        <div class="modal-body pt-4 pb-4 ps-4 pe-4">
          <div class="text-center">
            <img class="saveimgicon" src="/assets/images/icons/done-save.png">
          </div>  
          <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
          <p class="text-center mt-0 mb-4 fw-bold">Product published!</p>
          
          
          
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
            <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="openModalPublished()">DONE</a>
          </div>
  
        </div>
  
      </div>
    </div>
</div>
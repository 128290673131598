<div ngbAutofocus>

      <!-- <div class="modal-header bg1 rounded-0 text-center" *ngIf="title">
        <h5 class="text-white f1 mb-0"><strong>{{ title }}</strong></h5>
      </div> -->
      <div class="modal-body">

        <div class="p-3 text-center">
            <img src="/assets/images/icons/bi_cart-check@3x.png" class="cart_icon my-2">
            <!-- <h1 class="display-1 bi bi-cart-check pt-2"></h1> -->

            <h2 class="text-center fw-bold py-1">
                {{'success.title' | translate}}
            </h2>
            <p class="pt-1 pt-sm-2 pb-1 pb-sm-4">
                {{'success.add_to_cart' | translate}}</p>


            <div class="px-3">
                <div class="row">
                    <div class="col-12 col-sm-1"></div>
                    <div class="col-6 col-sm-5 p-1 p-sm-2">
                        <button type="button" class="text-uppercase text-uppercase rounded-1 bg3 fw-bold border1 c1 py-1 w-100 py-2" (click)="activeModal.close()">Close</button>
                    </div>
                    
                    <div class="col-6 col-sm-5 p-1 p-sm-2">
                        <button type="button" class="btn bg1 border-2 border1 f1 text-white text-uppercase text-uppercase w-100 py-2" (click)="activeModal.close(); submit()">Go to Cart</button>
                    </div>
                    <div class="col-12 col-sm-1"></div>
                </div>
            </div>


        </div>        



      </div>

</div>

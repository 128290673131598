import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { RatingService } from 'src/app/_services/rating.services';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-procurement-rating-vendor',
  templateUrl: './procurement-rating-vendor.component.html',
  styleUrls: ['./procurement-rating-vendor.component.scss']
})
export class ProcurementRatingVendorComponent implements OnInit {
  PageLoading:boolean=false
  proformaPoId:any=null
  DetailRating:any
  alreadyRating:boolean=false
  showRatingVendor:boolean=false
  transactionAccur:boolean=false
  RatingCompanyQuestion:any=[]

  prepareSubmitData:any=[]

  prepareRatingVendor:any=[]
  starRatingVendor:any=[]

  RatingProduct:any=[]
  starRatingProduct:any=[]

  valReviewVendor:any=''
  valReviewProducts:any=[]

  invalidRating:boolean=false
  
  onEditMode:boolean=false
  GetPpoRatingVendor:any
  currentlang:any
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    private popupmodal : Popupmodal,
    private ratingService: RatingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('language');
    if(this.currentlang){
      this.currentlang = (this.currentlang=='en'?'ENG':'IND')
    } else {
      this.currentlang = 'ENG'
    }
    this.proformaPoId = this.route.snapshot.paramMap.get('proformaPoId');
    this.getDetailRating()
    this.getQuestion()
    this.getListProductReview()

  }
  redirect(){

    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'md'
    })
    modalRef.componentInstance.modalconfirm = 'modal'
    modalRef.componentInstance.title = 'Confirm'
    modalRef.componentInstance.subtitle = 'Confirm submit rating & review?'
    modalRef.componentInstance.yesStr = 'Confirm'
    modalRef.componentInstance.noStr = 'Cancel'
    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      if (res){
        //alert('hit api');
        this.postDataReview()
        // this.router.navigate(['/procurement/rating/procurement-rating-list']);
      } 
    })

    //this.router.navigate(['/procurement/rating/procurement-rating-products']);
  }

  getDetailRating()
  {
    this.PageLoading=true
    this.ratingService.getProcurementGetPpoRatingDetail(this.proformaPoId).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.procurementGetPpoRatingDetail){
        this.DetailRating = res.data?.procurementGetPpoRatingDetail

        this.alreadyRating = res.data?.procurementGetPpoRatingDetail.isRating
        if(!this.alreadyRating){
          

          //this.showModalOccur()
          
        } else {
          this.getPpoRatingVendor()
        }
        this.showRatingVendor=true;
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }

  getQuestion()
  {
    this.PageLoading=true
    this.ratingService.getLoadRatingCompanyQuestion(this.currentlang).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.loadRatingCompanyQuestion){
        this.RatingCompanyQuestion = res.data?.loadRatingCompanyQuestion

        var question = res.data?.loadRatingCompanyQuestion
        var arrQuestion:any = []
        question.forEach((value: any, key: any) => {
          arrQuestion.push({'companyRatingQuestionId':value.companyRatingQuestionId})
        })

        this.prepareRatingVendor=arrQuestion

        var arrQuestion:any = []
        question.forEach((value: any, key: any) => {

          for (let index = 1; index <= 5; index++) {
            var data ={
              'question':value.companyRatingQuestionId,
              'question_text':value.question,
              'rank': index,
              'fill':false
            }
            arrQuestion.push(data)
          }
          
        })
        this.starRatingVendor=arrQuestion
        console.log(' this.starRatingVendor', this.starRatingVendor)

        this.prepareSubmitData['ratingCompanyQuestionInput'] = arrQuestion
        console.log('submitdata : ',this.prepareSubmitData)
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
    
  }


  addStar(totalStar:string,idQuestion:string,hover:number){
    // console.log('----------')
    // console.log('idQuestion : ',idQuestion)
    // console.log('totalStar : ',totalStar)
    // console.log('hover : ',hover)
    // console.log('----------')
  }

  clickStar(totalStar:string,idQuestion:string,hover:number){
    this.prepareRatingVendor.forEach((value: any, key: any) => {
      if(value.companyRatingQuestionId==idQuestion){
        this.prepareRatingVendor[key]['rating']=totalStar
        this.prepareRatingVendor[key]['rank']=hover
        //this.prepareRatingVendor[key]['fill']=true
        

          this.starRatingVendor.forEach((v: any, k: any) => {
            for (let index = 1; index <= hover; index++) {
              if(v.question==idQuestion){
                if(v.rank<=index){
                  this.starRatingVendor[k]['fill']=true
                } else {
                  this.starRatingVendor[k]['fill']=false
                }
              } 
            }

          })


        
      }
    })
    console.log('this.starRatingVendor : ',this.starRatingVendor)
    // console.log('this.prepareRatingVendor : ',this.prepareRatingVendor)
  }

  checkStar(position:string,idQuestion:string,rank:number){
    var res = false
    this.starRatingVendor.forEach((value: any, key: any) => {
      if(value.question==idQuestion && value.rank==rank){
        if(value.fill!==false){
          res = true
        }
      }
    })
    return res
  }

  showModalOccur()
  {
    const modalRef = this.modalService.open(ModalComponent, {
      modalDialogClass: 'modal-dialog-centered',
      centered: true,
      size: 'md'
    })
    modalRef.componentInstance.modalrating = 'modal'
    modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
      console.log('res:: ',res)
      if (res=='yes') this.transactionAccur=true;
      if (res=='no') this.transactionAccur=false;

      this.RatingProduct=[]
      this.starRatingProduct=[]
      if(this.transactionAccur){
        this.getListProductReview()
      }
    })
  }

  getListProductReview(){
    this.PageLoading=true
    this.ratingService.getprocurementGetPpoRatingProduct(this.proformaPoId).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.procurementGetPpoRatingProduct){
        this.RatingProduct = res.data?.procurementGetPpoRatingProduct
        console.log('this.RatingProduct',this.RatingProduct)
        var arrProdcts:any=[]
        
        this.RatingProduct.forEach((value: any, key: any) => {

          for (let index = 1; index <= 5; index++) {
            var data ={
              'item':value.proformaPoItemId,
              'rank': index,
              'fill':false
            }
            arrProdcts.push(data)
          }

        })

        this.starRatingProduct=arrProdcts
        console.log(' this.starRatingVendor', this.starRatingProduct)


      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }


  addStarProduct(totalStar:string,idQuestion:string,hover:number){
    // console.log('----------')
    // console.log('idQuestion : ',idQuestion)
    // console.log('totalStar : ',totalStar)
    // console.log('hover : ',hover)
    // console.log('----------')
  }

  clickStarProduct(totalStar:string,idQuestion:string,hover:number){
    this.RatingProduct.forEach((value: any, key: any) => {
      if(value.proformaPoItemId==idQuestion){
        this.RatingProduct[key]['rating']=totalStar
        this.RatingProduct[key]['rank']=hover
        //this.prepareRatingVendor[key]['fill']=true
        
        console.log('this.starRatingProduct : ',this.starRatingProduct)
          this.starRatingProduct.forEach((v: any, k: any) => {
            for (let index = 1; index <= hover; index++) {
              if(v.item==idQuestion){
                if(v.rank<=index){
                  this.starRatingProduct[k]['fill']=true
                } else {
                  this.starRatingProduct[k]['fill']=false
                }
              } 
            }

          })


        
      }
    })
    console.log('this.starRatingProduct : ',this.starRatingProduct)
    // console.log('this.prepareRatingVendor : ',this.prepareRatingVendor)
  }

  checkStarProduct(position:string,idQuestion:string,rank:number){
    var res = false
    this.starRatingProduct.forEach((value: any, key: any) => {
      if(value.item==idQuestion && value.rank==rank){
        if(value.fill!==false){
          res = true
        }
      }
    })
    return res
  }

  changeOccur(check: any) {
    if (check.target.checked) {
      this.transactionAccur = true
      
    } else {
      this.transactionAccur = false
    }
  }

  postDataReview()
  {
    this.invalidRating=false

    this.prepareSubmitData=[]

    var preparedata:any
    preparedata = {
      'isTransactionOccur':false,
      'vendorReview':'',
      'ratingCompanyQuestionInput':[],
      'ratingProductInput':[]
    }
    
    preparedata['proformaPoId'] = this.proformaPoId
    

    preparedata['isTransactionOccur'] = this.transactionAccur
    
    // if(this.valReviewVendor){
    //   preparedata['vendorReview'] = this.valReviewVendor
    // } else {
    //   delete preparedata['vendorReview'];
    // }
    preparedata['vendorReview'] = this.valReviewVendor

    this.prepareRatingVendor.forEach((value: any, key: any) => {
      preparedata['ratingCompanyQuestionInput'].push({'companyRatingQuestionId':value.companyRatingQuestionId,'rating':value.rating})
    })

    preparedata['ratingProductInput']=[]
    if(this.transactionAccur){
      this.RatingProduct.forEach((value: any, key: any) => {
        console.log('value',value)
        preparedata['ratingProductInput'].push({'proformaPoItemId':value.proformaPoItemId,'rating':value.rating,'productReview':this.valReviewProducts[key]})
      })
    } else {
      delete preparedata['ratingProductInput'];
    }

    if(!this.transactionAccur){
      delete preparedata['ratingProductInput'];
    }

    this.prepareSubmitData=preparedata

    //validate input
    var invalid =0

    this.prepareSubmitData['ratingCompanyQuestionInput'].forEach((v: any, k: any) => {
      if(!v['rating']){
        invalid++
      }
    })

    console.log('invalid : ',invalid)
    console.log('prepareSubmitData : ',this.prepareSubmitData)

    //if edit, seperate ppoid
    if(this.DetailRating.isRating){
      delete preparedata['proformaPoId']
    }
    

    if(invalid>0){
      this.invalidRating=true
      this.popupmodal.showError('Please rate star (1-5) and review vendor.',null).subscribe((res:any) => {})
    } else {
      const modalRef = this.modalService.open(ModalComponent, {
        modalDialogClass: 'modal-dialog-centered',
        centered: true,
        size: 'md'
      })
      modalRef.componentInstance.modalconfirm = 'modal'
      modalRef.componentInstance.title = 'Confirm'
      modalRef.componentInstance.subtitle = (this.DetailRating.isRating)?'Confirm update rating & review?':'Confirm submit rating & review?'
      modalRef.componentInstance.yesStr = 'Confirm'
      modalRef.componentInstance.noStr = 'Cancel'
      modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
        if (res){
          //alert('hit api');
          if(this.DetailRating.isRating){
            this.updateReview()
          } else {
            this.submitReview()
          }
          
          // this.router.navigate(['/procurement/rating/procurement-rating-list']);
        } 
      })
    }





  }

  submitReview(){
    this.PageLoading=true
    this.ratingService.getprocurementGiveRatingToVendor(this.prepareSubmitData).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.procurementGiveRatingToVendor){
          
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Success!' 
        modalRef.componentInstance.infoSuccess = 'Success submit rating!'
        modalRef.componentInstance.infoFailed = null
        modalRef.componentInstance.closeTxt = 'OK'
    
        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/procurement/rating/procurement-rating-list']);
          } 
        })

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }


  getPpoRatingVendor(){
    this.ratingService.getprocurementGetPpoRatingVendor(this.proformaPoId,this.currentlang).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.procurementGetPpoRatingVendor){
          
        this.GetPpoRatingVendor = res.data?.procurementGetPpoRatingVendor

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

  }

  clickEditMode() {
    this.onEditMode=true
    this.alreadyRating=false
    
    //this.DetailRating = res.data?.procurementGetPpoRatingDetail

    this.transactionAccur = this.DetailRating.isTransactionOccur
    console.log('this.DetailRating',this.DetailRating)

    this.valReviewVendor = this.GetPpoRatingVendor.review


    var numberRating =['','ONE','TWO','THREE','FOUR','FIVE']
    
    this.GetPpoRatingVendor.ppoRatingData.forEach((value: any, key: any) => {
      // rating // question
      console.log('value',value)
      this.starRatingVendor.forEach((v: any, k: any) => {
        console.log('v',v)
        if(v.question_text==value.question){
          // this.prepareRatingVendor[key]['rating']=value.rating
          // this.prepareRatingVendor[key]['rank']=value.rating
          this.clickStar(numberRating[value.rating],v.question,value.rating)
        }
      })
    })


    // this.alreadyRating = res.data?.procurementGetPpoRatingDetail.isRating
    // transactionAccur

    //load rating product
    this.RatingProduct.forEach((value: any, key: any) => {
        //fill if already have data
        this.clickStarProduct(numberRating[value.rating],value.proformaPoItemId,value.rating)
        this.valReviewProducts[key]=value.review
    })

  }

  
  updateReview(){
    this.PageLoading=true 
    this.ratingService.getprocurementUpdateRatingToVendor(this.proformaPoId,this.prepareSubmitData).subscribe((res:any) => {
      console.log(res)
      this.PageLoading=false
      if(res.data?.procurementUpdateRatingToVendor){
          
        const modalRef = this.modalService.open(ModalComponent, {
          modalDialogClass: 'modal-dialog-centered',
          centered: true,
          backdrop : 'static',
          keyboard : false,
          size: 'sm'
        })
        modalRef.componentInstance.modalinfo = 'info'
        modalRef.componentInstance.infoTitle = 'Success!' 
        modalRef.componentInstance.infoSuccess = 'Success update rating!'
        modalRef.componentInstance.infoFailed = null
        modalRef.componentInstance.closeTxt = 'OK'
    
        modalRef.componentInstance.confirmedClick.subscribe((res: any) => {
          if (res){
            this.router.navigate(['/procurement/rating/procurement-rating-list']);
          } 
        })

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });
  }


  counter(i: number) {
    return new Array(i);
  }

}

<div class="container pt-5 pb-5">
    <div class="card border-0  mb-3 divshbreadcum">

        <div class="container">
            <div class="row">
            <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white mb-3 pb-3 rounded-top">
                <h4 class="mb-0 fw-bold">Report Feedback</h4>
            </div>

            <div class="col-12 {{ loadingrating ? 'onloading' : ''}}">
                <div class="row justify-content-center">
                <div class="col-sm-12 col-md-12 col-lg-12 pt-3 pb-3 table-responsive">

                    <table class="table table-hover" id="usertable">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col"><font class="">ID </font></th>
                                <th class="text-center" scope="col"><font class="">Url </font></th>
                                <th class="text-left" scope="col"><font class="">Company Profile</font></th>
                                <th class="text-center" scope="col"><font class="">Type </font></th>
                                <th class="text-left" scope="col"><font class="">Notes </font></th>
                                <th class="text-center" scope="col"><font class="">Status </font></th>
                                <!-- <th class="text-center" scope="col"><font class="">Action </font></th> -->
                        </thead>
                        <tbody>

                            <ng-container *ngIf="noData">
                            <tr>
                                <td colspan="8" class="text-center">No data</td>
                            </tr>
                            </ng-container>

                            <ng-container *ngIf="!noData">

                            <tr *ngFor="let data of AllFeedbackData; let i = index">
                                <td><font placement="end" ngbTooltip="{{data.reportAndFeedbackId}}">{{data.reportAndFeedbackId}}</font></td>
                                <td><a href="{{data.reportedUrl}}" target="_blank"  placement="end" ngbTooltip="{{data.reportedUrl}}">{{limitText(data.reportedUrl)}}</a></td>
                                <td>
                                    <div class="">
                                        <div class="row g-0">
                                            <div class="col-4">Email</div>
                                            <div class="col-1">:</div>
                                            <div class="col-7">{{data.userEmail}}</div>

                                            <div class="col-4">Company Name</div>
                                            <div class="col-1">:</div>
                                            <div class="col-7">{{data.companyName}}</div>

                                            <div class="col-4">Role</div>
                                            <div class="col-1">:</div>
                                            <div class="col-7">{{data.userPosition}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{data.feedbackIssueType}}</td>
                                <td>{{data.notes}}</td>
                                <td>

                                    <strong>
                                        <ng-container [ngSwitch]="data.status">
                                            <ng-container *ngSwitchCase="'DONE'">
                                                <font class="text-success">{{data.status}}</font>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'NOT DONE'">
                                                <font class="text-danger">{{data.status}}</font>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>{{data.status}}</ng-container>
                                        </ng-container>
                                    </strong>
                                </td>
                                <!-- <td>
                                    {{getStatus(data.reportAndFeedbackId)}}
                                    <select class="form-select form-control"  [(ngModel)]="valspecValue[i]" 
                                    [ngModelOptions]="{standalone: true}"  name="statusupdate">
                                        <option value='DONE'>DONE</option>
                                        <option value='NOT_DONE'>NOT DONE</option>
                                    </select>
                                    <a class="btn btn-secondary">UPDATE</a>
                                </td> -->
                            </tr>

                            <!-- "proformaPoId": "01G3BAQDTVJNTDXYZ2C648ZZEN",
                            "proformaPoNumber": "u6JyMvwk",
                            "rfqDisplayId": "K7yYsz85",
                            "vendorName": "Company Pak Sen 3",
                            "isRating": true,
                            "proformaPoDate": "2022-05-18T10:16:59.483095Z",
                            "totalRating": 2.67,
                            "isTransactionOccur": true,
                            "__typename": "ProcurementPpoNeedRatingListData" -->


                            </ng-container>       





                        </tbody>
                    </table>


                    <div class="row g-0 mb-3 mt-3">


                    <div class="col-sm-12 pt-0 container {{ loadingrating ? 'onloading' : ''}}">

                        <div class="row g-0 pt-2   mb-0  justify-content-end">
                            <div class="col-auto ps-1 pe-1">
                            <div class=" disable-btn p-0 ps-3  pe-2">
                                <img *ngIf="hasprev" src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle pointer" (click)="gotopage(currentpage-1)">
                                <img *ngIf="!hasprev" src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                            </div>
                            </div>
                            <div class="col-auto mt-2">
                            <span  *ngFor="let item of [].constructor(totalpage); let i = index" class="pagingqrfq {{currentpage==i+1?'active':'pointer'}}" (click)="gotopage(i+1)">{{i+1}}</span>

                            <span *ngIf="noData" class="pagingqrfq active">1</span>

                            </div>
                            <div class="col-auto  ps-1 pe-1">
                            <div class="disable-btn p-0  ps-2">
                                <img *ngIf="hasnext" src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle pointer" (click)="gotopage(currentpage+1)">
                                <img  *ngIf="!hasnext" src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                            </div>
                            </div>
                        </div>

                    </div>

                    </div>





                </div>








                </div>
            </div>




            </div>
        </div>

    </div>
</div>


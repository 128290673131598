<div>

    <div class="container" *ngIf="PageLoading">
        
      <div>
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>  
      </div>   

    </div>
    <div class="container"  *ngIf="!PageLoading">
        <div class="mt-3">
            <div class="">
                <div class="pb-4">
                  <div class="d-none d-sm-block">
                    <div class="divshbreadcum mt-4 mb-4 pt-2 pb-2 pe-3 ps-3">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="shbreadcum">
                              <table>
                                <tr>
                                  <td class="separatorfh">
                                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 mt-2 ms-3">
                                        <strong class="c1"><font class="fhc1">Vendor Detail Page
                                          </font></strong>
                                    </h6>                                  
                                  </td>  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>  

                  <!-- Breadcrumb mobile -->
                  <div class="d-block d-sm-none">

                    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">


                      <div class="row">
                        <div class="col-6">
                          <div class="pt-1">
                            <strong class="c1">
                              <strong class="c1">
                                <font class="text-muted">Vendor Detail Page
                                </font>
                              </strong>
                            </strong>
                          </div>

                        </div>
                        <div class="col-6 text-end">
 
                        </div>
                      </div>

                    </div>
                  </div>
                  
                  <div class="card border-0  mb-4">

                    <div class="container filterdiv">
                        <div class="row vendorDiv">


                            <div class="col-12 vendorHeader">
                                <div class="row align-items-center">


                                  <div class="col-4 col-sm-2 col-md-2 col-lg-2">
                                    <div class="rounded-circle avatarVendor">

                                      <img class="rounded-circle avatar" src="{{(vendorLogo)?vendorLogo:'/assets/images/default-company.jpeg'}}">
                                    </div>
                                    
                                  </div>
                                  <div class="col-8 col-sm-7 col-md-7 col-lg-7">
                                    <div>
                                      <h3>
                                        <!-- <img class="ribbon" src="/assets/images/icons/fluent_ribbon-star-24-filled@3x.png">  -->
                                        <font class="{{isBlackist?'text-decoration-line-through op5':''}}">{{getVendorName}} </font>
                                        <!-- <font class="rating ps-3 pe-1">4.9</font> <img src="/assets/images/icons/rating-star.png" class="star"> -->
                                        <font  *ngIf="isBlackist" class="ps-3 bi bi-exclamation-circle"  placement="end" ngbTooltip="Blocked"></font>
                                      </h3>
                                      <!-- <p class="mb-0">Tax Status : {{isPKP?'PKP':'non-PKP'}}</p> -->
                                      <p class="d-none d-sm-block">{{getvendorCity}}, {{getvendorCountry}}</p>                           
                                    </div>

                                  </div>
                                  <div class="col-12 col-sm-3 col-md-3 col-lg-3">

                                    <small class="d-block d-sm-none my-2 text-white">{{getvendorCity}}, {{getvendorCountry}}</small> 
                                    <div class=" text-end">
                                      <a *ngIf="checkisFavourite" class="btn btn-secondary bgbtn-loading disabled"><i class="bi bi-heart{{ isFavourite ? '-fill' : ''}}"></i> Favourite</a>
                                      <a *ngIf="!checkisFavourite" (click)="setFavourite()" class="btn btn-primary  {{ isFavourite ? 'bgbtn-action-fav' : 'bgbtn-action'}}">
                                          <i class="bi bi-heart{{ isFavourite ? '-fill isfav' : ''}}"></i> Favourite
                                      </a>
  
                                      <div ngbDropdown placement="bottom-end" class="d-inline-block btntridots">
                                        <button class="btn dropdown-toggle text-white  pt-0 pb-0" id="dropdownBasic1" ngbDropdownToggle>
                                          <img class="dotsicon wishlisticon" src="/assets/images/icons/3dotshIcon-white.png">
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right fhddmenu">
  
                                          <a ngbDropdownItem class="dropdownbg pointer" (click)="sendMessage(VendorId)" ><img src="/assets/images/icons/item-message.png" class="fhddicon wishlisticon">Send Vendor a Message</a>
                                          <hr>
                                          <a (click)="setBlacklist()" ngbDropdownItem class="dropdownbg pointer"><img src="/assets/images/icons/item-blocked.png" class="fhddicon wishlisticon">{{isBlackist?'Unblock':'Block'}} Vendor</a>
                                          
                                        </div>
                                      </div>
                 
                                    </div>


                                  </div>


                                </div>
                            </div>


                        </div>
                    </div>

                  </div>

                  
                  <div class="card border-0  mb-4 d-none">
                    <div class="row g-0">
                        <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                          <img src="/assets/images/vendor_banner.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-sm-12 col-md-7 col-lg-7  bg-black container">
                          <div class="text-center text-white row g-0 h-100 justify-content-center align-items-center">
                            <div class="col-12 p-4">
                              <h5>Marketing Description Banner</h5>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis turpis diam viverra urna egestas scelerisque adipiscing in adipiscing. Magna elementum, amet, sit eget commodo fames libero diam.
                              </p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div class="text-center mb-3">

                    <div class="container">
                        <div class="row row-cols-auto justify-content-center">

                            <div class="col pointer" *ngIf="loadProducts==true; else elsePrev" (click)="LoadMoreProducts()">
                                
                                <img src="/assets/images/icons/vendor-prod-arrow.png" class=" arrowProd {{loadProducts ? 'arrowMore':'arrowLess'}} ">
                                <h3 class="headLoadMore m-0">Hide Vendor's Products</h3>
                            </div>
                            <ng-template #elsePrev>
                                <div class="col pointer"  (click)="LoadMoreProducts()">
                                    <h3 class="headLoadMore m-0">See Vendor's Products</h3>
                                    <img src="/assets/images/icons/vendor-prod-arrow.png" class=" arrowProd {{loadProducts ? 'arrowMore':'arrowLess'}} ">
                                    
                                </div>
                            </ng-template>
                            
                        </div>
                    </div>

                  </div>


                  <div class="card border-0  mb-4 container divshbreadcum divListProd" *ngIf="loadProducts">
                    <!-- <div class="row g-0">
                        <div class="col-12 p-0">
                            <img src="/assets/images/vendor-detail-slide.jpg" class="img-fluid">
                        </div>
                    </div> -->


                    <div class="mb-4">
  
                      <div class="row"  *ngIf="AlldataProducts?.length > 0" >
                        <!-- <div class="col-sm-8">
                          <p class="m-0 pt-2">
                            <i class="mt-1">
                              <strong>{{totaldata}}</strong> results for <strong>“<font [innerHTML]="prevkeyword"></font>”</strong>
                            </i>
                          </p>
                
                        </div>
                        <div class="col-sm-4">
                  
                          <div class="row" *ngIf="AlldataProducts?.length > 0" >
                
                            <div class="col-12" *ngIf="!pagingloading">
                              <div class="row g-0 mb-3 justify-content-end">
                                <div class="col-auto pe-3 pt-2 c1">
                                  Show
                                </div>
                                <div class="col-auto">
                                  <select class="form-select fhselect" #t (change)="productPerPage(t.value)" [(ngModel)]="ValPaging">
                                    <option value="9" selected>9</option>
                                    <option value="36">36</option>
                                    <option value="90">90</option>
                                    <option value="180">180</option>
                                  </select>
                                </div>
                                <div class="col-auto ps-1 pe-1">
                                  <div class="btn p-0 ps-3" *ngIf="hasPrevPage==true; else elsePrev" (click)="loadPrev()">
                                    <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                                  </div>
                                  <ng-template #elsePrev>
                                    <div class=" disable-btn p-0 ps-3">
                                      <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                    </div>
                                  </ng-template>
                                </div>
                                <div class="col-auto  ps-1 pe-1">
                                  <div class="btn p-0  ps-2" *ngIf="hasNextPage==true; else elseNext" (click)="loadNext()">
                                    <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                                  </div>
                                  <ng-template #elseNext>
                                    <div class=" disable-btn  p-0 ps-2">
                                      <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                    </div>
                                  </ng-template>
                                </div>
                              </div>     
                            </div>
                
                            <div class="col-12" *ngIf="pagingloading">
                              <div class="row g-0 mb-3 justify-content-end">
                                <div class="col-auto pe-3 pt-2 c1">
                                  Show
                                </div>
                                <div class="col-auto">
                                  <select class="form-select fhselect disabled" [(ngModel)]="ValPaging" disabled>
                                    <option value=" "> </option>
                                    <option value="9" selected>9</option>
                                    <option value="36">36</option>
                                    <option value="90">90</option>
                                    <option value="180">180</option>
                                  </select>
                                </div>
                                <div class="col-auto ps-1 pe-1">
                                  <div class=" disable-btn p-0 ps-3">
                                    <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                  </div>
                                </div>
                                <div class="col-auto  ps-1 pe-1">
                                  <div class=" disable-btn  p-0 ps-2">
                                    <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                  </div>
                                </div>
                              </div>     
                            </div>
                
                          </div>
                  
                          
                        </div> -->

                        <div class="col-sm-9">
                          <h3 class="titleListProd pt-2">Products and Services supplied by this Vendor</h3>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3 searchBox">
                            <input type="text" class="form-control fhinput1" placeholder="Search products..." aria-label="Search products..." aria-describedby="button-addon2" [disabled]="PageProductLoading" [(ngModel)]="valSearch" (keydown.enter)="searchProduct()" [ngModelOptions]="{standalone: true}">
                            <button class="btn btn-outline-secondary fhbtn1 text-white" type="button" id="button-addon2" (click)="searchProduct()" [disabled]="PageProductLoading"><img src="/assets/images/icons/ic_Search@3x.png" class="wishlisticon"></button>
                          </div>
                        </div>
                      </div>
                  
                  
                    </div>
                
                      <div *ngIf="PageProductLoading">
                        <div class="text-center pt-5 pb-5">
                          <div class="loader loader--style8" title="7">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                              </rect>
                              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                              </rect>
                              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                              </rect>
                            </svg>
                          </div>
                        </div>  
                      </div>   
                  
                      <div class="{{onSearch?'d-none':''}}"  *ngIf="!PageProductLoading">
                        
                        <div *ngIf="AlldataProducts?.length == 0"  class="empty-product">
                          <div class="row">
                            <div class="col-12 text-center  pb-3">
                              <i><strong>{{totaldata}}</strong> results for <strong>“<font [innerHTML]="prevkeyword"></font>”</strong></i>
                            </div>
                          </div>
                          <div class="bg-secondary bg-gradient rounded-3 pt-5 pb-5 mb-5 text-center">
                            <h1 class="text-light bi bi-shop-window" style="font-size: 80px;"></h1>
                            <h6 class="text-light">No Product.</h6>
                          </div>
                        </div>
                  
                  
                        <div class="mb-4" *ngIf="AlldataProducts?.length > 0">
                
                          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5 row-cols-xl-5">
                            <div class="col mb-4" *ngFor="let product of AlldataProducts; let i = index">
                              <app-product-card 
                                [images]="product?.imageUrl"
                                [title]="product?.productName"
                                [company]="getVendorName"
                                [currency]="product.currency?product.currency:'IDR'"
                                [price]="product?.price"
                                location="{{ getvendorCity? getvendorCity+', ':''}}{{ getvendorCountry}}"
                                [isConfidential]="getVendorName?'Y':'N'"
                                url="/product/{{getVendorDomain}}/{{product.productSlugUrl}}"
                              >
                              </app-product-card>
                            </div>
                          </div>


                            <div class="row d-none">
                                <div class="col-6 col-sm-4 mb-4" *ngFor="let product of AlldataProducts; let i = index">
                
                                  <a class="" href="/product/{{getVendorDomain}}/{{product.productSlugUrl}}">
                                    <div class="card procShadow linkproduct">



                                      <div class="card-img-top text-center">
                                        <div class="container-imgcard">
                                          <img src="{{(product?.imageUrl[0])?product?.imageUrl[0]:'/assets/images/default-image-corrupted.png'}}" class="imgcard">
                                        </div>
                                        
                                      </div>
                                      <div class="card-body pb-0 productList">
                                        <div class="row g-0">
                                          <div class="col-12">
                                            <h5 class="cut-text"><strong>{{ product.productName }}</strong></h5>
                                          </div>
                                          <div class="col-12"  *ngIf="product.price>0">
                                            <div class="">
                                              <h3 class="cut-text"><strong>{{product.currency}} {{ product.price | number }}</strong></h3>
                                            </div>
                                          </div>
                                          <div class="col-12"  *ngIf="product.price==0 || !product.price">
                                            <div class="">
                                              <h3 class="cut-text"><strong>{{product.currency?product.currency:'IDR'}} ***</strong></h3>
                                            </div>
                                          </div>

                                          <div class="col-6">
                                            <p class="pb-2 mb-0 cut-text h-100"><img class="vendoricon" src="/assets/images/icons/vendor_icon.jpg" style="width: 18px;" *ngIf="getVendorName"> <strong class="ps-2  cut-text"  *ngIf="getVendorName">{{ getVendorName }}</strong></p>
                                          </div>
                                          <div class="col-6 text-end">
                                            <p class="pb-2 mb-0 cut-text h-100"><i class="bi bi-geo-alt-fill" style="color:#1769b5"></i> <strong class="ps-2">{{ getvendorCity? getvendorCity+', ':''}}{{ getvendorCountry }}</strong></p>
                                          </div>

                                        </div>
                                      </div>

                                        <!-- <svg class="card-img-top productVendor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg> -->
                                        <!-- <img src="{{product?.imageUrl[0]}}" class="card-img-top">
                                        
                                        <div class="card-body pb-0">

                                          <div class="row g-0">
                                            <div class="col-12">
                                              <h5><strong>{{ product.productName }}</strong></h5>
                                            </div>
                                            <div class="col-12 col-sm-5 col-md-5 col-lg-5 order-2 order-sm-1 order-md-1 order-lg-1">
                                              <p>{{ (getvendorCity!=='' && getvendorCountry!='') ? getvendorCity+', '+getvendorCountry:'' }}</p>
                                            </div>
                                            <div class="col-12 col-sm-7 col-md-7 col-lg-7 order-1 order-sm-2 order-md-2 order-lg-2">
                                              <div class="pb-2 text-start text-sm-end text-md-end text-lg-end">
                                                <h4 class="m-0">{{ product.currency }} {{ product.price | number }}</h4>
                                              </div>
                                            </div>
                                          </div>


                                            
                                            
                                        </div> -->
                                    </div>
                                  </a>
                                </div>
                            </div>
                
                
                
                
                        </div> 
                  
                
                
                        <div class="row"  *ngIf="AlldataProducts?.length > 0" >
                          <div class="col-sm-8">
                            <p class="m-0 pt-2">
                              <i class="mt-1">
                                <!-- <strong>{{totaldata}}</strong> results for <strong>“<font [innerHTML]="prevkeyword"></font>”</strong> -->
                                Page {{currentpage}} of {{totalpage}}
                              </i>
                            </p>
                          </div>
                          <div class="col-sm-4">
                    
                            <div class="row divPaging" *ngIf="AlldataProducts?.length > 0" >
                  
                              <div class="col-12" *ngIf="!pagingloading">
                                <div class="row g-0 mb-3 justify-content-end">
                                  <div class="col-auto pe-3 pt-2 fhc1">
                                    Show
                                  </div>
                                  <div class="col-auto">
                                    <select class="form-select fhselect" #t (change)="productPerPage(t.value)" [(ngModel)]="ValPaging">
                                      <option value="10" selected>10</option>
                                      <option value="30">30</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                  <div class="col-auto ps-1 pe-1">
                                    <div class="btn p-0 ps-3" *ngIf="hasPrevPage==true; else elsePrev" (click)="loadPrev()">
                                      <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elsePrev>
                                      <div class=" disable-btn p-0 ps-3">
                                        <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-auto  ps-1 pe-1">
                                    <div class="btn p-0  ps-2" *ngIf="hasNextPage==true; else elseNext" (click)="loadNext()">
                                      <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elseNext>
                                      <div class=" disable-btn  p-0 ps-2">
                                        <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>     
                              </div>
                
                              <div class="col-12" *ngIf="pagingloading">
                                <div class="row g-0 mb-3 justify-content-end">
                                  <div class="col-auto pe-3 pt-2 fhc1">
                                    Show
                                  </div>
                                  <div class="col-auto">
                                    <select class="form-select fhc1 fhselect disabled" [(ngModel)]="ValPaging" disabled>
                                      <option value=""> </option>
                                      <option value="10" selected>10</option>
                                      <option value="30">30</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                  <div class="col-auto ps-1 pe-1">
                                    <div class=" disable-btn p-0 ps-3">
                                      <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                    </div>
                                  </div>
                                  <div class="col-auto  ps-1 pe-1">
                                    <div class=" disable-btn  p-0 ps-2">
                                      <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                    </div>
                                  </div>
                                </div>     
                              </div>
                              
                  
                  
                  
                            </div>
                    
                            
                          </div>
                        </div>
                  
                      </div>

                      <div class="{{onSearch?'':'d-none'}}" *ngIf="!PageProductLoading">
                        
                        <div  class="empty-product">
                          <div class="row mb-2">
                            <ng-container *ngIf="onSearch">
                              <div class="col-auto" *ngIf="valPrevSearch!==''">
                                <h5>
                                  <span class="badge rounded-pill bg-secondary bg1">Keyword : {{ valPrevSearch }} <i class="bi bi-x pointer" (click)="resetFilter('search')"></i></span>
                                </h5>
                              </div>
                            </ng-container>
                            <div class="col-7  pb-3">
                              <i><strong>{{totaldataSearch}}</strong> results for <strong>“<font [innerHTML]="valPrevSearch"></font>”</strong></i>
                            </div>
                          </div>
                          <div class="bg-secondary bg-gradient rounded-3 pt-5 pb-5 mb-5 text-center" *ngIf="AllSerachProducts?.length == 0" >
                            <h1 class="text-light bi bi-shop-window" style="font-size: 80px;"></h1>
                            <h6 class="text-light">No Product.</h6>
                          </div>
                        </div>
                  
                  
                        <div class="mb-4" *ngIf="AllSerachProducts?.length > 0">
                
                          <div class="row row-cols-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
                            <div class="col mb-4" *ngFor="let product of AllSerachProducts; let i = index">
                              <app-product-card 
                                [images]="product?.imageUrl"
                                [title]="product?.productName"
                                [company]="product?.productName"
                                [currency]="product.currency?product.currency:'IDR'"
                                [price]="product?.price"
                                location="{{ getvendorCity? getvendorCity+', ':''}}{{ getvendorCountry}}"
                                [isConfidential]="product?.productName?'Y':'N'"
                                url="/product/{{getVendorDomain}}/{{product.productSlugUrl}}"
                              >
                              </app-product-card>
                            </div>
                          </div>


                            <div class="row d-none">
                                <div class="col-sm-4 mb-4" *ngFor="let product of AllSerachProducts; let i = index">
                
                                  <a class="" href="/product/{{getVendorDomain}}/{{product.productSlugUrl}}">
                                    <div class="card procShadow linkproduct">


                                      <div class="card-img-top text-center">
                                        <div class="container-imgcard">
                                          <img src="{{(product?.imageUrl[0])?product?.imageUrl[0]:'/assets/images/default-image-corrupted.png'}}" class="imgcard">
                                        </div>
                                        
                                      </div>
                                      <div class="card-body pb-0 productList">
                                        <div class="row g-0">
                                          <div class="col-12">
                                            <h5 class="cut-text"><strong>{{ product.productName }}</strong></h5>
                                          </div>
                                          <div class="col-12"  *ngIf="product.price>0">
                                            <div class="">
                                              <h3 class="cut-text"><strong>{{product.currency}} {{ product.price | number }}</strong></h3>
                                            </div>
                                          </div>
                                          <div class="col-12">

                                            <p  class="pb-2 mb-0 cut-text h-100">{{ (getvendorCity!=='' && getvendorCountry!='') ? getvendorCity+', '+getvendorCountry:'' }}</p>

                                            <!-- <p class="pb-2 mb-0 cut-text h-100"><img class="vendoricon" src="/assets/images/icons/vendor_icon.jpg"  *ngIf="product.vendorName"> <strong class="ps-2"  *ngIf="product.vendorName">{{ product.vendorName}}</strong></p> -->
                                          </div>
                                        </div>
                                      </div>

                                        <!-- <svg class="card-img-top" width="100%" height="237px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#b1b1b1"></rect></svg> -->
                                        <!-- <img src="{{product?.imageUrl[0]}}" class="card-img-top">
                                        <div class="card-body pb-0">
                                          <h5><strong>{{ product.productName }}</strong></h5>
                                          <table width="100%" class="mb-3">
                                            <tr>
                                              <td width="40%">
                                                <p class="m-0">
                                                  {{ (getvendorCity!=='' && getvendorCountry!='') ? getvendorCity+', '+getvendorCountry:'' }}
                                                </p>
                                              </td>
                                              <td width="60%" class="text-end">
                                                <h4 class="m-0">{{ product.currency }} {{ product.price | number }}</h4>
                                              </td>
                                            </tr>
                                          </table>

                                            
                                            
                                        </div> -->
                                    </div>
                                  </a>
                                </div>
                            </div>
                
                
                
                
                        </div> 
                  
                
                
                        <div class="row"  *ngIf="AllSerachProducts?.length > 0" >
                          <div class="col-sm-8">
                            <p class="m-0 pt-2">
                              <i class="mt-1">
                                <!-- <strong>{{totaldata}}</strong> results for <strong>“<font [innerHTML]="prevkeyword"></font>”</strong> -->
                                Page {{currentpage}} of {{totalpage}}
                              </i>
                            </p>
                          </div>
                          <div class="col-sm-4">
                    
                            <div class="row" *ngIf="AllSerachProducts?.length > 0" >
                  
                              <div class="col-12" *ngIf="!pagingloading">
                                <div class="row g-0 mb-3 justify-content-end">
                                  <div class="col-auto pe-3 pt-2 fhc1">
                                    Show
                                  </div>
                                  <div class="col-auto">
                                    <select class="form-select fhselect" #t (change)="productPerPage(t.value)" [(ngModel)]="ValPaging">
                                      <option value="10" selected>10</option>
                                      <option value="30">30</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                  <div class="col-auto ps-1 pe-1 {{onSearch?'d-none':''}}">
                                    <div class="btn p-0 ps-3" *ngIf="hasPrevPage==true; else elsePrev" (click)="loadPrev()">
                                      <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elsePrev>
                                      <div class=" disable-btn p-0 ps-3">
                                        <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-auto  ps-1 pe-1 {{onSearch?'d-none':''}}">
                                    <div class="btn p-0  ps-2" *ngIf="hasNextPage==true; else elseNext" (click)="loadNext()">
                                      <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elseNext>
                                      <div class=" disable-btn  p-0 ps-2">
                                        <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>

                                  <!-- on search paging -->
                                  <div class="col-auto ps-1 pe-1 {{onSearch?'':'d-none'}}">
                                    <div class="btn p-0 ps-3" *ngIf="hasPrevPageSearch==true; else elsePrev" (click)="loadPrevSearch()">
                                      <img src="/assets/images/icons/paging-prev-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elsePrev>
                                      <div class=" disable-btn p-0 ps-3">
                                        <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div class="col-auto  ps-1 pe-1 {{onSearch?'':'d-none'}}">
                                    <div class="btn p-0  ps-2" *ngIf="hasNextPageSearch==true; else elseNext" (click)="loadNextSearch()">
                                      <img src="/assets/images/icons/paging-next-active@3x.png" class="fharrowCircle">
                                    </div>
                                    <ng-template #elseNext>
                                      <div class=" disable-btn  p-0 ps-2">
                                        <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                      </div>
                                    </ng-template>
                                  </div>
                                  <!--/on search paging -->
                                </div>     
                              </div>
                
                              <div class="col-12" *ngIf="pagingloading">
                                <div class="row g-0 mb-3 justify-content-end">
                                  <div class="col-auto pe-3 pt-2 fhc1">
                                    Show
                                  </div>
                                  <div class="col-auto">
                                    <select class="form-select fhc1 fhselect disabled" [(ngModel)]="ValPaging" disabled>
                                      <option value=""> </option>
                                      <option value="10" selected>10</option>
                                      <option value="30">30</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                  <div class="col-auto ps-1 pe-1">
                                    <div class=" disable-btn p-0 ps-3">
                                      <img src="/assets/images/icons/paging-prev@3x.png" class="fharrowCircle">
                                    </div>
                                  </div>
                                  <div class="col-auto  ps-1 pe-1">
                                    <div class=" disable-btn  p-0 ps-2">
                                      <img src="/assets/images/icons/paging-next@3x.png" class="fharrowCircle">
                                    </div>
                                  </div>
                                </div>     
                              </div>
                              
                  
                  
                  
                            </div>
                    
                            
                          </div>
                        </div>
                  
                      </div>
                


                  </div>

                </div>
            </div>    
        </div>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { DataService } from 'src/app/_services/data';
import { Subscription } from 'rxjs';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { TranslateService } from '@ngx-translate/core';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { LoginService } from 'src/app/_services/login.services';

const mutationVerify = gql`
mutation verifyEmail($verificationToken: String!) {
  verifyEmail(input: {
    verificationToken: $verificationToken}) {
      registrationId
      isAlreadyVerified
  }
}
`;

const mutationSubmitData = gql`
mutation inputUserDetailInfo($registrationId: ID!,$password: String!,$fullname: String!,$secondaryEmail: String) {
  inputUserDetailInfo(input: {
      registrationId: $registrationId
      password: $password
      fullname: $fullname
      secondaryEmail: $secondaryEmail
  })
}
`;


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html', 
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  verificationToken:any;
  // pageloading:boolean=false;
  btnloading:boolean=false;
  // divinput:boolean=false;
  submitted = false;
  regID:string=''
  // divSuccessMessage:boolean=false;
  state:string='loading' // loading, expired, submitdata, success

  //model

  ValBackupemail:string=''
  ValName:string=''
  ValPassword:string=''
  ValRetypePassword:string=''

  errortext:string=''

  detailinfoForm = this.formBuilder.group({
    // backupemail: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]],
    backupemail: ['', [Validators.email, Validators.pattern("^(?!.{255})([a-zA-Z0-9.!#\$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\$")]],

    name: ['', [Validators.required]],
    password: ['', Validators.required],
    retypepassword: ['', Validators.required]
  }
  , {
    validator: MustMatch('password', 'retypepassword')
  }
  );
  subscription: Subscription | any;
  sharemessage:any

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private authService: AuthService,
    private dataShare: DataService,
    private loginService : LoginService,
    private popupmodal : Popupmodal,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.authService.forceLogout();
    this.verificationToken = this.route.snapshot.paramMap.get('token');
    //this.pageloading=true;
    this.state = 'loading';

    if(this.verificationToken.length>32){
      this.verifyData()
    } else {
      this.router.navigate(['']);
    }

    // this.pageloading=false;
    // this.divinput= true
    //this.state='success'; // change to check page // loading, expired, submitdata, success
  }

  // convenience getter for easy access to form fields
  get f() { return this.detailinfoForm?.controls; }

  verifyData(){
    this.state = 'loading';
    this.loginService.postverifyEmail(this.verificationToken).subscribe((res:any) => {
      console.log(res)
      this.btnloading=false
      if(res.data?.verifyEmail){
        if(res.data.verifyEmail.isAlreadyVerified==true){
          this.state = 'alredyverified'

          //redirect to thank you
          this.dataShare.changeMessage('alredyverified')
          this.router.navigate(['/success-verification']);
          return 

          //continue to dashboard
          // this.router.navigate(['/vendor/dashboard']);
          //this.router.navigate(['/']);
        } else {
          //create password
          //alert('input backup email, fullname and pass');
          // this.divinput= true
          this.state = 'submitdata'
          this.regID = res.data.verifyEmail.registrationId
        }
    
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          // //force redirect on specific errortype
          // var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          // if(forceRedirect){
          //   window.location.href = forceRedirect
          //   return
          // }
        }
        //this.popupmodal.showError(errortext,null).subscribe((res:any) => {})

        this.state='expired'
        this.errortext = errortext
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

    return


    this.apollo
    .mutate({
      mutation: mutationVerify,
      variables: {verificationToken: this.verificationToken}
    })
    .subscribe(
      (res:any) => {
        console.log("succes, get data:", res);
        //this.pageloading=false;
        this.state = 'loading';

        //{"data":{"verifyEmail":{"registrationId":null,"isAlreadyVerified":true,"__typename":"VerificationResponse"}}}

        if(res.data){
          if(res.data.verifyEmail.isAlreadyVerified==true){
            this.state = 'alredyverified'

            //redirect to thank you
            this.dataShare.changeMessage('alredyverified')
            this.router.navigate(['/success-verification']);
            return 

            //continue to dashboard
            // this.router.navigate(['/vendor/dashboard']);
            //this.router.navigate(['/']);
          } else {
            //create password
            //alert('input backup email, fullname and pass');
            // this.divinput= true
            this.state = 'submitdata'
            this.regID = res.data.verifyEmail.registrationId
          }
        }
        console.log("res:",res);

       // this.router.navigate(['/dashboard']);

      },
      error => {
        console.log("there was an error sending the query", error);
        //this.pageloading=false;
        this.state='expired'
        //this.state="success" //test

        //this.divinput= false

        // console.log("res:",res);

        this.translate.get('error.VerificationTokenNotFound').subscribe((data:any)=> {
          if (this.translate.instant('error.VerificationTokenNotFound') !== 'error.VerificationTokenNotFound'){
            this.errortext = data
          }
        });

      }
    );
  }


  onSubmit() {
    this.submitted = true;
    console.log(this.detailinfoForm?.value);
    console.log(this.detailinfoForm);
    // stop here if form is invalid
    if (this.detailinfoForm.invalid) {
        return;
    }

    this.btnloading=true;
    // display form values on success
    //alert(JSON.stringify(this.registerForm?.value, null, 4));
    //this.postregister();

    // //test dummy
    // var res = {"data":{"inputUserDetailInfo":false}}
    // console.log('res.data.inputUserDetailInfo',res.data.inputUserDetailInfo);

    // if(res.data.inputUserDetailInfo){
    //   this.divSuccessMessage = true
    //   this.divinput= true
    // } else {
    //   this.divinput= false
    // }

    let ArrData:any
    if(this.ValBackupemail==''){
      ArrData ={
        registrationId: this.regID,
        password: this.ValPassword,
        fullname: this.ValName
      }
    } else {
      ArrData ={
        registrationId: this.regID,
        password: this.ValPassword,
        fullname: this.ValName,
        secondaryEmail: this.ValBackupemail
      }
    }

    this.loginService.postinputUserDetailInfo(this.regID, this.ValPassword, this.ValName, this.ValBackupemail).subscribe((res:any) => {
      console.log(res)
      this.btnloading=false
      this.state='submitdata'
      if(res.data){
        this.state ='success'

        // redirect to thank you
        this.dataShare.changeMessage('success')
        this.router.navigate(['/success-verification']);
        return 
    
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);

        var errortext = msg

        console.log('res',res.errors[0].extensions.type);
        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

    return

    // this.apollo
    // .mutate({
    //   mutation: mutationSubmitData,
    //   variables: ArrData
    // })
    // .subscribe(
    //   (res:any) => {
    //     console.log("succes, get data:", res);

    //     if(res.data.inputUserDetailInfo){
    //       // this.divSuccessMessage = true
    //       // this.divinput= true
    //       this.state ='success'

    //       // redirect to thank you
    //       this.dataShare.changeMessage('success')
    //       this.router.navigate(['/success-verification']);
    //       return 

    //     } else {
    //       // this.divinput= false
    //       this.state='submitdata'
    //       this.btnloading=false
    //     }

    //     // this.pageloading=false;
    //     // if(res.data){
    //     //   if(res.data.isAlreadyVerified){
    //     //     //continue to dashboard
    //     //     this.router.navigate(['/dashboard']);
    //     //   } else {
    //     //     //create password
    //     //     //alert('input backup email, fullname and pass');
    //     //     this.divinput= true
    //     //   }
    //     // }
    //     // console.log("res:",res);
    //    // this.router.navigate(['/dashboard']);
    //   },
    //   error => {
    //     console.log("there was an error sending the query", error);
    //     //alert('Opps something wrong')
    //     //this.divinput= false
    //     this.state='submitdata'

    //     console.log('error',error);
    //     var errMsg = error + '';
    //     var res = errMsg.split(":");
    //     var resMsg = res[res.length - 1];
    //     var msg = (resMsg)? resMsg: errMsg
    //     //alert(msg)

    //     this.popupmodal.showError(msg,null).subscribe((res:any) => {})

    //   }
    // );



  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

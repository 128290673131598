<div class="">

    <div class="container" *ngIf="PageLoading">
        
      <div>
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>  
      </div>   

    </div>
    <div class="container"  *ngIf="!PageLoading">
        <div class="mt-3">
            <div class="pb-5">
                <div class="mb-4">
                  <div class="">
                    <div class="divshbreadcum mt-4 mb-4 pt-2 pb-2 pe-3 ps-3 d-none d-sm-block">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="shbreadcum">
                              <table>
                                <tr>
                                  <td class="separator">
                                    <h6 class="pt-2 mt-1 me-3"><strong><a href="/" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 mt-2 ms-3">
                                        <strong class="c1"><font class="text-muted">Profile Detail
                                          </font></strong>
                                    </h6>                                  
                                  </td>  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                    </div>

                    <!-- Breadcrumb mobile -->
                    <div class="d-block d-sm-none">
                      <div class="divshbreadcum mt-3 mb-3 pt-2 pb-2 pe-3 ps-3 ">
                        <div class="row">
                          <div class="col-12">
                            <div class="pt-1">
                              <strong class="c1">
                                <!-- <a class="c1 pointer" href="/procurement/rating/procurement-rating-list">Vendor</a> > -->
                                <font class="text-muted">Profile Detail
                                </font>
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>  
                  <div class="card border-0  mb-3 divshbreadcum">

                    <div class="container">
                        <div class="row">

                          <form [formGroup]="UserForm" (ngSubmit)="onSubmit()" class="ps-0 pe-0">
                            <div class="col-sm-12 mt-0 pt-3  fhbgcyan text-white pb-3 rounded-top ps-3 ps-sm-5">
                                <h4 class="mb-0 fw-bold">Profile Detail</h4>
                            </div>
    
                            <div class="col-12 p-2 p-sm-3 pt-sm-5">
                                <div class="row g-0 justify-content-center pb-sm-5">
                                    <div class="col-sm-12 col-md-12 col-lg-7 pt-3 pb-3" *ngIf="UserFormInvalid">
            
                                        <div class="alert alert-danger alert-dismissible fade show rounded-1 errorNotif" role="alert">
                                            <strong>Warning</strong> : Please check all required input.
                                            <!-- <p class="btn-close errorNotif" (click)="closerfqFormInvalid()"></p> -->
                                        </div>
                                    </div>
        
                                    <div class="col-sm-12 col-md-12 col-lg-8 pt-3 pb-3">

                                        <div class="row">
                                            <div class="col-sm-8 order-last order-sm-first">

                                                <!-- <div class="mb-3 row">
                                                    <label for="currency" class="col-sm-4 col-form-label">Company Name</label>
                                                    <div class="col-sm-8">
                                                        <p class="m-0 p-1">{{ValCompanyname}}</p>
                                                    </div>
                                                </div> -->
                
                                                <div class="mb-sm-3 row">
                                                    <label for="name" class="col-4 col-form-label">Name <font class="text-danger" *ngIf="editmode">*</font></label>
                                                    <div class="col-8">
                                                        <p class="m-0 p-1" *ngIf="viewmode">{{Valname}}</p>
                                                        <input type="text" *ngIf="editmode" [(ngModel)]="Valname" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.name?.errors }" placeholder="First Name" />
                                                        <div *ngIf="submitted && f?.name?.errors" class="invalid-feedback">
                                                            <div *ngIf="f?.name?.errors?.required">Name is required</div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-sm-8" *ngIf="editmode">
                                                        <input type="text" class="form-control" value="{{Valname}}" disabled>   
                                                    </div> -->
                                                </div>                           
                
                                              
                                                <div class="mb-sm-3 row">
                                                    <label for="email" class="col-4 col-form-label">Email <font class="text-danger" *ngIf="editmode">*</font></label>
                                                    <div class="col-8">
                                                        <p class="m-0 p-1" *ngIf="viewmode">{{Valemail}}</p>
                                                        <input type="text" *ngIf="editmode" [(ngModel)]="Valemail" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f?.email?.errors }" placeholder="admin@mail.com"  />
                                                        <div *ngIf="submitted && f?.email?.errors" class="invalid-feedback">
                                                            <div *ngIf="f?.email?.errors?.required">Email is required</div>
                                                            <div *ngIf="f?.email?.errors?.pattern">Email must be a valid email address</div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-sm-8" *ngIf="editmode">
                                                        <input type="text" class="form-control" value="{{Valemail}}" disabled>
                                                    </div> -->
                                                </div> 

                                                <div class="mb-sm-3 row"  *ngIf="!isChangePassword">
                                                  <label class="col-4 col-form-label"></label>
                                                  <div class="col-8">
                                                      <p class="">
                                                        <a class="btn btn-link linkcghpass p-1" (click)="ChangePassword()">Change Password</a>
                                                      </p>
                                                  </div>
                                                </div>     

                                                <form  [formGroup]="ChangePassForm" (ngSubmit)="onSubmitChangePass()" class="ps-0 pe-0" >
                                                  <div class="mb-2 mb-sm-3 row" *ngIf="isChangePassword">
                                                      <label for="oldpassword" class="col-4 col-form-label">Old password <font class="text-danger">*</font></label>
                                                      <div class="col-8">
                                                          <input type="password" minlength="8" maxlength="64" [(ngModel)]="Valoldpassword" formControlName="oldpassword" class="form-control" [ngClass]="{ 'is-invalid': chgpasssubmitted && g?.oldpassword?.errors }" placeholder="Old Password" />
                                                          <div *ngIf="chgpasssubmitted && g?.oldpassword?.errors" class="invalid-feedback">
                                                              <div *ngIf="g?.oldpassword?.errors?.required">Old password is required</div>
                                                              <div *ngIf="chgpasssubmitted && g?.oldpassword?.errors?.minlength">Old password must be at least 8 characters</div>
                                                          </div>
                                                      </div>
                                                  </div>   

                                                  <div class="mb-2 mb-sm-3 row" *ngIf="isChangePassword">
                                                    <label for="newpassword" class="col-4 col-form-label">New password <font class="text-danger">*</font></label>
                                                    <div class="col-8">
                                                        <input type="password" minlength="8" maxlength="64" [(ngModel)]="Valnewpassword" formControlName="newpassword" class="form-control" [ngClass]="{ 'is-invalid': chgpasssubmitted && g?.newpassword?.errors }" placeholder="New password" />
                                                        <div *ngIf="chgpasssubmitted && g?.newpassword?.errors" class="invalid-feedback">
                                                            <div *ngIf="g?.newpassword?.errors?.required">New password is required</div>
                                                            <div *ngIf="chgpasssubmitted && g?.newpassword?.errors?.minlength">New password must be at least 8 characters</div>
                                                        </div>
                                                        
                                                    </div>
                                                  </div>   
                                                  <div class="mb-2 mb-sm-3 row" *ngIf="isChangePassword">
                                                    <label for="renewpassword" class="col-4 col-form-label">Re-type new password <font class="text-danger">*</font></label>
                                                    <div class="col-8">
                                                        <input type="password" minlength="8" maxlength="64" [(ngModel)]="Valrenewpassword" formControlName="renewpassword" class="form-control" [ngClass]="{ 'is-invalid': chgpasssubmitted && g?.renewpassword?.errors }" placeholder="Re-type new password" />
                                                        <div *ngIf="chgpasssubmitted && g?.renewpassword?.errors" class="invalid-feedback">
                                                            <div *ngIf="g?.renewpassword?.errors?.required">Re-type new password is required</div>
                                                            <div *ngIf="chgpasssubmitted && g?.renewpassword?.errors?.minlength">Re-type new password must be at least 8 characters</div>
                                                        </div>
                                                        <div
                                                        class="errtxt"
                                                        role="alert"
                                                        *ngIf="chgpasssubmitted && g.renewpassword.errors?.mustMatch" 
                                                        >
                                                        <div>Password must same with Re-type Password</div>
                                                        </div>
                                                    </div>
                                                  </div>  
                                                  <div class="mb-sm-3 row"  *ngIf="isChangePassword">
                                                    <label class="col-4 col-form-label"></label>
                                                    <div class="col-8">
                                                        <p class="m-0 p-0">
                                                          <button class="btn btn-primary fhbgcyan text-white">Update Password</button>
                                                        </p>
                                                    </div>
                                                  </div>     
                                                </form>
                                                
                                                <div class="mb-sm-3 row">
                                                    <label class="col-4 col-form-label"></label>
                                                    <div class="col-8">
                                                        <p class="m-0 p-0" *ngIf="verify=='0'">
                                                          <a class="btn btn-primary resend" *ngIf="!completeresendemail" (click)="resendEmail(Valemail,ValcompanyStaffId)"><i class="bi bi-envelope"></i> Resend Email Verification</a>
                                                          
                                                          <a class="btn btn-primary resend bg2 disabled" *ngIf="completeresendemail"><i class="bi bi-check2"></i> Email Verification Sent</a>
                                                        </p>
                                                    </div>
                                                </div>

                                           

                                            </div>
                                            <div class="col-sm-auto text-end order-first order-sm-last">
                                                <div class="text-center mb-3">
                                                    <svg _ngcontent-alg-c113="" width="120px" height="150px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false" class="bd-placeholder-img"><rect _ngcontent-alg-c113="" width="100%" height="100%" fill="#b1b1b1"></rect></svg><br>
                                                    <strong>Staff Profile</strong>
                                                    <br><br>
                                                    <!-- <div *ngIf="(userID)">
                                                      <strong class="">Status</strong>
                                                      <br>
                                                      <div class="badgeStat text-success" *ngIf="ValisActive=='true'"><strong>Active</strong></div>
                                                      <div class="badgeStat text-danger" *ngIf="ValisActive=='false'"><strong>Disabled</strong></div>
                                                    </div> -->

                                                    
                                                </div>
                                                

                                                
                                            </div>
                                        </div>




                                        <table class="table table-hover mt-4 mb-5 mb-sm-2" id="companytable">
                                          <thead>
                                              <tr>
                                                  <th scope="col">Company Name</th>
                                                  <th scope="col">Role</th>
                                                  <th scope="col">Status</th>
                                                  <th scope="col" class="text-center">Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>


                                              <tr *ngFor="let data of listRegisterdCompany; let i = index">
                                                <td class="pf">{{data.companyName}}</td>
                                                <td class="pf">{{getRole(data.roleId)}}</td>
                                                <td class="text-center m-1">
                                                  <div class="badgeStat text-success" *ngIf="data.isActive"><strong>Active</strong></div>
                                                  <div class="badgeStat text-danger" *ngIf="!data.isActive"><strong>Disabled</strong></div>
                                                </td>
                                                <td class="m-1 text-center">
                                                  <a class="btn btnleave" (click)="ConfirmLeave(data.staffId,data.companyName)">Leave Company</a>

                                                </td>
                                              </tr>


                                          </tbody>
                                      </table>
                                        

                                        <!-- <div class="infomsg w-100 ps-3 pe-3 mb-4">Staff Role and Status</div>


                                        <div class="mb-3 row">
                                            <div class="col-sm-8">
                                                <div class="mb-3 row">
                                                    <label for="roleMembershipId" class="col-sm-4 col-form-label">Role</label>

                                                    <div class="col-sm-8">
                                                        <p class="m-0 p-1">{{getRoleName()}}</p>
                                                    </div>
                                                </div> 

                                                <div class="mb-3 row" *ngIf="(userID)" >
                                                    <label for="isActive" class="col-sm-4 col-form-label">Status</label>

                                                    <div class="col-sm-8">
                                                        <p class="m-0 p-1">{{(ValisActive)?'Active':'Disable'}}</p>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="col-sm-4">

                                            </div>
                                        </div>                -->
     
                                    </div>

                                </div>
                            </div>

                          </form>                          



                        </div>
                    </div>

                  </div>
                </div>
            </div>    
        </div>
    </div>
</div>

<!-- Fixed Bottom Button -->
<div class="fixed-bottom d-none" *ngIf="!PageLoading">
  <div class="bg-white pt-3 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <a class="btn btn-link  rounded-1 roundedmore ps-4 pe-4 c1" href='/'><strong>Back</strong></a>
        </div>
        <div class="col-6 text-end">

          <a *ngIf="!viewmode" class="btn btn-link  rounded-1 roundedmore ps-5 pe-5 c1" (click)="ChangeToView()"><strong>Cancel</strong> </a>

          <a *ngIf="!viewmode" class="btn btn-primary fhbcyan rounded-1 roundedmore fixwbtn ms-3 ps-4 pe-4" (click)="onSubmit()">{{labelActionBtn}}</a>

          <a *ngIf="viewmode" (click)="ChangeToEdit()" class="btn btn-primary fhbcyan rounded-1 roundedmore fixwbtn ms-3 ps-4 pe-4 fhbdark  {{(loading) ? 'disable disabled' : ''}}">{{(loading) ? 'Loading' : labelActionBtn}}</a>



        </div>
      </div>
    </div>
  </div>
</div>



    <!-- Success Modal -->
    <div class="modal" id="successModal" tabindex="-1" role="dialog" [ngStyle]="{'display':successnotif}">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded-0">
          <div class="modal-body pt-4 pb-4 ps-4 pe-4">
            <div class="text-center">
              <img class="saveimgicon" src="/assets/images/icons/done-save.png">
            </div>
            <h3 class="text-center mt-3 mb-2 f1">Success!</h3>
            <p class="text-center mt-0 mb-4 fw-bold" *ngIf="!userID">We have sent an invitation email to the staff.</p>
            <p class="text-center mt-0 mb-4 fw-bold" *ngIf="userID">Staff has been successfully updated!</p>
            

            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
              <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="redirect()">DONE</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Error Modal -->
    <div class="modal" id="errorModal" tabindex="-1" role="dialog" [ngStyle]="{'display':errornotif}">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content rounded-0">
            <div class="modal-body pt-4 pb-4 ps-4 pe-4">
              <div class="text-center">
                <img class="saveimgicon" src="/assets/images/error.png">
              </div>
              <h3 class="text-center mt-3 mb-2 f1">Error!</h3>
              <p class="text-center mt-0 mb-4 fw-bold">{{errortext}}</p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
                <!-- <a class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="closeErrorModal()">CLOSE</a> -->

                <a *ngIf="!goToPrevpage" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" (click)="closeErrorModal()">CLOSE</a>

                <a *ngIf="goToPrevpage" class="btn btn-secondary f1 bg1 fw-bold pt-2 pb-2 pe-5 ps-5 pointer" href="/setting/company-management">CLOSE</a>

              </div>
            </div>
          </div>
        </div>
    </div>
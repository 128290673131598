import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {gql, Apollo} from 'apollo-angular';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { DataService } from 'src/app/_services/data';
import { Subscription } from 'rxjs';

const mutationVerify = gql`
mutation verifyEmail($verificationToken: String!) {
  verifyEmail(input: {
    verificationToken: $verificationToken}) {
      registrationId
      isAlreadyVerified
  }
}
`;

const mutationSubmitData = gql`
mutation inputUserDetailInfo($registrationId: ID!,$password: String!,$fullname: String!,$secondaryEmail: String) {
  inputUserDetailInfo(input: {
      registrationId: $registrationId
      password: $password
      fullname: $fullname
      secondaryEmail: $secondaryEmail
  })
}
`;


@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html', 
  styleUrls: ['./thank-you.component.scss']
})
export class ThankyouComponent implements OnInit {
  state:string='alredyverified'
  subscription: Subscription | any;

  constructor(
    private dataShare: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
        console.log('thank you this.dataShare.currentMessage : ',this.dataShare.currentMessage)
         this.subscription = this.dataShare.currentMessage.subscribe((message: string) => {
            this.state = message
            if(message==''){
              this.state = 'redirect'

              setTimeout(() => {
                this.router.navigate(['/']);
              }, 1500);  //1s

            }
         })
         console.log('thankyou this.message',this.state);
         //console.log('verify this.subscription',this.subscription);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

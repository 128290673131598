import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalComponent } from './components/modal/modal.component';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { DecryptPipe, EncryptPipe } from './_helpers/enctypt-decrytp.pipe';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Apollo, gql } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { Popupmodal } from './_helpers/popupmodal';
import { CompanyService } from './_services/company.services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import CheckErrortype from './_helpers/check-errortype';
import { filter } from 'rxjs';
// import { MatomoTracker } from '@ngx-matomo/tracker';

declare const statistic: any;

const gettac = gql`
query{
  registrationTermAndCondition{
      id
      version
      description
  }
}
`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CepetDapet';
  cookie:boolean = false
  modalCookie:boolean = false
  modalUpdateTos:boolean = false
  display:string='block'
  tac: any;

    // Strictly Necessary 
    // Performance Cookies 
    // Functional Cookies 
    // Targeting Cookies 

    // all = S,P,F,T

  checkedCookie:string[]=['S','P','F','T']
  closeResult:string=''
  public isCollapsed0 = true;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;

  ValPerformanceCookies:boolean=true
  ValFunctionalCookies:boolean=true
  ValTargetingCookies:boolean=true

  cookieForm = this.formBuilder.group({
    PerformanceCookies: [''],
    FunctionalCookies: [''],
    TargetingCookies: [''],
  });
  TnCsrc:any
  clickCookiePolicy:boolean=false

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private encryptPipe: EncryptPipe,
    private decryptPipe: DecryptPipe,
    config: NgbModalConfig, 
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    protected _sanitizer: DomSanitizer,
    private apollo: Apollo,
    private http:HttpClient,
    private popupmodal : Popupmodal,
    private companyService: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    // private readonly tracker: MatomoTracker
  ) {
    config.backdrop = 'static';
		config.keyboard = false;


    translate.addLangs(['id', 'en']);

    if(localStorage.getItem('language')=== null){
      localStorage.setItem('language', 'id');
      this.translate.use('id');
      translate.setDefaultLang('id');
    } else {
      var setlang = localStorage.getItem('language') 
      if(setlang){
        this.translate.use(setlang);
        translate.setDefaultLang(setlang);
      }
    }  

    //this.cookieService.set('Test', 'Hello World');
    var getCookieClient = this.cookieService.get('cd_cookie')
    console.log('get cookieess :: ',getCookieClient);
    console.log('get cookieess decripy :: ',this.decryptPipe.transform(getCookieClient));
    this.cookie = this.decryptPipe.transform(getCookieClient) ? true : false


    // Set Open Graph tags for the index page
    this.metaService.updateTag({ property: 'og:title', content: 'CepetDapet' });
    this.metaService.updateTag({ property: 'og:description', content: 'Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor. Kami bukan market place dan tidak menarik komisi.' });
    this.metaService.updateTag({ property: 'og:image', content: 'https://cepetdapet.com/assets/Screenshot.png' });
    this.metaService.updateTag({ property: 'og:url', content: 'https://cepetdapet.com' });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });

    this.metaService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
    this.metaService.updateTag({name: 'twitter:domain', content: 'cepetdapet.com'});
    this.metaService.updateTag({name: 'twitter:url', content: 'https://cepetdapet.com/'});
    this.metaService.updateTag({name: 'twitter:title', content: 'CepetDapet'});
    this.metaService.updateTag({name: 'twitter:description', content: 'Aplikasi dengan cara yang lebih baik untuk menemukan dan menghubungi Procurement dan Vendor. Kami bukan market place dan tidak menarik komisi.'});
    this.metaService.updateTag({name: 'twitter:image', content: 'https://cepetdapet.com/assets/Screenshot.png'});

    this.setTitleDynamic()
  }

  @ViewChild('cookieModal') cookieModal : any;
  @ViewChild('tosModal') tosModal : any;
  
  ngOnInit(): void {

    console.log('app NEEDCONFIRMTOS >> ',localStorage.getItem('NEEDCONFIRMTOS'))
    console.log('app COMPANYSELECTID >> ',localStorage.getItem('COMPANYSELECTID'))
    // if any update in TOS
    if(localStorage.getItem('NEEDCONFIRMTOS') && localStorage.getItem('COMPANYSELECTID')){
      this.showModalTos()
    }

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // )
    //   .subscribe(() => {
    //     var rt = this.getChild(this.activatedRoute)
 
    //     rt.data.subscribe((data:any) => {
    //       console.log('MATOMO datart :  ',data)
    //       console.log('MATOMO this.router.url :  ',this.router.url)
    //           //matomo 
    //           this.tracker.trackPageView();
             
    //           // With custom page title
    //           this.tracker.trackPageView(data.title + ' | CepetDapet');
    //           this.tracker.setCustomUrl(this.router.url);
    //     })
    //   }
    // )




  }
  accpetAll(){
    //this.modalCookie = this.modalCookie?false:true
    this.sumbitCookies()
  }
  acceptSelected(){

    if(this.ValPerformanceCookies==false){
      var index = this.checkedCookie.indexOf('P');
      if (index > -1) { 
        this.checkedCookie.splice(index, 1); 
      }
    }

    if(this.ValFunctionalCookies==false){
      var index = this.checkedCookie.indexOf('F');
      if (index > -1) { 
        this.checkedCookie.splice(index, 1); 
      }
    }

    if(this.ValTargetingCookies==false){
      var index = this.checkedCookie.indexOf('T');
      if (index > -1) { 
        this.checkedCookie.splice(index, 1); 
      }
    }
    console.log('checkedCookie :: ',this.checkedCookie)
    this.sumbitCookies()
  }
  sumbitCookies(){

    // cpd_cookie

    var selectedCookies = this.checkedCookie.toString()
    var encrypt = this.encryptPipe.transform(selectedCookies)
    var decrypt = this.decryptPipe.transform(encrypt)
    console.log('encrypt encryptPipe :: ',encrypt)
    console.log('decrypt decryptPipe :: ',decrypt)
    this.cookieService.set( 'cd_cookie', encrypt, {expires: 90}); //3mo
    this.modalCookie = true 
  }
  cookiesSetting(){
    // const modalRef = this.modalService.open(ModalComponent,{
    //   modalDialogClass:'modal-dialog-centered',
    //   centered: true,
    //   backdrop : 'static',
    //   keyboard : false,
    //   size: 'lg' 
    // })
    //   modalRef.componentInstance.modalnotification = 'modalnotification'
    //   //modalRef.componentInstance.notificationSubject = 'subject'
    //   modalRef.componentInstance.notificationContent = 'content'
    //   // modalRef.componentInstance.notificationTime = 'time'

    //this.modalService.open(this.cookieModal);
    this.modalCookie = this.modalCookie?false:true
		this.modalService.open(this.cookieModal, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
        console.log('reason 1== ',result)
        //this.modalCookie = this.modalCookie?false:true
				this.closeResult = `Closed with: ${result}`;
        this.acceptSelected()
			},
			(reason) => {
        console.log('reason 2== ',reason)
        this.modalCookie = this.modalCookie?false:true
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
  }
	private getDismissReason(reason: any): string {
    console.log('reason :: ',reason)
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  onSubmit(){

  }
  onCheckPerformanceCookies(check: any){
    if(check.target.checked){
      console.log('onCheckPerformanceCookies checked')
      this.ValPerformanceCookies=true
    } else {
      console.log('onCheckPerformanceCookies unchecked')
      this.ValPerformanceCookies=false
    }
  }
  onCheckFunctionalCookies(check: any){
    if(check.target.checked){
      console.log('FunctionalCookies checked')
      this.ValFunctionalCookies=true
    } else {
      console.log('FunctionalCookies unchecked')
      this.ValFunctionalCookies=false
    }
  }
  onCheckTargetingCookies(check: any){
    if(check.target.checked){
      console.log('TargetingCookies checked')
      this.ValTargetingCookies=true
    } else {
      console.log('TargetingCookies unchecked')
      this.ValTargetingCookies=false
    }
  }

  showModalTos(){
    this.modalUpdateTos=true
    this.getTerm();
  }
  ShowCookiePolicy(){
    this.modalUpdateTos=true
    this.clickCookiePolicy=true
    this.getTerm();
  }
  getTerm(){
    this.apollo.watchQuery<any>({
      query: gettac
    })
    .valueChanges
    .subscribe(({data, loading}) => {
      console.log(loading);
      this.tac = data.registrationTermAndCondition;
      console.log('tac',this.tac);

      this.TnCsrc = {
        url: this.tac?.description,
        withCredentials: false
      }

      //this.modalUpdateTos = this.modalUpdateTos?false:true
      this.modalService.open(this.tosModal, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          console.log('reason 1== ',result)
          //this.modalCookie = this.modalCookie?false:true
          // alert(`Closed with: ${result} , remove storegeTOS`);



          this.confirmAgreeTos()
        },
        (reason) => {
          console.log('reason 2== ',reason)
          //this.modalUpdateTos = this.modalUpdateTos?false:true
          //alert(`Dismissed ${this.getDismissReason(reason)}`);
        },
      );

    })
  }
  confirmAgreeTos(){
    // localStorage.removeItem("NEEDCONFIRMTOS")
    // localStorage.removeItem("LATESTTOS")
    // location.reload()

    if(this.clickCookiePolicy){
      this.clickCookiePolicy=false
      return
    }

    // var companyId= (localStorage.getItem("COMPANYSELECTID")) ? localStorage.getItem("COMPANYSELECTID") :''
    var tosId= (localStorage.getItem("LATESTTOS")) ? localStorage.getItem("LATESTTOS") :''
    
    this.companyService.getagreeToTermCondition(tosId).subscribe((res:any) => {
      console.log(res)
  
      if(res.data?.agreeToTermCondition){
        console.log("succes, get data agreeToTermCondition:", res);
        localStorage.removeItem("NEEDCONFIRMTOS")
        localStorage.removeItem("LATESTTOS")

        this.popupmodal.showSuccess('Term & Condition applied. ','/tos-applied').subscribe((res:any) => {

        })
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }

        if(res.errors[0].extensions.type=='UnauthorizedException'){
          this.popupmodal.showError('Need owner to confirm Term & Condition','/logout').subscribe((res:any) => {})
        } else {
          this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
        }
        

        
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });


  }
  getSafeUrl(fileName: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(fileName);
  }

  setTitleDynamic(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe((data:any) => {
          console.log('setTitleDynamic',data);
          this.titleService.setTitle(data.title + ' | CepetDapet')
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            //this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            //this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            //this.metaService.removeTag("property='og:image'")
          }
 


          setTimeout(() => {
            //add matomo
            console.log('add matomo in app compenent : ',window.location.pathname)
            statistic(window.location.pathname);
          }, 700);  //.7s

        })
 
      })
  }
  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
}

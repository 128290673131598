import { Component, HostListener , OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { isArray } from 'util';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isLogin:boolean=false
  showtotop:boolean=false

  public href: string = "";
  isHomePage: boolean=false;
  isSearchPage: boolean=false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // console.log('isLoggedIn',this.authService.getJwtToken())
    // if(this.authService.getJwtToken()){
    //   this.isLogin = true
    // }
    if (localStorage.getItem("COMPANYSELECTID") !== null) {
      this.isLogin = true
    } 

    this.href = this.router.url;
    console.log('this.href : ',this.href);

    if(this.href=='/'){
      this.isHomePage=true
    }


    var arrUrl = this.href.split("/")
    console.log('this.router.url : ',this.router.url)
    console.log('arrUrl : ',arrUrl)
    if(Array.isArray(arrUrl)){
      if(arrUrl[1]=='search' || arrUrl[1]=='search-rfq' || arrUrl[1]=='product' || arrUrl[1]=='s'){
        this.isSearchPage=true
      }
      if(arrUrl[1]=='help' || arrUrl[1]=='subscription' || arrUrl[1]=='contact-us'){
        this.isLogin=false
      } 
    }

  }

  gototop() {
    window.scroll(0,0)
  }

  currentPosition = window.pageYOffset;
  @HostListener('window:scroll', ['$event']) 
  onScroll(event:any) {
    //console.log('event',event);
    this.currentPosition = window.pageYOffset;
    //console.log('currentPosition',this.currentPosition);

    if (window.pageYOffset > 300) {
      this.showtotop=true
    } else {
      this.showtotop=false
    }

  }

}

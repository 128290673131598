
      <main class="bggraddiv">
        <div class="container">
            
            <div class="row justify-content-center 
            pt-5 pb-5 mb-2 
            ps-3 pe-3
            ps-sm-0 pe-sm-0
            ps-md-0 pe-md-0
            ps-lg-0 pe-lg-0
            g-0" *ngIf="page=='login'">
                <div class="col-sm-12 col-md-12 col-lg-9 divshadow">
                    <div class="row g-0">
                        <div class="col-sm-12 col-md-6 col-lg-6 bglogin"></div>
                        <div class="col-sm-12 col-md-6 col-lg-6 bg-white">
                            <div class="w-100 text-center fhgrad">
                                <a href="/"><img class="img-fluid fh-logo-div pt-2 pb-2" src="/assets/images/logofh-cepetdapet.png"></a>
                            </div>
                            <div class="paddinglogin divlogin">
                                <h3 class="f1 pb-0 mb-0 texttitlelogin"><strong>Welcome Back!</strong></h3>
                                <p class="f1l pt-0 mt-0 mb-4 textlogin">Login to your account</p>

                                <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate >
                                    <div class="form-group mb-3">
                                        <label for="email">Email</label>
                                        <input
                                        type="text" placeholder="your@email.com"
                                        class="form-control"
                                        name="email"
                                        [(ngModel)]="form.email"
                                        required
                                        #email="ngModel"
                                        />
                                        <div
                                        class="errtxt"
                                        role="alert"
                                        *ngIf="email.errors && f.submitted"
                                        >
                                        Email is required
                                        </div>
                                    </div>
                                    <div class="form-group mb-1">
                                        <label for="password">Password</label>
                                        <!-- <input
                                        type="password" 
                                        placeholder="Type your password"
                                        class="form-control"
                                        name="password"
                                        [(ngModel)]="form.password"
                                        required
                                        minlength="8"
                                        maxlength="64"
                                        #password="ngModel"
                                        /> -->


                                        <div class="input-group mb-3">
                                            <input type="{{(showPass)?'text':'password'}}" 
                                            placeholder="Type your password"
                                            class="form-control passwordeyes pointer"
                                            name="password"
                                            [(ngModel)]="form.password"
                                            required
                                            minlength="8"
                                            maxlength="64"
                                            #password="ngModel">
                                            <span class="input-group-text eyesicon">
                                                <i (click)="showPassword()" *ngIf="showPass" class="bi bi-eye opacity-50"></i>
                                                <i (click)="showPassword()" *ngIf="!showPass" class="bi bi-eye-slash opacity-50"></i>
                                            </span>
                                          </div>


                                        <div
                                        class="errtxt"
                                        role="alert"
                                        *ngIf="password.errors && f.submitted"
                                        >
                                        <div *ngIf="password.errors.required">Password is required</div>
                                        <div *ngIf="password.errors.minlength">
                                            Password must be at least 8 characters
                                        </div>
                                        </div>
                                    </div>
                                    <div class="form-group">

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-check">
                                                    <input #checkboxRemember class="form-check-input" type="checkbox" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" id="flexCheckChecked" (change)="changeChecked(checkboxRemember.checked)">
                                                    <label class="form-check-label rememberme" for="flexCheckChecked">
                                                    Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-6 text-end">
                                                <a class="c1 rememberme pointer" (click)="showdiv('forgotpassword')"><strong>Forgot Password?</strong></a>
                                            </div>
                                        </div>


                                    </div>
    
                                    <div class="form-group mt-3" *ngIf="requiredCapcha">
                                        <re-captcha
                                        [(ngModel)]="cpatchaText"
                                        name="captcha"
                                        required
                                        siteKey="{{config.captchaKey}}"
                                        ></re-captcha>
    
                                        <div class="errmsg"  [hidden]="f?.form?.valid">
                                            Captcha is required
                                        </div>
    
                                    </div>
                                    
    
                                    <div class="form-group mt-3">
                                        <button *ngIf="!pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2">
                                        Login
                                        </button>
                                        <button *ngIf="pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2" disabled>
                                        Loading..
                                        </button>
                                        
                                    </div>
                                    <div class="form-group mt-3 text-center">
                                        <div class="col-12">
                                        
                                        </div>
                                        <div class="col-12 tb">
                                            <p class="c1 rememberme">
                                                <strong>
                                                Do not have account yet? 
                                            <a href="regis" class="c1 rememberme">Register here</a> </strong></p>    
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <div
                                        class="alert alert-danger mt-1 p-2"
                                        role="alert"
                                        *ngIf="f.submitted && isLoginFailed"
                                        >
                                        {{ errorMessage }}
                                        </div>
                                    </div>
                                </form> 
                            </div>
                               
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center pt-5 pb-5 mb-2 g-0" *ngIf="page=='forgotpassword'">
                <div class="col-sm-12 col-md-12 col-lg-9 divshadow">
                    <div class="row g-0">
                        <div class="col-sm-12 col-md-6 col-lg-6 bgforgot"></div>
                        <div class="col-sm-12 col-md-6 col-lg-6 bg-white">
                            <div class="w-100 text-center fhgrad">
                                <a href="/"><img class="img-fluid fh-logo-div pt-2 pb-2" src="/assets/images/logofh-cepetdapet.png"></a>
                            </div>
                            <div class="paddinglogin divlogin">
                                

                                <h3 class="f1 pb-0 mb-0 texttitlelogin"><strong>Forgot your password?</strong></h3>
                                <p class="f1l pt-0 mt-0 mb-4 textlogin">Stuff happens. Please enter your email address below and we will send you a link to reset your password!</p>

                                <form [formGroup]="forgotForm" (ngSubmit)="submitforgot()">
                                    <div class="form-group mb-3">
                                        <label for="email">Email</label>

                                        <input type="text" [(ngModel)]="ValEmail" formControlName="email" max="254" class="form-control" [ngClass]="{ 'is-invalid': submitedforgot && g?.email?.errors }" />
                                        <div
                                        class="errtxt"
                                        role="alert"
                                        *ngIf="submitedforgot && g?.email?.errors"
                                        >
                                        <div *ngIf="g?.email?.errors?.required">Email is required</div>
                                        <!-- <div *ngIf="f?.email?.errors?.email">Email must be a valid email address</div> -->
                                        <div *ngIf="g?.email?.errors?.pattern">Email must be a valid email address</div>
                                        </div>


                                    </div>

    
    
                                    <div class="form-group mt-3">
                                        <button *ngIf="!pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2">
                                        Reset Password
                                        </button>
                                        <button *ngIf="pageloading" class="f1 btn btn-secondary btn-block w-100 bg1 pt-2 pb-2" disabled>
                                        Loading..
                                        </button>
                                        
                                    </div>
                                    <div class="form-group mt-3 text-center">
                                        <div class="col-12">
                                            <!-- ValEmail : {{ValEmail}} -->
                                        </div>
                                        <div class="col-12 tb">
                                            <p class="c1 rememberme">
                                                <strong>
                                                    Already have account? 
                                            <a (click)="showdiv('login')" class="c1 rememberme pointer">Login here</a> </strong></p>    
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <div
                                        class="alert alert-danger mt-1 p-2"
                                        role="alert"
                                        *ngIf="submitedforgot && isForgotFailed!==''"
                                        >
                                        {{ errorMessage }}
                                        </div>
                                    </div>
                                </form> 
                            </div>
                               
                        </div>
                    </div>
                </div>
            </div>


    <div *ngIf="forgotcompleted">


        <div class="row justify-content-md-center pt-5 pb-5">

            <div class="col-auto">
              
              <div class="card divshadow mt-5 mb-5 border-0">
                <div class="card-body p-0 border-0">
                    <div class="card-line-danger" *ngIf="!forgotresstatus"></div>
                    <div class="card-line" *ngIf="forgotresstatus"></div>

                    <div class="p-5">
                        <div class="text-center">
                            <img *ngIf="!forgotresstatus" class="draftimgicon" src="/assets/images/icons/Group 518-2@2x.png" >
                            <img *ngIf="forgotresstatus" class="draftimgicon" src="/assets/images/icons/Group 518@2x.png">
                        </div>
                        <br>
                        <div class="row justify-content-center mb-3">
                            <div class="col-9 text-center ">
                                <h3 class="f1" *ngIf="!forgotresstatus">Error!</h3>
                                <h3 class="f1" *ngIf="forgotresstatus">Success!</h3>
                                <!-- <h5 class="card-title f1l">Sorry, an error occured. The token is expired or invalid.</h5> -->
                                <p class="mt-0 mb-4">{{forgotresmsg}}</p>
                            </div>
                        </div>
                        <div class="text-center">
                            <a href="/" *ngIf="!forgotresstatus" class="text-center f1 btn btn-secondary btn-block w-50 btn-failed pt-2 pb-2">Back</a>
                            <a *ngIf="forgotresstatus" class="btn btn-secondary f1 btn-success fw-bold pt-2 pb-2 pe-5 ps-5" data-bs-dismiss="modal" href='/'>Done</a>
                        </div>
                    </div>


                </div>
              </div>    
            </div>  
        </div>  


    </div>





            <div class="bggradlayer"></div> 
        </div>
        
                          
                                           
        
                        
      </main>
<div class="">
<app-footer></app-footer>
</div>
import { ActivatedRoute } from "@angular/router";

export default class Util {
    // ========================================== //
    // ReqType      : 'query' / 'mutation' 
    // ReqName      : ex :: rfqList / insertRfq / etc..
    // ArrParams    : ex :: { 'param':'companyLocationId', 'value':'01F84XE4F2MJY046GT19CBW15W', 'type':'ID!' }
    // Res          : ex :: '__typename,      ...on CompanyProduct {id          name          brand    description'          
    // ========================================== //
    static setQueryGQL(ReqType:string,ReqName:any,ArrParams:any,Res:string) { 

        var head = ``+ReqType+` `+ReqName+``

        var head_var='';
        var headvar
        var headvars: string[] = [];

        let body = '';
        var field
        var fields: string[] = [];

        var EditVar:any={}

        if(Array.isArray(ArrParams)){
            ArrParams.forEach((val:any) => {

                var parameter = ''
                parameter = val['param']
                var type = ''
                type = val['type']

                if(parameter!=='' && type!==''){
                    headvars.push('$'+parameter+': '+type+'')
                } 
                if(parameter!==''){
                    fields.push(''+val['param']+' : $'+val['param']+'')
                }
                
                if(val['value']!==''){
                    EditVar[parameter] = val['value'];
                   // Object.assign(EditVar, {parameter: val['value']});
                }
            })
        }

 
        if(headvars.length>0){
            head_var+='('
            for (headvar in headvars) {
                if (typeof headvar[headvar] !== 'object') {
                    head_var += headvars[headvar].toString() + ', ';
                }    
            }
            head_var+=')'
        }

        if(fields.length>0){
            console.log('>0');
            for (field in fields) {
                if (typeof fields[field] !== 'object') {
                    body += fields[field].toString() + ' ';
                }  
            }
        }       

        const query = ``+head+` `+head_var+` {  `+ReqName+`(input: { `+body+` }) { `+Res+` } }` ;
        console.log('test query ',query);

        console.log('EditVar',EditVar);
        var generate = []
        generate.push({"gql":query})
        generate.push({"variable":EditVar})
        return generate

    }

    // static setVariableGQL(ArrParams:any) { 
    //     console.log('ArrParams',ArrParams);
    //     var SetRequest={}
    //     if(Array.isArray(ArrParams)){
    //         ArrParams.forEach((val:any) => {
    //             var parameter = ''
    //             parameter = val['param']
    //             var value = ''
    //             value = val['value']

    //             if(parameter!=='' && value!==''){
    //                 Object.assign(SetRequest, {parameter : value});
    //             } 
    //         })
    //     }
    //     console.log('SetRequest',SetRequest);
    //     return SetRequest
    // }


    static formatShortDesc(shortdesc: any) {
        var desctext = shortdesc;
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
          return desctext.replace(getdetaildesc[0],'')
        } else {
          return shortdesc;
        }
    }
    static formatTextSpecDesc(shortdesc: any) {
        var desctext = shortdesc;
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
          return desctext.replace(getdetaildesc[1],'')
        } else {
          return '';
        }
    }

    static fhspectotable(shortdesc: any){
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
            var divFhSpec = getdetaildesc[1]

            if(!this.IsJsonString(divFhSpec)){
                return shortdesc;
            }

            var resdivFhSpec = JSON.parse(divFhSpec)
            if(!Array.isArray(resdivFhSpec)){
                return shortdesc;
            }

            var setTable    =  ''
            setTable       +=  '<table class="table table-bordered w-100">'
            setTable       +=  '<tbody>'

            resdivFhSpec.forEach((e:any) => {
                setTable       +=  '<tr>' 
                setTable       +=  '<td>'+e.label+'</td>' 
                setTable       +=  '<td>'+e.value+'</td>' 
                setTable       +=  '</tr>' 
            });

            setTable       +=  '<tbody>'
            setTable       +=  '</table>'


          return setTable
        } else {
          return shortdesc;
        }


    }

    static fhspectocomma(shortdesc: any){
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
            var divFhSpec = getdetaildesc[1]

            if(!this.IsJsonString(divFhSpec)){
                return '';
            }

            var resdivFhSpec = JSON.parse(divFhSpec)
            if(!Array.isArray(resdivFhSpec)){
                return '';
            }

            var setTable    =  ''
            setTable       +=  '<ul class="specDet">' 
            resdivFhSpec.forEach((e:any) => {
                // setTable       +=  '<div class="row row-cols-auto">' 
                // setTable       +=  '<div class="col">'+e.label+'</div>' 
                // setTable       +=  '<div class="col">:</div>' 
                // setTable       +=  '<div class="col">'+e.value+'</div>' 
                // setTable       +=  '</div>' 
                setTable       +=  '<li>'+e.label+' - '+e.value+'</li>'
            });
            setTable       +=  '</ul>' 

            if(resdivFhSpec.length==0){
                setTable       =  ''
            }

          return setTable
        } else {
          return '';
        }


    }

    static fhspectoarray(shortdesc: any){
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
            var divFhSpec = getdetaildesc[1]

            if(!this.IsJsonString(divFhSpec)){
                return false;
            }

            var resdivFhSpec = JSON.parse(divFhSpec)
            console.log('>>',resdivFhSpec);
            if(!Array.isArray(resdivFhSpec)){
                return false;
            }
          return resdivFhSpec
        } else {
          return false;
        }


    }

    static formatSpecDesc(shortdesc: any){
        var a = shortdesc;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        if(getdetaildesc){
            var divFhSpec = getdetaildesc[1]

            if(!this.IsJsonString(divFhSpec)){
                return '';
            }

            var resdivFhSpec = JSON.parse(divFhSpec)
            if(!Array.isArray(resdivFhSpec)){
                return '';
            }

            var setTable    =  ''
            setTable       +=  '<ul class="specDet">' 
            resdivFhSpec.forEach((e:any) => {
                // setTable       +=  '<div class="row row-cols-auto">' 
                // setTable       +=  '<div class="col">'+e.label+'</div>' 
                // setTable       +=  '<div class="col">:</div>' 
                // setTable       +=  '<div class="col">'+e.value+'</div>' 
                // setTable       +=  '</div>' 
                setTable       +=  '<li>'+e.label+' - '+e.value+'</li>'
            });
            setTable       +=  '</ul>' 


          return setTable
        } else {
          return '';
        }


    }

    static IsJsonString(str:any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static getRole(idRole:any){
        var Rolename =''
        if(idRole=='32'){
        Rolename='Procurement Staff'
        } 
        else if(idRole=='16'){
        Rolename='Vendor Staff'
        }
        else if(idRole=='8'){
        Rolename='Procurement Manage'
        }
        else if(idRole=='4'){
        Rolename='Vendor Manager'
        }
        else if(idRole=='2'){
        Rolename='Senior Manager'
        }
        else if(idRole=='1'){
        Rolename='Owner'
        } else {
        Rolename='Procurement Staff'
        }
        return Rolename
    }

    static redirectDasboard(idRole:string){
        var redirect ='/'

        // 1. Procurement > my rfq
        // 2. Vendor > rfq board
        // 3. S.mgr keatas > my rfq 

        if(idRole=='32'){
            redirect='/procurement/rfq-list'
        } 
        else if(idRole=='16'){
            redirect='/vendor/rfq-board'
        }
        else if(idRole=='8'){
            redirect='/procurement/rfq-list'
        }
        else if(idRole=='4'){
            redirect='/vendor/rfq-board'
        }
        else if(idRole=='2'){
            redirect='/procurement/rfq-list'
        }
        else if(idRole=='1'){
            redirect='/procurement/rfq-list'
        } else {
            redirect='/'
        }
        return redirect
    }


}


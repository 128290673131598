<div class="pt-0 pt-sm-3 pb-3">


  <!-- <input type="text" [(ngModel)]="filter">

  <div [innerHTML]="myAwesomeText | highlight : filter"></div> -->

    <div class="container" *ngIf="PageLoading">
        
      <div>
        <div class="text-center pt-5 pb-5">
          <div class="loader loader--style8" title="7">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
              </rect>
              <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
        </div>  
      </div>   

    </div>
    <div class="container"  *ngIf="!PageLoading">
        <div class="mt-3">
            <div class="pb-5 mb-5">
                <div class="mb-4">
                  <div class="d-none d-sm-block">
                    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="shbreadcum">
                              <table>
                                <tr>
                                  <td class="separatorfh">
                                    <h6 class="pt-2 mt-1 me-3"><strong><a (click)="back()" class="c1 pointer"><img src="/assets/images/icons/ic_round-navigate-next.png" class="backicon"> Back</a></strong></h6>
                                  </td>
                                  <td>
                                    <h6 class="mb-1 mt-2 ms-3">
                                        <strong class="c1"><font class="fh1">Messaging and Communication 
                                          </font></strong>
                                    </h6>                                  
                                  </td>  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>  
                  <!-- Breadcrumb mobile -->
                  <div class="d-block d-sm-none">
                    <div class="divshbreadcum mt-3 mb-4 pt-2 pb-2 pe-3 ps-3 ">
                      <div class="row">
                        <div class="col-12">
                          <div class="pt-1">
                            <strong class="c1">
                              <font class="c2">Messaging and Communication</font>
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  

                  <div class="card border-0  mb-3 divshbreadcum">

                    <div class="container filterdiv">
                        <div class="row">

                          <form [formGroup]="UserForm" (ngSubmit)="onSubmit()" class="ps-0 pe-0">

                            <div class="col-12 text-start ps-2 ps-sm-4 ps-md-4 ps-lg-4 fhbg1 text-white p-2 p-sm-3">
                              <h4 class="m-0 fw-bold">Messaging</h4>
                            </div>
    
                            <!-- <div class="col-12 ps-3 pe-3 pt-4">
                                <div class="row justify-content-center pb-5">
                                    <div class="col-sm-12 col-md-12 col-lg-7 pt-3 pb-3" *ngIf="UserFormInvalid">
            
                                        <div class="alert alert-danger alert-dismissible fade show rounded-1 errorNotif" role="alert">
                                            <strong>Warning</strong> : Please check all required input.

                                        </div>
                                    </div>


                                </div>
                            </div> -->
                            <div>
                              <div class="row g-0 messagingdiv">
                                <div class="col-sm-4 {{isMobileMessage?'d-none':''}}">
                                  <div class="row g-0">
                                    <div class="col-12 pt-3 pb-3 leftsidediv">
                                      <table width="100%">
                                        <tr>
                                          <td class="ps-3" width="auto" *ngIf="!OnBlulk">

                                            <form  [formGroup]="SearchForm" *ngIf="!onSearchFilterMessage">
                                              <div class="input-group">
                                                <input type="text" class="form-control inputSrch" placeholder="Search thread..." formControlName="search_thread" id="search_thread" name="search_thread" [(ngModel)]="valSearchThread"  (keyup.enter)="searchbykeyword()" [ngClass]="{ 'is-invalid': invlaidSearchThread }" autocomplete="off"> 
                                                <a class="btn btn-secondary bgGroupBtn cursorauto inputSrchIcon" (click)="searchbykeyword()" [ngClass]="{ 'is-invalid-search': invlaidSearchThread }"><img src="/assets/images/icons/Group 301@3x.png" class="iconSrch" ></a>
                                              </div>
                                            </form>

                                            <div *ngIf="onSearchFilterMessage">
                                              
                                              <span class="badge rounded-pill bg-secondary me-1" *ngIf="valSearch!==''">{{valSearch}} <i class="bi bi-x pointer" (click)="removeFilter('keyword')"></i> </span>

                                              <span class="badge rounded-pill bg-secondary me-1" *ngIf="Valread!==''">{{Valread}} <i class="bi bi-x pointer" (click)="removeFilter('read')"></i> </span>

                                              <span class="badge rounded-pill bg-secondary me-1" *ngIf="Valstar!==''">{{Valstar}} <i class="bi bi-x pointer" (click)="removeFilter('star')"></i> </span>

                                              <span class="badge rounded-pill bg-secondary me-1" *ngIf="ValminDateStart &&ValminDateTo">{{ ValminDateStartPrev | date:'d MMM y' }} - {{ ValminDateToPrev | date:'d MMM y' }} <i class="bi bi-x pointer" (click)="removeFilter('date')"></i> </span>

                                              <!-- Valstar:string=''
                                              Valread:string=''
                                              ValminDateStart: any = null
                                              ValminDateTo: any = null -->

                                            </div>

                                          </td>
                                          <td class="ps-3 pe-3" width="auto" *ngIf="OnBlulk">  
                                            <div class="row">
                                              <div class="col-6 fh3 fw-bold">
                                                <p class="mb-0 pt-2 pb-2">{{SelectedBulk.length}} Selected</p>
                                              </div>
                                              <!-- <div class="col-2 fh3 fw-bold">
                                                <p class="mb-0 pt-2 pb-2"><i class="bi bi-x-lg"></i></p> 
                                              </div> -->
                                              <div class="col-3 pe-1">
                                                <button class="btn btn-default fhbtnoutline1 w-100" (click)="cancelBulk()">Cancel</button>
                                              </div>
                                              <div class="col-3 ps-2">
                                                <button class="btn btn-default fhbtn1 text-white w-100" (click)="submitBulk()">Submit</button>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="ps-1" width="40px" *ngIf="!OnBlulk">
                                            <div class="d-flex justify-content-center">
                                              <img  class="iconDots pointer" (click)="openModalFilter()" src="/assets/images/icons/filter-msg.png">
                                            </div>   
                                          </td>
                                          <td class="pe-1" width="40px" *ngIf="!OnBlulk">
                                            <div class="d-flex justify-content-center">
                                              <!-- <img  class="iconDots pointer" src="/assets/images/icons/3dotshIcon.png">  -->

                                              <div ngbDropdown placement="bottom-end" class="d-inline-block p-0" *ngIf="!onSearchFilterMessage">
                                                <button class="btn dropdown-toggle text-white p-0"  id="dropdownBasic1" ngbDropdownToggle>
                                                  <img class="dotsicon wishlisticon iconDots pointer" src="/assets/images/icons/3dotshIcon.png">
                                                  <span *ngIf="OnBlulk" class="position-absolute top-0 start-50 translate-middle pnotif bg-danger border border-light rounded-circle">
                                                    <span class="visually-hidden">Alerts</span>
                                                  </span>
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right fhddmenu p-0 rounded-0">
                                                  <a (click)="bulkAction('Mark as Read')" ngbDropdownItem class="dropdownbg fw-bold pointer pt-2 pb-2">Mark as Read</a>
                                                  <hr class="m-0">
                                                  <a (click)="bulkAction('Mark as Unread')" ngbDropdownItem class="dropdownbg fw-bold pointer pt-2 pb-2">Mark as Unread</a>
                                                  <hr class="m-0">
                                                  <a (click)="bulkAction('Bulk Star')" ngbDropdownItem class="dropdownbg fw-bold pointer pt-2 pb-2" *ngIf="typeChat!=='Transactions'">Bulk Star</a>
                                                  <hr class="m-0" *ngIf="typeChat!=='Transactions'">
                                                  <a (click)="bulkAction('Bulk Unstar')" ngbDropdownItem class="dropdownbg fw-bold pointer pt-2 pb-2" *ngIf="typeChat!=='Transactions'">Bulk Unstar</a>
                                                  <hr class="m-0" *ngIf="typeChat!=='Transactions'">
                                                  <a (click)="bulkAction('Bulk Delete')" ngbDropdownItem class="dropdownbg fw-bold pointer pt-2 pb-2">Bulk Delete</a>

                                                  
                                                </div>
                                              </div>

                                              <i  *ngIf="onSearchFilterMessage" class="bi bi-x-lg pe-2 fh3 pointer" (click)="clearFilter()"></i>

                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                      <div class="categoryMsg pt-2 ps-3 pe-3">

                                        <ng-container  *ngIf="onSearchFilterMessage">
                                          <h6 class="mb-0 pb-0"><strong>'{{typeChat}}' search result :</strong></h6>
                                        </ng-container>


                                        <ng-container  *ngIf="OnBlulk">
                                          <h6 class="mb-0 pb-0"><strong>'{{typeChat}}' {{bulkType}} :</strong></h6>
                                        </ng-container>

                                        <ng-container  *ngIf="!onSearchFilterMessage && !OnBlulk">
                                          
                                          <button type="button" (click)="changeTab('Transactions')" class="btn btn-primary" [disabled]="typeChat=='Transactions'"> <!-- disabled -->
                                            Transactions 
                                            <span class="badge bg-secondary">{{TotalAllMessages}}</span>
                                          </button>
                                          <button type="button" (click)="changeTab('System')" class="btn btn-primary" [disabled]="typeChat=='System'">
                                            Systems 
                                            <span class="badge bg-secondary">{{TotalAllSystem}}</span>
                                          </button>
                                          <button type="button" (click)="changeTab('Internal')" class="btn btn-primary"  [disabled]="typeChat=='Internal'">
                                            Internal 
                                            <span class="badge bg-secondary">{{TotalAllInternal}}</span>
                                          </button>
                                          <!-- <button type="button" class="btn btn-primary">
                                            Leads 
                                            <span class="badge bg-secondary">7</span>
                                          </button> -->

                                          <button type="button" (click)="changeTab('Leads')" class="btn btn-primary"  [disabled]="typeChat=='Leads'">
                                            Leads 
                                            <span class="badge bg-secondary">{{TotalAllLeads}}</span>
                                          </button>

                                        </ng-container>


                                      </div>



                                    </div>
                                    <div class="col-12">
                                      <div class="listdiv overflow-auto leftsidedata"  (scroll)="onScroll($event)">
                                        <ul class="list-group">
                                          
                                        <ng-container *ngIf="typeChat=='Transactions' && !onSearchFilterMessage">

                                          <li *ngIf="noMessage"> <!-- unreadL  activeL -->
                                            <div class="text-center">
                                              <p class="mt-5 mb-5 text-black-50">No message</p>
                                            </div>
                                          </li>
                                          
                                          <li *ngFor="let message of AllMessages; let i = index"  class="list-group-item Lpadding {{message.isRead?'':'unreadL'}} {{activeChat(message.messageId)?'activeL':''}} {{isMobileMessage?'d-none':''}}" (click)="clickMenuOnMobile()"> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div *ngIf="OnBlulk" class="form-check">
                                                      <input class="form-check-input mt-4 pointer" type="checkbox" id="checkbox_{{message.messageId}}" (click)="OnClickCheck(message.messageId,t.checked)" #t
                                                      [checked]="setCheckBox(message.messageId)">
                                                      <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                    </div>
                                                    <div *ngIf="!OnBlulk" class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                    
                                                  </td>
                                                  <td  width="63%" (click)="clickChat(message)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt"><font class="{{message.isRead?'':'fw-bold'}} ">{{limitTitle(displayName(message))}}</font></h5>
                                                    <p class="m-0 p-0 pointer f14">RFQ # {{formatDisplayId(message.rfqDisplayId)}} 

                                                    <font *ngIf="message.quotationDisplayId"> <i class="bi bi-dot"></i> Quote {{formatDisplayId(message.quotationDisplayId)}}</font>
                                                      
                                                    </p>
                                                    <p class="m-0 p-0 descL f12">{{limitText(message.lastMessageContent)}}</p>
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{message.lastMessageDatetime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" (click)="setStarUnstar(message.messageId,message.isStar)" src="/assets/images/icons/{{message.isStar?'star-filled-fill.png':'star-filled-blank.png'}}"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>
                                        </ng-container>

                                        <ng-container *ngIf="onSearchFilterMessage">


                                          

                                          <li *ngIf="noMessageFilter"> <!-- unreadL  activeL -->
                                            <div class="text-center">
                                              <p class="mt-5 mb-5 text-black-50">No Result</p>
                                            </div>
                                          </li>
                                          
                                          
                                          <li *ngFor="let message of AllMessagesFilter; let i = index"  class="list-group-item {{message.isRead?'':'unreadL'}} {{activeChat(message.messageId)?'activeL':''}} {{isMobileMessage?'d-none':''}}" (click)="clickMenuOnMobile()"> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft" *ngIf="typeChat=='Transactions'">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickChat(message)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{message.isRead?'':'fw-bold'}} ">{{limitTitle(displayName(message))}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14">RFQ # {{formatDisplayId(message.rfqDisplayId)}} 

                                                    <font *ngIf="message.quotationDisplayId"> <i class="bi bi-dot"></i> Quote {{formatDisplayId(message.quotationDisplayId)}}</font>
                                                      
                                                    </p>
                                                    <p class="m-0 p-0 descL f12">{{limitText(message.lastMessageContent)}}</p>
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{message.lastMessageDatetime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <!-- <img class="starL pointer" (click)="setStarUnstar(message.messageId,message.isStar)" src="/assets/images/icons/{{message.isStar?'star-filled-fill.png':'star-filled-blank.png'}}">  -->
                                                      <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>


                                            <div class="itemMsgLeft" *ngIf="typeChat=='Internal' || typeChat=='System'">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickNotification(message)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{message.isRead?'':'fw-bold'}} ">{{limitTitle(message.notificationSubject)}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14" [innerHtml]="limitText(message.notificationContent)">
                                                    </p>
                                                    <!-- <p class="m-0 p-0 descL">{{limitText(system.notificationContent)}}</p> -->
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{message.notificationTime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" (click)="setStarUnstar(message.notificationId,message.isFavorite)" src="/assets/images/icons/{{message.isFavorite?'star-filled-fill.png':'star-filled-blank.png'}}"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>


                                            <div class="itemMsgLeft" *ngIf="typeChat=='Leads'">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickLeads(message)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{message.isRead?'':'fw-bold'}} ">{{limitTitle(displayName(message))}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14 d-none">RFQ # {{formatDisplayId(message.rfqDisplayId)}} 

                                                    <font *ngIf="message.quotationDisplayId"> <i class="bi bi-dot"></i> Quote {{formatDisplayId(message.quotationDisplayId)}}</font>
                                                      
                                                    </p>
                                                    <p class="m-0 p-0 descL f12">{{limitText(message.lastMessageContent)}}</p>
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{message.lastMessageDatetime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <!-- <img class="starL pointer" (click)="setStarUnstar(message.messageId,message.isStar)" src="/assets/images/icons/{{message.isStar?'star-filled-fill.png':'star-filled-blank.png'}}">  -->
                                                      <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>

                                            <!-- <div class="itemMsgLeft" *ngIf="typeChat!=='Transactions'">
                                              <table width="100%">
                                                <tr>
                                                  <td width="25%">
                                                    <div *ngIf="OnBlulk" class="form-check">
                                                      <input class="form-check-input mt-4" type="checkbox" value="" id="flexCheckDefault">
                                                      <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                    </div>
                                                    <div *ngIf="!OnBlulk" class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="60%" (click)="clickNotification(message)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>{{limitText(message.notificationSubject)}}</strong></h5>
                                                    <p class="m-0 p-0 pointer" [innerHtml]="limitText(message.notificationContent)">
                                                    </p>
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{message.notificationTime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                    </p>
                                                    <p class="p-0 m-0">
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div> -->

                                          </li>
                                        </ng-container>

                                        <ng-container *ngIf="typeChat=='System' && !onSearchFilterMessage">


                                          <li *ngIf="noMessageSystem"> <!-- unreadL  activeL -->
                                            <div class="text-center">
                                              <p class="mt-5 mb-5 text-black-50">No message</p>
                                            </div>
                                          </li>

                                          <li *ngFor="let system of AllSystem; let i = index"  class="list-group-item {{system.isRead?'':'unreadL'}} {{activeChat(system.notificationId)?'activeL':''}} {{isMobileMessage?'d-none':''}}" (click)="clickMenuOnMobile()" > <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div *ngIf="OnBlulk" class="form-check">
                                                      <input class="form-check-input mt-4 pointer" type="checkbox" id="checkbox_{{system.notificationId}}" (click)="OnClickCheck(system.notificationId,t.checked)" #t
                                                      [checked]="setCheckBox(system.notificationId)">
                                                      <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                    </div>
                                                    <div *ngIf="!OnBlulk" class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickNotification(system)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{system.isRead?'':'fw-bold'}} ">{{limitTitle(system.notificationSubject)}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14" [innerHtml]="limitText(system.notificationContent)">
                                                    </p>
                                                    <!-- <p class="m-0 p-0 descL">{{limitText(system.notificationContent)}}</p> -->
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{system.notificationTime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" (click)="setStarUnstar(system.notificationId,system.isFavorite)" src="/assets/images/icons/{{system.isFavorite?'star-filled-fill.png':'star-filled-blank.png'}}"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                        </ng-container>


                                        <ng-container *ngIf="typeChat=='Internal' && !onSearchFilterMessage">

                                          <li *ngIf="noMessageInternal"> <!-- unreadL  activeL -->
                                            <div class="text-center">
                                              <p class="mt-5 mb-5 text-black-50">No message</p>
                                            </div>
                                          </li>

                                          <li *ngFor="let internal of AllInternal; let i = index"  class="list-group-item {{internal.isRead?'':'unreadL'}} {{activeChat(internal.notificationId)?'activeL':''}} {{isMobileMessage?'d-none':''}}" (click)="clickMenuOnMobile()" > <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div *ngIf="OnBlulk" class="form-check">
                                                      <input class="form-check-input mt-4 pointer" type="checkbox" id="checkbox_{{internal.notificationId}}" (click)="OnClickCheck(internal.notificationId,t.checked)" #t
                                                      [checked]="setCheckBox(internal.notificationId)">
                                                      <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                    </div>
                                                    <div *ngIf="!OnBlulk" class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickNotification(internal)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{internal.isRead?'':'fw-bold'}} ">{{limitTitle(internal.notificationSubject)}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14" [innerHtml]="limitText(internal.notificationContent)">
                                                    </p>
                                                    <!-- <p class="m-0 p-0 descL">{{limitText(internal.notificationContent)}}</p> -->
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{internal.notificationTime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" (click)="setStarUnstar(internal.notificationId,internal.isFavorite)" src="/assets/images/icons/{{internal.isFavorite?'star-filled-fill.png':'star-filled-blank.png'}}"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                        </ng-container>


                                        <ng-container *ngIf="typeChat=='Leads' && !onSearchFilterMessage">

                                          <li *ngIf="noMessageLeads"> <!-- unreadL  activeL -->
                                            <div class="text-center">
                                              <p class="mt-5 mb-5 text-black-50">No message</p>
                                            </div>
                                          </li>

                                          <li *ngFor="let leads of AllLeads; let i = index"  class="list-group-item {{leads.isRead?'':'unreadL'}} {{activeChat(leads.leadsMessageId)?'activeL':''}} {{isMobileMessage?'d-none':''}}" (click)="clickMenuOnMobile()" > <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft">
                                              <table width="100%">
                                                <tr>
                                                  <td width="17%">
                                                    <div *ngIf="OnBlulk" class="form-check">
                                                      <input class="form-check-input mt-4 pointer" type="checkbox" id="checkbox_{{leads.leadsMessageId}}" (click)="OnClickCheck(leads.leadsMessageId,t.checked)" #t
                                                      [checked]="setCheckBox(leads.leadsMessageId)">
                                                      <div class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                    </div>
                                                    <div *ngIf="!OnBlulk" class="rounded-circle pointer" style="width: 50px; height: 50px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td  width="63%" (click)="clickLeads(leads)">
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer f16 limitTxt">
                                                      <font class="{{leads.isRead?'':'fw-bold'}} ">{{limitTitle(leads.vendorName)}}</font>
                                                    </h5>
                                                    <p class="m-0 p-0 pointer f14" [innerHtml]="limitText(leads.lastMessageContent)">
                                                    </p>
                                                    <!-- <p class="m-0 p-0 descL">{{limitText(leads.notificationContent)}}</p> -->
                                                  </td>
                                                  <td width="20%" class="text-end">
                                                    <small class="datetimeL">{{leads.lastMessageDatetime | date:'d MMM y'}}</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" (click)="setStarUnstar(leads.leadsMessageId,leads.isStar)" src="/assets/images/icons/{{leads.isStar?'star-filled-fill.png':'star-filled-blank.png'}}"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                        </ng-container>

                                        <li *ngIf="loadPaging"> 


                                          <div class="text-center mt-3 mb-3">
                                            <div class="spinner-border loader" role="status">
                                              <span class="visually-hidden">Loading...</span>
                                            </div>
                                          </div>

                                        </li>

                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                
                                <ng-container *ngIf="typeChat=='Transactions'">
                                <div class="col-sm-8 {{isMobile? isMobileMessage?'':'d-none':''}}">
                                  
                                  <div class="row g-0">
                                    <div class="col-1 {{isMobile?'':'d-none'}} pointer" style="background: #1a475a;">
                                      <p (click)="clickCloseOnMobile()" class="my-4 pt-1 px-2 {{isMobile?'':'d-none'}}">
                                        <b><i class="bi bi-chevron-left text-white"></i></b>
                                      </p>
                                    </div>
                                    <div class="{{isMobile?'col-11':'col-12'}} text-white headTitleRight">
                                      <table width="100%">
                                        <tr>
                                          <td width="93%">
                                            <!-- <h4 class="m-0 p-0  {{ getDetaiMessage ? '' : 'opacity0'}}">{{displayName(getDetaiMessage)}}</h4>
                                            <p class="m-0 p-0 {{ getDetaiMessage?.rfqDisplayId ? '' : 'opacity0'}}">RFQ # {{formatDisplayId(getDetaiMessage?.rfqDisplayId)}}</p> -->

                                            <div *ngIf="!onFindText">
                                            <h4 class="m-0 p-0">{{getDetaiMessage ? displayName(getDetaiMessage) : vendorSendMessage?.to}}</h4>
                                            <p class="m-0 p-0">{{getDetaiMessage ? 'RFQ #  '+formatDisplayId(getDetaiMessage?.rfqDisplayId) : vendorSendMessage?.showRfq}}

                                              <font *ngIf="getDetaiMessage?.quotationDisplayId"> <i class="bi bi-dot"></i> Quote {{formatDisplayId(getDetaiMessage?.quotationDisplayId)}}</font>

                                            </p>
                                            </div>
                                            <div *ngIf="onFindText" class="mt-2">
                                              <div class="row g-0">
                                                <div class="col-10 pe-3">
                                                  <input type="text" class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" maxlength="100"  (keyup)="onKeyUpFindText()">
                                                </div>
                                                <div class="col-1 text-center">
                                                  <h4 class="bi bi-chevron-up pt-1 m-0 pointer" (click)="goToSearch()"></h4>
                                                </div>
                                                <div class="col-1 text-center">
                                                  <h4 class="bi bi-chevron-down pt-1 m-0 pointer" (click)="goToSearch()"></h4>
                                                </div>
                                              </div>
                                              
                                              
                                              
                                            </div>

                                          </td>
                                          <td width="7%">
                                            <div class="d-flex justify-content-center" *ngIf="!onFindText">
                                              <img *ngIf="!disabledSendMsg" class="iconSearchWhite pointer" src="/assets/images/icons/Group 301-white@3x.png" (click)="findText()">
                                            </div>
                                            <div class="d-flex justify-content-center" *ngIf="onFindText">
                                              <font class="bi bi-x-lg mt-2 pointer" (click)="closeFindText()"></font>
                                            </div>   
                                          </td>
                                          <!-- <td width="7%">
                                            <div class="d-flex justify-content-center">
                                              <img *ngIf="!disabledSendMsg" class="iconDots pointer" src="/assets/images/icons/3dotshIcon-white.png"> 
                                            </div>
                                          </td> -->
                                        </tr>
                                      </table>
                                      
                                      
                                      

                                    </div>
                                    <div class="col-12 boxdivR">
                                      <div class="msgdiv overflow-auto rightsidedata" #divToScroll>

                                        <div class="msgdivR"  #content *ngIf="loadingRightsideMessage">
                                          <div class="row row-cols-auto justify-content-end">
                                            <div class="col d-flex align-items-end" style="height:300px;">
                                              <div class="">
                                                <div class="spinner-border loader" role="status">
                                                  <span class="visually-hidden">Loading...</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="msgdivR"  #content *ngIf="!loadingRightsideMessage" >
                                          
                                          <ng-container  #messages *ngFor="let chat of ChatList; let i = index">


                                            <div class="row row-cols-auto {{ isSender(chat,getDetaiMessage.companyAs) ? 'justify-content-end':'justify-content-start'}} ">
                                              <div class="col">
                                                <div class="msgchatSnderR {{ isSender(chat,getDetaiMessage.companyAs) ? 'msgchatSnderR':'msgchatReciveR'}}">
                                                  <h5 class="m-0">{{senderName(getDetaiMessage,chat.messageFrom)}}</h5>
                                                  <p class="m-0"><font [innerHtml]="chat.content | highlight : searchText : i"></font></p>
                                                  <h6 class="text-end m-0">{{chat.messageDate | date:'d-MMM-YY HH:mm:ss'}}</h6>
                                                </div>
                                              </div>
                                            </div>

                                          </ng-container>
      
                                        </div>

                                      </div>
                                      <div class="boxsendR">
                                        <table width="100%">
                                          <tr>
                                            <td width="80%">
                                              <div class="pe-3">
                                                <input type="text" class="form-control" [disabled]="disabledSendMsg"  [(ngModel)]="valTextMessage" [ngModelOptions]="{standalone: true}" maxlength="200" minlength="3">
                                              </div>
                                              
                                            </td>
                                            <td width="20%">

                                              <div class="d-grid gap-2">
                                                <button type="submit" class="btn btn-default fhbtn1 text-white" [disabled]="disabledSendMsg" (click)="beforeSendMessage()"> <i class="bi bi-send-fill"></i> 
                                                  {{isMobile?'':'Send'}}
                                                </button>
                                              </div>

                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                </ng-container>


                                <ng-container *ngIf="typeChat=='Internal' || typeChat=='System'">
                                  <div class="col-sm-8 {{isMobile? isMobileMessage?'':'d-none':''}}">
                                    
                                    <div class="row g-0">
                                      <div class="col-1 {{isMobile?'':'d-none'}} pointer" style="background: #1a475a;">
                                        <p (click)="clickCloseOnMobile()" class="my-4 pt-1 px-2 {{isMobile?'':'d-none'}}">
                                          <b><i class="bi bi-chevron-left text-white"></i></b>
                                        </p>
                                      </div>
                                      <div class="{{isMobile?'col-11':'col-12'}} text-white headTitleRight">
                                        <table width="100%">
                                          <tr>
                                            <td width="86%">
                                              <!-- <h4 class="m-0 p-0  {{ getDetaiMessage ? '' : 'opacity0'}}">{{displayName(getDetaiMessage)}}</h4>
                                              <p class="m-0 p-0 {{ getDetaiMessage?.rfqDisplayId ? '' : 'opacity0'}}">RFQ # {{formatDisplayId(getDetaiMessage?.rfqDisplayId)}}</p> -->
  
  
                                              
                                              <h4 class="m-0 p-0"></h4>
                                              <p class="m-0 p-0">{{getDetaiNotification?.notificationSubject}}
                                              </p>
  
  
                                            </td>
                                            <td width="7%">
                                              <div class="d-flex justify-content-center">
                                                <img *ngIf="!disabledSendMsg" class="iconSearchWhite pointer" src="/assets/images/icons/Group 301-white@3x.png">
                                              </div>   
                                            </td>
                                            <td width="7%">
                                              <div class="d-flex justify-content-center">
                                                <img *ngIf="!disabledSendMsg" class="iconDots pointer" src="/assets/images/icons/3dotshIcon-white.png"> 
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                        
                                        
                                        
  
                                      </div>
                                      <div class="col-12 boxdivR">
                                        <div class="msgdiv msgdivnochat overflow-auto rightsidedata" #divToScroll>
  
                                          <div class="msgdivR"  #content  *ngIf="getDetaiNotification" >

                                            <div class="row row-cols-auto justify-content-end">
                                              <div class="col-12">
                                                <div class="msgchatSnderR msgchatReciveR">
                                                  <!-- <h5 class="m-0">{{senderName(getDetaiMessage,chat.messageFrom)}}</h5> -->
                                                  <p class="m-0" [innerHtml]="getDetaiNotification?.notificationContent" ></p>
                                                  <h6 class="text-end m-0">{{getDetaiNotification?.notificationTime | date:'d-MMM-YY HH:mm:ss'}}</h6>
                                                </div>
                                              </div>
                                            </div>

                                          </div>
  
                                        </div>

                                      </div>
                                    </div>
  
                                  </div>
                                </ng-container>

                                <ng-container *ngIf="typeChat=='Leads'">
                                  <div class="col-sm-8 {{isMobile? isMobileMessage?'':'d-none':''}}">
                                    <div class="row g-0">
                                      <div class="col-1 {{isMobile?'':'d-none'}} pointer" style="background: #1a475a;">
                                        <p (click)="clickCloseOnMobile()" class="my-4 pt-1 px-2 {{isMobile?'':'d-none'}}">
                                          <b><i class="bi bi-chevron-left text-white"></i></b>
                                        </p>
                                      </div>
                                      <div class="{{isMobile?'col-11':'col-12'}} text-white headTitleRight">
                                        <table width="100%">
                                          <tr>
                                            <td width="93%">
                                              <!-- <h4 class="m-0 p-0  {{ getDetaiMessage ? '' : 'opacity0'}}">{{displayName(getDetaiMessage)}}</h4>
                                              <p class="m-0 p-0 {{ getDetaiMessage?.rfqDisplayId ? '' : 'opacity0'}}">RFQ # {{formatDisplayId(getDetaiMessage?.rfqDisplayId)}}</p> -->
  
                                              <div *ngIf="!onFindText">
                                              <h4 class="m-0 p-0 pt-2 mt-1">{{getDetaiMessage ? displayName(getDetaiMessage) : vendorSendMessage?.to}}</h4>
                                              <p class="m-0 p-0 d-none">{{getDetaiMessage ? 'RFQ #  '+formatDisplayId(getDetaiMessage?.rfqDisplayId) : vendorSendMessage?.showRfq}}
  
                                                <font *ngIf="getDetaiMessage?.quotationDisplayId"> <i class="bi bi-dot"></i> Quote {{formatDisplayId(getDetaiMessage?.quotationDisplayId)}}</font>
  
                                              </p>
                                              </div>
                                              <div *ngIf="onFindText" class="mt-2">
                                                <div class="row g-0">
                                                  <div class="col-10 pe-3">
                                                    <input type="text" class="form-control" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" maxlength="100"  (keyup)="onKeyUpFindText()">
                                                  </div>
                                                  <div class="col-1 text-center">
                                                    <h4 class="bi bi-chevron-up pt-1 m-0 pointer" (click)="goToSearch()"></h4>
                                                  </div>
                                                  <div class="col-1 text-center">
                                                    <h4 class="bi bi-chevron-down pt-1 m-0 pointer" (click)="goToSearch()"></h4>
                                                  </div>
                                                </div>
                                                
                                                
                                                
                                              </div>
  
                                            </td>
                                            <td width="7%">
                                              <div class="d-flex justify-content-center pt-2 mt-1" *ngIf="!onFindText">
                                                <img *ngIf="!disabledSendMsg" class="iconSearchWhite pointer" src="/assets/images/icons/Group 301-white@3x.png" (click)="findText()">
                                              </div>
                                              <div class="d-flex justify-content-center" *ngIf="onFindText">
                                                <font class="bi bi-x-lg mt-2 pointer" (click)="closeFindText()"></font>
                                              </div>   
                                            </td>
                                            <!-- <td width="7%">
                                              <div class="d-flex justify-content-center">
                                                <img *ngIf="!disabledSendMsg" class="iconDots pointer" src="/assets/images/icons/3dotshIcon-white.png"> 
                                              </div>
                                            </td> -->
                                          </tr>
                                        </table>
                                        
                                        
                                        
  
                                      </div>
                                      <div class="col-12 boxdivR">
                                        <div class="msgdiv overflow-auto rightsidedata" #divToScroll>
  
                                          <div class="msgdivR"  #content *ngIf="loadingRightsideMessage">
                                            <div class="row row-cols-auto justify-content-end">
                                              <div class="col d-flex align-items-end" style="height:300px;">
                                                <div class="">
                                                  <div class="spinner-border loader" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="msgdivR"  #content *ngIf="!loadingRightsideMessage" >
                                            
                                            <ng-container  #messages *ngFor="let chat of ChatList; let i = index">
  
  
                                              <div class="row row-cols-auto {{ isSender(chat,getDetaiMessage.companyAs) ? 'justify-content-end':'justify-content-start'}} ">
                                                <div class="col">
                                                  <div class="msgchatSnderR {{ isSender(chat,getDetaiMessage.companyAs) ? 'msgchatSnderR':'msgchatReciveR'}}">
                                                    <h5 class="m-0">{{senderName(getDetaiMessage,chat.messageFrom)}}</h5>
                                                    <p class="m-0"><font [innerHtml]="chat.content | highlight : searchText : i"></font></p>
                                                    <h6 class="text-end m-0">{{chat.messageDate | date:'d-MMM-YY HH:mm:ss'}}</h6>
                                                  </div>
                                                </div>
                                              </div>
  
                                            </ng-container>
        
                                          </div>
  
                                        </div>
                                        <div class="boxsendR">
                                          <table width="100%">
                                            <tr>
                                              <td width="80%">
                                                <div class="pe-3">
                                                  <input type="text" class="form-control" [disabled]="disabledSendMsg"  [(ngModel)]="valTextMessage" [ngModelOptions]="{standalone: true}" maxlength="200" minlength="3">
                                                </div>
                                                
                                              </td>
                                              <td width="20%">
  
                                                <div class="d-grid gap-2">
                                                  <button type="submit" class="btn btn-default fhbtn1 text-white" [disabled]="disabledSendMsg" (click)="beforeSendLeads()"> <i class="bi bi-send-fill"></i> Send</button>
                                                </div>
  
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
  
                                  </div>
                                </ng-container>


                              </div>
                            </div>

                          </form>                          



                        </div>
                    </div>

                  </div>






                  <div class="card border-0  mb-3 divshbreadcum d-none">

                    <div class="container filterdiv">
                        <div class="row">

                          <form [formGroup]="UserForm" (ngSubmit)="onSubmit()" class="ps-0 pe-0">
                            <div class="col-sm-12 mt-0 pt-3  fhbg1 text-white pb-3  ps-5"> <!-- rounded-top -->
                                <h4 class="mb-0 fw-bold">Messaging</h4>
                            </div>
    
                            <!-- <div class="col-12 ps-3 pe-3 pt-4">
                                <div class="row justify-content-center pb-5">
                                    <div class="col-sm-12 col-md-12 col-lg-7 pt-3 pb-3" *ngIf="UserFormInvalid">
            
                                        <div class="alert alert-danger alert-dismissible fade show rounded-1 errorNotif" role="alert">
                                            <strong>Warning</strong> : Please check all required input.

                                        </div>
                                    </div>


                                </div>
                            </div> -->
                            <div>
                              <div class="row g-0 messagingdiv">
                                <div class="col-5">
                                  <div class="row g-0">
                                    <div class="col-12 pt-3 pb-3 leftsidediv">
                                      <table width="100%">
                                        <tr>
                                          <td class="ps-3" width="84%">

                                            <form  [formGroup]="SearchForm">
                                              <div class="input-group">
                                                <input type="text" class="form-control inputSrch" placeholder="Search thread..." formControlName="search_thread" id="search_thread" name="search_thread" [(ngModel)]="valSearchThread"> 
                                                <a class="btn btn-secondary bgGroupBtn cursorauto inputSrchIcon"><img src="/assets/images/icons/Group 301@3x.png" class="iconSrch"></a>
                                              </div>
                                            </form>

                                          </td>
                                          <td class="ps-1" width="8%">
                                            <div class="d-flex justify-content-center">
                                              <img  class="iconDots pointer" src="/assets/images/icons/filter-msg.png">
                                            </div>   
                                          </td>
                                          <td class="pe-1" width="8%">
                                            <div class="d-flex justify-content-center">
                                              <img  class="iconDots pointer" src="/assets/images/icons/3dotshIcon.png"> 
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                      <div class="categoryMsg pt-2 ps-3 pe-3">
                                        <button type="button" class="btn btn-primary" disabled>
                                          Transactions <span class="badge bg-secondary">11</span>
                                        </button>
                                        <button type="button" class="btn btn-primary">
                                          Systems <span class="badge bg-secondary">22</span>
                                        </button>
                                        <button type="button" class="btn btn-primary">
                                          Internal <span class="badge bg-secondary">10</span>
                                        </button>
                                        <button type="button" class="btn btn-primary">
                                          Leads <span class="badge bg-secondary">7</span>
                                        </button>
                                      </div>



                                    </div>
                                    <div class="col-12">
                                      <div class="listdiv overflow-auto leftsidedata">
                                        <ul class="list-group">

                                          <li class="list-group-item unreadL"> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>PT. Jaya Berkarya</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #001264751 <i class="bi bi-dot"></i> Quote #2737739</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">18.37</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <span class="badge bg-secondary badgeL">7</span>
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-blank.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                          <li class="list-group-item unreadL"> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>PT. Putra Perkasa Indonesia</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #005112647 <i class="bi bi-dot"></i> Quote #2739737</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">13.10</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <span class="badge bg-secondary badgeL">7</span>
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-fill.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                          <li class="list-group-item activeL"> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>CV. Chanti Dewata Production</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #001264751 <i class="bi bi-dot"></i> Quote #2737739</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">18.37</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-blank.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                          <li class="list-group-item "> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>PT. Intika Mulia</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #001694881 <i class="bi bi-dot"></i> Quote #9983203</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">08.37</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-fill.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>



                                          <li class="list-group-item "> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>PT. Art Vision Permana</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #006674213 <i class="bi bi-dot"></i> Quote #8864242</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">16/03/22</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-blank.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                          <li class="list-group-item "> <!-- unreadL  activeL -->
                                            <div class="itemMsgLeft ">
                                              <table width="100%">
                                                <tr>
                                                  <td>
                                                    <div class="rounded-circle pointer" style="width: 75px; height: 75px; background-color: darkgray;" ></div>
                                                  </td>
                                                  <td>
                                                    <h5 class="p-0 m-0 text-black pb-1 pointer"><strong>PT. Jaya Berkarya</strong></h5>
                                                    <p class="m-0 p-0 pointer">RFQ #001264751 <i class="bi bi-dot"></i> Quote #2737739</p>
                                                    <p class="m-0 p-0 descL">Lorem ipsum dolor sit amet...</p>
                                                  </td>
                                                  <td class="text-end">
                                                    <small class="datetimeL">16/03/22</small>
                                                    <p class="pt-1 pb-1 m-0 badgeDivL">
                                                      <!-- <span class="badge bg-secondary badgeL">7</span> -->
                                                    </p>
                                                    <p class="p-0 m-0">
                                                      <img class="starL pointer" src="/assets/images/icons/star-filled-fill.png"> <!-- star-filled-fill.png -->
                                                    </p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </li>

                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-7">
                                  
                                  <div class="row g-0">
                                    <div class="col-12  text-white headTitleRight">
                                      <table width="100%">
                                        <tr>
                                          <td width="86%">
                                            <h4 class="m-0 p-0">CV. Teknologi Maju Bersama</h4>
                                            <p class="m-0 p-0">RFQ #007543321 <i class="bi bi-dot"></i> Quote #6533312</p>
                                          </td>
                                          <td width="7%">
                                            <div class="d-flex justify-content-center">
                                              <img  class="iconSearchWhite pointer" src="/assets/images/icons/Group 301-white@3x.png">
                                            </div>   
                                          </td>
                                          <td width="7%">
                                            <div class="d-flex justify-content-center">
                                              <img  class="iconDots pointer" src="/assets/images/icons/3dotshIcon-white.png"> 
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                      
                                      
                                      

                                    </div>
                                    <div class="col-12 boxdivR">
                                      <div class="msgdiv overflow-auto rightsidedata">

                                        <div class="msgdivR">

                                          <div class="msgdateR text-center pt-2 pb-2">
                                            March 16, 2022
                                          </div>


                                          <div class="row row-cols-auto justify-content-end">
                                            <div class="col">
                                              <div class="msgchatSnderR">
                                                <h5 class="m-0">Staff Vendor 1</h5>
                                                <p class="m-0">HHi CV. Chanti Dewata Production! I want to ask more detail about your RFQ, to see further information please check this link : <a class="pointer btn-link">Click Here</a> </p>
                                                <h6 class="text-end m-0">08.37</h6>
                                              </div>
                                            </div>
                                          </div>                                          
                                          

                                          <div class="row row-cols-auto justify-content-end">
                                            <div class="col">
                                              <div class="msgchatSnderR">
                                                <h5 class="m-0">Staff Vendor 1</h5>
                                                <p class="m-0">Hi, Just want to ask is it possible to offer </p>
                                                <h6 class="text-end m-0">08.37</h6>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="row row-cols-auto justify-content-start">
                                            <div class="col">
                                              <div class="msgchatReciveR">
                                                <h5 class="m-0">Staff Procurement 1</h5>
                                                <p class="m-0">Hello! Yes I would like to order more product 1 than others.</p>
                                                <h6 class="text-end m-0">08.35</h6>
                                              </div>
                                            </div>
                                          </div>


                                          <div class="msgdateR text-center pt-2 pb-2">
                                            March 17, 2022
                                          </div>

                                          <div class="row row-cols-auto justify-content-end">
                                            <div class="col">
                                              <div class="msgchatSnderR">
                                                <h5 class="m-0">Staff Vendor 2</h5>
                                                <p class="m-0">Sure! We can provide the products. Already send you the quotation, please check.</p>
                                                <h6 class="text-end m-0">08.34</h6>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="row row-cols-auto justify-content-start">
                                            <div class="col">
                                              <div class="msgchatReciveR">
                                                <h5 class="m-0">Staff Procurement 2</h5>
                                                <p class="m-0">Ok, Noted. </p>
                                                <h6 class="text-end m-0">08.33</h6>
                                              </div>
                                            </div>
                                          </div>

   
                                        </div>

                                      </div>
                                      <div class="boxsendR">
                                        <table width="100%">
                                          <tr>
                                            <td width="80%">
                                              <div class="pe-3">
                                                <input type="text" class="form-control">
                                              </div>
                                              
                                            </td>
                                            <td width="20%">

                                              <div class="d-grid gap-2">
                                                <a class="btn btn-default fhbtn1 text-white"> <i class="bi bi-send-fill"></i> Send</a>
                                              </div>

                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </form>                          



                        </div>
                    </div>

                  </div>
                </div>
            </div>    
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="modalFilter" tabindex="-1"  [ngStyle]="{'display':displayFilter}">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
        <h5 class="text-white f1 mb-0"><strong>Filter {{typeChat}}</strong></h5>
        <button type="button" class="btn text-white" data-bs-dismiss="modal" (click)="openModalFilter()"><i class="bi bi-x-lg"></i></button>
      </div>
      <div class="modal-body filterdivmodal">
        <form [formGroup]="rfqFilterForm" (ngSubmit)="onSubmit()" autocomplete="off">   

        <div class="row">
          <div class="col-12">
            <div class="row mb-2">
              <!-- <div class="input-group">
                
                <input type="text" class="form-control" placeholder="Type Keyword Search" formControlName="inmodulesearch" id="inmodulesearch" name="inmodulesearch" [(ngModel)]="valSearch" [ngClass]="{ 'is-invalid': submitted && f?.inmodulesearch?.errors }"> 
                <a class="btn btn-secondary bgGroupBtn cursorauto" [ngClass]="{ 'is-invalid-search': submitted && f?.inmodulesearch?.errors }"><img src="/assets/images/icons/ic_SearchBlack.png"></a>
              </div> -->
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Type Keyword Search" formControlName="inmodulesearch" id="inmodulesearch" name="inmodulesearch" [(ngModel)]="valSearch" [ngClass]="{ 'is-invalid': submitted && f?.inmodulesearch?.errors }"> 
              </div>


              <div *ngIf="submitted && f?.inmodulesearch?.errors" class="invalid-feedback">
                  <div *ngIf="f?.inmodulesearch?.errors?.required">Keyword is required</div>
              </div>

            </div>

            
            <div class="row mb-2">
              <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Status Read :</b> </label>
              <div class="input-group">
                <select class="form-select" [(ngModel)]="Valread"  [ngModelOptions]="{standalone: true}">
                  <option value=""> - </option>
                  <option value="read">Read</option>
                  <option value="unread">Unread</option>            
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Status Star :</b> </label>
              <div class="input-group">
                <select class="form-select" [(ngModel)]="Valstar"  [ngModelOptions]="{standalone: true}">
                  <option value=""> - </option>
                  <option value="star">Star</option>
                  <option value="unstar">Unstar</option>            
                </select>
              </div>
            </div>
            
            <div class="mb-2 row">
              <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Date Range:</b> </label>
              <div class="col-sm-5">
                  <div class="input-group {{ errorDateRange ? 'errDateRange' : '' }}">
                      <input type="text" class="form-control placeholderRfq readonly" placeholder="Select Date"
                      name="etaRequestedStart" [(ngModel)]="ValminDateStart" formControlName="etaRequestedStart"  ngbDatepicker #d1="ngbDatepicker"  (click)="d1.toggle()" [maxDate]="ValminDateTo" readonly> 
                      <a  class="btn btn-secondary bgGroupBtn" (click)="clearDate('start')" *ngIf="ValminDateStart"><i class="bi bi-x"></i></a>
                      <a class="btn btn-secondary bgGroupBtn"  (click)="d1.toggle()" ><img src="/assets/images/icons/Subtract.png"></a>
                  </div>
              </div>
              <div class="col-sm-2 text-sm-start text-md-center text-lg-center">
                <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>To</b> </label>
              </div>
              <div class="col-sm-5">
                <div class="input-group {{ errorDateRange ? 'errDateRange' : '' }}">
                    <input type="text" class="form-control placeholderRfq readonly" placeholder="Select Date"
                    name="etaRequestedTo" [(ngModel)]="ValminDateTo" formControlName="etaRequestedTo"  ngbDatepicker #d2="ngbDatepicker"  (click)="d2.toggle()" [minDate]="ValminDateStart" readonly> 
                    <a  class="btn btn-secondary bgGroupBtn" (click)="clearDate('end')" *ngIf="ValminDateTo"><i class="bi bi-x"></i></a>
                    <a class="btn btn-secondary bgGroupBtn" (click)="d2.toggle()" ><img src="/assets/images/icons/Subtract.png"></a>
                </div>
            </div>

            </div>

          </div>
        </div>


        <div class="row mt-3">
          <div class="col-sm-1 mb-3"></div>
          <div class="col-sm-5 mb-3">
            <button type="button" class="btn c1 f1 pe-5 ps-5 confirmBtn text-uppercase fsmall w-100" data-bs-dismiss="modal"  (click)="clearFilter()">Clear</button>
          </div>
          <div class="col-sm-5 mb-3">
            <button type="button" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall w-100" (click)="filterMessage()">Filter</button>
          </div>
          <div class="col-sm-1 mb-3"></div>
        </div>

        </form>
      </div>

    </div>
  </div>
</div>

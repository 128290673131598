<div ngbDropdown class="d-none d-inline-block divShare" placement="bottom-end" autoClose="false">
    <button class="btn btn-text text-end mt-2" id="dropdownShare" ngbDropdownToggle>
      <i class="bi bi-share"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownShare" class="dropdown-menu dropdown-menu-right sharemenu">
      <!-- <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" (click)="showModalInviteVendor()"><img src="/assets/images/icons/share-email@3x.png" class="shareicon">Email</a> -->
      <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="http://www.facebook.com/sharer/sharer.php?u={{shareUrl}}?utm_source=rfq-invite%26utm_medium=facebook%26utm_campaign=invite%26utm_term=vendor%26utm_content={{utm_content}}"><img src="/assets/images/icons/share-facebook@3x.png" class="shareicon">Facebook</a>
      <!-- <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="#"><img src="/assets/images/icons/share-instagram@3x.png" class="shareicon">Instagram</a> -->
      <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url={{shareUrl}}?utm_source%3Drfq-invite%26utm_medium%3Dlinkedin%26utm_campaign%3Dinvite%26utm_term%3Dvendor%26utm_content%3D{{utm_content}}"><img src="/assets/images/icons/share-linkedin@3x.png" class="shareicon">Linkedin</a>
      <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="https://wa.me/?text={{textWa}}"><img src="/assets/images/icons/share-whatsapp@3x.png" class="shareicon">Whatsapp</a>
    </div>
</div>
<div ngbDropdown class="d-inline-block divShare" placement="bottom-end" autoClose="false">
  <button class="btn btn-text text-end mt-2" id="dropdownShare" ngbDropdownToggle>
    <i class="bi bi-share"></i>
  </button>

  <div ngbDropdownMenu aria-labelledby="dropdownShare" class="dropdown-menu dropdown-menu-right sharemenu">
    <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="{{ link_fb }}"><img src="/assets/images/icons/share-facebook@3x.png" class="shareicon">Facebook</a>
    <!-- <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="#"><img src="/assets/images/icons/share-instagram@3x.png" class="shareicon">Instagram</a> -->
    <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="{{ link_lk }}"><img src="/assets/images/icons/share-linkedin@3x.png" class="shareicon">Linkedin</a>
    <a ngbDropdownItem class="dropdownbg pointer py-2 px-1" target="_blank" href="{{ link_wa }}"><img src="/assets/images/icons/share-whatsapp@3x.png" class="shareicon">Whatsapp</a>
  </div>
</div>


<!-- invite vendor Modal -->
<div class="modal" id="addModal" tabindex="-1" role="dialog" [ngStyle]="{'display':showInviteEmail}">
  <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content  rounded-0 border-0">
      <div class="modal-header bg1 rounded-0  text-center">
          <h5 class="text-white f1 mb-0"><strong>Share {{shareType=='product'? 'Product':'RFQ'}} </strong></h5>
          <button type="button" class="btn text-white" data-bs-dismiss="modal" (click)="showModalInviteVendor()"><i class="bi bi-x-lg"></i></button>
      </div>
      <div class="modal-body filterdiv">
          <form>   
              <div class="row p-3">
                <div class="col-sm-12">
                  <h5 class="invtitle">Input email</h5>
                  <div class="row mb-2">
                    <div class="input-group">
                      <input  type="email" class="form-control"  placeholder="Enter Email" id="inmodulesearch" name="inmodulesearch" [(ngModel)]="ValinputEmail" (keyup.enter)="addInvEmail()" maxlength="60" autocomplete="off" [ngModelOptions]="{standalone: true}"> 
                      <a class="btn btn-secondary bgGroupBtn" (click)="addInvEmail()"><img class="bgGroupIcon" src="/assets/images/icons/Group 452@2x.png"></a> 
                    </div>
                    <div style="height:15px;">
                      <div *ngIf="submitInvEmail && invalidEmail" class="notif-invalid">Invalid Email Address</div>
                    </div>
                   
                  </div>
                  <h5 class="invtitle">Below are list of email(s) to invite to this {{shareType=='product'? 'Product':'RFQ'}}</h5>
                  <div class="fixtagdivScroll invitedivScroll Costumscroll p-2 mb-2">
                    <span class="badge rounded-pill bg1 ps-3 me-2 catpillshown" *ngFor="let InvVendor of selectedInvEmail; let i = index" (click)="$event.stopPropagation()">{{InvVendor.val}} <i class="bi bi-x pointer" (click)="removeInvVendor(InvVendor.id);$event.stopPropagation()"></i></span>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                  <div class="col-1 mb-3"></div>
                  <div class="col-5 mb-3 text-end">
                      <button type="button" class="btn c1 f1 pe-5 ps-5 confirmBtn text-uppercase fsmall borderBtn" data-bs-dismiss="modal"  (click)="showModalInviteVendor()">Cancel</button>
                  </div>
                  <div *ngIf="shareType=='product'" class="col-5 mb-3 text-start">
                      <a *ngIf="!inviteloading" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall borderBtn {{selectedInvEmail.length==0?'disabled':''}}" (click)="submitInvProduct()">Invite</a>

                      <a *ngIf="inviteloading" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall borderBtn disabled">Loading...</a>
                  </div>

                  <div *ngIf="shareType=='rfq'" class="col-5 mb-3 text-start">
                    <a *ngIf="!inviteloading" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall borderBtn {{selectedInvEmail.length==0?'disabled':''}}" (click)="submitInv()">Invite</a>

                    <a *ngIf="inviteloading" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall borderBtn disabled">Loading...</a>
                  </div>
                  

                  <div class="col-1 mb-3"></div>
              </div>

          </form>
      </div>

      </div>
  </div>
</div>

<div ngbAutofocus *ngIf="modalconfirm">

      <div class="modal-header bg1 rounded-0 text-center" *ngIf="title">
        <h5 class="text-white f1 mb-0"><strong>{{ title }}</strong></h5>
      </div>
      <div class="modal-body">
        <h6 class="text-center mt-5 mb-4 px-3 px-sm-2" *ngIf="subtitle" [innerHTML]="subtitle"></h6>

        <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-3 ">
          <button type="button" class="pe-5 ps-5 text-uppercase fsmall rounded-1 bg3 fw-bold border1 c1" (click)="activeModal.close()" *ngIf="noStr">{{ noStr }}</button>
          <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="yesStr">{{ yesStr }}</button>
        </div> -->

        <div class="row g-0 mb-5">
          <div class="col-6 text-end p-1">
            <button type="button" class="pe-5 ps-5 text-uppercase fsmall rounded-1 bg3 fw-bold border1 c1 py-1" (click)="activeModal.close()" *ngIf="noStr">{{ noStr }}</button>
          </div>
          <div class="col-6 text-start p-1">
            <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="yesStr">{{ yesStr }}</button>
          </div>
        </div>

      </div>

</div>

<div ngbAutofocus *ngIf="modalinfo">

    <!-- @Input() modalinfo?: string;
    @Input() infoSuccess?: string;
    @Input() infoFailed?: string;
    @Input() closeTxt?: string; -->

  <ng-container *ngIf="modalinfo=='info'">
    <div class="modal-body" *ngIf="infoSuccess">
      <div class="successborder"></div>
      <div class="text-center mt-5">
        <img src="/assets/images/icons/Group 518.png" class="img-fluid">
      </div>
      <h5 class="text-center f1 mb-2 mt-4"><strong>{{infoTitle}}</strong></h5>
      <h6 class="text-center mt-1 mb-4 ps-3 pe-3"  *ngIf="infoSuccess" [innerHTML]="infoSuccess"></h6>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
        <button type="button" class="btn successbtn f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="closeTxt" id="{{ IdBtnClose }}">{{ closeTxt }}</button>
      </div>
    </div>

    <div class="modal-body" *ngIf="infoFailed">
      <div class="failedborder"></div>
      <div class="text-center mt-5">
        <img src="/assets/images/icons/Group 518-2.png" class="img-fluid">
      </div>
      <h5 class="text-center f1 mb-2 mt-4"><strong>{{infoTitle}}</strong></h5>
      <h6 class="text-center mt-1 mb-4 ps-3 pe-3"  *ngIf="infoFailed" [innerHTML]="infoFailed"></h6>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
        <button type="button" class="btn failedbtn f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="closeTxt">{{ closeTxt }}</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="modalinfo=='warning'">
    <div class="modal-body" *ngIf="infoFailed">
      <div class="warningborder"></div>
      <div class="text-center mt-5">
        <img src="/assets/images/icons/ic-group-518.png" class="img-fluid">
      </div>
      <h5 class="text-center f1 mb-2 mt-4"><strong>{{infoTitle}}</strong></h5>
      <h6 class="text-center mt-1 mb-4 ps-3 pe-3"  *ngIf="infoFailed" [innerHTML]="infoFailed"></h6>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
        <button type="button" class="btn warningbtn f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="closeTxt">{{ closeTxt }}</button>
      </div>
    </div>
  </ng-container>
</div>

<div ngbAutofocus *ngIf="modalhelp">

  <div class="modal-body " *ngIf="infoSuccess">
    <!-- <div class="successborder"></div> -->

    <div class="helpsuccessHeader">
      <h5 class="text-center text-white py-3"><strong>{{infoTitle}}</strong></h5>
    </div>
    
    <div class="text-center mt-5">
      <img src="/assets/images/icons/success-help-icon.png" class="img-fluid" style="max-width: 120px;">
    </div>
    

    <h6 class="text-center my-4 px-4"  *ngIf="infoSuccess" [innerHTML]="infoSuccess"></h6>
    <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
      <button type="button" class="btn helpsuccessBtn f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="closeTxt">{{ closeTxt }}</button>
    </div>
  </div>

  <div class="modal-body" *ngIf="infoFailed">
    <div class="failedborder"></div>
    <div class="text-center mt-5">
      <!-- <img src="/assets/images/icons/Group 518-2.png" class="img-fluid"> -->
    </div>
    <h5 class="text-center f1 mb-2 mt-4"><strong>{{infoTitle}}</strong></h5>
    <h6 class="text-center mt-1 mb-4 ps-3 pe-3"  *ngIf="infoFailed" [innerHTML]="infoFailed"></h6>
    <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
      <button type="button" class="btn failedbtn f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()"  *ngIf="closeTxt">{{ closeTxt }}</button>
    </div>
  </div>
</div>


<div ngbAutofocus *ngIf="modalnotification">
    <div class="modal-header">
      
      <h5 class="modal-title c1"><strong>{{notificationSubject}}</strong></h5>
      <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      {{notificationTime}}
    </div>
    <div class="modal-body">
      <div class="row g-0 ps-4 pe-4 pt-2 peb-2">

        <div class="col-12 text-center">
          <h5 class="mt-3 mb-4">
            {{notificationContent}}
          </h5>
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 ">
            <button type="button" class="btn fhbtn1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="activeModal.close(); submit()">Close</button>
          </div>
        </div>
      </div>
    </div>
</div>


<div ngbAutofocus *ngIf="modalrating">

  <!-- <div class="modal-header bg1 rounded-0 text-center">
  </div> -->
  <div class="modal-body text-center">
    <div class="topbgrating"><br></div>
    <h2 class="titlerating mt-4 mb-4">Rate and Review us!</h2>
    <p class="titledesc ps-5 pe-5 ms-2 me-2">Your transaction has been successfully done, please give us rate based on your experiences!</p>

    <p class="titledesc mt-5 mb-3"><strong>DID THE TRANSACTION OCCUR?</strong></p>

    <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 pb-2 ">
      <button type="button" class="pe-5 ps-5 text-uppercase fsmall rounded-1 bg3 fw-bold border1 c1 btnrating" (click)="activeModal.close(); rating('no')">NO</button>
      <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall btnrating" (click)="activeModal.close(); rating('yes')">YES</button>
    </div>

  </div>

</div>

<!-- procurement -->
<div ngbAutofocus *ngIf="modalRatingProcurement">

  <div class="modal-body text-center">
    <div class="  {{pstate=='success'?'topbgrating':'topbgratingerror'}}"><br></div>

    <div class="text-center {{pstate=='success'?'bgIconSuccess':'bgIconFailed'}} mt-5">

      
      <ng-container *ngIf="ptype=='vendor'">
        <div class=" {{pstate=='success'?'avatarMsuccess':'avatarMerror'}}"></div>
      </ng-container>
      <ng-container *ngIf="ptype=='procurement'">
        <div class=" {{pstate=='success'?'avatarFsuccess':'avatarFerror'}}"></div>
      </ng-container>

      <!-- <div class=" {{pstate=='success'?'avatarFsuccess':'avatarFerror'}}"></div> -->
    </div>


    <h2 class="titlerating mt-2 mb-2 f1" [innerHTML]="pheader"></h2>
    <p class="titledescription ps-5 pe-5 ms-2 me-2"  [innerHTML]="ptitle"></p>

    <p class="titledescription mb-3" [innerHTML]="ptext"></p>




    <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 pb-2 " *ngIf="pstate=='success'">
      <button *ngIf="phaverating=='Y'" type="button" class="pt-2 pb-2 pe-5 ps-5 text-uppercase fsmall rounded-1 bg3 fw-bold secodaryBtn" (click)="activeModal.close(); rating('no')" [innerHTML]="ptextno"></button>
      <button type="button" class="btn f1 text-white pt-2 pb-2 pe-5 ps-5 text-uppercase fsmall btn-success" (click)="activeModal.close(); rating('yes')" [innerHTML]="ptextyes"></button>
    </div>

    
    <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 pb-2 " *ngIf="pstate=='error'">
      <button type="button" class="btn f1 text-white pt-2 pb-2 pe-5 ps-5 text-uppercase fsmall failedbtn" (click)="activeModal.close(); rating('yes')" [innerHTML]="ptextyes"></button>
    </div>
  </div>

</div>



<div ngbAutofocus *ngIf="modalMenu">
  <div class="modal-header border-0">
    <h5 class="modal-title">
      <a href="/"><img class="img-fluid menuLogo pt-0 pb-0" src="/assets/images/logofh-cepetdapet.png"></a>
    </h5>
    <button type="button" class="btn-close pe-4" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close();hitmenu('close')" ></button>
  </div>
  <div class="modal-body">
    
    <div class="p-3">

          <div class="menuMobile">
            <div class="item">
              <a href="#" (click)="$event.preventDefault();"> <i class="pe-3 bi bi-info-circle"></i>About Us</a>
            </div>

            <div class="dropdown-divider"></div>
            <div class="item p-0">
              <ngb-accordion #acc="ngbAccordion" class="border-0">
                <ngb-panel>
    
                  <ng-template ngbPanelTitle>
                      <div class="d-flex justify-content-between">
                        <i class="pe-3 bi bi-lightbulb"></i>Solution
                      </div>
                  </ng-template>
    
    
                  <ng-template ngbPanelContent>
                    <div class="dropdown-divider"></div>
                    <div class="item">
                      <a href="#" (click)="$event.preventDefault()" class="ps-5">Procurement</a>
                    </div>
                    
                    <div class="dropdown-divider"></div>

                    <div class="item">
                      <a href="#" (click)="$event.preventDefault()" class="ps-5">Vendor</a>   
                    </div>
                    
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <div class="dropdown-divider"></div>

            <div class="item">
              <a href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-coin"></i>Subscription</a>
            </div>
            <div class="dropdown-divider"></div>
            <div class="item">
              <a href="#" (click)="$event.preventDefault()"><i class="pe-3 bi bi-briefcase"></i>Career</a>
            </div>
            
            
            
  
          </div>


        <div class="row mt-5" *ngIf="!modalisLogin">
          
          <div class="col-12">
            <a href="/regis" class="btn btn-default m-0 MenuBtn1 w-100 mb-2">Register</a>
          </div>
          <div class="col-12">
            <a href="/login" class="btn btn-default m-0 MenuBtn2 w-100 mb-2">Login</a>
          </div>

        </div>

        <div class="row mt-5" *ngIf="modalisLogin">

          
          <div class="col-2">
            <div class="text-center pt-1">
                <img class="rounded-circle pointer" style="width: 40px; height: 40px;" src="/assets/images/default-company.jpeg"  (click)="activeModal.close(); hitmenu('profile')">
            </div>
          </div>
          <div class="col-10 text-start "  (click)="activeModal.close(); hitmenu('company')">
            <font class="fhc2 pointer">
              <b>{{modalCompanyName}}</b><br><b>{{modalFullName}}</b>
            </font>
          </div>

          <div class="col-12">
            <a (click)="activeModal.close(); hitmenu('logout')" class="btn btn-default m-0 MenuBtn2 w-100 mt-4
            ">logout</a>
          </div>

        </div>

    </div>
    
  </div>

</div>
<div ngbAutofocus>

    <div class="modal-dialog modal-lg modal-dialog-centered m-0">

        <!-- filter -->
        <div class="modal-content  rounded-0 border-0">
            <div class="modal-header bg1 rounded-0  text-center">
                <h5 class="text-white f1 mb-0"><strong>Custom Filter</strong></h5>
                <button type="button" class="btn text-white ps-0 pe-0" data-bs-dismiss="modal" (click)="activeModal.close();"><i class="bi bi-x-lg"></i></button>
            </div>
            <div class="modal-body filterdiv">
              <form [formGroup]="rfqFilterForm" (ngSubmit)="onSubmit()">   
      
                <div class="row">
                    <div class="col-12">
                    <div class="row mb-2">
                        <div class="input-group">
                        
                        <input type="text" class="form-control" placeholder="Type Keyword Search" formControlName="inmodulesearch" id="inmodulesearch" name="inmodulesearch" [(ngModel)]="valSearch"> 
                        <a class="btn btn-secondary bgGroupBtn cursorauto"><img src="/assets/images/icons/ic_SearchBlack.png"></a>
                        </div>
                    </div>
        
                    <div class="row">
                        <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Category (max.5):</b> </label>
                    </div>
        
        
                    <div class="divcategories placeholderRfq mb-2">
                        <div class="row g-0">
                        <div class="col-11  cursor-text" *ngIf="selectedCat?.length > 0"  (click)="modalCategory()">
                                <span class="badge rounded-pill bg1 ps-3 me-2 catpillshown" *ngFor="let category of selectedCat; let i = index" (click)="$event.stopPropagation()">{{getCategoryName(category)}} <i class="bi bi-x pointer" (click)="removeCat(category);$event.stopPropagation()"></i></span>
                        </div>
            
                        <div class="col-11 cursor-text" *ngIf="selectedCat?.length == 0" (click)="modalCategory()">
                            <font class="placeholderRfq">Select Categories</font>
                        </div>
            
            
            
            
                        <div class="col-1 text-end pointer"  (click)="modalCategory()"><img src="/assets/images/icons/ic_SearchBlack.png" class=""></div>
                        </div>
                    </div>
        
                    
                    <div class="row mb-2">
                        <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Location (max.5):</b> </label>
                        <div class="input-group">
        
                        </div>
                        <div class="input-group">
                        <ng-multiselect-dropdown id="TagsList" class="w-100"
                        [placeholder]="'Select Location'"
                        [settings]="dropdownSettingsTag"
                        [data]="dropdownListTag"
                        [(ngModel)]="selectedItemsTag"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeselectAll($event)"
                        [ngModelOptions]="{standalone: true}"
                        >
                        </ng-multiselect-dropdown>
                        </div>
                    </div>
                    
                    <div class="mb-2 row">
                        <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>Expiration Date Range:</b> </label>
                        <div class="col-sm-5">
                            <div class="input-group {{ errorDateRange ? 'errDateRange' : '' }}">
                                <input type="text" class="form-control placeholderRfq readonly" placeholder="Select Date"
                                name="etaRequestedStart" [(ngModel)]="ValminDateStart" formControlName="etaRequestedStart"  ngbDatepicker #d1="ngbDatepicker"  (click)="d1.toggle()" [maxDate]="ValminDateTo" readonly> 
                                <a  class="btn btn-secondary bgGroupBtn" (click)="clearDate('start')" *ngIf="ValminDateStart"><i class="bi bi-x"></i></a>
                                <a class="btn btn-secondary bgGroupBtn"  (click)="d1.toggle()" ><img src="/assets/images/icons/Subtract.png"></a>
                            </div>
                        </div>
                        <div class="col-sm-2 text-sm-start text-md-center text-lg-center">
                        <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>To</b> </label>
                        </div>
                        <div class="col-sm-5">
                        <div class="input-group {{ errorDateRange ? 'errDateRange' : '' }}">
                            <input type="text" class="form-control placeholderRfq readonly" placeholder="Select Date"
                            name="etaRequestedTo" [(ngModel)]="ValminDateTo" formControlName="etaRequestedTo"  ngbDatepicker #d2="ngbDatepicker"  (click)="d2.toggle()" [minDate]="ValminDateStart" readonly> 
                            <a  class="btn btn-secondary bgGroupBtn" (click)="clearDate('end')" *ngIf="ValminDateTo"><i class="bi bi-x"></i></a>
                            <a class="btn btn-secondary bgGroupBtn" (click)="d2.toggle()" ><img src="/assets/images/icons/Subtract.png"></a>
                        </div>
                    </div>
        
                    </div>
        
                    <div class="row mb-2">
                        <label for="etaRequested" class="col-sm-12 col-form-label pb-0"><b>RFQ Status:</b> </label>
                        <div class="input-group">
                        <select class="form-select" [(ngModel)]="Valstatus"  [ngModelOptions]="{standalone: true}">
                            <option value=""> - </option>
                            <option value="PUBLISHED">PUBLISHED</option>
                            <option value="UNPUBLISHED">UNPUBLISHED</option>
                            <option value="CLOSED">CLOSED</option>
                            <option value="VOID">VOID</option>
                            <option value="HALT" class="text-uppercase">{{'text.halt' | translate}}</option>              
                        </select>
        
                        </div>
                    </div>
        
        
                    </div>
                </div>
        
        
                <div class="row mt-3">
                    <div class="col-sm-1 mb-3"></div>
                    <div class="col-sm-5 mb-3">
                    <button type="button" class="btn c1 f1 pe-5 ps-5 confirmBtn text-uppercase fsmall w-100" data-bs-dismiss="modal" (click)="activeModal.close();">Clear</button>
                    </div>
                    <div class="col-sm-5 mb-3">
                    <button type="button" class="btn bg1 f1 confirmBtn text-white pe-5 ps-5 text-uppercase fsmall w-100" (click)="filterRFQ()">Filter</button>
                    </div>
                    <div class="col-sm-1 mb-3"></div>
                </div>
      
              </form>
            </div>
      
        </div>
        <!--/filter -->

        <!-- <div class="modal-content  rounded-0 border-0">
            <div class="modal-header bg1 rounded-0  text-center">
                <h4 class="text-white f1 mb-0"><strong>Custom Filter</strong></h4>
                <button type="button" class="btn text-white ps-0 pe-0" data-bs-dismiss="modal" (click)="activeModal.close();"><i class="bi bi-x-lg"></i></button>
            </div>
            <div class="modal-body filterdiv" *ngIf="!getResponse">

                <p>Frequently Asked Questions</p>
                <p *ngFor="let question of sampleQuestion; let i = index" (click)="selectData(question.id, question.text)" class="pointer {{selectedIdQuestion==question.id?'selected':'unselected'}} mt-2 mb-2">{{question.text}}</p>
                <p class="mt-4 mb-1">Ask something else about the product here</p>
                <textarea class="form-control" row="3" maxlength="50" placeholder="Ask someting to {{sendTo}}"  [(ngModel)]="valMessage" [ngModelOptions]="{standalone: true}" (ngModelChange)="messageChange()" [disabled]="processMsg"></textarea>
                <div class="text-center mt-3 mb-2">
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="submitMessage()" [disabled]="isEmptyChat">{{processMsg?'Loading...':'Send'}}</button>
                </div>

            </div>
            <div  *ngIf="getResponse">
                <div class="text-center mt-3 mb-4">
                    <div class="m-3">
                        <p><font [innerHtml]="ResponseText"></font></p>
                    </div>
                    <button type="button" class="btn bg1 f1 text-white pe-5 ps-5 text-uppercase fsmall" (click)="close()">Close</button>
                </div>
            </div>
        </div> -->
    </div>

</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {gql, Apollo} from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
// import 'tinymce/icons/default';
import { NavigationService } from 'src/app/_services/navigation';
import Util from 'src/app/_helpers/util';
import { ProductService } from 'src/app/_services/product.services';
import { Popupmodal } from 'src/app/_helpers/popupmodal';
import { TranslateService } from '@ngx-translate/core';
import { LightGallery } from 'lightgallery/lightgallery';
import CheckErrortype from 'src/app/_helpers/check-errortype';
import { CompanyService } from 'src/app/_services/company.services';



@Component({
  selector: 'app-products-preview',
  templateUrl: './products-preview.component.html',
  styleUrls: ['./products-preview.component.scss']
})
export class ProductsPreviewComponent implements OnInit {
  pageloading: boolean = false;
  submitted = false;
  tags:any=[]
  SelectedTags:any=[]
  imageSrc: string=''
  imageSrcMore:any=[]

  successResponse:boolean=false;
  successResponseName:string=''

  MoreImgCount = new Array(3);
  MoreImgArr:any = []
  CombineImg:any = []

  checkPriceDecCalculate:boolean=false;
  checkDiscIncCalculate:boolean=false;

  displaydelete='none';

  displaydraft='none'
  displaydone='none'
  displaypublish='none'
  displaytimeline='none'

  divFhSpec :any=[] // <fhspecdiv></fhspecdiv>
  countarrFhSpec:any =[]

  valspecLabel=''
  valspecValue=''

  emptyLabelSpec : any = []
  emptyValueSpec : any = []

  ValSpecEmpty:boolean=false

  successdraft:boolean=false
  actionbtn:any='' //draft , preview, done

  StatusApproval:number=1 // 1->OPEN , 2->REQUESTED, 4->APPROVED
  LoadingreqeustApproval:boolean=false
  StatusComparation:boolean=false
  CanApproveReject:boolean=true // false

  LoadingreqeustPublish:boolean=false

  displayApproveReject='none'
  valApproveReject:string=''

  productForm = this.formBuilder.group({
    name: ['', Validators.required],
    brand: [''],
    // description: ['', Validators.required],
    description: [''],
    sku: [''],
    manufacturerSku: [''],
    price: ['', Validators.required],
    // imageFileName: [''],
    isDisplayPriceOnShopfront:  [''],
    isDisplayProductOnShopfront: [''],
    tags: [''],
    quotationInitialPriceOffer: [''],
    autobidMinimumPrice: [''],
    autobidPriceDecrement: [''],
    isAutobidEnabled: [''],
    isActivatedByUser: [''],
    primaryImg: [''],
    tinymce: ['', Validators.required],
  });


  //model
  Valname:string=''
  Valbrand:string=''
  Valdescription:string=''
  Valfulldescription:string=''
  Valsku:string=''
  ValmanufacturerSku:string=''
  Valprice:string=''
  ValimageFileName:string=''
  ValisDisplayPriceOnShopfront:boolean=true
  ValisDisplayProductOnShopfront:boolean=false
  Valtags:any=''
  ValquotationInitialPriceOffer:string=''
  ValautobidMinimumPrice:string=''
  ValautobidPriceDecrement:string=''
  ValisAutobidEnabled:boolean=false
  ValisActivatedByUser:boolean=false
  Valcurrency:string=''
  Valstate:string=''

  ValprimaryImg:string=''
  FilenamePirmaryImg:string=''
  ValimageMore:any=[]

  EditProductId:any
  PassData:any
  valspecValueErr: boolean=false;
  valspecLabelErr:boolean=false;
  previewShopSide: boolean=false;
  dataShopSide:any
  //EditTags:any
  companyName:string=''

  vendorName:string=''
  vendorId:string=''
  vendorLocation:string=''
  isPKP:any
  isCompanySetPKP:boolean=false
  Valtaxstatus:any
  ValisCompanyNameHiddenInRfq:boolean=false
  VallogoImageUrl:string=''
  CompanyData:any

  lightboxImg:any = [];
  private lightGallery!: LightGallery;
  private needRefresh = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http:HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private navigation: NavigationService,
    private productService: ProductService,
    private popupmodal : Popupmodal,
    private translate: TranslateService,
    private companyService: CompanyService
  ) { }



  ngOnInit(): void {
    this.EditProductId = this.route.snapshot.paramMap.get('ProductId');
    console.log('EditProductId',this.EditProductId);
    if(this.EditProductId !==''){
      if(this.EditProductId=='preview-shop-side'){
        this.previewShopSide=true
        var previewShopSide = localStorage.getItem('previewShopSide')
        if(previewShopSide){
          this.dataShopSide = JSON.parse(previewShopSide)
          console.log('this.dataShopSide ',this.dataShopSide)

          var companydata = localStorage.getItem('COMPANYDATA')
          console.log('companydata : ',companydata)
          if(companydata){
            // var arrcompanyname = JSON.parse(companydata)
            // this.companyName = arrcompanyname[0].companyName

            if (localStorage.getItem("COMPANYSELECTID") !== null) {
              var getCpName= localStorage.getItem("COMPANYSELECTNAME");
              if(getCpName){
                this.companyName = getCpName
              }
            } 

          }


          this.itemsGallery = []
          var ArrImg:string[] = []
          if(this.dataShopSide.imageFileName){
            ArrImg.push(this.dataShopSide.imageFileName)
          }
          if(this.dataShopSide.imageMore){
            this.dataShopSide.imageMore.forEach((element:string) => {
              ArrImg.push(element)
            });
          }
          
          console.log('ArrImg >> ',ArrImg)
          var idGallery = 0
          ArrImg.forEach((v:string,k:number) => {
            var src = v;
            var thumb = v;

            this.itemsGallery = [
              ...this.itemsGallery,
              {
                id: idGallery,
                src: src,
                thumb: thumb,
              }
            ];
            this.needRefresh = true;
            idGallery++

          });


          //get company confidential or not
          this.companyService
          .getCompanyDetail()
          .subscribe((res:any) => {
            if(res.data.company){
              console.log('CompanyDetail', 'res', res)
              this.CompanyData=res.data.company
              //mapping to input
              this.Valname=this.CompanyData.name
              this.ValisCompanyNameHiddenInRfq=this.CompanyData.isCompanyNameHiddenInRfq
              this.VallogoImageUrl=this.CompanyData.logoImageUrl
              console.log('this.CompanyData?.locations : ',this.CompanyData?.locations)
              if(!this.CompanyData?.isCompanyNameHiddenInRfq){
                this.vendorName=this.CompanyData.name

                if(this.CompanyData?.locations[0].city){
                  this.vendorLocation = this.CompanyData?.locations[0].city
                  if(this.CompanyData?.locations[0].country){
                    this.vendorLocation = this.CompanyData?.locations[0].city+', '+this.CompanyData?.locations[0].country
                  }
                }

                 
              }
      
              var ispkp = this.CompanyData.isPKP
              console.log('ispkp : ',ispkp)
              if(ispkp===null){
                this.Valtaxstatus = 'null'
              } else{
                this.isCompanySetPKP=true
                this.Valtaxstatus = ispkp.toString()
              }
            } 
          })
        }
      } else {
        this.getDataEdit(this.EditProductId)
      } 
    }

    //this.getTag()

    for (let i = 0; i < this.MoreImgCount.length; i++) {
      this.imageSrcMore.push({'id':i,'val':'','name':'','url':''})
    }
    //console.log('imageSrcMore',this.imageSrcMore);


    // var test = this.data.currentMessage.subscribe(message => this.PassData = message)
    // this.data.currentMessage.subscribe(message => {
    //   console.log('xxxxxxxxxx',message)
    // });



    // this.data.currentArr.subscribe(currentArr => {
    //   console.log('xxxx currentArr xxxxxx',currentArr)
    // });

  }
  get f() { return this.productForm?.controls; }



  settingsGallery = {
    counter: true,
    download:false,
  };
  settingsGalleryMobile = {
    counter: true,
    download:false,
    selector: '.itemz'
  };
  
  itemsGallery:any = []

  onInitGallery = (detail:any): void => {
    this.lightGallery = detail.instance;
  };

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }


  getUserStatus(){
    var usercompany = localStorage.getItem("USERCOMPANY")
    console.log('usercompany --- ',usercompany);
    //check if owner, show approve or reject

    if(usercompany){
      var arrUserCompany = JSON.parse(usercompany)
      console.log(arrUserCompany.roleMembership)
     //roleMembership
      // if (1,2,3) //1,2,4 can approve / reject
      // if(arrUserCompany.roleMembership==1 || arrUserCompany.roleMembership==2 || arrUserCompany.roleMembership==4){
      //   this.CanApproveReject=true
      // }

    }



  }


  getDataEdit(ProductId: any){

    
    this.productService.getProductDetail(ProductId)
    .subscribe((res:any) => {
      console.log("succes, get data:", res);
      this.LoadingreqeustApproval = false
      if(res.data.companyProduct){

        console.log('get prodct detail ',res.data.companyProduct);

        this.Valname = res.data.companyProduct.name
        this.Valbrand = res.data.companyProduct.brand
  
        this.Valfulldescription = res.data.companyProduct.description
        // seperate desc
        this.Valdescription = res.data.companyProduct.description
  
        this.divFhSpec = []
        this.Valdescription = ''
        var desctext = res.data.companyProduct.description;
  
        var a = res.data.companyProduct.description;
        var getdetaildesc = /<fhspecdiv>(.*?)<\/fhspecdiv>/g.exec(a);
        console.log('xxxx>>',getdetaildesc);
        if(getdetaildesc){
          console.log('>>>>>>> 0 ',getdetaildesc[0]);
          console.log('>>>>>>> 1 ',getdetaildesc[1]);
  
          this.divFhSpec = getdetaildesc[1]
          this.divFhSpec = JSON.parse(this.divFhSpec)
          // this.Valdescription = desctext.split(getdetaildesc[0]).pop()
          this.Valdescription = desctext.replace(getdetaildesc[0],'')
  
          console.log('this.Valdescription if ',this.Valdescription);
        } else {
          this.Valdescription = res.data.companyProduct.description;
          console.log('this.Valdescription else ',this.Valdescription);
        }
  
        console.log('divFhSpec',this.divFhSpec);
        console.log('Valdescription',this.Valdescription);
        // // this.fhspecdiv =
        // // this.Valdescription =
  
        //this.Valdescription = data.companyProduct.description
        this.Valsku = res.data.companyProduct.sku
        this.ValmanufacturerSku = res.data.companyProduct.manufacturerSku
        this.Valprice = res.data.companyProduct.price
        this.Valcurrency = res.data.companyProduct.currency
        this.ValimageFileName = '' //obj.node.imageUrl[0]
        this.ValisDisplayPriceOnShopfront = res.data.companyProduct.isDisplayPriceOnShopfront
        this.ValisDisplayProductOnShopfront = res.data.companyProduct.isDisplayProductOnShopfront
        this.Valtags = res.data.companyProduct.tags //obj.node.tags
        this.ValquotationInitialPriceOffer = res.data.companyProduct.quotationInitialPriceOffer
        this.ValautobidMinimumPrice = res.data.companyProduct.autobidMinimumPrice
        this.ValautobidPriceDecrement = res.data.companyProduct.autobidPriceDecrement
        this.ValisAutobidEnabled = res.data.companyProduct.isAutobidEnabled
        this.ValisActivatedByUser = res.data.companyProduct.isActivatedByUser
        this.ValprimaryImg = '' //obj.node.imageUrl[0]
        this.FilenamePirmaryImg = '' //obj.node.imageUrl[0]
        this.ValimageMore = []

        // this.imageSrcMore=[]
        this.ValimageFileName=''
        this.MoreImgArr=[]



        if(res.data.companyProduct.imageUrl){
          if(res.data.companyProduct.imageUrl.length>0){
            var x = 0;
            var more =0;
            res.data.companyProduct.imageUrl.forEach((element:string) => {
              if(x==0){
                this.ValimageFileName=element
              } else {
                this.imageSrcMore[more]['url']=element
                more++
              }
              x++;
            });


            var ArrImg = res.data.companyProduct.imageUrl

            var idGallery = 0
            ArrImg.forEach((v:string,k:number) => {
              var src = v;
              var thumb = v;
  
              this.itemsGallery = [
                ...this.itemsGallery,
                {
                  id: idGallery,
                  src: src,
                  thumb: thumb,
                }
              ];
              this.needRefresh = true;
              idGallery++
  
            });


          }
        }
  
        this.Valstate = res.data.companyProduct.state
  
              this.StatusApproval=res.data.companyProduct.publishApprovalStatus
  
            if(res.data.companyProduct.publishedOngoingApprovalStatus){
              console.log('ok, not null');
              this.StatusComparation = true
            } else {
              console.log(' null');
              this.StatusComparation = false
            }
  

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });

    this.getUserStatus();

  }




  openmodaldraft(){
    if(this.displaydraft=="none"){
      this.displaydraft = "block";
    } else {
      this.displaydraft = "none";
    }
  }

  openmodaldone(){
    if(this.displaydone=="none"){
      this.displaydone = "block";
    } else {
      this.displaydone = "none";
    }
  }
  openmodaldelete(){
    if(this.displaydelete=="none"){
      this.displaydelete = "block";
    } else {
      this.displaydelete = "none";
    }
  }



  openModalApproveReject(val:any){
    this.valApproveReject = val
    if(this.displayApproveReject=="none"){
      this.displayApproveReject = "block";
    } else {
      this.displayApproveReject = "none";
    }
  }



  OpenModalTimeLine(){
    if(this.displaytimeline=="none"){
      this.displaytimeline = "block";
    } else {
      this.displaytimeline = "none";
    }
    console.log('displaytimeline',this.displaytimeline);
  }

  submitaction(val:any){
    this.actionbtn = ''
    this.actionbtn = val
    //this.onSubmit()
    console.log('val',val);

  }
  closesuccessdraft(){
    this.successdraft = false
  }



  // getTag(){
  //   this.apollo.watchQuery<any>({
  //     query: getTags
  //   })
  //   .valueChanges
  //   .subscribe(({data}) => {
  //     this.tags = data.getCompanyTagList;
  //   })
  // }

  confirmdelete()
  {
    console.log('delete',this.EditProductId);

    this.openmodaldelete()
    //post delete

    this.productService.SubmitPostDelete(this.EditProductId).subscribe((res:any) => {
      console.log(res)
      if(res.data?.deleteCompanyProducts){
        this.popupmodal.showSuccess("Product deleted !",'/vendor/product-list').subscribe((res:any) => {})
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });


  }



  reqeustApproval(id:any,action:any){
    console.log('id',id);
    console.log('action',action);

    this.LoadingreqeustApproval = true

    if(action=='APPROVE' || action=='REJECT'){
      this.openModalApproveReject(action)
      console.log('appr / reject')

    }
    


    this.productService
    .SubmitPostApproval(action,id)
    .subscribe((res:any) => {
      console.log("succes, get data:", res);
      this.LoadingreqeustApproval = false
      if(res.data.changeCompanyProductsApproval){
        console.log("succes, get data:", res);


        if(action=='APPROVE'){
          this.StatusApproval=4
          console.log('appr reject',this.StatusApproval)
        }
        else if(action=='REJECT'){
          this.StatusApproval=1
        }
        else {
          console.log('request')
          this.StatusApproval=2
          console.log('request',this.StatusApproval)
        }

        // modalRef.componentInstance.modalinfo = 'info'
        // modalRef.componentInstance.infoTitle = 'Success!' 
        // modalRef.componentInstance.infoSuccess = 'Product published!'
        // modalRef.componentInstance.infoFailed = null
        // modalRef.componentInstance.closeTxt = 'Close'
        

      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });


  }

  submitPublish()
  {
    this.LoadingreqeustPublish=true
    this.productService.SubmitPostPublish(this.EditProductId).subscribe((res:any) => {
      console.log(res)
      this.LoadingreqeustPublish=false
      if(res.data?.publishProducts){
        console.log("succes requestPublish, get data:", res);
        this.Valstate = 'PUBLISHED'
        this.StatusApproval=4

        this.popupmodal.showSuccess("Product published!",null).subscribe((res:any) => {})
        
      }
      else if(res.errors){
        var error = res.errors[0].message
        console.log('error',error);
        var errMsg = error + '';
        var result = errMsg.split(":");
        var resMsg = result[result.length - 1];
        var msg = (resMsg)? resMsg: errMsg
        console.log('msg',msg);
        var errortext = msg

        if(res.errors[0].extensions.type){
          //get translate error, if exsit, replace errortext
          this.translate.get('error.'+res.errors[0].extensions.type).subscribe((data:any)=> {
            if (this.translate.instant('error.'+res.errors[0].extensions.type) !== 'error.'+res.errors[0].extensions.type){
              errortext = data
            }
          });
          //force redirect on specific errortype
          var forceRedirect = CheckErrortype.getResponse(res.errors[0].extensions.type)
          if(forceRedirect){
            window.location.href = forceRedirect
            return
          }
        }
        this.popupmodal.showError(errortext,null).subscribe((res:any) => {})
      }
      else {
        this.popupmodal.showFail().subscribe((res:any) => {})
      }
      
    });



  }

  comparepage(){
    console.log('comparepage');

  }

  back(): void {
    this.navigation.back()
  }

  divspec(val:any){
    return Util.fhspectocomma(val)
  }
  divshotdesc(val:any){
    return Util.formatShortDesc(val)
  }
  removeHtml(val:any){
    const strippedString = val.replace(/(<([^>]+)>)/gi, "");
    console.log(strippedString);
    return strippedString
  }
  closepage(){
    window.close();
  }
}
